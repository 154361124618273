import React from 'react';

const SVGActiveCampReg = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
<rect width="30" height="30" fill="url(#pattern0_140_1385)"/>
<defs>
<pattern id="pattern0_140_1385" patternContentUnits="objectBoundingBox" width="1" height="1">
<use xlinkHref="#image0_140_1385" transform="scale(0.02)"/>
</pattern>
<image id="image0_140_1385" width="50" height="50" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEzElEQVR4nO1a3YscRRAf/AIVvzWKvhjjg6B/gT74gdFA7m6rJq6iAQXBMxf/CONXCPrqmxDxIdzlRPNwMSB52Ez17OYipygkavBFjuQ20z17uQQRQjxXam6iO709Mz0fG/OwBQ3Lbnd1/7qqflVdrOOMZSxjuWqyQs37AsKXlMAPlYAvlcBjSuBSPPjzPP8WeI0dZ1sT9zrXkqwebd6hBL4jBS5Kwr+VwL7NkATrkrCjCHezjv8NwPlW404l4CNJeMH28KmgBKyxpVavNiBJ8KoS0K0KYHjAivTdV0YOoPvt1lslwRcWt7sgBexTBDNKuK9Fg2Am+k7AYQsrfn52aeKWkQWyJPjOfHi8LAkOhMJ9vj/fvD5PF88JfXhBCZjltSk6T9ROCBEIgT+nuMNst9V4uKzuc8J9JGYzE5hTtYGJ3clkCSUJJmrZxHEc5eOUJOgZwCzW4mammJCEv1axQpp0vcnNSuBpg9X318BOBhBt3OSMSLpt3GQCIwW8XCVP6BSr0iyh/KkHlYDprBEKeCMg96k8Quh6k5uH3QxWwsVttxcGIgn3GrJxakwo0XjGPgHisvJxZ17MKH0twfuFrRHngwQ7ZW5cAMiAm+7N1okJNpMCzxfK/lz/6HkiL7jLAOERCnCzqFnqecaHXdZAmPI0lzqQC14DIgkuSQFHE4PgpMFdf8nWC7PamnaBDJ6sYjljFwWiBEjTPCngzSHLtN3H0vSGUQWQAL5+4QTckwskfk8kaiebssMWyMZcWEns4eH2tLn91tM36LVZljsObMKPosSNLeQuKgCEs7QS+IcWg81M3YRHCrNX/LIbtMi+ckBQrYntdw2OntjxuCT4WjvUn8qDRpZuKfDj5Bo8aAPEK8MS5egX1nttfKIoiyqBx2yA/JAEkp24KgL51Eq3jzs1t/3+WgKy0D/ZvGmUQLxRupYk+J119vvvXmejt4Jr6WVB+WAPO82HFEGg3eZPNnSeHeww5+SJIvxA2/hwFfqVBG8NWwambXSm0q/A95w84eaZZpE1TkplgfDtK4IftRsNbIu//tL0jZLw4uD6UCDmLuT0z7SoLdxaFkj0m+8+a4iVT2yABITbSpUoLFEHsEAJnwck0inwkHagS0Fn8tFcvQRzml7fCkS8eCbpXniZS+oqQPjQfHhN76EsnQGvEfCXZs23rYGw/w4/rHC+ChAWdqehwPfwuTSdkvArDfhq4eeuoXjk5DhVBUjU8LakY0UNMOShPYVA/LupXm4T9LgxYNN8CAW+bpoXeI0n9aZE0Go+kJjjwxa+fW3vM8qfus0pI9xQNtzK6VG2g84dn7xfEf6m78vvpEqKuaFsBJNimSoS+LDFBEIJ+Kyycn4IcUPZkAd6WTFTVDgmpOZO8T7HlzvNm2vZhBvJ3FA2WCZiszxqzpINWk6yk/qPpU5ZJ7+CYBLdlcE8w0kz8NwXbcoZnhNlbII5Q57oX7FE7SCS723Yn2KZ+AB4URF8E1WsXH7zW4IH4e74uyN67WSKieW63ClLuKGsU3MdQxKcqcxORYUzLHczuI1ZGcBGkO8pnSdqA+TDLu4A6lVzzu2vcwHItVOpLvsohYOT3wpsKW7ZSMLWlT8MRJ8JD2781oC1TvPukR5mLGMZizMo/wAbI7y4+/VIBQAAAABJRU5ErkJggg=="/>
</defs>
</svg>



);

export default SVGActiveCampReg;
