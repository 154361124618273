import * as React from "react";
import { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import axios from "axios";
import _ from "lodash";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link, useNavigate, useLocation } from "react-router-dom";
import VideoSideBar from "../../components/VideoSideBar";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import MyCommunityTree from "../../components/MyCommunityTree";

function MyCommunity(props) {
  const dispatch = useDispatch();
  const locationPath = useLocation();
  const navigate = useNavigate();
  const inputRef = React.useRef(null);
  const videoRef = React.useRef(null);
  const [campregisterlist, setCampRegisterList] = useState([]);
  const [activityList1, setActivityList1] = React.useState("");
  const [campID, setCampID] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("cYAAG");
  const getCampRegistrationList = async () => {
    try {
      // const res = await axios.get(
      //   `/camp/video/${selectedCategory}/${localStorage.getItem("userid")}`
      // );
      const res = await axios.get(
        `/list/files/campaign/${selectedCategory}/${localStorage.getItem("userid")}`
      );
      setCampRegisterList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    getCampRegistrationList();

  }, [selectedCategory]);

  const getCampID = async () => {
    try {
      const res = await axios.get(
        `/camp/video/name/${selectedCategory}/${localStorage.getItem("userid")}`
      );
      setCampID(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    getCampID();

  }, [selectedCategory]);

  const getPerformanceReportOnUser1 = async () => {
    try {
      const res = await axios.get(
        `/adopter_dashboard/pf/mynetworkreward/test/${locationPath?.pathname.split("/")?.[2]}/${campID?.[0]?.campid}`
      );

      setActivityList1(res?.data);
      return res;
    } catch (err) {

      setActivityList1(err?.response?.data);
      throw err;
    }
  };

  React.useEffect(() => {
    getPerformanceReportOnUser1().then((response) => {

      setActivityList1(response);
      return response;
    });
  }, [campID, selectedCategory]);

  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button
              onClick={() => navigate(-1)}
              className="backWrapper"
            >
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <Stack sx={{ flexDirection: { sx: "column", md: "row" } }}>
              <Box sx={{
                height: { sx: "auto", md: "92vh" }, borderRight: "1px solid #FFDEAD",
                px: { sx: 0, md: 2 }
              }}>
                <VideoSideBar
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                />
              </Box>
              <Box p={2} sx={{ overflowY: 'auto', height: '90vh', flex: 2, flexWrap: "wrap", order: 3 }}>
                {
                  selectedCategory && campID && activityList1 &&
                    selectedCategory === "cYAAG" ? (
                    // <Typography variant={isMobile ? "h5" : "h4"}  fontWeight="bold" mb={2} sx={{ color: "black" }}>
                      <h3 style={{ marginBottom: "36px", fontFamily: "Roboto, sans-serif", fontSize: '25px', fontWeight: 600 }}>Please click on the campaign to see your Community</h3>
                      
                    // </Typography>
                  ) : (
                    <Typography variant="h4" fontWeight="bold" mb={2} sx={{ color: "black" }}>
                      {selectedCategory} <span style={{ color: '#F31503' }}></span>
                      <div><MyCommunityTree camp={campID?.[0]?.campid} selectedCategory={selectedCategory} reftree={activityList1} /></div>
                    </Typography>
                  )
                }
              </Box>
            </Stack>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
}
export default MyCommunity;