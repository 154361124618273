import React from 'react';

const SVG4 = () => (
  <svg width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <rect y="0.5" width="50" height="50" fill="url(#pattern0_140_897)" />
    <defs>
      <pattern id="pattern0_140_897" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_140_897" transform="scale(0.02)" />
      </pattern>
      <image id="image0_140_897" width="50" height="50" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACoUlEQVR4nO1XS4sTQRBu9SKo+LgKrpuquLCHBT15UFD0pLJ6maoggvov1FUXf4YPvK6u4t2DBjNVWRBP4mMFbx7Uw6KsgnqKdLbjxjgzmUkmk0T6g7p0T3XX19XzVbUxHh4eHh7DApIosjbG1MJ1IsMPpi8znUTMmAE9kYh/C0i+A+sykjxC0ot7zoY7zbhlBKPv7Beg8PLE+ermvAPPlQhweARY7gLJm5ZfieQEslxHlpfrhOR5OajvNqNGZJJr+4DlWaJqGGPKFT0JpO/tOJB8GCQZzEqkFISH7JVxJ/0RWOawovvj/KaD+i4grbUyk3TNbB3IVCt6JYIsgCxfXVALpeDF9jR+jkwzM8h6KeqbtESAtJYDEbcR6T0z39iYuNC/vqdaAjBxurrD5AxMS6RM9eOt6zQ1K9u6LhS1Gekr+02Z5UJ+FDISQdbbzdSyXOmcS/JrB5DeWFtDH5qhESF9Z8cnK0szqRaKgFUxR2Q5n/B7IAKs3+z4zLnHWzrn2v3SGLCuDk21gHXVjk8H1a19BNFNeQavWmB7qISrlQSg+gGXiddmQMAMP/st97PPZd6EZN6d5p0cYu6PSIn12FoNkU9R8hsH2wFbHye7h82AgJkKImnVzd83prGh6+rB4iYrte4AnuQbeh9EIKgjsKy4wriYlBnbvgDJA3cdV2x7E/dt4b1Wq2n8Q4bkc5n0qm0arZpZaXYN5DU712pJkMKDJgUK67XaM4OsT7ueHml1ipf2mgKAvRD5SwBIbtqHlS2YzacuyVs3dtQUCOyHyCgBPZGMANIzUJFZM+4ZAZZfwPrTjERB5PSNYZx17pPdRP4LIpBnHekFSPrDWt7rFk7Evg6tmQEBvfyOGDAuI+NqJk9lGpZBjKJ5eHh4eJgi8Bt473UM8ffRCAAAAABJRU5ErkJggg==" />
    </defs>
  </svg>


);

export default SVG4;
