import React,{useContext} from "react";
// import './main.scss';
import _ from "lodash";
// import API from '../../services';
// import { Progress } from 'reactstrap';
import { useState } from "react";
import uplodIcon from "../assets/upload.png";
import {
  getCampFiles,
  getCampFilesReset,
} from "../redux/action/campFilesAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import FileUploadImg from "../assets/file-up.png";
import MyOngoingCampaign from "../routes/Campaigns/MyOngoingCampaign";
// import { useMyContext } from '../routes/Campaigns/MyOngoingCampaign';
import { Button } from "@mui/material";
import { isEmpty } from "lodash";

const UploadFiles1Button = (parentTriggerFunction) => {

  console.log('parentTriggerFunction:', parentTriggerFunction);
  const dispatch = useDispatch();
  const locationPath = useLocation();
  // let { id, label, uploadUrl } = props;
  const [isUploding, setUploding] = useState(false);
  const [uploadedImgs, setUplodedImgs] = useState([]);
  const [uploadProgress, setProgress] = useState(0);
  const [files, setFiles] = useState();
  const [fileSize, setFileSize] = useState(true);
  const [fileUploadProgress, setFileUploadProgress] = useState(false);
  const [fileUploadResponse, setFileUploadResponse] = useState(null);

  React.useEffect(() => {
    dispatch(getCampFilesReset());
    setFileUploadResponse("");
    setFiles();
  }, [parentTriggerFunction.selectedCategory]);

  const {
    isSuccess: campFilesSuccess,
    response: campFilesResponse,
    isError: campFilesError,
  } = useSelector((state) => state.getCampFiles);

  // const { getCampRegistrationList } = useMyContext();

  const handleChange = (e) => {
    // let { files } = e.target;
    // setSelectedFile(e.target.files[0]);
    setFiles(e.target.files);
    // console.log(files);
    // let formData = new FormData();
    // setUploding(true);
    // _.forEach(files, file => {
    //     formData.append('files', file);
    //     dispatch(
    //         getCampFiles({ campid: props.camp, userId: localStorage.getItem("userid"), formData })

    //       );
    // });

    // setUploding(true);
    // // let { data } = await API.post(uploadUrl, formData, {
    // //     onUploadProgress: ({ loaded, total }) => {
    // //         let progress = ((loaded / total) * 100).toFixed(2);
    // //         setProgress(progress);
    // //     }
    // // });
    // // setUplodedImgs(data);
    // setUploding(false);
    // alert("File uploaded");
  };

  const handleUpload = async () => {
    // let { files } = e.target;
    // setSelectedFile(e.target.files[0]);
    // console.log(selectedFile);


    if (isEmpty(files)) {
      setFileUploadResponse("Please select file(s) to upload");
    
      return;
    }

    setFileSize(true);
    setFileUploadProgress(true);
    setFileUploadResponse(null);
    let formData = new FormData();
    // setUploding(true);
    // _.forEach(files, file => {
    //     formData.append('files', file);
    //     dispatch(
    //         getCampFiles({ campid: props.camp, userId: localStorage.getItem("userid"), formData })

    //       );
    // });

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 51200000) {
        setFileSize(false);
        setFileUploadProgress(false);
        setFileUploadResponse(null);
        return;
      }

      formData.append(`files`, files[i]);
      // dispatch(
      //   getCampFiles({
      //     campid: props.camp,
      //     userId: localStorage.getItem("userid"),
      //     formData,
      //   })
      // );
    }
    
    // await dispatch(
    //   getCampFiles({
    //     campid: parentTriggerFunction.camp,
    //     userId: localStorage.getItem("userid"),
    //     formData,
    //   })
    // );


    Promise.all([
      dispatch(
        getCampFiles({
          campid: parentTriggerFunction.camp,
          userId: localStorage.getItem("userid"),
          formData,
        })
      ),
    ]).then(() => parentTriggerFunction.parentTriggerFunction());

    // setUploding(true);
    // let { data } = await API.post(uploadUrl, formData, {
    //     onUploadProgress: ({ loaded, total }) => {
    //         let progress = ((loaded / total) * 100).toFixed(2);
    //         setProgress(progress);
    //     }
    // });
    // setUplodedImgs(data);
    // setUploding(false);
    setFileUploadProgress(false);
    setFileUploadResponse("File(s) uploaded");
    // alert("File uploaded");
    // if (typeof parentTriggerFunction.parentTriggerFunction === 'function') {
    //   // Check if parentTriggerFunction is a function before calling it

    //   parentTriggerFunction.parentTriggerFunction();
    // } else {
    //   console.error('parentTriggerFunction is not a function');
    // }
   
  };


  return (
    <div class="cyagg-sec-space">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div>
              <div class="filecardupcoming">
                <h4>Upload Images/Videos (Visible to all change makers)</h4>
                {/* <div class="upcoming"> */}
                <div>
                  {/* <header>
                    <h4>Select File here</h4>
                  </header>
                  <p>Files Supported: Images/Vidoes</p> */}
                  <input
                    type="file"
                    // hidden
                    accept=".png,.jpeg,.jpg"
                    id="fileID"
                    // style={{ color:"orange", display: "none" }}
                    // style={{ color:"orange", backgroundColor:"orange" }}
                    multiple
                    onChange={handleChange}
                    placeholder="Select File and Upload"
                    // class="btn"
                  />
                  <button
                    class="btn"
                    style={{ marginTop: "5px" }}
                    onClick={handleUpload}
                  >
                    Upload
                  </button>
                  {/* <button class="btn">Choose File</button> */}
                </div>

                {!fileSize && (
                  <p style={{ color: "red" }}>File size exceeded 50MB!!</p>
                )}
                {fileUploadProgress && (
                  <p style={{ color: "orange" }}>Uploading File(s)</p>
                )}
                {fileUploadResponse != null && (
                  <p style={{ color: "green" }}>{fileUploadResponse}</p>
                )}
                {/* {isUploding ? (
                  <div className="flex-grow-1 px-2">
                    TRUE
                    
                    <div className="text-center">{uploadProgress}%</div>
                     <Progress value={uploadProgress} />
                  </div>
                ) : null} */}
              </div>
              {/* {
                    uploadedImgs && !isUploding ? (
                        uploadedImgs.map(uploadedImg => (
                            <img src={uploadedImg} key={uploadedImg} alt="UploadedImage" className="img-thumbnail img-fluid uploaded-img mr-2" />
                        ))
                    ) : null
                } */}
            </div>
          </div>
          {/* <div class="col-md-6">
            <div class="text-center">
              <img
                src={FileUploadImg}
                class="img-fluid mob-space1"
                style={{ width: "300px" }}
              />
              <p style={{ textAlign: "justify", fontSize: 12 }}>
                1. Please upload images and videos of your participation in
                campaign.You can view your uploaded images/vidoes from <a href= "/myongoingcampaigns">My ongoing campaigns </a>
                 section
              </p>
              <p style={{ textAlign: "justify", fontSize: 12 }}>
                2. Please ensure that file size is less than 50 MB
              </p>
              <p style={{ textAlign: "justify", fontSize: 12 }}>
                3. All your uploaded files will be visible to other change
                makers
              </p>
            </div>
          </div> */}
          {/* {campFilesSuccess && <span>Files uploaded</span>} */}
        </div>
      </div>
    </div>
  );
};

export default UploadFiles1Button;
