/** @format */

import { takeLatest, takeEvery, fork, cancel } from "redux-saga/effects";

import { SOCIAL_LOGIN_FETCHING_DATA_ATTEMPT } from "../action/loginAction";
import { login } from "../saga/LoginSaga";

import { REGISTER_FETCHING_DATA_ATTEMPT } from "../action/registerAction";
import { register } from "../saga/Register";

import { PROFILE_FETCHING_DATA_ATTEMPT } from "../action/UserProfileActions";
import { profileSaga } from "../saga/UserProfileSaga";

import { VERIFY_EMAIL_FETCHING_DATA_ATTEMPT } from "../action/VerifyEmailAction";
import { verifyEmail } from "./VerifyEmailSaga";

import { VERIFY_OTP_FETCHING_DATA_ATTEMPT } from "../action/VerifyOTP";
import { verifyOTP } from "./VerifyOTPSaga";
import {
  ONBOARD_ACTIVITY_FETCHING_DATA_ATTEMPT,
  ONBOARD_BULK_FETCHING_DATA_ATTEMPT,
  ONBOARD_CAMPAIGN_FETCHING_DATA_ATTEMPT,
  ONBOARD_INDIVIDUAL_FETCHING_DATA_ATTEMPT,
  ONBOARD_ORGANIZATION_FETCHING_DATA_ATTEMPT,
  ONBOARD_SPONSOR_FETCHING_DATA_ATTEMPT,
  ONBOARD_SELF_FETCHING_DATA_ATTEMPT,
  ONBOARD_REGFORM_FETCHING_DATA_ATTEMPT,
} from "../action/OnboardAction";
import {
  onboardActivity,
  onboardBulk,
  onboardCampaign,
  onboardIndividual,
  onboardOrganization,
  onboardSponsor,
  onboardSelf,
  onboardRegForm,

} from "./OnboardSaga";
import { GET_VOLUNTEER_COUNT_FETCHING_DATA_ATTEMPT } from "../action/volunteerCountAction";
import { getVolunteerCount } from "./VolunteerCountSaga";
import { GET_STATES_UT_FETCHING_DATA_ATTEMPT } from "../action/statesUTAction";
import { getStatesUT } from "./StatesUTSaga";
import { GET_CITY_FETCHING_DATA_ATTEMPT } from "../action/cityAction";
import { getCity } from "./CitySaga";
import { GET_USER_PROFILE_IMAGE_FETCHING_DATA_ATTEMPT } from "../action/userProfileImage";
import { getUserProfileImage } from "./userProfileImageSaga";
import {
  GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_ATTEMPT,
  GET_REPORT_USER_IN_STATE_FETCHING_DATA_ATTEMPT,
  GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_ATTEMPT,
} from "../action/getReport";
import { getReportActivitySaga, getReportStateSaga, getSOVolReportSaga } from "./getReportReducer";
import { GET_VOLUNTEER_NAME_FETCHING_DATA_ATTEMPT } from "../action/getVolunteerName";
import { getVolunteerName } from "./getVolunteerSaga";
import { GET_ORGANIZATION_FETCHING_DATA_ATTEMPT } from "../action/getOrganization";
import { getOrganization } from "./getOrganizationSaga";
import { myReward } from "./MyRewardSaga";
import { MY_REWARD_FETCHING_DATA_ATTEMPT } from "../action/MyRewardAction";
import { validatorList } from "./ValidatorListSaga";
import { VALIDATOR_LIST_FETCHING_DATA_ATTEMPT } from "../action/ValidatorList";
import { validateReward } from "./ValidatorSaga";
import { VALIDATE_REWARD_FETCHING_DATA_ATTEMPT  } from "../action/ValidatorAction";
import { purchaseYaag } from "./PurchaseYaagSaga";
import { PURCHASE_FETCHING_DATA_ATTEMPT  } from "../action/PurchaseYaag";
import { updateCampaign } from "./UpdateSaga";
import { UPDATE_CAMPAIGN_FETCHING_DATA_ATTEMPT  } from "../action/UpdateAction";
import { updateOrg } from "./UpdateSaga";
import { UPDATE_ORGANIZATION_FETCHING_DATA_ATTEMPT  } from "../action/UpdateAction";
import { volDashBoardReward } from "./DashboardRewardSaga";
import { DASHBOARD_REWARD_FETCHING_DATA_ATTEMPT  } from "../action/DashboardReward";
import { getCampaign } from "./CampaignSaga";
import { GET_CAMPAIGN_FETCHING_DATA_ATTEMPT  } from "../action/CampaignAction";
import { getOrgProfileLogo } from "./orgLogoSaga";
import { GET_ORG_PROFILE_LOGO_FETCHING_DATA_ATTEMPT  } from "../action/orgLogoAction";
import { getCampProfileLogo } from "./campLogoSaga";
import { GET_CAMPAIGN_PROFILE_LOGO_FETCHING_DATA_ATTEMPT  } from "../action/campLogoAction";
import { getCampProfileBanner } from "./campBannerSaga";
import { GET_CAMPAIGN_PROFILE_BANNER_FETCHING_DATA_ATTEMPT  } from "../action/campBannerAction";
import { referralUrl } from "./ReferralURLSaga";
import { REFERRAL_URL_FETCHING_DATA_ATTEMPT  } from "../action/ReferralURLAction";
import { yaagMyCommunity } from "./YaagMyCommunitySaga";
import { YAAG_MY_COMMUNITY_FETCHING_DATA_ATTEMPT  } from "../action/YaagMyCommunityAction";
import { topAdopters } from "./TopAdopterSaga";
import { TOP_ADOPTERS_FETCHING_DATA_ATTEMPT  } from "../action/TopAdopterAction";
import { myRank } from "./MyRankSaga";
import { MY_RANK_FETCHING_DATA_ATTEMPT  } from "../action/MyRankAction";
import { referral } from "./ReferralSaga";
import { REFERRAL_FETCHING_DATA_ATTEMPT } from "../action/ReferralAction";
import { getRewardTitle } from "./RewardDetailSaga";
import { GET_REWARD_TITLE_FETCHING_DATA_ATTEMPT } from "../action/RewardDetailAction";
// import { getRewardSubTitle } from "./RewardDetailSaga";
// import { GET_REWARD_SUBTITLE_FETCHING_DATA } from "../action/RewardDetailAction";
import { getCampProfileVideo } from "./campVideoSaga";
import { GET_CAMPAIGN_PROFILE_VIDEO_FETCHING_DATA_ATTEMPT } from "../action/campVideoAction";
import { addRewards } from "./addRewardsSaga";
import { ADD_REWARDS_FETCHING_DATA_ATTEMPT } from "../action/addRewardsAction";
import { updateSponsor } from "./UpdateSaga";
import { UPDATE_SPONSOR_FETCHING_DATA_ATTEMPT  } from "../action/UpdateAction";
import { updateReward } from "./UpdateSaga";
import { UPDATE_REWARD_FETCHING_DATA_ATTEMPT  } from "../action/UpdateAction";
import { getCampFiles, getCampDocs } from "./campFilesSaga";
import { GET_CAMPAIGN_FILES_FETCHING_DATA_ATTEMPT, GET_CAMPAIGN_DOCS_FETCHING_DATA_ATTEMPT  } from "../action/campFilesAction";
import { userreviewlist } from "./UserOnboardReviewSaga";
import { USER_ONBOARD_REVIEW_LIST_FETCHING_DATA_ATTEMPT } from "../action/UserOnboardReviewAction";
import { actionUserOnboardReview } from "./ActionUserOnboardReviewSaga";
import { USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_ATTEMPT } from "../action/ActionUserOnboardReview";
import { campLeaderBoard } from "./CampLeaderBoardSaga";
import { CAMP_LEADERBOARD_FETCHING_DATA_ATTEMPT } from "../action/CampLeaderBoardAction";
import { rewardsRedeemedList } from "./RewardsRedeemedListSaga";
import { REWARDS_REDEEMED_LIST_FETCHING_DATA_ATTEMPT } from "../action/RewardsRedeemedList";
import { getRewardRedeemCert } from "./RewardRedeemCertificateSaga";
import { GET_REWARD_REDEEM_CERT_FETCHING_DATA_ATTEMPT } from "../action/RewardRedeemCertificateAction";
import { REWARD_REDEEM_FULFILMENT_FETCHING_DATA_ATTEMPT } from "../action/RewardRedeemFulfilmentAction";
import { rewardRedeemFulfilment } from "./RewardRedeemFulfilmentSaga";
import { SOCIAL_EMAIL_LOGIN_FETCHING_DATA_ATTEMPT } from "../action/LoginEmailAction";
import { socialEmailLogin } from "../saga/LoginEmailSaga";
import { ONBOARD_REWARDS_FETCHING_DATA_ATTEMPT } from "../action/onboardRewardsAction";
import { onboardRewards } from "../saga/onboardRewardsSaga";
import { addCampRewards } from "./addCampRewardsSaga";
import { ADD_CAMP_REWARDS_FETCHING_DATA_ATTEMPT } from "../action/addCampRewardsAction";
import { addCampRecognitions } from "./addCampRecognitionsSaga";
import { ADD_CAMP_RECOGNITIONS_FETCHING_DATA_ATTEMPT } from "../action/addCampRecognitionsAction";
import { addRecognitions } from "./addRecognitionsSaga";
import { ADD_RECOGNITIONS_FETCHING_DATA_ATTEMPT } from "../action/addRecognitionsAction";
import { onboardRecognitions } from "../saga/onboardRecognitionsSaga";
import { ONBOARD_RECOGNITIONS_FETCHING_DATA_ATTEMPT } from "../action/onboardRecognitionsAction";


// WatcherSaga
function* rootSaga() {
  yield takeLatest(REGISTER_FETCHING_DATA_ATTEMPT, register);
  yield takeLatest(SOCIAL_LOGIN_FETCHING_DATA_ATTEMPT, login);

  yield takeLatest(PROFILE_FETCHING_DATA_ATTEMPT, profileSaga);

  yield takeLatest(VERIFY_EMAIL_FETCHING_DATA_ATTEMPT, verifyEmail);
  yield takeLatest(VERIFY_OTP_FETCHING_DATA_ATTEMPT, verifyOTP);
  yield takeLatest(ONBOARD_INDIVIDUAL_FETCHING_DATA_ATTEMPT, onboardIndividual);
  yield takeLatest(ONBOARD_BULK_FETCHING_DATA_ATTEMPT, onboardBulk);
  yield takeLatest(ONBOARD_ACTIVITY_FETCHING_DATA_ATTEMPT, onboardActivity);
  yield takeLatest(
    ONBOARD_ORGANIZATION_FETCHING_DATA_ATTEMPT,
    onboardOrganization
  );
  yield takeLatest(ONBOARD_CAMPAIGN_FETCHING_DATA_ATTEMPT, onboardCampaign);
  yield takeLatest(
    GET_VOLUNTEER_COUNT_FETCHING_DATA_ATTEMPT,
    getVolunteerCount
  );
  yield takeLatest(GET_STATES_UT_FETCHING_DATA_ATTEMPT, getStatesUT);
  yield takeLatest(GET_CITY_FETCHING_DATA_ATTEMPT, getCity);
  yield takeLatest(
    GET_USER_PROFILE_IMAGE_FETCHING_DATA_ATTEMPT,
    getUserProfileImage
  );
  yield takeLatest(
    GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_ATTEMPT,
    getReportActivitySaga
  );
  yield takeLatest(
    GET_REPORT_USER_IN_STATE_FETCHING_DATA_ATTEMPT,
    getReportStateSaga
  );
  yield takeLatest(
    GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_ATTEMPT,
    getSOVolReportSaga
  );
  yield takeLatest(GET_VOLUNTEER_NAME_FETCHING_DATA_ATTEMPT, getVolunteerName);
  yield takeLatest(GET_ORGANIZATION_FETCHING_DATA_ATTEMPT, getOrganization);
  yield takeLatest(MY_REWARD_FETCHING_DATA_ATTEMPT, myReward);
  yield takeLatest(VALIDATOR_LIST_FETCHING_DATA_ATTEMPT, validatorList);
  yield takeLatest(VALIDATE_REWARD_FETCHING_DATA_ATTEMPT, validateReward);
  yield takeLatest(PURCHASE_FETCHING_DATA_ATTEMPT, purchaseYaag);
  yield takeLatest(UPDATE_CAMPAIGN_FETCHING_DATA_ATTEMPT, updateCampaign);
  yield takeLatest(DASHBOARD_REWARD_FETCHING_DATA_ATTEMPT, volDashBoardReward);
  yield takeLatest(GET_CAMPAIGN_FETCHING_DATA_ATTEMPT, getCampaign);
  yield takeLatest(UPDATE_ORGANIZATION_FETCHING_DATA_ATTEMPT, updateOrg);
  yield takeLatest(GET_ORG_PROFILE_LOGO_FETCHING_DATA_ATTEMPT, getOrgProfileLogo);
  yield takeLatest(GET_CAMPAIGN_PROFILE_LOGO_FETCHING_DATA_ATTEMPT, getCampProfileLogo);
  yield takeLatest(GET_CAMPAIGN_PROFILE_BANNER_FETCHING_DATA_ATTEMPT, getCampProfileBanner);
  yield takeLatest(REFERRAL_URL_FETCHING_DATA_ATTEMPT, referralUrl);
  yield takeLatest(YAAG_MY_COMMUNITY_FETCHING_DATA_ATTEMPT, yaagMyCommunity);
  yield takeLatest(TOP_ADOPTERS_FETCHING_DATA_ATTEMPT, topAdopters);
  yield takeLatest(MY_RANK_FETCHING_DATA_ATTEMPT, myRank);
  yield takeLatest(REFERRAL_FETCHING_DATA_ATTEMPT, referral);
  yield takeLatest(GET_REWARD_TITLE_FETCHING_DATA_ATTEMPT, getRewardTitle);
  // yield takeLatest(GET_REWARD_SUBTITLE_FETCHING_DATA, getRewardSubTitle);
  yield takeLatest(GET_CAMPAIGN_PROFILE_VIDEO_FETCHING_DATA_ATTEMPT, getCampProfileVideo);
  yield takeLatest(ADD_REWARDS_FETCHING_DATA_ATTEMPT, addRewards);
  yield takeLatest(ONBOARD_SPONSOR_FETCHING_DATA_ATTEMPT, onboardSponsor);
  yield takeLatest(UPDATE_SPONSOR_FETCHING_DATA_ATTEMPT, updateSponsor);
  yield takeLatest(UPDATE_REWARD_FETCHING_DATA_ATTEMPT, updateReward);
  yield takeLatest(GET_CAMPAIGN_FILES_FETCHING_DATA_ATTEMPT, getCampFiles);
  yield takeLatest(GET_CAMPAIGN_DOCS_FETCHING_DATA_ATTEMPT, getCampDocs);
  yield takeLatest(USER_ONBOARD_REVIEW_LIST_FETCHING_DATA_ATTEMPT, userreviewlist);
  yield takeLatest(USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_ATTEMPT, actionUserOnboardReview);
  yield takeLatest(ONBOARD_SELF_FETCHING_DATA_ATTEMPT, onboardSelf);
  yield takeLatest(CAMP_LEADERBOARD_FETCHING_DATA_ATTEMPT, campLeaderBoard);
  yield takeLatest(REWARDS_REDEEMED_LIST_FETCHING_DATA_ATTEMPT, rewardsRedeemedList);
  yield takeLatest(GET_REWARD_REDEEM_CERT_FETCHING_DATA_ATTEMPT, getRewardRedeemCert);
  yield takeLatest(REWARD_REDEEM_FULFILMENT_FETCHING_DATA_ATTEMPT, rewardRedeemFulfilment);
  yield takeLatest(SOCIAL_EMAIL_LOGIN_FETCHING_DATA_ATTEMPT, socialEmailLogin);
  yield takeLatest(ONBOARD_REWARDS_FETCHING_DATA_ATTEMPT, onboardRewards);
  yield takeLatest(ADD_CAMP_REWARDS_FETCHING_DATA_ATTEMPT, addCampRewards);
  yield takeLatest(ADD_CAMP_RECOGNITIONS_FETCHING_DATA_ATTEMPT, addCampRecognitions);
  yield takeLatest(ADD_RECOGNITIONS_FETCHING_DATA_ATTEMPT, addRecognitions);
  yield takeLatest(ONBOARD_RECOGNITIONS_FETCHING_DATA_ATTEMPT, onboardRecognitions);
  yield takeLatest(ONBOARD_REGFORM_FETCHING_DATA_ATTEMPT, onboardRegForm);
}

export default rootSaga;
