import * as React from "react";
import {
  Box,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getStatesUT } from "../redux/action/statesUTAction";
import { getCity, getCityReset } from "../redux/action/cityAction";
import {
  getReportUserInActivity,
  getReportUserInCity,
} from "../redux/action/getReport";

import ResponsiveDrawer from "../components/Header";
import { CopyRight } from "../components/CopyRight";
import TopHeaderScreen from "./TopHeader1";
import { Link, useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import { FixedFooterIcon } from "../components/FixedFooterIcon";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";

export default function DashboardScreen(props) {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(null);
  const [cityValue, setCityValue] = React.useState(null);
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { response: statesResponse } = useSelector((state) => state.states);
  const { isStateSuccess,  isActivitySuccess } = useSelector((state) => state.reportInfo);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (props?.response) {
      dispatch(getStatesUT(props?.response?.[0]?.nssrole));

      if (value) {
        dispatch(getCity(value));
      }
    }
  }, [props?.response, "All States"]);

  React.useEffect(() => {
    if (isActivitySuccess || isStateSuccess) {
      setLoading(false);
    }
  }, [isActivitySuccess, isStateSuccess]);

  const concatenatedData = statesResponse?.[0]?.concat(statesResponse?.[1]);
  const finalData = concatenatedData?.concat({ name: "All States" });

  {/* Total Vol for an org */}
  const sdk1 = new ChartsEmbedSDK ( {
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",

    // filter: {
    //   // "clientid": { "$eq": "c00001" }
    //   // "orgrole": { "$eq": "c00001" },
    //   // "organization": { "$eq": profileResponse?.organization }
    // }
        
  });
  
  const SOA_chart1 = sdk1.createChart({chartId: "64b122d5-5865-4120-8951-3da15427ac55", showAttribution: false});
  SOA_chart1.render(document.getElementById("SOA_chart1"));


  {/* Total Vol participated across org */}
  const sdk2 = new ChartsEmbedSDK ( {
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    
  });
  const SOA_chart2 = sdk2.createChart({chartId: "64b1396b-ce94-4ba9-842e-bcb8e8711516", showAttribution: false});
  
  SOA_chart2.render(document.getElementById("SOA_chart2"));
  
  
  {/* Location of CM's across globe */}
  const sdk3 = new ChartsEmbedSDK ( {
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    
  });
  const SOA_chart3 = sdk3.createChart({chartId: "64b13521-5865-454c-85e1-3da15431f5ef", showAttribution: false});
  SOA_chart3.render(document.getElementById("SOA_chart3"));
  
  {/* CM participation from across organization across campaigns */}
  const sdk4 = new ChartsEmbedSDK ( {
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    
  });
  const SOA_chart4 = sdk4.createChart({chartId: "64b13c5c-0ea1-4466-8f47-01f693dea340", showAttribution: false});
  SOA_chart4.render(document.getElementById("SOA_chart4"));

  
  {/* # of campaigns in a given SDG campaign category */}
  const sdk5 = new ChartsEmbedSDK ( {
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    
  });
  const SOA_chart5 = sdk5.createChart({chartId: "dd31fe1b-31ba-47b9-b219-0e949a1b339f", showAttribution: false});
  SOA_chart5.render(document.getElementById("SOA_chart5"));


  {/* Heat map - CM's on map across organizations */}
  const sdk6 = new ChartsEmbedSDK ( {
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    
  });
  const SOA_chart6 = sdk6.createChart({chartId: "518a323f-77a4-4313-ab2c-973d37a4a0d2", showAttribution: false});
  SOA_chart6.render(document.getElementById("SOA_chart6"));

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
          <Button
                onClick={() => navigate(-1)}
                className="backWrapper"
            >
                <i className={`fa fa-caret-left`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Overall Dashboard View
            </div>
            <br></br>
            <br></br>
            <header id="SOADashboardScreen1">
            <Box sx={{ minWidth: 200 }}>
            <div id="SOA_chart1" class="column chartMongo"> </div>
            <div id="SOA_chart2" class="column chartMongo"> </div>
            <div id="SOA_chart3" class="column chartMongo"> </div>
            <div id="SOA_chart4" class="column chartMongo"> </div>
            <div id="SOA_chart5" class="column chartMongo"> </div>
            <div id="SOA_chart6" class="column chartMongo"> </div>
            </Box>
            </header>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
}
