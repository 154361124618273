// import * as React, {useRef} from "react";
import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,

} from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";

import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import axios from "axios";
import _ from "lodash";
import TopHeaderScreen from "../../components/TopHeader1";
import { EditOutlined, RemoveRedEyeOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import {
  RewardsReportTableComponent,
} from "../../components/Table";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import { CSVLink, CSVDownload } from "react-csv";
import styled from 'styled-components';


const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));


function RewardsReport(props) {
  const navigate = useNavigate();

  const [activityList, setActivityList] = React.useState(undefined);

  React.useEffect(() => {
    // dispatch(getOrganization({ userid: localStorage.getItem("userid") }));
    getReportOnRewards();

  }, []);



  const getReportOnRewards = async () => {
    try {
      const res = await axios.get(`/rewards/usage/${localStorage.getItem("userid")}`);

      setActivityList(res?.data);
      return res;
    } catch (err) {

      throw err;
    }
  };

  const downloadDocument = () => {

    const pdf = new jsPDF("l", "pt", "a2");
    var width = pdf.internal.pageSize.getWidth()
    pdf.text('report', width / 2, 20)
    // const pdf = new jsPDF({
    //   orientation: "landscape",
    //   unit: "pt",
    //   format: [4, 2]
    // });
    pdf.html(document.querySelector("#SOADashboardScreen"), {
      callback: function (pdf) {
        var pageCount = pdf.internal.getNumberOfPages();
        // pdf.deletePage(pageCount);

        pdf.save("cyaag.pdf");
      }
    })
  }

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg" style={{marginLeft:"20px"}}>
            <Button
              onClick={() => navigate(-1)}
              className="backWrapper"
            >
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Report on Rewards</div>
            <div>

              <Button
                className="btn download-btn"
              >
                {
                  activityList &&
                  <CSVLink data={activityList}>
                    Download Report
                  </CSVLink>
                }
              </Button>

            </div>
            <div style={{ fontFamily: "monospace", fontSize: 16, }}>
            </div>

            <header id="SOADashboardScreen">
              <form autoComplete="off">
                <div style={{ border: "1px solid #dadce0", borderRadius: 8, backgroundColor: "#fff" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                  </div>

                  <div className="centerAlign" style={{ marginBottom: 50 }}>

                    <br />
                    <FormGroupFull>
                      <div id="showAll" style={{ overflow: "auto" }}>
                        <div id="downloadPdf">
                          <RewardsReportTableComponent rows={activityList} />
                        </div>
                      </div>
                    </FormGroupFull>
                  </div>
                </div>
              </form>
            </header>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>


    </div>
  );
}
export default RewardsReport;
