import React, { useState, useEffect , useRef} from 'react';
import {
  Button,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import _debounce from 'lodash.debounce';
import "react-datepicker/dist/react-datepicker.css";
import { onboardSelf, onboardSelfReset } from "../../redux/action/OnboardAction";
import { CopyRight } from "../../components/CopyRight";
import { getCity } from "../../redux/action/cityAction";
import {
  Gender,
  restrictedCountry,
} from "../../utils/dict";
import axios from "axios";
import RedStar from "../../components/RedStar";
import BasicPopover from "../../components/InformationPopup";
import ErrorMessage from "../../components/ErrorMessage";
import { useNavigate, useLocation } from "react-router-dom";
import _, { set } from "lodash";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import DoneIcon from "@mui/icons-material/Done";
import LoginLogo from "../../assets/sdp.jpeg";
import PhoneIcon from "@mui/icons-material/Phone";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import styled from 'styled-components';

const FormGroup = styled('div')(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: '40px',
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroupFull
const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup1
const FormGroup1 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup2
const FormGroup2 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  flexDirection: 'column',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '75px 0 0 16px',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup3
const FormGroup3 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  width: '20%',
  '@media (max-width: 1200px)': {
    width: '20%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup4
const FormGroup4 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'right',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 0',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup7
const FormGroup7 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup8
const FormGroup8 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledLabel
const StyledLabel = styled('label')(({ theme }) => ({
  display: 'flex',
  background: '#e9e7e7',
  padding: '10px',
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontWeight: 600,
  marginTop: '16px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

// StyledSpan
const StyledSpan = styled('span')(() => ({
  fontSize: '12px',
}));

// FocusThematic
const FocusThematic = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledInput
const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// StyledTextarea
const StyledTextarea = styled('textarea')(() => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// LogoButton
const LogoButton = styled('input')(() => ({
  width: '8%',
  border: 'none',
  padding: '10px',
  display: 'flex',
  height: '40px',
  color: 'white',
  '@media (max-width: 1920px)': {
    fontSize: '15px',
    width: '12%',
  },
  '@media (max-width: 1280px)': {
    fontSize: '15px',
    width: '15%',
  },
  '@media (max-width: 960px)': {
    fontSize: '15px',
    width: '20%',
  },
  '@media (max-width: 600px)': {
    fontSize: '15px',
    width: '30%',
  },
}));

// StyledTypography
const StyledTypography = styled(Typography)(({ theme }) => ({
  '& .css-u4tvz2-MuiFormLabel-root': {
    fontFamily: 'Poppins',
  },
  display: 'block',
  display: 'flex',
  fontSize: '16px',
  marginTop: '16px',
  fontWeight: 500,
  lineHeight: '18px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

const LoginPgLogo = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (max-width: 768px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
}));

const SelfRegisterGetOTpopUpContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  // bottom: '142px',
  width: '100%',
  // padding: '46px',
  // backgroundColor: 'wheat',
  // boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  justifyContent: 'center',
  animation: 'SelfRegisterGetOTPpopUp 0.5s ease-out',
  borderRadius: '10px',
}));

const form = [
  { id: 1, formQuestion: "First Name" },
  { id: 2, formQuestion: "Middle Name" },
  { id: 3, formQuestion: "Last Name" },
  { id: 4, formQuestion: "Father Name" },
  { id: 5, formQuestion: "Date of Birth" },
  { id: 6, formQuestion: "Adhaar No" },
  { id: 7, formQuestion: "Category" },
  { id: 8, formQuestion: "Gender" },
  { id: 9, formQuestion: "Blood Group" },
  { id: 10, formQuestion: "Present Address" },
  { id: 11, formQuestion: "Permanent Address" },
  { id: 12, formQuestion: "Email Address" },
  {
    id: 13,
    formQuestion: "Session",
  },
  { id: 14, formQuestion: "Roll Number" },
  { id: 15, formQuestion: "Mobile Number" },
  {
    id: 16,
    formQuestion: "Institute Name",
  },
  { id: 17, formQuestion: "University Name" },
  {
    id: 19,
    formQuestion: "State",
  },
  { id: 18, formQuestion: "City/Town" },
  { id: 20, formQuestion: "Organization" },
  { id: 21, formQuestion: "Organization Role" },
  { id: 22, formQuestion: "Organization Unit" },
];


// const useOutlinedInputStyles = makeStyles((theme) => ({
//   root: {
//     "&:hover $notchedOutline": {
//       borderColor: "gray",
//     },
//     "&$focused $notchedOutline": {
//       borderColor: "gray",
//     },
//     "&.Mui-error .MuiOutlinedInput-notchedOutline": {
//       borderColor: "gray"
//     },
//     '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
//       borderColor: "gray"
//     },

//   },

//   error: {
//     "&$notchedOutline": {
//       borderColor: "black",
//     },
//     "&:hover $notchedOutline": {
//       borderColor: "black",
//     },
//     "&$focused $notchedOutline": {
//       borderColor: "black",
//     }

//   },
//   focused: {},
//   notchedOutline: {},
// }));

const suggestionActiveStyle = {
  padding: "10px",
  cursor: "pointer",
  backgroundColor: "#007BFF",
  color: "#fff",
};

const suggestionStyle = {
  padding: "10px",
  cursor: "pointer",
  backgroundColor: "#fff",
  color: "#000",
  "&:hover": {
    backgroundColor: "#f0f0f0",
    color: "#000",
  },
};


function PGRegisterScreenTemp(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [registerCallingCode, setRegisterCallingCode] = React.useState("+91");
  const [value1, setValue1] = React.useState("");
  const [value1Error, setValue1Error] = React.useState(false);
  const [lastname, setLastname] = React.useState("");
  const [lastnameError, setLastnameError] = React.useState(false);
  const [value2, setValue2] = React.useState("");
  const [value3, setValue3] = React.useState("");
  const [value4, setValue4] = React.useState("");
  const [value5, setValue5] = React.useState(
    new Date().setFullYear(new Date().getFullYear() - 5)
  );
  const [gender, setGender] = React.useState("");
  const [genderError, setGenderError] = React.useState(false);
  const [value8, setValue8] = React.useState("");
  const [value9, setValue9] = React.useState("");
  const [value10, setValue10] = React.useState("");
  const [value11, setValue11] = React.useState("");
  const [value12, setValue12] = React.useState("");
  const [value12Error, setValue12Error] = React.useState(false);
  const [value12DupError, setValue12DupError] = React.useState(false);
  const [emailTextError, setEmailTextError] = React.useState("");
  const [value13, setValue13] = React.useState("");
  const [value14, setValue14] = React.useState("");
  const [value15, setValue15] = React.useState("");
  const [value15Error, setValue15Error] = React.useState(false);
  const [value16, setValue16] = React.useState("");
  const [countryError, setCountryError] = React.useState(false);
  const [value18, setValue18] = React.useState("");
  const [value18Error, setValue18Error] = React.useState(false);
  const [value19, setValue19] = React.useState("");
  const [value19Error, setValue19Error] = React.useState(false);
  const [areaError, setAreaError] = React.useState(false);
  const [value20, setValue20] = React.useState("");
  const [value21, setValue21] = React.useState("");
  const [value22, setValue22] = React.useState("");
  const [highLevelEdu, setHighLevelEdu] = React.useState("");
  const [noOfHours, setNoOfHours] = React.useState("");
  const [preferDays, setPreferDays] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [correspondenceaddress, setcorrespendeceaddress] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [area, setArea] = React.useState("");
  const [added, setAdded] = React.useState("no");
  const [isAddressComplete, setIsAddressComplete] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  // const outlinedInputClasses = useOutlinedInputStyles();
  const [selected, setSelected] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [emailExistCheck, setEmailExistCheck] = useState(false);
  const [showGetOTPButton, setShowGetOTPButton] = useState(false);
  const [confirmAddressClicked, setConfirmAddressClicked] = useState(false);
  const [phonenoExistCheck, setPhonenoExistCheck] = useState(false);
  const [userData, setUserData] = React.useState([]);
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);
  const [showEmailField, setShowEmailField] = useState(false);
  const [showAllFields, setShowAllFields] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();
  const { selectedIndex } = location.state || {};
  const emailInputRef = useRef(null);
  const topRef = useRef(null);

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const {
    selfOnboardResponse,
    isSelfOnboardSuccess,
    isSelfOnboardError,
    isSelfOnboardFetching,
  } = useSelector((state) => state.onBoard);




  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );


  const handleOnboardIndividual = async () => {
    
    navigate(`/verifypg`, { state: { selectedIndex } });
      
  };

  const checkEmail = _debounce(async (emailValue) => {
    try {
      
      if (emailValue !== "" && emailValue !== undefined) {
        const res = await axios.get(`/email/val/${emailValue}`);
        console.log("checkEmail:res?.data?.message = ", res?.data?.message);
        console.log("pattern.test(emailValue)= ", pattern.test(emailValue))

        if (!emailValue) {
          setEmailTextError(false);
          setValue12Error(true);
        } else if (!pattern.test(emailValue) && res?.data?.message === "Invalid") {
          setValue12Error(false);
          setEmailTextError(true);
          // setShowAllFields(false);
          setIsFieldsDisabled(false);
        } else if (!pattern.test(emailValue) && res?.data?.message === "Valid") {
          setValue12Error(false);
          setEmailTextError(true);
          setShowAllFields(true);
          setIsFieldsDisabled(false);
        }
        else if (pattern.test(emailValue) && res?.data?.message === "Valid") {
          setValue12Error(false);
          setEmailTextError(false);
          setValue12DupError(false);
          setShowAllFields(true);
          setIsFieldsDisabled(false);
        }
        else if (pattern.test(emailValue) && res?.data?.message === "Exist") {
          setValue12Error(false);
          setEmailTextError(false);
          setValue12DupError(true);
          setShowAllFields(true);
          setIsFieldsDisabled(true);
        }
      } else {
        console.log("else emailValue = ", emailValue !== "");
        console.log("else emailValue = ", emailValue !== undefined);
        setValue12Error(true);
        setEmailTextError(false);
      }
    } catch (err) {
      setEmailTextError(false);
      setValue12Error(true);
      throw err;
    }
  }, 300);

  
  return (
    <div className="wrapper" ref={topRef}>
      <div class="content content-wrap">
        <LoginPgLogo style={{ textAlign: "center", marginTop: "10px" }}>
          <img
            src={LoginLogo}
            style={{
              height: 56,
            }}
          />
          <figcaption>Powered by cYAAG</figcaption>
        </LoginPgLogo>
        <div class="content-mrg" style={{ background: "#fff" }}>
          <div style={{ maxWidth: "1100px", margin: "0 auto" }}>
            <div style={{ fontFamily: "monospace", fontSize: 32 }}>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginRight: 5,
                }}
              >
                <span class="selfregistertitle" style={{ color: "black" }}>
                  {" "}
                  Payment Form
                </span>
              </div>
            </div>
            <form autoComplete="off" style={{ height: isMobile ? '120vh' : '180vh' }}>
              <div style={{ border: "1px solid #dadce0", borderRadius: 8, backgroundColor: "oldlace", padding: "10px" }} >
                <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }} >                    
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 customFieldWidth" id="emailId" style={{margin: 'auto', maxWidth: isMobile? '100%': '50%'}}>
                      <StyledLabel>
                        {form[11]?.formQuestion}

                        <RedStar />
                        
                      </StyledLabel>
                      <StyledInput
                        value={value12}
                        type="text"
                        id="create-yaad--title"
                        ref={emailInputRef}
                        placeholder={"Enter your organization or personal email"}
                        onChange={(e) => {
                          setValue12(e.target.value);
                          checkEmail(e.target.value);
                          // checkDupEmail(e.target.value);
                        }}
                        // disabled={isFieldsDisabled}
                        onBlur={() => {
                          console.log("value12 = ", value12)
                          if (!value12) {
                            setValue12Error(true);
                          } else {
                            setValue12Error(false);
                          }
                        }}
                      />
                      {value12Error && !emailTextError && (
                        <ErrorMessage message="Please enter email address" />
                      )}
                      {!value12Error && emailTextError && (
                        <ErrorMessage message="Please enter valid email address" />
                      )}
                      {!value12Error && value12DupError && (
                        <ErrorMessage message="Please enter unique email address" />
                      )}
                    </div>
                  </div>
                  <SelfRegisterGetOTpopUpContainer>
                    <Button
                      style={{ marginTop: '53px' }}
                      variant="contained"
                      className="btn save-btn"
                      disableElevation
                      onClick={handleOnboardIndividual}
                      disabled={isSelfOnboardFetching}
                    >
                      <span>Review Payment</span>
                    </Button>
                    <br />
                  </SelfRegisterGetOTpopUpContainer>
                </div>
            </form>
          </div>
        </div>
      </div >
      <CopyRight />
    </div >
  );
}
export default PGRegisterScreenTemp;
