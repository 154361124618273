import React, {useEffect, useRef} from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {
  addRecognitionsReset,
  addRecognitions,
} from "../../redux/action/addRecognitionsAction";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";
import {
  AddCircleOutlineOutlined,
  RemoveCircleOutlineOutlined,
} from "@mui/icons-material";
import ErrorMessage from "../../components/ErrorMessage";
import _ from "lodash";
import RedStar from "../../components/RedStar";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link, useNavigate } from "react-router-dom";
import { getRewardTitle } from "../../redux/action/RewardDetailAction";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import AsyncSelect from "react-select/async";
import { isArray } from "lodash";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import styled from 'styled-components';

const HomeScreenMainContent = styled(Box)(({ theme }) => ({
  padding: '80px 0 100px 0px',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    padding: '80px 0 100px 330px',
  },
})); 

const FormGroup0 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#F0F8FF',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// Styled component for formGroup00
const FormGroup00 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#FAEBD7',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

const FormGroup = styled('div')(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: '40px',
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));


const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup1
const FormGroup1 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup2
const FormGroup2 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  flexDirection: 'column',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '75px 0 0 16px',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup3
const FormGroup3 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  width: '20%',
  '@media (max-width: 1200px)': {
    width: '20%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup4
const FormGroup4 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'right',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 0',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup7
const FormGroup7 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup8
const FormGroup8 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledLabel
const StyledLabel = styled('label')(({ theme }) => ({
  display: 'flex',
  background: '#e9e7e7',
  padding: '10px',
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontWeight: 600,
  marginTop: '16px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

// StyledSpan
const StyledSpan = styled('span')(() => ({
  fontSize: '12px',
}));

// FocusThematic
const FocusThematic = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledInput
const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// StyledTextarea
const StyledTextarea = styled('textarea')(() => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// LogoButton
const LogoButton = styled('input')(() => ({
  width: '8%',
  border: 'none',
  padding: '10px',
  display: 'flex',
  height: '40px',
  color: 'white',
  '@media (max-width: 1920px)': {
    fontSize: '15px',
    width: '12%',
  },
  '@media (max-width: 1280px)': {
    fontSize: '15px',
    width: '15%',
  },
  '@media (max-width: 960px)': {
    fontSize: '15px',
    width: '20%',
  },
  '@media (max-width: 600px)': {
    fontSize: '15px',
    width: '30%',
  },
}));

// StyledTypography
const StyledTypography = styled(Typography)(({ theme }) => ({
  '& .css-u4tvz2-MuiFormLabel-root': {
    fontFamily: 'Poppins',
  },
  display: 'block',
  display: 'flex',
  fontSize: '16px',
  marginTop: '16px',
  fontWeight: 500,
  lineHeight: '18px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

const LoginPgLogo = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (max-width: 768px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
}));

const StyledLargeAvatar = styled(Avatar)(({ theme }) => ({
  width: '200px !important',
  height: '200px !important',
  margin: '0 auto',
  marginTop: '5px',
  marginBottom: '5px',
  '@media (max-width: 400px)': {
    width: '120px !important',
    height: '120px !important',
  },
  '@media (max-width: 600px)': {
    width: '140px !important',
    height: '140px !important',
  },
  '@media (max-width: 768px)': {
    width: '150px !important',
    height: '150px !important',
  },
}));

function AdminPFRecognitions(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rewardtitle, setRewardtitle] = React.useState([
    {
      rewardchoice: "",
      rewardtype: "",
      title: "",
      subtitle: "",
      availablebudget: "",
      allocatedbudget: "",
      points: "",
      pf: "",
      auto: "",
      camp: "",
      count: "",
      titleList: "",
      // forhowmany:"",
    },
  ]);
  
  const [sponsor, setSponsor] = React.useState([]);
  
  const [rewardTypeList, setRewardTypeList] = React.useState(null);
  const [rewardChoice, setRewardChoice] = React.useState([
    {
      choice: "1",
    },
    {
      choice: "2",
    },
    {
      choice: "3",
    },
    {
      choice: "5",
    },
    {
      choice: "10",
    },
    {
      choice: "All",
    },
  ]);
  const [rewardDetailResponseEffect, setrewardDetailResponseEffect] = React.useState({});
  const topRef = useRef(null);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const [displayConfirmationModal, setDisplayConfirmationModal] =  React.useState(false);
  const [addRewardsValues, setaddRewardsValues] = React.useState({
    rewardtype: "",
    title: "",
    subtitle: "",
    sponsor: "",
    points: "",
    pf: "",
    camp: "",
    auto: "",
    count: "",
  });
  const [addRewardsValuesError, setaddRewardsValuesError] = React.useState({
    rewardtypeError: "",
    titleError: "",
    subtitleError: "",
    sponsorError: "",
    allocatedbudgetError: "",
    pointsError: "",
    pfError: "",
    campError: "",
    autoError: "",
    countError: "",
  });
  const [campName, setCampName] = React.useState([]);
  const [globalIndex, setGlobalIndex] = React.useState(0);
  const [selectedcampname, setselectedcampname] = React.useState([]);
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const {
    titleSuccess: rewardDetailSuccess,
    titleResponse: rewardDetailResponse,
  } = useSelector((state) => state.getRewardTitle);
  

  const {
    addrecognitionsSuccess: isAddRewardsSuccess,
    addrecognitionsResponse: addRewardsResponse,
    addrecognitionsError: isAddRewardsError,
    addrecognitionsFetching: isAddrewardsFetching,
  } = useSelector((state) => state.addRecognitions);


  const handleChangeRewards1 = (id, event, field, index) => {
  
    // const value = event.target.value;
    const value = [...rewardtitle];
    if (field === "rewardtype") {
      value[index]["titleList"] = rewardDetailResponseEffect;
    }
    if (field === "sponsor") {
      value[index][field] = event;
    } else if (field === "camp") {
      value[index][field] = event[0]["label"];
    } else {
      value[index][field] = event.target.value;
    }
    setRewardtitle(value);
   

    // setSelected([...selected, value[value?.length - 1]]);
  };

  const handleAddRewards = () => {
    
  
    // console.log(rewardtypeError);
    // console.log(titleError);
    // console.log(subtitleError);
    // console.log(sponsorError);
    // console.log(pointsError);
    // if (addRewardsValues.rewardtype) {
    //   setaddRewardsValuesError({
    //     ...addRewardsValuesError,
    //     rewardtypeError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else if (!addRewardsValues?.subtitle) {
    //   setaddRewardsValuesError({
    //     ...addRewardsValuesError,
    //     subtitleError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else if (!addRewardsValues?.sponsor) {
    //   setaddRewardsValuesError({
    //     ...addRewardsValuesError,
    //     sponsorError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else if (!addRewardsValues?.points) {
    //   setaddRewardsValuesError({
    //     ...addRewardsValuesError,
    //     pointsError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else {
    
    let formData = new FormData();
    formData.append("rewardtitle", JSON.stringify(rewardtitle));
    dispatch(
      // onboardCampaign({ userid: localStorage.getItem("userid"), formData })
      addRecognitions({ userid: localStorage.getItem("userid"), formData })
    );
    setDisplayConfirmationModal(true);
    dispatch(addRecognitionsReset());
    setRewardtitle([
      {
        rewardtype: "",
        title: "",
        subtitle: "",
        availablebudget: "",
        allocatedbudget: "",
        points: "",
        pf: "",
        auto: "",
        camp: "",
        count: "",
        // forhowmany:"",
        // titleList: "",
      },
    
    ]);
    setselectedcampname("");
    //  }
  };

  const getRewardDetailList = async (i, valll) => {
    try {
      const res = await axios
        .get(`/getrewardtitle/${valll}`)
        .then((response) => {
          const values = [...rewardtitle];
          values[i]["titleList"] = response?.data;
          return response?.data;
        });
    } catch (err) {
      throw err;
    }
  };

  const getSponsorList = async () => {
    try {
      const res = await axios.get(`/sponsor`).then((response) => {
        // const values = [...rewardtitle];

        setSponsor(response?.data);
        return response?.data;
      });
    } catch (err) {
      throw err;
    }
  };

  const getRewardTypeList = async () => {
    try {
      const res = await axios.get(`getrewardtypes`);
      setRewardTypeList(res?.data);
     
      return res;
      // }
    } catch (err) {
      throw err;
    }
  };

  
  const getPFAvailableBudget = async (i) => {

    try {
      const res = await axios
        .get(`/pf/availbudget`)
        .then((response) => {
          const values = [...rewardtitle];
          // values[i]["camp"] = response?.data?.[0]?.title;
          values[i]["availablebudget"] = response?.data?.[0]?.totalbudget;
          // values[i]["titleList"] = response?.data;
          setRewardtitle(values);
       
          return response?.data;
        });
    } catch (err) {
      throw err;
    }
  };

  const getCountForPoints = async (i, allocatedbudget) => {
  
    try {
      const res = await axios
        .get(
          `/count/forpoints/${selectedcampname[0]["label"]}/${allocatedbudget}`
        )
        .then((response) => {
          const values = [...rewardtitle];
          // values[i]["camp"] = response?.data?.[0]?.title;
          values[i]["points"] = response?.data?.[0]?.points;
          values[i]["count"] = response?.data?.[0]?.count;
          // values[i]["titleList"] = response?.data;
          setRewardtitle(values);
        
          return response?.data;
        });
    } catch (err) {
      throw err;
    }
  };

  const handleRewardAddFields = () => {
  

    // console.log("index = ", index)
    // setNewRewardField(true);
    if (rewardtitle.length > 0) {
     
      for (let i = 0; i < rewardtitle.length; i++) {
        
        if ( !(rewardtitle?.[rewardtitle.length - 1].rewardtype === "") && !(rewardtitle?.[rewardtitle.length - 1].title === "")
          && !(rewardtitle?.[rewardtitle.length - 1].subtitle === "") && !(rewardtitle?.[rewardtitle.length - 1].allocatedbudget === "")
          && !(rewardtitle?.[rewardtitle.length - 1].points === "") && !(rewardtitle?.[rewardtitle.length - 1].count === "") )
            {
            // setselectedcampname("")
            setGlobalIndex(i+1);
          setRewardtitle([
            ...rewardtitle,
            {
              rewardtype: "",
              title: "",
              subtitle: "",
              availablebudget: "",
              allocatedbudget: "",
              points: "",
              pf: "",
              auto: "",
              camp: "",
              count: "",
              titleList: "",
              // forhowmany:"",
            },
          ]);
        } else{
          
        }
      }
    }
    
  };

  const handleRemoveFields = (i) => {
    let newFormValues = [...rewardtitle];
    newFormValues.splice(i, 1);
    setRewardtitle(newFormValues);
  };
 

  React.useEffect(() => {
    getPFAvailableBudget(globalIndex);
    // getCampAvailableBudget();
  }, [globalIndex]); 



  const getRewardDetails = (itemreward, index) => {
    return (
      <div style={{ float: "left", width: "75%", marginTop: 50 }}>
        <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>
        Recognition Details
        </span>{" "}
        <form
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            float: "left",
          }}
        >
          <FormGroup1>
            <StyledLabel style={{display:'inline-flex'}}>
              Available Budget <RedStar/>
            </StyledLabel>

            <StyledInput
              disabled
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                border: "1px solid",
              }}
              value={itemreward?.availablebudget}
              type="number"
              id="create-yaad--title"
               
              placeholder="Budget"
            />
          </FormGroup1>

          <FormGroup1>
            <StyledLabel style={{display:'inline-flex'}}>
              Budget allocated
              <RedStar />
            </StyledLabel>
            <StyledInput
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                border: "1px solid",
              }}
              value={itemreward?.allocatedbudget}
              // defaultValue={itemreward.sponsorBudget}
              type="number"
              id="create-yaad--title"
               
              placeholder="Budget"
              onBlur={() => {
                if (!itemreward.allocatedbudget) {
                
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    allocatedbudgetError: true,
                  });
                } else {
                  if (
                    Number(itemreward?.allocatedbudget) >
                    rewardtitle?.[index]?.sponsorname?.[0]?.availablebudget
                  ) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      allocatedbudgetError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      allocatedbudgetError: false,
                    });
                  }
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    allocatedbudgetError: false,
                  });
                }
                
              }}
              onChange={(e) => {
                handleChangeRewards1(itemreward, e, "allocatedbudget", index);
              }}
            />
            {addRewardsValuesError?.allocatedbudgetError && (
              <ErrorMessage message="Please enter the budget to be allocated for this reward" />
            )}
            {addRewardsValuesError.allocatedbudgetError && (
              <ErrorMessage message="You have exceeded the budget to be allocated" />
            )}
          </FormGroup1>

          <FormGroup1>
            <StyledLabel style={{display:'inline-flex'}}>
              Points 
              <RedStar />
            </StyledLabel>
            <StyledInput
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                border: "1px solid",
              }}
              value={itemreward?.points}
              type="number"
              id="create-yaad--title"
               
              placeholder="Points"
              onBlur={() => {
                if (!itemreward.points) {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    pointsError: true,
                  });
                } else {
                  if (!Number(itemreward.points)) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      pointsError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      pointsError: false,
                    });
                  }
                }
              }}
              onChange={(e) =>
                handleChangeRewards1(itemreward, e, "points", index)
              }
            />
            {addRewardsValuesError?.pointsError && (
              <ErrorMessage message="Please enter the points for this reward" />
            )}
          </FormGroup1>

          <FormGroup1>
            <StyledLabel style={{display:'inline-flex'}}>
              Count <RedStar />
            </StyledLabel>
            <StyledInput
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
              value={itemreward?.count}
              type="number"
              id="create-yaad--title"
               
              placeholder="Count"
              onBlur={() => {
                if (!itemreward.count) {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    countError: true,
                  });
                } else {
                  if (!Number(itemreward.count)) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      countError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      countError: false,
                    });
                  }
                }
              }}
              onChange={(e) =>
                handleChangeRewards1(itemreward, e, "count", index)
              }
            />
            {addRewardsValuesError?.pointsError && (
              <ErrorMessage message="Please enter the count for this reward" />
            )}
          </FormGroup1>
          <FormGroup1>
            <StyledLabel style={{display:'inline-flex'}}>
              Recognition Type <RedStar />
            </StyledLabel>
            <Select
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
              value={itemreward.rewardtype}
              onBlur={() => {
                if (!itemreward.rewardtype) {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    rewardtypeError: true,
                  });
                } else {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    rewardtypeError: false,
                  });
                }
              }}
              onChange={(e) => {
                handleChangeRewards1(itemreward, e, "rewardtype", index);
                // dispatch(getRewardTitle(item.rewards));
            
                getRewardDetailList(index, itemreward.rewardtype);
                dispatch(getRewardTitle(itemreward.rewardtype));
               
              }}
            >
              {rewardTypeList &&
                rewardTypeList.map((item, index) => (
                  <MenuItem style={{ fontSize: 16 }} value={item.rewards}>
                    {item.rewards}
                  </MenuItem>
                ))}
            </Select>
            {addRewardsValuesError?.rewardtypeError && (
              <ErrorMessage message="Please enter the recognition type for this reward" />
            )}
          </FormGroup1>
          {isArray(rewardtitle) && 
          index !== rewardtitle.length - 1 ||
          (index === rewardtitle.length - 1 &&
            rewardtitle[index].title !== "") ||
          index === 0 ? (
            <FormGroup1>
              <StyledLabel style={{display:'inline-flex'}}>
                Recognition Title <RedStar />
              </StyledLabel>
              <Select
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
                // value={itemreward?.titlelist?.[0].title}
                value={itemreward.title}
                onBlur={() => {
                  if (!itemreward.title) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      titleError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      titleError: false,
                    });
                  }
                }}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "title", index);
                }}
              >
                {rewardDetailSuccess && isArray(rewardtitle[index]?.titleList) && 
                  rewardtitle[index]?.titleList.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.title}>
                      {item.title}
                    </MenuItem>
                  ))}
              </Select>
              {addRewardsValuesError?.titleError && (
                <ErrorMessage message="Please enter the title for this reward" />
              )}
            </FormGroup1>
          ) : (
            <FormGroup1>
              <StyledLabel style={{display:'inline-flex'}}>
                Recognition Title <RedStar />
              </StyledLabel>
              <Select
                // style={{
                //   width: "50%",
                //   fontSize: 14,
                //   height: 56,
                //   boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                // }}

                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
                // value={itemreward?.titlelist?.[0].title}
                value={itemreward.title}
                onBlur={() => {
                  if (!itemreward.title) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      titleError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      titleError: false,
                    });
                  }
                }}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "title", index);
                }}
              >
                {rewardDetailSuccess &&
                  rewardDetailResponse.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.title}>
                      {item.title}
                    </MenuItem>
                  ))}
              </Select>
              {addRewardsValuesError?.titleError && (
                <ErrorMessage message="Please enter the title for this reward" />
              )}
            </FormGroup1>
          )}

          { isArray(rewardtitle) &&
          index !== rewardtitle.length - 1 ||
          (index === rewardtitle.length - 1 &&
            rewardtitle[index].subtitle !== "") ||
          index === 0 ? (
            <FormGroup1>
              <StyledLabel style={{display:'inline-flex'}}>
              Recognition Sub Title <RedStar />
              </StyledLabel>
              <Select
                // style={{
                //   width: "50%",
                //   fontSize: 14,
                //   height: 56,
                //   boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                // }}

                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
                // value={itemreward?.titlelist?.[0].subtitle}
                // value={itemreward.subtitle}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "subtitle", index);
                }}
                onBlur={() => {
                  if (!itemreward.subtitle) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      subtitleError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      subtitleError: false,
                    });
                  }
                }}
              >
                {rewardDetailSuccess && isArray(rewardtitle[index]?.titleList) && 
                  rewardtitle[index]?.titleList.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.subtitle}>
                      {item.subtitle}
                    </MenuItem>
                  ))}
              </Select>
              {addRewardsValuesError?.subtitleError && (
                <ErrorMessage message="Please enter the subtitle for this reward" />
              )}
            </FormGroup1>
          ) : (
            <FormGroup1>
              <StyledLabel style={{display:'inline-flex'}}>
              Recognition Sub Title <RedStar />
              </StyledLabel>
              <Select
                // style={{
                //   width: "50%",
                //   fontSize: 14,
                //   height: 56,
                //   boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                // }}

                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
                // value={itemreward?.titlelist?.[0].subtitle}
                // value={itemreward.subtitle}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "subtitle", index);
                }}
                onBlur={() => {
                  if (!itemreward.subtitle) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      subtitleError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      subtitleError: false,
                    });
                  }
                }}
              >
                {rewardDetailSuccess &&
                  rewardDetailResponse.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.subtitle}>
                      {item.subtitle}
                    </MenuItem>
                  ))}
              </Select>
              {addRewardsValuesError?.subtitleError && (
                <ErrorMessage message="Please enter the subtitle for this reward" />
              )}
            </FormGroup1>
          )}

        </form>
      </div>
    );
  };

  React.useEffect(() => {
    setRewardtitle([
      {
        rewardtype: "",
        title: "",
        subtitle: "",
        availablebudget: "",
        allocatedbudget: "",
        points: "",
        pf: "",
        auto: "",
        camp: "",
        count: "",
        titleList: "",
        // forhowmany:"",
      },
    ]);
  }, []);

  React.useEffect(() => {
    getRewardTypeList();
  }, []);
 

  React.useEffect(() => {
    getSponsorList();
  }, []);


  return (
    <div className="wrapper"ref={topRef}>
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg" style={{marginLeft:'20px'}}>
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Add Platform Recognition
            </div>
            <div style={{ fontFamily: "monospace", fontSize: 16 }}>
              <div
                style={{
                  display: "flex",
                  width: "75%",
                  justifyContent: "flex-end",
                  marginRight: 5,
                  marginBottom: 20,
                }}
              >
                <span style={{ color: "red" }}>
                  Please do not leave this page until you submit as you may risk
                  losing the partially filled in form{" "}
                </span>
              </div>
            </div>
            <form autoComplete="off">
              <div
                style={{
                  border: "1px solid #dadce0",
                  borderRadius: 8,
                  backgroundColor: "#fff",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <FormGroupFull>
                    {rewardtitle.map((itemreward, index) => (
                      <div key={index}>
                        {getRewardDetails(itemreward, index)}
                      </div>
                    ))}
                  </FormGroupFull>
                </div>
                {/* <div>
                  {isAddRewardsSuccess && (
                    <span
                      style={{
                        color: "green",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      {addRewardsResponse?.message}
                    </span>
                  )}
                  {isAddRewardsError && (
                    // <span style={{ color: "red" }}>Error occured</span>
                    <span
                      style={{
                        color: "red",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      {addRewardsResponse?.message}
                    </span>
                  )}
                </div> */}
                <div
                  className="centerAlign"
                  style={{ padding: "16px 0", marginBottom: 50 }}
                >
                  <Button
                    variant="contained"
                    onClick={handleAddRewards}
                    className="btn save-btn"
                    disableElevation
                    disabled={isAddrewardsFetching}
                  >
                    {isAddrewardsFetching ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      <span>Submit</span>
                    )}
                  </Button>
                  <br />
                  {/* {isCampaignSuccess && 
                  <span style={{ color: "green", textAlign: "center", display: "block" }}>{campaignResponse?.message}</span>}
                  {isCampaignError && (
                    // <span style={{ color: "red" }}>Error occured</span>
                    <span style={{ color: "red", textAlign: "center", display: "block" }}>{campaignResponse?.message}</span>
                  )} */}
                </div>
              </div>
            </form>
          </div>
        </div>
        <CopyRight />

        <FixedFooterIcon />
        <ConfirmationDialog
          showModal={displayConfirmationModal}
          // confirmModal={handleRedeem}
          hideModal={hideConfirmationModal}
          // message={"You do not have enough points to redeem"}
          message={addRewardsResponse?.message}
        />
      </section>
    </div>
  );
}
export default AdminPFRecognitions;
