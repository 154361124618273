// import * as React, {useRef} from "react";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
} from "@mui/material";

import "react-datepicker/dist/react-datepicker.css";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import axios from "axios";
import _ from "lodash";
import TopHeaderScreen from "../../components/TopHeader1";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import { SponsorBasedReportTableComponent } from "../../components/Table";
import { CSVLink, CSVDownload } from "react-csv";
import { styled } from '@mui/material/styles';

const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));


function PFRewardsReport(props) {
  const navigate = useNavigate();
  const [pfrewards, setPFrewards] = React.useState(undefined);
  const [pfrewardsvalue, setPFrewardsValue] = React.useState(undefined);
  React.useEffect(() => {
    getPFRewardsReport();
    getPFRewardsValue();
  }, []);

  
  
  const getPFRewardsReport = async () => {
    try {
      const res = await axios.get(
        `/sponsor/dashboard/all/${localStorage.getItem("userid")}`
      );
      
      setPFrewards(res?.data);
      return res;
    } catch (err) {
      
      throw err;
    }
  };

  const getPFRewardsValue = async () => {
    try {
      const res = await axios.get(
        `/pa/pf/rewards/total/${localStorage.getItem("userid")}`
      );
     
      setPFrewardsValue(res?.data);
      return res;
    } catch (err) {
     
      throw err;
    }
  };
  
  const downloadDocument = () => {
    
    const pdf = new jsPDF("l", "pt", "a2");
    // const pdf = new jsPDF({
    //   orientation: "landscape",
    //   unit: "pt",
    //   format: [4, 2]
    // });
    pdf.html(document.querySelector("#downloadPdf"), {
      callback: function (pdf) {
        var pageCount = pdf.internal.getNumberOfPages();
        // pdf.deletePage(pageCount);

        pdf.save("cyaag.pdf");
      },
    });
    
  };

  
  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Report on Sponsor
            </div>
            <div
              class="content-title"
              style={{
                fontSize: 20,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
                textAlign:"center",
              }}
            >
              {/* Total Platform Rewards Budget 
              
              <div><Skeleton  variant="rounded" style={{display: "inline", backgroundColor: "rgba(235, 149, 50, 1)"}}>{pfrewardsvalue?.[0]?.total}</Skeleton></div>
                 */}
              
            </div>
            <div>
              {
                pfrewards && (
              <Button
                className="btn download-btn"
                // variant="contained"
                // onClick={downloadDocument}
              >
                <CSVLink data={pfrewards}>
                  Download
                </CSVLink>
                
              </Button>
                )
}
            </div>
            <div style={{ fontFamily: "monospace", fontSize: 16 }}></div>
            <header id="SOADashboardScreen">
              <form autoComplete="off">
                <div
                  style={{
                    border: "1px solid #dadce0",
                    borderRadius: 8,
                    backgroundColor: "#fff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  ></div>
                  <div
                    className="centerAlign"
                    style={{ padding: "16px 0", marginBottom: 50 }}
                  >
                    <br />
                    <FormGroupFull>
                      <div id="showAll" style={{ overflow: "auto" }}>
                        <div id="downloadPdf">
                          <SponsorBasedReportTableComponent rows={pfrewards} />
                        </div>
                      </div>
                    </FormGroupFull>
                  </div>
                </div>
              </form>
            </header>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
}
export default PFRewardsReport;



