import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { isEmpty } from 'lodash';

const Overlay = styled("div")(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
    backdropFilter: 'blur(5px)', // Adds a blur effect to the background
  }));
  
  const PopupContainer = styled("div")(({ theme, isMobile }) => ({
    width: isMobile ? '95%' : '90%',
    maxWidth: '400px',
    maxHeight: '80vh',
    background: 'linear-gradient(135deg, #ffffff, #f7f7f7)',
    border: 'none',
    borderRadius: '15px',
    padding: '20px',
    position: 'relative',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
    zIndex: 1001,
    overflowY: 'auto',
    overflowX: 'auto',
    scrollbarWidth: 'thin', 
    scrollbarColor: '#bbb #f1f1f1', 
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#bbb', 
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    transition: 'transform 0.3s ease-in-out', // Smooth transition
    transform: isMobile ? 'translateY(-10px)' : 'translateY(0)', // Slight animation for mobile
  }));
  
  const CloseButton = styled("button")(({ theme }) => ({
    position: 'absolute',
    top: '10px',
    left: '10px',
    background: '#ff4d4f',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    width: '22px',
    height: '22px',
    fontSize: '15px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    transition: 'background 0.3s ease',
    '&:hover': {
      background: '#ff7875',
    },
  }));
  
  const StyledTable = styled("table")(({ theme }) => ({
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px',
    tableLayout: 'fixed',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
  }));
  
  const TableHeader = styled("th")(({ theme, isFirstColumn }) => ({
    border: '1px solid #ddd',
    padding: '12px',
    textAlign: 'center',
    backgroundColor: '#f2f2f2',
    color: '#333',
    fontWeight: 'bold',
    wordWrap: 'break-word',
  }));
  
  const TableCell = styled("td")(({ theme, isFirstColumn }) => ({
    border: '1px solid #ddd',
    padding: '12px',
    textAlign: 'center',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    minWidth: isFirstColumn ? '300px' : '150px',
    maxWidth: isFirstColumn ? '400px' : '200px',
  }));

const PopupHome1 = ({ isOpen, setIsOpen, th1, th2, attr1, attr2, data }) => {
  const closePopup = () => {
    setIsOpen(false); 
  };
  console.log("isOpen = ", isOpen)

  if (!isOpen) return null;

  const isMobile = window.innerWidth <= 768;

  return (
    <Overlay>
      <PopupContainer isMobile={isMobile}>
        <CloseButton onClick={closePopup}>X</CloseButton>
        <StyledTable>
          <thead>
            <tr>
              <TableHeader style={{ width: '300px' }}>{th1}</TableHeader>
              <TableHeader style={{ width: '150px' }}>{th2}</TableHeader>
            </tr>
          </thead>
          <tbody >
            {!isEmpty(data) && data.map((item, index) => (
              <tr key={index}>
                <TableCell  isFirstColumn={true}>{item[attr1]}</TableCell >
                <TableCell  isFirstColumn={false}>{item[attr2]}</TableCell >
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </PopupContainer>
    </Overlay>
  );
};

export default PopupHome1;
