import React from 'react';

const SVG5 = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <rect width="30" height="30" fill="url(#pattern0_140_1358)" />
    <defs>
      <pattern id="pattern0_140_1358" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_140_1358" transform="scale(0.02)" />
      </pattern>
      <image id="image0_140_1358" width="50" height="50" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEuUlEQVR4nO1aXYgcRRBu9dQoKkYUUUFEH8QHxeAP/gRORCGay03VXhZ88qIY8ckf8qAPyimSxBcVzfkTAiJiXk6IiKImqHNbNbkkeODhD/7hxQQv2e3eS07zEKNeRmp/e+fudmZ2ZndzcB/Uy+x0VX/dXdVVNavUEpawuJCngRsMwzZDMFEShm35Ued6tZigGZ/UBP8YRt8WeaYJH1enEn75dNXZmmBYE3xtGMdrQvhNkMAcKb9TGyM6NOEW0dlxIgXCtaETZsjJe6V3CSns/QLh2s4TYVgZRsIff/TM6vv+99mz5FlTIgwrO07E99VphnHSOv87NGO2LHDfwbHsOcEx8kx+q71HsMMiMik6VTdgGJ+vEWH8Ie54TfCdtRBDqls47DpXaYLZ2qruxluiji3m4NY6CTyZ58zVqpvQhK61qsORxzG8YY37SnUbhZyzzprQdJQQKo6vCYvVcUWGQdVtFNzseYbhmOW0Ww3j0yGy1Ypux0RHt3mocvRqHlZDw3S3olXgdt/eOolqxIP35LipbmBqvO9cTbgzMCm5V0YiSu0OqvjXrvnunraTMIyjARK7p9y+i6PqmOHVyzXjF41kcOd+d3CZ6gR8t7fHMHwSIDHSygRKR5Pxw4DPfG6nNm2BOKUmfDdwJN73/aHTW9bp9vaIjkafwTdVO6EZngis3gcykZR2+bPAAq1X7UCR8TZNcMIytCfN+sF4/eeXK8ma/hOFnHOHShPFvasu0AQHLCN/6BxelqoRsTOWvUJ0W3Z+F4KpGTAMr1nn91/ZHdUmaIJezfCfFcneSkfx6MCKBsUML6k2wzBstHZl1ngDNydWqgnHLKU/diLO73cHlxnCX+00JpFCw85dDVEqh3erDmHag9uLjA8ar//ambHsRYnyMU3wkR2l1GJEwYNr7OpPe5n7O2l/hlcvN5zZoBm+1IQ/acZ9huH12E0+Tbi5dqQIJjqZahcZ7zWE+XkzZYJZTbgpcjYhjl1XkNmgOgS/fMu/ohkPNk39CTeHKjvC2SvtQdJkUB2G7/b2lJyd4fBCO1PwnBsjdxHldm3XZCXFMeysMQSvSgatS8kjbCxQ5s7qUT5E2UvETxYg83bkfpUYaAcJWaxSCrJg1Yj7qhdhpbE3H5lvQ4jAO7HOYkwEFqpZPX+8yJCp7oxhOBTYkQNhRGqFU9qfATTDw/FqejhuGG6SsQXChxp+I6QwIvXawIPH0iIhN7RmPBKPCMox2ys+I5WjJpyynj8XQgQ/bseOaMKn4pIwFanWJuLgFRJH83vWXBpiEIYtIlvSIwK7WiViCF8UHdKVlFJCol0Ug+tth0pSk9tozGhjHi+C7aW5jQ6skGQ2kkFp65RZV5Tk8JE0iDQLtxFkpEWj9W6JJvxLViIpEcPgJSDycmtGvf7LNcOMReZPTfhAkuTRML7Q8tHyEmTfkoXO+cxMMKEZnjU5cIqcuSeKVG9o+Zhjd2JiyGTi3rAm6LN3pkX5WxLROeVBJCfHk4YcUGkVWdKMKylNGHX8kewZduUZYdyQShvFUbjOMDxTSmHKf8v4LZZ42F8ng5uaHTNNMH1KfMmKAvGZUgJJyJUG4M/SkZfW7FHXubDb81uCSoD/Af/WTJg+U//NAAAAAElFTkSuQmCC" />
    </defs>
  </svg>

);

export default SVG5;
