import React, { useEffect, useState } from "react";
import { Chip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Button, Stack } from "@mui/material";
import _ from "lodash";
import { isEmpty } from "lodash";
import "../../scss/containerStyles/_home.scss";
import "../../App.css";
import "../../cyaag-style.css";
import "../../font-awesome.min.css";
import "../../bootstrap.min.css";
import "../../icon-font.css";
import "../../flaticon-font.css";
import { userProfile } from "../../redux/action/UserProfileActions";
import { Modal } from "react-bootstrap";
import { CopyRight } from "../../components/CopyRight";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import { getVolunteerCount } from "../../redux/action/volunteerCountAction";
import ResponsiveDrawer from "../../components/Header";
import TopHeaderScreen from "../../components/TopHeader1";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getCampaign } from "../../redux/action/CampaignAction";
import Card from "@mui/material/Card";
import { referral } from "../../redux/action/ReferralAction";
import { isArray } from "lodash";
import RedeemConfirmation from "../../components/RedeemConfirmation";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import PostRedeemConfirmation from "../../components/PostRedeemConfirmation";
import { Audio, Circles } from "react-loader-spinner";

const SubscriptionListScreen = (props) => {
  // console.log("start HAScreen")
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [myCampaignList, setMyCampaignList] = useState([]);
  const [myRecommendedCampList, setMyRecommendedCampList] = useState([]);
  const [campStatusCount, setCampStatusCount] = useState([]);
  const [SDGCampMapping, setSDGCampMapping] = React.useState([]);
  const [mycommunity, setmycommunity] = useState([]);
  const [myredeemedrewards, setmyredeemedrewards] = useState([]);
  const [marketplacelist, setmarketplacelist] = useState([]);
  const [successStories, setSuccessStories] = useState([]);
  const [afterRedeem, setAfterRedeem] = useState([]);
  const [displayRedeemConfirmationModal, setDisplayRedeemConfirmationModal] =
    useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [redeemindex, setRedeemIndex] = useState(0);
  const [postRedeemDisplayModal, setPostRedeemDisplayModal] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [reLoading, setReLoading] = React.useState(false);
  const [newValue, setNewValue] = React.useState({});
  const [showModal, setShowModal] = React.useState(false);
  const [modalData, setModalData] = useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  // console.log("calling profile")
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  const userid = localStorage.getItem("userid");

  React.useEffect(() => {
    dispatch(userProfile(userid));
  }, [reLoading]);

  // console.log("calling useeffect 2")
  useEffect(() => {
    getCampainUpdateList();
  }, [reLoading]);

  useEffect(() => {
    getMyRedeemedRewards();
  }, []);

  useEffect(() => {
    //if (profileSuccess) {
    getMyCommunityDetails().then((data) => {
      setmycommunity(data);
    });
    setLoading(false);
  }, [reLoading]);

  useEffect(() => {
    getSuccessStories().then((data) => {
      setSuccessStories(data);
    });
  }, []);

  useEffect(() => {
    // setLoading(false);
    getRecommendedCampDetails();
    // setLoading(true);
  }, [reLoading]);

  useEffect(() => {
    if (newValue?.orgrole && newValue?.organization) {
      getCampStatusCount();
    }
  }, [newValue]);
  
  useEffect(() => {
    getSDGCampMapping();
  }, [reLoading]);

  const getCampainUpdateList = () => {
    dispatch(userProfile(userid));
    dispatch(getVolunteerCount());
    Promise.all([getProfileInfo()]).then((data) =>
      getVolunteerCampDetails(data)
    );
    dispatch(
      getCampaign(profileResponse.orgrole, profileResponse.organization)
    );
  };
  const getProfileInfo = async () => {
    try {
      const res = await axios.get(`cyaaguser/${userid}`);
      return res;
      // }
    } catch (err) {
      throw err;
    }
  };

  const getVolunteerCampDetails = async (dd) => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `/campaign/all/open/${dd[0].data?.orgrole}/${dd[0].data?.organization}/${dd[0].data?.phoneno}`
      );

      setMyCampaignList(res);
      setNewValue(dd[0]?.data);
      setLoading(true);
      setIsLoading(false);

      console.log('New Value:', dd[0]?.data);

      return res;
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
  };

  const getMyCommunityDetails = async () => {
    try {
      const res = await axios.get(`adopter_dashboard/mycommunity/${userid}`);
      // console.log(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getMyRedeemedRewards = async () => {
    try {
      const res = await axios.get(`reward/redeemed/${userid}`);
      setmyredeemedrewards(res);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getSuccessStories = async () => {
    try {
      const res = await axios.get(`/success/stories`);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getRecommendedCampDetails = async () => {
    try {
      const res = await axios
        .get(`recommendation/${userid}`)
        .then((response) => {
          setMyRecommendedCampList(response);
          return response;
        });
    } catch (err) {
      throw err;
    }
  };

  const getCampStatusCount = async () => {
    try {
      console.log("getCampStatusCount = ", newValue);
      console.log("getCampStatusCount orgrole= ", newValue?.orgrole);
      const res = await axios.get(`camp/count/${newValue?.orgrole}/${newValue?.organization}/${userid}`)
        .then((response) => {
          setCampStatusCount(response);
          return response;
        });
    } catch (err) {
      throw err;
    }
  };

  const getSDGCampMapping = async () => {
    try {
      const res = await axios.get(`camp/sdg/mapping`);
      setSDGCampMapping(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const handleRedeem = async () => {
    const payload = {
      phoneno: localStorage.getItem("userid"),
      rewardtype: marketplacelist?.data?.[redeemindex]?.rewardtype,
      mpid: marketplacelist?.data?.[redeemindex]?.mpid,
      campid: marketplacelist?.data?.[redeemindex]?.campid,
      title: marketplacelist?.data?.[redeemindex]?.title,
      subtitle: marketplacelist?.data?.[redeemindex]?.subtitle,
      points: marketplacelist?.data?.[redeemindex]?.points,
      sponsor: marketplacelist?.data?.[redeemindex]?.sponsor,
    };
    try {
      const res = await axios.post(
        `/reward/redeem/${profileResponse?.phoneno}`,
        payload
      );
      setAfterRedeem(res?.data);
      setDisplayRedeemConfirmationModal(false);
      if (res?.data?.message === "Reward successfully redeemed") {
        setPostRedeemDisplayModal(true);
      } else {
        setDisplayConfirmationModal(true);
      }
      return res;
    } catch (err) {
      throw err;
    }
  };

  // Hide the modal
  const hideRedeemConfirmationModal = () => {
    setDisplayRedeemConfirmationModal(false);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handlePostRedeemModal = () => {
    setPostRedeemDisplayModal(false);
    navigate("/marketplace");
  };

  const subscribeAction = async (campIdVal, subscribeVal) => {
    setModalData({ campIdValue: campIdVal, subscribeValue: subscribeVal });

    if (subscribeVal === "subscribe") {
      setShowModal(true);
      return false;
    } else {
      subscribeNow(campIdVal, subscribeVal);
    }
  };

  const closeModalWindow = () => {
    setShowModal(false);
  };

  const subscribeNow = async (campIdVal, subscribeVal) => {
    try {
      console.log(
        `${subscribeVal === "subscribe" ? "unsubscribe" : "subscribe"
        }/camp/${campIdVal}/${userid}`
      );
      const res = await axios
        .put(
          `${subscribeVal === "subscribe" ? "unsubscribe" : "subscribe"
          }/camp/${campIdVal}/${userid}`
        )
        .then((response) => {
          return response;
        });
      setShowModal(false);
      getCampainUpdateList();
    } catch (err) {
      throw err;
    }
  };

  const handleImageLoad = async (img) => {
    const im = await Promise.all(img);
    console.log(im);
    if (im) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Card style={{display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      margin: "0 -7px",}} onClick={props.onClick}>
      <div className="wrapper">
        <ResponsiveDrawer {...props} />
        <section className="containers">
          <TopHeaderScreen {...props} />
          <div class="content content-wrap">
            <div class="content-mrg hasScreen">
              <Button onClick={() => navigate(-1)} className="backWrapper">
                <i className={`fa fa-caret-left`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
              </Button>
              <div class="content content-pad-0">
                <div class="row">
                  <div class="col-lg-12">
                    <section id="tabs" class="project-tab">
                      <div
                        class="container-fluids"
                        style={{ maxWidth: "1440px" }}
                      >
                        <div class="cyagg-camp-space campaigns-tabs">
                          <div>
                            <div class="row">
                              <div class="col-12">
                                <div>
                                  <h4 class="site-title">Campaigns</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                          {isLoading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
                              <Circles
                                type="TailSpin"
                                color="rgb(155, 236, 34)"
                                height={70}
                                width={70}
                                timeout={5000}
                                ariaLabel="three-circles-rotating"
                              />
                            </div>

                          ) : (
                            <div class="widget-content animated-underline-content">
                              <div
                                class="tab-content customOwlNav"
                                id="animateLineContent-4"
                              >
                                <div
                                  class="tab-pane fade show active"
                                  id="animated-underline-home"
                                  role="tabpanel"
                                  aria-labelledby="animated-underline-home-tab"
                                >
                                  {/* {campStatusCount &&
                                    campStatusCount?.data?.[0]?.activecount ===
                                    0 && isEmpty(myCampaignList.data)? ( */}
                                    { isEmpty(myCampaignList?.data) || myCampaignList?.data
                                        ?.filter(
                                          (item, index) =>
                                            item.active === "Completed"
                                        )? (
                                    <span>
                                      <i>
                                        <b>
                                          Please keep watching this space for
                                          new campaigns. You will also be
                                          notified here.
                                        </b>
                                      </i>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  <div className="subscribeCampaignList row">
                                    {myCampaignList &&
                                      myCampaignList?.data
                                        ?.filter(
                                          (item, index) =>
                                            item.active === "Active" ||
                                            item.active === "Upcoming"
                                        )
                                        .map((tile, index) => (
                                          <div class="item col-md-4 col-sm-6 col-xs-12">
                                            <div class="">
                                              
                                              
                                              <div class="campaigns-wrap campaigns-wrap-new">
                                                <div>
                                                  <div>
                                                  <div className="textcamphome" style={{ flexDirection: "row", display: "inline-flex" }}>
                                                      <img src={tile?.organizationlogo} style={{ width: "auto", marginBottom: 15, marginTop: 5, border: "0.5px solid silver" }} alt="cYAAG" />
                                                      <h5 class="campaign-title-new" style={{ marginLeft: "5px", textWrap: "wrap" }}>{tile?.name}</h5>
                                                    </div>
                                                    <div class="campaign-img">
                                                      <div class="video-wrapper">
                                                        <div
                                                          class="video-container"
                                                          id="video-container"
                                                        >
                                                          <video
                                                            controls
                                                            controlsList="nodownload"
                                                            id="video"
                                                            // preload="metadata"
                                                            preload="auto"
                                                            // poster="//cdn.jsdelivr.net/npm/big-buck-bunny-1080p@0.0.6/poster.jpg"
                                                            poster={tile?.logo}
                                                            autoplay
                                                          >
                                                            <source
                                                              // src="//cdn.jsdelivr.net/npm/big-buck-bunny-1080p@0.0.6/video.mp4"
                                                              src={tile?.video}
                                                              type="video/mp4"
                                                              display="none"
                                                            />
                                                          </video>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    
                                                    <div style={{ marginTop: "5px" }}>
                                                      <div
                                                        // title={tile?.name}
                                                        class="campaign-title-new"
                                                        style={{
                                                          color: "#808080",
                                                          // display: "inline-block",
                                                          // border: "1px solid",
                                                          padding: "5px 0px",
                                                          // borderRadius: "5px",
                                                          maxHeight: "40px",
                                                          verticalAlign: "middle",
                                                          // backgroundColor: "blue",
                                                          marginTop: "10px",
                                                        }}
                                                      >
                                                        Sponsored By
                                                      </div>
                                                      <div class="row sponserHomeWrapper" style={{ textAlign: "center", marginBottom: "10px" }}>
                                                        <div class="" >

                                                          {isArray(tile?.sponsor) &&
                                                            tile?.sponsor?.map(
                                                              (tile, index) => (
                                                                handleImageLoad(tile.sponsorlogo) ? (
                                                                  <img
                                                                    style={{
                                                                      width: "auto",
                                                                      // height: "72px",
                                                                      maxHeight: "50px"
                                                                    }}
                                                                    src={tile.sponsorlogo}
                                                                    key={index} alt="reward"
                                                                  />
                                                                ) : null
                                                              )
                                                            )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div class="campaign-hashtags" style={{ textAlign: "right" }}>
                                                      {tile.tag.map((tag) =>
                                                        SDGCampMapping?.map(
                                                          (item) =>
                                                            item?.cat ===
                                                            tag?.label && (
                                                              <a
                                                                class="hash-styles"
                                                                style={{
                                                                  cursor:
                                                                    "initial",
                                                                }}
                                                              >
                                                                {tag?.tag}{" "}
                                                                <a
                                                                  class="hash-styles-sdg"
                                                                  style={{
                                                                    cursor:
                                                                      "initial",
                                                                  }}
                                                                >
                                                                  SDG{" "}
                                                                  {item?.sdg}
                                                                </a>
                                                              </a>
                                                            )
                                                        )
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div
                                                    style={{padding: 1,

                                                      // backgroundColor: "#E8E8E8",
                                                      margin: 2,
                                                      borderRadius: 4,
                                                      display: "block",}}
                                                  >
                                                    <Stack
                                                      direction="row"
                                                      alignItems="center"
                                                      justifyContent="space-between"
                                                      gap={0.5}
                                                    >
                                                      <h5 class="campaign-goal">
                                                        <a
                                                          class="hash-styles-goal"
                                                          style={{
                                                            padding:
                                                              "0px 5px 0px 0px",
                                                            cursor: "initial",
                                                          }}
                                                        >
                                                         
                                                          <span style={{ fontSize: "16px", fontWeight: 700 }}>Goal</span>: <span class="text-6" style={{ color: "black" }}>{tile.goalquantity}{" "} {tile.unitofgoal}</span>
                                                        </a>

                                                      </h5>
                                                    </Stack>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col-md-12">
                                                    <div>
                                                      <div class="campaigns-details">

                                                        <div class="p-bar-new">
                                                          <div class="row ">
                                                            <div class="col-6">
                                                              <div>
                                                                <h6>
                                                                  <span style={{ color: "#AAAAAA" }}>Pledged: </span>{" "} <span style={{ backgroundColor: "#FFDB58" }}>
                                                                    {Math.round(
                                                                      (tile.committedcount)
                                                                    )}
                                                                  </span>
                                                                </h6>
                                                              </div>
                                                            </div>
                                                            <div class="col-6">
                                                              <div
                                                                style={{
                                                                  textAlign:
                                                                    "right",
                                                                }}
                                                              >
                                                                <h6>
                                                                  <span style={{ color: "#AAAAAA" }}>Achieved: </span>
                                                                  {" "}
                                                                  <span style={{ backgroundColor: "#A9D18E" }}> {tile.achievedcount}</span>
                                                                  {" "}
                                                                </h6>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="progress br-30 campaign-progress" style={{ position: "relative" }}>
                                                            <div
                                                              className="progress-bar"
                                                              role="progressbar"
                                                              style={{
                                                                width: `${(tile.committedcount / tile.goalquantity) * 100}%`,
                                                                backgroundColor: "#FFDB58",
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                zIndex: 1,
                                                                height: "100%"
                                                              }}
                                                              aria-valuenow={Math.round((tile.committedcount / tile.goalquantity) * 100)}
                                                              aria-valuemin="0"
                                                              aria-valuemax="100"
                                                            ></div>

                                                            <div
                                                              className="progress-bar"
                                                              role="progressbar"
                                                              style={{
                                                                width: `${(tile.achievedcount / tile.goalquantity) * 100}%`,
                                                                backgroundColor: "#A9D18E",
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                zIndex: 2,
                                                                height: "100%"
                                                              }}
                                                              aria-valuenow={Math.round((tile.achievedcount / tile.goalquantity) * 100)}
                                                              aria-valuemin="0"
                                                              aria-valuemax="100"
                                                            ></div>
                                                          </div>
                                                        </div>

                                                        <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                                          <div style={{ flex: 1 }}>
                                                            <h6>
                                                              <span style={{ color: "#AAAAAA" }}>Signed Up:</span>{" "}
                                                              <span style={{ backgroundColor: "#DAE8FD" }}>{tile.submittedcount}</span>
                                                            </h6>
                                                          </div>
                                                          <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                            <Chip
                                                              key={tile.campaigntype}
                                                              fontSize="small"
                                                              size="small"
                                                              label={tile.campaigntype}
                                                            />

                                                          </div>
                                                        </div>

                                                        <div class="row" style={{ marginTop: "10px" }}>
                                                          <div class="col-md-4 col-6 customHalfWidth">
                                                            <div class="cam-cols">
                                                              <div class="timer-here">
                                                                <div class="text-center">
                                                                  <span class="campaign-time-title">
                                                                    Earn upto
                                                                  </span>
                                                                </div>
                                                                <div>
                                                                  <ul id="countdown" style={{listStyle: "none"}}>
                                                                    <li id="hours">
                                                                      <div class="numberhome"></div>
                                                                      <div class="label"></div>
                                                                    </li>
                                                                    <li id="minutes">
                                                                      <div
                                                                        class="numberhome"
                                                                        style={{
                                                                          padding:
                                                                            "0px 0px 0px 6px",
                                                                        }}
                                                                      >
                                                                        {
                                                                          tile.maxpointstobeearned
                                                                        }
                                                                      </div>
                                                                      <div
                                                                        class="label"
                                                                        style={{
                                                                          padding:
                                                                            "0px 0px 0px 12px",
                                                                        }}
                                                                      >
                                                                        Points
                                                                      </div>
                                                                    </li>
                                                                    <li id="seconds">
                                                                      <div class="numberhome"></div>
                                                                      <div class="label"></div>
                                                                    </li>
                                                                  </ul>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div class="col-md-4 col-6 customHalfWidth">
                                                            <div class="cam-cols">
                                                              <div class="timer-here">
                                                                <div class="text-center">
                                                                  <span class="campaign-time-title" style={{ margin: "0px 0px" }}>
                                                                    Time Left
                                                                  </span>
                                                                </div>
                                                                <div>
                                                                  <ul id="countdown" style={{listStyle: "none"}}>
                                                                    <li id="hours">
                                                                      <div class="number">
                                                                        {
                                                                          tile.dayrem
                                                                        }
                                                                      </div>
                                                                      <div class="label">
                                                                        Days
                                                                      </div>
                                                                    </li>
                                                                    <li id="minutes">
                                                                      <div class="number">
                                                                        {
                                                                          tile.hourrem
                                                                        }
                                                                      </div>
                                                                      <div class="label">
                                                                        Hours
                                                                      </div>
                                                                    </li>
                                                                    <li id="seconds">
                                                                      <div class="number">
                                                                        {
                                                                          tile.minrem
                                                                        }
                                                                      </div>
                                                                      <div class="label">
                                                                        Mins
                                                                      </div>
                                                                    </li>
                                                                  </ul>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div
                                                            class="col-md-4 customFullWidth"
                                                            style={{
                                                              paddingLeft:
                                                                "0px",
                                                            }}
                                                          >
                                                            <div class="par-btn-here">
                                                              <div class="text-center">
                                                                <a class="par-now-new-subscribe">
                                                                  
                                                                  <Button
                                                                    className={
                                                                      tile.issubscribed ===
                                                                        "subscribe"
                                                                        ? "unsubscribe"
                                                                        : "subscribe"
                                                                    }
                                                                    onClick={() =>
                                                                      subscribeAction(
                                                                        tile.campid,
                                                                        tile.issubscribed
                                                                      )
                                                                    }
                                                                    style={{
                                                                      color:
                                                                        "white",
                                                                      // padding:"0px 0px 0px 0px",
                                                                      padding:
                                                                        "6px",
                                                                      fontSize:
                                                                        "12px",
                                                                    }}
                                                                    name={
                                                                      "activepnow" +
                                                                      tile.campid
                                                                    }
                                                                  >
                                                                    {tile.issubscribed ===
                                                                      "subscribe"
                                                                      ? "unsubscribe"
                                                                      : "subscribe"}
                                                                    {tile.issubscribed ===
                                                                      "subscribe" ? (
                                                                      <i
                                                                        class="fa fa-bell fa-bell-slash"
                                                                        aria-hidden="true"
                                                                        style={{
                                                                          marginLeft:
                                                                            "5px",
                                                                        }}
                                                                      ></i>
                                                                    ) : (
                                                                      <i
                                                                        class="fa fa-bell"
                                                                        aria-hidden="true"
                                                                        style={{
                                                                          marginLeft:
                                                                            "5px",
                                                                        }}
                                                                      ></i>
                                                                    )}
                                                                  </Button>
                                                                  
                                                                </a>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="mr-15 text-center">
                                                          <a class="campaign-signin">
                                                            Join Now{" "}
                                                            <i
                                                              class="fa fa-arrow-right"
                                                              aria-hidden="true"
                                                            ></i>
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ))}

                                    {/* Box model for confirmation message for unsubscribte */}

                                    <Modal
                                      show={showModal}
                                      onHide={() => closeModalWindow()}
                                    >
                                      <Modal.Header>
                                        <Modal.Title>Confirmation</Modal.Title>
                                        <button
                                          type="button"
                                          onClick={() => closeModalWindow()}
                                          class="close"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <span aria-hidden="true">×</span>
                                        </button>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <p>
                                          Are you sure you want to unsubscribe
                                          this campaign?
                                        </p>
                                      </Modal.Body>
                                      <Modal.Footer>
                                        <Button
                                          variant="secondary"
                                          style={{
                                            background: "#e9ecef",
                                            color: "#3B3F5C",
                                          }}
                                          onClick={() => closeModalWindow()}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          variant="secondary"
                                          style={{
                                            backgroundColor: "#db8d0f",
                                            color: "#fff",
                                          }}
                                          onClick={() =>
                                            subscribeNow(
                                              modalData.campIdValue,
                                              modalData.subscribeValue
                                            )
                                          }
                                        >
                                          Unsubscribe
                                        </Button>
                                      </Modal.Footer>
                                    </Modal>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </section>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CopyRight />
          <FixedFooterIcon />
          {/* <RedeemConfirmation
            showModal={displayRedeemConfirmationModal}
            confirmModal={handleRedeem}
            hideModal={hideRedeemConfirmationModal}
            message={"Are you sure you want to redeem this reward?"}
          />
          <ConfirmationDialog
            showModal={displayConfirmationModal}
            // confirmModal={handleRedeem}
            hideModal={hideConfirmationModal}
            message={"You do not have enough points to redeem"}
          />
          <PostRedeemConfirmation
            showModal={postRedeemDisplayModal}
            confirmModal={handlePostRedeemModal}
            // hideModal={handlePostRedeemModal}
            message={"You have successfully redeemed"}
          /> */}
        </section>
      </div>
    </Card>
  );
};

export default SubscriptionListScreen;
