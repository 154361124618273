/** @format */

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import { ValidateRewardsRedeemed } from "../../components/ValidateRewardsRedeemed";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Button,
} from "@mui/material";
import { rewardsRedeemedList } from "../../redux/action/RewardsRedeemedList";
import { userProfile } from "../../redux/action/UserProfileActions";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link, useNavigate } from "react-router-dom";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import styled from 'styled-components';

const HomeScreenMainContent = styled(Box)(({ theme }) => ({
  padding: '10px 0 10px 0px',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    padding: '10px 0 10px 10px',
  },
})); 

const CustomTabs = styled(Tabs)(({ theme }) => ({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1976d2',
  },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: 'uppercase',
  minWidth: 72,
  // fontWeight: theme.typography.fontWeightRegular,
  // marginRight: theme.spacing(4),
  color: '#888da8',
  fontWeight: 600,
  '&:hover': {
    color: '#115293',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#1976d2',
    // fontWeight: theme.typography.fontWeightMedium,
  },
  '&:focus': {
    color: '#1976d2',
  },
}));

function createDataValidator(
  id,
  firstname,
  // formuserfullname,
  lastname,
  phoneno,
  organization,
  rewardtype,
  title,
  subtitle,
  points,
  status,
  rewarddate,
  mpid,
) {
  return {
    id,
    firstname,
    // formuserfullname,
    lastname,
    phoneno,
    organization,
    rewardtype,
    title,
    subtitle,
    points,
    status,
    rewarddate,
    mpid,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  // console.log(props)
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
  // console.log(props)
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const SOActionOnRewardsRedeemed = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [welcomeOpen, setWelcomeOpen] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const userid = localStorage.getItem("userid");
  const [value1, setValue1] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [reviewStatus, setReviewStatus] = React.useState("");
  const [campaignList, setCampaignList] = useState(
    [
      'campaignname',
      'formuserfirstname',
      'formuserfirstname',
      'formuserphoneno',
      'registrationstatus',
      'rewarddate'
    ]
  );

  const { isSuccess: validatorListSuccess, response: validatorListResponse } = useSelector(
    (state) => state.rewardsRedeemedList
  );
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(rewardsRedeemedList());
    // dispatch(validatorList(newValue));
  };

  useEffect(() => {
    dispatch(rewardsRedeemedList(profileResponse));
    dispatch(userProfile(userid));
  }, [campaignList]);

  useEffect(() => {
    // dispatch(validatorList());
    dispatch(rewardsRedeemedList(profileResponse));
    dispatch(userProfile(userid));
  }, []);

  useEffect(() => {
    if (
      profileSuccess &&
      (profileResponse[0]?.legal === "no" ||
        profileResponse[0]?.legal === undefined)
    ) {
      setWelcomeOpen(true);
    } else {
      setWelcomeOpen(false);
    }
  }, [profileResponse]);

  useEffect(() => {
    // console.log("useeffect")
    let temp = [];
    for (let i = 0; i < validatorListResponse.length; i += 1) {
      const formattedArray = [];
      // console.log(validatorListResponse[i])
      formattedArray.push(
        // validatorListResponse[i]._id,
        validatorListResponse[i].firstname,
        // validatorListResponse[i].formuserfullname,
        validatorListResponse[i].lastname,
        validatorListResponse[i].phoneno,
        validatorListResponse[i].organization,
        // validatorListResponse[i].affiliation,
        validatorListResponse[i].rewardtype,
        validatorListResponse[i].title,
        validatorListResponse[i].subtitle,
        validatorListResponse[i].points,
        validatorListResponse[i].status,
        validatorListResponse[i].rewarddate,
        validatorListResponse[i].mpid,
        // validatorListResponse[i].documentuploadcount,


      );
      console.log(formattedArray);
      temp.push(createDataValidator(i, ...formattedArray));
      console.log("temp");
      console.log(temp);
    }
    setRows(temp);
  }, [validatorListResponse]);

  useEffect(() => {
    const firstTime = localStorage.getItem("firstTime");
    if (firstTime) {
      setWelcomeOpen(true);
    }
  }, []);


  const reviewedData = rows.filter((item) => item.status === "open");
  const acceptedData = rows.filter((item) => item.status === "close");
  // const rejectedData = rows.filter((item) => item.registrationstatus === "rejected");

  const sortedDataReview =
    inputValue === ""
      ? ""
      : reviewedData.filter((item) => item.firstname === inputValue);

  const sortedDataApprove =
    inputValue === ""
      ? ""
      : acceptedData.filter((item) => item.firstname === inputValue);

  // const sortedDataRejected =
  //   inputValue === ""
  //     ? ""
  //     : rejectedData.filter((item) => item.organization === inputValue);

  console.log(rows);
  // console.log(sortedDataApprove)
  // console.log(sortedDataRejected)
  return (
    <div class="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button
              onClick={() => navigate(-1)}
              className="backWrapper"
              style={{ justifyContent: "left" }}
            >
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Manage Redeem</div>
            <HomeScreenMainContent id="exec"  >
              <div style={{display: 'flex',
    justifyContent: 'center',
    marginRight: '100px',}}>
                <CustomTabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <CustomTab label="open" />
                  <CustomTab label="close" />
                </CustomTabs>
              </div>

              <TabPanel value={value} index={0} >
                <ValidateRewardsRedeemed
                  value1={value1}
                  setValue1={setValue1}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  // response={response}
                  // isFetching={isFetching}
                  rows={reviewedData}
                  sortedData={sortedDataReview}
                  actionTaken={false}
                  setReviewStatus={setReviewStatus}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ValidateRewardsRedeemed
                  value1={value1}
                  setValue1={setValue1}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  // response={response}
                  // isFetching={isFetching}
                  rows={acceptedData}
                  sortedData={sortedDataApprove}
                  actionTaken
                />
              </TabPanel>
            </HomeScreenMainContent>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({}, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SOActionOnRewardsRedeemed);