import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "../../components/Header";
import { userProfile } from "../../redux/action/UserProfileActions";
import { API_URL } from "../../services/Constants";
import { getVolunteerCount } from "../../redux/action/volunteerCountAction";
import { getCampaign } from "../../redux/action/CampaignAction";
import TopHeaderScreen from "../../components/TopHeader1";
import { CopyRight } from "../../components/CopyRight";
import { CopyRightLanding } from "../../components/CopyRightLanding";
import { referral } from "../../redux/action/ReferralAction";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";

const PrivacyPolicyLanding = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [campaignList, setCampaignList] = useState([]);
  const [rewardList, setRewardList] = useState([]);
  const [marketPlace, setMarketPlace] = useState([]);

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  const { isSuccess: campSuccess, response: campResponse } = useSelector(
    (state) => state.getCampaign
  );
  const refferalId = localStorage.getItem("refferalId");
  const userid = localStorage.getItem("userid");
  useEffect(() => {
    currentUserData();
    localStorage.setItem('myActiveMenu', "NoMenu");
    localStorage.setItem('mySubMenu', "NoSubMenu");
    // getVolunteerCamp();
    getRewardList();
    getMarketPlace();
  }, []);
  useEffect(() => {
    let temp = [];
    localStorage.getItem("refferalId") &&
      dispatch(
        referral({
          campid: localStorage.getItem("refferalId"),
          phoneno: localStorage.getItem("refferalPhoneNumber"),
          reflevel: localStorage.getItem("reflevel"),
          refuserid: localStorage.getItem("userid"),
        })
      );
    temp.push(localStorage.getItem("userid"));
  }, []);

  React.useEffect(() => {
    dispatch(userProfile(userid));
    dispatch(getVolunteerCount());
    dispatch(
      getCampaign(profileResponse.orgrole, profileResponse.organization)
    );
    // }
  }, [campaignList]);



  const currentUserData = async () => {
    try {
      const res = await axios.get(`cyaaguser/${userid}`).then((userData) => {
        
        getVolunteerCamp(userData.data);
      });
    } catch (err) {
      throw err;
    }
  };

  const getVolunteerCamp = async (pRes) => {
    try {
      const res = await axios
        .get(`vol_dashboard/camp/${pRes?.orgrole}/${pRes?.organization}`)
        .then((response) => {
          setCampaignList(response?.data);
          return response;
        });

      // console.log(res)

      // }
    } catch (err) {
      throw err;
    }
  };

  const getRewardList = async () => {
    try {
      const res = await axios.get(`/success/stories`).then((response) => {
        setRewardList(response?.data);
        return response;
      });
    } catch (err) {
      throw err;
    }
  };

  const getMarketPlace = async () => {
    try {
      const res = await axios
        .get(`/marketplace/read/undefined`)
        .then((response) => {
          setMarketPlace(response?.data);
          return response;
        });
    } catch (err) {
      throw err;
    }
  };

  return (
    <div>
      <div className="wrapper">
        {/* <ResponsiveDrawer {...props} /> */}
        <section className="containers">
          {/* <TopHeaderScreen {...props} /> */}
          <div class="content content-wrap">
            <div class="content-mrg">
              {/* <div class="landingPageWrapper"> */}
              <Button
                onClick={() => navigate(-1)}
                className="backWrapper"
                id="aboutusback"
              >
                <i className={`fa fa-caret-left`} />
                <span
                  style={{ paddingLeft: 3, fontSize: 14, color: "#1976d2" }}
                >
                  Back
                </span>
              </Button>
              <div
                style={{ float: "left", width: "100%" }}
                class="sliderContentWrap"
              >
                <h2 class="pl-3 pr-3 " style={{textAlign:"center"}}>
                  Privacy Policy
                  <br />
                </h2>
                <div class="item marginLeft15">
                  <h3>Introduction</h3>
                  <p>cYAAG places great importance on security of your personal data and only partners with vendors that adhere to the strictest security and data protection standards.</p>
                  <p>cYAAG has implemented technical and organizational security measures to ensure the security of your personal data. Information is stored on secure networks and access is restricted to only those employees and partners who are entitled to access our systems.</p>
                  <p>This policy (the “Data Protection and Privacy Policy”) explains which personal data concerning you we collect when you visit our website (the “Website”), when and why we collect the personal data, how we use them, the conditions of our disclosure to third parties, as well has how we secure the stored personal data.</p>
                  <p>Please read the Data Protection and Privacy Policy thoroughly in order to understand how we process your personal data.</p>
                  <p>The Data Controller<br />
                  CYAAG Inc</p>
                  <p>If you have any questions or concerns about CYAAG’s personal data practices or your privacy rights, you may contact us at <a class="onOrange" href="mailto:admin@CYAAG.com">admin@CYAAG.com</a></p>
                </div>

                <div class="item marginLeft15">
                <h3>Executive Summary</h3>
                  <p>As a global organization, CYAAG complies with data protection legislation and guidelines in all countries where it has locations. CYAAG has therefore chosen to work only with IT vendors who participate in and have certified compliance with the EU–U.S. Privacy Shield Framework and are committed to subjecting all personal data received from EU member countries, in reliance on the Privacy Shield Framework, to the Framework’s applicable Principles, or have taken other measures to comply with GDPR as mentioned below, under chapter VII. To learn more about the Privacy Shield Framework, you may visit the U.S. Department of Commerce’s Privacy Shield List.</p>
                  <p>CYAAG also complies with the EU ePrivacy Directive, including the requirement for website operators to obtain users’ consent prior to creating Cookies.</p>
                </div>


                <div class="item marginLeft15">
                  <h3>How We Collect and Use Your Personal Data</h3>
                  <p>CYAAG collects personally identifiable information in the following ways:</p>
                  <h4>Member and Client Data</h4>
                  <p>When a company or individual joins as a member of CYAAG or subscribes to our CYAAG Mailing list, we collect contact data in the form of the following data:</p>
                  <ul style={{marginLeft:30}}>
                  <li>First and last name</li>
                  <li>Company (for clients)</li>
                  <li>Email address</li>
                  <li>Phone number</li>
                  <li>Area of interest (e.g. sustainability, climate etc.)</li>
                  <li>Town/city/state</li>
                  <li>Country</li>
                  </ul>
                  <p>All personal data collected will only be used to process your membership application and send you product information and occasional special offers or announcements from selected CYAAG partners. Data collected from questionnaires from subscribers or individuals will be shared with accepted third parties to convey interest and opinion. We do not sell personal data to anyone and only share it with third parties who are facilitating the delivery of CYAAG services.</p>
                  <p>We rely on fulfilment of contract as the lawful basis under GDPR Article 6(1)(b) for the processing of member and client data.</p>
                </div>

                <div class="item marginLeft15">
                  <h4>Human Resources Data</h4>
                  <p>CYAAG is always looking for new employees, and we are always pleased to receive solicited job applications. If you wish to apply for a position with us, please apply directly through our website. Email resume attachments will not be considered.</p>
                  <p>When you submit your application for employment with CYAAG, we process your personal data in accordance with applicable personal data regulations. This implies that:</p>
                  <ul style={{marginLeft:30}}>
                  <li>Your personal data will be treated confidentially</li>
                  <li>We only use your personal data for recruitment purposes</li>
                  <li>We do not disclose your personal data, except for the data processors we use in our recruitment procedure.</li>
                  </ul>
                  <p>CYAAG has ensured that applicants have expressly authorized personal information to be transmitted to CYAAG for position consideration. Access to this personal data is restricted to relevant employees within CYAAG only.</p>
                  <p>CYAAG stores employee details and performance data with security-cleared data processors, which are assisting us with these HR services. Your personal data are stored on secure servers in the United States.</p>
                  <p>Any personal data received from you with your application will only be used for the purpose of processing your application and will not be disclosed, except to CYAAG’s security-cleared data processors in connection with the recruitment procedure.</p>
                  <p>We rely on fulfillment of contract to which the applicant is party or in order to take steps at the request of the applicant prior to entering into a contract as the lawful basis under GDPR Article 6(1)(b) for the processing of Human Resources Data.</p>
                </div>

                <div class="item marginLeft15">
                  <h4>Events and CYAAG Conference Data</h4>
                  <p>Individuals within companies provide their corporate information to register for an event. During Conference and event registration, where information is voluntarily provided during event signup, we collect the following information from you:</p>
                  <ul style={{marginLeft:30}}>
                  <li>First and last name</li>
                  <li>Job title</li>
                  <li>Company</li>
                  <li>Work e-mail address</li>
                  <li>Phone number</li>
                  <li>Area of interest (e.g. sustainability, climate etc.)</li>
                  <li>Town/city/state</li>
                  <li>Country</li>
                  </ul>
                  <p>CYAAG events may be photographed and/or video/audio recorded for the purpose of reflecting the events in CYAAG publications and on the CYAAG website. We focus our efforts solely on the key note speakers and other voluntary participants from the audience, as well as the audience as a whole.</p>
                  <p>We rely on legitimate interest as the lawful basis under GDPR Article 6(1)(f) for the processing of Events and CYAAG Conference Data.</p>
                </div>

                <div class="item marginLeft15">
                  <h4>Website Visitors’ Data</h4>
                  <p>In general, website visitors do not need to provide personalized information to CYAAG. We do collect "aggregate data," that is, group data with no personal identifiers. We use this aggregate data to help us understand how the site is being used and to improve its usability. We also use it to enhance the quality and availability of products and services we offer.</p>
                  <p>We also, with explicit permission, use aggregate data from online surveys you choose to fill out for research and publication purposes.</p>
                  <p>If personal data is provided, and retained, it is only name, business contact email, and business contact phone number, which allow CYAAG to contact the visitor at his or her organization. CYAAG solely holds the information and engages in no contact-sharing program with other organizations.</p>
                  <p>Many websites create Cookies (small text files) when a user visits a website, and these Cookies are used to analyze aggregate user behavior on a website. In compliance with the EU ePrivacy Directive, CYAAG websites ask permission of the visitor prior to setting Cookies. Should the visitor agree, CYAAG’s server will only collect the following information:</p>
                  <ul style={{marginLeft:30}}>
                  <li>The visitor’s IP address (including the domain name associated with the IP address, i.e. using reverse look-up).</li>
                  <li>The date and time of the visit to the website.</li>
                  <li>The pages visited on the website.</li>
                  <li>The browser being used.</li>
                  </ul>
                  <p>In addition, where this is available, CYAAG will also collect:</p>
                  <ul style={{marginLeft:30}}>
                  <li>The visitor’s IP address (including the domain name associated with the IP address, i.e. using reverse look-up).</li>
                  <li>The country from which the visitor is accessing the website (only the ending is saved, e.g., de, since this indicates the relevant country).</li>
                  <li>The language of the browser being used.</li>
                  <li>The website from which the visitor is accessing the CYAAG website.</li>
                  <li>The search word used (if the site is accessed via a search engine).</li>
                  <li>The type of connection and operating system.</li>
                  </ul>
                  <p>We only use this data to improve the visitor’s website experience. Please review our Cookie Policy to learn more about how we use Cookies.</p>
                  <p>When it comes to Cookies, we rely on consent given as the lawful basis under GDPR Article 6(1)(a).</p>
                </div>

                <div class="item marginLeft15">
                <h4>Inquiries</h4>
                  <p>When you send an inquiry to us through our contact form, we use the personal data that you have stated in the contact form to answer you. Any personal data received from you will not be used for any other purpose without your prior consent and knowledge and will not be disclosed.</p>
                  <p>We rely on a legitimate interest as the lawful basis under GDPR Article 6(1)(f) for the processing of data in connection to inquiries.</p>
                </div>

                <div class="item marginLeft15">
                <h4>Surveys</h4>
                  <p>In order to ensure that the services we offer meet your requirements, we may ask for your feedback in form of surveys and polls. Any feedback received from you will only be used for the purpose of improving our services and will not be disclosed.</p>
                  <p>We rely on your consent as the lawful basis under GDPR Article 6(1)(a) for the processing of data in connection with surveys.</p>
                </div>

                <div class="item marginLeft15">
                <h4>Interviews</h4>
                  <p>If we contact you to perform stakeholder interviews, any personal data received from you will not be used for any other purpose without your prior consent.</p>
                </div>

                <div class="item marginLeft15">
                <h4>eCommerce</h4>
                  <p>CYAAG’s use of ecommerce is limited to registration for a limited number of events each year. Individuals within companies provide their corporate information to register for an event. We use the data collected in order to process billing and orders for products/services you choose to purchase on our website.</p>
                  <p>We rely on fulfillment of contract as the lawful basis under GDPR Article 6(1)(b) for the processing of eCommerce Data.</p>
                </div>

                <div class="item marginLeft15">
                <h3>Personal Data Collected From Third Parties</h3>
                  <p>In some cases, we collect your personal data from third parties:</p>
                  <p>We receive a limited amount of data via lead generation programs. Contacts can change email preferences at any time and opt-out by following the links included in CYAAG emails for this purpose.</p>
                </div>

                <div class="item marginLeft15">
                <h3>Payment Information</h3>
                  <p>When you purchase services from us, we request you to state your payment card details (name on card, billing address [street address/city/state/country], card type [e.g. Visa], card number, expiration date, security code). We are using a secure third party to manage transactions and ecommerce payment processing.</p>
                  <p>Your payment information will be stored as long as the third party is entitled or obliged to store it pursuant to legislation. Read more about this directly with the third party</p>
                </div>

                <div class="item marginLeft15">
                <h3>Duration of Storage</h3>
                  <p>We will store your personal data until these are no longer necessary for us to process. In certain situations, it may be difficult to envisage an exact period, but the below paragraphs list our periods for the processing of your personal data.</p>
                </div>

                <div class="item marginLeft15">
                <h3>Member and Client Data</h3>
                <p>We store member company data and contact information of member companies for the duration of the membership with us and for a period of time thereafter to allow members to recover accounts if they decide to renew, to analyze the data for our own operations, and for historical and archiving purposes associated with our history as a membership organization. For more information on where and how long your personal data is stored, and for more information on your rights of erasure and portability, please contact our data protection officer at <a class="onOrange" href="mailto:admin@CYAAG.com">admin@CYAAG.com</a></p>
                  <p>Client data, i.e. data collected due to your subscription to our mailing list, will be erased as soon as possible after your deregistration to the mailing list.</p>
                </div>

                <div class="item marginLeft15">
                <h3>Human Resources</h3>
                <p>If you submit an application to us, your consent is required to store your application data longer than six months after receipt. The application will be stored in order for you to be taken into consideration for any future positions that have any relevance for your profile.</p>
                </div>

                <div class="item marginLeft15">
                <h3>Events and CYAAG Conference Data</h3>
                <p>As a main rule, information about participation to events and the CYAAG Conference are deleted as soon as possible after the Conference or event is over, unless CYAAG has legitimate and exceptional reasons to store the data for a longer period.</p>
                </div>

                <div class="item marginLeft15">
                <h3>Inquiries</h3>
                <p>Stored until six months after completion of processing of your inquiry.</p>
                </div>

                <div class="item marginLeft15">
                <h3>Surveys</h3>
                <p>Stored up to five years after receipt. To the extent possible, we will store your feedback in an anonymous form, and we have a long duration of storage in order to measure our own performance over time.</p>
                <p>In general, if we have reason to store your personal data as part of the protection of our legitimate interests, including, for example, legal disputes, we reserve our right to store your personal data for an extended period and minimum until the legal dispute has been determined.</p>
                </div>

                <div class="item marginLeft15">
                  <h3>Transfer of Your Personal Data</h3>
                  <p>We do not rent or sell personally identifiable information with other individuals or organizations.</p>
                  <p>However, we may transfer your personal data to third parties when it is necessary in order to provide you with our service. Third parties shall mean:</p>
                  <ul style={{marginLeft:30}}>
                  <li>Undertakings in the CYAAG Group</li>
                  <li>Business partners</li>
                  <li>Company</li>
                  <li>Security-cleared data processors/subcontractors, who are assisting us or the group with IT or other services</li>
                  </ul>
                  <p>When we transfer your personal data to business partners, you should be aware that they might have stored personal data concerning you collected by other means, e.g. if you have been in contact with them in another context.</p>
                  <p>We also transfer your personal data to the above or other third parties if we are obliged to do so according to legislation or in order to protect our or the group’s interests in legal disputes.</p>
                </div>

                <div class="item marginLeft15">
                <h3>For EU Citizens</h3>
                <h4>File Storage and Security</h4>
                <p>CYAAG partners with a security-cleared data processor to store files and data on secure servers. This data processor has self-certified under the EU-U.S. Privacy Shield Framework and thereby guarantees an appropriate standard of data protection and operates to an appropriate standard of data security.</p>
                <p>All data is accessed via secure connections in the United States.</p>
                <p>In spite of our efforts to establish a secure environment for the website, you should be aware that no information is completely secure on the internet. Therefore, you should always take the necessary safeguards on your own equipment.</p>
                </div>

                <div class="item marginLeft15">
                <h4>Your Rights</h4>
                <p>You have the right of access to the personal data we are processing concerning you, as well as to have your personal data updated, rectified, or erased, or to obtain a copy of your personal data. All requests shall be made in writing to <a class="onOrange" href="mailto:info.cyaag@gmail.com">info.cyaag@gmail.com</a></p>
                </div>

                <div class="item marginLeft15">
                <h4>Transfer of Personal Data to Third Countries</h4>
                <p>CYAAG partners with various IT vendors and from time to time. This will result in a transfer of personal data to a third country or international organization.</p>
                <p>In order to ensure a sufficient level of security for such transfer in accordance with the GDPR, CYAAG has chosen to work only with vendors that:</p>
                <ul style={{marginLeft:30}}>
                  <li>have certified compliance with the EU-U.S. Privacy Shield Framework, or</li>
                  <li>have entered into Standard Contractual Clauses with CYAAG.</li>
                  </ul>
                  <p>A copy of the Standard Contractual Clauses can be obtained by contacting <a class="onOrange" href="mailto:email—info.cyaag@gmail.com">email—info.cyaag@gmail.com.</a></p>
                </div>

                <div class="item marginLeft15">
                <h4>Complaints</h4>
                <p>If you want to lodge a complaint over our processing of your personal data, please contact us directly. If we cannot help you, you can lodge a complaint to the national Data Protection Authority.</p>
                </div>
                <div class="item marginLeft15">
                <h4>Changes</h4>
                <p>We recognize that data protection and privacy is an ongoing responsibility, so we reserve our right to make changes to this Data Protection and Privacy Policy from time to time as we undertake new personal data practices or adopt new privacy policies, etc. If such changes are substantial, we will notify you via email, provided that we have your email address.</p>
                </div>

                <div class="item marginLeft15">
                <h3>Cookie Policy</h3>
                <h4>General Information</h4>
                <p>We use Cookies and other tracking mechanisms (collectively “Cookies”) on the platform in order to improve our services, for targeting of advertisements, and to better understand and improve our communications efforts.</p>
                <p>On your first visit to the website, you will be asked to accept that we place one or more Cookies on your device. Your continued use of the platform constitutes acceptance hereof. You can delete Cookies by following the instructions near the bottom of this page.</p>
                </div>


                <div class="item marginLeft15">
                <h4>What are Cookies?</h4>
                <p>A Cookie is a small text file that is stored on your computer. Cookies allow us to:</p>
                <ul style={{marginLeft:30}}>
                  <li>Recognize you when you visit the platform for the purpose of giving you a more personal experience.</li>
                  <li>Observe your habits on the website. The better we understand which products you are interested in, the better our ability to tailor solutions to meet your requirements.</li>
                  <li>Obtain information about the number of visitors and their behavior.</li>
                  <li>Improve the performance of our websites.</li>
                  <li>Determine interest in the topics and services we provide.</li>
                  <li>Measure the effectiveness of our communications.</li>
                  </ul>
                </div>

                <div class="item marginLeft15">
                <h3>Our Types of Cookies</h3>
                <h4>Strictly Necessary Cookies</h4>
                <p>These Cookies are required in order for the website to work properly. If you do not allow these Cookies, some parts of the website may not work properly, such as logging in, submitting forms, and other standard website behavior. The only way to disable these Cookies is via your browser.</p>
                <p><b>Cookie Name:</b> exp_csrf_token</p>
                <p><b>Purpose:</b> If you log in to our website, this is a security Cookie used to identify the user and prevent Cross Site Request Forgery attacks.</p>
                <p><b>Storage Period:</b> Session (expires after quitting your browser)</p>
                <p><b>Cookie Name:</b> exp_last_activity</p>
                <p><b>Purpose:</b> Records the time of the last page load. Used in conjunction with the exp_last_visit Cookie.</p>
                <p><b>Storage Period:</b> One year</p>
                <p><b>Cookie Name:</b> exp_last_visit</p>
                <p><b>Purpose:</b> Date of the user’s last visit. Can be shown as a statistic for logged in visitors.</p>
                <p><b>Storage Period:</b> One year</p>
                <p><b>Cookie Name:</b> exp_remember</p>
                <p><b>Purpose:</b> Determines whether a user is automatically logged in upon visiting the website.</p>
                <p><b>Storage Period:</b> Session (expires after quitting your browser)</p>
                <p><b>Cookie Name:</b> PHPSESSID</p>
                <p><b>Purpose:</b> PHP's session cookie.</p>
                <p><b>Storage Period:</b> Session (expires after quitting your browser)</p>
                </div>

                <div class="item marginLeft15">
                <h4>Remarketing Services</h4>
                <p>From time to time, our website may use a remarketing advertising service. We use remarketing services provided by Google, Twitter, and LinkedIn to show our ads on websites across the internet. With remarketing, you may see ads for our products, services, or events based on your past activity, such as visiting our websites or clicked on a marketing email.</p>
                <p>Here’s an example of how remarketing works: Suppose you visit a website that sells watches, but you do not buy a watch on your first visit to that website. The website owner might like to encourage you to revisit its website and buy a watch by showing you its ads again on other websites you visit.</p>
                <p>We use remarketing for similar purposes, so you may see advertisements for our products, services, or events like the annual SDG Conference as you search the internet. For this to happen, the advertising platform will read a Cookie that is already in your browser, or it will place a Cookie in your browser when you visit one of our websites or other sites using remarketing.</p>
                <p>This can only happen if your browser is set to let it happen. You can set preferences for how Google, Twitter, and LinkedIn advertise to you by updating your privacy settings:</p>
                <ul style={{marginLeft:30}}>
                  <li>Google: <a target="_blank" href="https://www.google.com/settings/ads/onweb" class="onOrange">https://www.google.com/settings/ads/onweb</a></li>
                  <li>Twitter: <a target="_blank" href="https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads"class="onOrange">https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads</a></li>
                  <li>LinkedIn: <a target="_blank" href="https://www.linkedin.com/help/linkedin/answer/62931/manage-advertising-preferences" class="onOrange">https://www.linkedin.com/help/linkedin/answer/62931/manage-advertising-preferences</a></li>
                  </ul>
                  <p>And if you would like, you can opt out of interest-based advertising permanently by changing your privacy options in your web browser.</p>
                </div>

                <div class="item marginLeft15">
                <h4>Reject or Delete Cookies</h4>
                <p>You can disable Cookies in your browser, including by deleting your browser history. See how at <a target="_blank" class="onOrange" href="https://www.lifewire.com/how-to-delete-cookies-2617981">https://www.lifewire.com/how-to-delete-cookies-2617981.</a></p>
                <p>You can always reject Cookies by changing the settings of your browser.</p>
                <p>If you choose to block or delete our Cookies, you might not be able to access certain features on the platform, which may affect how the platform works.</p>
                </div>

                <div class="item marginLeft15">
                <h4>Contact Information</h4>
                <p>In case you have questions about our Privacy Policy, please contact us at <a class="onOrange" href="mailto:compliance@cyaag.com">compliance@cyaag.com</a></p>
                </div>

              </div>
            </div>
          </div>
          <CopyRightLanding />
          <FixedFooterIcon style={{width: "100% !important"}}/>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicyLanding;
