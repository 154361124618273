
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "../../components/Header";
import {
  AcitvityTableComponent,
} from "../../components/Table";
import { userProfile } from "../../redux/action/UserProfileActions";
import styled from 'styled-components';
import { Box} from "@mui/material";

const HomeScreenMainContent = styled(Box)(({ theme }) => ({
  padding: '80px 0 100px 0px',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    padding: '80px 0 100px 330px',
  },
})); 

const ActivityListScreen = (props) => {
  const dispatch = useDispatch();
  const [activityList, setActivityList] = useState([]);

  const getActivityList = async () => {
    try {
      const res = await axios.get(`/report/volunteeractivity`);
      setActivityList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    dispatch(userProfile(localStorage.getItem("userid")));
    getActivityList();
  }, []);

  return (
    <div>
      <ResponsiveDrawer {...props} />
      <HomeScreenMainContent
        id="homepage-main"
      >
        <span style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Activity List</span>

        <AcitvityTableComponent rows={activityList} />
      </HomeScreenMainContent>
    </div>
  );
};

export default ActivityListScreen;
