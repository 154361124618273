
import React, { useState, useRef } from 'react';
import { useMediaQuery, Avatar, Chip, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { Button as MuiButton } from '@mui/material';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { isArray, isEmpty } from "lodash";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';
import styled, { keyframes } from 'styled-components';

const CampaignTypeWrapper = styled('div')({
  padding: 1,
  margin: 2,
  borderRadius: 4,
  display: 'block',
});

const StyledButton = styled(MuiButton)(({ theme }) => ({
  color: 'white',
  padding: '6px',
  fontSize: '12px',
}));

const blinking = keyframes`
  0% { filter: brightness(0.9); }
  50% { filter: brightness(1.1); }
  100% { filter: brightness(1); }
`;

const BlinkingSVG = styled.svg`
  cursor: pointer;
  animation: ${blinking} 1s infinite;
`;


const BlinkingDiv = styled.div`
  width: 40px;
  height: 40px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  cursor: pointer;
  margin-top: 7px;
  animation: ${blinking} 1s infinite;
`;

const SVGCampLeaderboard = (props) => (
  <BlinkingSVG width="40" height="40" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <rect width="40" height="40" fill="url(#pattern0_231_5)" />
    <defs>
      <pattern id="pattern0_231_5" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_231_5" transform="scale(0.02)" />
      </pattern>
      <image id="image0_231_5" width="50" height="50" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADPklEQVR4nO2Y20+SYRzHadnW/9PWVRsXvFt31tq8wHnAWWBBaFlgIIqkIWLiAhEUrAulPKQiMkhF8Ihazo43WRq1aalbuixMgV97XivFUHhBfNf2fLfPxgaM3+f5PacXBgMHBwcHBwcHh4yXIMReFmvDy2JBIkwQhH+cxbrKoCPAYBzzEsTmBz4flqTShJjLy0My67SIuJnMFDSaKzIZ+DWahFiSSFBnArSIPOOeOoFEPgqFCXfEJxAgkSAtIu5s5slE10YYBAH0iJQyU7rL2LDSWw0wakqIpZ4q6C5j09QRLGJKTkfS0tqOn5fYTqdKewkqXJB0ndXLr4GvWxN16nzruwebHkNyp9Y5qd2aKrFDvIxZ9PsW6Hfp4GWTCHoU6bBsUydXJFViD0pa5kHtWKXMQSI/3fWkANoQEEchAsWtn5IiMqkXkh2JJvK8UwmF+oIQx1w0SpUcs9iZaRafIUV42mkQNr6mTLSphZh7JI8q4u2ogCyTCG5ZVZQpaC0LccziRQav+D5cLm0OXZFbglThy5tDrzoOT8Q42UQZlUsL2SZRgHFYhxoWGd3pSFaccEwiiNiRrSEjzFpkMFEngAm9EN62yCAwZExaR7669DD2uCIu+pql0KDMCUUUmWkshCcqDkwbr4O3TkAWMmXIT5rIfmwM1oGv/TY5kL52BbkT7rt1RxL5bK2EwPBOB1BXrIp0CA43UC5msUsJY1o+rDqj3wBgD/1VuTCgzoWhWh75+/aKTFhz1sYuspcZ0w2wKthxiSTC2q6ifxcML0w34xNZ79eSIzGu4x+pBESoA9X65kERdZFVh4ZcK87KHPg+oDvy4rc8BvhiVZHrw13DA1f1RfAP6qmJLHTdAVt5BrhruPCDBglAu5lD8/euhmYFWvR7p/eBIss9anJxTdXnhy16utj0GGC+TUFeQp8aCmIX8Wi44FBmk88RaG7+gW6p4do86C3PiF1k9/V7N/GcBfHid+nC1iUaVDS90FYcswh6MxIHPeUdNsu27ek9ePcSeGq45GtnJSexc4QOQiPbMu8flsI7SwksdCohOPLvORYmgj6MvvQ/Mmsp2RH575Gz6fm7FQcHBwcHB4eRnPwCG4GUygmgFQIAAAAASUVORK5CYII=" />
    </defs>
  </BlinkingSVG>
);
const CampaignCard = ({
  organizationlogo,
  name,
  logo,
  video,
  sponsor,
  tag,
  SDGCampMapping,
  goalquantity,
  unitofgoal,
  committedcount,
  achievedcount,
  submittedcount,
  campaigntype,
  campid,
  maxpointstobeearned,
  dayrem,
  hourrem,
  minrem,
  profileResponse,
  index,
  goal,
  desc,
  whattodo,
  guidelines,
  landing,
  status,
  directlink,
  showcamplb,
  participatepage,
  teamdetails,
  currentTabIndex,
}) => {
  console.log('Current Tab Index:', currentTabIndex);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [openSponsoredBy, setOpenSponsoredBy] = useState(false);
  const [openOrganizedBy, setOpenOrganizedBy] = useState(false);
  const [openSDG, setOpenSDG] = useState(false);


  const handleClickOpenSponsoredBy = () => {
    setOpenSponsoredBy(true);
  };

  const handleCloseSponsoredBy = () => {
    setOpenSponsoredBy(false);
  };

  const handleClickOpenOrganizedBy = () => {
    setOpenOrganizedBy(true);
  };

  const handleCloseOrganizedBy = () => {
    setOpenOrganizedBy(false);
  };

  const handleClickOpenSDG = () => {
    setOpenSDG(true);
  };

  const handleCloseSDG = () => {
    setOpenSDG(false);
  };

  const handleImageLoad = async (img) => {
    const im = await Promise.all(img);
    console.log(im);
    if (im) {
      return true;
    } else {
      return false;
    }
  };


  const stopAllVideos = (i) => {
    const videos = document.querySelectorAll('video');
    videos.forEach(video => {
      if (video.id !== "video-" + i) {
        video.pause();
      }
    });
  };

  const svg = `
  <svg width="40" height="40" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <rect width="40" height="40" fill="url(#pattern0_231_5)" />
    <defs>
      <pattern id="pattern0_231_5" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlink:href="#image0_231_5" transform="scale(0.02)" />
      </pattern>
      <image id="image0_231_5" width="50" height="50" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADPklEQVR4nO2Y20+SYRzHadnW/9PWVRsXvFt31tq8wHnAWWBBaFlgIIqkIWLiAhEUrAulPKQiMkhF8Ihazo43WRq1aalbuixMgV97XivFUHhBfNf2fLfPxgaM3+f5PacXBgMHBwcHBwcHh4yXIMReFmvDy2JBIkwQhH+cxbrKoCPAYBzzEsTmBz4flqTShJjLy0My67SIuJnMFDSaKzIZ+DWahFiSSFBnArSIPOOeOoFEPgqFCXfEJxAgkSAtIu5s5slE10YYBAH0iJQyU7rL2LDSWw0wakqIpZ4q6C5j09QRLGJKTkfS0tqOn5fYTqdKewkqXJB0ndXLr4GvWxN16nzruwebHkNyp9Y5qd2aKrFDvIxZ9PsW6Hfp4GWTCHoU6bBsUydXJFViD0pa5kHtWKXMQSI/3fWkANoQEEchAsWtn5IiMqkXkh2JJvK8UwmF+oIQx1w0SpUcs9iZaRafIUV42mkQNr6mTLSphZh7JI8q4u2ogCyTCG5ZVZQpaC0LccziRQav+D5cLm0OXZFbglThy5tDrzoOT8Q42UQZlUsL2SZRgHFYhxoWGd3pSFaccEwiiNiRrSEjzFpkMFEngAm9EN62yCAwZExaR7669DD2uCIu+pql0KDMCUUUmWkshCcqDkwbr4O3TkAWMmXIT5rIfmwM1oGv/TY5kL52BbkT7rt1RxL5bK2EwPBOB1BXrIp0CA43UC5msUsJY1o+rDqj3wBgD/1VuTCgzoWhWh75+/aKTFhz1sYuspcZ0w2wKthxiSTC2q6ifxcML0w34xNZ79eSIzGu4x+pBESoA9X65kERdZFVh4ZcK87KHPg+oDvy4rc8BvhiVZHrw13DA1f1RfAP6qmJLHTdAVt5BrhruPCDBglAu5lD8/euhmYFWvR7p/eBIss9anJxTdXnhy16utj0GGC+TUFeQp8aCmIX8Wi44FBmk88RaG7+gW6p4do86C3PiF1k9/V7N/GcBfHid+nC1iUaVDS90FYcswh6MxIHPeUdNsu27ek9ePcSeGq45GtnJSexc4QOQiPbMu8flsI7SwksdCohOPLvORYmgj6MvvQ/Mmsp2RH575Gz6fm7FQcHBwcHB4eRnPwCG4GUygmgFQIAAAAASUVORK5CYII=" />
    </defs>
  </svg>
`;

  const encodedSvg = btoa(svg);
  const dataUrl = `data:image/svg+xml;base64,${encodedSvg}`;


  const renderHTML = (htmlContent) => {
    return { __html: htmlContent };
  };

  const handleLinkClick = (event) => {
    event.preventDefault();
  };

  return (
    <div class="item">
      <div class="">
        <div class="campaigns-wrap campaigns-wrap-new">
          <div>
            <div>
              {/* <div className="textcamphome" style={{ flexDirection: "row", display: "inline-flex" }}>
                <img src={organizationlogo} style={{ width: "auto", marginBottom: 15, marginTop: 5, border: "0.5px solid silver" }} alt="cYAAG" />
                <h5 class="campaign-title-new" style={{ marginLeft: "5px", textWrap: "wrap" }}>{name}</h5>
              </div> */}
              <div class="campaign-img">
                <div class="video-wrapper">
                  <div
                    class="video-container"
                    id="video-container"
                    onClick={stopAllVideos(index)}
                  >
                    <video
                      controls
                      controlsList="nodownload"
                      key={index}
                      id={`video-${index}`}
                      preload="auto"
                      poster={logo}
                      onPlay={() => stopAllVideos(index)}
                    >
                      <source
                        src={video}
                        type="video/mp4"
                        display="none"
                      />
                    </video>
                    <div>
                    </div>
                  </div>
                </div>
                <h5 class="campaign-title-new" style={{ textWrap: "wrap", fontSize: isMobile ? '12px' : '16px' }}>{name}</h5>
              </div>


              <div style={{ marginTop: "5px", }}>
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                  <div
                    class="campaign-title-new"
                    style={{
                      color: "#808080",
                      padding: "5px 0px",
                      maxHeight: "40px",
                      verticalAlign: "middle",
                      marginTop: "10px",
                      cursor: 'pointer',
                      fontSize: 12,
                    }}
                    onClick={handleClickOpenOrganizedBy}
                  >
                    <a class="story-signin" style={{ padding: "6px 8px", fontSize: isMobile ? 10 : 14 }}>
                      Organized By <i class="fa fa-arrow-right"></i>
                    </a>
                  </div>
                  <div
                    class="campaign-title-new"
                    style={{
                      color: "#808080",
                      padding: "5px 0px",
                      maxHeight: "40px",
                      verticalAlign: "middle",
                      marginTop: "10px",
                      cursor: 'pointer',
                      fontSize: 12,
                    }}
                    onClick={handleClickOpenSponsoredBy}
                  >
                    <a class="story-signin" style={{ padding: "6px 8px", fontSize: isMobile ? 10 : 14 }}>
                      Sponsored By <i class="fa fa-arrow-right"></i>
                    </a>
                  </div>
                  <div style={{
                    color: "#808080",
                    padding: "2px 0px",
                    maxHeight: "40px",
                    verticalAlign: "middle",
                    marginTop: "10px",

                  }}
                    onClick={handleClickOpenSDG}
                  >
                    {isArray(tag) && !isEmpty(tag) && tag.map((tagItem) =>
                      SDGCampMapping
                        .filter((item) => item.cat === tagItem.label)
                        .map((mappedItem) => (
                          <span
                            class="hash-styles-sdg"
                            style={{
                              cursor: "initial",
                              color: "white",
                              marginTop: "2px",
                              backgroundColor: "#3082f7",
                              padding: "6px 8px",
                              borderRadius: "4px",
                              fontSize: 12,
                              cursor: 'pointer',
                            }}
                          >
                            SDG {mappedItem.sdg}
                          </span>
                        ))
                    )}
                  </div>
                </div>

                <Dialog open={openOrganizedBy} onClose={handleCloseOrganizedBy}>
                  <DialogTitle>Organized By</DialogTitle>
                  <DialogContent>
                    <div class="row sponserHomeWrapper" style={{ textAlign: "center", marginBottom: "0px" }}>
                      <div class="" >
                        {/* {isArray(sponsor) &&
                          sponsor?.map(
                            (tile, index) => (
                              handleImageLoad(tile.sponsorlogo) ? ( */}
                        <img
                          style={{
                            width: "auto",
                            maxHeight: "50px"
                          }}
                          src={organizationlogo}
                          key='orglogo' alt="cYAAG"
                        />
                        {/* ) : null
                            )
                          )} */}
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>

                <Dialog open={openSponsoredBy} onClose={handleCloseSponsoredBy}>
                  <DialogTitle>Sponsored By</DialogTitle>
                  <DialogContent>

                    <div class="row sponserHomeWrapper" style={{ textAlign: "center", marginBottom: "0px" }}>
                      <div class="" >
                        {isArray(sponsor) &&
                          sponsor?.map(
                            (tile, index) => (
                              handleImageLoad(tile.sponsorlogo) ? (
                                <img
                                  style={{
                                    width: "auto",
                                    maxHeight: "50px"
                                  }}
                                  src={tile.sponsorlogo}
                                  key={index} alt="reward"
                                />
                              ) : null
                            )
                          )}
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>

                <Dialog open={openSDG} onClose={handleCloseSDG}>
                  <DialogTitle style={{ fontSize: 15, textAlign:'center'}}>Sustainable Development Goals (SDG)</DialogTitle>
                  <DialogContent>
                    <div class="row sponserHomeWrapper" style={{ textAlign: "center", marginBottom: "0px" }}>
                      <div class="" >
                        <span style={{ display: "block", marginBottom: "10px" , fontStyle: "italic", fontWeight: 600, fontSize: 14}}>
                          This campaign focuses on below SDG target
                        </span>
                        <span>
                          {isArray(tag) && !isEmpty(tag) && tag.map((tagItem) =>
                            SDGCampMapping
                              .filter((item) => item.cat === tagItem.label)
                              .map((mappedItem) => (
                                <span
                                  class="hash-styles-sdg"
                                  style={{
                                    cursor: "initial",
                                    color: "white",
                                    marginTop: "2px",
                                    backgroundColor: "#3082f7",
                                    padding: "8px 8px",
                                    borderRadius: "2px",
                                    fontSize: 14,
                                    // cursor: 'pointer',
                                  }}
                                >
                                  {mappedItem.cat}
                                </span>
                              ))
                          )}
                        </span>

                        <span style={{ textAlign: "center", display: "block", marginTop: '20px', fontSize: 13}}>
                          The Sustainable Development Goals (SDGs)<br />
                          are a set of 17 global goals established<br />
                          by the United Nations in 2015 as part of<br />
                          2030 Agenda for Sustainable Development.<br />
                          
                        </span>

                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>

              {/* <div className="campaign-hashtags" style={{ textAlign: "right" }}>
                {tag.map((tagItem) =>
                  SDGCampMapping
                    .filter((item) => item.cat === tagItem.label)
                    .map((mappedItem) => (
                      <a
                        key={tagItem.tag}
                        class="hash-styles"
                        style={{
                          cursor: "initial",
                          marginRight: "5px",
                        }}
                      >
                        {tagItem.tag}{" "}
                        <span
                          class="hash-styles-sdg"
                          style={{
                            cursor: "initial",
                            color: "white",
                            marginTop: "2px",
                            backgroundColor: "#3082f7",
                            padding: "2px 8px",
                            borderRadius: "2px"

                          }}
                        >
                          SDG {mappedItem.sdg}
                        </span>
                      </a>
                    ))
                )}
              </div> */}
            </div>

            <CampaignTypeWrapper>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap={0.5}
              >
                <h5 class="campaign-goal">
                  <a
                    class="hash-styles-goal"
                    style={{
                      padding:
                        "0px 5px 0px 0px",
                      cursor: "initial",
                    }}
                  >
                    <span style={{ fontSize: "16px", fontWeight: 700 }}>Goal</span>: <span class="text-6" style={{ color: "black" }}>{goalquantity}{" "} {unitofgoal}</span>
                  </a>
                </h5>
              </Stack>
            </CampaignTypeWrapper>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div>
                <div class="campaigns-details">
                  <div class="p-bar-new">
                    <div class="row ">
                      <div class="col-6">
                        <div>
                          <h6 style={{ textAlign: 'left' }}>
                            <span style={{ color: "#AAAAAA" }}>Pledged: </span>{" "} <span style={{ backgroundColor: "#FFDB58" }}>
                              {Math.round(
                                (committedcount)
                              )}
                            </span>
                          </h6>
                        </div>
                      </div>
                      <div class="col-6">
                        <div
                          style={{ textAlign: "right", }}
                        >
                          <h6>
                            <span style={{ color: "#AAAAAA" }}>Achieved: </span>
                            {" "}
                            <span style={{ backgroundColor: "#A9D18E" }}> {achievedcount}</span>
                            {" "}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="progress br-30 campaign-progress" style={{ position: "relative" }}>
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${(committedcount / goalquantity) * 100}%`,
                          backgroundColor: "#FFDB58",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          zIndex: 1,
                          height: "100%"
                        }}
                        aria-valuenow={Math.round((committedcount / goalquantity) * 100)}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>

                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${(achievedcount / goalquantity) * 100}%`,
                          backgroundColor: "#A9D18E",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          zIndex: 2,
                          height: "100%"
                        }}
                        aria-valuenow={Math.round((achievedcount / goalquantity) * 100)}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>

                  <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                    <div style={{ flex: 6, display: "flex", alignItems: "center" }}>
                      <h6 style={{ display: "flex", alignItems: "center", margin: 0 }}>
                        <span style={{ color: "#AAAAAA" }}>Signed Up:</span>
                        <span style={{ backgroundColor: "#DAE8FD", marginLeft: 5, marginRight: 5 }}>{submittedcount}</span>

                        {/* {showcamplb === "yes" && (status === "Completed" || status === "Active" || status === "Upcoming" || status === "Paused") ? ( */}
                        {(showcamplb === "yes" && landing === "no") &&(
                          // <SVGCampLeaderboard style={{ marginTop: '7px'}} onClick={() => navigate(`/camp/leaderboard/${campid}`)} />
                          // <SVGCampLeaderboard key={`tab-${currentTabIndex}`}/>
                          // <SVGCampLeaderboard />
                          // <img
                          <BlinkingDiv
                            src={dataUrl}
                            // width="40"
                            // height="40"
                            // alt="Leaderboard Icon"
                            // style={{ marginTop: '7px', cursor: 'pointer', animation: '1s infinite blinking' }} 
                            onClick={() => navigate(`/camp/leaderboard/${campid}`)}
                          />
                        )
                        }
                      </h6>
                    </div>
                    <div style={{ flex: 4, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                      <Chip
                        key={campaigntype}
                        fontSize="small"
                        size="small"
                        label={campaigntype}
                      />
                    </div>
                  </div>
                  {
                    (landing === "no" && participatepage === "no" && status !== "Completed") ?
                      <div class="row">
                        <div class="col-md-4 col-6 customHalfWidth">
                          <div class="cam-cols">
                            <div class="timer-here">
                              <div class="text-center">
                                <span class="campaign-time-title" style={{ marginLeft: 0 }}>
                                  Earn upto
                                </span>
                              </div>
                              <div style={{ textAlign: "center" }}>
                                <div
                                  class="number"
                                  style={{ padding: "5px 0px 0px 0px", boxShadow: "none" }}
                                >
                                  {maxpointstobeearned}
                                </div>
                                <div
                                  class="label"
                                  style={{ padding: "0px 0px 0px 0px" }}
                                >
                                  Points
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4 col-6 customHalfWidth">
                          <div class="cam-cols">
                            <div class="timer-here">
                              <div class="text-center">
                                <span class="campaign-time-title">
                                  Time Left
                                </span>
                              </div>
                              <div>
                                <ul id="countdown" style={{ listStyle: "none" }}>
                                  <li id="hours">
                                    <div class="number">
                                      {dayrem}
                                    </div>
                                    <div class="label">
                                      Days
                                    </div>
                                  </li>
                                  <li id="minutes">
                                    <div class="number">
                                      {hourrem}
                                    </div>
                                    <div class="label">
                                      Hours
                                    </div>
                                  </li>
                                  <li id="seconds">
                                    <div class="number">
                                      {minrem}
                                    </div>
                                    <div class="label">
                                      Mins
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 customFullWidth">
                          <div class="par-btn-here">
                            <div class="text-center">
                              {
                                directlink === "no" ?
                                  <a class="par-now-new">
                                    <StyledButton
                                      onClick={() => navigate(
                                        `/participate/campaign/${campid}/${profileResponse?.phoneno}`
                                      )
                                      }
                                      style={{ color: "white", padding: "6px", fontSize: "12px" }}
                                      name={"activepnow" + campid}
                                    >
                                      Participate Now{" "}
                                      <i
                                        class="fa fa-arrow-right"
                                        aria-hidden="true"
                                      ></i>
                                    </StyledButton>
                                  </a>
                                  :
                                  <a class="par-now-new">
                                    <StyledButton onClick={() => navigate(
                                      `/login`
                                    )
                                    }
                                      style={{ color: "white", padding: "6px", fontSize: "12px" }}
                                      name={"activepnow" + campid}
                                    >
                                      Participate Now{" "}
                                      <i
                                        class="fa fa-arrow-right"
                                        aria-hidden="true"
                                      ></i>
                                    </StyledButton>
                                  </a>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <div class="row">
                        <div class="col-md-5 customHalfWidth">
                          <div class="cam-cols">
                            <div class="timer-here">
                              <div class="text-center">
                                <span class="campaign-time-title" style={{ marginLeft: 0 }}>
                                  Earn upto
                                </span>
                              </div>
                              <div style={{ textAlign: "center" }}>
                                <div
                                  class="number"
                                  style={{ padding: "5px 0px 0px 0px", boxShadow: "none" }}
                                >
                                  {maxpointstobeearned}
                                </div>
                                <div
                                  class="label"
                                  style={{ padding: "0px 0px 0px 0px" }}
                                >
                                  Points
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-6 ">
                          <div class="cam-cols">
                            <div class="timer-here">
                              <div class="text-center">
                                <span class="campaign-time-title">
                                  Time Left
                                </span>
                              </div>
                              <div>
                                <ul id="countdown" style={{ listStyle: "none" }}>
                                  <li id="hours">
                                    <div class="number">
                                      {dayrem}
                                    </div>
                                    <div class="label">
                                      Days
                                    </div>
                                  </li>
                                  <li id="minutes">
                                    <div class="number">
                                      {hourrem}
                                    </div>
                                    <div class="label">
                                      Hours
                                    </div>
                                  </li>
                                  <li id="seconds">
                                    <div class="number">
                                      {minrem}
                                    </div>
                                    <div class="label">
                                      Mins
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  }
                  <div class="mr-15 text-center">
                    <a class="campaign-signin">
                      Join Now{" "}
                      <i
                        class="fa fa-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          landing === "no" &&
          <div class="logged-cam-detai">
            <div class="aft-log-acc">
              <div class="widget-content widget-content-area creative2-accordion-content">
                <div id="creativeAccordion">
                  <div className="card mb-3">
                    <div className="card-header" id={`creative2headingOne${status}${index}`}>
                      <h5 className="mb-0 mt-0">
                        <span
                          role="menu"
                          className="changeIcon collapsed"
                          data-toggle="collapse"
                          data-target={`#creative2CollapseOne${status}${index}`}
                          aria-expanded="false"
                          aria-controls={`creative2CollapseOne${status}${index}`}
                        >
                          <span className="icon">
                            <i className="fa fa-plus"></i>
                            <i className="fa fa-minus"></i>
                          </span>
                          <span className="text ml-2" style={{ textWrap: "wrap" }}>
                            How to achieve the goal?
                          </span>
                        </span>
                      </h5>
                    </div>

                    <div
                      id={`creative2CollapseOne${status}${index}`}
                      className="collapse"
                      aria-labelledby={`creative2headingOne${status}${index}`}
                      data-parent="#creativeAccordion"
                    >
                      <div className="card-body" style={{ textAlign: 'left' }}>
                        <p className="mb-3">
                          1. {goal}
                        </p>
                        <p className="mb-3">
                          2. {desc}
                        </p>
                        <p className="mb-3">
                          3. Win up to <b>{maxpointstobeearned}</b> points by participating or inviting motivated friends to join this campaign. Points show your social impact, get you on the leaderboard, and as you accumulate more points, you become eligible for a variety of rewards.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card mb-3">
                    <div className="card-header" id={`creative2HeadingTwo${status}${index}`}>
                      <h5 className="mb-0 mt-0">
                        <span
                          role="menu"
                          className="changeIcon collapsed"
                          data-toggle="collapse"
                          data-target={`#creative2CollapseTwo${status}${index}`}
                          aria-expanded="false"
                          aria-controls={`creative2CollapseTwo${status}${index}`}
                        >
                          <span className="icon">
                            <i className="fa fa-plus"></i>
                            <i className="fa fa-minus"></i>
                          </span>
                          <span className="text ml-2" style={{ textWrap: "wrap" }}>
                            How to participate?
                          </span>
                        </span>
                      </h5>
                    </div>
                    <div
                      id={`creative2CollapseTwo${status}${index}`}
                      className="collapse"
                      aria-labelledby={`creative2HeadingTwo${status}${index}`}
                      data-parent="#creativeAccordion"
                    >
                      <div className="card-body" style={{ textAlign: 'left' }}>
                        <p className="mb-3">
                          <div style={{ wordWrap: "break-word" }} dangerouslySetInnerHTML={renderHTML(whattodo)} />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card mb-3">
                    <div className="card-header" id={`creative2HeadingThree${status}${index}`}>
                      <h5 className="mb-0 mt-0">
                        <span
                          role="menu"
                          className="changeIcon collapsed"
                          data-toggle="collapse"
                          data-target={`#creative2CollapseThree${status}${index}`}
                          aria-expanded="false"
                          aria-controls={`creative2CollapseThree${status}${index}`}
                        >
                          <span className="icon">
                            <i className="fa fa-plus"></i>
                            <i className="fa fa-minus"></i>
                          </span>
                          <span className="text ml-2" style={{ textWrap: "wrap", textAlign: 'left' }}>
                            How to win points and rewards?
                          </span>
                        </span>
                      </h5>
                    </div>
                    <div
                      id={`creative2CollapseThree${status}${index}`}
                      className="collapse"
                      aria-labelledby={`creative2HeadingThree${status}${index}`}
                      data-parent="#creativeAccordion"
                    >
                      <div className="card-body" style={{ textAlign: 'left' }}>
                        <p className="mb-3">
                          <div style={{ wordWrap: "break-word" }} dangerouslySetInnerHTML={renderHTML(guidelines)} />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            {
              teamdetails !== undefined &&
              <div style={{ marginTop: "5px" }}>
                <div
                  className="campaign-title-new"
                  style={{
                    color: "#808080",
                    padding: "5px 0px",
                    maxHeight: "40px",
                    verticalAlign: "middle",
                    marginTop: "10px",
                    fontWeight: 800,
                  }}
                >
                  Active Teams..
                </div>
                <div className="row sponserTeamWrapper" style={{ textAlign: "center", marginBottom: "0px", marginLeft: "5px", marginRight: "5px" }}>
                  <div className="scrollable-div">
                    {Array.isArray(teamdetails) && teamdetails.length > 0 ? (
                      teamdetails.map((tile, index) =>
                        handleImageLoad(tile.logo) ? (
                          tile.visibility === "public" ?
                            <div key={index} style={{ position: "relative", display: "inline-flex" }}>
                              <Link to={`/team/home/${tile.teamid}`} key={index}>
                                <img
                                  style={{
                                    width: "auto",
                                    maxHeight: "50px"
                                  }}
                                  src={tile.logo}
                                  key={index}
                                  alt="reward"
                                />
                              </Link>
                            </div>
                            :
                            <div key={index} style={{ position: "relative", display: "inline-flex" }}>
                              <Link to="#" onClick={handleLinkClick} key={index}>
                                <img
                                  style={{
                                    width: "auto",
                                    maxHeight: "50px",
                                    cursor: "default"
                                  }}
                                  src={tile.logo}
                                  key={index}
                                  alt="reward"
                                />
                              </Link>
                              <LockIcon
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  fontSize: 20,
                                  color: 'red'
                                }}
                              />
                            </div>

                        ) : null
                      )
                    ) : (
                      <div
                        style={{
                          width: "auto",
                          minHeight: "54px"
                        }}
                      >
                        No Teams
                      </div>
                    )}
                  </div>
                </div>

                <style jsx>{`
                .scrollable-div {
                  overflow-x: auto;
                  white-space: nowrap;
                }
                /* Custom scrollbar styles for Webkit browsers */
                .scrollable-div::-webkit-scrollbar {
                  height: 2px; 
                }
                .scrollable-div::-webkit-scrollbar-track {
                  background: #f1f1f1; 
                }
                .scrollable-div::-webkit-scrollbar-thumb {
                  background: #888; 
                  border-radius: 10px; 
                }
                .scrollable-div::-webkit-scrollbar-thumb:hover {
                  background: #555; 
                }
                .scrollable-div {
                  scrollbar-width: thin; 
                  scrollbar-color: #888 #f1f1f1; 
                }
            `}</style>
              </div>
            }
            {
              teamdetails !== undefined &&
              <hr />
            }
          </div>
        }
      </div>
    </div>
  );
}

export default CampaignCard;
