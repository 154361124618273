import React from 'react';

const SVGRedeemed = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
<rect width="30" height="30" fill="url(#pattern0_140_1367)"/>
<defs>
<pattern id="pattern0_140_1367" patternContentUnits="objectBoundingBox" width="1" height="1">
<use xlinkHref="#image0_140_1367" transform="scale(0.02)"/>
</pattern>
<image id="image0_140_1367" width="50" height="50" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD7klEQVR4nO1ZTYgUVxDuaNAY1IMmBzUBETF4UpAEc4gRzGED63bVykCMoCKiRyMIHhQSSMgKOUVJVtlAEg8ielNcEH8682ocVlkUfw/iuiSwG6der1ldMKu7caR6p2feNNM9PTuzDGv6g3eY6fdq6qtXVV81Y1kJEiRoGvJ3UrOYYIcmOK0VdrOCH1jBCms6QWfaFmuCm5owby4mGNeEu6udH+htfdtqNvqdbW8xYU+QRJGMwpecsT8Jnvsrm5rDhCkmuMAEl6xmQxPuC9zCcVa4lxX8WfxeYbe/3yVcqwm6WOFTk2wuu3H5lDo6nE0tGMrAxzrT9oHUgfks73z6JiscMBz6zX/GBJ8bBDUTfsUKbkfc3PdTQoDTuEgKt5DnhcjCM1bwe45w1cSe9nUlR+C5m00t8c/n0vamMKeNMy9Kn2FQAtNQEoMq9a4m7A91gGCcFR7RCr81InreL3xWcIAJ/wmtGYWOzuAWIa4JR4vP02A3lAgT/FTmgHcT+DKkKxWJsMIzTDhWOQAwyAo7grWgFZw0bJxpLBGFruHswXzeeuPJVVjoEm7VCh9US5kA0bMS6bC0can9s9J+HDPTs+52ajrjOvie+Vxn2uZpgutVSDyUAMRxKp//eobsN+rmQEOIeM4SsHHdHRLNXMZerQkOM+HjEOdHJU0kwuJcnN/JOam5nMad5fUIfXHPxyHyY6C7/Fc9leB6XPu+lmiCkYq2lL2hIUREA6KdhxGt8BdWsDnw/Zowm1JjTLAnWkvgmow0bk/L/Ek7P+C0vuMpMuHd8OLFHi8VMm3z/HNaIRlp2GnalCYh0dWEJ8rabLnNx5KyvjZNCpKLktMyVkiLDfkh0YNjUiOVbOTS9najSw3LADghpri/Sofr1QS76h4Y3TR8FCZ6nmgRXpTUud/dMjvKjuc4wXApv/FG2TQQQ0vqguSsJvg3QEDmpu8eUfuyuHbEqah2HEdL6kYhd/0Uupo/lZoZX2vav2QFlyspvt9GRRMaJnBRKFdVGB9K2+9H7v8DVjLBIZlmQ7WE8JQme2PcoDQEXlch6ItSVWmFUpSF1hgxjkhTgC4h2oilCXbVREZGCXO08FVVNEG6VahoTfFihU5NRCSHzYmVCX9mwnvNcF7XQ2Qi+ni2ilGXFRyNlxJl5zprSicF5+ojkgY7pPPcZIVfVNOSQFCK5/927KW1+JEzxXUyRKS/i1j5Ymg4802ttppKRCCvnqyg1RtXpjMRH5rw14SIldyIhyS1KiGpkQKSrmUlNVIZSY0UkNSIldRInBrxJuLeGpf5KnCrxrP9UzU0Tq83xNeTyBX8cOJv4+YuTfb6uogkSPA/xitDI6RN/qUN4gAAAABJRU5ErkJggg=="/>
</defs>
</svg>


);

export default SVGRedeemed;
