// import * as React, {useRef} from "react";
import React from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isArray } from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import ResponsiveDrawer from "../components/Header";
import { CopyRight } from "../components/CopyRight";
import axios from "axios";
import _ from "lodash";
import TopHeaderScreen from "../components/TopHeader1";
import { useNavigate } from "react-router-dom";
import { CampSubmissionReportTableComponent } from "../components/Table";
import AsyncSelect from "react-select/async";
import { CSVLink, CSVDownload } from "react-csv";
import { FixedFooterIcon } from "../components/FixedFooterIcon";
import styled from 'styled-components';

const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': { // xl breakpoint
    width: '100%',
  },
  '@media (max-width: 992px)': { // lg breakpoint
    width: '100%',
  },
  '@media (max-width: 768px)': { // md breakpoint
    width: '100%',
  },
  '@media (max-width: 600px)': { // sm breakpoint
    width: '100%',
  },
  '@media (max-width: 400px)': { // xs breakpoint
    width: '100%',
  },
}));

function CampReport(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [campName, setCampName] = React.useState([]);
  const [selectedcampname, setselectedcampname] = React.useState([]);
  const [campSubmissionlist, setCampSubmissionlist] = React.useState([]);

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  React.useEffect(() => {
    getCampName();
  }, []);

  const loadOptions = (searchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = campName.filter((category) =>
        category.label.toLowerCase().includes(searchValue.toLowerCase())
      );

      callback(filteredOptions);
    });
  };

  const getCampName = async () => {
    try {
      const res = await axios.get(
        `/camp/name/report/${profileResponse?.phoneno}`
      );
      setCampName(res?.data);

      return res;
    } catch (err) {
      throw err;
    }
  };

  const getRewardsFromMarketplaceOnSearch = async () => {
    try {
      // const res = ""
      //   if ((selectedrewardtype.length === 0) && !(selectedpointsrange.length === 0)) {

      const res = await axios
        .get(
          `/camp/submission/report/${selectedcampname[0]["label"]}/${profileResponse?.phoneno}`
        )
        .then((response) => {
          setCampSubmissionlist(response?.data);
          return response;
        });
      //   }
    } catch (err) {
      throw err;
    }
  };

  const colorStyles = {
    control: (styles) => ({ ...styles }),
    multiValue: (styles, { data }) => {
      return { ...styles, backgroundColor: "#0096FF", color: "#fff" };
    },
    multiValueLabel: (styles, { data }) => {
      return { ...styles, color: "#fff" };
    },
    multiValueRemove: (styles, { data }) => {
      return {
        ...styles,
        color: "#fff",
        cursor: "pointer",
        ":hover": {
          color: "#fff",
        },
      };
    },
    // menu: (styles) => ({
    //   ...styles,
    //   maxHeight: '10px',  // Fixed maximum height for the dropdown menu
    //   overflowY: 'auto',   // Allow vertical scrolling if needed
    //   overflowX: 'hidden', // Hide horizontal overflow
    // }),
    // menuList: (styles) => ({
    //   ...styles,
    //   maxHeight: '10px',  // Fixed maximum height for the menu list
    //   overflowY: 'auto',   // Allow vertical scrolling if needed
    //   overflowX: 'hidden', // Hide horizontal overflow
    // }),
    // container: (styles) => ({
    //   ...styles,
    //   overflow: 'visible' // Ensure the container does not cause overflow
    // }),
    // valueContainer: (styles) => ({
    //   ...styles,
    //   overflow: 'visible' // Ensure the value container does not cause overflow
    // })
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Report on Campaign Submissions
            </div>
            <div class="formMarketPlace">
              <div>
                <div
                  class="form-market-wrap"
                  id=""
                  role="tabpanel"
                  aria-labelledby="nav-places-tab"
                >
                  <h6>Please select a Campaign</h6>
                  <form action="#" method="get">
                    <div
                      style={{
                        padding: "0px 0px 0px 0px",
                        width: "300px",
                        marginBottom: "5px",
                        maxWidth: "100%",
                        position: 'relative',
                        zIndex: 1000
                      }}
                    >
                      <AsyncSelect
                        loadOptions={loadOptions}
                        defaultOptions={campName}
                        value={selectedcampname}
                        isMulti
                        placeholder="Select Campaign"
                        isOptionDisabled={() => selectedcampname.length >= 1}
                        styles={colorStyles}
                        onChange={(event) => {
                          setselectedcampname(event);

                        }}
                      />
                    </div>
                    <Button
                      class="btn camp-report-gen"
                      variant="contained"
                      onClick={getRewardsFromMarketplaceOnSearch}
                      className="btn save-btn"
                      disableElevation
                      style={{ marginLeft: "30px" }}
                    >
                      <i class="fa fa-search pr-2" aria-hidden="true"></i>
                      <span>Generate Report</span>
                    </Button>
                    {
                      isArray(campSubmissionlist) && !(isEmpty(campSubmissionlist)) ? (
                        <Button
                          class="btn camp-report-gen"
                          variant="contained"
                          // className="btn save-btn"
                          // disableElevation
                          style={{ marginLeft: "30px", backgroundColor: "white", color: "black" }}
                        >
                          <i
                            class="fa fa-arrow-circle-down pr-2"
                            aria-hidden="true"
                          // style={{ color: "black" }}
                          ></i>

                          <CSVLink data={campSubmissionlist}>
                            Download Report
                          </CSVLink>


                        </Button>
                      ) : (
                        <Button
                          // disabled
                          class="btn camp-report-gen"
                          variant="contained"
                          // className="btn save-btn"

                          // disableElevation
                          style={{ marginLeft: "30px", backgroundColor: "gray", color: "silver", cursor: "initial" }}
                        >
                          <i
                            class="fa fa-arrow-circle-down pr-2"
                            aria-hidden="true"
                          // style={{ color: "black" }}
                          ></i>

                          <span>Download Report</span>
                        </Button>
                      )
                    }
                  </form>
                </div>
              </div>
            </div>

            <header id="SOADashboardScreen">
              <form autoComplete="off">
                <div
                  style={{
                    border: "1px solid #dadce0",
                    borderRadius: 8,
                    backgroundColor: "#fff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  ></div>
                  <div
                    className="centerAlign"
                    style={{ padding: "16px 0", marginBottom: 50 }}
                  >
                    <br />

                    <FormGroupFull>
                      {isArray(campSubmissionlist) &&
                        !isEmpty(campSubmissionlist) ? (
                        <div>
                          <CampSubmissionReportTableComponent
                            rows={campSubmissionlist}
                          />
                        </div>
                      ) : !isArray(campSubmissionlist) ? (
                        campSubmissionlist?.message
                      ) : (
                        "Please select a campaign and click on Generate Report button"
                      )}
                    </FormGroupFull>
                  </div>
                </div>
              </form>
            </header>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
}
export default CampReport;
