import React from "react";
import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import ResponsiveDrawer from "../components/Header";
import { CopyRight } from "../components/CopyRight";
import _ from "lodash";
import TopHeaderScreen from "../components/TopHeader1";
import { useNavigate } from "react-router-dom";
import { FixedFooterIcon } from "../components/FixedFooterIcon";
import styled from 'styled-components';
import SVGGetStarted1 from "../icons/SVGGetStarted1";
import SVGGetStarted2 from "../icons/SVGGetStarted2";
import SVGGetStarted3 from "../icons/SVGGetStarted3";
import SVGGetStarted4 from "../icons/SVGGetStarted4";
import SVGGetStarted5 from "../icons/SVGGetStarted5";
import SVGGetStarted6 from "../icons/SVGGetStarted6";

const ScrollableContainer = styled('div')(({ theme }) => ({
  overflow: 'auto',
  maxHeight: '580px', 
  border: '2px solid #ccc', 
  borderRadius: '8px', 
  padding: '10px',
  marginBottom: '16px', 
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
  transition: 'box-shadow 0.3s ease-in-out', 
  '&:hover': {
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)', 
  },
  '@media (max-width: 768px)': {
    maxHeight: 'auto',
  },
  '@media (max-width: 600px)': {
    maxHeight: 'auto',
  },
  '::-webkit-scrollbar': {
    width: '2px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#888', 
    borderRadius: '8px',
  },
  '::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555', 
  },
  'scrollbar-width': 'thin', 
  'scrollbar-color': '#888 #ccc',
}));

function GetStarted(props) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg" style={{marginBottom:"100px"}}>
            <Grid container spacing={2} sx={{ padding: '16px' }}>
              <Button onClick={() => navigate(-1)} className="backWrapper">
                <i className={`fa fa-caret-left`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
              </Button>             
              <Grid item xs={12}>
                <Typography variant="h5" component="div" sx={{ fontWeight: 600, marginBottom: 2, marginTop: 1 }}>
                  How to Get Started
                </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography
                  variant={isMobile ? "h6" : "h5"}  
                  style={{ 
                    textAlign: 'center',
                    marginBottom: isMobile ? '8px' : '16px',
                    fontFamily: 'Segoe UI',
                    fontWeight: 'bold',
                    color: '#34495e'
                  }}
                >
                  You can participate in Campaigns
                </Typography>
                <ScrollableContainer className="svgResponsive" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                
                  <SVGGetStarted1/>
                </ScrollableContainer>
              </Grid>
              <Grid item xs={isMobile ? 12: 6}>
              <Typography
                  variant={isMobile ? "h6" : "h5"}  
                  style={{ 
                    textAlign: 'center',
                    marginBottom: isMobile ? '8px' : '16px',
                    fontFamily: 'Segoe UI',
                    fontWeight: 'bold',
                    color: '#34495e'
                  }}
                >
                  Win Points
                </Typography>
                <ScrollableContainer className="svgResponsive" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <SVGGetStarted2 />
                </ScrollableContainer>
              </Grid>
              <Grid item xs={isMobile ? 12: 6}>
              <Typography
                  variant={isMobile ? "h6" : "h5"}  
                  style={{ 
                    textAlign: 'center',
                    marginBottom: isMobile ? '8px' : '16px',
                    fontFamily: 'Segoe UI',
                    fontWeight: 'bold',
                    color: '#34495e'
                  }}
                >
                  View your achievements
                </Typography>
                <ScrollableContainer className="svgResponsive" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <SVGGetStarted3 />
                </ScrollableContainer>
              </Grid>
              <Grid item xs={isMobile ? 12: 6}>
              <Typography
                  variant={isMobile ? "h6" : "h5"}  
                  style={{ 
                    textAlign: 'center',
                    marginBottom: isMobile ? '8px' : '16px',
                    fontFamily: 'Segoe UI',
                    fontWeight: 'bold',
                    color: '#34495e'
                  }}
                >
                  Highlight your achievements
                </Typography>
                <ScrollableContainer  className="svgResponsive" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <SVGGetStarted4/>
                </ScrollableContainer>
              </Grid>
              <Grid item xs={isMobile ? 12: 6}>
              <Typography
                  variant={isMobile ? "h6" : "h5"}  
                  style={{ 
                    textAlign: 'center',
                    marginBottom: isMobile ? '8px' : '16px',
                    fontFamily: 'Segoe UI',
                    fontWeight: 'bold',
                    color: '#34495e'
                  }}
                >
                  Redeem rewards
                </Typography>
                <ScrollableContainer  className="svgResponsive" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <SVGGetStarted5/>
                </ScrollableContainer>
              </Grid>
              <Grid item xs={12}>
                <Typography
                    variant={isMobile ? "h6" : "h5"}  
                    style={{ 
                      textAlign: 'center',
                      marginBottom: isMobile ? '8px' : '16px',
                      fontFamily: 'Segoe UI',
                      fontWeight: 'bold',
                      color: '#34495e'
                    }}
                  >
                    Build your community
                  </Typography>
                  <ScrollableContainer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <SVGGetStarted6 />
                  </ScrollableContainer>
              </Grid>
              
            </Grid>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
}
export default GetStarted;
