import React, { useEffect, useState } from "react";
import { Button, CircularProgress, MenuItem, Select, Avatar, Typography, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {
  onboardActivityReset,
  onboardBulkReset,
  onboardCampaign,
  onboardCampaignReset,
  onboardIndividualReset,
  onboardOrganization,
  onboardOrganizationReset,
} from "../../redux/action/OnboardAction";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";
import {status,} from "../../utils/dict";
import { useLocation, useNavigate, Link } from "react-router-dom";
import TopHeaderScreen from "../../components/TopHeader1";
import styled, { keyframes } from 'styled-components';

const blinking = keyframes`
  0% { filter: brightness(0.9); }
  50% { filter: brightness(1.1); }
  100% { filter: brightness(1); }
`;

const BlinkingSVG = styled.svg`
  cursor: pointer;
  animation: ${blinking} 1s infinite;
`;

const SVGCampLeaderboard = (props) => (
  <BlinkingSVG width="40" height="40" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <rect width="40" height="40" fill="url(#pattern0_231_5)" />
    <defs>
      <pattern id="pattern0_231_5" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_231_5" transform="scale(0.02)" />
      </pattern>
      <image id="image0_231_5" width="50" height="50" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADPklEQVR4nO2Y20+SYRzHadnW/9PWVRsXvFt31tq8wHnAWWBBaFlgIIqkIWLiAhEUrAulPKQiMkhF8Ihazo43WRq1aalbuixMgV97XivFUHhBfNf2fLfPxgaM3+f5PacXBgMHBwcHBwcHh4yXIMReFmvDy2JBIkwQhH+cxbrKoCPAYBzzEsTmBz4flqTShJjLy0My67SIuJnMFDSaKzIZ+DWahFiSSFBnArSIPOOeOoFEPgqFCXfEJxAgkSAtIu5s5slE10YYBAH0iJQyU7rL2LDSWw0wakqIpZ4q6C5j09QRLGJKTkfS0tqOn5fYTqdKewkqXJB0ndXLr4GvWxN16nzruwebHkNyp9Y5qd2aKrFDvIxZ9PsW6Hfp4GWTCHoU6bBsUydXJFViD0pa5kHtWKXMQSI/3fWkANoQEEchAsWtn5IiMqkXkh2JJvK8UwmF+oIQx1w0SpUcs9iZaRafIUV42mkQNr6mTLSphZh7JI8q4u2ogCyTCG5ZVZQpaC0LccziRQav+D5cLm0OXZFbglThy5tDrzoOT8Q42UQZlUsL2SZRgHFYhxoWGd3pSFaccEwiiNiRrSEjzFpkMFEngAm9EN62yCAwZExaR7669DD2uCIu+pql0KDMCUUUmWkshCcqDkwbr4O3TkAWMmXIT5rIfmwM1oGv/TY5kL52BbkT7rt1RxL5bK2EwPBOB1BXrIp0CA43UC5msUsJY1o+rDqj3wBgD/1VuTCgzoWhWh75+/aKTFhz1sYuspcZ0w2wKthxiSTC2q6ifxcML0w34xNZ79eSIzGu4x+pBESoA9X65kERdZFVh4ZcK87KHPg+oDvy4rc8BvhiVZHrw13DA1f1RfAP6qmJLHTdAVt5BrhruPCDBglAu5lD8/euhmYFWvR7p/eBIss9anJxTdXnhy16utj0GGC+TUFeQp8aCmIX8Wi44FBmk88RaG7+gW6p4do86C3PiF1k9/V7N/GcBfHid+nC1iUaVDS90FYcswh6MxIHPeUdNsu27ek9ePcSeGq45GtnJSexc4QOQiPbMu8flsI7SwksdCohOPLvORYmgj6MvvQ/Mmsp2RH575Gz6fm7FQcHBwcHB4eRnPwCG4GUygmgFQIAAAAASUVORK5CYII=" />
    </defs>
  </BlinkingSVG>
);

const HomeScreenMainContent = styled(Box)(({ theme }) => ({
  padding: '80px 0 100px 0px',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    padding: '80px 0 100px 330px',
  },
})); 

const FormGroup0 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#F0F8FF',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// Styled component for formGroup00
const FormGroup00 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#FAEBD7',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

const FormGroup = styled('div')(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: '40px',
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));


const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup1
const FormGroup1 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup2
const FormGroup2 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  flexDirection: 'column',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '75px 0 0 16px',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup3
const FormGroup3 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  width: '20%',
  '@media (max-width: 1200px)': {
    width: '20%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup4
const FormGroup4 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'right',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 0',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup7
const FormGroup7 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup8
const FormGroup8 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledLabel
const StyledLabel = styled('label')(({ theme }) => ({
  display: 'flex',
  background: '#e9e7e7',
  padding: '10px',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 600,
  marginTop: '16px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

// StyledSpan
const StyledSpan = styled('span')(() => ({
  fontSize: '12px',
}));

// FocusThematic
const FocusThematic = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledInput
const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// StyledTextarea
const StyledTextarea = styled('textarea')(() => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// LogoButton
const LogoButton = styled('input')(() => ({
  width: '8%',
  border: 'none',
  padding: '10px',
  display: 'flex',
  height: '40px',
  color: 'white',
  '@media (max-width: 1920px)': {
    fontSize: '15px',
    width: '12%',
  },
  '@media (max-width: 1280px)': {
    fontSize: '15px',
    width: '15%',
  },
  '@media (max-width: 960px)': {
    fontSize: '15px',
    width: '20%',
  },
  '@media (max-width: 600px)': {
    fontSize: '15px',
    width: '30%',
  },
}));

// StyledTypography
const StyledTypography = styled(Typography)(({ theme }) => ({
  '& .css-u4tvz2-MuiFormLabel-root': {
    fontFamily: 'Poppins',
  },
  display: 'block',
  display: 'flex',
  fontSize: '16px',
  marginTop: '16px',
  fontWeight: 500,
  lineHeight: '18px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

const LoginPgLogo = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (max-width: 768px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
}));

const StyledLargeAvatar = styled(Avatar)(({ theme }) => ({
  width: '200px !important',
  height: '200px !important',
  margin: '0 auto',
  marginTop: '5px',
  marginBottom: '5px',
  '@media (max-width: 400px)': {
    width: '120px !important',
    height: '120px !important',
  },
  '@media (max-width: 600px)': {
    width: '140px !important',
    height: '140px !important',
  },
  '@media (max-width: 768px)': {
    width: '150px !important',
    height: '150px !important',
  },
}));

const CommentsSpan = styled('span')(({ theme }) => ({
  color: 'white',
  '@media (max-width: 400px)': {
    fontSize: "10px !important",
  },
  '@media (max-width: 600px)': {
    fontSize: "12px !important",
  },
  '@media (max-width: 768px)': {
    fontSize: "12px !important",
  },
  '@media (max-width: 1200px)': {
    fontSize: "14px !important",
  },
}));


function OrganizationViewAndReviewScreen(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationPath = useLocation();

  const [reviewstatus, setReviewStatus] = React.useState("");
  const [reviewComments, setReviewComments] = React.useState("");
  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "" },
  ]);

  
  const [activityList, setActivityList] = React.useState(undefined);
  const [orgValues, setOrgValues] = React.useState({
    titleOfOrganization: "",
    totalStaff: "",
    headquarters: "",
    organizationLogo: "",
    registeredAs: "",
    website: "",
    emailId: "",
    contactNo: "",
    totalNumberofVolunteers: "",
    chiefFunctionary: "",
  });
  const {
    isCampaignSuccess,
    isCampaignFetching,

    isCampaignError,
  } = useSelector((state) => state.onBoard);

  
  
  const [response, setResponse] = useState({});

  const handleOnboardCampaign = async () => {
    const payload = {
      clientid: locationPath?.state?.clientid,
      createdate: locationPath?.state?.createdate,
      reviewstatus: reviewstatus,
      reviewcomments: reviewComments,
    };
    try {
      // if (!(reviewstatus === "")) {
        const res = await axios.put(
          `/org/review/${localStorage.getItem("userid")}`,
          payload
        );
        // navigate("/revieworganization");
        setResponse(res?.data);
        return res;
      // } else {
      //   alert("Please chose a review status");
      // }
    } catch (err) {
      throw err;
    }
  };

  const handleCampaignFieldChange = (field, e) => {
    setResponse({});
    (isCampaignError || isCampaignSuccess) && dispatch(onboardCampaignReset());
    setOrgValues({
      ...orgValues,
      [field]: e.target.value,
    });
  };

  const unitOfCampaign = activityList?.filter(
    (item, index) => item?.activity === orgValues?.typeOfCampaign
  );

  

  const handleChangeLocation = (id, e, field, index) => {
    const values = [...location];
    values[index][field] = e.target.value;
    setLocation(values);
  };

  
  const getAddress = (item, index) => {
    return (
      <div style={{ float: "left", width: "100%",marginTop: 25 }}>
        <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>Operational Areas</span>
        <form style={{ display: "flex", flexWrap: "wrap" }}>
          <FormGroup1>
            <StyledLabel>
              Country
            </StyledLabel>
            <StyledInput
              disabled
              value="India"
              type="text"
               
            />
          </FormGroup1>

          <FormGroup1>
            <StyledLabel>
              State
            </StyledLabel>
            <StyledInput
              disabled
              value={item.state}
              type="text"
               
            />
          </FormGroup1>
          <FormGroup1>
            <StyledLabel>
              City/Town
            </StyledLabel>
            <StyledInput
              disabled
              value={item.city}
              type="text"
               
            />
          </FormGroup1>
          <FormGroup1>
            <StyledLabel>
              Area
            </StyledLabel>

            <StyledInput
              disabled
              value={item.area}
              type="text"
              id="create-yaad--title"
               
              // placeholder="Your Answer"
              onChange={(e) => handleChangeLocation(item, e, "area", index)}
            />
          </FormGroup1>
        </form>
      </div>
    );
  };

  React.useEffect(() => {
    
    dispatch(onboardBulkReset());
    dispatch(onboardIndividualReset());
    dispatch(onboardActivityReset());
    dispatch(onboardCampaignReset());
    dispatch(onboardOrganizationReset());
  }, []);

  React.useEffect(() => {
    setResponse({});
    if (locationPath?.state) {
      setOrgValues({
        titleOfOrganization: locationPath?.state?.organization,
        totalStaff: locationPath?.state?.noofemployees,
        headquarters: locationPath?.state?.orghq,
        registeredAs: locationPath?.state?.registeredas,
        website: locationPath?.state?.website,
        emailId: locationPath?.state?.emailid,
        chiefFunctionary: locationPath?.state?.chieffunctionary,
        contactNo: locationPath?.state?.contactnumber,
        orgFocusArea: locationPath?.state?.orgfocusarea,
        totalNumberofVolunteers: locationPath?.state?.noofvol,
      });
      setLocation(locationPath?.state?.location);
      // setFocusArea(JSON.parse(locationPath?.state?.rewardstobeearned))
    }
  }, [locationPath?.state]);

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
      <TopHeaderScreen {...props} />
      <div class="content content-wrap">
          <div class="content-mrg">
          <div style={{fontFamily:"monospace", fontSize: 16,  }}>
          <Button
                onClick={() => navigate(-1)}
                className="backWrapper"
            >
                <i className={`fa fa-caret-left}`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
          </div>
          <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>View & Review</div> 
        <form autoComplete="off">
          <div style={{ border: "1px solid #dadce0", borderRadius: 8,padding: "13px 30px 40px 30px",backgroundColor:"#fff" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              
              <FormGroup>
                <StyledLabel>Name of Organisation</StyledLabel>
                <StyledInput
                  disabled
                  value={orgValues?.titleOfOrganization}
                  type="text"
                  placeholder="Your answer"
                  onChange={(e) => {
                    handleCampaignFieldChange("titleOfOrganization", e);
                  }}
                />
              </FormGroup>

              
              <FormGroup>
                <StyledLabel>
                Headquarters Name
                </StyledLabel>
                <StyledInput
                  disabled
                  value={orgValues?.headquarters}
                  type="text"
                  placeholder="Your answer"
                  onChange={(e) => {
                    handleCampaignFieldChange("headquarters", e);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <StyledLabel>
                 Website
                </StyledLabel>
                <StyledInput
                  disabled
                  value={orgValues?.website}
                  type="text"
                  placeholder="Your answer"
                  onChange={(e) => {
                    handleCampaignFieldChange("website", e);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <StyledLabel>
                Name of Chief Functionary
                </StyledLabel>

                <StyledInput
                  disabled
                  value={orgValues?.chiefFunctionary}
                  type="text"
                  id="create-yaad--title"
                   
                  placeholder="Your answer"
                  onChange={(e) => {
                    handleCampaignFieldChange("chiefFunctionary", e);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <StyledLabel>
                Contact Number
                </StyledLabel>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <StyledInput
                    disabled
                    value={orgValues?.contactNo}
                    type="number"
                    id="create-yaad--title"
                     
                    placeholder="Your Answer"
                    onChange={(e) => {
                      handleCampaignFieldChange("contactNo", e);
                    }}
                  />
                  <span style={{ marginLeft: 4 }}>
                    {unitOfCampaign?.[0]?.unit}
                  </span>
                </div>
              </FormGroup>

              <FormGroup>
                <StyledLabel>
                Email Id
                </StyledLabel>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <StyledInput
                    disabled
                    value={orgValues?.emailId}
                    type="text"
                    id="create-yaad--title"
                     
                    placeholder="Your Answer"
                    onChange={(e) => {
                      handleCampaignFieldChange("emailId", e);
                    }}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <StyledLabel>
                Total Staff
                </StyledLabel>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <StyledInput
                    disabled
                    value={orgValues?.totalStaff}
                    type="text"
                    id="create-yaad--title"
                     
                    placeholder="Your Answer"
                    onChange={(e) => {
                      handleCampaignFieldChange("totalStaff", e);
                    }}
                  />
                </div>
              </FormGroup>
        
              <FormGroup>
                <StyledLabel>
                Total No. of Volunteers
                </StyledLabel>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <StyledInput
                    disabled
                    value={orgValues?.totalNumberofVolunteers}
                    type="text"
                    id="create-yaad--title"
                     
                    placeholder="Your Answer"
                    onChange={(e) => {
                      handleCampaignFieldChange("totalNumberofVolunteers", e);
                    }}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <StyledLabel>
                Focus Thematic Areas
                </StyledLabel>
                {orgValues?.orgFocusArea?.map((item, index) => (
                  <span style={{color:"#0F0F0F",fontFamily:"inherit", padding:"5px"}}> {(index ? ', ' : '')} {item.focus} </span>
                 
                ))}
              </FormGroup>
                {location.map((item, index) => (
                  <div key={index}>{getAddress(item, index)}</div>
                ))}
            </div>
          </div>
        </form>
        <br></br>
        
            <br></br>
            {response?.status === "success" && 
                  <span style={{ color: "green", textAlign: "center", display: "block" }}>{response?.message}</span>}
                  {response?.status === "failure" && (
                    // <span style={{ color: "red" }}>Error occured</span>
                    <span style={{ color: "red", textAlign: "center", display: "block" }}>{response?.message}</span>
                  )}
        
        <FormGroup2>
          <div
            style={{
              display: "flex",
              width: "40%",
              flexDirection: "column",
              justifyContent:"center"
            }}
          >
            <CommentsSpan>Comments</CommentsSpan>
            <textarea
              style={{ height: 100, borderRadius: 8, padding: 8 }}
              placeholder="Comments"
              onChange={(e) => setReviewComments(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>Status</span>
              <Select
                style={{
                  width: 200,
                  fontSize: 14,
                  height: 56,
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  background: "#fff",
                }}
                onChange={(e) => setReviewStatus(e.target.value)}
              >
                {status?.map((item, index) => (
                  <MenuItem style={{ fontSize: 16 }} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <Button
              style={{
                marginTop: 10,
                backgroundColor: "#E88A00",
                color: "#fff",
                fontFamily: "Poppins !important",
                width: "100%",
                fontSize: 15,
    fontWeight: "bold",
    lineHeight: 1.2,
    marginLeft: 40,
              }}
              variant="contained"
              onClick={handleOnboardCampaign}
            >
              {isCampaignFetching ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
          <br />
        </FormGroup2>
        
      </div>
      </div>
      <CopyRight />
      </section>
    </div>
  );
}
export default OrganizationViewAndReviewScreen;
