import * as React from "react";
import {
  Avatar,
  Button,
  Box,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import _, { values } from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import {
  onboardActivityReset,
  onboardBulkReset,
  onboardCampaign,
  onboardCampaignReset,
  onboardIndividualReset,
  onboardOrganization,
  onboardOrganizationReset,
} from "../../redux/action/OnboardAction";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";
import {
  BloodGroup,
  Gender,
  status,
  educationList,
  preferWorkingDays,
  hoursEveryWeek,
  interestAreaDict,
  skillSets,
} from "../../utils/dict";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { getCity } from "../../redux/action/cityAction";
import { states, MenuProps } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import DoneIcon from "@mui/icons-material/Done";
import PaidIcon from '@mui/icons-material/Paid';
import styled from 'styled-components';

const HomeScreenMainContent = styled(Box)(({ theme }) => ({
  padding: '80px 0 100px 0px',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    padding: '80px 0 100px 330px',
  },
}));

const FormGroup = styled('div')(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: '40px',
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));


const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup1
const FormGroup1 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup2
const FormGroup2 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  flexDirection: 'column',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '75px 0 0 16px',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup3
const FormGroup3 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  width: '20%',
  '@media (max-width: 1200px)': {
    width: '20%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup4
const FormGroup4 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'right',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 0',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup7
const FormGroup7 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup8
const FormGroup8 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledLabel
const StyledLabel = styled('label')(({ theme }) => ({
  display: 'block',
  background: '#e9e7e7',
  padding: '10px',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 600,
  marginTop: '16px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

// StyledSpan
const StyledSpan = styled('span')(() => ({
  fontSize: '12px',
}));

// FocusThematic
const FocusThematic = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledInput
const StyledInput = styled('input')(({ theme }) => ({
  ...commonFontStyles,
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// StyledTextarea
const StyledTextarea = styled('textarea')(() => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// LogoButton
const LogoButton = styled('input')(() => ({
  width: '8%',
  border: 'none',
  padding: '10px',
  display: 'flex',
  height: '40px',
  color: 'white',
  '@media (max-width: 1920px)': {
    fontSize: '15px',
    width: '12%',
  },
  '@media (max-width: 1280px)': {
    fontSize: '15px',
    width: '15%',
  },
  '@media (max-width: 960px)': {
    fontSize: '15px',
    width: '20%',
  },
  '@media (max-width: 600px)': {
    fontSize: '15px',
    width: '30%',
  },
}));

// StyledTypography
const StyledTypography = styled(Typography)(({ theme }) => ({
  '& .css-u4tvz2-MuiFormLabel-root': {
    fontFamily: 'Poppins',
  },
  display: 'block',
  display: 'flex',
  fontSize: '16px',
  marginTop: '16px',
  fontWeight: 500,
  lineHeight: '18px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

const LoginPgLogo = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (max-width: 768px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
}));

const StyledLargeAvatar = styled(Avatar)(({ theme }) => ({
  width: '200px !important',
  height: '200px !important',
  margin: '0 auto',
  marginTop: '5px',
  marginBottom: '5px',
  '@media (max-width: 400px)': {
    width: '120px !important',
    height: '120px !important',
  },
  '@media (max-width: 600px)': {
    width: '140px !important',
    height: '140px !important',
  },
  '@media (max-width: 768px)': {
    width: '150px !important',
    height: '150px !important',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    ...commonFontStyles,
    width: '100%',
    height: '56px',
    color: '#949494',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  ...commonFontStyles,
  width: '100%',
  height: '56px',
  color: '#949494',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  color: 'black'
}));

const commonFontStyles = {
  fontFamily: 'Arial, sans-serif', // Replace with your desired font family
  fontSize: '16px', // Replace with your desired font size
};


function VolunteerEditScreen(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [orgRole, setORGRole] = React.useState([]);
  const [org, setORG] = React.useState([]);
  const locationPath = useLocation();
  const [address, setAddress] = React.useState("");
  const [correspondenceaddress, setcorrespendeceaddress] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [area, setArea] = React.useState("");
  const [added, setAdded] = React.useState("no");
  const [state, setState] = React.useState("");
  const [city, setCity] = React.useState(false);



  const handleSelect = async (value) => {
    setSearchSelect(true);

    // console.log(value);

    const results = await geocodeByAddress(value);
    // console.log(results[0]["formatted_address"]);
    setAddress(results[0]["formatted_address"]);

    // console.log(value);
  };

  const [volunteerDetails, setvolunteerDetails] = useState({
    // fullname: "",
    firstname: "",
    lastname: "",
    dob: new Date(),
    correspondenceAddress: "",
    emailId: "",
    gender: "",
    phoneNumber: "",
    city: "",
    state: "",
    area: "",
    organization: "",
    highLevelEdu: "",
    preferDays: "",
    interestArea: "",
    hoursPerWeek: "",
    orgRole: "",
    bloodGroup: "",
    skill: "",
    expertise: "",
  });

  const getORGRole = async () => {
    try {
      const res = await axios.get(`/orgrole/${localStorage.getItem("userid")}`);
      setORGRole(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getORG = async () => {
    try {
      const res = await axios.get(
        `/organization/${localStorage.getItem("userid")}`
      );
      setORG(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const [response, setResponse] = useState({});
  const [errorOnSave, setErrorOnSave] = useState("");
  const [searchSelect, setSearchSelect] = useState(false);
  const { isSuccess: profileSuccess, response: VolunteerResponse } =
    useSelector((state) => state.profile);

  const handleFieldChange = (field, e) => {
    setResponse({});
    setvolunteerDetails({
      ...volunteerDetails,
      [field]: field === "dob" ? e : e.target.value,
    });
  };
  const getSelectedValue = (orgObj) => {
    // console.log(orgObj);
  };

  const [notificationShow, setNotificationShow] = React.useState(false);

  function subtractYears(numOfYears, date = new Date()) {
    date.setFullYear(date.getFullYear() - numOfYears);

    return date;
  }

  const handleChangeLocation = (id, e, field, index) => {
    const values = [...location];
    values[index][field] = e.target.value;
    setLocation(values);
  };

  //   const handleVolunteer = async () => {
  //     const payload = {
  //       campid: locationPath?.state?.campid,
  //       createdate: locationPath?.state?.createdate,
  //       reviewstatus: reviewstatus,
  //       reviewcomments: reviewComments,
  //     };
  //     try {
  //       const res = await axios.get(
  //         `/cyaaguser/${localStorage.getItem("userid")}`,
  //         payload
  //       );
  //       navigate("/reviewcampaign");
  //       return res;
  //     } catch (err) {
  //       throw err;
  //     }
  //   };

  // console.log(locationPath?.state.dob)
  // console.log(new Date())


  useEffect(() => {
    if (locationPath?.state) {
      setvolunteerDetails({
        // fullname: locationPath?.state.fullname,
        // dob: locationPath?.state.dob,
        firstname: locationPath?.state.firstname,
        lastname: locationPath?.state.lastname,
        dob: moment(locationPath?.state.dob, "MM-DD-YYYY"),
        correspondenceAddress: locationPath?.state.correspondenceaddress,
        emailId: locationPath?.state.emailid,
        gender: locationPath?.state.gender,
        phoneNumber: locationPath?.state.phoneno,
        country: locationPath?.state.country,
        city: locationPath?.state.city,
        state: locationPath?.state.state,
        area: locationPath?.state.area,
        organization: locationPath?.state.organization,
        orgRole: locationPath?.state.orgrole,
        highLevelEdu: locationPath?.state.highlvledu,
        preferDays: locationPath?.state.preferday,
        interestArea: locationPath?.state.interestarea,
        hoursPerWeek: locationPath?.state.hoursperweek,
        bloodGroup: locationPath?.state.bloodgroup,
        skill: locationPath?.state.skill,
        expertise: locationPath?.state.expertise,
      });
    }
  }, [locationPath?.state]);



  const handleSave = async () => {
    try {

      if (
        volunteerDetails?.firstname != "" &&
        volunteerDetails?.lastname != "" &&
        volunteerDetails?.correspondenceAddress != "" &&
        volunteerDetails?.emailId != "" &&
        country != "" &&
        state != ""
      ) {
        const res = await axios.put(`/user/profile`, {
          // fullname: volunteerDetails?.fullname,
          firstname: volunteerDetails?.firstname,
          lastname: volunteerDetails?.lastname,
          dob: moment(volunteerDetails?.dob).format("DD-MM-yyyy"),
          phoneno: volunteerDetails?.phoneNumber,
          correspondenceaddress: volunteerDetails?.correspondenceAddress,
          emailid: volunteerDetails?.emailId,
          gender: volunteerDetails?.gender,
          // city: volunteerDetails?.city,
          // state: volunteerDetails?.state,
          country: country,
          state: state,
          city: city,
          area: area,
          organization: volunteerDetails?.organization,
          highlvledu: volunteerDetails?.highLevelEdu,
          preferday: volunteerDetails?.preferDays,
          hoursperweek: volunteerDetails?.hoursPerWeek,
          interestarea: volunteerDetails?.interestArea,
          orgrole: volunteerDetails?.orgRole.toLowerCase(),
          bloodgroup: volunteerDetails?.bloodGroup,
          skill: volunteerDetails.skill,
          expertise: volunteerDetails?.expertise,
        });
        setResponse(res?.data);
        setErrorOnSave("");
        return res;
      } else if (
        volunteerDetails?.firstname != "" &&
        volunteerDetails?.lastname != "" &&
        volunteerDetails?.correspondenceAddress != "" &&
        volunteerDetails?.emailId != "" &&
        volunteerDetails.country != "" &&
        volunteerDetails.state != ""
      ) {
        const res = await axios.put(`/user/profile`, {
          // fullname: volunteerDetails?.fullname,
          firstname: volunteerDetails?.firstname,
          lastname: volunteerDetails?.lastname,
          dob: moment(volunteerDetails?.dob).format("DD-MM-yyyy"),
          phoneno: volunteerDetails?.phoneNumber,
          correspondenceaddress: volunteerDetails?.correspondenceAddress,
          emailid: volunteerDetails?.emailId,
          gender: volunteerDetails?.gender,
          // city: volunteerDetails?.city,
          // state: volunteerDetails?.state,
          country: volunteerDetails.country,
          state: volunteerDetails.state,
          city: volunteerDetails.city,
          area: volunteerDetails.area,
          organization: volunteerDetails?.organization,
          highlvledu: volunteerDetails?.highLevelEdu,
          preferday: volunteerDetails?.preferDays,
          hoursperweek: volunteerDetails?.hoursPerWeek,
          interestarea: volunteerDetails?.interestArea,
          orgrole: volunteerDetails?.orgRole.toLowerCase(),
          bloodgroup: volunteerDetails?.bloodGroup,
          skill: volunteerDetails.skill,
          expertise: volunteerDetails?.expertise,
        });
        setResponse(res?.data);
        setErrorOnSave("");
        return res;
      } else {
        setErrorOnSave("Please do not leave any field empty and click on Save");
      }
    } catch (e) {
      // console.log("error", e);
    }
  };


  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "" },
  ]);

  const { isSuccess: citiesSuccess, response: citiesResponse } = useSelector(
    (state) => state.cities
  );


  React.useEffect(() => {
    dispatch(onboardBulkReset());
    dispatch(onboardIndividualReset());
    dispatch(onboardActivityReset());
    dispatch(onboardCampaignReset());
    dispatch(onboardOrganizationReset());
  }, []);


  useEffect(() => {
    // console.log(locationPath?.state.city)
    if (locationPath?.state.city) {
      dispatch(getCity(locationPath?.state.state));
    }
    getORGRole();
    getORG();
  }, []);

  //handle key press event
  const handleKeypress = (e) => {
    e.preventDefault();
    return false;
  };


  const updatelocation = (address) => {
    if (!address) {
      // setCountryError(true);
      setAdded("no");
    } else if (
      String(address.split(",")[address.split(",").length - 2]).trim(" ") ===
      "undefined"
    ) {
      // setCountryError(true);
      setAdded("no");
    } else {
      // setCountryError(false);
      setCountry(
        String(address.split(",")[address.split(",").length - 1]).trim(" ")
      );
      setState(
        String(address.split(",")[address.split(",").length - 2]).trim(" ")
      );
      setCity(
        String(address.split(",")[address.split(",").length - 3]).trim(" ")
      );
      setArea(
        String(address.split(",")[address.split(",").length - 4]).trim(" ")
      );
      setAdded("yes");
    }

  };
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button
              onClick={() => navigate(-1)}
              className="backWrapper"
              style={{ justifyContent: "left" }}
            >
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div>
            <span
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
                marginLeft: "5px",
              }}
            >
              Edit User Profile
            </span>
            </div>
            <form autoComplete="off">
              {/* <div style={{ border: "1px solid #dadce0", borderRadius: 8, padding: "13px 30px 40px 30px" }}> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >

                <FormGroup>
                  <StyledLabel>First Name</StyledLabel>
                  <StyledInput
                    // value={volunteerDetails?.fullname}
                    value={volunteerDetails?.firstname}
                    type="text"
                    placeholder="Your answer"
                    onChange={(e) => {
                      handleFieldChange("firstname", e);
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <StyledLabel>Last Name</StyledLabel>
                  <StyledInput
                    // value={volunteerDetails?.fullname}
                    value={volunteerDetails?.lastname}
                    type="text"
                    placeholder="Your answer"
                    onChange={(e) => {
                      handleFieldChange("lastname", e);
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <StyledLabel>Date of Birth</StyledLabel>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={volunteerDetails?.dob}
                      onChange={(newValue) => {
                        handleFieldChange("dob", newValue);
                      }}
                      // maxDate={new Date()}
                      maxDate={subtractYears(5)}
                      renderInput={(params) => (
                        <TextField
                          type="text"
                          onKeyDown={(e) => {
                            handleKeypress(e);
                          }}
                          style={{
                            width: "100%",
                            height: 56,
                            color: "#949494",
                            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormGroup>
                <FormGroup>
                  <StyledLabel>Gender</StyledLabel>
                  <StyledSelect
                    style={{
                      width: "100%",
                      // fontSize: 14,
                      // height: 56,
                      // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                    }}
                    value={volunteerDetails?.gender}
                    onChange={(e) => handleFieldChange("gender", e)}
                  >
                    {Gender.map((item, index) => (
                      <MenuItem key={item.value} value={item?.value}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormGroup>

                <FormGroup>
                  <StyledLabel>Blood Group</StyledLabel>
                  <StyledSelect
                    style={{
                      width: "100%",
                      // fontSize: 14,
                      // height: 56,
                      // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                    }}
                    value={volunteerDetails?.bloodGroup}
                    onChange={(e) => handleFieldChange("bloodGroup", e)}
                  >
                    {BloodGroup.map((item, index) => (
                      <MenuItem key={item.value} value={item?.value}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormGroup>


                <FormGroup>
                  <StyledLabel>Email ID</StyledLabel>
                  <StyledInput
                    //disabled
                    value={volunteerDetails?.emailId}
                    type="text"
                    style={{
                      width: "100%",
                      // border: "none",
                      // padding: 10,
                      // display: "block",
                      // borderRadius: 6,
                      // height: 54,
                      // boxShadow: "0px 3px 6px #00000029",
                      // color: "black",
                      // fontSize: 15,
                    }}
                    onChange={(e) => handleFieldChange("emailId", e)}
                  />
                </FormGroup>
                <FormGroup>
                  <StyledLabel>
                    Phone no
                  </StyledLabel>
                  <StyledInput
                    disabled
                    value={volunteerDetails?.phoneNumber}
                    type="text"
                    onChange={(e) => handleFieldChange("phoneNumber", e)}
                  />
                </FormGroup>

                <FormGroup >
                  <StyledLabel>
                    Highest Education
                  </StyledLabel>
                  <StyledSelect
                    style={{
                      width: "100%",
                      // fontSize: 14,
                      // height: 56,

                      // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                    }}
                    value={volunteerDetails?.highLevelEdu}
                    onChange={(e) => handleFieldChange("highLevelEdu", e)}
                  >
                    {educationList.map((item, index) => (
                      <MenuItem key={item.value} value={item?.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormGroup>
                <FormGroup>
                  <StyledLabel>Hours Per Week</StyledLabel>
                  <StyledSelect
                    style={{
                      width: "100%",
                      // fontSize: 14,
                      // height: 56,
                      // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                    }}
                    value={volunteerDetails?.hoursPerWeek}
                    onChange={(e) => handleFieldChange("hoursPerWeek", e)}
                  >
                    {hoursEveryWeek.map((item, index) => (
                      <MenuItem key={item.value} value={item?.value}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormGroup>
                <FormGroup>
                  <StyledLabel>
                    Prefer Day
                  </StyledLabel>
                  <StyledSelect
                    style={{
                      width: "100%",
                      // fontSize: 14,
                      // height: 56,
                      // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                    }}
                    value={volunteerDetails?.preferDays}
                    onChange={(e) => handleFieldChange("preferDays", e)}
                  >
                    {preferWorkingDays.map((item, index) => (
                      <MenuItem key={item.value} value={item?.value}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormGroup>

                <FormGroup>
                  <StyledLabel> Organization</StyledLabel>
                  <StyledSelect
                    style={{
                      width: "100%",
                      // fontSize: 14,
                      // height: 56,
                      // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                    }}
                    value={volunteerDetails?.organization}
                    onChange={(e) => {
                      handleFieldChange("organization", e);
                    }}
                  >
                    {org?.map((item, index) => (
                      item?.label === "sponsor" ? (
                        <MenuItem key={item.name} value={item.name}>
                          {item.name} <PaidIcon />
                        </MenuItem>
                      ) : (
                        <MenuItem key={item.name} value={item.name}>
                          {item.name}
                        </MenuItem>
                      )
                    ))}

                   
                  </StyledSelect>
                </FormGroup>

                <FormGroup>
                  <StyledLabel>Organisation Role</StyledLabel>
                  <StyledSelect
                    style={{
                      width: "100%",
                      // fontSize: 14,
                      // height: 56,
                      // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                    }}
                    value={volunteerDetails?.orgRole.toLowerCase()}
                    onChange={(e) => {
                      handleFieldChange("orgRole", e);
                    }}
                  >
                    {orgRole?.map((item, index) => (
                      <MenuItem key={item.role} value={item.role}>
                        {item.role}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormGroup>

                <FormGroup2>
                  <StyledLabel htmlFor="create-yaad--title" >
                    Enter your Correspondence Address{" "}
                    <span
                      style={{
                        paddingLeft: 0,
                        fontSize: 16,
                        fontWeight: 300,
                        fontStyle: "italic",
                      }}
                    >
                      {" "}
                      (Search for address, select and click on Confirm Address.)
                    </span>
                  </StyledLabel>
                 
                  <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div style={{ padding: "25px 0px 0px 0px" }}>
                        <TextField
                          // onChange={handleChange1}
                          // onChange={(e) => setcorrespendeceaddress(e.target.value)}
                          // onFilled={(e) => { handletextentered(e) }}
                          // onBlur={(e) => { handletextentered(e) }}
                          // onFocus={(e) => { handletextentered(e) }}
                          value={correspondenceaddress}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <IconButton>
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          // onBlur={() => {
                          //     if (!country) {
                          //         setCountryError(true);
                          //     } else {
                          //         setCountryError(false);
                          //     }
                          // }}

                          {...getInputProps({
                            placeholder: "Search for address..",
                            autoFocus: false,
                          })}
                        />
                        <div> {loading ? <div>...loading...</div> : null}</div>
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active ? "blue" : "white",
                          };
                          // console.log("Hi");
                          return (
                            <div {...getSuggestionItemProps(suggestion, { style })}>
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </PlacesAutocomplete>
                </FormGroup2>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "100%",
                    transition:
                      "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
                    backgroundColor: "#fff",
                  }}
                >
                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                    <StyledLabel htmlFor="create-yaad--title">
                      Country
                      {/* <RedStar /> */}
                    </StyledLabel>
                    <StyledInput
                    value=
                    {searchSelect
                      ? address.split(",")[address.split(",").length - 1]
                      : volunteerDetails?.country}
                      />
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                    <StyledLabel htmlFor="create-yaad--title" >
                      State
                      {/* <RedStar /> */}
                    </StyledLabel>
                    <StyledInput
                    value=
                    {searchSelect
                      ? address.split(",")[address.split(",").length - 2]
                      : volunteerDetails?.state}
                      />
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                    <StyledLabel htmlFor="create-yaad--title" >
                      City/Town
                      {/* <RedStar /> */}
                    </StyledLabel>
                    <StyledInput
                    value={searchSelect
                      ? address.split(",")[address.split(",").length - 3]
                      : volunteerDetails?.city}
                      />
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                    <StyledLabel htmlFor="create-yaad--title" >
                      Area
                      {/* <RedStar /> */}
                    </StyledLabel>
                    <StyledInput
                    value=
                    {searchSelect
                      ? address.split(",")[address.split(",").length - 4]
                      : volunteerDetails?.area}
                      />
                  </div>
                </div>
              </div>
              <div
                style={{
                  // display: "flex",
                  // flexDirection: "row",
                  // flexWrap: "wrap",
                  // width:"100%",
                  transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
                  backgroundColor: "#fff",
                }}
              >
                <div style={{ textAlign: "center", padding: "20px 0px 0px 0px" }}>
                  <Button
                    variant="contained"
                    onClick={() => updatelocation(address)}
                    // className="btn save-btn"
                    disableElevation
                    style={{
                      // marginTop: 1,
                      // width: "86px",
                      // height: "35px",
                      width: "186px",
                      height: "35px",
                      background: "#f5eceb",
                      // borderRadius: 20,
                      // color: "#fff",
                      color: "green",
                      fontSize: 16,
                      // borderBlockColor: "orange",
                      // borderBlock: 5,
                      fontFamily: "Poppins !important",
                    }}
                  >
                    Confirm Address
                  </Button>
                  {added === "yes" ? (
                    <DoneIcon style={{ color: "green", fontSize: "30px" }} />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "100%",
                  transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
                  backgroundColor: "#fff",
                }}
              >
                <FormGroup style={{ width: "100%" }}>
                  <StyledLabel>
                    House/Door/Flat No.
                  </StyledLabel>
                  <StyledInput
                    // disabled
                    value={volunteerDetails?.correspondenceAddress}
                    type="text"
                    accept="image/*"
                    id="create-yaad--title"
                    onChange={(e) => handleFieldChange("correspondenceAddress", e)}
                  />
                </FormGroup>
              </div>
            </form>
            <div
              style={{
                display: "inline-flex",
                flexDirection: "row",
                // justifyContent: "center",
                margin:'auto'
              }}
            >
              <Button
                onClick={() => handleSave()}
                // className="btn save-btn"
                style={{
                  marginTop: 8,
                  marginLeft: isMobile? 70: 500,
                  width: isMobile ? "100px" : "200px",
                  background: "#E88A00",
                  borderRadius: 20,
                  color: "#fff",
                  fontFamily: "Poppins !important",
                  alignSelf: "center",
                }}
              >
                <span>Save</span>
              </Button>

              <Button
                onClick={() => navigate(-1)}
                style={{
                  marginTop: 8,
                  // marginLeft: 600,
                  // marginLeft: isMobile? 100: 400,
                  width: isMobile ? "100px" : "200px",
                  background: "#e9ecef",
                  borderRadius: 20,
                  // color: "#fff",
                  color: "#3B3F5C",
                  fontFamily: "Poppins !important",
                  alignSelf: "center",
                }}
              >
                <span>Cancel</span>
              </Button>
            </div>
            {profileSuccess && (
              <span
                style={{ color: "green", textAlign: "center", display: "block" }}
              >
                {response?.message}
              </span>
            )}
            {!profileSuccess && (
              // <span style={{ color: "red" }}>Error occured</span>
              <span style={{ color: "red", textAlign: "center", display: "block" }}>
                {response?.message}
              </span>
            )}
            {errorOnSave && (
              <span style={{ color: "red", textAlign: "center", display: "block" }}>
                {errorOnSave}
              </span>
            )}
            <CopyRight />
          </div>
        </div>
      </section>
    </div>
  );
}
export default VolunteerEditScreen;
