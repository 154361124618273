// import * as React, {useRef} from "react";
import React, { useEffect, useRef, useState } from "react";
import { Avatar, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import ResponsiveDrawer from "./Header";
import { CopyRight } from "./CopyRight";
import axios from "axios";
import {isArray, _} from "lodash";
import TopHeaderScreen from "./TopHeader1";
import { useNavigate } from "react-router-dom";
import { FixedFooterIcon } from "./FixedFooterIcon";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";

function SOAManageCampOrder(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [campNames, setCampNames] = React.useState([]);
  const [selectedCampId, setSelectedCampId] = useState(null);
  const [response, setResponse] = useState({});

  const [isLoading, setIsLoading] = React.useState(false);

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  React.useEffect(() => {
    getCampNames();
  }, []);

  const getCampNames = async () => {
    setIsLoading(true);

    // console.log(selectedpointsrange.length === 0);
    try {
      // const res = ""
      //   if ((selectedrewardtype.length === 0) && !(selectedpointsrange.length === 0)) {

      const res = await axios
        .get(`/camp/names/${profileResponse?.phoneno}`)
        .then((response) => {
          setCampNames(response?.data);

          // return response;
          setIsLoading(false);
        });
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
  };

  // const [names, setNames] = useState(["Alice", "Bob", "Charlie", "David"]);
  console.log("campNames = ", campNames);

  const moveName = (index, direction) => {
    setResponse({});
    const newIndex = index + direction;
    if (newIndex >= 0 && newIndex < campNames.length) {
      const newCampNames = [...campNames]; // Copy the campNames array
      const movedItem = newCampNames.splice(index, 1)[0]; // Remove the item from its current index
      newCampNames.splice(newIndex, 0, movedItem); // Insert the item at the new index
      setCampNames(newCampNames); // Update the campNames array in state
      setSelectedTitles(newCampNames.map((name) => name.title)); // Update selectedTitles with new titles
      setSelectedCampids(newCampNames.map((name) => name.campid));
    }
  };

  // Array to store titles
  const [selectedTitles, setSelectedTitles] = useState([]);

  // Array to store campids
  const [selectedCampids, setSelectedCampids] = useState([]);

  // Function to handle submission on button click
  const handleSubmit = async () => {
    console.log("called handleSubmit");
    console.log("selectedTitles = ", selectedTitles);
    console.log("new campnames = ", campNames);
    console.log("selectedCampids = ", selectedCampids);
    if (campNames.length > 0) {
      // if (selectedTitles.length > 0) {
      try {
        // Make an API call with the selected titles
        const res = await axios.put(`/campaign/reorder`, {
          // titles: selectedTitles,
          phoneno: profileResponse?.phoneno,
          // campaigns: selectedCampids,
          campaigns: campNames,
        });
        // Handle the response as needed
        setResponse(res?.data);
        console.log("Submitted:", res.data);
        return res;
      } catch (error) {
        // Handle errors
        console.error("Error:", error);
      }
    } else {
      console.warn("No titles selected.");
    }
  };

  console.log("campNames array = ", isArray(campNames))
  console.log("campNames array len = ", (campNames.length))

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 30,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Rearrange Campaigns
            </div>

            <div style={{ overflowX: "auto" }}>
              <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <thead>
                  <tr
                    style={{
                      backgroundColor: "#808080",
                      color: "white",
                      fontSize: "18px",
                    }}
                  >
                    <th
                      style={{
                        border: "1px solid black",
                        padding: "8px",
                        width: "50%",
                        textAlign: "center",
                      }}
                    >
                      Campaign Name
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                        padding: "8px",
                        width: "25%",
                        textAlign: "center",
                      }}
                    >
                      Campaign Status
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                        padding: "8px",
                        width: "25%",
                        textAlign: "center",
                      }}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                                        
                      campNames.map((name, index) => {
                      const isEvenRow = index % 2 === 0; // Check if the row index is even
                      return (
                        <tr
                          key={index}
                          style={{
                            backgroundColor: isEvenRow ? "#F5F5F5" : "white",
                          }}
                        >
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                              textAlign: "center",
                            }}
                            // style={{ border: "1px solid black", padding: "5px 8px 5px 32px"}}
                          >
                            <img
                              src={name?.logo}
                              class="img-fluid mob-space1 hide-on-mobile"
                              style={{ maxWidth: "50px" }}
                              alt={name?.title}
                            />
                            <span
                              style={{
                                padding: "16px",
                                fontWeight: "800px",
                              }}
                            >
                              {name?.title}
                            </span>
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              style={{
                                padding: "8px",
                                fontWeight: "800px",
                                backgroundColor:
                                name?.status === "Completed"
                                                    ? "#6C757D"
                                                    : name?.status === "Paused"
                                                    ? "#EF4E4E"
                                                    : name?.status === "Upcoming"
                                                    ? "#FADB5F"
                                                    : name?.status === "Active"
                                                    ? "#0D731E"
                                                    : "#000000", // Default color, if none of the conditions match
                                                    color: 
                                                    name?.status === "Upcoming"
                                                        ? "black"
                                                        : "white",
                                
                              }}
                            >
                              {name?.status}
                            </span>
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div style={{ margin: "0 5px", cursor: "pointer" }}>
                                <ArrowUpward
                                  onClick={() => moveName(index, -1)}
                                />
                              </div>
                              <div style={{ margin: "0 5px", cursor: "pointer" }}>
                                <ArrowDownward
                                  onClick={() => moveName(index, 1)}
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })

                  }
                </tbody>
              </table>
              <br></br>
              {
                campNames.length === 0 &&
                <div style={{textAlign:"center"}}>
                  uh oh! You have not created or subscribed to any Campaigns. Please 
                  <span
                    style={{ cursor: "pointer", textDecoration: "underline", color:"blue" }}
                    onClick={() => navigate('/subscriptionlist')}
                  >
                   {" "} subscribe to campaigns {" "}
                  </span>
                  or
                  <span
                    style={{ cursor: "pointer", textDecoration: "underline", color:"blue" }}
                    onClick={() => navigate('/createcampaign')}
                  >
                   {" "} create your own campaign { " "}
                  </span>
                   and inspire changemakers!
                </div>
              }
              {
                campNames.length === 0 ?
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  // className="btn save-btn"
                  className="btn save-btn"
                  onClick={() => handleSubmit()}
                  
                  // style={{ backgroundColor: "white", marginBottom: "10px", color:"gray"}}
                  disabled
                >
                  Save
                </Button>
              </div>
              :
              <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                
                
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                // className="btn save-btn"
                className="btn save-btn"
                onClick={() => handleSubmit()}
                disableElevation
                
                
              >
                <span>Save</span>
              </Button>
            </div>
              }
              {/* <br></br> */}
              {response && (
                <span
                  style={{
                    color: "green",
                    textAlign: "center",
                    display: "block",
                  }}
                >
                  {response?.message}
                </span>
              )}
              <br></br>
            </div>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
}
export default SOAManageCampOrder;
