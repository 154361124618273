import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "../../components/Header";
import { TableComponent } from "../../components/Table";
import { getVolunteerName } from "../../redux/action/getVolunteerName";
import TopHeaderScreen from "../../components/TopHeader1";


const VolunteerListScreen = (props) => {
  const dispatch = useDispatch();
  const { response: volunteerNameResponse, isFetching: volunteerNameFetching } =
    useSelector((state) => state.volunteerName);

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  // useEffect(() => {
  //   dispatch(userProfile(localStorage.getItem("userid")));
  // }, []);
  React.useEffect(() => {
    if (profileResponse) {
      dispatch(
        getVolunteerName({
          organization: profileResponse?.organization,
          userid: localStorage.getItem("userid"),
        })
      );
    }
  }, [profileResponse]);
  return (
    <div>
      <ResponsiveDrawer {...props} />
      <TopHeaderScreen {...props} />
      <main
        onClick={() => {
          props.setNotificationShow(false);
        }}
       
        id="homepage-main"
      >
        <span style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Volunteers List</span>
        <TableComponent
          rows={volunteerNameResponse}
          isFetching={volunteerNameFetching}
        />
      </main>
    </div>
  );
};

export default VolunteerListScreen;
