import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
// import Logo from "../../assets/logo-black.png";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
// import ExecHeader from "../components/ExecHeader";
import { ValidatorTable } from "../../components/validatorTable1";
import {
  Box,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { validatorList } from "../../redux/action/ValidatorList";
import { userProfile } from "../../redux/action/UserProfileActions";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link, useNavigate } from "react-router-dom";
import {  Button } from "@mui/material";

function createDataValidator(
  id,
  formcampaignname,
  // formuserfullname,
  formuserfirstname,
  formuserlastname,
  formuserphone,
  campid,
  registrationstatus,
  registerdate,
  registrationid,
  rewarddate,
) {
  return {
    id,
    formcampaignname,
    // formuserfullname,
    formuserfirstname,
    formuserlastname,
    formuserphone,
    campid,
    registrationstatus,
    registerdate,
    registrationid,
    rewarddate,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  // console.log(props)
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
  // console.log(props)
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ReviewCampaignParticipationScreen = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [welcomeOpen, setWelcomeOpen] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const userid = localStorage.getItem("userid");
  const [value1, setValue1] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [reviewStatus, setReviewStatus] = React.useState("");
  const [campaignList, setCampaignList] = useState(
    [
      'campaignname',
      'formuserfirstname',
      'formuserfirstname',
      'formuserphoneno',
      'registrationstatus',
      'rewarddate'
    ]
  );
  const { isSuccess: validatorListSuccess, response: validatorListResponse } = useSelector(
    (state) => state.validatorList
  );
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(validatorList());
    // dispatch(validatorList(newValue));
  };

  useEffect(() => {

    // console.log("start 2222")    
    // validationList1();
    dispatch(validatorList(profileResponse));
    dispatch(userProfile(userid));
    // validatorList
    // console.log(campaignList);
    // console.log("end 2222")   

  }, [campaignList]);
  useEffect(() => {
    // dispatch(validatorList());
    dispatch(validatorList(profileResponse));
    dispatch(userProfile(userid));
  }, []);

  useEffect(() => {
    if (
      profileSuccess &&
      (profileResponse[0]?.legal === "no" ||
        profileResponse[0]?.legal === undefined)
    ) {
      setWelcomeOpen(true);
    } else {
      setWelcomeOpen(false);
    }
  }, [profileResponse]);

  useEffect(() => {
    // console.log("useeffect")
    let temp = [];
    for (let i = 0; i < validatorListResponse.length; i += 1) {
      const formattedArray = [];
      // console.log(validatorListResponse[i])
      formattedArray.push(
        // validatorListResponse[i]._id,
        validatorListResponse[i].formcampaignname,
        // validatorListResponse[i].formuserfullname,
        validatorListResponse[i].formuserfirstname,
        validatorListResponse[i].formuserlastname,
        validatorListResponse[i].formuserphone,
        // validatorListResponse[i].affiliation,
        validatorListResponse[i].campid,
        validatorListResponse[i].registrationstatus,
        validatorListResponse[i].registerdate,
        validatorListResponse[i].registrationid,
        validatorListResponse[i].formuserlastname,
        validatorListResponse[i].rewarddate
      );

      temp.push(createDataValidator(i, ...formattedArray));
    }
    setRows(temp);
  }, [validatorListResponse]);

  useEffect(() => {
    const firstTime = localStorage.getItem("firstTime");
    if (firstTime) {
      setWelcomeOpen(true);
    }
  }, []);
  const reviewedData = rows.filter((item) => item.registrationstatus === "submitted");
  const acceptedData = rows.filter((item) => item.registrationstatus === "approved");
  const rejectedData = rows.filter((item) => item.registrationstatus === "rejected");
  // console.log(item.reviewStatus)
  // console.log(item.submissionstatus)

  const sortedDataReview =
    inputValue === ""
      ? ""
      : reviewedData.filter((item) => item.formcampaignname === inputValue);

  const sortedDataApprove =
    inputValue === ""
      ? ""
      : acceptedData.filter((item) => item.formcampaignname === inputValue);

  const sortedDataRejected =
    inputValue === ""
      ? ""
      : rejectedData.filter((item) => item.formcampaignname === inputValue);

  // console.log(sortedDataReview)
  // console.log(sortedDataApprove)
  // console.log(sortedDataRejected)
  return (
    <div>
      <ResponsiveDrawer {...props} />
      <section className="containers">
      <TopHeaderScreen {...props} />
      {/* <Link className="backBtnSOAdmin" to={"/home"}> */}
      <div class="content content-wrap">
          <div class="content-mrg">
          <Button
                onClick={() => navigate(-1)}
                className="backWrapper"
                style={{justifyContent:"left"}}
            >
                <i className={`fa fa-caret-left`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
      <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Review Participations</div>
      <main  id="exec" style={{ marginLeft: "400" }} >
        <Tabs
          style={{ display: "flex", justifyContent: "center", marginRight: "100" }}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Registered" />
          <Tab label="Accepted" />
          <Tab label="Rejected" />
        </Tabs>
        <TabPanel value={value} index={0} >
          <ValidatorTable
            value1={value1}
            setValue1={setValue1}
            inputValue={inputValue}
            setInputValue={setInputValue}
            // response={response}
            // isFetching={isFetching}
            rows={reviewedData}
            sortedData={sortedDataReview}
            actionTaken={false}
            setReviewStatus={setReviewStatus}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ValidatorTable
            value1={value1}
            setValue1={setValue1}
            inputValue={inputValue}
            setInputValue={setInputValue}
            // response={response}
            // isFetching={isFetching}
            rows={acceptedData}
            sortedData={sortedDataApprove}
            actionTaken
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ValidatorTable
            value1={value1}
            setValue1={setValue1}
            inputValue={inputValue}
            setInputValue={setInputValue}
            // response={response}
            // isFetching={isFetching}
            rows={rejectedData}
            sortedData={sortedDataRejected}
            actionTaken
          />
        </TabPanel>
      </main>
      </div>
      </div>
      <CopyRight />
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({}, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewCampaignParticipationScreen);
