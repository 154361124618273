import React from "react";
import _ from "lodash";
import { useState } from "react";
import {
  getRewardRedeemCert,
  getRewardRedeemCertReset,
} from "../redux/action/RewardRedeemCertificateAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";

const UploadRedeemedCertificate = (props) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState();
  const [fileSize, setFileSize] = useState(true);
  const [fileUploadProgress, setFileUploadProgress] = useState(false);
  const [fileUploadResponse, setFileUploadResponse] = useState(null);

  React.useEffect(() => {
    dispatch(getRewardRedeemCertReset());
    setFileUploadResponse("");
  }, []);

  const { isCampDocSuccess, campDocResponse, isCampDocError } = useSelector(
    (state) => state.getRewardRedeemCert
  );

  const handleChange = (e) => {
    setFiles(e.target.files);
    
  };
  const handleUpload = () => {

    if (isEmpty(files)) {
      setFileUploadResponse("Please select file(s) to upload");
     
      return;
    }
   
    setFileSize(true);
    setFileUploadProgress(true);
    setFileUploadResponse(null);
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 51200000) {
        setFileSize(false);
        setFileUploadProgress(false);
        setFileUploadResponse(null);
        return;
      }
   
      formData.append(`files`, files[i]);
   
    }
    dispatch(
      getRewardRedeemCert({
        // campid: props.camp,
        userid: localStorage.getItem("userid"),
        phoneno: props.phoneno,
        rewardtype: props.rewardtype,
        mpid: props.mpid,
        formData,
      })
    );
    setFileUploadProgress(false);
    setFileUploadResponse("File(s) uploaded");
  };
 

  return (
    // <div class="col-md-6">
    <div class="row">
      <div class="filecardrewardredeemedcert">
        <div>
          <input
            type="file"
            accept=".doc,.docx,.pdf"
            id="fileID"
            multiple
            onChange={handleChange}
            placeholder="Select File and Upload"
          />
          <button
            class="btn"
            // style={{ marginTop: "5px", marginLeft: "40px" }}
            style={{ marginTop: "5px", maxHeight:"33px" }}
            onClick={handleUpload}
          >
            Upload
          </button>
        </div>
        {!fileSize && <p style={{ color: "red" }}>File size exceeded 50MB!!</p>}
        {fileUploadProgress && (
          <p style={{ color: "orange" }}>Uploading File(s)</p>
        )}
        {fileUploadResponse != null && (
          <p style={{ color: "green" }}>{fileUploadResponse}</p>
        )}
      </div>
    </div>
  );
};

export default UploadRedeemedCertificate;
