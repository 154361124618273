import React from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { isEmpty, isArray } from "lodash";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Avatar, Button } from "@mui/material";


const MyCommunityTree = (props) => {
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  return (
    <div class="containers">
      <div class="cyagg-sec-space">
        {/* <div class="row"> */}
        <div>
          <div class="col-md-12">
            <div
              style={{
                padding: "10px 0",
                // height: "50%",
                // boxShadow: "1px 1px 1px 5px #f0f0f0",
                borderRadius: 30,
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                // justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "#fff"
                backgroundColor: "#FFFFFF",
                // width: "50%",
                border: "1px solid #dadce0",
              }}
            >
              <Avatar
                src={profileResponse?.profilephoto}
                style={{
                  display: "inline-flex",
                  height: "100px",
                  width: "100px",
                }}
              />
            </div>
            <div class="tab-content customOwlNav" id="animateLineContent-4" style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
              {/* <OwlCarousel className="owl-theme" {...optionsFour}> */}
              <div>
                {props.reftree &&
                  props.reftree?.data?.map(
                    (item) =>
                    
                    !isEmpty(item?.referral)  ? 
                     
                      item &&
                      item?.referral.map((item1) => (
                        
                        <div  class="marketplaceinrow" style={{  marginBottom: "30px", float: "left",  textAlign: "center", }}>
                          <div class="reward-wrap-marketplace">
                            <h6 class="sponsor-title">Level {item?.level}</h6>
                            <div
                              style={{ fontSize: "20px", fontWeight: "500" }}
                            >
                              <div>{item1.name}</div>
                            </div>
                          </div>
                        </div>
                      )
                      ):(
                        <div
                          class="marketplaceinrow"
                          style={{
                            marginBottom: "30px",
                            float: "left",
                            textAlign: "center",
                          }} 
                        >
                          <div class="reward-wrap-marketplace">
                            {/* <h6 class="sponsor-title">Level {item?.level}</h6> */}
                            <div
                              style={{ fontSize: "20px", fontWeight: "500" }}
                            >
                              <div>You have not built your network</div>
                            </div>
                          </div>
                        </div>
                      )
                      
                  )}
              </div>
              {/* </OwlCarousel> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
    // </div>
    // <CopyRight />
    // <FixedFooterIcon />
    // </section>
    // </div>
  );
};

export default MyCommunityTree;
