import React, { useEffect, useState, useRef} from 'react';
import { useParams, Link } from 'react-router-dom';
// import styled from 'styled-components';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { Box, Button, Avatar, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link as MuiLink, Grid, IconButton, Menu, List, ListItem, Chip, CircularProgress, TextField, MenuItem, Alert, Snackbar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, AlertTitle } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import TopHeaderScreen from "../../components/TopHeader1";
import { useNavigate } from "react-router-dom";
import { isEmpty, isArray, size } from "lodash";
import moment from "moment";
import { styled } from '@mui/system';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import TeamLogoModal from "../Teams/TeamLogoModal";
// import { userProfile } from "../redux/action/UserProfileActions";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import ActivityCard from "../Teams/ActivityCard";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// import SearchBar from "material-ui-search-bar";
import SearchBar from "../../components/SearchBar";
import { Circles } from "react-loader-spinner";


const StyledListItem = styled(ListItem)(({ theme }) => ({

    '&.MuiButtonBase-root': {
        justifyContent: 'left',
    },
}));

const StyledMenuItem = styled(MenuItem)({
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'flex-start !important',
    padding: '8px 16px !important',
});

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    padding: '16px',
    '@media (min-width: 600px)': {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
});

const Item = styled(Box)({
    flex: '1 1 48%',
    '@media (max-width: 600px)': {
        flex: '1 1 100%',
    },
});

const FullWidthItem = styled(Box)({
    flex: '1 1 100%',
});

const Banner = styled(Box)({
    height: '200px',
    backgroundColor: 'gray',
    position: 'relative',
});

const TeamLogo = styled(Avatar)({
    position: 'absolute',
    bottom: '-30px',
    left: '16px',
    width: '80px',
    height: '80px',
});

const TeamName = styled(Typography)({
    fontWeight: 'bold',
    fontSize: '24px',
    marginTop: '40px',

});

const decryptTeamId = (encryptedId) => {
    const secretKey = 'wq4r35';
    const bytes = CryptoJS.AES.decrypt(encryptedId, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
};


const PointsRank = styled(Typography)({
    backgroundColor: '#01539d',
    color: '#fff',
    padding: '5px',
    borderRadius: '5px',


});

const StatisticBox = styled(Box)({
    textAlign: 'center',
    paddingBottom: '20px',
});

const StatisticText = styled(Typography)({

});

const CurrentPoints = styled(Typography)({
    backgroundColor: '#01539d',
    color: '#fff',
    padding: '5px',
    borderRadius: '5px',

});

const PPText = styled(Typography)({

});

const PPPoint = styled(Typography)({
    backgroundColor: '#01539d',
    color: '#fff',
    padding: '5px',
    borderRadius: '5px',

});

const TeamStatisticsContainer = styled(Box)({
    paddingBottom: '0px',
    backgroundColor: '#da8c10',
    color: "white",
    padding: '12px',
    borderRadius: '5px',
    background: 'linear-gradient(to bottom, #db8d0f, #dc901561)',

});

const HeaderTableRow = styled(TableRow)({
    backgroundColor: '#808080',
});

const Team = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();

    const { teamid } = useParams();
    console.log("teamid = ", teamid);
    // const decryptedTeamId = decryptTeamId(teamid);
    const [myTeamData, setMyTeamData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    console.log("myTeamData = ", myTeamData)
    const [activityList, setActivityList] = React.useState("");
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [loading, setLoading] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');
    const [selectedUser, setSelectedUser] = useState([]);
    const [changemakerResults, setChangemakerResults] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [addTeamMemberResponse, setAddTeamMemberResponse] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [logo, setLogo] = useState(myTeamData[0]?.logo);
    const [modalOpen, setModalOpen] = useState(false);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const topRef = useRef(null);

    useEffect(() => {
        if (topRef.current) {
        topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);


    const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
        (state) => state.profile
    );

    const handleLogoChange = async (newLogo) => {

        const formData = new FormData();
        formData.append('logo', newLogo);
        formData.append('teamid', teamid);
        const response = await axios.post(`/teams/upload/logo/${profileResponse?.phoneno}`, formData);
        console.log("response.data = ", response.data)

        setLogo(URL.createObjectURL(newLogo));
        await fetchMyTeamSpecificPage();
        // setMyTeamData((prevData) => {
        //     return prevData.map((team) =>
        //         team.teamid === teamid ? { ...team, logo: newLogoUrl } : team
        //     );
        // });
        // callback();
    };

    const handleMakeOwnerMember = async (role) => {
        try {
            // setSnackbarOpen(true);
            setAddTeamMemberResponse('');
            const response = await axios.post(`/teams/change/memberowner/${profileResponse?.phoneno}`, {
                teamid: teamid,
                role: role,
                member: selectedUserId,
            });
            if (response.data.status === "success") {
                console.log(response.data.message);
                // setSnackbarMessage(response.data.message);
                setAddTeamMemberResponse(response.data);

            } else {
                console.error(response.data.message);
                // setSnackbarMessage(response.data.message);
                setAddTeamMemberResponse(response.data);

            }
            await fetchMyTeamSpecificPage();
        } catch (error) {
            console.error('There was an error making the user an owner!', error);

        } finally {
            setSnackbarOpen(true);
            handleMenuClose();
        }
    };

    const handleRemoveOwnerMember = async () => {
        try {
            // setSnackbarOpen(true);
            setAddTeamMemberResponse('');
            const response = await axios.post(`/teams/remove/memberowner/${profileResponse?.phoneno}`, {
                teamid: teamid,
                member: selectedUserId,
            });
            if (response.data.status === "success") {
                console.log(response.data.message);
                // setSnackbarMessage(response.data.message);
                setAddTeamMemberResponse(response.data);

            } else {
                console.error(response.data.message);
                // setSnackbarMessage(response.data.message);
                setAddTeamMemberResponse(response.data);

            }
            await fetchMyTeamSpecificPage();
        } catch (error) {
            console.error('There was an error removing the member!', error);

        } finally {
            setSnackbarOpen(true);
            handleMenuClose();
        }
    };


    const handleOpenConfirmDialog = () => {
        // setSelectedUserId(userId);
        setConfirmDialogOpen(true);
    };

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    };

    const handleConfirmRemove = async () => {
        await handleRemoveOwnerMember();
        setConfirmDialogOpen(false);
    };

    const handleMenuClick = async (event, userId) => {
        setAnchorEl(event.currentTarget);
        // setSelectedTeam(team);
        setSelectedUserId(userId);
        await fetchMyTeamSpecificPage();
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedTeam(null);
    };


    const handleAddMembers = async () => {
        if (!selectedUser || selectedUser.length === 0) {
            console.log("no member selected")
            const response = { "message": "Please select a member to add.", "status": "failure" }
            setAddTeamMemberResponse(response);
            setSnackbarOpen(true);
            console.log("snackbar opened");
            return;
        }
        try {
            await addMemberToTeam(selectedUser);
            setSelectedUser(null);
            setSnackbarOpen(true);
            // setTimeout(() => {
            //     setSnackbarOpen(false); 
            // }, 6000);
        } catch (error) {
            console.error("Failed to add member to the team", error);
        }
    };

    const addMemberToTeam = async (selectedUser) => {
        try {
            console.log("selectedUser = ", selectedUser)
            const memberNames = selectedUser.map(member => `${member.uniqueuserid}`).join(',');
            console.log("memberNames = ", memberNames)
            const response = await axios.post(`/teams/add/member/${profileResponse?.phoneno}`, { members: memberNames, teamid: teamid });
            console.log("response = ", response)
            setAddTeamMemberResponse(response.data);
            // dispatch(userProfile(replacedLoginPhoneNumber))
            return response.data;
        } catch (error) {
            console.error("There was an error adding the member to the team!", error);
            throw error;
        }
    };
    const fetchChangemakerData = async (searchQuery) => {
        try {
            setLoading(true);
            const response = await axios.get(`/teams/search/vol/test/${searchQuery}`);
            console.log("Fetched data:", response.data);
            setChangemakerResults(response.data);
        } catch (error) {

            console.error("Error fetching Changemaker data:", error);
            setChangemakerResults([]);
        } finally {
            setLoading(false);
        }
    };
    const handleChangeWrapper = (event) => {
        handleSearchChange(event.target.value);
    };

    const handleSearchChange = (value) => {
        const query = value;
        setSearchQuery(query);
        if (query.length > 0) {
            fetchChangemakerData(query);
        } else {
            setChangemakerResults([]);
        }
    };

    const handleSelectUser = (user) => {
        console.log("user = ", user)
        setSelectedUser((prevSelectedUsers) => {
            prevSelectedUsers = prevSelectedUsers || [];
            console.log("prevSelectedUsers = ", prevSelectedUsers)
            if (prevSelectedUsers.find((selectedUser) => selectedUser.id === user.id)) {
                return prevSelectedUsers;
            }
            return [...prevSelectedUsers, user];
        });
        setChangemakerResults([]);
        setSearchQuery('');
    };

    const handleDeleteUser = (user) => {
        setSelectedUser((prevSelectedUsers) =>
            prevSelectedUsers.filter((selectedUser) => selectedUser.id !== user.id)
        );
    };

    const getActivityList = async () => {
        try {
            const res = await axios.get(
                `/teams/activity/${teamid}/${localStorage.getItem("userid")}`
            );

            setActivityList(res?.data);
            return res;

        } catch (err) {

            throw err;
        }
    };


    React.useEffect(() => {
        getActivityList();
    }, []);

    const fetchMyTeamSpecificPage = async () => {
        try {
            const response = await axios.get(`/teams/my/team/${teamid}/${profileResponse?.phoneno}`);
            setMyTeamData(response.data);
            setLogo(response?.data[0].logo);
        } catch (error) {
            console.error('Error fetching my team', error);
        }
    };

    useEffect(() => {
        if (!teamid || !profileResponse?.phoneno) return;
        const fetchMyTeamSpecificPage = async () => {
            try {
                const response = await axios.get(`/teams/my/team/${teamid}/${profileResponse?.phoneno}`);
                setMyTeamData(response.data);
            } catch (error) {
                console.error('Error fetching my team', error);
            }
        };
        fetchMyTeamSpecificPage();
    }, [teamid, profileResponse, selectedUser]);

    // if (!myTeamData || myTeamData.length === 0) return <div>Loading...</div>;
    const team = myTeamData[0];

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        handleUpload(event.target.files[0]);
    };

    const handleUpload = async (file) => {
        if (!file) {
            alert('Please select abanner first');
            return;
        }

        const formData = new FormData();
        formData.append('bannerlogo', file);
        formData.append('teamid', teamid);

        try {
            const response = await axios.post(`/teams/upload/banner/${profileResponse?.phoneno}`, formData);
            const newBannerUrl = response.data.banner;
            console.log("newbannerurl = ", newBannerUrl);
            console.log('File uploaded successfully', response.data);

            setMyTeamData((prevData) => {
                return prevData.map((team) =>
                    team.teamid === teamid ? { ...team, banner: newBannerUrl } : team
                );
            });
        } catch (error) {
            console.error('Error uploading file', error);
        }
    };

    const triggerFileInput = () => {
        document.getElementById('upload-banner').click();
    };
    return (
        <div className="wrapperTeam" ref={topRef}>
            <ResponsiveDrawer {...props} />
            <section className="containers">
                <TopHeaderScreen {...props} />
                <div className="content content-wrap">
                    <div className="content-mrg createTeamPage">
                        <Button onClick={() => navigate(-1)} style={{ color: 'rgb(25, 118, 210)' }}>
                            <i className="fa fa-caret-left" />
                            <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
                        </Button>
                        {!myTeamData || myTeamData.length === 0 ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
                                <Circles
                                    type="TailSpin"
                                    color="rgb(155, 236, 34)"
                                    height={70}
                                    width={70}
                                    timeout={5000}
                                    ariaLabel="three-circles-rotating"
                                />
                            </div>
                        ) : (
                            <>
                            <Container>
                                <FullWidthItem>
                                    <Banner>
                                        {
                                            team?.areyouowner === "yes" ?
                                                <TeamLogo src={team.logo} alt={team.name} onClick={() => setModalOpen(true)} style={{ cursor: 'pointer' }} />
                                                :
                                                <TeamLogo src={team.logo} alt={team.name} />
                                        }
                                        {
                                            team.banner &&
                                            <img src={team.banner} alt="Team Banner" style={{ width: '100%', height: '100%' }} />
                                        }
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            id="upload-banner"
                                            onChange={handleFileChange}
                                        />
                                        {
                                            team?.areyouowner === "yes" &&
                                            <label htmlFor="upload-banner">
                                                <IconButton variant="contained" style={{ position: 'absolute', top: 10, right: 10, backgroundColor: '#1976d2', color: 'white' }} onClick={triggerFileInput}>
                                                    <CameraAltOutlinedIcon />
                                                </IconButton>
                                            </label>
                                        }
                                    </Banner>
                                </FullWidthItem>
                                <TeamLogoModal
                                    open={modalOpen}
                                    handleClose={() => setModalOpen(false)}
                                    handleLogoChange={handleLogoChange}
                                    // teamLogo={logo}
                                    teamLogo={myTeamData[0]?.logo}
                                    teamid={teamid}
                                />
                                <FullWidthItem>
                                    <TeamName>{team.name}</TeamName>
                                    <Typography variant="subtitle2">{team.tagline}</Typography>
                                    <Typography>{team.about}</Typography>
                                    <MuiLink href="#members" style={{ display: 'block', margin: '8px 0' }}>
                                        See all members in team
                                    </MuiLink>
                                    {
                                        team?.areyouowner === "yes" &&
                                        <Button
                                            startIcon={<EditIcon />}
                                            component={Link}
                                            to={`/edit/team/${teamid}`}
                                        />
                                    }
                                </FullWidthItem>
                            </Container>
                        
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8}>
                                <FullWidthItem id="campaigns">
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography variant="h6">Team Campaigns</Typography>
                                        {
                                            team?.areyouowner === "yes" &&
                                            <Button
                                                // variant="outlined"
                                                startIcon={<EditIcon />}
                                                component={Link}
                                                to={`/edit/team/campaign/${teamid}`}
                                                style={{ marginBottom: "2px" }}
                                            />
                                        }
                                    </Box>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <HeaderTableRow>

                                                    <TableCell sx={{ width: { xs: '30%', sm: '30%', md: '30%' }, color: 'white' }}>Campaign Name</TableCell>
                                                    <TableCell sx={{ width: { xs: '70%', sm: '70%', md: '70%' }, color: 'white' }}>Campaign Goal</TableCell>
                                                </HeaderTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {team.campname.map((camp, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{camp}</TableCell>
                                                        <TableCell style={{ maxHeight: '125px', overflowY: 'auto', display: 'block' }}>{team.goal[index]}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </FullWidthItem>
                                <FullWidthItem>
                                    <Typography variant="h6" style={{ marginTop: "20px" }}>Team Statistics</Typography>
                                    <TeamStatisticsContainer>
                                        <div>
                                            <Grid container spacing={3} justifyContent="center">
                                                <Grid item xs={12} sm={4} md={3}>
                                                    <StatisticBox>
                                                        <StatisticText>Team Points</StatisticText>
                                                        <CurrentPoints variant="h6" style={{ marginTop: "10px" }}>
                                                            {
                                                                team.teampoints.toFixed(1)
                                                            }
                                                        </CurrentPoints>
                                                    </StatisticBox>
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={3}>
                                                    <StatisticBox>
                                                        <StatisticText>Team Rank</StatisticText>
                                                        <PointsRank variant="h6" style={{ marginTop: "10px" }}>
                                                            {
                                                                team.teamrank
                                                            }
                                                        </PointsRank>
                                                    </StatisticBox>
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={3}>
                                                    <StatisticBox>
                                                        <PPText>Team Count</PPText>
                                                        <PPPoint variant="h6" style={{ marginTop: "10px" }}>
                                                            {
                                                                team.memberdetails.length
                                                            }
                                                        </PPPoint>
                                                    </StatisticBox>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </TeamStatisticsContainer>
                                </FullWidthItem>

                                <FullWidthItem id="members">
                                    <Typography variant="h6" style={{ marginTop: "20px" }}>My Team Members</Typography>
                                    {

                                        team?.areyouowner === "yes" &&
                                        // <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, marginTop: '5px', flexDirection: { xs: 'column', sm: 'row' } }}>
                                        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                            <Box sx={{ width: { xs: "100%", sm: "auto" }, mb: { xs: 2, sm: 0 }, flex: 1 }}>
                                                <SearchBar
                                                    value={searchQuery}
                                                    // onChange={(searchVal) => handleSearchChange({ target: { value: searchVal } })}
                                                    onChange={handleChangeWrapper}
                                                    // onCancelSearch={() => handleSearchChange({ target: { value: '' } })}
                                                    onCancelSearch={() => handleSearchChange('')}
                                                    placeholder="Search"
                                                    style={{
                                                        width: isMobile ? '100%' : '50%', justifyContent: 'normal', border: "1px solid #ccc", // Adjust the color and width as needed
                                                        borderRadius: "4px"
                                                    }}
                                                />

                                                {loading && <CircularProgress />}
                                                {!loading && (
                                                    <Box sx={{ position: "relative" }}>
                                                        <List sx={{ width: "100%", paddingBottom: "0px", overflow: "auto", maxHeight: "200px" }}>
                                                            {changemakerResults &&
                                                                changemakerResults.map((result) => (
                                                                    <StyledListItem
                                                                        key={result.id}
                                                                        onClick={() => handleSelectUser(result)}

                                                                    >
                                                                        {result.firstname} {result.lastname}
                                                                    </StyledListItem>
                                                                ))}
                                                        </List>
                                                    </Box>
                                                )}
                                                {selectedUser &&
                                                    selectedUser.length > 0 && (
                                                        <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1, marginBottom: "10px" }}>
                                                            {selectedUser.map((user) => (
                                                                <Chip
                                                                    key={user.id}
                                                                    label={`${user.firstname} ${user.lastname}`}
                                                                    onDelete={() => handleDeleteUser(user)}
                                                                />
                                                            ))}
                                                        </Box>
                                                    )}
                                            </Box>

                                            <Box sx={{
                                                flexShrink: 0,
                                                ml: { xs: 0, sm: 2 },
                                                mt: { xs: 2, sm: 0 },
                                                alignSelf: "flex-start",
                                            }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleAddMembers}
                                                    style={{
                                                        minWidth: '120px !important',
                                                        fontSize: '12px !important',
                                                        backgroundColor: '#1976d2',
                                                        padding: '6px 16px',
                                                    }}
                                                >
                                                    Add Members
                                                </Button>
                                            </Box>
                                        </Box>
                                    }
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <HeaderTableRow>
                                                    <TableCell sx={{ width: { xs: '30%', sm: '50%', md: '30%' }, color: 'white' }}>Name</TableCell>
                                                    <TableCell sx={{ width: { xs: '50%', sm: '50%', md: '50%' }, color: 'white' }}>Organization</TableCell>
                                                    <TableCell sx={{ width: { xs: '30%', sm: '30%', md: '30%' }, color: 'white' }}>Role</TableCell>
                                                    <TableCell sx={{ width: { xs: '40%', sm: '40%', md: '40%' }, color: 'white' }}>Points</TableCell>
                                                    <TableCell sx={{ width: { xs: '20%', sm: '20%', md: '20%' }, color: 'white' }}>Actions</TableCell>
                                                </HeaderTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {team.memberdetails.map((member, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap'
                                                                }}
                                                            >
                                                                {member.firstname} {member.lastname}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>{member.organization}</TableCell>
                                                        <TableCell >{member.isowner.charAt(0).toUpperCase() + member.isowner.slice(1)}</TableCell>
                                                        <TableCell style={{ minWidth: "100px" }}>{member.totalpoints.toFixed(1)}</TableCell>
                                                        <TableCell style={{ minHeight: "150px", maxWidth: "50px" }}>
                                                            <IconButton onClick={(event) => handleMenuClick(event, member.uniqueuserid)}>
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                            <Menu
                                                                anchorEl={anchorEl}
                                                                open={Boolean(anchorEl)}
                                                                onClose={handleMenuClose}
                                                            >
                                                                {
                                                                    myTeamData &&
                                                                        myTeamData?.[0]?.areyouowner === "yes" ?
                                                                        <>
                                                                            <StyledMenuItem onClick={() => handleMakeOwnerMember("owner")}>
                                                                                <VerifiedUserIcon style={{ marginRight: 8, color: 'gray' }} />
                                                                                Make Owner
                                                                            </StyledMenuItem>
                                                                            <StyledMenuItem onClick={() => handleMakeOwnerMember("member")}>
                                                                                <PersonIcon style={{ marginRight: 8, color: 'gray' }} />
                                                                                Make Member
                                                                            </StyledMenuItem>
                                                                            <StyledMenuItem onClick={() => handleOpenConfirmDialog()}>
                                                                                <DeleteIcon style={{ marginRight: 8, color: 'gray' }} />
                                                                                Remove from team
                                                                            </StyledMenuItem>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <StyledMenuItem disabled>
                                                                                <VerifiedUserIcon style={{ marginRight: 8, color: 'gray' }} />
                                                                                Make Owner
                                                                            </StyledMenuItem>
                                                                            <StyledMenuItem disabled>
                                                                                <PersonIcon style={{ marginRight: 8, color: 'gray' }} />
                                                                                Make Member
                                                                            </StyledMenuItem>
                                                                            <StyledMenuItem disabled>
                                                                                <DeleteIcon style={{ marginRight: 8, color: 'red' }} />
                                                                                Remove from team
                                                                            </StyledMenuItem>
                                                                        </>

                                                                }


                                                                <Dialog
                                                                    open={confirmDialogOpen}
                                                                    onClose={handleConfirmDialogClose}
                                                                >
                                                                    <DialogTitle>{"Confirm Remove Member"}</DialogTitle>
                                                                    <DialogContent>
                                                                        <DialogContentText>
                                                                            Are you sure you want to remove this member from the team?
                                                                        </DialogContentText>
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button onClick={handleConfirmDialogClose} color="primary">
                                                                            No
                                                                        </Button>
                                                                        <Button onClick={handleConfirmRemove} color="primary" autoFocus>
                                                                            Yes
                                                                        </Button>
                                                                    </DialogActions>
                                                                </Dialog>

                                                            </Menu>

                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </FullWidthItem>

                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h6">Activities</Typography>
                                <Box>
                                    {isArray(activityList) ? (
                                        <div class="new-pro-space new-job-pro-right-wrap" style={{ overflowY: "scroll", height: "735px" }}>
                                            <div>
                                                {
                                                    activityList &&
                                                    activityList?.map(
                                                        (action) => (
                                                            <ActivityCard
                                                                profilePhoto={action?.camplogo}
                                                                timestamp={action?.date}
                                                                hoursAgo={moment(
                                                                    action?.date
                                                                ).fromNow()}
                                                                message={action?.desc}
                                                            />
                                                        )
                                                    )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            class="new-pro-space new-job-pro-right-wrap"
                                        >
                                            <div>
                                                <div class="m-acti-wrap">
                                                    <div class="sec-1">
                                                        <span
                                                            style={{
                                                                display: "inline-block",
                                                                fontSize: 16,
                                                                display: "block",
                                                                fontWeight: "400",
                                                                marginLeft: "2px",
                                                            }}
                                                        >
                                                            {activityList?.message}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                        </>
    )}
                    </div>
                </div>
                <CopyRight />
                <FixedFooterIcon />
            </section>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={() => setSnackbarOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={() => setSnackbarOpen(false)}
                        severity={addTeamMemberResponse?.status === "success" ? "success" : "warning"}
                        sx={{
                            width: '100%',
                            backgroundColor: addTeamMemberResponse?.status === "success" ? '#28A745 !important' : '#B9544B !important',
                            color: 'white !important',
                            boxShadow: 'none',
                            zIndex: 9999,
                        }}
                    >
                        {addTeamMemberResponse?.message}
                    </Alert>
                </Snackbar>
            </Box>
        </div>
    );
};

export default Team;
