import React from "react";
import { Button , Typography, Avatar} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import ResponsiveDrawer from "./Header";
import { CopyRight } from "./CopyRight";
import TopHeaderScreen from "./TopHeader1";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirmation from "./DeleteConfirmation";
import { FixedFooterIcon } from "./FixedFooterIcon";
import { isArray, isEmpty } from "lodash";
import styled from 'styled-components';
import InputBase from '@mui/material/InputBase';

const StyledEmailInput = styled(InputBase)(({ theme }) => ({
  width: '100%',
  border: 'none',
  padding: 10,
  display: 'block',
  borderRadius: 6,
  height: 54,
  boxShadow: '0px 3px 6px #00000029',
  color: '#949494',
  fontSize: 15,
  '@media (max-width: 600px)': {
    fontSize: 12,
  },
}));

const FormGroup = styled('div')(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: '40px',
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));


const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup1
const FormGroup1 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup2
const FormGroup2 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  flexDirection: 'column',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '75px 0 0 16px',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup3
const FormGroup3 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  width: '20%',
  '@media (max-width: 1200px)': {
    width: '20%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup4
const FormGroup4 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'right',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 0',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup7
const FormGroup7 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup8
const FormGroup8 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledLabel
const StyledLabel = styled('label')(({ theme }) => ({
  display: 'block',
  background: '#e9e7e7',
  padding: '10px',
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontWeight: 600,
  marginTop: '16px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

// StyledSpan
const StyledSpan = styled('span')(() => ({
  fontSize: '12px',
}));

// FocusThematic
const FocusThematic = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledInput
const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// StyledTextarea
const StyledTextarea = styled('textarea')(() => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// LogoButton
const LogoButton = styled('input')(() => ({
  width: '8%',
  border: 'none',
  padding: '10px',
  display: 'flex',
  height: '40px',
  color: 'white',
  '@media (max-width: 1920px)': {
    fontSize: '15px',
    width: '12%',
  },
  '@media (max-width: 1280px)': {
    fontSize: '15px',
    width: '15%',
  },
  '@media (max-width: 960px)': {
    fontSize: '15px',
    width: '20%',
  },
  '@media (max-width: 600px)': {
    fontSize: '15px',
    width: '30%',
  },
}));

// StyledTypography
const StyledTypography = styled(Typography)(({ theme }) => ({
  '& .css-u4tvz2-MuiFormLabel-root': {
    fontFamily: 'Poppins',
  },
  display: 'block',
  display: 'flex',
  fontSize: '16px',
  marginTop: '16px',
  fontWeight: 500,
  lineHeight: '18px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

const LoginPgLogo = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (max-width: 768px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
}));

const StyledLargeAvatar = styled(Avatar)(({ theme }) => ({
  width: '200px !important',
  height: '200px !important',
  margin: '0 auto',
  marginTop: '5px',
  marginBottom: '5px',
  '@media (max-width: 400px)': {
    width: '120px !important',
    height: '120px !important',
  },
  '@media (max-width: 600px)': {
    width: '140px !important',
    height: '140px !important',
  },
  '@media (max-width: 768px)': {
    width: '150px !important',
    height: '150px !important',
  },
}));

const DeletePersonalFileDetails = (props) => {
  const location = useLocation();
  const [campregisterlist, setCampRegisterList] = useState([]);
  
  const getCampRegistrationList = async () => {
    try {
      // const res = await axios.get(
      //   `/camp/video/${selectedCategory}/${localStorage.getItem("userid")}`
      // );
      const res = await axios.get(
        `/list/files/campaign/personal/${location?.pathname.split("/")?.[3]}/${
          location?.pathname.split("/")?.[4]
        }/${location?.pathname.split("/")?.[5]}`
      );
      setCampRegisterList(res?.data);
      
      return res;
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    getCampRegistrationList();
  }, [location?.pathname.split("/")?.[2]]);
 

  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const navigate = useNavigate();
  const locationPath = useLocation();
  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };
  
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  
  const handleDeleteFile = async () => {
    // setDisplayConfirmationModal(true);
    const payload = {
      phoneno: location?.pathname.split("/")?.[5],
      campid: location?.pathname.split("/")?.[4],
      id: location?.pathname.split("/")?.[3],
    };
    try {
      const res = await axios.put(
        // `/user/delete/${localStorage.getItem("userid")}`,
        `/camp/personal/file/delete`,
        payload
      );
      // navigate("/reviewcampaign");
      // setuserdeletestat(res?.data);
      setDisplayConfirmationModal(false);
      navigate("/myongoingcampaignspvt");
      return res;
    } catch (err) {
      throw err;
    }
  };
  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Delete File
            </div>
            {/* <span
        style={{
          fontSize: 25,
          fontWeight: "600",
          marginBottom: 15,
          marginTop: 5,
        }}
      >
        Delete File
      </span> */}
            <form autoComplete="off">
              <div
                style={{
                  border: "1px solid #dadce0",
                  borderRadius: 8,
                  padding: "13px 30px 40px 30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <FormGroup style={{ width: "100%" }}>
                    <StyledLabel>User Name</StyledLabel>
                    <StyledInput
                      disabled
                      // value={volunteerDetails?.fullname}
                      value={campregisterlist?.username}
                      type="text"
                    />
                  </FormGroup>
                  <FormGroup style={{ width: "100%" }}>
                    <StyledLabel>User organization</StyledLabel>
                    <StyledEmailInput
                      disabled
                      value={campregisterlist?.userorg}
                      type="text"
                    />
                  </FormGroup>
                  <FormGroup style={{ width: "100%" }}>
                    <StyledLabel>File uploaded on</StyledLabel>
                    <StyledInput
                      disabled
                      value={campregisterlist?.timestamp}
                      type="text"
                      placeholder="Your answer"
                    />
                  </FormGroup>
                </div>
              </div>
            </form>
            <div className="centerAlign" style={{ padding: "16px",marginRight:"10px",float:"left",width:"100%"}}>
            {
                isEmpty(campregisterlist) ?
              <Button
              disabled
                style={{
                  marginTop: 8,
                  width: "200px",
                  background: "gray",
                  borderRadius: 20,
                  color: "#fff",
                  fontFamily: "Poppins !important",
                  alignSelf: "center",
                  marginRight:"5px",
                }}
              >
                <span>Delete</span>
              </Button>
              : 
              <Button
                onClick={() => setDisplayConfirmationModal(true)}
                style={{
                  marginTop: 8,
                  width: "200px",
                  background: "red",
                  borderRadius: 20,
                  color: "#fff",
                  fontFamily: "Poppins !important",
                  alignSelf: "center",
                  marginRight:"5px",
                }}
              >
                <span>Delete</span>
              </Button>
}

              <Button
                onClick={() => navigate(-1)}
                style={{
                  marginTop: 8,
                  width: "200px",
                  background: "#e9ecef",
                  borderRadius: 20,
                  color: "#3B3F5C",
                  fontFamily: "Poppins !important",
                  alignSelf: "center",
                }}
              >
                <span>Cancel</span>
              </Button>
            </div>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon/>
        <DeleteConfirmation
          showModal={displayConfirmationModal}
          confirmModal={handleDeleteFile}
          hideModal={hideConfirmationModal}
          message={"Are you sure you want to delete this file?"}
        />
      </section>
    </div>
  );
};

export default DeletePersonalFileDetails;
