import React, { useState } from 'react';
import QrReader from 'react-qr-scanner';
import CloseIcon from '@mui/icons-material/Close';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { Modal } from "react-bootstrap";
import { Button } from "@mui/material";
import axios from "axios";

const QRScanner = ({onClose, onScan, scanStatus, selectedCampId,invalidBarcode}) => {
  const [result, setResult] = useState('');
  const [isCameraOpen, setIsCameraOpen] = useState(true);
  const [showScannedMessage, setShowScannedMessage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [postScanCampidDecrypt, setPostScanCampidDecrypt] = useState('');

  // const decryptQRCode = async (encryptedcampid) => {
  //   try {
  //       console.log("decryptQRCode = ", encryptedcampid)
  //       const resqr = await axios.get(`/decode/qr/campid/${encryptedcampid}`);
  //       console.log("resqr = ", resqr);
  //       setPostScanCampidDecrypt(resqr?.data);

  //     // return res;
  //   } catch (err) {
  //     throw err;
  //   }
  // };

  // console.log("postScanCampidDecrypt = ", postScanCampidDecrypt)

  const handleScan = async (data) => {
    if (data) {
      try {

        console.log("QRScanner.js ->  *****SCAN STARTS*****")
        console.log("QRScanner.js -> data = ", data)
        const cleanedText = data.text.replace(/^"(.*)"$/, '$1');
        const validJsonText = cleanedText.replace(/'/g, '"');
        const parsedData = JSON.parse(validJsonText);
        console.log("parsedData.campid = ", parsedData.campid);
        // decryptQRCode(parsedData.campid);
        const resqr = await axios.get(`/decode/qr/campid/${parsedData.campid}`);
        console.log("QRScanner - resqr = ", resqr);
        // setPostScanCampidDecrypt(resqr?.data);
        
        // const isCampIdPresent = parsedData.campid === selectedCampId.value;
        // const isCampIdPresent = postScanCampidDecrypt["campid"] === selectedCampId.value;
        const isCampIdPresent = resqr?.data["campid"] === selectedCampId.value;
        console.log("Campid matching ? = ", isCampIdPresent)
        if(!isCampIdPresent)
        {
          setShowModal(true);
          invalidBarcode();
          return false;
        }
        setResult(parsedData);
        onScan(parsedData);

        setShowScannedMessage(true);   
        setTimeout(() => {
          setShowScannedMessage(false);
          setIsCameraOpen(true);
          if(scanStatus === 'Approved') {
           console.log("if approved trigger");
            setLoading(true);
            setIsCameraOpen(false);
            setTimeout(() => {
              setLoading(false);
              setIsCameraOpen(true);
            }, 3000);
          }
        }, 3000);
       
      } catch (error) {
        console.error("Error parsing QR code data:", error);
        onScan("Error");
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const closeModalWindow = () => {
    setShowModal(false);
  };
  
  const handleLoad = () => {
    setLoading(false);
  };
  
  const previewStyle = {
    width: '100%',
    height: '250px',
    maxWidth: '500px',
    margin: '0 auto',
    border: '5px solid #fff',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
  };

  const closeCamera = () => {
    setIsCameraOpen(false);
    onClose();
  };

  React.useEffect(() => {
    
    const timeoutId = setTimeout(() => {
      setShowScannedMessage(false);
      setIsCameraOpen(true);
    }, 3000);

    
    return () => clearTimeout(timeoutId);
  }, [showScannedMessage]);

  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{height:"250px"}}>
        <div style={{ textAlign: 'center',position:"absolute",top:"10px" }}>
         <QrCodeScannerIcon style={{width:"70%",height:"60%", color:"black", 
        mask: `url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><circle cx='4' cy='4' r='2' fill='black' /></svg>") repeat`,
        WebkitMask: `url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><circle cx='4' cy='4' r='2' fill='black' /></svg>") repeat`,
        MozMask: `url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><circle cx='4' cy='4' r='2' fill='black' /></svg>") repeat`,}}/>
         {loading && (scanStatus || !scanStatus) && (
          <p>Loading...</p>
          )}
           {!loading && !scanStatus && (
          <p style={{marginTop:"5px"}}>Please Scan...</p>
          )}
           {scanStatus && !loading && (
         // <p style={{color: "greenyellow",background:"#000"}}>{scanStatus}</p>
          
         scanStatus === "Approved" ?
            <p style={{color: "greenyellow",background:"#000"}}>{scanStatus}</p>
            : scanStatus === "Rejected" ?
             <p style={{color: "orange",background:"#000"}}>{scanStatus}</p>
            : <p style={{color: "red",background:"#000"}}>{scanStatus}</p>
          
          )}
        </div>
      
      <CloseIcon
        style={{ cursor: 'pointer', position: 'absolute', top: '20px', right: '10px',zIndex:1, color: "#fff"}}
        onClick={closeCamera}
      />
      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={previewStyle}
        onLoad={handleLoad}
      />
      <p>{result}</p>
      </div>
      <Modal
        show={showModal}
        onHide={() => closeModalWindow()}
      >
      <Modal.Header>
          <Modal.Title>Alert!!!</Modal.Title>
          <button
            type="button"
            onClick={() => closeModalWindow()}
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>
            QR Code is not valid for this campaign.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{
              // backgroundColor: "#db8d0f",
              // backgroundColor: "#E9ECEF",
              backgroundColor: "#A9A9A9",
              color: "#fff",
            }}
            onClick={() => closeModalWindow()}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default QRScanner;
