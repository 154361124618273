// OTPInput.js
import React, {useRef} from 'react';
import PropTypes from 'prop-types';

const OTPInput = ({
  value,
  onChange,
  length,
  separator,
  placeholder,
  isInputNum,
  onKeyPress,
  inputStyle
}) => {
  const inputRefs = useRef([]);
  const handleChange = (event, index) => {
    const newValue = event.target.value;
    const otpArray = value.split('');
    otpArray[index] = newValue;

    onChange(otpArray.join(''));
    if (newValue && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };
  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && index > 0) {
      const otpArray = value.split('');
      otpArray[index - 1] = '';
      onChange(otpArray.join(''));
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div className="otp-input-container">
      {Array.from({ length }).map((_, index) => (
        <input
          key={index}
          ref={(el) => (inputRefs.current[index] = el)}
          type={isInputNum ? 'number' : 'text'}
          value={value[index] || ''}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onKeyPress={onKeyPress}
          // placeholder={placeholder}
          // style={inputStyle}
          style={{ ...inputStyle, width: '45px', height: '45px' }}
          maxLength={1}
        />
      ))}
      {separator}
    </div>
  );
};

OTPInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  length: PropTypes.number.isRequired,
  separator: PropTypes.element,
  placeholder: PropTypes.string,
  isInputNum: PropTypes.bool,
  onKeyPress: PropTypes.func,
  inputStyle: PropTypes.object
};

OTPInput.defaultProps = {
  separator: null,
  placeholder: '',
  isInputNum: false,
  onKeyPress: () => {},
  inputStyle: {}
};

export default OTPInput;
