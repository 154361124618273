import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { Avatar} from '@mui/material';
import { styled } from '@mui/system';

const Paper = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: 400,
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 2),
  borderRadius: '5px',
  '@media (max-width: 600px)': {
    width: '90%',
    padding: theme.spacing(2, 2, 2),
  },
}));

const TeamLogo = styled(Avatar)({
  // position: 'absolute',
  // bottom: '-30px',
  // left: '16px',
  width: '80px',
  height: '80px',
  marginLeft: '120px'
});

const TeamLogoModal = ({ open, handleClose, handleLogoChange, teamLogo, teamid }) => {
  const [newLogo, setNewLogo] = useState(null);
  const [error, setError] = useState('');

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];

    if (file && !validImageTypes.includes(file.type)) {
      setError('Please upload a valid image file (JPEG, PNG, GIF).');
      setNewLogo(null);
    } else {
      setError(''); 
      setNewLogo(file);
    }
  };

  const handleSaveImage = () => {
    
    if (newLogo) {
        handleLogoChange(newLogo);
        handleClose();
      }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',}}
    >
      <Paper >
        <TeamLogo src={newLogo ? URL.createObjectURL(newLogo) : teamLogo}  alt="Team Logo" />
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
        />
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <Button 
          variant="contained" 
          onClick={handleSaveImage} 
          disabled={!newLogo} 
          style={{marginTop:"20px", marginLeft:"104px", width: '113px',backgroundColor:'#1976D2', color: 'white'}}
        >
          <span>Upload</span>
        </Button>
      </Paper>
    </Modal>
  );
};

export default TeamLogoModal;