import React from "react";
import { Button, Box, Grid } from "@mui/material";

import { VideoCard } from "./VideoCard";
import { VideoCard4Sides } from "./VideoCard4Sides";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { CheckCircle } from "@mui/icons-material";
import ResponsiveDrawer from "./Header";
import { CopyRight } from "./CopyRight";
import TopHeaderScreen from "./TopHeader1";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { VideoDetailsTableComponent } from "../components/Table";

const VideoDetails = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [campregisterlist, setCampRegisterList] = useState([]);
  const [campWithFilesList, setCampWithFilesList] = useState([]);



  const getCampRegistrationList = async () => {
    try {
      // const res = await axios.get(
      //   `/camp/video/${selectedCategory}/${localStorage.getItem("userid")}`
      // );
      const res = await axios.get(
        `/list/files/campaign/${location?.pathname.split("/")?.[2]}/${
          location?.pathname.split("/")?.[3]
        }/${location?.pathname.split("/")?.[4]}`
      );
      setCampRegisterList(res?.data);
      // JSON.stringify(res)
     
      return res;
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    getCampRegistrationList();
  }, [location?.pathname.split("/")?.[2]]);


  const getCampFileList = async () => {
    try {
      // const res = await axios.get(
      //   `/camp/video/${selectedCategory}/${localStorage.getItem("userid")}`
      // );
      const res = await axios.get(
        `/list/files/campaignwithid/${
          location?.pathname.split("/")?.[3]
        }/${localStorage.getItem("userid")}`
      );
      setCampWithFilesList(res?.data);
      // JSON.stringify(res)
    
      return res;
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    getCampFileList();
  }, []);


  const navigate = useNavigate();
  const locationPath = useLocation();

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
 

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <Grid container>
              {/* <Grid item xs={12} sm={8} md={6} lg={3} xl={3}> */}
              {/* <Grid item mx={5} my={5}> */}
              <Grid item mx={2} my={2} className="playerWrapper">
                <Box>
                  <ReactPlayer
                    url={campregisterlist?.fileurl}
                    className="react-player"
                    controls
                  />
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  <VideoDetailsTableComponent rows={campregisterlist} />
                </Box>
              </Grid>
              <Box
                sx={{
                  height: { sx: "auto", md: "92vh" },
                  borderRight: "1px solid #FFDEAD",
                  px: { sx: 0, md: 2 },
                }}
              ></Box>
              <Grid item mx={1} my={1} overflowY="auto">
                <Box sx={{ textAlign: "center" }}>
                  <VideoCard4Sides video={campWithFilesList} />
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
    </div>
  );
};

export default VideoDetails;
