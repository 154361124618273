import React, {useEffect, useRef} from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {
  addCampRewardsReset,
  addCampRewards,
} from "../../redux/action/addCampRewardsAction";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import axios from "axios";
import ErrorMessage from "../../components/ErrorMessage";
import _ from "lodash";
import RedStar from "../../components/RedStar";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link, useNavigate } from "react-router-dom";
import { getRewardTitle } from "../../redux/action/RewardDetailAction";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import AsyncSelect from "react-select/async";
import { isArray } from "lodash";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import Modal from "react-bootstrap/Modal";
import Buttons from "react-bootstrap/Button";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import styled from 'styled-components';

const HomeScreenMainContent = styled(Box)(({ theme }) => ({
  padding: '80px 0 100px 0px',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    padding: '80px 0 100px 330px',
  },
})); 

const FormGroup0 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#F0F8FF',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// Styled component for formGroup00
const FormGroup00 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#FAEBD7',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

const FormGroup = styled('div')(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: '40px',
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));


const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup1
const FormGroup1 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup2
const FormGroup2 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  flexDirection: 'column',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '75px 0 0 16px',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup3
const FormGroup3 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  width: '20%',
  '@media (max-width: 1200px)': {
    width: '20%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup4
const FormGroup4 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'right',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 0',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup7
const FormGroup7 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup8
const FormGroup8 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledLabel
const StyledLabel = styled('label')(({ theme }) => ({
  display: 'flex',
  background: '#e9e7e7',
  padding: '10px',
  //  ,
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontWeight: 600,
  marginTop: '16px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

// StyledSpan
const StyledSpan = styled('span')(() => ({
  fontSize: '12px',
}));

// FocusThematic
const FocusThematic = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledInput
const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// StyledTextarea
const StyledTextarea = styled('textarea')(() => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// LogoButton
const LogoButton = styled('input')(() => ({
  width: '8%',
  border: 'none',
  padding: '10px',
  display: 'flex',
  height: '40px',
  color: 'white',
  '@media (max-width: 1920px)': {
    fontSize: '15px',
    width: '12%',
  },
  '@media (max-width: 1280px)': {
    fontSize: '15px',
    width: '15%',
  },
  '@media (max-width: 960px)': {
    fontSize: '15px',
    width: '20%',
  },
  '@media (max-width: 600px)': {
    fontSize: '15px',
    width: '30%',
  },
}));

// StyledTypography
const StyledTypography = styled(Typography)(({ theme }) => ({
  '& .css-u4tvz2-MuiFormLabel-root': {
    fontFamily: 'Poppins',
  },
  display: 'block',
  display: 'flex',
  fontSize: '16px',
  marginTop: '16px',
  fontWeight: 500,
  lineHeight: '18px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

const LoginPgLogo = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (max-width: 768px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
}));

const StyledLargeAvatar = styled(Avatar)(({ theme }) => ({
  width: '200px !important',
  height: '200px !important',
  margin: '0 auto',
  marginTop: '5px',
  marginBottom: '5px',
  '@media (max-width: 400px)': {
    width: '120px !important',
    height: '120px !important',
  },
  '@media (max-width: 600px)': {
    width: '140px !important',
    height: '140px !important',
  },
  '@media (max-width: 768px)': {
    width: '150px !important',
    height: '150px !important',
  },
}));


function AdminRewards(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rewardtitle, setRewardtitle] = React.useState([
    {
      rewardchoice: "",
      rewardtype: "",
      title: "",
      subtitle: "",
      availablebudget: "",
      allocatedbudget: "",
      points: "",
      pf: "",
      auto: "",
      camp: "",
      count: "",
      titleList: "",
      logo: "",
    },
  ]);
  const [sponsor, setSponsor] = React.useState([]);
  const [rewardTypeList, setRewardTypeList] = React.useState(null);
  const [rewardDetailResponseEffect, setrewardDetailResponseEffect] = React.useState({});
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const [displayConfirmationModal, setDisplayConfirmationModal] = React.useState(false);
  const [addRewardsValuesError, setaddRewardsValuesError] = React.useState({
    rewardtypeError: "",
    titleError: "",
    subtitleError: "",
    sponsorError: "",
    allocatedbudgetError: "",
    pointsError: "",
    pfError: "",
    campError: "",
    autoError: "",
    countError: "",
  });
  const [campName, setCampName] = React.useState([]);
  const [selectedcampname, setselectedcampname] = React.useState([]);
  const topRef = useRef(null);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
    navigate("/marketplace");
  };

  const {
    titleSuccess: rewardDetailSuccess,
    titleResponse: rewardDetailResponse,
  } = useSelector((state) => state.getRewardTitle);

  const {
    addcamprewardsSuccess: isAddRewardsSuccess,
    addcamprewardsResponse: addRewardsResponse,
    addcamprewardsError: isAddRewardsError,
    addcamprewardsFetching: isAddrewardsFetching,
  } = useSelector((state) => state.addCampRewards);

  const handleChangeRewards1 = (id, event, field, index) => {
    const value = [...rewardtitle];
    if (field === "rewardtype") {
      value[index]["titleList"] = rewardDetailResponseEffect;
    }
    if (field === "sponsor") {
      value[index][field] = event;
    } else if (field === "camp") {
      value[index][field] = event[0]["label"];
    } else {
      value[index][field] = event.target.value;
    }
    setRewardtitle(value);
  };

  const handleAddRewards = () => {
    if (addRewardsValuesError.rewardtypeError || addRewardsValuesError.titleError ||
      addRewardsValuesError.subtitleError || addRewardsValuesError.allocatedbudgetError) {
      window.scrollTo(0, 0);

    } else if (addRewardsValuesError.rewardtypeError === undefined || addRewardsValuesError.titleError === undefined ||
      addRewardsValuesError.subtitleError === undefined || addRewardsValuesError.allocatedbudgetError === undefined) {
      window.scrollTo(0, 0);
    }
    else {

      let formData = new FormData();
      formData.append("rewardtitle", JSON.stringify(rewardtitle));
      dispatch(addCampRewards({ userid: localStorage.getItem("userid"), formData }));
      setDisplayConfirmationModal(true);
      dispatch(addCampRewardsReset());
      setRewardtitle([
        {
          rewardtype: "",
          title: "",
          subtitle: "",
          availablebudget: "",
          allocatedbudget: "",
          points: "",
          pf: "",
          auto: "",
          camp: "",
          count: "",
          logo: "",
        },
      ]);
      setselectedcampname("");
    }
  };

  const getRewardDetailList = async (i, valll) => {
    try {
      const res = await axios
        .get(`/getrewardtitle/${valll}`)
        .then((response) => {
          const values = [...rewardtitle];
          values[i]["titleList"] = response?.data;
          return response?.data;
        });
    } catch (err) {
      throw err;
    }
  };

  const getSponsorList = async () => {
    try {
      const res = await axios.get(`/sponsor`).then((response) => {
        setSponsor(response?.data);
        return response?.data;
      });
    } catch (err) {
      throw err;
    }
  };

  const getRewardTypeList = async () => {
    try {
      const res = await axios.get(`getrewardtypes`);
      setRewardTypeList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getCampAvailableBudget = async (i, campname) => {
    try {
      const res = await axios
        .get(`/camp/availbudget/${campname}`)
        .then((response) => {
          const values = [...rewardtitle];
          values[i]["camp"] = response?.data?.[0]?.title;
          values[i]["availablebudget"] = response?.data?.[0]?.totalbudget;
          setRewardtitle(values);
          return response?.data;
        });
    } catch (err) {
      throw err;
    }
  };

  const getCountForPoints = async (i, allocatedbudget) => {
    try {
      const res = await axios.get( `/count/forpoints/${selectedcampname[0]["label"]}/${allocatedbudget}`)
        .then((response) => {
          const values = [...rewardtitle];
          values[i]["points"] = response?.data?.[0]?.points;
          values[i]["count"] = response?.data?.[0]?.count;
          setRewardtitle(values);
          return response?.data;
        });
    } catch (err) {
      throw err;
    }
  };

  const loadOptions = (searchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = campName.filter((category) =>
        category.label.toLowerCase().includes(searchValue.toLowerCase())
      );
      callback(filteredOptions);
    });
  };

  React.useEffect(() => {
    getCampName();
  }, []);

  React.useEffect(() => {
    setaddRewardsValuesError([
      {
        rewardtypeError: false,
        titleError: false,
        subtitleError: false,
        allocatedbudgetError: false,
        pointsError: false,
      },
    ]);

  }, []);


  const getCampName = async () => {
    try {
      const res = await axios.get(`/camp/name/${profileResponse?.phoneno}`);
      setCampName(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const colorStyles = {
    control: (styles) => ({ ...styles }),
    multiValue: (styles, { data }) => {
      return { ...styles, backgroundColor: "#0096FF", color: "#fff" };
    },
    multiValueLabel: (styles, { data }) => {
      return { ...styles, color: "#fff" };
    },
    multiValueRemove: (styles, { data }) => {
      return {
        ...styles,
        color: "#fff",
        cursor: "pointer",
        ":hover": {
          color: "#fff",
        },
      };
    },
  };

  const getRewardDetails = (itemreward, index) => {
    return (
      <div style={{ float: "left", width: "98%", marginTop: 50 }}>
        <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>
          Campaign Reward Details
        </span>{" "}
        <form
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            float: "left",
          }}
        >
           <FormGroup1>
            <StyledLabel htmlFor="create-yaad--title"  >
              Campaign
              <RedStar />
             </StyledLabel>
            <AsyncSelect
              loadOptions={loadOptions}
              defaultOptions={campName}
              value={selectedcampname}
              isMulti
              placeholder="Select Campaign"
              isOptionDisabled={() => selectedcampname.length >= 1}
              styles={colorStyles}
              onChange={(e) => {
                setselectedcampname(e);
                handleChangeRewards1(itemreward, e, "camp", index);
                getCampAvailableBudget(index, itemreward.camp);
              }}
            />
          </FormGroup1>
           <FormGroup1>
            <StyledLabel
            >
              Available Budget
             </StyledLabel>

            <StyledInput
              disabled
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                border: "1px solid",
              }}
              value={itemreward?.availablebudget}
              type="number"
              id="create-yaad--title"
              
              placeholder="Budget"
            />
          </FormGroup1>

           <FormGroup1>
            <StyledLabel
              htmlFor="create-yaad--title"
               
            >
              Budget allocated
              <RedStar />
             </StyledLabel>
            <StyledInput
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                border: "1px solid",
              }}
              value={itemreward?.allocatedbudget}
              type="number"
              id="create-yaad--title"
              placeholder="Budget"
              onBlur={() => {
                if (!itemreward.allocatedbudget) {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    allocatedbudgetError: true,
                  });
                } else {
                  if (Number(itemreward?.allocatedbudget) > rewardtitle?.[index]?.availablebudget) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      allocatedbudgetError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      allocatedbudgetError: false,
                    });
                  }
                }
              }}
              onChange={(e) => {
                handleChangeRewards1(itemreward, e, "allocatedbudget", index);
                if (itemreward.pf === "" || itemreward.pf === "No") {
                  getCountForPoints(index, itemreward.allocatedbudget);
                }
              }}
            />
          </FormGroup1>

           <FormGroup1>
            <StyledLabel htmlFor="create-yaad--title"  >
              Points
              <RedStar />
             </StyledLabel>
            <StyledInput
              disabled
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                border: "1px solid",
              }}
              value={itemreward?.points}
              type="number"
              id="create-yaad--title"
              placeholder="Points"
            />
          </FormGroup1>
           <FormGroup1>
            <StyledLabel htmlFor="create-yaad--title"  >
              Count <RedStar />
             </StyledLabel>
            <StyledInput
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                border: "1px solid"
              }}
              value={itemreward?.count}
              type="number"
              id="create-yaad--title"
              placeholder="Count"
              onBlur={() => {
                if (!itemreward.count) {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    countError: true,
                  });
                } else {
                  if (!Number(itemreward.count)) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      countError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      countError: false,
                    });
                  }
                }
              }}
              onChange={(e) =>
                handleChangeRewards1(itemreward, e, "count", index)
              }
            />
          </FormGroup1>
           <FormGroup1>
            <StyledLabel htmlFor="create-yaad--title"  >
              Reward Type <RedStar />
             </StyledLabel>
            <Select
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
              value={itemreward.rewardtype}
              onBlur={() => {
                if (!itemreward.rewardtype) {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    rewardtypeError: true,
                  });
                } else {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    rewardtypeError: false,
                  });
                }
              }}
              onChange={(e) => {
                handleChangeRewards1(itemreward, e, "rewardtype", index);
                getRewardDetailList(index, itemreward.rewardtype);
                dispatch(getRewardTitle(itemreward.rewardtype));
              }}
            >
              {rewardTypeList &&
                rewardTypeList.map((item, index) => (
                  <MenuItem style={{ fontSize: 16 }} value={item.rewards}>
                    {item.rewards}
                  </MenuItem>
                ))}
            </Select>
          </FormGroup1>
          {(isArray(rewardtitle) && index !== rewardtitle.length - 1) ||
            (index === rewardtitle.length - 1 &&
              rewardtitle[index].title !== "") ||
            index === 0 ? (
             <FormGroup1>
              <StyledLabel htmlFor="create-yaad--title"  >
                Reward Title <RedStar />
               </StyledLabel>
              <Select
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
                value={itemreward.title}
                onBlur={() => {
                  if (!itemreward.title) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      titleError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      titleError: false,
                    });
                  }
                }}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "title", index);
                }}
              >
                {rewardDetailSuccess &&
                  isArray(rewardtitle[index]?.titleList) &&
                  rewardtitle[index]?.titleList.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.title}>
                      {item.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormGroup1>
          ) : (
             <FormGroup1>
              <StyledLabel htmlFor="create-yaad--title"  >
                Reward Title <RedStar />
               </StyledLabel>
              <Select
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
                value={itemreward.title}
                onBlur={() => {
                  if (!itemreward.title) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      titleError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      titleError: false,
                    });
                  }
                }}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "title", index);
                }}
              >
                {rewardDetailSuccess &&
                  rewardDetailResponse.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.title}>
                      {item.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormGroup1>
          )}

          {(isArray(rewardtitle) && index !== rewardtitle.length - 1) ||
            (index === rewardtitle.length - 1 &&
              rewardtitle[index].subtitle !== "") ||
            index === 0 ? (
             <FormGroup1>
              <StyledLabel htmlFor="create-yaad--title"  >
                Reward Sub Title <RedStar />
               </StyledLabel>
              <Select
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "subtitle", index);
                }}
                onBlur={() => {
                  if (!itemreward.subtitle) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      subtitleError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      subtitleError: false,
                    });
                  }
                }}
              >
                {rewardDetailSuccess &&
                  isArray(rewardtitle[index]?.titleList) &&
                  rewardtitle[index]?.titleList.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.subtitle}>
                      {item.subtitle}
                    </MenuItem>
                  ))}
              </Select>
            </FormGroup1>
          ) : (
             <FormGroup1>
              <StyledLabel htmlFor="create-yaad--title"  >
                Reward Sub Title <RedStar />
               </StyledLabel>
              <Select
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "subtitle", index);
                }}
                onBlur={() => {
                  if (!itemreward.subtitle) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      subtitleError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      subtitleError: false,
                    });
                  }
                }}
              >
                {rewardDetailSuccess &&
                  rewardDetailResponse.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.subtitle}>
                      {item.subtitle}
                    </MenuItem>
                  ))}
              </Select>
            </FormGroup1>
          )}
        </form>
      </div>
    );
  };

  React.useEffect(() => {
    setRewardtitle([
      {
        rewardtype: "",
        title: "",
        subtitle: "",
        availablebudget: "",
        allocatedbudget: "",
        points: "",
        pf: "",
        auto: "",
        camp: "",
        count: "",
        titleList: "",
        logo: "",
      },
    ]);
  }, []);

  React.useEffect(() => {
    getRewardTypeList();
  }, []);

  React.useEffect(() => {
    getSponsorList();
  }, []);

  const [showPreviewModal, setShowPreviewModal] = React.useState(false);

  const hidePreviewModal = () => {
    setShowPreviewModal(false);
  };

  return (
    <div className="wrapper" ref={topRef}>
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg" style={{ marginLeft: '20px' }}>
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Add Campaign Reward
            </div>
            <div style={{ fontFamily: "monospace", fontSize: 16 }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                  marginRight: 5,
                  marginBottom: 20,
                }}
              >
                <marquee behavior="scroll" direction="left" style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px', color: 'red' }}>
                  Please do not leave this page until you submit as you may risk
                  losing the partially filled in form{" "}
                </marquee>
              </div>
            </div>
            <form autoComplete="off">
              <div
                style={{
                  border: "1px solid #dadce0",
                  borderRadius: 8,
                  backgroundColor: "#fff",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <FormGroupFull>
                    {rewardtitle.map((itemreward, index) => (
                      <div key={index}>
                        {getRewardDetails(itemreward, index)}
                      </div>
                    ))}
                  </FormGroupFull>
                </div>
                <div
                  className="centerAlign"
                  style={{ padding: "16px 0", marginBottom: 50 }}
                >
                  <Button
                    variant="contained"
                    onClick={handleAddRewards}
                    className="btn save-btn"
                    disableElevation
                    disabled={isAddrewardsFetching}
                  >
                    {isAddrewardsFetching ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      <span>Submit</span>
                    )}
                  </Button>
                  {
                    addRewardsValuesError?.rewardtypeError || addRewardsValuesError?.titleError ||
                      addRewardsValuesError?.subtitleError || addRewardsValuesError?.rewardtypeError === undefined ||
                      addRewardsValuesError?.titleError === undefined || addRewardsValuesError?.subtitleError === undefined
                      ? (
                        <ErrorMessage message="Please ensure that you fill all the details" />
                      ) : ""
                    }
                    {addRewardsValuesError?.allocatedbudgetError && (
                      <ErrorMessage message="You have exceeded the budget to be allocated" />
                    )
                  }
                  <br />
                </div>
              </div>
            </form>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
        <ConfirmationDialog
          showModal={displayConfirmationModal}
          hideModal={hideConfirmationModal}
          message={
            addRewardsResponse?.message +
            ". You will be redirected to Marketplace"
          }
        />
      </section>
      <Modal show={showPreviewModal} onHide={hidePreviewModal}>
        <div class="modal-header">
          <h5 class="modal-title">Preview Reward</h5>
          <buttons
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={hidePreviewModal}
          >
            <span aria-hidden="true">&times;</span>
          </buttons>
        </div>
        <Modal.Body>
          <div
            class="marketplaceinrow"
            style={{ marginBottom: "30px", float: "left" }}
          >
            <div class="reward-wrap-marketplace">
              <div style={{ maxHeight: "400px", position: "relative" }}>
                <div>
                  <img
                    class="reward-image"
                    src={"image"}
                    style={{ width: "100%", opacity: 0.4 }}
                  />
                </div>
              </div>
              <div class="reward-details">
                <div>
                  <div>
                    <div id="toggleAccordion">
                      <div class="card mb-1">
                        <div
                          class="card-header"
                          id="headingOne4"
                          style={{
                            borderBottom: "0px",
                            backgroundColor: "white",
                          }}
                        >
                          <h5
                            class="mb-0 mt-0 reward-desc-title"
                            title={rewardtitle?.[0]?.camp}
                          >
                            <span
                              role="menu"
                              class=""
                              data-toggle="collapse"
                              aria-expanded="true"
                              aria-controls="iconChangeAccordionOne"
                            >
                              {rewardtitle?.[0]?.rewardtype}
                              <i
                                style={{
                                  marginLeft: "2px",
                                }}
                                class="flaticon-down-arrow float-right"
                              ></i>
                            </span>
                          </h5>
                        </div>
                        <div
                          id={0}
                          class="collapse"
                          aria-labelledby="headingOne4"
                          data-parent="#toggleAccordion"
                        >
                          <div class="card-body">
                            <p class="mb-3">
                              <div >
                                <span
                                  style={{
                                    fontSize: 12,
                                    color: "blue",
                                  }}
                                >
                                  {" "}
                                  {rewardtitle?.[0]?.subtitle}{" "}
                                </span>
                              </div>
                            </p>
                            <p class="mb-3">
                              <div >
                                <span
                                  style={{
                                    fontSize: 12,
                                    color: "green",
                                  }}
                                >
                                  {" "}
                                  {rewardtitle?.[0]?.title}{" "}
                                </span>
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="redeem-pts">
                    <div class="row">
                    </div>
                  </div>
                  <div class="redeem-pts1">
                    <div class="row">
                      <div class="col-12">
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <h6 class="sponsor-title">Sponsors</h6>
                    <div class="row sponserWrapper">
                      <div class="">
                        <CheckBoxOutlineBlankIcon style={{
                          width: "auto",
                          height: "100px",
                        }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Buttons variant="default" onClick={hidePreviewModal}>
            Ok
          </Buttons>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default AdminRewards;
