/** @format */

const ONBOARD_INDIVIDUAL_FETCHING_DATA = "ONBOARD_INDIVIDUAL_FETCHING_DATA";
const ONBOARD_INDIVIDUAL_FETCHING_DATA_FAILURE =
  "ONBOARD_INDIVIDUAL_FETCHING_DATA_FAILURE";
const ONBOARD_INDIVIDUAL_FETCHING_DATA_RESET =
  "ONBOARD_INDIVIDUAL_FETCHING_DATA_RESET";
const ONBOARD_INDIVIDUAL_FETCHING_DATA_SUCCESS =
  "ONBOARD_INDIVIDUAL_FETCHING_DATA_SUCCESS";
const ONBOARD_INDIVIDUAL_FETCHING_DATA_ATTEMPT =
  "ONBOARD_INDIVIDUAL_FETCHING_DATA_ATTEMPT";
const onboardIndividual = (payload) => ({
  type: ONBOARD_INDIVIDUAL_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const onboardIndividualSuccess = () => ({
  type: ONBOARD_INDIVIDUAL_FETCHING_DATA_SUCCESS,
});
const onboardIndividualReset = () => ({
  type: ONBOARD_INDIVIDUAL_FETCHING_DATA_RESET,
});

export {
  ONBOARD_INDIVIDUAL_FETCHING_DATA,
  ONBOARD_INDIVIDUAL_FETCHING_DATA_FAILURE,
  ONBOARD_INDIVIDUAL_FETCHING_DATA_RESET,
  ONBOARD_INDIVIDUAL_FETCHING_DATA_SUCCESS,
  ONBOARD_INDIVIDUAL_FETCHING_DATA_ATTEMPT,
  onboardIndividual,
  onboardIndividualSuccess,
  onboardIndividualReset,
};

//bulk

const ONBOARD_BULK_FETCHING_DATA = "ONBOARD_BULK_FETCHING_DATA";
const ONBOARD_BULK_FETCHING_DATA_FAILURE = "ONBOARD_BULK_FETCHING_DATA_FAILURE";
const ONBOARD_BULK_FETCHING_DATA_RESET = "ONBOARD_BULK_FETCHING_DATA_RESET";
const ONBOARD_BULK_FETCHING_DATA_SUCCESS = "ONBOARD_BULK_FETCHING_DATA_SUCCESS";
const ONBOARD_BULK_FETCHING_DATA_ATTEMPT = "ONBOARD_BULK_FETCHING_DATA_ATTEMPT";
const onboardBulk = (payload) => ({
  type: ONBOARD_BULK_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const onboardBulkSuccess = () => ({
  type: ONBOARD_BULK_FETCHING_DATA_SUCCESS,
});
const onboardBulkFailure = () => ({
  type: ONBOARD_BULK_FETCHING_DATA_FAILURE,
});
const onboardBulkReset = () => ({
  type: ONBOARD_BULK_FETCHING_DATA_RESET,
});

export {
  ONBOARD_BULK_FETCHING_DATA,
  ONBOARD_BULK_FETCHING_DATA_FAILURE,
  ONBOARD_BULK_FETCHING_DATA_RESET,
  ONBOARD_BULK_FETCHING_DATA_SUCCESS,
  ONBOARD_BULK_FETCHING_DATA_ATTEMPT,
  onboardBulk,
  onboardBulkSuccess,
  onboardBulkFailure,
  onboardBulkReset,
};


//self

const ONBOARD_SELF_FETCHING_DATA = "ONBOARD_SELF_FETCHING_DATA";
const ONBOARD_SELF_FETCHING_DATA_FAILURE = "ONBOARD_SELF_FETCHING_DATA_FAILURE";
const ONBOARD_SELF_FETCHING_DATA_RESET = "ONBOARD_SELF_FETCHING_DATA_RESET";
const ONBOARD_SELF_FETCHING_DATA_SUCCESS = "ONBOARD_SELF_FETCHING_DATA_SUCCESS";
const ONBOARD_SELF_FETCHING_DATA_ATTEMPT = "ONBOARD_SELF_FETCHING_DATA_ATTEMPT";
const onboardSelf = (payload) => ({
  type: ONBOARD_SELF_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const onboardSelfSuccess = () => ({
  type: ONBOARD_SELF_FETCHING_DATA_SUCCESS,
});
const onboardSelfFailure = () => ({
  type: ONBOARD_SELF_FETCHING_DATA_FAILURE,
});
const onboardSelfReset = () => ({
  type: ONBOARD_SELF_FETCHING_DATA_RESET,
});

export {
  ONBOARD_SELF_FETCHING_DATA,
  ONBOARD_SELF_FETCHING_DATA_FAILURE,
  ONBOARD_SELF_FETCHING_DATA_RESET,
  ONBOARD_SELF_FETCHING_DATA_SUCCESS,
  ONBOARD_SELF_FETCHING_DATA_ATTEMPT,
  onboardSelf,
  onboardSelfSuccess,
  onboardSelfFailure,
  onboardSelfReset,
};

//activity

const ONBOARD_ACTIVITY_FETCHING_DATA = "ONBOARD_ACTIVITY_FETCHING_DATA";
const ONBOARD_ACTIVITY_FETCHING_DATA_FAILURE =
  "ONBOARD_ACTIVITY_FETCHING_DATA_FAILURE";
const ONBOARD_ACTIVITY_FETCHING_DATA_RESET =
  "ONBOARD_ACTIVITY_FETCHING_DATA_RESET";
const ONBOARD_ACTIVITY_FETCHING_DATA_SUCCESS =
  "ONBOARD_ACTIVITY_FETCHING_DATA_SUCCESS";
const ONBOARD_ACTIVITY_FETCHING_DATA_ATTEMPT =
  "ONBOARD_ACTIVITY_FETCHING_DATA_ATTEMPT";
const onboardActivity = (payload) => ({
  type: ONBOARD_ACTIVITY_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const onboardActivitySuccess = () => ({
  type: ONBOARD_ACTIVITY_FETCHING_DATA_SUCCESS,
});
const onboardActivityReset = () => ({
  type: ONBOARD_ACTIVITY_FETCHING_DATA_RESET,
});

export {
  ONBOARD_ACTIVITY_FETCHING_DATA,
  ONBOARD_ACTIVITY_FETCHING_DATA_FAILURE,
  ONBOARD_ACTIVITY_FETCHING_DATA_RESET,
  ONBOARD_ACTIVITY_FETCHING_DATA_SUCCESS,
  ONBOARD_ACTIVITY_FETCHING_DATA_ATTEMPT,
  onboardActivity,
  onboardActivitySuccess,
  onboardActivityReset,
};

//campaign

const ONBOARD_CAMPAIGN_FETCHING_DATA = "ONBOARD_CAMPAIGN_FETCHING_DATA";
const ONBOARD_CAMPAIGN_FETCHING_DATA_FAILURE =
  "ONBOARD_CAMPAIGN_FETCHING_DATA_FAILURE";
const ONBOARD_CAMPAIGN_FETCHING_DATA_RESET =
  "ONBOARD_CAMPAIGN_FETCHING_DATA_RESET";
const ONBOARD_CAMPAIGN_FETCHING_DATA_SUCCESS =
  "ONBOARD_CAMPAIGN_FETCHING_DATA_SUCCESS";
const ONBOARD_CAMPAIGN_FETCHING_DATA_ATTEMPT =
  "ONBOARD_CAMPAIGN_FETCHING_DATA_ATTEMPT";
const onboardCampaign = (payload) => ({
  type: ONBOARD_CAMPAIGN_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const onboardCampaignSuccess = () => ({
  type: ONBOARD_CAMPAIGN_FETCHING_DATA_SUCCESS,
});
const onboardCampaignReset = () => ({
  type: ONBOARD_CAMPAIGN_FETCHING_DATA_RESET,
});

export {
  ONBOARD_CAMPAIGN_FETCHING_DATA,
  ONBOARD_CAMPAIGN_FETCHING_DATA_FAILURE,
  ONBOARD_CAMPAIGN_FETCHING_DATA_RESET,
  ONBOARD_CAMPAIGN_FETCHING_DATA_SUCCESS,
  ONBOARD_CAMPAIGN_FETCHING_DATA_ATTEMPT,
  onboardCampaign,
  onboardCampaignSuccess,
  onboardCampaignReset,
};

//organization

const ONBOARD_ORGANIZATION_FETCHING_DATA = "ONBOARD_ORGANIZATION_FETCHING_DATA";
const ONBOARD_ORGANIZATION_FETCHING_DATA_FAILURE =
  "ONBOARD_ORGANIZATION_FETCHING_DATA_FAILURE";
const ONBOARD_ORGANIZATION_FETCHING_DATA_RESET =
  "ONBOARD_ORGANIZATION_FETCHING_DATA_RESET";
const ONBOARD_ORGANIZATION_FETCHING_DATA_SUCCESS =
  "ONBOARD_ORGANIZATION_FETCHING_DATA_SUCCESS";
const ONBOARD_ORGANIZATION_FETCHING_DATA_ATTEMPT =
  "ONBOARD_ORGANIZATION_FETCHING_DATA_ATTEMPT";
const onboardOrganization = (payload) => ({
  type: ONBOARD_ORGANIZATION_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const onboardOrganizationSuccess = () => ({
  type: ONBOARD_ORGANIZATION_FETCHING_DATA_SUCCESS,
});
const onboardOrganizationReset = () => ({
  type: ONBOARD_ORGANIZATION_FETCHING_DATA_RESET,
});

export {
  ONBOARD_ORGANIZATION_FETCHING_DATA,
  ONBOARD_ORGANIZATION_FETCHING_DATA_FAILURE,
  ONBOARD_ORGANIZATION_FETCHING_DATA_RESET,
  ONBOARD_ORGANIZATION_FETCHING_DATA_SUCCESS,
  ONBOARD_ORGANIZATION_FETCHING_DATA_ATTEMPT,
  onboardOrganization,
  onboardOrganizationSuccess,
  onboardOrganizationReset,
};


//sponsor

const ONBOARD_SPONSOR_FETCHING_DATA = "ONBOARD_SPONSOR_FETCHING_DATA";
const ONBOARD_SPONSOR_FETCHING_DATA_FAILURE = "ONBOARD_SPONSOR_FETCHING_DATA_FAILURE";
const ONBOARD_SPONSOR_FETCHING_DATA_RESET = "ONBOARD_SPONSOR_FETCHING_DATA_RESET";
const ONBOARD_SPONSOR_FETCHING_DATA_SUCCESS = "ONBOARD_SPONSOR_FETCHING_DATA_SUCCESS";
const ONBOARD_SPONSOR_FETCHING_DATA_ATTEMPT = "ONBOARD_SPONSOR_FETCHING_DATA_ATTEMPT";
const onboardSponsor = (payload) => ({
  type: ONBOARD_SPONSOR_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const onboardSponsorSuccess = () => ({
  type: ONBOARD_SPONSOR_FETCHING_DATA_SUCCESS,
});
const onboardSponsorReset = () => ({
  type: ONBOARD_SPONSOR_FETCHING_DATA_RESET,
});

export {
  ONBOARD_SPONSOR_FETCHING_DATA,
  ONBOARD_SPONSOR_FETCHING_DATA_FAILURE,
  ONBOARD_SPONSOR_FETCHING_DATA_RESET,
  ONBOARD_SPONSOR_FETCHING_DATA_SUCCESS,
  ONBOARD_SPONSOR_FETCHING_DATA_ATTEMPT,
  onboardSponsor,
  onboardSponsorSuccess,
  onboardSponsorReset,
};



//Form

const ONBOARD_REGFORM_FETCHING_DATA = "ONBOARD_REGFORM_FETCHING_DATA";
const ONBOARD_REGFORM_FETCHING_DATA_FAILURE = "ONBOARD_REGFORM_FETCHING_DATA_FAILURE";
const ONBOARD_REGFORM_FETCHING_DATA_RESET = "ONBOARD_REGFORM_FETCHING_DATA_RESET";
const ONBOARD_REGFORM_FETCHING_DATA_SUCCESS = "ONBOARD_REGFORM_FETCHING_DATA_SUCCESS";
const ONBOARD_REGFORM_FETCHING_DATA_ATTEMPT = "ONBOARD_REGFORM_FETCHING_DATA_ATTEMPT";
const onboardRegForm = (payload) => ({
  type: ONBOARD_REGFORM_FETCHING_DATA_ATTEMPT,
  payload: payload,
});
const onboardRegFormSuccess = () => ({
  type: ONBOARD_REGFORM_FETCHING_DATA_SUCCESS,
});
const onboardRegFormReset = () => ({
  type: ONBOARD_REGFORM_FETCHING_DATA_RESET,
});

export {
  ONBOARD_REGFORM_FETCHING_DATA,
  ONBOARD_REGFORM_FETCHING_DATA_FAILURE,
  ONBOARD_REGFORM_FETCHING_DATA_RESET,
  ONBOARD_REGFORM_FETCHING_DATA_SUCCESS,
  ONBOARD_REGFORM_FETCHING_DATA_ATTEMPT,
  onboardRegForm,
  onboardRegFormSuccess,
  onboardRegFormReset,
};