// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBOENeeZH0WC9-8-_86aO4P6MWX-t4ULUI",
  authDomain: "cyaag-2fcf2.firebaseapp.com",
  databaseURL: "https://cyaag-2fcf2.firebaseio.com",
  projectId: "cyaag-2fcf2",
  storageBucket: "cyaag-2fcf2.appspot.com",
  messagingSenderId: "393825780552",
  appId: "1:393825780552:web:bb2c3dae199eba027d297d",
  measurementId: "G-K1DBGBN0GC",
};

// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);

export { firebaseConfig };
