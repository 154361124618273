import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";
import { status } from "../../utils/dict";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeaderScreen from "../../components/TopHeader1";
import Multiselect from "multiselect-react-dropdown";
import _ from "lodash";
import ErrorMessage from "../../components/ErrorMessage";
import {
  AddCircleOutlineOutlined,
  RemoveCircleOutlineOutlined,
} from "@mui/icons-material";
import { isArray } from "lodash";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import styled from 'styled-components';

const HomeScreenMainContent = styled(Box)(({ theme }) => ({
  padding: '80px 0 100px 0px',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    padding: '80px 0 100px 330px',
  },
}));

const FormGroup0 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#F0F8FF',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// Styled component for formGroup00
const FormGroup00 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#FAEBD7',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

const FormGroup = styled('div')(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: '40px',
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));


const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup1
const FormGroup1 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup2
const FormGroup2 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  flexDirection: 'column',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '75px 0 0 16px',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup3
const FormGroup3 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  width: '20%',
  '@media (max-width: 1200px)': {
    width: '20%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup4
const FormGroup4 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'right',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 0',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup7
const FormGroup7 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup8
const FormGroup8 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledLabel
const StyledLabel = styled('label')(({ theme }) => ({
  display: 'flex',
  background: '#e9e7e7',
  padding: '10px',
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontWeight: 600,
  marginTop: '16px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

// StyledSpan
const StyledSpan = styled('span')(() => ({
  fontSize: '16px',
}));

// FocusThematic
const FocusThematic = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledInput
const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// StyledTextarea
const StyledTextarea = styled('textarea')(() => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// LogoButton
const LogoButton = styled('input')(() => ({
  width: '8%',
  border: 'none',
  padding: '10px',
  display: 'flex',
  height: '40px',
  color: 'white',
  '@media (max-width: 1920px)': {
    fontSize: '15px',
    width: '12%',
  },
  '@media (max-width: 1280px)': {
    fontSize: '15px',
    width: '15%',
  },
  '@media (max-width: 960px)': {
    fontSize: '15px',
    width: '20%',
  },
  '@media (max-width: 600px)': {
    fontSize: '15px',
    width: '30%',
  },
}));

// StyledTypography
const StyledTypography = styled(Typography)(({ theme }) => ({
  '& .css-u4tvz2-MuiFormLabel-root': {
    fontFamily: 'Poppins',
  },
  display: 'block',
  display: 'flex',
  fontSize: '16px',
  marginTop: '16px',
  fontWeight: 500,
  lineHeight: '18px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

const LoginPgLogo = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (max-width: 768px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
}));

const StyledLargeAvatar = styled(Avatar)(({ theme }) => ({
  width: '200px !important',
  height: '200px !important',
  margin: '0 auto',
  marginTop: '5px',
  marginBottom: '5px',
  '@media (max-width: 400px)': {
    width: '120px !important',
    height: '120px !important',
  },
  '@media (max-width: 600px)': {
    width: '140px !important',
    height: '140px !important',
  },
  '@media (max-width: 768px)': {
    width: '150px !important',
    height: '150px !important',
  },
}));




function ViewCampaignScreen(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationPath = useLocation();

  const [reviewstatus, setReviewStatus] = React.useState("");
  const [reviewComments, setReviewComments] = React.useState("");
  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "" },
  ]);
  const [activityList, setActivityList] = React.useState(undefined);
  const [sponsor, setSponsor] = React.useState([]);
  const [campStatus, setCampStatus] = React.useState([]);

  const [sponsorAvailBudget, setSponsorAvailBudget] = React.useState([]);

  const [reviewstat, setreviewstat] = React.useState([]);
  const [selected1, setSelected1] = React.useState([]);
  const [dupSponsor, setDupSponsor] = React.useState(false);

  // const [countOfSponsor, setCountOfSponsor] = React.useState(0);
  const [selectedCategory, setSelectedCategory] = React.useState("cYAAG");
  const [addSponsorError, setaddSponsorError] = React.useState({
    sponsorNameError: false,
    sponsorBudgetError: false,
    sponsorBudgetExceeded: false,
    sponsorDupError: false,
  });
  const [rewardtitle, setRewardtitle] = React.useState([
    { sponsorname: "", sponsorbudget: "" },
  ]);
  // const [rewardtitle, setRewardtitle] = React.useState([]);
  const [campaignValues, setCampaignValues] = React.useState({
    titleOfCampaign: "",
    descriptionOfCampaign: "",
    typeOfCampaign: "",
    volunteerCount: "",
    //impact: "",
    visibility: "",
    startDate: new Date(),
    endDate: new Date(),
    durationOfCampaign: "",
    goal: "",
    campaignType: "",
    hourOfParticipation: "",
    socialMediaMessage: "",
    campaignLogo: "",
    campaignVideo: "",
    totalbudget: "",
    sponsor: "",
  });
  const {
    isCampaignSuccess,
    isCampaignFetching,

    isCampaignError,
  } = useSelector((state) => state.onBoard);

  const handleOnboardCampaign = async () => {
    if (!addSponsorError?.sponsorBudgetExceeded && !dupSponsor) {
      const payload = {
        campid: locationPath?.state?.campid,
        createdate: locationPath?.state?.createdate,
        reviewstatus: reviewstatus,
        reviewcomments: reviewComments,
        sponsordet: rewardtitle,
      };
      try {
        const res = await axios.put(
          `/campaign/review/${localStorage.getItem("userid")}`,
          payload
        );
        // navigate("/reviewcampaign");
        setreviewstat(res?.data);
        return res;
      } catch (err) {
        throw err;
      }
    }
  };
  // console.log(locationPath);
  // console.log(state.rewardstobeearned);

  const getSponsorList = async () => {
    try {
      const res = await axios.get(`/sponsor`).then((response) => {
        // const values = [...rewardtitle];

        setSponsor(response?.data);
        return response?.data;
      });
    } catch (err) {
      throw err;
    }
  };


  const getCampReviewstatus = async () => {
    try {
      const res = await axios.get(`/campaign/review/status/${locationPath?.state?.campid}`).then((response) => {
        setCampStatus(response?.data);
        return response?.data;
      });
    } catch (err) {
      throw err;
    }
  };

  React.useEffect(() => {
    getSponsorList();
  }, []);


  React.useEffect(() => {
    getCampReviewstatus();
  }, []);

  // const getSponsorAvailableBudget = async () => {
  //   try {
  //     const res = await axios.get(`/sponsor/available/budget/${selectedCategory}`).then((response) => {
  //       // const values = [...rewardtitle];

  //       setSponsorAvailBudget(response?.data);
  //       return response?.data;
  //     });
  //   } catch (err) {
  //     throw err;
  //   }
  // };
  // console.log(selectedCategory)
  // console.log(sponsorAvailBudget)

  React.useEffect(() => {
    if (rewardtitle.length === 1) {
      setDupSponsor(false);
    }
  }, [rewardtitle.length]);

  React.useEffect(() => {
    const value = [...rewardtitle];
    // console.log("useeffect len of rewardtitle = ", rewardtitle.length)
    if (value.length > 1) {
      for (let i = 0; i < value.length - 1; i++) {


        if (
          value.length != 0 &&
          value?.[i].sponsorname?.[0]?.sponsor ===
          value?.[value.length - 1].sponsorname?.[0]?.sponsor &&
          value?.[value.length - 1].sponsorname?.[0]?.sponsor != undefined
        ) {
          // console.log("setting dupSponsor TRUE");
          setDupSponsor(true);
          setaddSponsorError({
            ...addSponsorError,
            sponsorDupError: true,
          });
          break;
        } else {
          // console.log("setting dupSponsor FALSE");
          setDupSponsor(false);
          setaddSponsorError({
            ...addSponsorError,
            sponsorDupError: false,
          });
        }
      }
    }
  }, [rewardtitle.length]);

  const handleChangeRewards1 = (itemreward, event, field, index) => {
    // console.log(field);
    // console.log(event);

    // const value = event.target.value;
    const value = [...rewardtitle];

    if (field === "sponsorname") {
      value[index][field] = event;
      // value[index]["titleList"] = event;
      // setSelectedCategory(value[0]["sponsorname"][0]["sponsorid"])
      // setSelectedCategory(event[0]["sponsorid"])
      // setCountOfSponsor(index - 1);
      for (let i = 0; i < index; i++) {
        // console.log("inside for loop = ", i, " and index = ", index)
        // console.log("i = ", i, " value = ", value[i][field][0]["sponsor"]);
        // console.log(
        //   "index = ",
        //   index,
        //   "value = ",
        //   value[index][field][0]["sponsor"]
        // );
        if (
          index != 0 &&
          value[i][field][0]["sponsor"] === value[index][field][0]["sponsor"]
        ) {
          setDupSponsor(true);
          setaddSponsorError({
            ...addSponsorError,
            sponsorDupError: true,
          });
          break;
        } else {
          setDupSponsor(false);
          setaddSponsorError({
            ...addSponsorError,
            sponsorDupError: false,
          });
        }
      }
      if (index === 0) {
        setDupSponsor(false);
      }
      //   value[index]["titleList"] = sponsorAvailBudget;
      //   console.log("value[index][titleList] = ", value[index]["titleList"]);
    } else {
      if (field === "sponsorbudget") {
        value[index][field] = parseFloat(event.target.value);
      } else {
        value[index][field] = event.target.value;
      }
      // console.log("event.target.value = ", event.target.value);
      // console.log("value[index][field]  = ", value[index][field]);
      // value[field] = event.target.value;
    }
    setRewardtitle(value);
    // console.log(value);
    // console.log(rewardtitle);

    // setSelected([...selected, value[value?.length - 1]]);
  };


  // const handleCampaignFieldChange = (field, e) => {
  //   (isCampaignError || isCampaignSuccess) && dispatch(onboardCampaignReset());
  //   setCampaignValues({
  //     ...campaignValues,
  //     [field]:
  //       field === "startDate" || field === "endDate"
  //         ? e
  //         : field === "campaignLogo" || field === "campaignVideo"
  //         ? e.target.files[0]
  //         : e.target.value,
  //   });
  // };

  const unitOfCampaign = activityList?.filter(
    (item, index) => item?.activity === campaignValues?.typeOfCampaign
  );
  const handleChangeLocation = (id, e, field, index) => {
    const values = [...location];
    values[index][field] = e.target.value;
    setLocation(values);
  };


  const getAddress = (item, index) => {
    return (
      <div style={{ float: "left", width: "100%", marginTop: 25 }}>
        <FormGroup style={{ width: "100%" }}>
          <StyledLabel>
            {/* <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}> */}
            Operational Areas
            {/* </span> */}
          </StyledLabel>
        </FormGroup>
        <form style={{ display: "flex", flexWrap: "wrap" }}>
          <FormGroup1>
            <StyledLabel>
              Country
            </StyledLabel>

            <StyledInput
              disabled
              value={item.country}
              type="text"

            />
          </FormGroup1>

          <FormGroup1>
            <StyledLabel>
              State
            </StyledLabel>
            <StyledInput
              disabled
              value={item.state}
              type="text"

            />
          </FormGroup1>
          <FormGroup1>
            <StyledLabel>
              City/Town
            </StyledLabel>
            <StyledInput
              disabled
              value={item.city}
              type="text"

            />
          </FormGroup1>
          <FormGroup1>
            <StyledLabel>
              Area
            </StyledLabel>
            <StyledInput
              disabled
              value={item.area}
              type="text"
              id="create-yaad--title"

              // placeholder="Your Answer"
              onChange={(e) => handleChangeLocation(item, e, "area", index)}
            />
          </FormGroup1>
        </form>
      </div>
    );
  };

  React.useEffect(() => {
    if (locationPath?.state) {
      setCampaignValues({
        titleOfCampaign: locationPath?.state?.title,
        descriptionOfCampaign: locationPath?.state?.desc,
        typeOfCampaign: locationPath?.state?.type,
        volunteerCount: locationPath?.state?.targetvol,
        // impact: locationPath?.state?.impact,
        visibility: locationPath?.state.visibility,
        startDate: moment(locationPath?.state?.startdate).format("YYYY-DD-MM"),
        endDate: moment(locationPath?.state?.endDate).format("YYYY-DD-MM"),
        durationOfCampaign: locationPath?.state?.timeframe,
        goal: locationPath?.state?.goal,
        campaignType: locationPath?.state?.campaigntype,
        hourOfParticipation: locationPath?.state?.hourofparticipation,
        socialMediaMessage: locationPath?.state?.socialMediaMessage,
        campaignLogo: locationPath?.state?.logo,
        campaignVideo: locationPath?.state?.video,
        totalbudget: locationPath?.state?.totalbudget,
        sponsor: locationPath?.state?.sponsor,
      });
      setLocation(locationPath?.state?.location);
      // setFocusArea(JSON.parse(locationPath?.state?.rewardstobeearned))
    }
  }, [locationPath?.state]);


  // const [newRewardField, setNewRewardField] = React.useState(false);
  const handleRewardAddFields = () => {

    if (rewardtitle.length > 0) {

      for (let i = 0; i < rewardtitle.length; i++) {


        if (rewardtitle?.[rewardtitle.length - 1].sponsorname?.[0]?.sponsor != undefined) {
          setRewardtitle([...rewardtitle, { sponsorname: "", sponsorbudget: "" }]);
          // break;
        }
      }
      // setRewardtitle([...rewardtitle, { sponsorname: "", sponsorbudget: "" }]);
    }
  };
  const handleRemoveFields = (i) => {
    // console.log("handleRemoveFields before rewardtitle = ", rewardtitle);
    // console.log("index to be deleted = ", i);
    let newFormValues = [...rewardtitle];
    // console.log("newFormValues before = ", newFormValues);
    newFormValues.splice(i, 1);
    // console.log("newFormValues after = ", newFormValues);
    setRewardtitle(newFormValues);
    // console.log("handleRemoveFields after rewardtitle = ", rewardtitle);
  };
  const getRewardDetails = (itemreward, index) => {
    return (
      <div style={{ float: "left", width: "100%", marginTop: 25 }}>
        <form
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <FormGroup1>
            <StyledLabel
              style={{ marginTop: "0px", fontSize: 15 }}
            >
              Sponsor Name
            </StyledLabel>
            <Multiselect
              displayValue="sponsor"
              showCheckbox
              selectedValues={itemreward?.sponsor}
              options={sponsor}
              selectionLimit={1}
              onSelect={(event) => {
                handleChangeRewards1(
                  itemreward,
                  event,
                  "sponsorname",
                  index
                );
                if (_.isEmpty(event)) {
                  setaddSponsorError({
                    ...addSponsorError,
                    sponsorNameError: true,
                  });
                } else {
                  setaddSponsorError({
                    ...addSponsorError,
                    sponsorNameError: false,
                  });
                }
              }}
              value={rewardtitle?.[index]?.sponsorname?.[0]?.sponsor.length > 10 ? `${rewardtitle?.[index]?.sponsorname?.[0]?.sponsor.slice(0, 10)}...` : rewardtitle?.[index]?.sponsorname?.[0]?.sponsor}
              style={{
                multiselectContainer: {
                  border: "1px solid",
                  borderRadius: "6px",
                  padding: "5px",
                  maxHeight: '55px'
                },
                searchBox: {
                  border: "none",
                  paddingTop: 0,
                  paddingBottom: 0,
                  height: "45px",
                  maxHeight: '55px',
                  overflow: 'hidden',
                  // textOverflow: 'ellipsis'
                },
              }}
            />
            {addSponsorError?.sponsorNameError && (
              <ErrorMessage message="Please select the Sponsor for this campaign" />
            )}
            {dupSponsor && (
              <ErrorMessage message="Please select another Sponsor for this campaign" />
            )}
          </FormGroup1>
          <FormGroup1>
            <StyledLabel
              style={{ marginTop: "0px", fontSize: 15 }}
            >
              Available Budget
            </StyledLabel>
            <StyledInput
              disabled
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                height: "55px",
                border: "1px solid",
              }}
              value={
                parseFloat(rewardtitle?.[index]?.sponsorname?.[0]?.availablebudget)
              }
              type="number"
              id="create-yaad--title"

              placeholder="Budget"
            />
          </FormGroup1>
          <FormGroup1>
            <StyledLabel
              style={{ marginTop: "0px", fontSize: 15 }}
            >
              Budget allocated
            </StyledLabel>
            <StyledInput
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                height: "55px",
                border: "1px solid",
              }}
              value={parseFloat(itemreward?.sponsorbudget)}
              type="number"
              id="create-yaad--title"

              placeholder="Budget"
              onBlur={() => {
                if (!itemreward.sponsorbudget) {
                  setaddSponsorError({
                    ...addSponsorError,
                    sponsorBudgetError: true,
                  });
                } else {
                  if (Number(itemreward?.sponsorbudget) >
                    rewardtitle?.[index]?.sponsorname?.[0]?.availablebudget
                  ) {
                    setaddSponsorError({
                      ...addSponsorError,
                      sponsorBudgetExceeded: true,
                    });
                  } else {
                    setaddSponsorError({
                      ...addSponsorError,
                      sponsorBudgetExceeded: false,
                    });
                  }
                  setaddSponsorError({
                    ...addSponsorError,
                    sponsorBudgetError: false,
                  });
                }
              }}
              onChange={(e) =>
                handleChangeRewards1(itemreward, e, "sponsorbudget", index)
              }
            />
            {addSponsorError?.sponsorBudgetError && (
              <ErrorMessage message="Please enter the budget for this sponsor" />
            )}
            {addSponsorError.sponsorBudgetExceeded && (
              <ErrorMessage message="You have exceeded the budget" />
            )}
          </FormGroup1>
        </form>
        {index ? (
          <div
            style={{
              float: "right",
              position: "relative",
              bottom: 44,
              cursor: "pointer",
              left: 19,
            }}
            onClick={(e) => handleRemoveFields(index)}
          >
            <RemoveCircleOutlineOutlined></RemoveCircleOutlineOutlined>
          </div>
        ) : (
          <div
            className="handlePlus"
            style={{
              float: "right",
              bottom: 45,
              position: "relative",
              left: 20,
              cursor: "pointer",
              right: "15px",
            }}
          >
            <AddCircleOutlineOutlined
              onClick={handleRewardAddFields}
            ></AddCircleOutlineOutlined>
          </div>
        )}
      </div>
    );
  };
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            {/* <div class="content content-wrap"> */}
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              View & Review Campaign
            </div>
            <form autoComplete="off">
              <div
                style={{
                  border: "1px solid #dadce0",
                  borderRadius: 8,
                  padding: "13px 30px 40px 30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >

                  <FormGroup>
                    <StyledLabel>Title of Campaign </StyledLabel>

                    <StyledInput
                      disabled
                      value={campaignValues?.titleOfCampaign}
                      type="text"

                    // placeholder="Your answer"
                    // onChange={(e) => {
                    //   handleCampaignFieldChange("titleOfCampaign", e);
                    // }}
                    />
                  </FormGroup>


                  <FormGroup>
                    <StyledLabel>
                      Description
                    </StyledLabel>
                    <StyledInput
                      disabled
                      value={campaignValues?.descriptionOfCampaign}
                      type="text"

                      placeholder="Your answer"
                    // onChange={(e) => {
                    //   handleCampaignFieldChange("descriptionOfCampaign", e);
                    // }}
                    />
                  </FormGroup>

                  <FormGroup>
                    <StyledLabel>
                      Campaign Category
                    </StyledLabel>
                    {
                      campaignValues?.typeOfCampaign != "" &&

                      <StyledInput
                        disabled
                        value={campaignValues?.typeOfCampaign.label}
                        type="text"

                        placeholder="Your answer"
                      />

                    }
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel>
                      Number of volunteers Required
                    </StyledLabel>

                    <StyledInput
                      disabled
                      value={campaignValues?.volunteerCount}
                      type="number"
                      id="create-yaad--title"

                      placeholder="Your answer"
                    // onChange={(e) => {
                    //   handleCampaignFieldChange("volunteerCount", e);
                    // }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel>
                      Campaign Participation
                    </StyledLabel>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <StyledInput
                        disabled
                        value={campaignValues?.visibility}
                        type="text"
                        //type="number"
                        id="create-yaad--title"

                      // placeholder="Your Answer"
                      // onChange={(e) => {
                      //   handleCampaignFieldChange("visibility", e);
                      // }}
                      />
                      <span style={{ marginLeft: 4 }}>
                        {unitOfCampaign?.[0]?.unit}
                      </span>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel>
                      Start Date
                    </StyledLabel>
                    <StyledInput
                      disabled
                      // value={campaignValues?.startDate}
                      value={locationPath?.state?.startdate}
                      type="text"

                    />

                  </FormGroup>
                  <FormGroup>
                    <StyledLabel>
                      End Date
                    </StyledLabel>
                    <StyledInput
                      disabled
                      // value={campaignValues?.endDate}
                      value={locationPath?.state?.enddate}
                      type="text"

                    />

                  </FormGroup>

                  <FormGroup>
                    <StyledLabel>
                      Hours of Volunteer Participation
                    </StyledLabel>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <StyledInput
                        disabled
                        value={campaignValues?.hourOfParticipation}
                        type="number"
                        id="create-yaad--title"

                        placeholder="Your Answer"
                      // onChange={(e) => {
                      //   handleCampaignFieldChange("hourOfParticipation", e);
                      // }}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel>
                      Campaign Type
                    </StyledLabel>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <StyledInput
                        disabled
                        value={campaignValues.campaignType}
                        type="text"

                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel>
                      Campaign Goal
                    </StyledLabel>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <StyledInput
                        disabled
                        value={campaignValues?.goal}
                        type="text"
                        id="create-yaad--title"

                      // placeholder="Your Answer"
                      // onChange={(e) => {
                      //   handleCampaignFieldChange("goal", e);
                      // }}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel>
                      Duration (in days)
                    </StyledLabel>

                    <StyledInput
                      disabled
                      value={campaignValues?.durationOfCampaign}
                      type="text"
                      id="create-yaad--title"

                    // placeholder="Your answer"
                    // onChange={(e) => {
                    //   handleCampaignFieldChange("durationofCampaign", e);
                    // }}
                    />
                  </FormGroup>

                  <FormGroup>
                    <StyledLabel>
                      Total Budget
                    </StyledLabel>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <StyledInput
                        disabled
                        value={campaignValues?.totalbudget}
                        type="text"
                        id="create-yaad--title"

                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel>
                      Sponsors
                    </StyledLabel>
                    {
                      isArray(campaignValues?.sponsor) ? (
                        campaignValues?.sponsor?.map((spon => (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "flex-end",
                            }}
                          >
                            {/* <span>{spon?.sponsorname}</span> */}
                            <StyledInput
                              disabled
                              value={spon?.sponsor}
                              type="text"
                              id="create-yaad--title"

                            />
                          </div>
                        )))
                      ) : ""
                    }
                  </FormGroup>

                  {location.map((item, index) => (
                    <div key={index}>{getAddress(item, index)}</div>
                  ))}
                </div>
              </div>
            </form>

            <form autoComplete="off">
              <div style={{ border: "1px solid #dadce0", borderRadius: 8, padding: "13px 30px 40px 30px", marginTop: '10px', }} >
                <div style={{ float: "left", width: "98%", marginTop: 15 }}>
                  <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>
                    Assign Sponsor
                  </span>{" "}
                  <span
                    style={{
                      paddingLeft: 0,
                      fontSize: 16,
                      fontWeight: 300,
                      fontStyle: "italic",
                    }}
                  >
                    {" "}
                    (click on "+" icon to enter additional sponsors)
                  </span>
                  {rewardtitle.map((itemreward, index) => (
                    <div key={index}>{getRewardDetails(itemreward, index)}</div>
                  ))}
                </div>

                <FormGroup2>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: isMobile ? "column" : "row",
                      justifyContent: "space-between",
                      width: "100%",
                      flexWrap: "wrap",
                      gap: "16px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%",
                        maxWidth: isMobile ? "100%" : "48%",
                        marginBottom: "16px",
                      }}
                    >
                      <StyledSpan>Comments (Max 50 chars)</StyledSpan>
                      <StyledTextarea
                        style={{ height: 100, borderRadius: 8, padding: 8 }}
                        placeholder="Comments"
                        maxLength="50"
                        onChange={(e) => setReviewComments(e.target.value)}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        maxWidth: isMobile ? "100%" : "48%",
                        marginBottom: "16px",
                      }}
                    >
                      <span>Status</span>
                      <Select
                        style={{
                          width: "100%",
                          fontSize: 14,
                          height: 56,
                          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                          background: "#fff",
                        }}
                        onChange={(e) => setReviewStatus(e.target.value)}
                      >
                        {status?.map((item, index) => (
                          <MenuItem key={index} style={{ fontSize: 16 }} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    {(campStatus?.[0]?.reviewstatus === "Approved" ||
                      campStatus?.[0]?.reviewstatus === "approved" ||
                      campStatus?.[0]?.reviewstatus === "Rejected" ||
                      campStatus?.[0]?.reviewstatus === "rejected") ? (
                      <Button
                        disabled
                        style={{
                          backgroundColor: "gray",
                          color: "silver",
                          fontFamily: "Poppins !important",
                          // width: "80%",
                        }}
                        variant="contained"
                      >
                        {isCampaignFetching ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    ) : (
                      <Button
                        style={{
                          backgroundColor: "#E88A00",
                          color: "#fff",
                          fontFamily: "Poppins !important",
                          width: "80%",
                        }}
                        variant="contained"
                        onClick={handleOnboardCampaign}
                      >
                        {isCampaignFetching ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    )}
                  </div>
                </FormGroup2>


                {/* <FormGroup2>
                  <div
                    style={{
                      display: "flex",
                      width: "40%",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <StyledSpan >
                      Comments (Max 50 chars)
                    </StyledSpan>
                    <StyledTextarea
                      style={{ height: 100, borderRadius: 8, padding: 8 }}
                      placeholder="Comments"
                      maxLength="50"
                      onChange={(e) => setReviewComments(e.target.value)}
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>Status</span>
                      <Select
                        style={{
                          width: 183,
                          fontSize: 14,
                          height: 56,
                          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                          background: "#fff",
                        }}
                        onChange={(e) => setReviewStatus(e.target.value)}
                      >
                        {status?.map((item, index) => (
                          <MenuItem style={{ fontSize: 16 }} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    {
                      (campStatus?.[0]?.reviewstatus === "Approved" ||
                        campStatus?.[0]?.reviewstatus === "approved" ||
                        campStatus?.[0]?.reviewstatus === "Rejected" ||
                        campStatus?.[0]?.reviewstatus === "rejected")
                        ? (
                          <Button
                            disabled
                            style={{
                              marginTop: 10,
                              backgroundColor: "gray",
                              color: "silver",
                              fontFamily: "Poppins !important",
                              width: "80%",
                              marginLeft: "9px",
                            }}
                            variant="contained"
                          >
                            {isCampaignFetching ? (
                              <CircularProgress color="inherit" size={24} />
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        ) : (
                          <Button
                            style={{
                              marginTop: 10,
                              backgroundColor: "#E88A00",
                              color: "#fff",
                              fontFamily: "Poppins !important",
                              width: "80%",
                              marginLeft: "9px",
                            }}
                            variant="contained"
                            onClick={handleOnboardCampaign}
                          >
                            {isCampaignFetching ? (
                              <CircularProgress color="inherit" size={24} />
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        )
                    }
                  </div>
                </FormGroup2> */}
              </div>
            </form>
            <div style={{ textAlign: "center" }}>
              {reviewstat?.status === "success" && (
                <span style={{ color: "green" }}> {reviewstat.message} </span>
              )}
              {reviewstat?.status === "failure" && (
                <span style={{ color: "red" }}>{reviewstat.message}</span>
              )}
            </div>
            <br />
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section >
    </div >
  );
}
export default ViewCampaignScreen;
