// import * as React, {useRef} from "react";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Skeleton,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import ResponsiveDrawer from "./Header";
import { CopyRight } from "./CopyRight";
import axios from "axios";
import _ from "lodash";
import TopHeaderScreen from "./TopHeader1";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import { FixedFooterIcon } from "../components/FixedFooterIcon";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";


function SponsorAdminDashboard(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [campSubmissionlist, setCampSubmissionlist] = React.useState([]);
  const [investedBudgetList, setInvestedBudgetList] = React.useState([]);

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  

  const downloadDocument = () => {
   
    const pdf = new jsPDF("l", "pt", "a3");
    // const pdf = new jsPDF({
    //   orientation: "landscape",
    //   unit: "pt",
    //   format: [4, 2]
    // });
    pdf.html(document.querySelector("#SOADashboardScreen"), {
      callback: function (pdf) {
        var pageCount = pdf.internal.getNumberOfPages();
        // pdf.deletePage(pageCount);

        pdf.save("cyaag.pdf");
      },
    });
  };

  const getSponsorAdminDashboard = async () => {

    try {
      // const res = ""
      //   if ((selectedrewardtype.length === 0) && !(selectedpointsrange.length === 0)) {
  
      const res = await axios
        .get(
          `/sponsor/dashboard/${profileResponse?.phoneno}`
        )
        .then((response) => {
          setCampSubmissionlist(response?.data);
          return response;
        });
      //   }
    } catch (err) {
      throw err;
    }
  };

  const getInvestedBudgetForSponsorAdminDashboard = async () => {
    
    // console.log(selectedpointsrange.length === 0);
    try {
      
      const res = await axios
        .get(
          `/sponsor/dashboard/investedbudget/${profileResponse?.phoneno}`
        )
        .then((response) => {
          setInvestedBudgetList(response?.data);
          return response;
        });
      //   }
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getSponsorAdminDashboard();
    getInvestedBudgetForSponsorAdminDashboard();
  }, []);

  const sdk1 = new ChartsEmbedSDK ( {
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",

    filter: {
      // "clientid": { "$eq": "c00001" }
      // "orgrole": { "$eq": "c00001" },
      "sponsor.sponsorname.sponsorid_lookup_sponsor.organization": { "$eq": profileResponse?.organization }
    }
        
  });
 
  const SOA_chart1 = sdk1.createChart({chartId: "d3678010-292f-4b64-923e-acb545755aec", showAttribution: false});
  SOA_chart1.render(document.getElementById("SOA_chart1"));


  const sdk2 = new ChartsEmbedSDK ( {
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",

    filter: {
      // "clientid": { "$eq": "c00001" }
      // "orgrole": { "$eq": "c00001" },
      "sponsor.sponsorname.sponsorid_lookup_sponsor.organization": { "$eq": profileResponse?.organization }
    }
        
  });
  const SOA_chart2 = sdk2.createChart({chartId: "dd3779d8-ee7d-47e5-a22f-d4f7d2a36147", showAttribution: false});
  SOA_chart2.render(document.getElementById("SOA_chart2"));

  return (
    <div className="wrapper">
      {/* {
        // profileSuccess &&


        (profileResponse?.orgrole?.toLowerCase() === "hyphenadmin" ||
          profileResponse?.orgrole?.toLowerCase() === "admin" ? (
          <ResponsiveDrawer {...props} />
        ) : (
          <CustomDrawer menuItems={volunteerMenuItems} {...props} />
        ))} */}

      <ResponsiveDrawer {...props} />

      {/* <ResponsiveDrawer {...props} /> */}
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap"  id = "pdf">
          <div class="content-mrg">
          <Button
                onClick={() => navigate(-1)}
                className="backWrapper"
            >
                <i className={`fa fa-caret-left`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <br></br>
            <br></br>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Dashboard
            </div>
            <div>
              <Button className="btn download-btn" variant="contained" onClick={downloadDocument}>Download</Button>
            </div>
            <header id="SOADashboardScreen">
            <Box sx={{ minWidth: 200 }}>            
            
              <div id="SOA_chart1" class="column chartMongo"> </div>
              <div id="SOA_chart2" class="column chartMongo"> </div>
           

              
            </Box>
            </header>

            <header id="SOADashboardScreen1" style={{position:"absolute",zIndex:"-1",marginTop:"50px"}}>
            <Box sx={{ minWidth: 200 }}>
              {/* <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolInCampOverTimeChartComponent
                    response={volovertimeincampList}
                  />
                </div>
              </div> */}
              {/* <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolOverTimeChartComponent1 response={volovertimeList} />
                </div>
              </div>
              <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolMaleVsFemaleOrgChartComponent1
                    response={volgenderinorgList}
                  />
                </div>
              </div>
              <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolHoursSpentCampChartComponent1
                    response={volhourspentincampList}
                  />
                </div>
              </div>
              <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolEmpInOrgChartComponent1 response={volempinorgList} />
                </div>
              </div> */}
              {/* <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolImpactDivComponent response={volimpactinorgList} />
                </div>
              </div> */}
              {/* <div class="iframeContentWrapper">
                <div class="innerSpace"></div>
              </div>
              <div class="iframeContentWrapper">
                <div class="innerSpace"></div>
              </div> */}
            </Box>
            </header>


          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
      {/* <PDF rootElementId="pdf" downloadFileName="testpdf" /> */}
    </div>
  );
}
export default SponsorAdminDashboard;
