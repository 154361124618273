import React, { useEffect, useState } from 'react'
import { Stack } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

const VideoSideBar = ({ selectedCategory, setSelectedCategory }) => {
  const [campregisterlist, setCampRegisterList] = useState([]);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  const getCampRegistrationList = async () => {
    try {
      const res = await axios.get(
        `vol_dashboard/camp/my/${profileResponse?.orgrole}/${profileResponse?.organization}/${profileResponse?.phoneno}`
      );
      setCampRegisterList(res?.data);
      // JSON.stringify(res)

      return res;
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    if (profileSuccess) {
      getCampRegistrationList();
    }

  }, [profileSuccess]);

  return (
    <Stack
      direction="row"
      sx={{
        overflowY: "auto",
        height: { sx: "auto", md: "95%" },
        flexDirection: { md: "column" },
      }}
    >
      {campregisterlist.length > 0 &&
        campregisterlist?.map((camp) => (
          <button
            className='category-btn'
            style={{
              color: camp.name === selectedCategory ? 'white' : 'black',
              background: camp.name === selectedCategory && '#f0990c',
              maxWidth: "200px",
              textTransform: "none"
            }}
            key={camp.name}
            onClick={() => setSelectedCategory(camp.name)}
          >
            {/* <span><img src={camp.logo} style={{height:"20px", maxWidth:"20px", borderRadius:"5px", marginRight:"15px"}}></img></span> */}
            <span><img src={camp.logo} style={{ maxWidth: "40px", borderRadius: "5px", marginRight: "15px" }}></img></span>
            <span title={camp.name} style={{
              opacity: camp.name === selectedCategory ? '1' : '0.8',
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "64%",

            }} > {camp.name}</span>
          </button>
        ))}
      {campregisterlist.length === 0 &&
        <div class="progressing-a-0-campaigns">
          <p>You have not registered for any ongoing campaign.</p>
          <p>Please register for the campaign to view</p>
        </div>
      }

    </Stack>
  )
}

export default VideoSideBar