import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ResponsiveDrawer from "./Header";
import TopHeaderScreen from "./TopHeader1";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import "./Join/Join.css";
import firebase from "../services/FirebaseConfig";

export default function SignIn(props) {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const locationPath = useLocation();
  const [phoneError, setPhoneError] = useState(false);
  const [phoneTextError, setPhoneTextError] = React.useState("");
  const navigate = useNavigate();
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  var patternPhone = new RegExp(/^([0-9]{12})$/g);

  useEffect(() => {
    if (profileSuccess) {
      setName(profileResponse?.firstname);
    }
  }, [profileSuccess]);


  const handlePhonenoChange = () => {

    if (phoneError) {
      setPhoneError(true);

    } else {
      setPhoneError(false);
      handleClickLogin();
    }
  };

  const handleClickLogin = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container1"
    );
    var appVerifier = window.recaptchaVerifier;


    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        localStorage.setItem("newuserid", phoneNumber.slice(1))
        navigate("/otpphonechange");
      })
      .catch((error) => {

      });
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <span
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
                display: "block",
              }}
            >
              Please update your Phone Number here
            </span>
            <div className="joinOuterContainer">
              <div className="joinInnerContainer">
                {/* <h1 className="heading">Join</h1> */}
                <div style={{
                  width: "310px",
                  minWidth: 330,
                  maxWidth: 330,
                  display: "flex",
                  flexDirection: "column",
                  border: "6px solid rgb(134, 133, 133)",
                  background: "white",
                  borderRadius: "10px",
                  borderColor: "#f28800",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                  <div>
                    <label>Your current Phone Number</label>
                    <input
                      disabled
                      placeholder="Name"
                      className="joinInputPhoneNo"
                      type="text"
                      value={locationPath?.pathname.split("/")?.[2]}
                    />
                  </div>
                  <div>
                    <label>Please enter your new no</label>
                    <input
                      placeholder="Enter New No"
                      className="joinInputPhoneNo"
                      type="text"
                      onChange={(event) => {
                        setPhoneNumber("+" + (event.target.value));
                        if (event.target.value === locationPath?.pathname.split("/")?.[2]) {
                          setPhoneTextError("Please Enter a new phone number");
                          setPhoneError(true);
                        }
                      }}
                      onBlur={(event) => {
                        if (!patternPhone.test(phoneNumber.slice(1))) {
                          setPhoneTextError("Please Enter a Valid Phone Number");
                          setPhoneError(true);
                        } else {
                          setPhoneTextError(false);
                          setPhoneError(false);
                        }
                      }}
                    />
                  </div>
                  <div id="recaptcha-container1" />
                  {
                    !phoneError ? (
                      <button
                        className={"button mt-20"}
                        type="submit"
                        style={{ fontSize: "14px" }}
                        onClick={() => handlePhonenoChange()}
                      >
                        Update
                      </button>
                    ) : (
                      <button
                        disabled
                        className={"button mt-20"}
                        type="submit"
                        style={{ fontSize: "14px", backgroundColor: "gray", cursor: "initial" }}
                      >
                        Update
                      </button>
                    )
                  }
                  {
                    phoneError &&
                    <span style={{ color: "red" }}>{phoneTextError}</span>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
