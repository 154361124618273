import React, { useEffect, useState, useRef } from "react";
import {
  Avatar,
  Button,
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Chip } from "@mui/material";


export const VideoCard4Sides = (video) => {
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const navigate = useNavigate();
  const locationPath = useLocation();
  let [showCount, setShowCount] = useState(3);
  const videoListRef = React.useRef();


  const handleScroll = (e) => {
    var myScrollTop = e.target.scrollTop;
    var myScrollHeight = e.target.scrollHeight;
   
    var diff = myScrollHeight - myScrollTop;
   
       var offPageHeight = 100;
    if(diff < 850) {
     // const timer = setTimeout(() => {
        setShowCount(showCount + 3);
    //  }, 5000);
      
    }
  }

  useEffect(() => {
    var myDiv = document.getElementById('myDiv');
    videoListRef.current.addEventListener('scroll',handleScroll);
  },[showCount]);

  return (
    <>
      <Grid container style={{display:"revert"}}>
      <div style={{ textAlign: "center", overflowY:"auto",height: "830px", overflowX: "scroll" }} ref={videoListRef}>
        {video?.video?.map((item) =>
          item.files?.slice(0, showCount).map((item1) => (
            // <Grid item xs={12} sm={8} md={6} lg={3} xl={3}>
            <Grid item xs={"50%"} sm={6} md={6} lg={12} xl={12}>
              <Box p={1}>
                <Card
                  sx={{
                    
                    width: { xs: "70%", sm: "60%", md: "200px" },
                    // width: { xs: "70%", sm: "60%", md: "300px" },
                    boxShadow: "none",
                    borderRadius: 0,
                    flexDirection: { sx: "column", md: "column" },
                    marginBottom: "5px",
                  }}
                  onClick={video?.onClick}
                >
                  {item1?.type === "video" ? (
                    <Link
                      to={`/video/${item1?.id}/${item.campid}/${item?.phoneno}`}
                    >
                      <CardMedia
                        image={item1?.logo}
                        alt={item?.campname}
                        sx={{ width: 200, height: 180 }}
                        // sx={{ width: { xs: '100%', sm: '358px', md: 200}, height: 180 }}
                      />
                    </Link>
                  ) : (
                    <CardMedia
                      image={item1?.fileurl}
                      alt={item?.campname}
                      sx={{ width: 200, height: 180 }}
                    />
                  )}

                  <CardContent
                    sx={{
                      backgroundColor: "#1e1e1e",
                      height: "80px",
                      padding: "6px",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      fontWeight="bold"
                      color="#FFF"
                      textAlign="center"
                    >
                      <Chip key={item1?.id} size="small" label={item1?.type} style={{backgroundColor: "#404040"}}/>
                    </Typography>
                    {/* <Link
                      to={
                        item1?.type === "video"
                          ? `/video/${item1?.id}/${item.campid}/${item?.phoneno}`
                          : `/campaigndetails/${item?.campid}/${profileResponse?.orgrole}/${profileResponse?.organization}`
                      }
                    > */}
                      <Typography
                        variant="subtitle2"
                        fontWeight="bold"
                        color="#E9DCC9"
                        fontSize="12px"
                      >
                        
                        
                        
                        {item1?.timestamp.split(" ")?.[0].slice(0,10)}
                      </Typography>
                    {/* </Link> */}

                    {/* <Link
                      to={`/video/${item1?.id}/${item.campid}/${item?.phoneno}`}
                    > */}
                      <Typography
                        variant="subtitle2"
                        fontWeight="bold"
                        color="gray"
                      >
                        {item1?.username}
                        <CheckCircle
                          sx={{ fontSize: 12, color: "gray", ml: "5px" }}
                        />
                        {item?.phoneno === profileResponse?.phoneno && (
                        <Button 
                          sx={{ fontSize: 10,  color: "red", ml: "22px", backgroundColor: "silver",
                          borderRadius: 20, lineHeight: 0.5, minWidth:"0px" }}
                          
                          onClick={() => navigate(`/file/${item1?.id}/${item.campid}/${item?.phoneno}`)}
                          
                          >
                        Delete
                      </Button>
                        )
                        
}
                      </Typography>
                    {/* </Link> */}
                    
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          ))
        )}
        </div>
      </Grid>
      
    </>
  );
};

export default VideoCard4Sides;
