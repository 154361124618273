import * as React from "react";
import FormControl from "@mui/material/FormControl";
import {
  Box,
  Avatar,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";

import {
  onboardActivity,
  onboardActivityReset,
  onboardBulkReset,
  onboardCampaign,
  onboardCampaignReset,
  onboardIndividualReset,
  onboardOrganization,
  onboardOrganizationReset,
} from "../../redux/action/OnboardAction";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";
import { API_URL } from "../../services/Constants";
import { getOrganization } from "../../redux/action/getOrganization";
import { activityDuration } from "../../utils/dict";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getVolunteerName } from "../../redux/action/getVolunteerName";
import styled from 'styled-components';

const HomeScreenMainContent = styled(Box)(({ theme }) => ({
  padding: '80px 0 100px 0px',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    padding: '80px 0 100px 330px',
  },
})); 
const FormGroup = styled('div')(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: '40px',
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));


const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup1
const FormGroup1 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup2
const FormGroup2 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  flexDirection: 'column',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '75px 0 0 16px',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup3
const FormGroup3 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  width: '20%',
  '@media (max-width: 1200px)': {
    width: '20%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup4
const FormGroup4 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'right',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 0',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup7
const FormGroup7 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup8
const FormGroup8 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledLabel
const StyledLabel = styled('label')(({ theme }) => ({
  display: 'block',
  background: '#e9e7e7',
  padding: '10px',
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontWeight: 600,
  marginTop: '16px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

// StyledSpan
const StyledSpan = styled('span')(() => ({
  fontSize: '12px',
}));

// FocusThematic
const FocusThematic = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledInput
const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// StyledTextarea
const StyledTextarea = styled('textarea')(() => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// LogoButton
const LogoButton = styled('input')(() => ({
  width: '8%',
  border: 'none',
  padding: '10px',
  display: 'flex',
  height: '40px',
  color: 'white',
  '@media (max-width: 1920px)': {
    fontSize: '15px',
    width: '12%',
  },
  '@media (max-width: 1280px)': {
    fontSize: '15px',
    width: '15%',
  },
  '@media (max-width: 960px)': {
    fontSize: '15px',
    width: '20%',
  },
  '@media (max-width: 600px)': {
    fontSize: '15px',
    width: '30%',
  },
}));

// StyledTypography
const StyledTypography = styled(Typography)(({ theme }) => ({
  '& .css-u4tvz2-MuiFormLabel-root': {
    fontFamily: 'Poppins',
  },
  display: 'block',
  display: 'flex',
  fontSize: '16px',
  marginTop: '16px',
  fontWeight: 500,
  lineHeight: '18px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

const LoginPgLogo = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (max-width: 768px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
}));

const StyledLargeAvatar = styled(Avatar)(({ theme }) => ({
  width: '200px !important',
  height: '200px !important',
  margin: '0 auto',
  marginTop: '5px',
  marginBottom: '5px',
  '@media (max-width: 400px)': {
    width: '120px !important',
    height: '120px !important',
  },
  '@media (max-width: 600px)': {
    width: '140px !important',
    height: '140px !important',
  },
  '@media (max-width: 768px)': {
    width: '150px !important',
    height: '150px !important',
  },
}));

function CreateActivity(props) {
  const dispatch = useDispatch();
  const [areas, setAreas] = React.useState([]);
  const [activityList, setActivityList] = React.useState(undefined);
  const [activityType, setActivityType] = React.useState("");
  const [volunteerName, setVolunteerName] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [dateofActivity, setdateofActivity] = React.useState(new Date());
  const [activityDescription, setActivityDescription] = React.useState("");
  const [activityDurationDescription, setActivityDurationDescription] =
    React.useState("");
  const [certificate, setCertificate] = React.useState("");
  const [impact, setimpact] = React.useState("");
  const [campaignName, setCampaignName] = React.useState("");
  const [organizationName, setOrganizationName] = React.useState("");
  const {
    isActivitySuccess,
    isActivityFetching,
    isActivityError,
  } = useSelector((state) => state.onBoard);

  const { response: volunteerNameResponse, isSuccess: volunteerNameSuccess } =
    useSelector((state) => state.volunteerName);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  function subtractYears(numOfYears, date = new Date()) {
    date.setFullYear(date.getFullYear() - numOfYears);

    return date;
  }

  const handleOnboardActivity = () => {
    let formData = new FormData();
    formData.append("activitytype", activityType);
    formData.append(
      "activitydate",
      moment(dateofActivity).format("DD-MM-yyyy")
    );
    formData.append("activitydesc", activityDescription);
    formData.append("activityduration", activityDurationDescription);
    formData.append("activitycert", certificate);
    // formData.append("nsscertificate", nssCertificateReceived);
    formData.append(
      "fullname",
      volunteerName?.firstname +
      " " +
      volunteerName?.middlename +
      " " +
      volunteerName?.lastname
    );
    formData.append("impact", impact);
    formData.append("campaignname", campaignName);
    formData.append("organizationname", organizationName);

    dispatch(
      onboardActivity({
        userid: volunteerName.phoneno,
        formData,
      })
    );
  };

  const getActivityList = async () => {
    try {
      const res = await axios.get(`/activity`);
      setActivityList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const unitOfActivity = activityList?.filter(
    (item, index) => item?.activity === activityType
  );
  React.useEffect(() => {
    dispatch(onboardBulkReset());
    dispatch(onboardIndividualReset());
    dispatch(onboardActivityReset());
    dispatch(onboardCampaignReset());
    dispatch(onboardOrganizationReset());
    setAreas([]);
  }, []);

  React.useEffect(() => {
    if (isActivitySuccess) {
      setActivityType("");
      setVolunteerName("");
      setActivityDescription("");
      setActivityDurationDescription("");
      setCertificate("");
      setimpact("");
      setCampaignName("");
      setOrganizationName("");
      dispatch(onboardActivityReset());
    }
  }, [isActivitySuccess]);
  React.useEffect(() => {
    if (profileResponse) {
      dispatch(
        getVolunteerName({
          organization: profileResponse?.organization,
          userid: profileResponse?.phoneno,
        })
      );
    }
  }, [profileResponse]);

  React.useEffect(() => {
    dispatch(getOrganization({ userid: localStorage.getItem("userid") }));
    getActivityList();
  }, []);

  return (
    <div>
      <ResponsiveDrawer {...props} />
      <HomeScreenMainContent
        id="homepage-main"
      >
        <FormControl>
          <div style={{ border: "1px solid #dadce0", borderRadius: 8 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <FormGroup>
                <StyledLabel>Activity Type </StyledLabel>
                <Select
                  style={{
                    width: 200,
                    fontSize: 14,
                    height: 56,
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  }}
                  value={activityType}
                  autoFocus={false}
                  onChange={(event) => setActivityType(event.target.value)}
                >
                  {activityList?.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.activity}>
                      {item.activity}
                    </MenuItem>
                  ))}
                </Select>
              </FormGroup>

              <FormGroup>
                <StyledLabel>
                  Volunteer Name
                </StyledLabel>
                <Select
                  style={{
                    width: 200,
                    fontSize: 14,
                    height: 56,
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  }}
                  value={volunteerName}
                  onChange={(event) => setVolunteerName(event.target.value)}
                >
                  {volunteerNameSuccess &&
                    volunteerNameResponse?.map((item, index) => (
                      <MenuItem
                        style={{ fontSize: 16 }}
                        value={item}
                        onChange={() => setUserId(item.phoneno)}
                      >
                        {item.firstname +
                          " " +
                          item.middlename +
                          " " +
                          item.lastname}
                      </MenuItem>
                    ))}
                </Select>
              </FormGroup>
              <FormGroup>
                <StyledLabel>
                  Date of Activity
                </StyledLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    style={{ boxShadow: "0px 3px 6px #00000029" }}
                    inputFormat={"dd-MM-yyyy"}
                    selected={dateofActivity}
                    maxDate={subtractYears(0)}
                    onChange={(date) => {
                      setdateofActivity(date);
                    }}
                    renderInput={(params) => (
                      <TextField
                        style={{
                          width: 150,
                          height: 56,
                          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                        }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormGroup>
              <FormGroup>
                <StyledLabel>
                  Activity Description
                </StyledLabel>
                <StyledInput
                  value={activityDescription}
                  type="text"
                  id="create-yaad--title"
                  placeholder="Your answer"
                  onChange={(e) => {
                    setActivityDescription(e.target.value);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <StyledLabel>
                  Activity Duration in mins
                </StyledLabel>
                <Select
                  style={{
                    width: 200,
                    fontSize: 14,
                    height: 56,
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  }}
                  value={activityDurationDescription}
                  onChange={(event) =>
                    setActivityDurationDescription(event.target.value)
                  }
                >
                  {activityDuration.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.value}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormGroup>
              <FormGroup>
                <StyledLabel>
                  Impact
                </StyledLabel>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <StyledInput
                    value={impact}
                    type="number"
                    style={{
                      WebkitAppearance: "textfield",
                      margin: 0,
                      MozAppearance: "textfield",
                    }}
                    id="create-yaad--title"
                    placeholder="Your Answer"
                    onChange={(e) => {
                      setimpact(e.target.value);
                      // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                    }}
                  />
                  <span style={{ marginLeft: 4 }}>
                    {unitOfActivity?.[0]?.unit}
                  </span>
                </div>
              </FormGroup>
              <FormGroup>
                <StyledLabel>
                  Campaign Name
                </StyledLabel>

                <StyledInput
                  value={campaignName}
                  type="text"
                  id="create-yaad--title"
                  placeholder="Your Answer"
                  onChange={(e) => {
                    setCampaignName(e.target.value);
                    // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <StyledLabel>
                  Organization Name
                </StyledLabel>

                <StyledInput
                  value={organizationName}
                  type="text"
                  id="create-yaad--title"
                  placeholder="Your Answer"
                  onChange={(e) => {
                    setOrganizationName(e.target.value);
                    // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <StyledLabel>
                  Certificate
                </StyledLabel>
                <StyledInput
                  value={certificate}
                  type="text"
                  id="create-yaad--title"
                  placeholder="Your Answer"
                  onChange={(e) => {
                    setCertificate(e.target.value);
                    // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                  }}
                />
              </FormGroup>
            </div>
            <div style={{ padding: "16px 0", bottom: 50 }}>
              <Button
                style={{
                  marginLeft: 20, fontSize: 15,
                  fontWeight: "bold",
                  maxWidth: 107,
                  lineHeight: 1.2,
                  marginLeft: 40,
                }}
                variant="contained"
                onClick={handleOnboardActivity}

                disableElevation
                disabled={
                  isActivityFetching ||
                  !activityType ||
                  !volunteerName ||
                  !dateofActivity ||
                  !activityDescription ||
                  !activityDuration ||
                  !impact ||
                  !campaignName ||
                  !organizationName ||
                  !certificate
                }
              >
                {isActivityFetching ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Submit"
                )}
              </Button>
              <br />
              {isActivitySuccess && (
                <span style={{ color: "green" }}>
                  Activity updated sucessfully
                </span>
              )}
              {isActivityError && (
                <span style={{ color: "red" }}>Something error occured</span>
              )}
            </div>
          </div>
        </FormControl>
      </HomeScreenMainContent>
      <CopyRight />
    </div>
  );
}
export default CreateActivity;
