/** @format */

import React, { useEffect, useRef, useState } from "react";
// import Logo from "../../assets/logo-black.png";
import PropTypes from "prop-types";
import Select from 'react-select';
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import { ValidatorTable } from "../../components/validatorTable1";
import {
  Box,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { validatorList } from "../../redux/action/ValidatorList";
import { userProfile } from "../../redux/action/UserProfileActions";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import { purchaseYaag } from "../../redux/action/PurchaseYaag";
import { validateReward } from "../../redux/action/ValidatorAction";
import moment from "moment";
import QRScanner from "../../components/QRScanner";
import CropFreeIcon from '@mui/icons-material/CropFree';
import axios from "axios";
import { styled } from "@mui/material/styles";
import { isEmpty, isArray } from "lodash";
const CustomTabs = styled(Tabs)(({ theme }) => ({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1976d2',
  },
}));

// Styled component for CustomTab
const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: 'uppercase',
  minWidth: 72,
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(4),
  color: '#888da8',
  fontWeight: 600,
  '&:hover': {
    color: '#115293',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#1976d2',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&:focus': {
    color: '#1976d2',
  },
}));

function createDataValidator(
  id,
  formcampaignname,
  // formuserfullname,
  formuserfirstname,
  formuserlastname,
  formuserphone,
  campid,
  registrationstatus,
  registerdate,
  registrationid,
  rewarddate,
  documentuploadcount,
) {
  return {
    id,
    formcampaignname,
    // formuserfullname,
    formuserfirstname,
    formuserlastname,
    formuserphone,
    campid,
    registrationstatus,
    registerdate,
    registrationid,
    rewarddate,
    documentuploadcount,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  // console.log(props)
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
  // console.log(props)
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ReviewCampaignRegistrationScreen = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [welcomeOpen, setWelcomeOpen] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const userid = localStorage.getItem("userid");
  const [value1, setValue1] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [reviewStatus, setReviewStatus] = React.useState("");
  const [campaignList, setCampaignList] = useState(
    [
      'campaignname',
      'formuserfirstname',
      'formuserfirstname',
      'formuserphoneno',
      'registrationstatus',
      'rewarddate'
    ]
  );
  // const validatorListResponse = useSelector((state) => state.validatorList);
  const { isSuccess: validatorListSuccess, response: validatorListResponse } = useSelector(
    (state) => state.validatorList
  );

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  
  const handleChange = (event,newValue) => {
    console.log("handleChange==",newValue);
    setValue(newValue);
    // dispatch(validatorList(newValue));
  };

  useEffect(() => {
    dispatch(validatorList(profileResponse));
    dispatch(userProfile(userid));
  }, [campaignList]);
  
  useEffect(() => {
    // dispatch(validatorList());
    dispatch(validatorList(profileResponse));
    dispatch(userProfile(userid));
  }, []);

  useEffect(() => {
    if (
      profileSuccess &&
      (profileResponse[0]?.legal === "no" ||
        profileResponse[0]?.legal === undefined)
    ) {
      setWelcomeOpen(true);
    } else {
      setWelcomeOpen(false);
    }
  }, [profileResponse]);

  useEffect(() => {
    // console.log("useeffect")
    let temp = [];
    for (let i = 0; i < validatorListResponse.length; i += 1) {
      const formattedArray = [];
      // console.log(validatorListResponse[i])
      formattedArray.push(
        // validatorListResponse[i]._id,
        validatorListResponse[i].formcampaignname,
        // validatorListResponse[i].formuserfullname,
        validatorListResponse[i].formuserfirstname,
        validatorListResponse[i].formuserlastname,
        validatorListResponse[i].formuserphone,
        // validatorListResponse[i].affiliation,
        validatorListResponse[i].campid,
        validatorListResponse[i].registrationstatus,
        validatorListResponse[i].registerdate,
        validatorListResponse[i].registrationid,
        // validatorListResponse[i].formuserlastname,
        validatorListResponse[i].rewarddate,
        validatorListResponse[i].documentuploadcount,
      );
      console.log(formattedArray);
      temp.push(createDataValidator(i, ...formattedArray));
      console.log("temp");
      console.log(temp);
    }
    console.log("======== Formatted Value =======",validatorListResponse)
    console.log("======== Temp Value =======",temp)
    setRows(temp);
  }, [validatorListResponse]);

  useEffect(() => {
    setScannedMessage("");
    const firstTime = localStorage.getItem("firstTime");
    if (firstTime) {
      setWelcomeOpen(true);
    }
  }, []);

  const reviewedData = Array.isArray(rows) ?  rows.filter((item) => item.registrationstatus === "submitted") : [];
  const acceptedData = Array.isArray(rows) ?  rows.filter((item) => item.registrationstatus === "approved") : [];
  const rejectedData = Array.isArray(rows) ?  rows.filter((item) => item.registrationstatus === "rejected") : [];

  const sortedDataReview =
    inputValue === ""
      ? ""
      : reviewedData.filter((item) => item.formcampaignname === inputValue);

  const sortedDataApprove =
    inputValue === ""
      ? ""
      : acceptedData.filter((item) => item.formcampaignname === inputValue);

  const sortedDataRejected =
    inputValue === ""
      ? ""
      : rejectedData.filter((item) => item.formcampaignname === inputValue);

  const validationList1 = async () => {
    try {
      const res = await axios.get(
        `/validator/list/${profileResponse?.phoneno}`
      );
      const vaa = await res?.data;
      setRows(vaa);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const PopupWindow = ({ children }) => {
    const popupStyle = {
      width: '300px',
      height: '250px',
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      border: '1px solid #ccc',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
      backgroundColor: '#fff',
      zIndex: '1',
    };
    return <div style={popupStyle}>{children}</div>;
  }


  const handleONEScanButtonClick = () => {
    setScannedMessage("");
    console.log("handleScanButtonClick")
    // setClickedItem(item);
    if (scannerVisible) {
      setScannerVisible(false);
    } else {
      setScannerVisible(true);
      setScannedMessage('')
    }
  };

  const [scannerVisible, setScannerVisible] = useState(false);
  // const [clickedItem, setClickedItem] = useState(null);
  const [scannedData, setScannedData] = useState('');
  const [postScan, setPostScan] = useState('');
  const [postScanDecrypt, setPostScanDecrypt] = useState([]);
  const [scanned, setScanned] = useState(false);
  const [scannedMessage, setScannedMessage] = useState('');

  const [options, setOptions] = useState([]);
  const [hideScanner, setHideScanner] = useState(true);
  const [selectedOption1, setSelectedOption1] = useState('qrscan');

  useEffect(() => {
    setSelectedOption([]);
    campaignDropDownList();
   // handleChange(0);
  }, []);


  const closeScannerPopup = () => {
    console.log("closeScannerPopup called")
    // setClickedItem(null);
    setScannerVisible(false);
  };

  const handleScannedData = (data) => {
    console.log("ReviewRegistration - handleScannedData")
    setScannedMessage('');
    setScannedData(data);
    setScanned(true);
  };

 
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("calling decryptQRCode");
        const decryptedData = await decryptQRCode();
        console.log("called decryptQRCode = ", decryptedData)

      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (scannerVisible && scanned) {
      fetchData();
    }
  }, [scannerVisible, scanned]);

  useEffect(() => {
    console.log("postScanDecrypt updated:", postScanDecrypt);
    analyzeQRCode();
  }, [postScanDecrypt]);

  const decryptQRCode = async () => {
    try {
      console.log("scannedData = ", scannedData);
      if (!(scannedData === "Error")) {
        const resqr = await axios.get(
          `/decode/qr/${scannedData["uniqueuserid"]}/${scannedData["campid"]}/${scannedData["registrationid"]}`
        );

        console.log("decryptQRCode - API Response:", resqr?.data);
        setPostScanDecrypt(resqr?.data);
        console.log("postScanDecrypt after set:", postScanDecrypt);
        return resqr;

      } else {
        setScannedMessage("QR Code Invalid");
        setScanned(false);
      }
    } catch (err) {
      throw err;
    }
  };


  const analyzeQRCode = async () => {
    try {
      console.log("analyzeQRCode->postScanDecrypt = ", postScanDecrypt);
      console.log("analyzeQRCode->postScanDecrypt length = ", postScanDecrypt.length);
      if (postScanDecrypt) {
        const res = await axios.get(
          `/analyze/qr/camp/${postScanDecrypt["uniqueuserid"]}/${postScanDecrypt["campid"]}/${postScanDecrypt["registrationid"]}`
        );
        setPostScan(res?.data);
        if (res?.data["message"] === "Approved") {
          Promise.all([
            dispatch(
              purchaseYaag({
                toberewardeddata: [
                  {
                    registrationid: postScanDecrypt["registrationid"],
                    campid: postScanDecrypt["campid"],
                    phoneno: scannedData["phoneno"],
                  },
                ],
              })
            ),
          ])
            .then(
              () =>
                dispatch(
                  validateReward({
                    registrationid: [
                      {
                        registrationid: postScanDecrypt["registrationid"],
                        campid: postScanDecrypt["campid"],
                        reviewcomments: "QR code scanned",
                        registrationstatus: "approved",
                        rewarddate: moment(
                          new Date()
                        ).format("DD-MM-yyyy"),
                      },
                    ],
                  })
                )
            )
            .then(() => dispatch(validatorList()));

          setScannedMessage("Approved");
          console.log("scanned message Approve ====", scannedMessage);
        } else {
          setScannedMessage("Rejected")
          console.log("scanned message Reject ====", scannedMessage);
        }
        validationList1();
      } else if (!(scannedData === "Error")){
        setScannedMessage("Invalid Code")
      }
      else {
        setScannedMessage("Please Scan Again")
      }
      setScanned(false);
    } catch (err) {
      console.log("Error in analyzeQRCode()")
      throw err;
    }
  };

  const [selectedOption, setSelectedOption] = useState(null);

  //Dropdown campaign list
  const campaignDropDownList = async () => {
    try {
      const res = await axios.get(
        `camp/list/qr/${profileResponse?.phoneno}`
      );
      console.log("====== qr======", res);
      const data1 = res.data;
      // const optionsArray =  data || [];
      if (Array.isArray(data1)) {
        // Transform data1 into optionsArray
        const optionsArray = data1.map((item) => ({
          value: item.campid,
          label: item.campname,
        }));

        setOptions(optionsArray);
      } else {
        console.error("Unexpected data format:", data1);
      }
    } catch (err) {
      throw err;
    }
  };

  const handleInvalidBarcode = () => {
    setScannedMessage("QR Code Invalid");
  };

  // Handle change in the selected option
  const handleChange1 = (selected) => {
    if (selected.value !== "") {
      setHideScanner(false);
    }
    setSelectedOption(selected);
  };

  const handleOptionChange = (event) => {
    setHideScanner(true);
    setSelectedOption({});
    setSelectedOption1(event.target.value);
    setValue(0);
    validationList1();
  };

  return (
    <div class="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button
              onClick={() => navigate(-1)}
              className="backWrapper"
              style={{ justifyContent: "left" }}
            >
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Review Registrations</div>
            <main  id="exec" style={{ marginLeft: "400" }} >
            <label for="scan">
                <input
                  id="scan"
                  type="radio"
                  value="qrscan"
                  checked={selectedOption1 === 'qrscan'}
                  onChange={handleOptionChange}
                  style={{marginRight:5}}
                />
                QR Scan
            </label>

            <label for="manual" style={{marginLeft:20}}>
                <input
                  id="manual"
                  type="radio"
                  value="manualapprove"
                  checked={selectedOption1 === 'manualapprove'}
                  onChange={handleOptionChange}
                  style={{marginRight:5}}
                />
                Manual Review
            </label>

              {selectedOption1 === 'qrscan' &&
              <div>
                <div className="campaignDropDown" style={{ maxWidth: "300px", margin: "0 auto", marginBottom: "20px" }}>

                  <label>Select an option:</label>

                  <Select
                    value={selectedOption}
                    onChange={handleChange1}
                    options={options}
                    isSearchable={true} // Enable search functionality
                    placeholder="Select..."
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderRadius: 8,
                        borderColor: '#ccc',
                        boxShadow: 'none',
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? '#007bff' : 'white',
                        color: state.isSelected ? 'white' : 'black',
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: '#333',
                      }),
                    }}

                  />
                 
                </div>
                {!hideScanner && selectedOption.length !== 0 && (
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <span
                      onClick={() => {
                        handleONEScanButtonClick();
                      }}
                      style={{
                        textAlign: "center", display: "inline-block"
                      }}
                    >

                      <CropFreeIcon style={{ cursor: "pointer", display: "block", margin: "auto", height: "100px", width: "100px" }} />
                      <p>Click to Scan</p>
                      {scannerVisible && !hideScanner && (
                        <PopupWindow>
                          <QRScanner onClose={closeScannerPopup} onScan={handleScannedData} scanStatus={scannedMessage} selectedCampId={selectedOption} invalidBarcode={handleInvalidBarcode} />
                        </PopupWindow>
                      )}
                      
                    </span>
                  </div>
                )}
              </div>
              }


              {selectedOption1 === 'manualapprove' &&
              <div class="registrationreview-tab">
                <div style={{display: 'flex',
    justifyContent: 'center',
    marginRight: '100px',}}>
              <CustomTabs
                style={{ display: "flex", justifyContent: "center", marginRight: "100" }}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <CustomTab label="Submitted" />
                <CustomTab label="Approved" />
                <CustomTab label="Rejected" />
              </CustomTabs>
              </div>
             
              <TabPanel value={value} index={0} >
                <ValidatorTable
                  value1={value1}
                  setValue1={setValue1}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  // response={response}
                  // isFetching={isFetching}
                  rows={reviewedData}
                  sortedData={sortedDataReview}
                  actionTaken={false}
                  setReviewStatus={setReviewStatus}
                  triggerHangleChange = {handleChange}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ValidatorTable
                  value1={value1}
                  setValue1={setValue1}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  // response={response}
                  // isFetching={isFetching}
                  rows={acceptedData}
                  sortedData={sortedDataApprove}
                  actionTaken
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <ValidatorTable
                  value1={value1}
                  setValue1={setValue1}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  // response={response}
                  // isFetching={isFetching}
                  rows={rejectedData}
                  sortedData={sortedDataRejected}
                  actionTaken
                />
              </TabPanel>
              </div>
              }
            </main>

          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({}, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewCampaignRegistrationScreen);