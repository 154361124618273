import React from 'react';

const SVG3 = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <rect width="50" height="50" fill="url(#pattern0_140_883)" />
    <defs>
      <pattern id="pattern0_140_883" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_140_883" transform="scale(0.02)" />
      </pattern>
      <image id="image0_140_883" width="50" height="50" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADsklEQVR4nO1ZS2sUQRBuD4KgN/GBRiXTtSZE9ODrDygIehFkqtdEEDzoMf4A8eBFPAWNuXpIxEeIIuhBQRCTqvFBbgqKB0HEV4whCh5CDpGaTXbHzby2M7PZQT+Yy8x0TX3T1fV1VSv1H/8ItHm6RRse0YZ/yQVIdztc6lDFI0E/wPBc8PLv9TxvU0WBNjxST6J20bAqCrSEUgQRjfRTtTrAHe0CQw+jZ8OfkWnVqug8+mItIF3VhmfjScg64REZ0468T8bI2OX2X+05Pb4SDPcC8tS8k7Pa0IBTpr3a0GTYYneOPyvJWEDu9+9XxvaKLbUcKJX5iDb8thb7/EiXx3ZUn3ePOWD4tjgv60JmYoGEQN6VMYG188ZBOtw0AqIFGulB9U8jvQPjubb2SugdBEOvAvYeO663M1chc07wejD0uxoS6J3NIiT8EEXvbDBEF9aPzKJGvjM/q+mENY2QaUN9eS3SzvqkgTxlJazphGxuRdYEFvlR/nv9NCysrSZkYGjayh9teCLyDyB9azSrAfI9jfxZMpINER3zY2OFFZBvRQsZ3WjAgUthH5Zs125Gt6e1A0h3k4Q1FJXcT19CnPgiz1KRQG93rLIjfexyn6xJnepN+GIPalIotrtP2wFpMBBSg3JPpQQYOp+0TQHjdae21/O8TRa2n35DhDWFQ5WPqgahkS4nE6HzqlkASyIl5HNJREronWnU7q4Tj1bL1TQigHQskUiZjzTLH7WE0LqYREQjXSgAET6kDc3EpPEZx/CBZvmjrAdWdOR6DJFrNjZhOYg47thWMPwhRMTed5RpU2GICLa5LzbWE2kvP9ugLAHLRSRoI0tbqmkDA9uLeiJL6TSCrT+2ArTt5JNV4PKpsD2b7ITlmbzTqF3IYFZTQcpg0ZCw7klI5pr09cYdXZcbEW3RfC653n6N/D15j7Uoi01I6yjWdveYow0PBcYMJe7EbZvPYHi8URK1i15G2yUNSF8XjUH6GkvGtvnsx74lEY38yabQA0M3C1Oza9vS27pGzgnavmaPrpHD2qL5YW7Fkmr2uBp54X6wC5hfg476kvxJLHeDNXKlr0TDkj0qH+H+YBcwr5YpGPotupRJzZ7QxL6faRMb+XXmTey0kCMAabxFrZ+gsFb+NA0Hc/+iYwXDb21K4FwOesDwlRqJkNhG/j5/CDSQR4hmtkjlOC1ZWKsz4J9utcTRm13+b2YaXyJaTVitkSCst1VR0BEtZJMl19usigSoEzKZicKR+A9ljz+O4spXLOeUjQAAAABJRU5ErkJggg==" />
    </defs>
  </svg>

);

export default SVG3;
