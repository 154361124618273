import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Alert, Button, CircularProgress, InputAdornment, MenuItem, OutlinedInput, Select, Snackbar, TextField, Typography, useMediaQuery, FormControl } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { onboardBulk, onboardBulkReset, onboardIndividual, onboardIndividualReset } from "../../redux/action/OnboardAction";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import { Gender, restrictedCountry } from "../../utils/dict";
import { getOrganization } from "../../redux/action/getOrganization";
import { Upload } from "@mui/icons-material";
import CallingCode from "../../services/CallingCode";
import axios from "axios";
import RedStar from "../../components/RedStar";
import ErrorMessage from "../../components/ErrorMessage";
import { useNavigate } from "react-router-dom";
import TopHeaderScreen from "../../components/TopHeader1";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import DoneIcon from "@mui/icons-material/Done";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import MuiAlert from '@mui/material/Alert';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import PhoneIcon from "@mui/icons-material/Phone";
import { styled } from '@mui/material/styles';

const FormGroup = styled('div')(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: '40px',
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroupFull
const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup1
const FormGroup1 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup2
const FormGroup2 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  flexDirection: 'column',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '75px 0 0 16px',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup3
const FormGroup3 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  width: '20%',
  '@media (max-width: 1200px)': {
    width: '20%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup4
const FormGroup4 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'right',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 0',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup7
const FormGroup7 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup8
const FormGroup8 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledLabel
const StyledLabel = styled('label')(({ theme }) => ({
  display: 'flex',
  background: '#e9e7e7',
  padding: '10px',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 600,
  marginTop: '16px',

  

  
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

// StyledSpan
const StyledSpan = styled('span')(() => ({
  fontSize: '12px',
}));

// FocusThematic
const FocusThematic = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledInput
const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// StyledTextarea
const StyledTextarea = styled('textarea')(() => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// LogoButton
const LogoButton = styled('input')(() => ({
  width: '8%',
  border: 'none',
  padding: '10px',
  display: 'flex',
  height: '40px',
  color: 'white',
  '@media (max-width: 1920px)': {
    fontSize: '15px',
    width: '12%',
  },
  '@media (max-width: 1280px)': {
    fontSize: '15px',
    width: '15%',
  },
  '@media (max-width: 960px)': {
    fontSize: '15px',
    width: '20%',
  },
  '@media (max-width: 600px)': {
    fontSize: '15px',
    width: '30%',
  },
}));

// StyledTypography
const StyledTypography = styled(Typography)(({ theme }) => ({
  '& .css-u4tvz2-MuiFormLabel-root': {
    fontFamily: 'Poppins',
  },
  display: 'block',
  display: 'flex',
  fontSize: '16px',
  marginTop: '16px',
  fontWeight: 500,
  lineHeight: '18px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  padding: '16px',
  '@media (min-width: 600px)': {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

const Item = styled(Box)({
  flex: '1 1 48%',
  '@media (max-width: 600px)': {
    flex: '1 1 100%',
  },
});

const form = [
  { id: 1, formQuestion: "First Name" },
  { id: 2, formQuestion: "Middle Name" },
  { id: 3, formQuestion: "Last Name" },
  { id: 4, formQuestion: "Father Name" },
  { id: 5, formQuestion: "Date of Birth" },
  { id: 6, formQuestion: "Adhaar No" },
  { id: 7, formQuestion: "Category" },
  { id: 8, formQuestion: "Gender" },
  { id: 9, formQuestion: "Blood Group" },
  { id: 10, formQuestion: "Present Address" },
  { id: 11, formQuestion: "Permanent Address" },
  { id: 12, formQuestion: "Email Address" },
  {
    id: 13,
    formQuestion: "Session",
  },
  { id: 14, formQuestion: "Roll Number" },
  { id: 15, formQuestion: "Phone Number (Mobile Number)" },
  {
    id: 16,
    formQuestion: "Institute Name",
  },
  { id: 17, formQuestion: "University Name" },
  {
    id: 19,
    formQuestion: "State",
  },
  { id: 18, formQuestion: "City/Town" },
  { id: 20, formQuestion: "Organization" },
  { id: 21, formQuestion: "Organization Role" },
  { id: 22, formQuestion: "Organization Unit" },
];

const suggestionActiveStyle = {
  padding: "10px",
  cursor: "pointer",
  backgroundColor: "#007BFF",
  color: "#fff",
};

const suggestionStyle = {
  padding: "10px",
  cursor: "pointer",
  backgroundColor: "#fff",
  color: "#000",
  "&:hover": {
    backgroundColor: "#f0f0f0",
    color: "#000",
  },
};



function OnBoardVolunteer(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    gender: '',
    emailid: '',
    phoneno: '',
    orgrole: '',
    correspondenceaddress: '',
    country: '',
    state: '',
    city: '',
    area: '',
  });
  const [open, setOpen] = React.useState(false);

  const [orgRole, setORGRole] = React.useState([]);

  const [selectedFiles, setSelectedFiles] = React.useState("");
  const [registerCallingCode, setRegisterCallingCode] = React.useState("");
  const [countryError, setCountryError] = React.useState(false);
  const [value18, setValue18] = React.useState("");
  const [value19, setValue19] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [correspondenceaddress, setcorrespendeceaddress] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [area, setArea] = React.useState("");
  const [added, setAdded] = React.useState("no");
  const [displayConfirmationModal, setDisplayConfirmationModal] = React.useState(false);
  const [localPhoneNumber, setLocalPhoneNumber] = React.useState("");
  const [phonenoError, setPhonenoError] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isAddressComplete, setIsAddressComplete] = useState(false);
  const [confirmAddressClicked, setConfirmAddressClicked] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );


  useEffect(() => {
    const addressParts = address.split(",");
    const complete = addressParts.length >= 4 && addressParts.slice(-4).every(part => part.trim() !== "");
    setIsAddressComplete(complete);
    setIsSearching(true);
  }, [address]);

  const handleSelect = async (value) => {
    // console.log(value);
    const results = await geocodeByAddress(value);
    // console.log(results[0]["formatted_address"]);
    setAddress(results[0]["formatted_address"]);

    // console.log(value);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const {
    isIndividualSuccess,
    isIndividualFetching,
    isIndividualError,
    isBulkSuccess,
    isBulkError,
    isBulkFetching,
    individualResponse,
    bulkResponse,
  } = useSelector((state) => state.onBoard);

  React.useEffect(() => {
    dispatch(onboardBulkReset());
    dispatch(onboardIndividualReset());
  }, []);


  const handleFileInput = (e) => {
    setSelectedFiles(e.target.files[0]);
    handleFile(e.target.files[0]);
  };

  const handleFile = (selectedFiles) => {
    const formData = new FormData();
    formData.append("bulkupload", selectedFiles);

    dispatch(onboardBulk(formData));
  };

  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const getORGRole = async () => {
    try {
      const res = await axios.get(`/orgrole/${localStorage.getItem("userid")}`);
      setORGRole(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const roleDisplayNames = {
    admin: "Organization Admin",
    volunteer: "Changemaker",
    campadmin: "Campaign Admin",
    cyaagadmin: "Platform Admin"
  };

  const sortedCallingCode = CallingCode.sort(function (a, b) {
    if (a.name < b.name) return -1;
    else if (a.name > b.name) return 1;
    return 0;
  });
  const replacedPhoneNumber =
    (registerCallingCode === ""
      ? "91"
      : registerCallingCode.replace(/\+/g, "")) + localPhoneNumber;
  const handleChangeRegisterCallingCode = (event) => {
    setRegisterCallingCode(event.target.value);
    validatePhoneNumber(localPhoneNumber, event.target.value);
  };

  const validatePhoneNumber = (phone, code) => {
    const phoneNumber = parsePhoneNumberFromString(phone, code);
    if (!phoneNumber || !phoneNumber.isValid()) {
      setPhonenoError(true);
    } else {
      setPhonenoError(false);
    }
  };

  const handleOnboardIndividual = () => {
    console.log("registerCallingCode = ", registerCallingCode)
    const { firstname, lastname, gender, emailid, orgrole, correspondenceaddress } = formData;
    let newErrors = {};
    if (!firstname) {
      newErrors.firstname = 'First Name is required';
      firstnameRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    if (!lastname) {
      newErrors.lastname = 'Last Name is required';
      lastnameRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    if (!gender) {
      newErrors.gender = 'Gender is required';
      genderRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    if (!emailid) {
      newErrors.emailid = 'Email Address is required';
      emailidRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    if (!localPhoneNumber) {
      setPhonenoError(true);
      phonenoRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    if (countryError) {
      newErrors.country = 'Country is required';
      correspondenceRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    if (!value18) {
      newErrors.state = 'State is required';
      correspondenceRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    if (!value19) {
      newErrors.city = 'City is required';
      correspondenceRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    if (!area) {
      newErrors.area = 'Area is required';
      correspondenceRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    if (!orgrole) {
      newErrors.orgrole = 'Organization Role is required';
      orgRoleRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    if (added === "no") {
      newErrors.correspondenceaddress = 'Address is required';
      correspondenceRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (!isAddressComplete) {
      newErrors.correspondenceaddress = 'Address is required';
      correspondenceRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    console.log("newErrors - ", newErrors)


    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      window.scrollTo(0, 0);
      return;
    }
    dispatch(onboardIndividualReset());
    let formDataToSend = new FormData();

    formDataToSend.append("adminphoneno", localStorage.getItem("userid"));
    formDataToSend.append("firstname", firstname);
    formDataToSend.append("lastname", lastname);
    formDataToSend.append("dob", "01-01-2000");
    formDataToSend.append("bloodgroup", "A +ve");
    formDataToSend.append("gender", gender);
    formDataToSend.append("correspondenceaddress", "Home");
    formDataToSend.append("emailid", emailid);
    formDataToSend.append("phoneno", replacedPhoneNumber);
    formDataToSend.append("country", country);
    formDataToSend.append("state", value18);
    formDataToSend.append("city", value19);
    formDataToSend.append("area", area);
    formDataToSend.append("orgrole", orgrole);
    formDataToSend.append("highlvledu", "Graduate");
    formDataToSend.append("hoursperweek", "1-2 hours");
    formDataToSend.append("preferday", "Weekends (Sat-Sun)");
    formDataToSend.append("interestarea", JSON.stringify([{ "interest": "No Poverty" }]));
    formDataToSend.append("skill", JSON.stringify([{ "skill": "Networking" }]));
    formDataToSend.append("expertise", "");

    dispatch(onboardIndividual(formDataToSend));

    setSnackbarOpen(true); // Show the snackbar
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 3000);

  };


  React.useEffect(() => {
    dispatch(getOrganization({ userid: localStorage.getItem("userid") }));
  }, []);

  React.useEffect(() => {
    if (isIndividualSuccess && individualResponse?.status === "success") {

      setFormData({
        firstname: '',
        lastname: '',
        gender: '',
        emailid: '',
        phoneno: '',
        orgrole: '',

      });

      setCountry("");
      setValue18("");
      setValue19("");
      setArea("");
      setAdded("");
      setAddress("");
      setLocalPhoneNumber("");
    } else if (isBulkSuccess) {
      setOpen(true);
    }
  }, [isIndividualSuccess, isBulkSuccess, isIndividualError]);

  React.useEffect(() => {
    getORGRole();
  }, []);


  const updatelocation = (address) => {
    if (isAddressComplete) {
      setAdded("yes");
      setIsSearching(false);
      setConfirmAddressClicked(true);
      setErrors(prevErrors => ({
        ...prevErrors,
        correspondenceaddress: ''
      }));
      setCountry(
        String(address.split(",")[address.split(",").length - 1]).trim(" ")
      );
      setValue18(
        String(address.split(",")[address.split(",").length - 2]).trim(" ")
      );
      setValue19(
        String(address.split(",")[address.split(",").length - 3]).trim(" ")
      );
      setArea(
        String(address.split(",")[address.split(",").length - 4]).trim(" ")
      );
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        correspondenceaddress: 'Please select address and click on Confirm Address'
      }));
    }
  };




  const numberOfFields = 1;
  const [fieldVisibility, setFieldVisibility] = React.useState(
    Array(numberOfFields).fill(false)
  );

  const toggleField = (index) => {
    const updatedFieldVisibility = [...fieldVisibility];
    updatedFieldVisibility[index] = !updatedFieldVisibility[index];
    setFieldVisibility(updatedFieldVisibility);
  };
  const isDesktop = useMediaQuery('(min-width:600px)');
  const [errors, setErrors] = useState({});
  const firstnameRef = useRef(null);
  const lastnameRef = useRef(null);
  const genderRef = useRef(null);
  const emailidRef = useRef(null);
  const phonenoRef = useRef(null);
  const orgRoleRef = useRef(null);
  const correspondenceRef = useRef(null);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("name = ", name);
    console.log("value = ", value);
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));

    // if (name === 'phoneno') {
    //     setLocalPhoneNumber(value);
    //     setFormData(prevFormData => ({
    //         ...prevFormData,
    //         phoneno: registerCallingCode.replace(/\+/g, '') + value,
    //     }));
    // } else {
    //     setFormData(prevFormData => ({
    //         ...prevFormData,
    //         [name]: value,
    //     }));
    // }

    if ((name === 'firstname' && errors.firstname) ||
      (name === 'lastname' && errors.lastname) ||
      (name === 'gender' && errors.gender) ||
      (name === 'emailid' && errors.emailid) ||
      (name === 'correspondenceaddress' && errors.correspondenceaddress) ||
      (name === 'orgrole' && errors.orgrole)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: '',
      }));
    }

    if (value === '') {
      let capitalizedField;
      if (name === 'firstname') {
        capitalizedField = 'First Name';
      } else if (name === 'lastname') {
        capitalizedField = 'Last Name';
      } else if (name === 'emailid') {
        capitalizedField = 'Email Address';
      } else if (name === 'phoneno') {
        capitalizedField = 'Phone Number';
      } else if (name === 'orgrole') {
        capitalizedField = 'Organization Role';
      } else {
        capitalizedField = name.charAt(0).toUpperCase() + name.slice(1);
      }
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: `${capitalizedField} is required`,
      }));
    }
  };


  const handleSelectChange = (name) => (event) => {
    setFormData({
      ...formData,
      [name]: event.target.value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleBlur = () => {
    // Handle onBlur logic here
    console.log("isAddressComplete = ", isAddressComplete);
    if (!isAddressComplete) {
      setErrors(prevErrors => ({
        ...prevErrors,
        correspondenceaddress: 'Please select address and click on Confirm Address'
      }));
      setCountryError(true);
      setAdded("no");
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        correspondenceaddress: ''
      }));
      setCountryError(false);
      setAdded("yes");
    }
  };
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);
  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            {

              profileResponse?.orgrole === "cyaagadmin" || (profileResponse?.orgrole === "admin" && profileResponse?.organization === "cYAAG Technologies") ?
                ""
                :
                <div>
                  <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", marginRight: 12, marginBottom: 4 }} >
                    <Button variant="contained" component="label" style={{ marginTop: '6px' }}>
                      {isBulkFetching ? (
                        <CircularProgress size={22} color="inherit" />
                      ) : (
                        <>
                          Bulk Upload
                          <Upload />
                          <input hidden accept=".xlsx" multiple type="file" onChange={handleFileInput} />
                        </>
                      )}
                    </Button>
                  </div>

                  <div style={{ textAlign: "right" }}>
                    {isBulkSuccess && (
                      <span
                        style={{
                          marginLeft: 400,
                          marginTop: 50,
                          color: "rgb(0,200,50)",
                        }}
                      >
                        {bulkResponse?.message}
                      </span>
                    )}
                    {isBulkError && (
                      <>
                        <span
                          style={{
                            marginLeft: 600,
                            marginTop: 500,
                            color: "rgb(255,0,0)",
                          }}
                        >
                          {/* "Please verify the file uploaded is in the right format" */}
                          {isBulkError}
                          {bulkResponse?.message}
                          {bulkResponse?.status}
                        </span>
                      </>
                    )}
                  </div>
                </div>
            }
            <div style={{ fontFamily: "monospace", fontSize: 16 }}>

              <Button onClick={() => navigate(-1)} className="backWrapper">
                <i className={`fa fa-caret-left`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
              </Button>
              {

                profileResponse?.orgrole === "cyaagadmin" || (profileResponse?.orgrole === "admin" && profileResponse?.organization === "cYAAG Technologies") ?
                  ""
                  :
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                      marginRight: 5,
                      marginBottom: 20,
                    }}
                  >
                    <marquee behavior="scroll" direction="left" style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px', color: 'red' }}>
                      Please do not leave this page until you submit as you may risk losing the partially filled in form{" "}
                    </marquee>
                  </div>
              }
            </div>
            <span
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Onboard Users
            </span>


            {

              profileResponse?.orgrole === "cyaagadmin" || (profileResponse?.orgrole === "admin" && profileResponse?.organization === "cYAAG Technologies") ?
                <div style={{ marginTop: '20px', color: 'red' }}>Please encourage users to self register</div>
                :


                <Box sx={{ maxWidth: 1000, mx: 'auto', mt: 4, border: "1px solid #dadce0", borderRadius: 2, }}>
                  <Container>
                    <Item ref={firstnameRef}>
                      <StyledTypography variant="subtitle1" gutterBottom>
                        First Name <RedStar />
                      </StyledTypography >
                      <TextField
                        fullWidth
                        name="firstname"
                        placeholder="Your answer"
                        value={formData.firstname}
                        onChange={handleInputChange}
                        error={!!errors.firstname}
                        helperText={errors.firstname}
                        FormHelperTextProps={{
                          style: {
                            marginLeft: 0,
                            marginTop: -1,
                            color: '#FF0000',
                            fontSize: '0.875rem'
                          }
                        }}
                      />
                    </Item>
                    <Item ref={lastnameRef}>
                      <StyledTypography variant="subtitle1" gutterBottom>
                        Last Name <RedStar />
                      </StyledTypography >
                      <TextField
                        fullWidth
                        name="lastname"
                        placeholder="Your answer"
                        value={formData.lastname}
                        onChange={handleInputChange}
                        error={!!errors.lastname}
                        helperText={errors.lastname}
                        FormHelperTextProps={{
                          style: {
                            marginLeft: 0,
                            marginTop: -1,
                            color: '#FF0000',
                            fontSize: '0.875rem'
                          }
                        }}
                      />
                    </Item>
                    <Item ref={genderRef}>
                      <StyledTypography variant="subtitle1" gutterBottom>
                        Gender <RedStar />
                      </StyledTypography>
                      <FormControl fullWidth>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                          className="menuDropDownTeams"
                        >
                          <Select
                            placeholder="Select"
                            name="gender"
                            value={formData.gender}
                            onChange={handleSelectChange('gender')}
                            error={!!errors.gender}
                            style={{ width: '100%' }}
                          >
                            {Gender.map((item, index) => (
                              <MenuItem style={{ fontSize: 16 }} value={item.value} sx={{
                                fontSize: 16,
                                "&:hover": {
                                  backgroundColor: "#0096fb",
                                },
                              }}>
                                {item.value}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                        {errors.gender && <Typography variant="body2" color="#FF0000">{errors.gender}</Typography>}
                      </FormControl>
                    </Item>
                    <Item ref={emailidRef}>
                      <StyledTypography variant="subtitle1" gutterBottom>Email Address <RedStar /></StyledTypography>
                      <TextField
                        fullWidth
                        name="emailid"
                        value={formData.emailid}
                        placeholder="Your answer"
                        onChange={handleInputChange}
                        error={!!errors.emailid}
                        helperText={errors.emailid}
                        FormHelperTextProps={{
                          style: {
                            marginLeft: 0,
                            marginTop: -1,
                            color: '#FF0000',
                            fontSize: '0.875rem'
                          }
                        }}
                        style={{ width: '100%' }}
                      />
                    </Item>

                    <Item ref={phonenoRef}>
                      <StyledTypography variant="subtitle1" gutterBottom>Phone Number (Mobile Number) <RedStar /></StyledTypography>
                      <div>
                        <Select
                          style={{
                            width: 102,
                            fontSize: 12,
                            // padding: "0px 0px 0px 5px",
                            marginRight: 12,
                            height: 56,
                          }}
                          value={
                            registerCallingCode === ""
                              ? "+91"
                              : registerCallingCode
                          }
                          onChange={handleChangeRegisterCallingCode}
                        >
                          {restrictedCountry.map((item, index) => (
                            <MenuItem value={item.dial_code}>
                              {item.name + "\t" + item.dial_code}
                            </MenuItem>
                          ))}
                        </Select>
                        <TextField
                          name="phoneno"
                          value={localPhoneNumber}
                          style={{
                            height: 56,
                            width: "calc(100% - 114px)",
                            // boxShadow: "0px 3px 6px #00000029",
                            fontFamily: "Poppins",
                            // border: "1px solid #ccc",
                          }}
                          onBlur={() => {
                            if (!localPhoneNumber) {
                              setPhonenoError(true);
                            } else {
                              setPhonenoError(false);
                            }
                          }}
                          required
                          id="login-phone-number"
                          placeholder="Phone No."
                          onChange={(e) => {
                            setLocalPhoneNumber(e.target.value);
                            validatePhoneNumber(e.target.value, registerCallingCode);
                          }}
                          // onChange={handleInputChange}
                          error={!!errors.phoneno}
                          helperText={errors.phoneno}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PhoneIcon color="#2C7973" />
                              </InputAdornment>
                            ),
                          }}
                          FormHelperTextProps={{
                            style: {
                              marginLeft: 0,
                              marginTop: -1,
                              color: '#FF0000',
                              fontSize: '0.875rem'
                            }
                          }}
                        />

                      </div>
                      {phonenoError && (
                        <ErrorMessage message="Please enter valid mobile number" />
                      )}
                    </Item>
                    <Item ref={orgRoleRef}>
                      <StyledTypography variant="subtitle1" gutterBottom>Organization Role <RedStar /></StyledTypography>
                      <Select

                        placeholder="Select"
                        name="orgrole"
                        value={formData.orgrole}
                        onChange={handleSelectChange('orgrole')}
                        error={!!errors.orgrole}
                        style={{ width: '100%' }}
                      >

                        {orgRole.map((item, index) => (
                          <MenuItem style={{ fontSize: 16 }} value={item.role} sx={{
                            fontSize: 16,
                            "&:hover": {
                              backgroundColor: "#0096fb",
                            },
                          }}>
                            {/* {item.role} */}
                            {roleDisplayNames[item.role]}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.orgrole && <Typography variant="body2" color="#FF0000">{errors.orgrole}</Typography>}
                    </Item>
                  </Container>
                </Box>
            }

            {

              profileResponse?.orgrole === "cyaagadmin" || (profileResponse?.orgrole === "admin" && profileResponse?.organization === "cYAAG Technologies") ?
                ""
                :
                <Box sx={{ maxWidth: 1000, mx: 'auto', border: "1px solid #dadce0", borderRadius: 2, padding: '16px', marginTop: '4px' }}>
                  <Item ref={correspondenceRef}>
                    <StyledTypography
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      Enter your Correspondence Address <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "5px",
                          marginTop: '2px',
                          marginBottom: '5px'
                        }}
                        onClick={() => toggleField(1)}
                      ></i>
                    </StyledTypography>
                    {fieldVisibility[1] && (
                      <div
                        className="bottom-div"
                        style={{

                          position: "relative",
                          fontSize: "12px",
                          marginLeft: "10px",
                          backgroundColor: 'cyan',
                          width: isDesktop ? '30%' : '80%',
                        }}
                      >
                        Search for address, select and click on CONFIRM ADDRESS button below.
                      </div>
                    )}
                    <br />
                    <PlacesAutocomplete
                      value={address}
                      onChange={setAddress}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div >

                          <TextField
                            value={correspondenceaddress}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment>
                                  <IconButton>
                                    <SearchIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            // onBlur={() => {
                            //   console.log("isAddressComplete = ", isAddressComplete)
                            //   // if (!address || !address.trim() || !country) {
                            //   if (!isAddressComplete) {
                            //   setErrors(prevErrors => ({
                            //     ...prevErrors,
                            //     correspondenceaddress: 'Please select address and click on Confirm Address'
                            //   }));
                            //   setCountryError(true);
                            //   setAdded("no");
                            // } else {
                            //   setErrors(prevErrors => ({
                            //     ...prevErrors,
                            //     correspondenceaddress: ''
                            //   }));
                            //   setCountryError(false);
                            //   setAdded("yes");
                            // }
                            // }}
                            {...getInputProps({
                              placeholder: "Search for address..",
                              // autoFocus: false,
                              onBlur: handleBlur,

                            })}
                          />
                          <div> {loading ? <div>...loading...</div> : null}</div>
                          {suggestions.map((suggestion, index) => {
                            const isActive = suggestion.active;
                            const style = isActive
                              ? suggestionActiveStyle
                              : {
                                ...suggestionStyle,
                                ...(hoveredIndex === index ? { backgroundColor: "#f0f0f0", color: "#000" } : {}),
                              };

                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, { style })}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </PlacesAutocomplete>
                    {errors.correspondenceaddress && (
                      <Typography color="#FF0000" style={{ fontSize: '14px' }} >{errors.correspondenceaddress}</Typography>
                    )}
                  </Item>


                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}>
                    {[
                      { label: "Country", value: address.split(",").pop() },
                      { label: form[17]?.formQuestion, value: address.split(",").slice(-2, -1)[0] },
                      { label: form[18]?.formQuestion, value: address.split(",").slice(-3, -2)[0] },
                      { label: "Area", value: address.split(",").slice(-4, -3)[0] },
                    ].map((field, index) => (
                      <div key={index} style={{
                        flex: "1 1 50%",
                        padding: "10px",
                      }}>
                        <StyledLabel htmlFor={`field-${index}`} >
                          {field.label}
                          <RedStar />
                        </StyledLabel>
                        <div style={{
                          padding: "8px",
                          borderRadius: "6px",
                          backgroundColor: "#f5f5f5",
                          border: "1px solid #ddd",
                          fontSize: "14px",
                          color: "#333",
                          marginTop: "4px",
                          fontFamily: "Poppins",
                          lineHeight: "1.5",
                        }}>{field.value}</div>
                      </div>
                    ))}
                  </div>
                  <div style={{ textAlign: "center", padding: "20px 0px 0px 0px" }} >
                    <Button
                      variant="contained"
                      onClick={() => updatelocation(address)}
                      disableElevation
                      disabled={!isAddressComplete}
                      style={{
                        width: "170px",
                        height: "35px",
                        background: "#f5eceb",
                        color: "green",
                        fontSize: "14px",
                        fontFamily: "Poppins !important",
                      }}

                    >
                      Confirm Address
                    </Button>
                    {isAddressComplete && !isSearching && added === "yes" && <DoneIcon style={{
                      color: "green",
                      fontSize: "30px",
                      marginLeft: "10px",
                    }} />}

                  </div>
                </Box>
            }
          </div>
          {

            profileResponse?.orgrole === "cyaagadmin" || (profileResponse?.orgrole === "admin" && profileResponse?.organization === "cYAAG Technologies") ?
              ""
              :
              <div className="centerAlign" style={{ padding: "60px 0", bottom: 50 }} >
                <Button
                  style={{ marginLeft: 20 }}
                  variant="contained"
                  className="btn save-btn"
                  disableElevation
                  onClick={handleOnboardIndividual}
                  disabled={isIndividualFetching}
                >
                  <span>Submit</span>
                </Button>
                <br />
                <div>
                </div>

              </div>
          }
        </div>
        <CopyRight />
        <FixedFooterIcon />
        {/* <ConfirmationDialog
          showModal={displayConfirmationModal}
          hideModal={hideConfirmationModal}
          message={individualResponse?.message}
        /> */}
      </section>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={isIndividualSuccess && individualResponse?.status === "success" ? "success" : "warning"}
            sx={{
              width: '100%',
              backgroundColor: individualResponse?.status === "success" ? '#28A745 !important' : '#B9544B !important',
              color: 'white !important',
              boxShadow: 'none',
              zIndex: 9999,
            }}
          >
            {individualResponse?.message}
          </Alert>
        </Snackbar>
      </Box>
    </div>
  );
}
export default OnBoardVolunteer;
