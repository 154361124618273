import { Typography, Box, Paper, Button } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { CopyRight } from "../../components/CopyRight";
import LoginLogo from "../../assets/sdp.jpeg";
import styled from 'styled-components';
import { useNavigate, useLocation, Link } from "react-router-dom";

const LoginPgLogo = styled('div')(({ theme }) => ({
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    background: '#f6f6f6',

    '@media (max-width: 768px)': {
        width: '100%',
        background: '#f6f6f6',
    },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: '20px',
    marginTop: '20px',
    textAlign: 'left',
    backgroundColor: '#f0f0f0',
}));


const PGContactSalesConfirm = (props) => {
    const topRef = useRef(null);
    const locationPath = useLocation();
    const { selectedIndex, formData } = locationPath.state || {};
    return (
        <div className="wrapper" ref={topRef}>
            <section className="containers emailOtp" style={{ overflow: "hidden" }}>
                <div class="content content-wrap">
                    <LoginPgLogo style={{ textAlign: "center" }}>
                        <img
                            src={LoginLogo}
                            style={{
                                height: 56,
                                // width: 100,
                            }}
                        />
                        <figcaption style={{ marginLeft: "5px" }}>Powered by cYAAG</figcaption>
                    </LoginPgLogo>
                    <Box textAlign="center" mt={3}>
                        <Typography variant="h5" gutterBottom>
                            Thank You, {formData?.firstname} {formData?.lastname}!
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Your request has been received. We will contact you shortly.
                        </Typography>
                    </Box>
                    <StyledPaper elevation={3}>
                        <Typography variant="h6">Your Details</Typography>
                        <Typography variant="body1"><strong>Full Name:</strong> {formData?.firstname} {formData?.lastname}</Typography>
                        <Typography variant="body1"><strong>Email:</strong> {formData?.emailid}</Typography>
                        <Typography variant="body1"><strong>Phone:</strong> {formData?.phoneno}</Typography>
                        <Typography variant="body1"><strong>Address:</strong> {formData?.correspondenceaddress}, {formData?.area}, {formData?.city}, {formData?.state}, {formData?.country}</Typography>
                        
                        <Box textAlign="center" mt={3}>
                            <Button variant="contained" color="primary" component={Link} to="/landing">
                                Go Back
                            </Button>
                        </Box>
                    </StyledPaper>
                    
                    <CopyRight />
                </div>
            </section>
        </div>
    );
};

export default PGContactSalesConfirm;