import React from 'react';

const SVG1 = () => (
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <rect width="70" height="70" fill="url(#pattern0_140_846)"/>
    <defs>
      <pattern id="pattern0_140_846" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_140_846" transform="scale(0.02)"/>
      </pattern>
      <image id="image0_140_846" width="50" height="50" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEwklEQVR4nO1ZXWgcVRS+aavV+oc/oKJUqxURFamiDyJExdJos9lzJozii6JgtA+CIlLQhz6oIIoFEbQqohWrEKFUCtHqw+7cs7u2EFBRVKqlNhKSuXe7xqg1WuvImd1Jbiazs5vdSXZX9oN5mbtz7v3u+e75uStEF138f6Bz1m2i0+F9Y5+sJPykctZdopOhJWzRhJ4i3C86Fd7o0Ema8DAT4cd1rE2iE6EJhgISvlckFkQ7w/O2rXBzcLkrsc+V+KiS+Iom+EQR/mISCSSmJL6jJDytCO5W2cEN46P9a0Q7wPNEjybYHl50PY+S+H2xYF8k2gla4jOLIkH4rXLwQtGO0IRb6yIi8YvxTP95op2hCZ6LlxN8PUWbzxbtDiXh3niPwMeiE6AJn40/G/CzaBdM7Nt4WrUxRbgnFJkySuK0+a4eaU0V7HMSX/jCxcK7OjdwZdSYlvhjZcF5JaGX3/16AM5VBM9rCcf8LE9wS805JOzWBDeIpYJbSK1XhMeZTHiMk5uW8KUitKO+LWbwYk3wsivxgbg5jtLg1UrCCU3woVgqKAk7K1r/R2WtK8wxb9heyQmylg0v07sqblwTDlci3ImjebxGJA0uRdgbht7fTqrMmcikLy0SblQSHy97wzhjOese5eD1xf19ZyYxH+v/rVB2Pj5J1mXNSlVLnFxEVfBSUyRKeesSJeGviLzwZtNyzQ5u0IS6dm0Gu2rJsiY04RuG0RnD+N8si2bJuITXaQIVQ2IHSzBpbzysCQ6Zk4gEoMl6okqVPF1PEKkJJfE1Y9FHKr34Q6ZXmGzz88COah5xM/YFDRk9nLn/lMls+lqOGKaUuPMLWlm+WDAmG+YE2ExprglyQRBREt7TEn8I7BfJuqPqh7wYjjr8I16gn30J97JsOE9E9BBj7I3ZiXPwSJUdnKlIb2/ZJgzxHLUinCIs8Te8gcH6ioT3ce+vCB9bSMDbtoITW9Ri4/sI2DLPDkuMcGxRNvhA5/HGKAVwrogiOFawT+XIFsnezdinc7KpMfGM38kR7lGELxwc6Vu9wI5jbfLzi0RZKx9wwCgSWCJpjI/2r1ESPg3tuqsc3DzhpNY1Eu44+/LOKsJXF8otnRJLhYMjfauVhI/mk8ERdnWjNjWlb9UEvy0biQCVcLo7FLv3sTbFIqEI7tQEfxqb8kdsxEkaXLn6IW/+wXR0buCMem2wJOeHavxdO3i7WG54md5VmvD9kMxkPZdofIg5Mc6FaphynfTNolXwyp7xe47gqaft5IbJyDelogM3iVbD5ZA6d1bGG7jrzYt2gDaKNz709XzDMjJllUiRl2wDBdvNMW49NcEHfGFtliulz+yzlMR/g+9KZK8VrYYiPGDs7oP+QsleqwlfN8uacnkCQ0GzY5YrHIZbSsLzRI95D8UhtVJMzsSUHt/xzQnfIs6+d+DJlhKZcFLrQoucDamG3A5Ftb3zwq+EnS0lonM4ELPzR3wpDdsro6QWekZbSkRJfCqy9CbcGlV/FbNwVbmpmjvoleLzGBNuDQvht5u7jNA77Z+POsoUToDlpszwYOgCb1mhCb7iQo8JNPI/Bt/r+r2J75U0iFbAK3eOL05+njq/OTuih0lUuwPuoosuxJLiP9srZlZu1xflAAAAAElFTkSuQmCC"/>
    </defs>
  </svg>
);

export default SVG1;
