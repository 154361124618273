// import * as React, {useRef} from "react";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import ResponsiveDrawer from "./Header";
import { CopyRight } from "./CopyRight";
import moment from "moment";
import axios from "axios";
import _ from "lodash";
import TopHeaderScreen from "./TopHeader1";
import { useNavigate } from "react-router-dom";
import CampImpactComponent from "./CampImpactComponent";
import AsyncSelect from "react-select/async";
import { CSVLink, CSVDownload } from "react-csv";
import { FixedFooterIcon } from "./FixedFooterIcon";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { Audio, RotatingTriangles } from "react-loader-spinner";
import styled from 'styled-components';

const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': { 
    width: '100%',
  },
  '@media (max-width: 768px)': { 
    width: '100%',
  },
  '@media (max-width: 600px)': { 
    width: '100%',
  },
  '@media (max-width: 400px)': { 
    width: '100%',
  },
}));

function SOACampaignImpact(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = React.useRef(null);
  const inputRefBanner = React.useRef(null);
  const videoRef = React.useRef(null);
  const [show, setShow] = React.useState(false);

  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "", cityList: "" },
  ]);

  const [focusArea, setFocusArea] = React.useState([
    {
      rewards: "Certificate of Participation",
    },
    {
      rewards: "Discount Coupons",
    },
    { rewards: "Gift Cards" },
    { rewards: "Social Media Recognition" },
  ]);

  const [campName, setCampName] = React.useState([]);

  const [selectedcampname, setselectedcampname] = React.useState([]);
  const [campSubmissionlist, setCampSubmissionlist] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  React.useEffect(() => {
    getCampName();
  }, []);

  const loadOptions = (searchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = campName.filter((category) =>
        category.label.toLowerCase().includes(searchValue.toLowerCase())
      );

      callback(filteredOptions);
    });
  };

  const getCampName = async () => {
    try {
      // const res = await axios.get(
      //   `/camp/name/report/${profileResponse?.phoneno}`
      // );
      const res = await axios.get(
        `/a/b/c/d/${profileResponse?.phoneno}`
      );
      setCampName(res?.data);

      return res;
    } catch (err) {
      throw err;
    }
  };


  const getRewardsFromMarketplaceOnSearch = async () => {

    setIsLoading(true);

    // console.log(selectedpointsrange.length === 0);
    try {
      // const res = ""
      //   if ((selectedrewardtype.length === 0) && !(selectedpointsrange.length === 0)) {

      const res = await axios
        .get(`/a/${selectedcampname[0]["label"]}/${profileResponse?.phoneno}`)
        .then((response) => {
          setCampSubmissionlist(response?.data);

          // return response;
          setIsLoading(false);
        });
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
  };

  const colorStyles = {
    control: (styles) => ({ ...styles }),
    // option: (styles, {data, isDisabled, isFocussed, isSelected}) => {
    //   return{...styles, color: "blue"};
    // },
    multiValue: (styles, { data }) => {
      return { ...styles, backgroundColor: "#0096FF", color: "#fff" };
    },
    multiValueLabel: (styles, { data }) => {
      return { ...styles, color: "#fff" };
    },
    multiValueRemove: (styles, { data }) => {
      return {
        ...styles,
        color: "#fff",
        cursor: "pointer",
        ":hover": {
          color: "#fff",
        },
      };
    },
  };

  const ren = (
    <header id="SOADashboardScreen">
      <form autoComplete="off">
        <div
          style={{
            border: "1px solid #dadce0",
            borderRadius: 8,
            backgroundColor: "#fff",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          ></div>
          <div
            className="centerAlign"
            style={{ padding: "16px 0", marginBottom: 50 }}
          >
            <br />
            <FormGroupFull>
              <div>
                <CampImpactComponent rows={campSubmissionlist} />
              </div>
            </FormGroupFull>
          </div>
        </div>
      </form>
    </header>
  );

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Impact Assessment of Campaign
            </div>
            <div class="formMarketPlace">
              <div>
                <div
                  class="form-market-wrap"
                  id=""
                  role="tabpanel"
                  aria-labelledby="nav-places-tab"
                >
                  <h6>Please select a Campaign</h6>
                  <form action="#" method="get">
                    <div
                      style={{
                        padding: "0px 0px 0px 0px",
                        width: "300px",
                        marginBottom: "5px",
                        maxWidth: "100%",
                      }}
                    >
                      <AsyncSelect
                        loadOptions={loadOptions}
                        defaultOptions={campName}
                        value={selectedcampname}
                        isMulti
                        placeholder="Select Campaign"
                        isOptionDisabled={() => selectedcampname.length >= 1}
                        styles={colorStyles}
                        onChange={(event) => {
                          setselectedcampname(event);
                        }}
                      />
                    </div>
                    <Button
                      class="btn camp-report-gen responsiveViewCenter"
                      variant="contained"
                      onClick={getRewardsFromMarketplaceOnSearch}
                      className="btn save-btn"
                      disableElevation
                      style={{ marginLeft: "30px" }}
                    >
                      <i class="fa fa-search pr-2" aria-hidden="true"></i>
                      <span>Generate Impact Assessment</span>
                    </Button>
                  </form>
                </div>
              </div>
            </div>
            <header id="SOADashboardScreen">
              <form autoComplete="off">
                <div
                  style={{
                    border: "1px solid #dadce0",
                    borderRadius: 8,
                    backgroundColor: "#fff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  ></div>
                  <div
                    className="centerAlign"
                    style={{ padding: "16px 0", marginBottom: 50 }}
                  >
                    <br />
                    <FormGroupFull >
                      {!isLoading && campSubmissionlist.length === 0 ? (
                        "Please select a campaign and click on Generate Impact Assessment"
                      ) : isLoading ? (
                        <div>
                          <div>
                            <RotatingTriangles
                              type="TailSpin"
                              color="rgb(155, 236, 34)"
                              height={70}
                              width={70}
                              timeout={5000}
                              style={{ display: "inherit" }}
                              ariaLabel="three-circles-rotating"
                            />
                          </div>
                          <span><i>Please wait while your AI based report is being generated</i></span>
                        </div>
                      ) : (
                        <div>
                          <CampImpactComponent rows={campSubmissionlist} />
                        </div>
                      )}
                    </FormGroupFull>
                  </div>
                </div>
              </form>
            </header>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
}
export default SOACampaignImpact;
