import React from 'react';
import { Email as EmailIcon } from '@mui/icons-material';
import { Box, Typography, IconButton } from '@mui/material';
import ResponsiveDrawer from "../components/Header";
import TopHeaderScreen from "../components/TopHeader1";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { CopyRight } from "../components/CopyRight";
import { FixedFooterIcon } from "../components/FixedFooterIcon";

const ContactUs = (props) => {
    const navigate = useNavigate();
    return (
        <div className="wrapper">
            <ResponsiveDrawer {...props} />
            <section className="containers">
                <TopHeaderScreen {...props} />
                <div class="content content-wrap">
                    <div class="content-mrg">
                        <Button onClick={() => navigate(-1)} className="backWrapper">
                            <i className={`fa fa-caret-left`} />
                            <span style={{ paddingLeft: 3, fontSize: 14, color: "#1976d2" }}>
                                Back
                            </span>
                        </Button>
                        <Box
                            display="flex"
                            alignItems="center"
                            p={2}
                            border={1}
                            borderColor="grey.300"
                            borderRadius="8px"
                        >
                            <IconButton edge="start" color="primary">
                                <EmailIcon />
                            </IconButton>
                            <Typography variant="body1" ml={2}>
                                Please write to <a href="mailto:support@cyaag.com">support@cyaag.com</a> for any queries.
                            </Typography>
                        </Box>
                    </div>
                </div>
                <CopyRight />
        <FixedFooterIcon />
            </section>
        </div>
    );
};

export default ContactUs;
