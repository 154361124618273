import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import Messages from "../Messages/Messages";
import InfoBar from "../InfoBar/InfoBar";
import Input from "../Input/Input";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import ResponsiveDrawer from "../Header";
import { CopyRight } from "../CopyRight";
import TopHeaderScreen from "../TopHeader1";
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography } from "@mui/material";
import { TextContainerComponent } from "../Table";

import "./Chat.css";

const ENDPOINT = 'https://stagechatapi.cyaag.com';
let socket;

const Chat = ({ location, props }) => {
  const [name, setName] = useState("");
  const [room, setRoom] = useState("");
  const [users, setUsers] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const locationPath = useLocation();
  const navigate = useNavigate();
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const [count, setCount] = React.useState(0);

  useEffect(() => {
    setCount(count + 1);
    socket = io(ENDPOINT, {
      "force new connection": true,
      "reconnectionAttempts": "Infinity",
      "transports": ['websocket', 'polling', 'flashsocket'],
    });
    setName(profileResponse?.firstname);
    setRoom(locationPath?.pathname.split("/")?.[3]);
    if (name && room) {
      socket.emit("join", { name, room }, (error) => {
        if (error) {
          alert(error);
        }
      });
    }
  }, [profileSuccess]);

  useEffect(() => {
    socket.on("message", (message) => {
      setMessages((messages) => [...messages, message]);
    });
    socket.on("roomData", ({ users }) => {
      setUsers(users);
    });
  }, [room]);

  const sendMessage = (event) => {
    event.preventDefault();
    if (message) {
      socket.emit("sendMessage", message, () => setMessage(""));
    }
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <span style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5, display: "block" }}>
              cYAAG Chat
            </span>
            <div className="outerContainer">
              <div className="chatcontainer">
                <InfoBar room={room} />
                <Messages messages={messages} name={name} />
                <Input
                  message={message}
                  setMessage={setMessage}
                  sendMessage={sendMessage}
                />
              </div>
            </div>
            <div className="tableouterContainer">
              <div className="tablecontainer">
                <TextContainerComponent users={users} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Chat;
