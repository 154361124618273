// import * as React, {useRef} from "react";
import React from "react";
import {
  Avatar,
  Box,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";

import _ from "lodash";
import TopHeaderScreen from "../../components/TopHeader1";
import { useNavigate, useLocation } from "react-router-dom";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import { styled } from '@mui/material/styles';

const HomeScreenMainContent = styled(Box)(({ theme }) => ({
  padding: '80px 0 100px 0px',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    padding: '80px 0 100px 330px',
  },
})); 

const FormGroup0 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#F0F8FF',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// Styled component for formGroup00
const FormGroup00 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#FAEBD7',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

const FormGroup = styled('div')(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: '40px',
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));


const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup1
const FormGroup1 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup2
const FormGroup2 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  flexDirection: 'column',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '75px 0 0 16px',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup3
const FormGroup3 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  width: '20%',
  '@media (max-width: 1200px)': {
    width: '20%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup4
const FormGroup4 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'right',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 0',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup7
const FormGroup7 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup8
const FormGroup8 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledLabel
const StyledLabel = styled('label')(({ theme }) => ({
  display: 'block',
  background: '#e9e7e7',
  padding: '10px',
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontWeight: 600,
  marginTop: '16px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

// StyledSpan
const StyledSpan = styled('span')(() => ({
  fontSize: '12px',
}));

// FocusThematic
const FocusThematic = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledInput
const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// StyledTextarea
const StyledTextarea = styled('textarea')(() => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// LogoButton
const LogoButton = styled('input')(() => ({
  width: '8%',
  border: 'none',
  padding: '10px',
  display: 'flex',
  height: '40px',
  color: 'white',
  '@media (max-width: 1920px)': {
    fontSize: '15px',
    width: '12%',
  },
  '@media (max-width: 1280px)': {
    fontSize: '15px',
    width: '15%',
  },
  '@media (max-width: 960px)': {
    fontSize: '15px',
    width: '20%',
  },
  '@media (max-width: 600px)': {
    fontSize: '15px',
    width: '30%',
  },
}));

// StyledTypography
const StyledTypography = styled(Typography)(({ theme }) => ({
  '& .css-u4tvz2-MuiFormLabel-root': {
    fontFamily: 'Poppins',
  },
  display: 'block',
  display: 'flex',
  fontSize: '16px',
  marginTop: '16px',
  fontWeight: 500,
  lineHeight: '18px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

const LoginPgLogo = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (max-width: 768px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
}));

const StyledLargeAvatar = styled(Avatar)(({ theme }) => ({
  width: '200px !important',
  height: '200px !important',
  margin: '0 auto',
  marginTop: '5px',
  marginBottom: '5px',
  '@media (max-width: 400px)': {
    width: '120px !important',
    height: '120px !important',
  },
  '@media (max-width: 600px)': {
    width: '140px !important',
    height: '140px !important',
  },
  '@media (max-width: 768px)': {
    width: '150px !important',
    height: '150px !important',
  },
}));


function PFRewardsDetailReport(props) {
  const navigate = useNavigate();
  const locationPath = useLocation();
  const [pfrewards, setPFrewards] = React.useState(undefined);
  const [pfrewardsvalue, setPFrewardsValue] = React.useState(undefined);

  

  React.useEffect(() => {
    getPFRewardsReport();
  }, []);

  
  const getPFRewardsReport = async () => {
    try {
      const res = await axios.get(
        `/pa/pf/rewards/report/camp/${locationPath?.pathname.split("/")?.[4]}/${
          locationPath?.pathname.split("/")?.[5]
        }`
      );
    
      setPFrewards(res?.data);
      return res;
    } catch (err) {
     
      throw err;
    }
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Campaign Financial Tracking
            </div>
            <div
              class="content-title"
              style={{
                fontSize: 20,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
                textAlign: "center",
              }}
            ></div>

            <div style={{ fontFamily: "monospace", fontSize: 16 }}></div>
            <header id="SOADashboardScreen">
              <form autoComplete="off">
                <form autoComplete="off">
                  <div style={{ border: "2px solid #dadce0", borderRadius: 8, backgroundColor: "#fff", float: "left", width: "100%", marginBottom:"10px" }} >
                    <div style={{ display: "flex", flexDirection: "row",  flexWrap: "wrap", }} >
                      <FormGroup0>
                        <StyledLabel>
                          Total Camp Budget
                        </StyledLabel>
                        <StyledLabel
                        >
                          <i>(Total Budget)</i>
                        </StyledLabel>

                        <StyledInput
                          value={pfrewards?.[0]?.totalcampbudget}
                          type="text"
                           
                          placeholder="Your answer"
                          // maxLength="25"
                        />
                      </FormGroup0>

                      <FormGroup0>
                        <StyledLabel>
                          Platform Fees
                        </StyledLabel>
                        <StyledLabel
                        >
                          <i>(60% Total Budget)</i>
                        </StyledLabel>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <StyledInput
                            value={pfrewards?.[0]?.platformfees}
                            type="text"
                            id="create-yaad--title"
                             
                            placeholder="Your Answer"
                          />
                        </div>
                      </FormGroup0>

                      <FormGroup0>
                        <StyledLabel>
                          Campaign Fees 
                        </StyledLabel>
                        <StyledLabel
                        >
                          <i>(20% Total Budget)</i>
                        </StyledLabel>

                        <StyledInput
                          value={pfrewards?.[0]?.campaignexpensefees}
                          type="number"
                          id="create-yaad--title"
                           
                          placeholder="Your answer"
                        />
                      </FormGroup0>

                      <FormGroup0>
                        <StyledLabel>
                          Reward Budget 
                        </StyledLabel>
                        <StyledLabel
                        >
                          <i>(20% Total Budget)</i>
                        </StyledLabel>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <StyledInput
                            value={pfrewards?.[0]?.rewardbudget}
                            type="text"
                            id="create-yaad--title"
                             
                            placeholder="Your Answer"
                          />
                        </div>
                      </FormGroup0>
                    </div>
                  </div>
                  <div style={{ border: "2px solid #dadce0", borderRadius: 8, backgroundColor: "#FAEBD7", float: "left", width: "100%", marginBottom:"10px" }} >
                    <div style={{ display: "flex", flexDirection: "row",  flexWrap: "wrap", }} >
                     
                      <FormGroup00>
                        <StyledLabel>
                          Reward Budget 
                        </StyledLabel>
                        <StyledLabel
                        >
                          <i>(20% Total Budget)</i>
                        </StyledLabel>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <StyledInput
                            value={pfrewards?.[0]?.rewardbudget}
                            type="text"
                            id="create-yaad--title"
                             
                            placeholder="Your Answer"
                          />
                        </div>
                      </FormGroup00>
                     
                      <FormGroup00>
                        <StyledLabel>
                          Platform Reward Budget
                        </StyledLabel>
                        <StyledLabel
                        >
                          <i>(20% Reward Budget)</i>
                        </StyledLabel>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <StyledInput
                            value={pfrewards?.[0]?.platformreward}
                            type="text"
                            id="create-yaad--title"
                             
                            placeholder="Your Answer"
                          />
                        </div>
                      </FormGroup00>

                      <FormGroup00>
                        <StyledLabel>
                          Campaign Reward Budget 
                        </StyledLabel>
                        <StyledLabel
                        >
                          <i>(80% Reward Budget)</i>
                        </StyledLabel>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <StyledInput
                            value={pfrewards?.[0]?.campaignreward}
                            type="number"
                            id="create-yaad--title"
                             
                            placeholder="Your Answer"
                            min="0"
                          />
                        </div>
                      </FormGroup00>

                      <FormGroup00>
                        <StyledLabel>
                          Campaign Marketplace Budget
                        </StyledLabel>
                        <StyledLabel
                        >
                          <i>(20% Campaign Reward Budget )</i>
                        </StyledLabel>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <StyledInput
                            value={pfrewards?.[0]?.campaignmarketplacereward}
                            type="number"
                            id="create-yaad--title"
                             
                            placeholder="Your Answer"
                            min="0"
                          />
                        </div>
                      </FormGroup00>
                    </div>
                  </div>


                  <div style={{ border: "2px solid #dadce0", borderRadius: 8, backgroundColor: "#fff", float: "left", width: "100%", marginBottom:"10px" }} >
                    <div style={{ display: "flex", flexDirection: "row",  flexWrap: "wrap",backgroundColor: "#F0F8FF" }} >
                      
                    <FormGroup0>
                        <StyledLabel>
                          Campaign Marketplace Budget
                        </StyledLabel>
                        <StyledLabel
                        >
                          <i>(20% Campaign Reward Budget )</i>
                        </StyledLabel>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <StyledInput
                            value={pfrewards?.[0]?.campaignmarketplacereward}
                            type="number"
                            id="create-yaad--title"
                             
                            placeholder="Your Answer"
                            min="0"
                          />
                        </div>
                      </FormGroup0>

                      <FormGroup0>
                        <StyledLabel>
                          Campaign Marketplace Budget
                        </StyledLabel>
                        <StyledLabel
                        >
                          <i>(Unused)</i>
                        </StyledLabel>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <StyledInput
                            value={
                              pfrewards?.[0]?.campaignmarketplaceunusedreward
                            }
                            type="number"
                            id="create-yaad--title"
                             
                            placeholder="Your Answer"
                          />
                        </div>
                      </FormGroup0>
                    </div>
                  </div>


                  <div style={{ border: "2px solid #dadce0", borderRadius: 8, backgroundColor: "#fff", float: "left", width: "100%", marginBottom:"10px" }} >
                    <div style={{ display: "flex", flexDirection: "row",  flexWrap: "wrap", backgroundColor: "#F0F8FF"}} >

                      <FormGroup00>
                        <StyledLabel>
                          Campaign Action Budget
                        </StyledLabel>
                        <StyledLabel
                        >
                          <i>(80% Campaign Reward Budget )</i>
                        </StyledLabel>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <StyledInput
                            value={pfrewards?.[0]?.campaignactionreward}
                            type="number"
                            id="create-yaad--title"
                             
                            placeholder="Your Answer"
                          />
                        </div>
                      </FormGroup00>

                      <FormGroup00>
                        <StyledLabel>
                          Participate Budget 
                        </StyledLabel>
                        <StyledLabel
                        >
                            
                        </StyledLabel>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <StyledInput
                            value={pfrewards?.[0]?.participatebudgetpoints}
                            type="number"
                            id="create-yaad--title"
                             
                            placeholder="Your Answer"
                          />
                        </div>
                      </FormGroup00>

                      <FormGroup00>
                        <StyledLabel>
                          Referral Budget 
                        </StyledLabel>
                        <StyledLabel
                        >
                            
                        </StyledLabel>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <StyledInput
                            value={pfrewards?.[0]?.referralbudgetpoints}
                            type="number"
                            id="create-yaad--title"
                             
                            placeholder="Your Answer"
                          />
                        </div>
                      </FormGroup00>
                      <FormGroup00>
                        <StyledLabel>
                          Target Bonus Budget 
                        </StyledLabel>
                        <StyledLabel
                        >
                            
                        </StyledLabel>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <StyledInput
                            value={pfrewards?.[0]?.targetbonuspoints}
                            type="number"
                            id="create-yaad--title"
                             
                            placeholder="Your Answer"
                          />
                        </div>
                      </FormGroup00>
                    </div>
                  </div>


                  <div style={{ border: "2px solid #dadce0", borderRadius: 8, backgroundColor: "#fff", float: "left", width: "100%", marginBottom:"10px" }} >
                    <div style={{ display: "flex", flexDirection: "row",  flexWrap: "wrap", backgroundColor: "#F0F8FF"}} >

                      <FormGroup0>
                        <StyledLabel>
                          Campaign Action Budget
                        </StyledLabel>
                        <StyledLabel
                        >
                          <i>(Unused)</i>
                        </StyledLabel>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <StyledInput
                            value={pfrewards?.[0]?.campaigninactionunusedreward}
                            type="number"
                            id="create-yaad--title"
                             
                            placeholder="Your Answer"
                          />
                        </div>
                      </FormGroup0>

                      <FormGroup0>
                        <StyledLabel>
                          Participate Budget 
                        </StyledLabel>
                        <StyledLabel
                        >
                          <i>(Unused)</i>
                        </StyledLabel>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <StyledInput
                            value={pfrewards?.[0]?.participatebudgetunused}
                            type="number"
                            id="create-yaad--title"
                             
                            placeholder="Your Answer"
                          />
                        </div>
                      </FormGroup0>

                      <FormGroup0>
                        <StyledLabel>
                          Referral Budget 
                        </StyledLabel>
                        <StyledLabel
                        >
                          <i>(Unused)</i>
                        </StyledLabel>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <StyledInput
                            value={pfrewards?.[0]?.referralbudgetsunused}
                            type="number"
                            id="create-yaad--title"
                             
                            placeholder="Your Answer"
                          />
                        </div>
                      </FormGroup0>
                      <FormGroup0>
                        <StyledLabel>
                          Target Bonus Budget 
                        </StyledLabel>
                        <StyledLabel>
                          <i>(Unused)</i>
                        </StyledLabel>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <StyledInput
                            value={pfrewards?.[0]?.targetbonusbudgetsunused}
                            type="number"
                            id="create-yaad--title"
                             
                            placeholder="Your Answer"
                          />
                        </div>
                      </FormGroup0>
                    </div>
                  </div>

                  
                </form>
              </form>
            </header>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
}
export default PFRewardsDetailReport;
