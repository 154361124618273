import React, { useState, useEffect, useRef } from 'react';
import { Box, Avatar, Typography, Button, Grid, Paper, Card, CardContent, CardMedia, useTheme, useMediaQuery, CircularProgress, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FaBriefcase, FaRegEdit } from 'react-icons/fa';
import SVG1 from '../../icons/SVG1';
import SVG2 from '../../icons/SVG2';
import SVG3 from '../../icons/SVG3';
import SVG4 from '../../icons/SVG4';
import SVG5 from '../../icons/SVG5';
import SVG6 from '../../icons/SVG6';
import SVG7 from '../../icons/SVG7';
import SVGRedeemed from '../../icons/SVGRedeemed';
import SVGOrgRank from '../../icons/SVGOrgRank';
import SVGActiveCampReg from '../../icons/SVGActiveCampReg';
import SVGActiveCampUnReg from '../../icons/SVGActiveCampUnReg';
import SVGProfileDefaultBanner from '../../icons/SVGProfileDefaultBanner';
import axios from 'axios';
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import isEmpty from 'lodash/isEmpty';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from 'moment';
import ResponsiveDrawer from "../../components/Header";
import TopHeaderScreen from "../../components/TopHeader1";
import { CopyRight } from "../../components/CopyRight";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import Login from "../../assets/Login.png";
import styled from 'styled-components';
import PhoneIcon from '@mui/icons-material/Phone';
import UserEmailIcon from '@mui/icons-material/Email';
import WorkIcon from '@mui/icons-material/Work';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Delete as DeleteIcon } from '@mui/icons-material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import TeamLogoModal from "../Teams/TeamLogoModal";
import DashboardIcon from '@mui/icons-material/Dashboard';
import LaunchIcon from '@mui/icons-material/Launch';
import jsPDF from "jspdf";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import html2canvas from "html2canvas";
import ShareIcon from "@mui/icons-material/Share";
import { Audio, Circles } from "react-loader-spinner";

const LoginPgLogo = styled('div')({
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    background: '#f6f6f6',
    '@media (min-width: 960px)': {
        width: '100%',
        background: '#f6f6f6',
    },
});
const Banner = styled(Box)(({ theme }) => ({
    height: '200px',
    backgroundColor: 'orange',
    position: 'relative',
    '@media (max-width: 600px)': {
        height: '100px',
    },
}));

const ProfileNavigationS = styled('div')({
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // marginTop: '40px',
    // marginBottom: '20px',
    // marginTop: '-152px',
    // marginBottom: '101px',
    // marginRight: '344px',
    // right: '840px',
    // left: '634px',
    // top: '424px',
    top: '50%',
    left: '50%',
    bottom: '50%',
    right: '50%',
    // bottom: '350px',
    transform: 'translate(-50%, -50%)',

    '@media (max-width: 600px)': {
        // position: 'relative',
        // marginBottom: '0px',
        // marginRight: '0px',
        // marginTop: '44px',
        right: '0px',
        left: '0px',
        top: '330px',
        bottom: '0px',
    },
});

const ProfileToggleS = styled('div')({
    position: 'fixed',
    width: '40px',
    height: '40px',
    backgroundColor: '#fff',
    borderRadius: '70px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 0px 15px rgba(0, 0, 0, 0.15)',
    zIndex: 2,

    '&.active::before': {
        transform: 'rotate(315deg)',
    },
});

const MenuS = styled('div')(({ active }) => ({
    position: 'absolute',
    // height: '14px',
    top: active ? '0px' : '50px',
    height: '80px',
    borderRadius: '70px',
    zIndex: 0,
    transform: active ? 'translateY(-80px)' : 'translateY(0)',
    transition: 'transform 0.5s, width 0.5s, height 0.5s',

    'ul': {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // height: '90px',
        height: '90px',
        gap: '30px',
        padding: 'unset',
    },

    'ul li, ul button': {
        listStyle: 'none',
        cursor: 'pointer',
        opacity: active ? '1' : '0',
        visibility: active ? 'visible' : 'hidden',
        transform: active ? 'translateY(0px)' : 'translateY(-30px)',
        transition: '0.25s',
        transitionDelay: 'calc(0s + var(--i))',
    },

    'ul li a': {
        display: 'block',
        fontSize: '2.2em',
    },
}));

const SocialProfile = (props) => {
    const [loading, setLoading] = useState(true);
    const [myTeamData, setMyTeamData] = useState([]);
    const [searchedUser, setSearchedUser] = React.useState("");
    const [activityList, setActivityList] = React.useState("");
    const [mycommunity, setmycommunity] = React.useState([]);
    const [userDashboardData, setUserDashboardData] = React.useState([]);
    const [myOrgRank, setMyOrgRank] = React.useState([]);
    const [bannerImage, setBannerImage] = useState('');
    // const [bannerImage, setBannerImage] = useState(SVGProfileDefaultBanner);
    const [openDialog, setOpenDialog] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [logo, setLogo] = useState('');
    const [level, setLevel] = React.useState("");
    const [nextLevel, setnextLevel] = React.useState("");

    const topRef = useRef(null);
    const locationPath = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    // const isMobile = useMediaQuery('(max-width:600px)');

    const [menuActive, setMenuActive] = useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const toggleMenu = () => {
        console.log("ShareIcon clicked"); // Log to check if the click is registered
        setMenuActive((prev) => {
            console.log("menuActive state before:", prev);
            return !prev;
        });
    };

    var browserurl1 = window.location.href;
    const browserurl = browserurl1.replace("newprofile", "cm");
    useEffect(() => {
        if (topRef.current) {
            topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [isLoading]);
    const formatNumber = (number) => {
        if (number >= 1_000_000) {
            return `${(number / 1_000_000).toFixed(1)}M`;
        } else if (number >= 1_000) {
            return `${(number / 1_000).toFixed(1)}k`;
        } else {
            return number.toString();
        }
    };

    const settings = {
        dots: true,
        infinite: false, // Disable infinite scrolling
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
        (state) => state.profile
    );


    useEffect(() => {
        const style = document.createElement('style');
        style.innerHTML = `
          #root {
            background-color: transparent !important; /* Override background color */
          }
        `;
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };
    }, []);

    const getSearchedUserProfile = async () => {
        setIsLoading(true);
        try {
            const id = locationPath?.pathname.split("/")?.[2];

            if (id === undefined) {
                const res = await axios.get(
                    `/profile/user/${localStorage.getItem("userid")}`
                );
                setSearchedUser(res?.data);
                return res;
            } else {
                const res = await axios.get(
                    `/profile/user/${locationPath?.pathname.split("/")?.[2]}`
                );
                setSearchedUser(res?.data);
                setBannerImage(res?.data?.[0]?.banner)
                return res;
            }
        } catch (err) {
            throw err;
        } finally {
            setIsLoading(false);
        }
    };

    React.useEffect(() => {
        getSearchedUserProfile();
    }, [locationPath?.pathname.split("/")?.[2]]);

    React.useEffect(() => {
        getSearchedUserProfile();
    }, [profileResponse?.profileurl, profileResponse?.firstname, profileResponse?.lastname, logo]);

    const getActivityList = async () => {
        try {
            const id = locationPath?.pathname.split("/")?.[2];
            if (id === undefined) {
                const res = await axios.get(
                    `/activity/user/${localStorage.getItem("userid")}`
                );
                setActivityList(res?.data);
                return res;
            } else {
                const res = await axios.get(
                    `/activity/user/${locationPath?.pathname.split("/")?.[2]}`
                );
                setActivityList(res?.data);
                return res;
            }
        } catch (err) {
            throw err;
        }
    };

    React.useEffect(() => {
        getActivityList();
    }, [locationPath?.pathname.split("/")?.[2]]);

    const getMyCommunityDetails = async () => {
        try {
            const id = locationPath?.pathname.split("/")?.[2];
            if (id === undefined) {
                const res = await axios.get(
                    `adopter_dashboard/mycommunity/${localStorage.getItem("userid")}`
                );
                return res;
            } else {
                const res = await axios.get(
                    `adopter_dashboard/mycommunity/${locationPath?.pathname.split("/")?.[2]
                    }`
                );
                return res;
            }
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        getMyCommunityDetails().then((data) => {
            setmycommunity(data);
        });
    }, []);

    const fetchMyTeam = async () => {
        try {
            const id = locationPath?.pathname.split("/")?.[2];
            console.log(`/teams/my/team/${localStorage.getItem("userid")}`)
            setLoading(false);

            if (id === undefined) {
                const response = await axios.get(`/teams/my/team/${localStorage.getItem("userid")}`);
                setMyTeamData(response?.data)
                return response;
            } else {
                const response = await axios.get(`/teams/my/team/${locationPath?.pathname.split("/")?.[2]}`);
                setMyTeamData(response?.data)
                return response;
            }
        } catch (error) {
            console.error('Error fetching my team', error);
        }
    };

    React.useEffect(() => {
        fetchMyTeam();
    }, [locationPath?.pathname.split("/")?.[2]]);

    const getUserDashboard = async () => {
        try {
            const id = locationPath?.pathname.split("/")?.[2];
            setLoading(false);
            if (id === undefined) {
                const response = await axios.get(`/profile/user/dashboard/${localStorage.getItem("userid")}`);
                setUserDashboardData(response?.data)
                return response;
            } else {
                const response = await axios.get(`/profile/user/dashboard/${locationPath?.pathname.split("/")?.[2]}`);
                setUserDashboardData(response?.data)
                return response;
            }
        } catch (error) {
            console.error('Error fetching my team', error);
        }
    };

    React.useEffect(() => {
        getUserDashboard();
    }, [locationPath?.pathname.split("/")?.[2]]);

    console.log(`/my/org/rank/${localStorage.getItem("userid")}`)
    const getMyOrgRank = async () => {
        try {
            const id = locationPath?.pathname.split("/")?.[2];
            if (id === undefined) {
                const res = await axios.get(`/my/org/rank/${localStorage.getItem("userid")}`);
                setMyOrgRank(res?.data);
            } else {
                const res = await axios.get(`/my/org/rank/${locationPath?.pathname.split("/")?.[2]}`);
                setMyOrgRank(res?.data);
            }
        } catch (err) {
            throw err;
        }
    };

    React.useEffect(() => {
        getMyOrgRank();
    }, [locationPath?.pathname.split("/")?.[2]]);

    useEffect(() => {
        if (mycommunity?.data) {
            if (mycommunity?.data?.mylevel?.level === "0") {
                setLevel("Starter");
                setnextLevel("Bronze");
            } else if (mycommunity?.data?.mylevel?.level === "1") {
                setLevel("Bronze");
                setnextLevel("Silver");
            } else if (mycommunity?.data?.mylevel?.level === "2") {
                setLevel("Silver");
                setnextLevel("Gold");
            } else if (mycommunity?.data?.mylevel?.level === "3") {
                setLevel("Gold");
                setnextLevel("Diamond");
            } else if (mycommunity?.data?.mylevel?.level === "4") {
                setLevel("Diamond");
                setnextLevel("Platinum");
            } else if (mycommunity?.data?.mylevel?.level === "5") {
                setLevel("Platinum");
            }
        }
    }, [mycommunity?.data]);

    const triggerFileInput = () => {
        document.getElementById('upload-banner').click();
    };

    const handleBannerUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('bannerlogo', file);
            try {
                const response = await axios.post(`/cyaaguser/upload/banner/${localStorage.getItem('userid')}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                const imageUrl = response.data.banner;
                console.log("imageUrl = ", imageUrl);
                setBannerImage(imageUrl);
            } catch (error) {
                console.error('Error uploading user profile banner', error);
            }
        }
    };

    const handleLogoChange = async (newLogo) => {
        const formData = new FormData();
        formData.append('profilephoto', newLogo);
        const response = await axios.post(`/user/profile/image/${profileResponse?.phoneno}`, formData);
        console.log("response.data = ", response.data)
        setLogo(URL.createObjectURL(newLogo));

    };

    const handleDeleteBanner = async () => {
        try {
            await axios.delete(`/cyaaguser/delete/banner/${localStorage.getItem('userid')}`);
            setBannerImage(''); // Reset to default
        } catch (error) {
            console.error('Error deleting user profile banner', error);
        }
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDelete = () => {
        handleDeleteBanner();
        handleCloseDialog();
    };

    const renderUnderline = (value) => {
        const valueStr = value ? String(value) : '0';
        return (
            <Box
                component="div"
                style={{
                    width: '80%',
                    borderBottom: `2px solid black`,
                    height: '0',
                    marginTop: '4px',
                    marginLeft: '12px'
                }}
            >
                <Box
                    component="div"
                    style={{
                        width: `${(valueStr.length / 10) * 100}%`,
                        borderBottom: `2px solid black`,
                        height: '0',
                    }}
                />
            </Box>
        );
    };

    const skills = Array.isArray(searchedUser?.[0]?.skill) ? searchedUser[0].skill : [];
    const interestAreas = Array.isArray(searchedUser?.[0]?.interestarea) ? searchedUser[0].interestarea : [];

    const handleMetricsClick = () => {
        navigate('/cm/insights', { state: { phoneno: profileResponse?.phoneno } });
    };

    function truncateName(name, maxLength) {
        if (name.length > maxLength) {
            return name.substring(0, maxLength) + "...";
        }
        return name;
    }
    return (
        <div className="wrapper" ref={topRef}>
            <ResponsiveDrawer {...props} />
            <section className="containers" style={{ backgroundColor: '' }}>
                <TopHeaderScreen {...props} />
                <div class="content content-wrap" >
                    <div class="content-mrg" >
                        <Button onClick={() => navigate(-1)} className="backWrapper">
                            <i className={`fa fa-caret-left`} />
                            <span style={{ paddingLeft: 3, fontSize: 14, color: "#1976d2" }}>
                                Back
                            </span>
                        </Button>


                        {
                        isLoading ? (
                              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
                                <Circles
                                  type="TailSpin"
                                  color="rgb(155, 236, 34)"
                                  height={70}
                                  width={70}
                                  timeout={5000}
                                  ariaLabel="three-circles-rotating"
                                />
                              </div>
                            ) : (




                        <Box
                            className="fullWidthContent"
                            maxWidth="1200px"
                            margin="0px auto"
                            padding={isMobile ? 2 : 6}
                            maxHeight={isMobile ? '620vh' : '500vh'}
                            minHeight={isMobile ? '530vh' : '200vh'}
                            background='none'
                        >
                            <Paper elevation={3} style={{ position: 'relative', marginBottom: theme.spacing(3), height: 'auto' }}>
                                <Banner>
                                    {
                                        bannerImage ? (
                                            <img src={bannerImage} alt="Profile Banner" style={{ width: '100%', height: '100%' }} />
                                        ) : (
                                            <SVGProfileDefaultBanner width={isMobile ? '100%' : '100%'} height={isMobile ? 'auto' : '100%'} />
                                        )
                                    }
                                    <input
                                        type="file"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        id="upload-banner"
                                        onChange={handleBannerUpload}
                                    />
                                    <label htmlFor="upload-banner">
                                        {searchedUser?.[0]?.phoneno === localStorage.getItem("userid") &&
                                            <IconButton variant="contained" style={{ position: 'absolute', top: 10, right: 10, backgroundColor: '#1976d2', color: 'white' }} onClick={triggerFileInput}>
                                                <CameraAltOutlinedIcon />
                                            </IconButton>
                                        }
                                    </label>
                                    {searchedUser?.[0]?.phoneno === localStorage.getItem("userid") &&
                                        bannerImage && (
                                            <IconButton onClick={handleOpenDialog} style={{ position: 'absolute', top: 10, right: 80, backgroundColor: 'red', color: 'silver' }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    <Dialog open={openDialog} onClose={handleCloseDialog}>
                                        <DialogTitle>{"Confirm Delete"}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                Are you sure you want to delete this banner?
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleCloseDialog} color="primary">
                                                Cancel
                                            </Button>
                                            <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                                                Confirm
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <Avatar
                                        src={searchedUser?.[0]?.profilephoto}
                                        alt="Profile Image"
                                        sx={{
                                            width: isMobile ? 110 : 120,
                                            height: isMobile ? 110 : 120,
                                            border: '5px solid white',
                                            marginRight: isMobile ? 0 : 2,
                                            position: 'absolute',
                                            top: isMobile ? '50px' : '130px',
                                            left: isMobile ? 'calc(50% - 60px)' : '2%',

                                        }}
                                        // onClick={() => setModalOpen(true)} style={{ cursor: 'pointer' }}
                                        onClick={
                                            searchedUser?.[0]?.phoneno === localStorage.getItem("userid") ? () => setModalOpen(true) : undefined
                                        }
                                        style={searchedUser?.[0]?.phoneno === localStorage.getItem("userid") ? { cursor: 'pointer' } : {}}
                                    />
                                </Banner>
                                <TeamLogoModal
                                    open={modalOpen}
                                    handleClose={() => setModalOpen(false)}
                                    handleLogoChange={handleLogoChange}
                                    // teamLogo={logo}
                                    teamLogo={logo}
                                    teamid={""}
                                />
                                <Box textAlign={isMobile ? 'center' : 'left'} mt={isMobile ? 7 : 6} ml={isMobile ? '2%' : '2%'}>
                                    {
                                        searchedUser?.[0]?.phoneno === localStorage.getItem("userid") ?
                                            <Typography variant={isMobile ? "h5" : "h4"}>
                                                {truncateName(`${searchedUser?.[0]?.firstname} ${searchedUser?.[0]?.lastname}`, isMobile ? 15 : Infinity)}
                                                <Button
                                                    startIcon={<EditIcon />}
                                                    component={Link}
                                                    to={`/edit/user/profile/personal`}
                                                />
                                                <div className="shareIconWrapper" style={{ display: "inline-block" }}>
                                                    <div class="text-center mob-space">
                                                        <div class="profilenavigationS">
                                                            <div class="profileToggleS" onClick={toggleMenu} >
                                                                <ShareIcon style={{ color: "orange" }} />
                                                            </div>
                                                            <MenuS active={menuActive}>
                                                                <ul style={{left: isMobile? '0px':'-89px'}}>
                                                                    <li>
                                                                        <WhatsappShareButton url={`Hello!\nPlease see my SDP cYAAG Profile\n${browserurl}`}>
                                                                            <WhatsappIcon size={32} round={true} />
                                                                        </WhatsappShareButton>
                                                                    </li>
                                                                    <li>
                                                                        <FacebookShareButton url={`Hello!\nPlease see my SDP cYAAG Profile\n${browserurl}`} hashtag="#cyaag">
                                                                            <FacebookIcon size={32} round={true} />
                                                                        </FacebookShareButton>
                                                                    </li>
                                                                    <li>
                                                                        <EmailShareButton url="" body={`Hello!\nPlease see my SDP cYAAG Profile\n${browserurl}`} subject="My SDP cYAAG Profile">
                                                                            <EmailIcon size={32} round={true} />
                                                                        </EmailShareButton>
                                                                    </li>
                                                                    <li>
                                                                        <TwitterShareButton url={`Hello!\nPlease see my SDP cYAAG Profile\n${browserurl}`} title="Hello">
                                                                            <TwitterIcon size={32} round={true} />
                                                                        </TwitterShareButton>
                                                                    </li>
                                                                </ul>
                                                            </MenuS>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Typography>
                                            :
                                            <Typography variant={isMobile ? "h5" : "h4"}>
                                                {truncateName(`${searchedUser?.[0]?.firstname} ${searchedUser?.[0]?.lastname}`, isMobile ? 15 : Infinity)}
                                            </Typography>
                                    }
                                    <Box display="flex" alignItems="center">
                                        <WorkIcon fontSize="small" style={{ marginRight: theme.spacing(1) }} />
                                        <Typography variant="subtitle1" color="textSecondary">
                                            {searchedUser?.[0]?.orgrole}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="flex-start">
                                        <BusinessIcon fontSize="small" style={{ marginRight: theme.spacing(1) }} />
                                        <Typography variant="subtitle2" color="textSecondary" style={{textAlign:"left"}}>
                                            {searchedUser?.[0]?.organization}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="flex-start">
                                        <LocationOnIcon fontSize="small" style={{ marginRight: theme.spacing(1) }} />
                                        <Typography variant="subtitle2" color="textSecondary" style={{textAlign:"left"}}>
                                            {searchedUser?.[0]?.country} {" "} {"|"}{" "} {searchedUser?.[0]?.state}
                                        </Typography>
                                    </Box>
                                    {
                                        searchedUser?.[0]?.phoneno === localStorage.getItem("userid") &&
                                    <Box display="flex" alignItems="center">
                                        <PhoneIcon fontSize="small" style={{ marginRight: theme.spacing(1) }} />
                                        <Typography variant="body2" color="textSecondary">
                                            {searchedUser?.[0]?.phoneno}
                                        </Typography>
                                    </Box>
}
{
                                    searchedUser?.[0]?.phoneno === localStorage.getItem("userid") &&
                                    <Box display="flex" alignItems="flex-start" >
                                        <UserEmailIcon fontSize="small" style={{ marginRight: theme.spacing(1) }} />
                                        <Typography variant="body2" color="textSecondary" style={{textAlign:"left",wordWrap: "break-word",wordBreak: "break-all"}}>
                                            {searchedUser?.[0]?.emailid}
                                        </Typography>
                                    </Box>
}
                                    <br />
                                </Box>
                            </Paper>
                            <Paper elevation={3} style={{ padding: theme.spacing(3), marginBottom: theme.spacing(3) }}>
                                <Typography variant="h6" gutterBottom>
                                    Expertise
                                    {searchedUser?.[0]?.phoneno === localStorage.getItem("userid") &&
                                        <Button
                                            startIcon={<EditIcon />}
                                            component={Link}
                                            to={`/edit/user/profile/expertise`}
                                        />
                                    }
                                </Typography>
                                <Typography sx={{ textAlign: 'justify', wordBreak: "break-all" }}>
                                    {searchedUser?.[0]?.expertise}
                                </Typography>
                            </Paper>

                            {/* Grid Container for two columns */}
                            <Grid container spacing={3}>
                                {/* Column 1 */}
                                {/* <Grid item xs={12} sm={6} md={6} style={{ flex: '0 0 65%', maxWidth: '65%' }}> */}
                                <Grid className='columnFirst' item xs={12} sm={6} md={12} sx={{ flex: { xs: 'none', sm: 'none', md: '0 0 65%' }, maxWidth: { xs: '100%', sm: '100%', md: '65%' } }}>
                                    <Grid container spacing={3}>
                                        {/* Row 1: Current Points & Overall Rank */}
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Card style={{ borderRadius: 16, border: '2px solid #ccc', boxShadow: 'none', overflow: 'hidden' }}>
                                                <Grid container>
                                                    <Grid item xs={4} display="flex" alignItems="center" justifyContent="center">
                                                        <CardMedia
                                                            component="div"
                                                            style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                        >
                                                            <SVG1 />
                                                        </CardMedia>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <CardContent>
                                                            <Typography variant="h6" gutterBottom style={{ borderRadius: '10px', backgroundColor: '#EAF3FD', padding: '10px 10px', width: '50%', textAlign: 'center', fontSize: '25px' }}>
                                                                {mycommunity?.data?.mylevel?.rewardpoint}
                                                            </Typography>
                                                            <Typography variant="body2" color="textSecondary" style={{ fontWeight: 700 }}>
                                                                Current Points
                                                            </Typography>
                                                        </CardContent>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <Card style={{ borderRadius: 16, border: '2px solid #ccc', boxShadow: 'none', overflow: 'hidden' }}>
                                                <Grid container>
                                                    <Grid item xs={4} display="flex" alignItems="center" justifyContent="center">
                                                        <CardMedia
                                                            component="div"
                                                            style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                        >
                                                            <SVG2 />
                                                        </CardMedia>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <CardContent >
                                                            <Typography variant="h6" gutterBottom style={{ borderRadius: '10px', backgroundColor: '#EAF3FD', padding: '10px 10px', width: '50%', textAlign: 'center', fontSize: '24px' }}>
                                                                {mycommunity?.data?.myrankpoints?.myrank}
                                                            </Typography>
                                                            <Typography variant="body2" color="textSecondary" style={{ fontWeight: 700 }}>
                                                                Overall Rank
                                                            </Typography>
                                                        </CardContent>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </Grid>

                                        {/* Row 2: Network & Dashboard */}
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Paper style={{ backgroundColor: 'white', padding: 16, height: '100%', maxHeight: '240px' }}>
                                                <Card style={{ borderRadius: 16, backgroundColor: '#D8EFCD', boxShadow: 'none', overflow: 'hidden', padding: '15px 10px' }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6} style={{ maxWidth: '200px', overflow: 'hidden',flexDirection: 'row', display: 'flex'}}>
                                                            <Typography variant="h6" style={{ marginBottom: '16px' }}>Network</Typography>
                                                            {
                                                                searchedUser?.[0]?.phoneno === localStorage.getItem("userid") &&
                                                                <LaunchIcon style={{ marginLeft: '5px', marginTop: "2px", cursor: 'pointer', color: '#566F55' }} onClick={() => navigate(`/mycommunity/${searchedUser?.[0]?.phoneno}`)
                                                                    } />
                                                            }
                                                        </Grid>
                                                        
                                                        <Grid item xs={6} style={{ textAlign: 'center' }}>
                                                            <SVG3 />
                                                        </Grid>
                                                        <Grid item xs={6} style={{ maxWidth: '200px', overflow: 'hidden', height: '64px' }}>
                                                            <Typography variant="subtitle2" style={{ marginBottom: '16px', textAlign: 'center' }}>Community Size</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} style={{ maxWidth: '200px', overflow: 'hidden', height: '64px' }}>
                                                            <Typography variant="subtitle2" style={{ marginBottom: '16px', textAlign: 'center' }}>Community Points</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} style={{ maxWidth: '200px', overflow: 'hidden' }}>
                                                            <Typography variant="body1" style={{ textAlign: 'center', fontWeight: 700, color: '#01539D', fontSize: '24px' }}>{mycommunity?.data?.refcount}</Typography>
                                                            {renderUnderline(mycommunity?.data?.refcount)}
                                                        </Grid>
                                                        <Grid item xs={6} style={{ maxWidth: '200px', overflow: 'hidden' }}>
                                                            <Typography variant="body1" style={{ textAlign: 'center', fontWeight: 700, color: '#01539D', fontSize: '24px' }}>{mycommunity?.data?.rewardpoint}</Typography>
                                                            {renderUnderline(mycommunity?.data?.rewardpoint)}
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Paper style={{ backgroundColor: 'white', padding: 16, height: '100%', maxHeight: '240px' }}>
                                                <Card style={{ borderRadius: 16, backgroundColor: '#F4F2CD', boxShadow: 'none', overflow: 'hidden', padding: '15px 10px' }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6} style={{ maxWidth: '200px', overflow: 'hidden' }}>
                                                            <Typography variant="h6" style={{ marginBottom: '16px', flexDirection: 'row', display: 'flex' }}>
                                                                Metrics
                                                                {/* <DashboardIcon style={{ marginRight: '8px', cursor: 'pointer' }} onClick={handleMetricsClick} /> */}
                                                                {
                                                                    searchedUser?.[0]?.phoneno === localStorage.getItem("userid") &&
                                                                    <LaunchIcon style={{ marginLeft: '5px', marginTop: "2px", cursor: 'pointer', color: '#db8d0f' }} onClick={handleMetricsClick} />
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6} style={{ textAlign: 'center' }}>
                                                            <SVG4 />
                                                        </Grid>
                                                        <Grid item xs={6} style={{ maxWidth: '200px', overflow: 'hidden', height: '64px' }}>
                                                            <Typography variant="subtitle2" style={{ marginBottom: '16px', textAlign: 'center' }}>
                                                                Campaigns registered
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6} style={{ maxWidth: '200px', overflow: 'hidden', height: '64px' }}>
                                                            <Typography variant="subtitle2" style={{ marginBottom: '16px', textAlign: 'center' }}>
                                                                Rewards won
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6} style={{ maxWidth: '200px', overflow: 'hidden' }}>
                                                            <Typography variant="body1" style={{ textAlign: 'center', fontWeight: 700, color: '#01539D', fontSize: '24px' }}>{userDashboardData?.[0]?.campsregistered}</Typography>
                                                            {renderUnderline(userDashboardData?.[0]?.campsregistered)}
                                                        </Grid>
                                                        <Grid item xs={6} style={{ maxWidth: '200px', overflow: 'hidden' }}>
                                                            <Typography variant="body1" style={{ textAlign: 'center', fontWeight: 700, color: '#01539D', fontSize: '24px' }}>{userDashboardData?.[0]?.rewardswon}</Typography>
                                                            {renderUnderline(userDashboardData?.[0]?.rewardswon)}
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Paper>
                                        </Grid>

                                        {/* Row 3: Skills & Interest Areas */}
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Paper style={{ backgroundColor: 'white', padding: 16, maxHeight: '228px', minHeight: '228px' }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Box display="flex" alignItems="center" style={{ marginBottom: '16px' }}>
                                                            <Typography variant="h6">
                                                                Skills

                                                            </Typography>
                                                            <Typography
                                                                variant="h6"
                                                                gutterBottom
                                                                style={{
                                                                    borderRadius: '10px',
                                                                    backgroundColor: '#EAF3FD',
                                                                    padding: '10px 10px',
                                                                    marginLeft: '16px',
                                                                    textAlign: 'center',
                                                                    fontSize: '25px',
                                                                }}
                                                            >
                                                                {skills.length}

                                                            </Typography>
                                                            {searchedUser?.[0]?.phoneno === localStorage.getItem("userid") &&
                                                                <Button
                                                                    startIcon={<EditIcon />}
                                                                    component={Link}
                                                                    to={`/edit/user/profile/skills`}
                                                                />
                                                            }
                                                        </Box>
                                                    </Grid>
                                                    {/* <Grid item xs={12} style={skills.length > 2 ? { maxHeight: '200px', overflowY: 'auto' } : {}}> */}
                                                    <Grid item xs={12} style={{ maxHeight: '120px', overflowY: 'auto' }}>
                                                        {!isEmpty(skills) ? (
                                                            skills.map((item, index) => (
                                                                <Box key={index} style={{ position: 'relative', marginBottom: '24px' }}>
                                                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Box
                                                                            style={{
                                                                                width: 24,
                                                                                height: 24,
                                                                                borderRadius: '50%',
                                                                                backgroundColor: 'pink',
                                                                                color: 'white',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                marginRight: '8px',
                                                                            }}
                                                                        >
                                                                            {index + 1}
                                                                        </Box>
                                                                        <Box style={{ marginLeft: '8px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                            <Typography style={{ fontSize: '12px' }}>{item?.skill}</Typography>
                                                                        </Box>
                                                                        {index < skills.length - 1 && (
                                                                            <Box
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    left: '10px',
                                                                                    top: '24px',
                                                                                    bottom: '-24px',
                                                                                    width: '2px',
                                                                                    backgroundColor: '#000',
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                            ))
                                                        ) : (
                                                            <Typography variant="body1">No Skills Listed</Typography>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <Paper style={{ backgroundColor: 'white', padding: 16, maxHeight: '228px', minHeight: '228px' }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Box display="flex" alignItems="center" style={{ marginBottom: '16px' }}>
                                                            <Typography variant="h6">Interest Areas</Typography>
                                                            <Typography
                                                                variant="h6"
                                                                gutterBottom
                                                                style={{
                                                                    borderRadius: '10px',
                                                                    backgroundColor: '#EAF3FD',
                                                                    padding: '10px 10px',
                                                                    marginLeft: '16px',
                                                                    textAlign: 'center',
                                                                    fontSize: '25px',
                                                                }}
                                                            >
                                                                {interestAreas.length}
                                                            </Typography>
                                                            {searchedUser?.[0]?.phoneno === localStorage.getItem("userid") &&
                                                                <Button
                                                                    startIcon={<EditIcon />}
                                                                    component={Link}
                                                                    to={`/edit/user/profile/interestareas`}
                                                                />
                                                            }
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ maxHeight: '120px', overflowY: 'auto' }}>
                                                        {!isEmpty(interestAreas) ? (
                                                            interestAreas.map((item, index) => (
                                                                <Box key={index} style={{ position: 'relative', marginBottom: '24px' }}>
                                                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Box
                                                                            style={{
                                                                                width: 24,
                                                                                height: 24,
                                                                                borderRadius: '50%',
                                                                                backgroundColor: 'pink',
                                                                                color: 'white',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                marginRight: '8px',
                                                                            }}
                                                                        >
                                                                            {index + 1}
                                                                        </Box>
                                                                        <Box style={{ marginLeft: '8px', width: 'calc(100% - 40px)' }}>
                                                                            <Typography style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '12px' }} title={item?.interest}>{item?.interest}</Typography>
                                                                        </Box>
                                                                        {index < interestAreas.length - 1 && (
                                                                            <Box
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    left: '12px',
                                                                                    top: '24px',
                                                                                    bottom: '-24px',
                                                                                    width: '2px',
                                                                                    backgroundColor: '#000',
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                            ))
                                                        ) : (
                                                            <Typography variant="body1">No Interest Areas Listed</Typography>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                        {
                                            searchedUser?.[0]?.phoneno === localStorage.getItem("userid") ? (
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Paper style={{ backgroundColor: 'white', padding: 16, height: '100%', minHeight: '215px', }}>
                                                        <Card style={{ borderRadius: 16, backgroundColor: '#EAF3FD', boxShadow: 'none', padding: '12px 10px' }}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h6" align="center" >
                                                                    Cummulative Points
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} style={{ marginTop: '14px' }}>
                                                                <Typography variant="h6" align="center" style={{ textAlign: 'center', fontWeight: 700, color: '#01539D', fontSize: '24px', paddingTop: '0px' }}>
                                                                    {mycommunity?.data?.mylevel?.rewardpoint}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="body2" align="center" style={{ marginTop: '8px', fontSize: '12px' }}>
                                                                    <Typography component="span" style={{ fontWeight: 'bold' }}>
                                                                        {formatNumber(parseFloat(mycommunity?.data?.mylevel?.nextlevel))}
                                                                    </Typography>
                                                                    {' points more to reach '}
                                                                    <Typography component="span" style={{ fontWeight: 'bold' }}>
                                                                        {nextLevel}
                                                                    </Typography>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                                <Card className="cardWrapperMain" style={{ borderRadius: 16, backgroundColor: '#EAF3FD', display: 'flex', alignItems: 'center', marginBottom: '20px', width: '100%', margin: '0 auto', boxShadow: 'none', overflow: 'visible' }}>

                                                                    {/* Box 1 */}
                                                                    <Card className="leftColumn" style={{ float: 'left', borderRadius: 16 }}>
                                                                        <Grid container alignItems="center">
                                                                            <Grid className='profileLeftIcon' item xs={2} style={{ textAlign: 'center' }}>
                                                                                <SVG5 />
                                                                            </Grid>
                                                                            <Grid className='profileCenterIcon' item xs={8}>
                                                                                <Typography variant="h6" align="center" style={{ fontSize: '14px', marginBottom: '2px' }}>
                                                                                    {parseFloat(userDashboardData?.[0]?.redeemedpoints) + parseFloat(mycommunity?.data?.mylevel?.rewardpoint)}
                                                                                </Typography>
                                                                                <Typography variant="body2" align="center" style={{ fontSize: '14px' }}>
                                                                                    Earned
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid className='profileRightIcon' item xs={2} style={{ textAlign: 'center' }}>
                                                                                <SVG7 style={{ cursor: 'pointer' }} onClick={() => navigate(`/mypoints`)}/>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Card>

                                                                    {/* Box 2 */}
                                                                    <Card className="rightColumn" style={{ float: 'right', marginLeft: '13px', borderRadius: 16 }}>
                                                                        <Grid container alignItems="center">
                                                                            <Grid className='profileLeftIcon' item xs={2} style={{ textAlign: 'center' }}>
                                                                                <SVGRedeemed />
                                                                            </Grid>
                                                                            <Grid className='profileCenterIcon' item xs={8}>
                                                                                <Typography variant="h6" align="center" style={{ fontSize: '14px', marginBottom: '2px' }}>
                                                                                    {userDashboardData?.[0]?.redeemedpoints}
                                                                                </Typography>
                                                                                <Typography variant="body2" align="center" style={{ fontSize: '14px', marginLeft: '-3px' }}>
                                                                                    Redeemed
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid className='profileRightIcon' item xs={2} style={{ textAlign: 'center', }}>
                                                                                <SVG7 style={{ cursor: 'pointer' }} onClick={() => navigate(`/redeemreport`)}/>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Card>
                                                                </Card>
                                                            </Grid>
                                                        </Card>
                                                    </Paper>
                                                </Grid>

                                            ) : ""
                                        }
                                        {
                                            searchedUser?.[0]?.phoneno === localStorage.getItem("userid") ? (
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Paper style={{ backgroundColor: 'white', padding: 16, height: '100%', minHeight: '215px' }}>
                                                        <Card style={{ borderRadius: 16, backgroundColor: '#EAF3FD', boxShadow: 'none', overflow: 'visible', padding: '12px 10px' }}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h6" align="center" >
                                                                    Active Campaigns
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} style={{ marginTop: '14px' }}>
                                                                <Typography variant="h6" align="center" style={{ textAlign: 'center', fontWeight: 700, color: '#01539D', fontSize: '24px', }}>
                                                                    {userDashboardData?.[0]?.activecampaigns}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="body2" align="center" style={{ marginTop: '12px', fontSize: '12px' }}>
                                                                    Participate, refer campaigns, Win!
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                                <Card className="cardWrapperMain" style={{ borderRadius: 16, backgroundColor: '#EAF3FD', alignItems: 'center', width: '100%', boxShadow: 'none', padding: '2px 5px', display: 'flex' }}>

                                                                    {/* Box 1 */}
                                                                    <Card className="leftColumn" style={{ float: 'left', borderRadius: 16 }}>
                                                                        <Grid container alignItems="center">
                                                                            <Grid className='profileLeftIcon' item xs={2} style={{ textAlign: 'center' }}>
                                                                                <SVGActiveCampReg />
                                                                            </Grid>
                                                                            <Grid className='profileCenterIcon' item xs={8}>
                                                                                <Typography variant="h6" align="center" style={{ fontSize: '14px', marginBottom: '2px' }}>
                                                                                    {userDashboardData?.[0]?.campsregistered}
                                                                                </Typography>
                                                                                <Typography variant="body2" align="center" style={{ fontSize: '14px', marginLeft: '-2px' }}>
                                                                                    Registered
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid className='profileRightIcon' item xs={2} style={{ textAlign: 'center' }}>
                                                                                <SVG7 style={{ cursor: 'pointer' }} onClick={() => navigate(`/campaignregisterstatus`)}/>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Card>

                                                                    {/* Box 2 */}
                                                                    <Card className="rightColumn" style={{ float: 'right', borderRadius: 16, marginLeft: '14px' }}>
                                                                        <Grid container alignItems="center">
                                                                            <Grid className='profileLeftIcon' item xs={2} style={{ textAlign: 'center' }}>
                                                                                <SVGActiveCampUnReg />
                                                                            </Grid>
                                                                            <Grid className='profileCenterIcon' item xs={8}>
                                                                                <Typography variant="h6" align="center" style={{ fontSize: '14px', marginBottom: '3px' }}>
                                                                                    {userDashboardData?.[0]?.campsunregistered}
                                                                                </Typography>
                                                                                <Typography variant="body2" align="center" style={{ marginRight: '-2px' }} >
                                                                                    To Register
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid className='profileRightIcon' item xs={2} style={{ textAlign: 'center' }}>
                                                                                <SVG7 style={{cursor: 'pointer'}} onClick={() => navigate(`/home`)}/>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Card>
                                                                </Card>
                                                            </Grid>
                                                        </Card>
                                                    </Paper>
                                                </Grid>
                                            ) : ""
                                        }
                                    </Grid>
                                </Grid>
                                {/* Column 2 */}
                                <Grid className='actionWrapperConent' item xs={12} sm={6} md={12} sx={{ flex: { xs: '0 0 100%', sm: 'none', md: '0 0 35%' }, maxWidth: { xs: '100%', sm: '100%', md: '35%' } }}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Card style={{ borderRadius: 16, background: 'linear-gradient(90deg, #FF7E5F 0%, #FEB47B 100%)', overflow: 'hidden' }}>
                                            <CardContent style={{ height: 'auto', minHeight: '126px', maxHeight: '126px' }}>
                                                <Box display="flex" alignItems="center">
                                                    <Typography variant="h6" gutterBottom>
                                                        Teams
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"
                                                        gutterBottom
                                                        style={{
                                                            borderRadius: '10px',
                                                            backgroundColor: '#EAF3FD',
                                                            width: '13%',
                                                            marginLeft: '5px',
                                                            textAlign: 'center',
                                                            fontSize: '18px',
                                                        }}
                                                    >
                                                        {myTeamData.length}
                                                    </Typography>
                                                </Box>
                                                {loading ? (
                                                    <CircularProgress />
                                                ) : (
                                                    myTeamData.length > 0 ? (
                                                        <Slider {...settings}>
                                                            {myTeamData.map((team, index) => (
                                                                <Box
                                                                    key={index}
                                                                    className="carousel-item"
                                                                >
                                                                    <img
                                                                        src={team.logo}
                                                                        alt={`Fetched ${index}`}
                                                                        className="carousel-image"
                                                                    />
                                                                </Box>
                                                            ))}
                                                        </Slider>
                                                    ) : (
                                                        <Typography variant="body2" color="textSecondary">
                                                            No images available
                                                        </Typography>
                                                    )
                                                )}
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{ marginTop: '30px' }}>
                                        <Paper className="activityWrapper" style={{ backgroundColor: 'white', padding: 16, height: '100%', maxHeight: '491px', minHeight: '491px' }}>
                                            <Card className="activityInnerWidth" sx={{ borderRadius: 2, height: 465, display: 'flex', flexDirection: 'column' }}>
                                                <CardContent>
                                                    <Typography variant="h6" gutterBottom >
                                                        Activities
                                                    </Typography>
                                                </CardContent>
                                                <CardContent className="activityHeight" sx={{ flex: 1, overflowY: 'auto' }}>
                                                    {activityList && activityList.length > 0 ? (
                                                        activityList.map((action, index) => (
                                                            <Grid container spacing={2} key={index} sx={{ marginBottom: 2 }}>
                                                                <Grid item xs={3}>
                                                                    <Avatar
                                                                        sx={{ width: 56, height: 56 }}
                                                                        src={action?.camplogo}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={9}>
                                                                    <Box>
                                                                        <Typography variant="body2" color="textSecondary">
                                                                            {moment(action?.date).fromNow()}
                                                                        </Typography>
                                                                        <Typography variant="body1">
                                                                            {action?.desc}
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        ))
                                                    ) : (
                                                        <Typography variant="body2" color="textSecondary">
                                                            No activities available
                                                        </Typography>
                                                    )}
                                                </CardContent>
                                            </Card>
                                        </Paper>
                                    </Grid>
                                    {
                                        searchedUser?.[0]?.phoneno === localStorage.getItem("userid") ? (
                                            <Grid item xs={12} sm={12} md={12} sx={{ marginTop: '26px' }}>
                                                <Paper style={{ backgroundColor: 'white', padding: 16, height: '100%', minHeight: '215px' }}>
                                                    <Card style={{ borderRadius: 16, backgroundColor: '#EAF3FD', boxShadow: 'none', overflow: 'visible', padding: '12px 10px' }}>
                                                        <Grid item xs={12} style={{ textAlign: 'center', }}>
                                                            <Typography variant="h6" gutterBottom >
                                                                Overall Rank
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} style={{ textAlign: 'center', marginTop: '14px' }}>
                                                            <Typography variant="h6" align="center" style={{ textAlign: 'center', fontWeight: 700, color: '#01539D', fontSize: '24px', paddingTop: '0px' }}>
                                                                {mycommunity?.data?.myrankpoints?.myrank}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" align="center" style={{ marginTop: '8px', fontSize: '12px' }} >
                                                                Your organization rank
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} style={{ textAlign: 'center', marginTop: '9px' }}>
                                                            <Card style={{ borderRadius: 16, backgroundColor: 'white', alignItems: 'center', marginBottom: '20px', margin: '0 auto' }}>
                                                                <Grid container alignItems="center">
                                                                    <Grid className='profileLeftIcon' item xs={2} style={{ textAlign: 'center' }}>
                                                                        <SVGOrgRank />
                                                                    </Grid>
                                                                    <Grid className='profileCenterIcon' item xs={8}>
                                                                        <Typography variant="h6" align="center" style={{ fontSize: '14px' }}>
                                                                            {!isEmpty(myOrgRank) && myOrgRank?.[0].rank}
                                                                        </Typography>
                                                                        <Typography variant="body2" align="center" style={{ fontSize: '14px' }}>
                                                                            out of {!isEmpty(myOrgRank) && myOrgRank?.[0].totalusers}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid className='profileRightIcon' item xs={2} style={{ textAlign: 'center' }}>
                                                                        <SVG7 style={{ cursor: 'pointer' }} onClick={() => navigate(`/mypoints#platform-leaderboard`)} />
                                                                    </Grid>
                                                                </Grid>
                                                            </Card>
                                                        </Grid>
                                                    </Card>
                                                </Paper>
                                            </Grid>
                                        ) : ""
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                            )
                        }
                    </div>
                </div>
                <CopyRight />
                <FixedFooterIcon />
            </section>
        </div>
    );
};

export default SocialProfile;
