import * as React from "react";
import { useState, useRef } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "../components/Header";
import { CopyRight } from "../components/CopyRight";
import TopHeaderScreen from "./TopHeader1";
import { userProfile } from "../redux/action/UserProfileActions";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import { FixedFooterIcon } from "../components/FixedFooterIcon";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";

export default function SOAdashboardScreen(props) {
  const navigate = useNavigate();
  const chartRef = useRef(null);
  const [loading, setLoading] = React.useState(false);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  // console.log(profileResponse)

  const { response: statesResponse } = useSelector((state) => state.states);
  const { isSuccess: citiesSuccess, response: citiesResponse } = useSelector(
    (state) => state.cities
  );
  const {
    isStateSuccess,
    isActivitySuccess,
    isSOVolOrgSuccess,
  } = useSelector((state) => state.reportInfo);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isActivitySuccess || isStateSuccess) {
      setLoading(false);
    }
  }, [isActivitySuccess, isStateSuccess]);

  React.useEffect(() => {
    if (isSOVolOrgSuccess)
      dispatch(userProfile(localStorage.getItem("userid")));
  }, [isSOVolOrgSuccess]);

  const concatenatedData = statesResponse?.[0]?.concat(statesResponse?.[1]);
  const finalData = concatenatedData?.concat({ name: "All States" });
  const [campaignList, setCampaignList] = useState(null);
  const [volovertimeList, setVolovertimeList] = useState(null);
  const [volincampinorgList, setvolincampinorgList] = useState(null);
  const [volempinorgList, setvolempinorgList] = useState(null);
  const [volgenderinorgList, setvolgenderinorgList] = useState(null);
  const [chart1Value, setChart1Value] = useState(false);
  const [chart2Value, setChart2Value] = useState(false);
  const [chart3Value, setChart3Value] = useState(false);
  const [chart4Value, setChart4Value] = useState(false);
  const [chart5Value, setChart5Value] = useState(false);
  const [chart6Value, setChart6Value] = useState(false);
  const [chart7Value, setChart7Value] = useState(false);
  const [chart8Value, setChart8Value] = useState(false);
  const [chart9Value, setChart9Value] = useState(false);
  const [volhourspentincampList, setvolhourspentincampList] = useState(null);
  const [volovertimeincampList, setvolovertimeincampList] = useState(null);

  React.useEffect(() => {
    if (document.getElementById("SOA_chart1") === null) {
      setChart1Value(false);
    } else {
      setChart1Value(true);
    }
  }, [document.getElementById("SOA_chart1")]);

  React.useEffect(() => {
    if (document.getElementById("SOA_chart2") === null) {
      setChart2Value(false);
    } else {
      setChart2Value(true);
    }
  }, [document.getElementById("SOA_chart2")]);

  React.useEffect(() => {
    if (document.getElementById("SOA_chart3") === null) {
      setChart3Value(false);
    } else {
      setChart3Value(true);
    }
  }, [document.getElementById("SOA_chart3")]);

  React.useEffect(() => {
    if (document.getElementById("SOA_chart4") === null) {
      setChart4Value(false);
    } else {
      setChart4Value(true);
    }
  }, [document.getElementById("SOA_chart4")]);

  React.useEffect(() => {
    if (document.getElementById("SOA_chart5") === null) {
      setChart5Value(false);
    } else {
      setChart5Value(true);
    }
  }, [document.getElementById("SOA_chart5")]);

  React.useEffect(() => {
    if (document.getElementById("SOA_chart6") === null) {
      setChart6Value(false);
    } else {
      setChart6Value(true);
    }
  }, [document.getElementById("SOA_chart6")]);

  React.useEffect(() => {
    if (document.getElementById("SOA_chart7") === null) {
      setChart7Value(false);
    } else {
      setChart7Value(true);
    }
  }, [document.getElementById("SOA_chart7")]);

  React.useEffect(() => {
    if (document.getElementById("SOA_chart8") === null) {
      setChart8Value(false);
    } else {
      setChart8Value(true);
    }
  }, [document.getElementById("SOA_chart8")]);

  React.useEffect(() => {
    if (document.getElementById("SOA_chart9") === null) {
      setChart9Value(false);
    } else {
      setChart9Value(true);
    }
  }, [document.getElementById("SOA_chart9")]);

  const sdk1 = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",

    filter: {
      // "clientid": { "$eq": "c00001" }
      // "orgrole": { "$eq": "c00001" },
      organization: { $eq: profileResponse?.organization },
    },
  });
  
  const SOA_chart1 = sdk1.createChart({
    chartId: "64db1b64-636d-4f73-8261-c8e3766e416a",
    showAttribution: false,
  });
 
  if (chart1Value) {
    SOA_chart1.render(document.getElementById("SOA_chart1"));
  }

  const sdk2 = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    filter: {
      // "clientid": { "$eq": "c00001" }
      // "orgrole": { "$eq": profileResponse?.orgrole },
      organization: { $eq: profileResponse?.organization },
    },
  });
  // console.log("sdk2 = ", sdk2)

  const SOA_chart2 = sdk2.createChart({
    chartId: "64dbb167-636d-48e2-816d-c8e3761e6913",
    showAttribution: false,
  });
  if (chart2Value) {
  SOA_chart2.render(document.getElementById("SOA_chart2"));
  }

  // console.log(JSON.parse(localStorage.getItem("token")))
  const sdk3 = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    // getUserToken: () => JSON.parse(localStorage.getItem("token")),
    // getUserToken: () => (localStorage.getItem("token")),

    filter: {
      // "clientid": { "$eq": "c00001" }
      // "orgrole": { "$eq": profileResponse?.orgrole },
      // formorgname: { $eq: profileResponse?.organization },
      "campid_lookup_campaign.organization": { $eq: profileResponse?.organization },
    },
  });

  const SOA_chart3 = sdk3.createChart({
    chartId: "64dc7da7-ef75-4917-8a43-fb10770d44a0",
    showAttribution: false,
  });
  if (chart3Value) {
  SOA_chart3.render(document.getElementById("SOA_chart3"));
  }
  


  const sdk4 = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    // getUserToken: () => localStorage.getItem("token"),

    filter: {
      // "clientid": { "$eq": "c00001" }
      // "orgrole": { "$eq": profileResponse?.orgrole },
      "campid_lookup_campaign.organization": { $eq: profileResponse?.organization },
    },
  });

  const SOA_chart4 = sdk4.createChart({
    chartId: "5148b346-6151-4201-8a89-123f598b8b20",
    showAttribution: false,
  });
  if (chart4Value) {
  SOA_chart4.render(document.getElementById("SOA_chart4"));
  }

  {
    /* Total Vol and Emp */
  }
  const sdk5 = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",

    filter: {
      organization: { $eq: profileResponse?.organization },
    },
  });

  const SOA_chart5 = sdk5.createChart({
    chartId: "64dd06cc-20b7-4883-805b-12e34ee7ca5b",
    showAttribution: false,
  });
  if (chart5Value) {
  SOA_chart5.render(document.getElementById("SOA_chart5"));
  }

  {
    /* Heat Map Chart*/
  }
  const sdk6 = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    //theme:"dark",

    filter: {
      // "clientid": { "$eq": "c00001" }
      // "orgrole": { "$eq": profileResponse?.orgrole },
      organization: { $eq: profileResponse?.organization },
    },
  });

  const SOA_chart6 = sdk6.createChart({
    chartId: "64dcffb3-8c93-4df3-882f-968630db5e93",
    showAttribution: false,
  });
  if (chart6Value) {
  SOA_chart6.render(document.getElementById("SOA_chart6"));
  }

  {
    /* My versus other org vol in camp*/
  }
  const sdk7 = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",

    filter: {
      // "clientid": { "$eq": "c00001" }
      // "orgrole": { "$eq": profileResponse?.orgrole },
      "formcampaignname_lookup_campaign.organization": {
        $eq: profileResponse?.organization,
      },
    },
  });

  const SOA_chart7 = sdk7.createChart({
    chartId: "9b0f63e3-2e39-4299-8456-be5343bfa126",
    showAttribution: false,
  });
  if (chart7Value) {
  SOA_chart7.render(document.getElementById("SOA_chart7"));
  }

  {
    /* User's time commitment as in profile*/
  }
  const sdk8 = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    //theme:"dark",
    // getUserToken: () => localStorage.getItem("userid"),

    filter: {
      // "clientid": { "$eq": "c00001" }
      // "orgrole": { "$eq": profileResponse?.orgrole },
      organization: { $eq: profileResponse?.organization },
    },
  });

  const SOA_chart8 = sdk8.createChart({
    chartId: "96865e46-3e52-4372-a558-065ae30e9091",
    showAttribution: false,
  });
  if (chart8Value) {
  SOA_chart8.render(document.getElementById("SOA_chart8"));
  }

  {
    /* Summary of Campaigns by Category*/
  }
  const sdk9 = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    //theme:"dark",
    // getUserToken: () => localStorage.getItem("userid"),

    filter: {
      // "clientid": { "$eq": "c00001" }
      // "orgrole": { "$eq": profileResponse?.orgrole },
      organization: { $eq: profileResponse?.organization },
    },
  });

  const SOA_chart9 = sdk9.createChart({
    chartId: "b199303c-7773-467a-9ce5-b3eeb4085993",
    showAttribution: false,
  });
  if (chart9Value) {
  SOA_chart9.render(document.getElementById("SOA_chart9"));
  }
  


  return (
    <div className="wrapper">
      {/* {
        // profileSuccess &&


        (profileResponse?.orgrole?.toLowerCase() === "hyphenadmin" ||
          profileResponse?.orgrole?.toLowerCase() === "admin" ? (
          <ResponsiveDrawer {...props} />
        ) : (
          <CustomDrawer menuItems={volunteerMenuItems} {...props} />
        ))} */}

      <ResponsiveDrawer {...props} />

      {/* <ResponsiveDrawer {...props} /> */}
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap" id="pdf">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Overall Dashboard View
            </div>
            {/* <br></br>
            <br></br> */}
            {/* <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Dashboard
            </div>
            <div>
              <Button
                className="btn download-btn"
                variant="contained"
                onClick={downloadDocument}
              >
                Download
              </Button>
            </div> */}
            {/* <header id="SOADashboardScreen1" style={{zIndex:"-10",marginTop:"50px"}}> */}
            <header id="SOADashboardScreen1" style={{zIndex:"-10"}}>
              {/* <Box sx={{ minWidth: 200 }}> */}
                {/* <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolInCampOverTimeChartComponent
                    response={volovertimeincampList}
                  />
                </div>
              </div> */}
                <div class="row">
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <div id="SOA_chart3" class="column chartMongo">
                      {" "}
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <div id="SOA_chart4" class="column chartMongo">
                      
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <div id="SOA_chart6" class="column chartMongo">
                     
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <div id="SOA_chart9" class="column chartMongo">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <div id="SOA_chart7" class="column chartMongo">
                      
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <div id="SOA_chart2" class="column chartMongo">
                      
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <div id="SOA_chart8" class="column chartMongo">
                     
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <div
                      id="SOA_chart5"
                      class="column chartMongo"
                      style={{ textAlign: "center" }}
                    >
                      
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <div id="SOA_chart1" class="column chartMongo">
                      
                    </div>
                  </div>
                </div>

                
              {/* </Box> */}
            </header>

            {/* <header id="SOADashboardScreen2" style={{position:"absolute",zIndex:"-1",marginTop:"50px"}}>
            <Box sx={{ minWidth: 200 }}> */}
            {/* <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolInCampOverTimeChartComponent
                    response={volovertimeincampList}
                  />
                </div>
              </div> */}
            {/* <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolOverTimeChartComponent1 response={volovertimeList} />
                </div>
              </div>
              <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolMaleVsFemaleOrgChartComponent1
                    response={volgenderinorgList}
                  />
                </div>
              </div>
              <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolHoursSpentCampChartComponent1
                    response={volhourspentincampList}
                  />
                </div>
              </div>
              <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolEmpInOrgChartComponent1 response={volempinorgList} />
                </div>
              </div> */}
            {/* <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolImpactDivComponent response={volimpactinorgList} />
                </div>
              </div> */}
            {/* <div class="iframeContentWrapper">
                <div class="innerSpace"></div>
              </div>
              <div class="iframeContentWrapper">
                <div class="innerSpace"></div>
              </div> */}
            {/* </Box>
            </header> */}
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
      {/* <PDF rootElementId="pdf" downloadFileName="testpdf" /> */}
    </div>
  );
}
