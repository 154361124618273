import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  useMediaQuery,
  Typography,
  useTheme,
  Snackbar,
  Alert,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import _debounce from 'lodash.debounce';
import "react-datepicker/dist/react-datepicker.css";
import { CopyRight } from "../../components/CopyRight";
import { useNavigate, useLocation } from "react-router-dom";
import _, { set } from "lodash";
import LoginLogo from "../../assets/sdp.jpeg";
import styled from 'styled-components';
import axios from 'axios';


const LoginPgLogo = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (max-width: 768px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
}));

const Container = styled('div')(({ theme }) => ({
  maxWidth: '1100px',
  margin: '0 auto',
  textAlign: 'center',
  background: '#fff',
}));

const PaymentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '20px',

  '@media (max-width: 600px)': {
    marginTop: '10px',
  },
}));

const PriceLabel = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 'bold',
  color: 'black',
  marginBottom: '20px',

  '@media (max-width: 600px)': {
    fontSize: '20px',
    marginBottom: '10px',
  },
}));

const StatementLabel = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  // fontWeight: 'bold',
  color: 'black',
  marginBottom: '10px',

  '@media (max-width: 600px)': {
    fontSize: '16px',
    marginBottom: '5px',
  },
}));

const PayButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#007BFF',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#0056b3',
  },
}));


function PGVerify(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedIndex, formData } = location.state || {};
  console.log("pgVerify selectedIndex = ", selectedIndex);
  console.log("pgVerify formData = ", formData);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const getPriceLabel = () => {
    switch (selectedIndex) {
      case 0:
        return 1;
      case 1:
        return 1;
      case 2:
        return 1;
      default:
        return '';
    }
  };
  // const handlePayNowClick = () => {
  //   setSnackbarOpen(true);
  // };

  const handlePayNowClick = async () => {
    try {
      formData.MID = 'MID' + Date.now();
      formData.transactionId = 'T' + Date.now();
      formData.amount = getPriceLabel();
      console.log("formData.amount = ", formData.amount)
      const response = await axios.post('/pg/payment', {formData});
      if (response.data.success) {
        console.log("redirect")
        window.location.href = response.data.phonepe_url;
      } else {
        // Handle failure
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Payment API call failed:', error);
      setSnackbarOpen(true);
    }
  };


  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="wrapper">
      <div class="content content-wrap">
        <LoginPgLogo style={{ textAlign: "center", marginTop: "10px" }}>
          <img
            src={LoginLogo}
            style={{
              height: 56,
            }}
          />
          <figcaption>Powered by cYAAG</figcaption>
        </LoginPgLogo>
        <div class="content-mrg" style={{ background: "#fff" }}>
          <div style={{ maxWidth: "1100px", margin: "0 auto" }}>
            <Container>
              <Typography
                variant="h4"
                style={{
                  // fontFamily: "monospace",
                  marginTop: "10px",
                  color: "black",
                }}
              >
                Payment Verification
              </Typography>
              <PaymentContainer>
                <StatementLabel>You will pay</StatementLabel>
                <PriceLabel>₹{getPriceLabel()}</PriceLabel>
                <PayButton onClick={handlePayNowClick}>Pay Now</PayButton>
              </PaymentContainer>
            </Container>
          </div>
        </div>
      </div >
      <CopyRight />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
          This feature will come up soon
        </Alert>
      </Snackbar>
    </div >
  );
}
export default PGVerify;
