import React, { useState, useEffect, useRef } from "react";
import { addDays } from "date-fns";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { MenuItem, Select, Typography, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { CopyRight } from "../components/CopyRight";
import Multiselect from "multiselect-react-dropdown";
import _ from "lodash";
import ErrorMessage from "../components/ErrorMessage";
import ResponsiveDrawer from "../components/Header";
import TopHeaderScreen from "../components/TopHeader1";
import RewardMainImage from "../assets/rew.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { isEmpty, isArray } from "lodash";
import RegistrationTopImg from "../assets/thank you.png";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import UploadDocsParticipate from "./UploadDocsParticipate";
import ShareIcon from "@mui/icons-material/Share";
import { FixedFooterIcon } from "./FixedFooterIcon";
import { referral } from "../redux/action/ReferralAction";
import CEOImage from "../assets/ceo.png";
import DatePicker from 'react-datepicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import 'react-datepicker/dist/react-datepicker.css';
import CampaignCard from "../components/CampaignCard";

const Participate = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fieldRef1 = React.useRef();
  const fieldRef2 = React.useRef();
  const fieldRef3 = React.useRef();
  const [referralrewardmsg, setreferralrewardmsg] = useState([]);
  const [referredUser, setReferredUser] = useState([]);

  // const alert = useAlert();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /* For alerts */
  const [status, setStatus] = useState(false);
  const [type, setType] = useState("success");
  const [title, setTitle] = useState("This is a alert");
  const [show, setShow] = React.useState(false);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "" },
  ]);
  const [activityList, setActivityList] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const [camploading, setCampLoading] = React.useState(false);
  const locationPath = useLocation();
  const [response, setResponse] = useState({});
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const [campaignList, setCampaignList] = useState(undefined);
  const [regStatusList, setRegStatusList] = useState(undefined);
  const [marketplacelist, setmarketplacelist] = useState([]);
  const [regformdata, setregformdata] = useState([]);
  const [campRegFormQ, setCampRegFormQ] = useState([]);
  // const [hideShow, setHideShow] = useState(false);
  const [myreferralurl, setmyreferralurl] = useState([]);
  const [formSubmissionMessage, setFormSubmissionMessage] = useState("")
  const [SDGCampMapping, setSDGCampMapping] = React.useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


  const [selected2, setSelected2] = React.useState([]);


  const [selectedOptions, setSelectedOptions] = useState([]);


  const participateRef = React.useRef();


  const { isSuccess: campaignSuccess, response: campaignResponse } =
    useSelector((state) => state.onBoard);
  // console.log(data)


  const options = {
    items: 3,
    margin: 10,
    loop: false,
    nav: false,
    autoplaySpeed: 900,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };



  const handleCampaignFieldChange1 = (field, e, type) => {
    console.log("field = ", field);
    console.log("e = ", e);
    console.log("type = ", type);

    // setCampaignValues({
    //   ...campaignValues,
    //   [type]: type === "dateOfBirth" ? e : e.target.value,
    // });

    setCampaignValues({
      ...campaignValues,
      [field]: type === "dateOfBirth" ? e : e.target.value,
    });
  };



  const [refValues, setrefValues] = React.useState({
    refCode: "",
  });


  useEffect(() => {

    localStorage.getItem("referralid") &&
      getReferredUserDetails();

    localStorage.getItem("referralid") &&
      dispatch(
        referral({
          referralid: localStorage.getItem("referralid"),
          campid: localStorage.getItem("referedcampid"),
          reflevel: localStorage.getItem("reflevel"),
          phoneno: localStorage.getItem("userid"),
        })
      );
    localStorage.setItem("referralid", null)
    localStorage.setItem("referedcampid", null)
    localStorage.setItem("reflevel", null);
    localStorage.setItem("title", null);
  }, []);


  const [campaignValues, setCampaignValues] = React.useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [regStatusList]);

  // const [campaignRegistrationValuesError, setCampaignRegistrationValuesError] =
  //   React.useState({
  //     timeCommitmentCampaignError: "",
  //     impactCommitmentCampaignError: "",
  //     locationCampaignError: "",
  //     formq5CampaignError: "",
  //     formq6CampaignError: "",
  //     formq7CampaignError: "",
  //     formq8CampaignError: "",
  //     formq9CampaignError: "",
  //     formq14CampaignError: "",
  //     formq10CampaignError: "",
  //     formq11CampaignError: "",
  //     formq12CampaignError: "",
  //     dateSelection: new Date(),
  //   });
  const [campaignRegistrationValuesError, setCampaignRegistrationValuesError] = React.useState({
    formq7CampaignError: "",
  });

  // const hideShowCal = async () => {
  //   setHideShow((hideShow) => !hideShow);
  // };


  const [filesUploaded, setFilesUploaded] = useState(false);

  const handleFileUpload = (status) => {
    setFilesUploaded(status);
  };


  const handleRegister = async () => {
    try {

      setIsButtonDisabled(true);
      // console.log("campaignValues = ", campaignValues);
      console.log("filesUploaded = ", filesUploaded)
      const hasUploadFiles = regformdata?.data?.[0]?.form?.some(formq => formq.type === "uploadFiles");
      console.log("hasUploadFiles = ", hasUploadFiles)
      if (hasUploadFiles) {
        if (filesUploaded) {
          const res = await axios.post(`/campaign/register`, {
            campid: campaignList?.data?.[0].campid,
            formuserfirstname: profileResponse?.firstname,
            formuserlastname: profileResponse?.lastname,
            formuserphone: profileResponse?.phoneno,
            formorgname: profileResponse?.organization,
            formcampaignname: campaignList?.data?.[0]?.name,
            contribution: campaignValues?.impact,
            participatedays:
              moment(range[0].startDate).format("YYYY-MM-DD") +
              "," +
              moment(range[0].endDate).format("YYYY-MM-DD"),
            formq: campaignValues,
            registerdate: moment(new Date()).format("YYYY-MM-DD") + " 00:00:00",
          });

          console.log("res?.data = ", res?.data)
          setFormSubmissionMessage(res?.data?.message)
          if (res?.data?.message !== "Please fill all the fields") {

            navigate(
              `/adoption/${campaignList?.data?.[0]?.campid}/${res?.data?.message}`
            );
          }
          return res;
        } else {
          console.log("No files uploaded");
        }
      } else {
        const res = await axios.post(`/campaign/register`, {
          campid: campaignList?.data?.[0].campid,
          formuserfirstname: profileResponse?.firstname,
          formuserlastname: profileResponse?.lastname,
          formuserphone: profileResponse?.phoneno,
          formorgname: profileResponse?.organization,
          formcampaignname: campaignList?.data?.[0]?.name,
          contribution: campaignValues?.impact,
          participatedays:
            moment(range[0].startDate).format("YYYY-MM-DD") +
            "," +
            moment(range[0].endDate).format("YYYY-MM-DD"),
          formq: campaignValues,
          registerdate: moment(new Date()).format("YYYY-MM-DD") + " 00:00:00",
        });

        console.log("res?.data = ", res?.data)
        setFormSubmissionMessage(res?.data?.message)
        if (res?.data?.message !== "Please fill all the fields") {

          navigate(
            `/adoption/${campaignList?.data?.[0]?.campid}/${res?.data?.message}`
          );
        }
        return res;
      }

    } catch (err) {
      setLoading(false);
      throw err;
    } finally {
      // This code block will execute whether there's an error or not
      setTimeout(() => {
        setIsButtonDisabled(false); // Enable the button after 3 seconds
      }, 3000);
    }
  };

  const getRewardsFromMarketplace = async () => {
    try {
      //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);
      const res = await axios
        .get(`/marketplace/read/${profileResponse?.phoneno}`)
        .then((response) => {

          setmarketplacelist(response);
          return response;
        });
      // setmarketplacelist(res?.data);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getRewardsFromMarketplace();

  }, []);



  const getRegForm = async () => {
    try {
      const res = await axios
        .get(`/t1/get/${profileResponse?.phoneno}/${locationPath?.pathname.split("/")?.[3]}`)
        .then((response) => {
          setregformdata(response);
          return response;
        });
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getRegForm();
  }, []);


  console.log(`vol_dashboard/camp/${locationPath?.pathname.split("/")?.[3]}/${profileResponse?.orgrole
    }/${profileResponse?.organization}/${profileResponse?.phoneno}`)

  const getCampaignDetails = async () => {
    try {
      setCampLoading(true);
      console.log("camploading = ", camploading);
      const res = await axios
        .get(
          `vol_dashboard/camp/${locationPath?.pathname.split("/")?.[3]}/${profileResponse?.orgrole
          }/${profileResponse?.organization}/${profileResponse?.phoneno}`
        )
        .then((response) => {
          setCampaignList(response);
          console.log("response = ", response)
          return response;
        });
    } catch (err) {
      throw err;
    }
  };

  console.log("campaignList = ", campaignList)


  useEffect(() => {
    // console.log("USEEFFECT")
    getCampaignDetails();
    setCampLoading(false);

  }, []);

  const getRegFormQuestions = async () => {
    try {
      // console.log(profileResponse?.orgrole)
      const res = await axios
        .get(
          // `/vol_dashboard/camp/${location?.pathname.split("/")?.[2]}`

          `campaign/registrationform/${locationPath?.pathname.split("/")?.[2]}`
        )
        .then((response) => {
          setCampRegFormQ(response);
          return response;
        });
      // setCampRegFormQ(res?.data);
      // console.log(res?.data)
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getRegFormQuestions();
  }, []);

  const getRegStatus = async () => {
    try {
      const res = await axios
        .get(
          `/camp/registeredornot/${profileResponse?.phoneno}/${locationPath?.pathname.split("/")?.[2]
          }`
        )
        .then((response) => {
          setRegStatusList(response);
          return response;
        });
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getRegStatus();
  }, []);

  console.log("locationPath?.pathname.split)?.[1] =", locationPath?.pathname.split("/")?.[1])
  console.log("locationPath?.pathname.split)?.[2] =", locationPath?.pathname.split("/")?.[2])
  console.log("locationPath?.pathname.split)?.[3] =", locationPath?.pathname.split("/")?.[3])
  console.log("locationPath?.pathname.split)?.[4] =", locationPath?.pathname.split("/")?.[4])
  const handleShare = async () => {
    // alert("Handle Share")

    try {
      // setDialogOpen(true);
      const res = await axios
        .get(
          `refrralurl/${locationPath?.pathname.split("/")?.[3]
          }/${localStorage.getItem("userid")}`
        )
        .then((response) => {
          setmyreferralurl(response);
          // generateMessage(response?.data?.url)

          // setHasShared(true);
          // delay(10000);
          return response;
        });
    } catch (err) {
      // setLoading(false);

      throw err;
    }
  };

  const splitString1 = (val) => {
    if (val) {
      const parts = val.split(/(::)|(,,)|(--)|;/).filter(Boolean);
      let inUnderlinedSection = false;
      let inBoldSection = false;
      let skipLineBreak = true;
      let inHighlightTextSection = false;

      return (
        <ul>
          {parts.map((bulletVal, index) => {
            if (bulletVal === '::') {
              inUnderlinedSection = !inUnderlinedSection;
              skipLineBreak = true;
              return null;
            } else if (bulletVal === ',,') {
              inBoldSection = !inBoldSection;
              skipLineBreak = true;
              return null;
            } else if (bulletVal === '--') {
              inHighlightTextSection = !inHighlightTextSection;
              skipLineBreak = true;
              return null;
            }

            if (inUnderlinedSection) {
              return (
                <li key={index}>
                  <span className="underlined-text">{bulletVal}</span>
                </li>
              );
            } else if (inBoldSection) {
              return (
                <li key={index}>
                  {!skipLineBreak && <br />}
                  <span className="bold-text">{bulletVal}</span>
                </li>
              );
            } else if (inHighlightTextSection) {
              return (
                <li key={index}>
                  {!skipLineBreak && <br />}
                  <span className="highlight-text">{bulletVal}</span>
                </li>
              );
            } else if (bulletVal.trim() !== '') {
              const bulletPoints = bulletVal.split(';').map((point) => point.trim());

              return (
                <li key={index}>
                  {bulletPoints.map((point, subIndex) => {
                    const shouldBreakLine = subIndex > 0;
                    if (shouldBreakLine) {
                      skipLineBreak = false;
                      return (
                        <React.Fragment key={`${index}-${subIndex}`}>
                          <br />
                          <p>{point}</p>
                        </React.Fragment>
                      );
                    }
                    return <p key={`${index}-${subIndex}`} style={{ marginTop: 0 }}>{point}</p>;
                  })}
                </li>
              );
            }
            return null;
          })}
        </ul>
      );
    }
  };



  const [range, setRange] = useState([
    {
      // startDate: new Date(),
      // startDate: addDays(new Date(), campaignList?.data?.[0]?.daystostart),
      startDate: addDays(new Date(), 1),


      endDate: addDays(new Date(), 1),
      // endDate: addDays(new Date(), campaignList?.data?.[0]?.daystoend),
      // endDate: new Date(),
      key: "selection",
    },
  ]);
  // const [range, setRange] = useState([])

  useEffect(() => {
    if (!(isEmpty(campaignList))) {
      // console.log("seeting Range")
      setRange([{
        startDate: addDays(new Date(), campaignList?.data?.[0]?.daystostart),
        endDate: addDays(new Date(), campaignList?.data?.[0]?.daystoend),
        key: "selection",
      }])
    }
    participateRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [camploading, isEmpty(campaignList)]);


  const [shortenedLink, setShortenedLink] = useState("")
  const generateMessage = async (userInput) => {
    try {
      const response = await axios(
        `https://api.shrtco.de/v2/shorten/dXJsPQ== ${userInput}`
      );
      setShortenedLink(response.data.result.full_short_link);
    } catch (e) {
      // console.log(e);
    }
  };


  const getReferredUserDetails = async () => {
    try {
      const userres = await axios
        .get(`cyaaguser/${localStorage.getItem("referralid")}`)
        .then((response) => {
          setReferredUser(response?.data);
          // console.log(response?.data);
          return response;
        });

    } catch (err) {
      throw err;
    }
  };

  const [selectedDate, setSelectedDate] = useState({});
  const handleDateChange = (date, index) => {
    const selectedDateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    setSelectedDate({
      ...selectedDate,
      [index]: selectedDateWithoutTime,
    });
    setCampaignValues({
      ...campaignValues,
      [index]: selectedDateWithoutTime,
    });
  };

  // console.log("referredUser = ", referredUser["firstname"]);

  const handleSelect = (index, event) => {
    setSelectedOptions((prevSelected) => {
      const newSelected = [...prevSelected];
      newSelected[index] = event;
      return newSelected;
    });
    setCampaignValues((prevCampaignValues) => {
      const newCampaignValues = { ...prevCampaignValues };
      newCampaignValues[`multiSelectq${index}`] = event;
      return newCampaignValues;
    });
  };

  const handleRemove = (index, event) => {
    setSelectedOptions((prevSelected) => {
      const newSelected = [...prevSelected];
      newSelected[index] = event;
      return newSelected;
    });
    setCampaignValues((prevCampaignValues) => {
      const newCampaignValues = { ...prevCampaignValues };
      newCampaignValues[`multiSelectq${index}`] = event;
      return newCampaignValues;
    });
  };


  // Function to sanitize and render HTML content
  const renderHTML = (htmlContent) => {
    return { __html: htmlContent };
  };

  const [formErrors, setFormErrors] = useState({});
  const submitButtonRef = useRef(null);



  const updateFormError = (questionIndex, isError) => {
    console.log("questionIndex = ", questionIndex);
    console.log("isError = ", isError);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [questionIndex]: isError,
    }));


  };


  const handleImageLoad = async (img) => {
    const im = await Promise.all(img);
    console.log(im);
    if (im) {
      return true;
    } else {
      return false;
    }

    // Additional actions to perform after the image is loaded
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`camp/sdg/mapping`);
        setSDGCampMapping(res?.data);
      } catch (err) {
        console.error("Error fetching SDG camp mapping:", err);
      }
    };

    // Call the fetchData function
    fetchData();
  }, []);

  const handleKeyDown = (event) => {
    // Disable key entries
    event.preventDefault();
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg" ref={participateRef}>
            <div class="row">
              {/* <Button onClick={() => navigate(-1)} className="backWrapper"> */}
              <Button onClick={() => navigate(`/home`)} className="backWrapper">
                <i className={`fa fa-caret-left`} style={{ marginLeft: "19px" }} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Home</span>
              </Button>
            </div>

            {/* <div class="cyagg-sec-space"> */}
            {
              !isEmpty(campaignList?.data) ? (
              <div>
                <div class="container">
                  <div class="row" >
                    <div class="col-md-6 col-xs-12 col-sm-12">
                      {!isEmpty(campaignList) && campaignList && (
                        <div class="reg-banner" style={{ marginTop: "10px" }}>
                          <div class="container">
                            <div class="row">
                              <div class="col-lg-6 col-md-6 col-sm-12 col-12 banner-left-content">
                                {/* <p class="p-3"> */}
                                <p class="">
                                  <span
                                    style={{
                                      textAlign: "center",
                                      fontSize: 20,
                                      display: "block",
                                      padding: "5px 15px",
                                    }}
                                  >
                                    {
                                      referredUser["firstname"] === undefined ?
                                        // <b>
                                        //   Thank you for your interest in{" "}
                                        //   <u>{campaignList?.data?.[0]?.name} </u>! Be a real
                                        //   changemaker by also inviting other volunteers to
                                        //   adopt this campaign.
                                        // </b>
                                        <b>
                                          Thank you for your interest in <u>{campaignList?.data?.[0]?.name} </u>! You will earn points when you register here and also when you invite others!
                                        </b>
                                        :
                                        <b>
                                          Thank you for accepting {referredUser["firstname"]}'s invitation to <u>{campaignList?.data?.[0]?.name} </u>! Both you and {referredUser["firstname"]} will earn points when you register here and also when you invite others!
                                        </b>
                                      // <b>
                                      //   Thank you for your interest in{" "}
                                      //   <u>{campaignList?.data?.[0]?.name} </u>! Be a real
                                      //   changemaker by also inviting other volunteers to
                                      //   adopt this campaign.
                                      // </b>
                                    }
                                  </span>
                                  {!(regStatusList?.data?.registered === 0) && (
                                    <span
                                      style={{
                                        textAlign: "center",
                                        fontSize: 15,
                                        color: "red",
                                        padding: "5px",
                                        display: "block",
                                      }}
                                    >
                                      Please note that you have committed to participate
                                      for this campaign on{" "}
                                      <u>
                                        <b>{regStatusList?.data?.registerdates}</b>
                                      </u>
                                    </span>
                                  )}
                                </p>
                                <div class=" pl-4 pr-4">
                                  <button
                                    type="button"
                                    // class="view-more-btn btn btn-secondary mb-4 mr-2"
                                    class="view-more-btn btn btn-tertiary mb-4 mr-2"
                                  >
                                    <a href="#participate">Register Now </a>
                                    <i class="fa fa-arrow-right"></i>
                                  </button>
                                </div>
                              </div>
                              <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-center ">
                                <div class="slider-img d-sm-flex justify-content-center">
                                  <img
                                    alt="ERP Implementation "
                                    class="img-fluid hide-on-mobile reg-b-img"
                                    height="400px"
                                    src={RegistrationTopImg}
                                    title="ERP Implementation"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div class="cyagg-sec-space" style={{ marginTop: "15px" }}>
                        <div class="container">
                          <div class="row customShareMobile">
                            <div class="row">
                              {/* <div class="col-md-6"> */}
                              <div class="text-center mob-space">
                                <h5>Invite Friends, Increase Impact, Earn More Points</h5>
                                <div class="navigationS">
                                  <div
                                    class="menuToggleS"
                                    onClick={handleShare}
                                  >
                                    <ShareIcon style={{ color: "orange" }} />
                                  </div>
                                  <div class="menuS">
                                    <ul>
                                      <WhatsappShareButton
                                        url={
                                          "Hello! I am personally recommending this social impact opportunity for you " + myreferralurl?.data?.url +
                                          "\n" +
                                          "\n" +
                                          campaignList?.data?.[0]?.socialmediamsg +
                                          "\n" +
                                          "\n" +
                                          "Please register by clicking on the link below. Both you and I will also get points, rewards, and recognition on the cYAAG Sustainable Development Platform." +
                                          "\n" +
                                          myreferralurl?.data?.url
                                        }
                                      >

                                        <WhatsappIcon
                                          size={32}
                                          round={true}
                                        ></WhatsappIcon>
                                      </WhatsappShareButton>

                                      <FacebookShareButton

                                        url={
                                          "Hello! I am personally recommending this social impact opportunity for you " + myreferralurl?.data?.url +
                                          "\n" +
                                          "\n" +
                                          campaignList?.data?.[0]?.socialmediamsg +
                                          "\n" +
                                          "\n" +
                                          "Please register by clicking on the link below. Both you and I will also get points, rewards, and recognition on the cYAAG Sustainable Development Platform." +
                                          "\n" +
                                          myreferralurl?.data?.url
                                        }
                                        hashtag="#cyaag"
                                      >
                                        <FacebookIcon
                                          size={32}
                                          round={true}
                                        ></FacebookIcon>
                                      </FacebookShareButton>

                                      <EmailShareButton
                                        url=""

                                        // body={
                                        //   "Hello!" +
                                        //   "\n" +
                                        //   "I really liked this new SDP initiative. With SDP, we can participate in many social impact campaigns and win points and rewards. I am personally inviting you to participate in this campaign on SDP." +
                                        //   "\n" +
                                        //   "\n" +
                                        //   campaignList?.data?.[0]?.socialmediamsg +
                                        //   "\n" +
                                        //   "\n" +
                                        //   "Please use the link below and don't forget to enter your personal REFERRAL CODE " +
                                        //   " " +
                                        //   myreferralurl?.data?.refcode +
                                        //   " " +
                                        //   "when you participate in this campaign as that will win extra points for you!" +
                                        //   "\n" +
                                        //   myreferralurl.data?.url
                                        // }
                                        body={
                                          "Hello! I am personally recommending this social impact opportunity for you " + myreferralurl?.data?.url +
                                          "\n" +
                                          "\n" +
                                          campaignList?.data?.[0]?.socialmediamsg +
                                          "\n" +
                                          "\n" +
                                          "Please register by clicking on the link below. Both you and I will also get points, rewards, and recognition on the cYAAG Sustainable Development Platform." +
                                          "\n" +
                                          myreferralurl?.data?.url
                                        }
                                        subject="Invitation to cYAAG"
                                      >
                                        <EmailIcon size={32} round={true} />
                                      </EmailShareButton>

                                      <TwitterShareButton

                                        url={
                                          "Hello! I am personally recommending this social impact opportunity for you " + myreferralurl?.data?.url +

                                          "\n" +
                                          "\n" +
                                          "Please register by clicking on the link above. Both you and I will also get points, rewards, and recognition on the cYAAG Sustainable Development Platform."

                                        }
                                      >
                                        <TwitterIcon size={32} round={true}></TwitterIcon>
                                      </TwitterShareButton>
                                    </ul>
                                  </div>
                                </div>
                                <p style={{ padding: "0px 39px" }}>
                                  You win more points when your friends register, even more when they invite others, and when the campaign goal is achieved!
                                </p>
                                <hr />
                                <p style={{ padding: "0px 47px" }}>
                                  We are providing you an invite message that you can edit before sending.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 col-xs-12 col-sm-12">
                      <div class="cyagg-sec-space reg-camp-sec">
                        <div class="container">
                          <div>
                            {
                              SDGCampMapping && campaignList &&
                              <div>
                                <CampaignCard
                                  organizationlogo={campaignList?.data?.[0]?.organizationlogo}
                                  name={campaignList?.data?.[0]?.name}

                                  logo={campaignList?.data?.[0]?.logo}
                                  video={campaignList?.data?.[0]?.video}
                                  sponsor={campaignList?.data?.[0]?.sponsor}
                                  tag={campaignList?.data?.[0]?.tag}
                                  SDGCampMapping={SDGCampMapping}
                                  goalquantity={campaignList?.data?.[0]?.goalquantity}
                                  unitofgoal={campaignList?.data?.[0]?.unitofgoal}
                                  committedcount={campaignList?.data?.[0]?.committedcount}
                                  achievedcount={campaignList?.data?.[0]?.achievedcount}
                                  submittedcount={campaignList?.data?.[0]?.submittedcount}
                                  campaigntype={campaignList?.data?.[0]?.campaigntype}
                                  campid={campaignList?.data?.[0]?.campid}
                                  maxpointstobeearned={campaignList?.data?.[0]?.maxpointstobeearned}
                                  dayrem={campaignList?.data?.[0]?.dayrem}
                                  hourrem={campaignList?.data?.[0]?.hourrem}
                                  minrem={campaignList?.data?.[0]?.minrem}
                                  profileResponse={profileResponse}
                                  index={0}
                                  goal={campaignList?.data?.[0]?.goal}
                                  desc={campaignList?.data?.[0]?.desc}
                                  whattodo={campaignList?.data?.[0]?.whattodo}
                                  guidelines={campaignList?.data?.[0]?.guidelines}
                                  landing="no"
                                  status={campaignList?.data?.[0]?.active}
                                  directlink="no"
                                  showcamplb="yes"
                                  participatepage="yes"
                                />
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="participate" class="cyagg-sec-space">
                  <div class="container">
                    <div>
                      <h4>Register Now</h4>
                    </div>
                    <br />
                    <div style={{ textAlign: "center", color: "#1E90FF", marginBottom: 20 }}>
                      This campaign ends on {" "}
                      <b>{campaignList?.data?.[0]?.enddate}</b>
                    </div>
                    <div class="">
                      <div >
                        <div class="form-reg-participate multiselectSpace" style={{ userSelect: "text", backgroundColor: "white" }}>
                          <div class="row">
                            {/* <div class="col-md-6"> */}
                            <div class="col-md-12">
                              <div>
                                {
                                  regformdata &&
                                  regformdata?.data?.[0]?.form?.map(
                                    (formq, index) => (
                                      formq.type === "freeForm" ? (
                                        <div style={{ marginBottom: 20 }}>
                                          <label for="password">
                                            {/* {[`Q${index + 1}`]}. {formq[`q${index}`]} */}
                                            {formq[`q${index}`]}

                                          </label>

                                          <input
                                            type="text"
                                            id="create-yaad--title"
                                            name=""
                                            placeholder="Type"
                                            value={campaignValues?.[`q${index}`]}
                                            onBlur={() => {
                                              if (!campaignValues?.[`q${index}`]) {
                                                setCampaignRegistrationValuesError({
                                                  ...campaignRegistrationValuesError,
                                                  // formq7CampaignError: true,
                                                  [`formq${index}CampaignError`]: true,
                                                });
                                              } else {
                                                setCampaignRegistrationValuesError({
                                                  ...campaignRegistrationValuesError,
                                                  // formq7CampaignError: false,
                                                  [`formq${index}CampaignError`]: false,
                                                });
                                              }
                                            }}
                                            // onBlur={() => {
                                            //   if (!campaignValues?.[`q${index}`]) {
                                            //     console.log("FreeForm error")
                                            //     updateFormError(`formq${index}CampaignError`, true);
                                            //   } else {
                                            //     console.log("No Freeform error")
                                            //     updateFormError(`formq${index}CampaignError`, false);
                                            //   }
                                            // }}
                                            onChange={(e) => {
                                              // handleCampaignFieldChange1([`q${index}`], e, "freeForm");
                                              handleCampaignFieldChange1(`freeFormq${index}`, e, "freeForm");
                                            }}
                                          />

                                        </div>

                                      ) : formq.type === "singleSelect" ? (
                                        <div className="menuDropDownParticipate" style={{ marginBottom: 20 }}>

                                          <label for="job">
                                            {/* {[`Q${index + 1}`]}. {formq[`q${index}`]} */}
                                            {formq[`q${index}`]}
                                          </label>

                                          <Select
                                            inputProps={{
                                              style: {
                                                width: "fit-content", // your custom styles for the native input

                                              },
                                            }}
                                            style={{
                                              width: "100%",
                                              // display:"inline-block",
                                              // position:"relative",
                                              fontSize: 14,
                                              height: 48,
                                              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                                              border: "1px solid #ccc",
                                              color: "#8a97a0",
                                              backgroundColor: "#e8eeef",
                                              marginBottom: "16px",
                                              // placeholder: "Select"
                                            }}
                                            value={campaignValues?.[`q${index}`]}
                                            placeholder="Select"
                                            onBlur={() => {
                                              if (!campaignValues?.[`q${index}`]) {
                                                setCampaignRegistrationValuesError({
                                                  ...campaignRegistrationValuesError,
                                                  // formq7CampaignError: true,
                                                  [`formq${index}CampaignError`]: true,
                                                });
                                              } else {
                                                setCampaignRegistrationValuesError({
                                                  ...campaignRegistrationValuesError,
                                                  // formq7CampaignError: false,
                                                  [`formq${index}CampaignError`]: false,
                                                });
                                              }
                                            }}
                                            onChange={(e) =>
                                              // handleCampaignFieldChange1([`q${index}`], e, "singleSelect")
                                              handleCampaignFieldChange1(`singleSelectq${index}`, e, "singleSelect")
                                            }
                                          >
                                            {formq.choices.map((item, index) => (
                                              <MenuItem style={{ fontSize: 16, overflow: "auto" }} value={item}>
                                                {item}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </div>
                                      ) :
                                        formq.type === "multiSelect" ? (
                                          // <div style={{ marginBottom: 20 }}>
                                          <div onKeyDown={handleKeyDown} style={{ marginBottom: 20 }}>
                                            <label for="job">
                                              {/* {[`Q${index + 1}`]}.  {formq[`q${index}`]} */}
                                              {formq[`q${index}`]}
                                            </label>

                                            <Multiselect
                                              displayValue="value"  // Set displayValue to the property containing the display values
                                              showCheckbox

                                              selectedValues={selectedOptions[index] || []}
                                              options={formq.choices.map((item, index) => ({ value: item, id: index }))}
                                              onSelect={(event) => {
                                                handleSelect(index, event);
                                                if (!event || event.length === 0) {
                                                  // updateFormError(`formq${index}MultiselectError`, true);
                                                  setCampaignRegistrationValuesError({
                                                    ...campaignRegistrationValuesError,
                                                    // formq7CampaignError: true,
                                                    [`formq${index}CampaignError`]: true,
                                                  });
                                                } else {
                                                  // updateFormError(`formq${index}MultiselectError`, false);
                                                  setCampaignRegistrationValuesError({
                                                    ...campaignRegistrationValuesError,
                                                    // formq7CampaignError: false,
                                                    [`formq${index}CampaignError`]: false,
                                                  });
                                                }
                                              }}
                                              onRemove={(event) => {
                                                handleRemove(index, event);
                                                if (!selected2[index] || selected2[index].length === 0) {
                                                  // updateFormError(`formq${index}MultiselectError`, true);
                                                  setCampaignRegistrationValuesError({
                                                    ...campaignRegistrationValuesError,
                                                    // formq7CampaignError: true,
                                                    [`formq${index}CampaignError`]: true,
                                                  });
                                                } else {
                                                  // updateFormError(`formq${index}MultiselectError`, false);
                                                  setCampaignRegistrationValuesError({
                                                    ...campaignRegistrationValuesError,
                                                    // formq7CampaignError: false,
                                                    [`formq${index}CampaignError`]: false,
                                                  });
                                                }
                                              }}
                                              // value={selected2[index] || []}
                                              value={selectedOptions[index] || []}

                                              style={{
                                                searchBox: {
                                                  minHeight: 54,
                                                  paddingTop: 10,
                                                  padding: "0px",
                                                  border: "0px",
                                                },

                                              }}
                                            />
                                            <div className="overlay"></div>
                                          </div>
                                        )
                                          : formq.type === "dateOfBirth" ? (
                                            <div style={{ marginBottom: 20 }}>
                                              <label for="job">
                                                {/* {[`Q${index + 1}`]}. {formq[`q${index}`]} */}
                                                {formq[`q${index}`]}
                                              </label>

                                              <DatePicker
                                                selected={selectedDate[`q${index}`]}

                                                // selected={selectedDate}
                                                onChange={(date) => {
                                                  handleDateChange(date, `dateq${index}`);
                                                  if (!date) {
                                                    // updateFormError(`formq${index}DateError`, true);
                                                    setCampaignRegistrationValuesError({
                                                      ...campaignRegistrationValuesError,
                                                      // formq7CampaignError: true,
                                                      [`formq${index}CampaignError`]: true,
                                                    });
                                                  } else {
                                                    // updateFormError(`formq${index}DateError`, false);
                                                    setCampaignRegistrationValuesError({
                                                      ...campaignRegistrationValuesError,
                                                      // formq7CampaignError: false,
                                                      [`formq${index}CampaignError`]: false,
                                                    });
                                                  }

                                                }}
                                                dateFormat="MM/dd/yyyy"
                                                placeholderText="Select Date"
                                                // maxDate={new Date()} 
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={15}
                                                timeInputLabel="Time:"
                                                showTimeInput={false}
                                                customInput={
                                                  <div
                                                    className="custom-datepicker-input"
                                                    style={{
                                                      background: '#e8eeef',
                                                      borderRadius: '5px',
                                                      color: '#8a97a0',
                                                      padding: '8px',
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      marginBottom: "16px",
                                                    }}
                                                  >
                                                    <input
                                                      style={{
                                                        border: 'none',
                                                        width: '100%',
                                                        outline: 'none',
                                                        background: '#e8eeef',
                                                      }}
                                                      value={selectedDate[`dateq${index}`] ? selectedDate[`dateq${index}`].toLocaleDateString() : ''}
                                                      readOnly
                                                    />
                                                    <CalendarMonthIcon style={{ marginLeft: '8px', backgroundColor: "white" }} />
                                                  </div>
                                                }
                                              />
                                            </div>

                                          ) : formq.type === "message" ? (
                                            <div style={{ marginBottom: 20 }}>
                                              <label for="job">
                                                <div style={{ wordWrap: "break-word" }} dangerouslySetInnerHTML={renderHTML(formq[`m${index}`])} />
                                              </label>
                                            </div>
                                          ) : formq.type === "uploadFiles" ? (
                                            <div>
                                              <label for="password">
                                                {/* {[`Q${index + 1}`]}. {formq[`u${index}`]} */}
                                                {formq[`u${index}`]}
                                              </label>

                                              {/* <UploadDocsParticipate camp={campaignList?.data?.[0]?.campid} /> */}
                                              <UploadDocsParticipate camp={campaignList?.data?.[0]?.campid} onFileUpload={handleFileUpload} />
                                              {
                                                !filesUploaded && (
                                                  // <ErrorMessage style={{marginLeft:"18px !important"}} message="Please upload file(s) here" />
                                                  <div>
                                                    <span style={{ marginLeft: "18px", color: "red", fontSize: "14px" }}>Please upload file(s) here</span>
                                                  </div>
                                                )
                                              }
                                              <br />
                                            </div>
                                          ) : ""
                                    ))
                                }
                              </div>
                            </div>
                          </div>
                          <div style={{ textAlign: "center" }}>
                            {
                              formSubmissionMessage && (
                                <ErrorMessage message={formSubmissionMessage} />
                              )
                            }

                          </div>

                          <div class="col-12" style={{ marginTop: "10px" }}>
                            <div class="text-center">
                              {(campRegFormQ?.data?.[0]?.qpattern === "4" ||
                                campRegFormQ?.data?.[0]?.qpattern === "6" ||
                                campRegFormQ?.data?.[0]?.qpattern === "11") &&
                                regStatusList?.data?.message ===
                                "Already Registered" ? (
                                <Button type="submit" class="reg-m-bttn-disabled" >
                                  Register
                                </Button>
                              ) : campaignList?.data?.[0]?.paused === "yes" ? (
                                <Button type="submit" class="reg-m-bttn-disabled">
                                  Register
                                </Button>
                              ) : (
                                <Button
                                  type="submit"
                                  class="reg-m-bttn"
                                  onClick={() => {
                                    handleRegister();
                                  }}
                                  style={{ backgroundColor: "#da8d0f", border: "1px solid #da8d0f", fontSize: "20px" }}
                                >
                                  {"Register".split("").join(" ")}
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div style={{ textAlign: "center", marginBottom: "15px" }}>
                  <div class="col-md-6 col-xs-12" style={{ margin: "0 auto" }}>
                    <div class="logged-cam-detai mob-space1">
                      <h4 style={{ textAlign: "left" }}>My Statistics</h4>
                      <div class="comm-backg" style={{ paddingBottom: "0px" }}>
                        <div class="points-rank">
                          <div class="row">
                            <div class="col-md-3 col-xs-6" style={{ paddingBottom: "20px" }}>
                              <div class="text-center">
                                <span> My Points</span>
                                <h6 class="cam-curr-point">
                                  {
                                    campaignList?.data?.[0]?.myrankpoints
                                      ?.rewardpoint
                                  }
                                </h6>
                              </div>
                            </div>
                            <div class="col-md-3 col-xs-6" style={{ paddingBottom: "20px" }}>
                              <div class="text-center">
                                <span> My Rank</span>
                                <h6 class="cam-curr-point">
                                  {
                                    campaignList?.data?.[0]?.myrankpoints
                                      ?.rank
                                  }
                                </h6>
                              </div>
                            </div>
                            <div class="col-md-3 col-xs-6" style={{ paddingBottom: "20px" }}>
                              <div class="text-center">
                                <span class="pp-p-text">
                                  Community Size
                                </span>
                                <h6 class="pp-point">
                                  {
                                    campaignList?.data?.[0]?.mycommunity
                                      ?.refcount
                                  }
                                </h6>
                              </div>
                            </div>
                            <div class="col-md-3 col-xs-6" style={{ paddingBottom: "20px" }}>
                              <div class="text-center">
                                <span class="pp-p-text">
                                  Community Points
                                </span>
                                <h6 class="pp-point">
                                  {
                                    campaignList?.data?.[0]?.mycommunity
                                      ?.rewardpoint
                                  }
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="container">
                  <div class="cyagg-sec-space">
                    <div>
                      <div class="row">
                        <div class="col-7">
                          <div>
                            <h4 class="site-title">Win Rewards!</h4>
                          </div>
                        </div>
                        <div class="col-5">
                          <div
                            style={{ textAlign: "right" }}
                            onClick={() => navigate(`/marketplace`)}
                          >
                            <a class="story-signin">
                              View More <i class="fa fa-arrow-right"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4">
                        <div>
                          <img
                            class="hide-on-mobile"
                            src={RewardMainImage}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                      <div class="col-md-8">
                        <div class="">
                          <OwlCarousel className="owl-theme" {...options}>
                            {marketplacelist &&
                              marketplacelist?.data?.map(
                                (reward, index) =>
                                  index < 5 && (
                                    <div class="item">
                                      <div class="reward-wrap">
                                        <div>

                                          {!(reward?.logo === "") ? (
                                            reward?.expired === "yes" ? (
                                              <img
                                                class="reward-imagehm"
                                                src={reward?.logo}
                                                style={{
                                                  width: "100%",
                                                  opacity: 0.4,
                                                }}
                                              />
                                            ) : (
                                              <img
                                                class="reward-imagehm"
                                                src={reward?.logo}
                                                style={{ width: "100%" }}
                                              />
                                            )
                                          ) : reward?.expired === "yes" ? (
                                            <img
                                              class="reward-imagehm"
                                              src={CEOImage}
                                              style={{
                                                width: "100%",
                                                opacity: 0.4,
                                              }}
                                            />
                                          ) : (
                                            <img
                                              class="reward-imagehm"
                                              src={CEOImage}
                                              style={{ width: "100%" }}
                                            />
                                          )}
                                        </div>
                                        <div class="reward-details">
                                          <div>
                                            <div>
                                              <div id="toggleAccordion">
                                                <div class="card mb-1">
                                                  <div
                                                    class="card-header"
                                                    id="headingOne4"
                                                  >
                                                    <h5 class="mb-0 mt-0 reward-desc-title">
                                                      <span
                                                        role="menu"
                                                        class=""
                                                        data-toggle="collapse"
                                                        data-target={"#" + index}
                                                        aria-expanded="true"
                                                        aria-controls="iconChangeAccordionOne"
                                                      >
                                                        {reward?.rewardtype}
                                                        <i class="flaticon-down-arrow float-right"></i>
                                                      </span>
                                                    </h5>
                                                  </div>

                                                  <div
                                                    id={index}
                                                    class="collapse"
                                                    aria-labelledby="headingOne4"
                                                    data-parent="#toggleAccordion"
                                                  >
                                                    <div class="card-body">
                                                      <p class="mb-3">
                                                        <div>
                                                          <span
                                                            style={{
                                                              fontSize: 12,
                                                              color: "blue",
                                                            }}
                                                          >
                                                            {" "}
                                                            {reward?.subtitle}{" "}
                                                          </span>
                                                        </div>
                                                      </p>
                                                      <p class="mb-3">
                                                        <div>
                                                          <span
                                                            style={{
                                                              fontSize: 12,
                                                              color: "green",
                                                            }}
                                                          >
                                                            {" "}
                                                            {reward?.title}{" "}
                                                          </span>
                                                        </div>
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="redeem-pts">
                                              <div class="row">
                                              </div>
                                            </div>
                                            <div class="text-center">
                                              <h6 class="sponsor-title">
                                                Sponsors
                                              </h6>
                                              <div class="row sponserWrapper">
                                                <div class="">
                                                  {isArray(reward?.sponsor) &&
                                                    reward?.sponsor?.map(
                                                      (reward, index) => (
                                                        <img
                                                          style={{
                                                            width: "auto",
                                                            height: "50px",
                                                          }}
                                                          src={reward?.sponsorlogo}
                                                        />
                                                      )
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                              )}
                          </OwlCarousel>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



              </div>
              ): 
              <div>
                <div class="reg-banner" style={{ marginTop: "10px" }}>
                          <div class="container">
                            <div class="row"></div>
                Sorry you do not have access to view this page
                </div>
                </div>
                </div>
            }



          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
};

export default Participate;