import * as React from "react";
import { Button, CircularProgress, MenuItem, Select, Avatar, TextField, Typography, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import Multiselect from 'multiselect-react-dropdown';
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import {
  focusAreaDict,
  registeredAs,
  restrictedCountry,
} from "../../utils/dict";
import { getOrganizationReset } from "../../redux/action/getOrganization";
import { MenuProps, states } from "../../utils/constants";
import { AddCircleOutlineOutlined, RemoveCircleOutlineOutlined } from "@mui/icons-material";
import { getCity } from "../../redux/action/cityAction";
import _ from "lodash";
import ErrorMessage from "../../components/ErrorMessage";
import RedStar from "../../components/RedStar";
import BasicPopover from "../../components/InformationPopup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { height } from "@mui/system";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  updateOrg,
  updateOrgReset,
} from "../../redux/action/UpdateAction";
import IconButton from "@mui/material/IconButton";
import { getOrgProfileLogo } from "../../redux/action/orgLogoAction";
import axios from "axios";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from "@mui/material/InputAdornment";
import DoneIcon from '@mui/icons-material/Done';
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import styled, { keyframes } from 'styled-components';

const blinking = keyframes`
  0% { filter: brightness(0.9); }
  50% { filter: brightness(1.1); }
  100% { filter: brightness(1); }
`;

const BlinkingSVG = styled.svg`
  cursor: pointer;
  animation: ${blinking} 1s infinite;
`;

const SVGCampLeaderboard = (props) => (
  <BlinkingSVG width="40" height="40" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <rect width="40" height="40" fill="url(#pattern0_231_5)" />
    <defs>
      <pattern id="pattern0_231_5" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_231_5" transform="scale(0.02)" />
      </pattern>
      <image id="image0_231_5" width="50" height="50" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADPklEQVR4nO2Y20+SYRzHadnW/9PWVRsXvFt31tq8wHnAWWBBaFlgIIqkIWLiAhEUrAulPKQiMkhF8Ihazo43WRq1aalbuixMgV97XivFUHhBfNf2fLfPxgaM3+f5PacXBgMHBwcHBwcHh4yXIMReFmvDy2JBIkwQhH+cxbrKoCPAYBzzEsTmBz4flqTShJjLy0My67SIuJnMFDSaKzIZ+DWahFiSSFBnArSIPOOeOoFEPgqFCXfEJxAgkSAtIu5s5slE10YYBAH0iJQyU7rL2LDSWw0wakqIpZ4q6C5j09QRLGJKTkfS0tqOn5fYTqdKewkqXJB0ndXLr4GvWxN16nzruwebHkNyp9Y5qd2aKrFDvIxZ9PsW6Hfp4GWTCHoU6bBsUydXJFViD0pa5kHtWKXMQSI/3fWkANoQEEchAsWtn5IiMqkXkh2JJvK8UwmF+oIQx1w0SpUcs9iZaRafIUV42mkQNr6mTLSphZh7JI8q4u2ogCyTCG5ZVZQpaC0LccziRQav+D5cLm0OXZFbglThy5tDrzoOT8Q42UQZlUsL2SZRgHFYhxoWGd3pSFaccEwiiNiRrSEjzFpkMFEngAm9EN62yCAwZExaR7669DD2uCIu+pql0KDMCUUUmWkshCcqDkwbr4O3TkAWMmXIT5rIfmwM1oGv/TY5kL52BbkT7rt1RxL5bK2EwPBOB1BXrIp0CA43UC5msUsJY1o+rDqj3wBgD/1VuTCgzoWhWh75+/aKTFhz1sYuspcZ0w2wKthxiSTC2q6ifxcML0w34xNZ79eSIzGu4x+pBESoA9X65kERdZFVh4ZcK87KHPg+oDvy4rc8BvhiVZHrw13DA1f1RfAP6qmJLHTdAVt5BrhruPCDBglAu5lD8/euhmYFWvR7p/eBIss9anJxTdXnhy16utj0GGC+TUFeQp8aCmIX8Wi44FBmk88RaG7+gW6p4do86C3PiF1k9/V7N/GcBfHid+nC1iUaVDS90FYcswh6MxIHPeUdNsu27ek9ePcSeGq45GtnJSexc4QOQiPbMu8flsI7SwksdCohOPLvORYmgj6MvvQ/Mmsp2RH575Gz6fm7FQcHBwcHB4eRnPwCG4GUygmgFQIAAAAASUVORK5CYII=" />
    </defs>
  </BlinkingSVG>
);

const HomeScreenMainContent = styled(Box)(({ theme }) => ({
  padding: '80px 0 100px 0px',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    padding: '80px 0 100px 330px',
  },
})); 

const FormGroup0 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#F0F8FF',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// Styled component for formGroup00
const FormGroup00 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#FAEBD7',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

const FormGroup = styled('div')(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: '40px',
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));


const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup1
const FormGroup1 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup2
const FormGroup2 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  flexDirection: 'column',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '75px 0 0 16px',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup3
const FormGroup3 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  width: '20%',
  '@media (max-width: 1200px)': {
    width: '20%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup4
const FormGroup4 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'right',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 0',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup7
const FormGroup7 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup8
const FormGroup8 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledLabel
const StyledLabel = styled('label')(({ theme }) => ({
  display: 'flex',
  background: '#e9e7e7',
  padding: '10px',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 600,
  marginTop: '16px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

// StyledSpan
const StyledSpan = styled('span')(() => ({
  fontSize: '12px',
}));

// FocusThematic
const FocusThematic = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledInput
const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// StyledTextarea
const StyledTextarea = styled('textarea')(() => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// LogoButton
const LogoButton = styled('input')(() => ({
  width: '8%',
  border: 'none',
  padding: '10px',
  display: 'flex',
  height: '40px',
  color: 'white',
  '@media (max-width: 1920px)': {
    fontSize: '15px',
    width: '12%',
  },
  '@media (max-width: 1280px)': {
    fontSize: '15px',
    width: '15%',
  },
  '@media (max-width: 960px)': {
    fontSize: '15px',
    width: '20%',
  },
  '@media (max-width: 600px)': {
    fontSize: '15px',
    width: '30%',
  },
}));

// StyledTypography
const StyledTypography = styled(Typography)(({ theme }) => ({
  '& .css-u4tvz2-MuiFormLabel-root': {
    fontFamily: 'Poppins',
  },
  display: 'block',
  display: 'flex',
  fontSize: '16px',
  marginTop: '16px',
  fontWeight: 500,
  lineHeight: '18px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

const LoginPgLogo = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (max-width: 768px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
}));

const StyledLargeAvatar = styled(Avatar)(({ theme }) => ({
  width: '200px !important',
  height: '200px !important',
  margin: '0 auto',
  marginTop: '5px',
  marginBottom: '5px',
  '@media (max-width: 400px)': {
    width: '120px !important',
    height: '120px !important',
  },
  '@media (max-width: 600px)': {
    width: '140px !important',
    height: '140px !important',
  },
  '@media (max-width: 768px)': {
    width: '150px !important',
    height: '150px !important',
  },
}));


function EditOrganizationScreen(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationPath = useLocation();
  const [image, setImage] = React.useState(null);
  const inputRef = React.useRef(null);
  const [cityResponse, setCityResponse] = React.useState({});
  const [newField, setNewField] = React.useState(false);
  const [addressId, setAddressId] = React.useState(0);
  const [address, setAddress] = React.useState("");
  const [oldaddress, setOldAddress] = React.useState("");
  const [addressList, setAddressList] = React.useState([addressId]);
  const [thematicFocusAreaError, setThematicFocusAreaError] = React.useState("");

  // const [notificationShow, setNotificationShow] = React.useState(false);
  //const [image,setImage] = React.useState('');
  const [organizationValues, setOrganizationValues] = React.useState({
    titleOfOrganization: "",
    totalStaff: "",
    headquarters: "",
    // organizationLogo: "",
    registeredAs: "",
    website: "",
    emailId: "",
    contactNo: "",
    totalNumberofVolunteers: "",
    chiefFunctionary: "",
  });
  const [organizationValuesError, setOrganizationValuesError] = React.useState({
    titleOfOrganizationError: false,
    totalStaffError: false,
    headquartersError: false,
    // organizationLogoError: false,
    registeredAsError: false,
    websiteError: false,
    emailIdError: false,
    contactNoError: false,
    totalNumberofVolunteersError: false,
    chiefFunctionaryError: false,
  });
  const [focusError, setFocusError] = React.useState(false);
  const [focusArea, setFocusArea] = React.useState([
    { focus: "No Poverty", isAdded: false },
    { focus: "Zero Hunger", isAdded: false },
    { focus: "Good Health and Well-Being", isAdded: false },
    { focus: "Quality Education", isAdded: false },
    { focus: "Gender Equality", isAdded: false },
    { focus: "Clean Water and Sanitation", isAdded: false },
    { focus: "Affordable and clean energy", isAdded: false },
    { focus: "Decent work and economic growth", isAdded: false },
    { focus: "Industry Innovation and Infrastructure", isAdded: false },
    { focus: "Reduced Inequalities", isAdded: false },
    { focus: "Sustainable cities and communities", isAdded: false },
    { focus: "Responsible Consumption and Production", isAdded: false },
    { focus: "Climate Action", isAdded: false },
    { focus: "Life Below Water", isAdded: false },
    { focus: "Life on Land", isAdded: false },
    { focus: "Peace, Justice and Strong Institutions", isAdded: false },
    {
      focus: "Partnerships for achieving Sustainable Development Goals",
      isAdded: false,
    },
  ]);
  const [selected, setSelected] = React.useState([]);

  const [location, setLocation] = React.useState([
    { country: "India", state: "", city: "", area: "", cityList: "" },
  ]);
  const [locationError, setLocationError] = React.useState([
    { country: false, state: false, city: false, area: false },
  ]);

  // const {
  //   isOrganizationSuccess,
  //   isOrganizationFetching,
  //   isOrganizationError,
  //   organizationResponse,
  // } = useSelector((state) => state.onBoard);

  const {
    isOrganizationSuccess,
    isOrganizationFetching,
    isOrganizationError,
    organizationResponse,
  } = useSelector((state) => state.updateOrg);

  const { isSuccess: citiesSuccess, response: citiesResponse } = useSelector(
    (state) => state.cities
  );

  // const resetFile = (event) => {
  //   inputRef.current.value = null;
  // };

  React.useEffect(() => {

    dispatch(updateOrgReset());

  }, []);
  React.useEffect(() => {
    // console.log(campaignValues?.city)
    // console.log(campaignValues)
    // console.log(locationPath?.state?.location?.[0]?.city)
    if (locationPath?.state?.location?.[0]?.city) {
      dispatch(getCity(locationPath?.state?.location?.[0]?.state));

    }
  }, []);

  const [selectedFocusOption, setSelectedFocusOption] = React.useState([]);

  // const focusOptions = [
  //   { focus: "Water & Sanitation" },
  //   { focus: "Education" },
  //   { focus: "Climate Change and Sustainability" },
  //   { focus: "Poverty Alleviation" },
  //   { focus: "Teaching / Mentoring" },
  //   { focus: "Rural Development" },
  //   { focus: "Women Empowerment" },
  //   { focus: "Child Rights" },
  //   { focus: "Animal Rights" },
  // ];

  const focusOptions = [

    { focus: "No Poverty" },
    { focus: "Zero Hunger" },
    { focus: "Good Health and Well-Being" },
    { focus: "Quality Education" },
    { focus: "Gender Equality" },
    { focus: "Clean Water and Sanitation" },
    { focus: "Affordable and Clean Energy" },
    { focus: "Decent Work and Economic Growth" },
    { focus: "Industry Innovation and Infrastructure" },
    { focus: "Reduced Inequalities" },
    { focus: "Sustainable Cities and Communities" },
    { focus: "Responsible Consumption and Production" },
    { focus: "Climate Action" },
    { focus: "Life Below Water" },
    { focus: "Life on Land" },
    { focus: "Peace, Justice and Strong Institutions" },
    { focus: "Partnerships for the Goals" },

  ]

  const getStateList = async (i, valll) => {
    try {
      const res = await axios.get(`/statecity/${valll}`).then((response) => {
        const values = [...location];
        values[i]["cityList"] = response?.data;
        return response?.data;
      });

    } catch (err) {
      throw err;
    }
  };
  // const updatelocation = (index, address) => {
  //   console.log("addressin updatelocation");
  //   console.log(address);
  //   const values = [...location];
  //   if (!address) {
  //     console.log("address empty")
  //     if (values[index]["added"] === "no") {
  //     setOrganizationValuesError({
  //       ...organizationValuesError,
  //       locationError: true,
  //     });
  //   }
  //     const values = [...location];
  //     // values[index]["country"] = "";
  //     // values[index]["state"] = "";
  //     // values[index]["city"] = "";
  //     // values[index]["area"] = "";
  //     // values[index]["added"] = "no";
  //   } else {
  //     setOrganizationValuesError({
  //       ...organizationValuesError,
  //       locationError: false,
  //     });
  //     values[index]["country"] = String(address.split(",")[(address.split(",").length - 1)]).trim(" ");
  //     values[index]["state"] = String(address.split(",")[(address.split(",").length - 2)]).trim(" ");
  //     values[index]["city"] = String(address.split(",")[(address.split(",").length - 3)]).trim(" ");
  //     values[index]["area"] = String(address.split(",")[(address.split(",").length - 4)]).trim(" ");
  //     values[index]["added"] = "yes";
  //     if (values[index]["state"] === "undefined" && values[index]["city"] === "undefined"
  //         && values[index]["area"] === "undefined") {
  //         values[index]["added"] = "no";
  //         setOrganizationValuesError({
  //           ...organizationValuesError,
  //           locationError: true,
  //         });
  //       }

  //       if (values[index]["state"] === "undefined") {
  //         values[index]["state"] = ""
  //       }
  //       if (values[index]["city"] === "undefined") {
  //         values[index]["city"] = ""
  //       }
  //       if (values[index]["area"] === "undefined") {
  //         values[index]["area"] = ""
  //       }
  //     setLocation(values);
  //     setOldAddress(address);
  //   }
  //   console.log("location in updatelocation");
  //   console.log(location);
  //   console.log("len of location in updatelocation");
  //   console.log(location.length);
  // };


  const updatelocation = (index, address) => {

    const values = [...location];
    if (!address) {

      if (values[index]["added"] === "no") {
        setOrganizationValuesError({
          ...organizationValuesError,
          locationError: true,
        });
      }
      const values = [...location];
      // values[index]["country"] = "";
      // values[index]["state"] = "";
      // values[index]["city"] = "";
      // values[index]["area"] = "";
      // values[index]["added"] = "no";
    } else {
      setOrganizationValuesError({
        ...organizationValuesError,
        locationError: false,
      });
      values[index]["country"] = String(address.split(",")[(address.split(",").length - 1)]).trim(" ");
      values[index]["state"] = String(address.split(",")[(address.split(",").length - 2)]).trim(" ");
      values[index]["city"] = String(address.split(",")[(address.split(",").length - 3)]).trim(" ");
      values[index]["area"] = String(address.split(",")[(address.split(",").length - 4)]).trim(" ");
      values[index]["added"] = "yes";

      if (values[index]["state"] === "undefined" && values[index]["city"] === "undefined"
        && values[index]["area"] === "undefined") {
        values[index]["added"] = "no";
        setOrganizationValuesError({
          ...organizationValuesError,
          locationError: true,
        });
      }

      if (values[index]["state"] === "undefined") {
        values[index]["state"] = ""
      }
      if (values[index]["city"] === "undefined") {
        values[index]["city"] = ""
      }
      if (values[index]["area"] === "undefined") {
        values[index]["area"] = ""
      }
      if (values[index]["state"] == "" && values[index]["city"] == "" && values[index]["area"] == "") {
        setOrganizationValuesError({
          ...organizationValuesError,
          locationError: true,
        });
        values[index]["added"] = "no";
        // console.log(organizationValuesError)
      } else {
        setLocation(values);
        setOldAddress(address);
      }
    }

  };


  const handleAddFields = () => {

    let errorcount = 0;
    for (let i = 0; i < location.length; i += 1) {


      if (location[i]["country"] == "" || location[i]["state"] == "" || location[i]["city"] == "" ||
        location[i]["added"] == "no") {
        setNewField(false);
        errorcount = errorcount + 1;
      }
    }
    if (errorcount == 0) {
      setNewField(true);
      // setLocation([...location, { country: "", state: "", city: "", area: "", cityList: "" }]);
      setLocation([...location, { country: "", state: "", city: "", area: "", added: "no" }]);
    } else {
      setNewField(false);
    }


    // setRewardtitle([...rewardtitle, { rewardtype: "", title: "", subtitle: "", sponsor: "", titleList: "" }])
  };


  const handleOnboardOrganization = () => {


    if (!organizationValues?.titleOfOrganization) {
      setOrganizationValuesError({
        ...organizationValuesError,
        titleOfOrganizationError: true,
      });
      dispatch(updateOrgReset());
      window.scrollTo(0, 400);
    } else if (!organizationValues?.totalStaff) {
      setOrganizationValuesError({
        ...organizationValuesError,
        totalStaffError: true,
      });
      dispatch(updateOrgReset());
      window.scrollTo(0, 400);
    } else if (!organizationValues?.totalNumberofVolunteers) {
      setOrganizationValuesError({
        ...organizationValuesError,
        totalNumberofVolunteersError: true,
      });
      dispatch(updateOrgReset());
      window.scrollTo(0, 400);
    } else if (!organizationValues?.headquarters) {
      setOrganizationValuesError({
        ...organizationValuesError,
        headquartersError: true,
      });
      dispatch(updateOrgReset());
      window.scrollTo(0, 400);
    } else if (!organizationValues?.chiefFunctionary) {
      setOrganizationValuesError({
        ...organizationValuesError,
        chiefFunctionaryError: true,
      });
      dispatch(updateOrgReset());
      window.scrollTo(0, 400);
    } else if (!organizationValues?.contactNo) {
      setOrganizationValuesError({
        ...organizationValuesError,
        contactNoError: true,
      });
      dispatch(updateOrgReset());
      window.scrollTo(0, 400);
    } else if (!organizationValues?.emailId) {
      setOrganizationValuesError({
        ...organizationValuesError,
        emailIdError: true,
      });
      dispatch(updateOrgReset());
      window.scrollTo(0, 400);
    } else if (!organizationValues?.totalNumberofVolunteers) {
      setOrganizationValuesError({
        ...organizationValuesError,
        totalNumberofVolunteersError: true,
      });
      dispatch(updateOrgReset());
      window.scrollTo(0, 600);
    } else if (!organizationValues?.website) {
      setOrganizationValuesError({
        ...organizationValuesError,
        websiteError: true,
      });
      dispatch(updateOrgReset());
      window.scrollTo(0, 0);
    } else if (thematicFocusAreaError === "true") {

      dispatch(updateOrgReset());
      window.scrollTo(0, 600);
    } else if (organizationValuesError?.locationError) {
      setOrganizationValuesError({
        ...organizationValuesError,
        locationError: true,
      });
      dispatch(updateOrgReset());
      window.scrollTo(1500, 1500);
    }

    else {
      dispatch(updateOrgReset());
      let formData = new FormData();
      formData.append("organization", organizationValues?.titleOfOrganization);
      formData.append("noofemployees", organizationValues?.totalStaff);
      formData.append("orghq", organizationValues?.headquarters);
      //   formData.append("organizationlogo", organizationValues?.organizationLogo);
      formData.append("registeredas", organizationValues?.registeredAs);
      formData.append("website", organizationValues?.website);
      formData.append("chieffunctionary", organizationValues?.chiefFunctionary);
      formData.append("contactnumber", organizationValues?.contactNo);
      formData.append("emailid", organizationValues?.emailId);
      formData.append("_noofvol", organizationValues?.totalNumberofVolunteers);
      formData.append("orgfocusarea", JSON.stringify(selectedFocusOption));
      // formData.append("orgfocusarea", JSON.stringify(selectedSOrgFocusArea));

      formData.append("location", JSON.stringify(location));

      //   formData.append("location", JSON.stringify(location));
      dispatch(
        updateOrg({
          clientid: organizationValues?.clientid,
          userid: localStorage.getItem("userid"),
          formData,
        })
      );
    }
  };
  const handleOrganizationFieldChange = (field, e) => {
    const re = /^[0-9\b]+$/;
    // if (e.target.value === '' || re.test(e.target.value)) {

    (isOrganizationError || isOrganizationSuccess) && dispatch(updateOrgReset());
    setOrganizationValues({
      ...organizationValues,
      [field]:
        field === "organizationlogo" ? e.target.files[0] : e.target.value,

    });
    // }
  };

  const handleOrganizationFieldValidation = (field, e) => {
    // alert("enter")
    const re = /^[0-9\b]+$/;
    // alert(e.target.value)
    if (e.target.value === '' || re.test(e.target.value)) {
      // alert("true")

      handleOrganizationFieldChange(field, e);

      // });
    }
  };

  const onAddingItem = (event, option, index) => {
    const values = [...focusArea];
    values[index].isAdded = event.target.checked;
    setFocusArea(values);
  };

  const handleChangeLocation = (id, e, field, index) => {
    const values = [...location];
    if (field === 'state') {
      values[index]["cityList"] = cityResponse;
    }

    values[index][field] = e.target.value;
    setLocation(values);
  };

  const handleChange = (event, index) => {
    const value = event.target.value;

    setSelected([...selected, value[value?.length - 1]]);
  };

  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const handleSelect = async (value) => {

    // console.log(value);
    const results = await geocodeByAddress(value);
    // console.log(results[0]["formatted_address"]);
    setAddress(results[0]["formatted_address"]);

    // console.log(value);
  }

  const handleRemoveFields = (i) => {
    let newFormValues = [...location];
    newFormValues.splice(i, 1);
    setLocation(newFormValues);
  };
  React.useEffect(() => {
    // console.log(locationPath)
    if (locationPath?.state?.orgfocusarea) {
      // setselectedSOrgFocusArea(locationPath?.state?.orgfocusarea);
      setSelectedFocusOption(locationPath?.state?.orgfocusarea);

    }
    // if (locationPath?.state?.rewardstobeearned) {
    //   setSelectedRewards(locationPath?.state?.rewardstobeearned);
    // }
    if (locationPath?.state) {
      setOrganizationValues({
        titleOfOrganization: locationPath?.state?.organization,
        totalStaff: locationPath?.state?.noofemployees,
        headquarters: locationPath?.state?.orghq,
        // organizationLogo: locationPath?.state?,
        registeredAs: locationPath?.state?.registeredas,
        website: locationPath?.state?.website,
        emailId: locationPath?.state?.emailid,
        contactNo: locationPath?.state?.contactnumber,
        totalNumberofVolunteers: locationPath?.state?.noofvol,
        chiefFunctionary: locationPath?.state?.chieffunctionary,
        // OrgFocusArea: locationPath?.state?.selectedSOrgFocusArea,
        OrgFocusArea: locationPath?.state?.orgfocusarea,
        clientid: locationPath?.state?.clientid,
      });
      setLocation(locationPath?.state?.location);
      // setFocusArea(JSON.parse(locationPath?.state?.rewardstobeearned))
    }
  }, [locationPath?.state]);

  const [selectedSOrgFocusArea, setselectedSOrgFocusArea] = React.useState([]);

  /* For profile image handling */

  const [loading, setLoading] = React.useState(false);
  // const [image, setImage] = React.useState(null);
  const [show, setShow] = React.useState(false);

  const {
    isSuccess: profileImageSuccess,
    response: profileImageResponse,
    isError: profileImageError,
  } = useSelector((state) => state.profileImage);

  const {
    isSuccess: orgLogoSuccess,
    response: orgLogoResponse,
    isError: orgLogoError,
  } = useSelector((state) => state.getOrgProfileLogo);

  const handleChangeFile = (e) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("organizationlogo", e.target.files[0]);
    dispatch(
      getOrgProfileLogo({ clientid: locationPath?.state?.clientid, userId: localStorage.getItem("userid"), formData })
    );
    setImage(e.target.files[0]);
  };

  // React.useEffect(() => {
  //   if (profileImageSuccess || profileImageError) {
  //     setLoading(false);
  //   }
  // }, [profileImageorganizationValuesSuccess, profileImageError]);

  React.useEffect(() => {
    if (orgLogoSuccess || orgLogoError) {
      setLoading(false);
    }
  }, [orgLogoSuccess, orgLogoError]);



  // console.log(organizationValues);
  //   console.log(locationPath);
  const getAddress = (item, index) => {
    // console.log("address in getadress");
    // console.log(address);
    return (
      <div style={{ float: "left", width: "75%", marginTop: 50 }}>
        <form style={{ display: "flex", flexWrap: "wrap", width: "100%", float: "left" }}>
          {
            (index == location.length - 1 && location[index]?.country === "") ? (
              <FormGroup3>
                <StyledLabel htmlFor="create-yaad--title" >
                  Country
                </StyledLabel>
                {!(address && index > 0 && location[index]?.added == "no" && index == location.length - 1 && address == oldaddress) ? (
                  <span >{address.split(",")[(address.split(",").length - 1)]}</span>
                ) :
                  <span style={{ fontSize: 12 }}>Search location</span>
                }
              </FormGroup3>
            )
              :
              <FormGroup3>
                <StyledLabel>
                  Country
                </StyledLabel>
                <span >{location[index]?.country}</span>
              </FormGroup3>
          }
          {
            (index == location.length - 1 && location[index]?.state === "") ? (
              <FormGroup3>
                <StyledLabel>
                  State
                </StyledLabel>
                {!(address && index > 0 && location[index]?.added == "no" && index == location.length - 1 && address == oldaddress) ? (
                  <span >{address.split(",")[(address.split(",").length - 2)]}</span>
                ) :
                  <span style={{ fontSize: 12 }}>Search location</span>
                }
              </FormGroup3>
            ) :
              <FormGroup3>
                <StyledLabel>
                  State
                </StyledLabel>
                {address ? (
                  <span >{location[index]?.state}</span>
                ) : !address ? (
                  <span>{item.state}</span>
                ) :
                  <span style={{ fontSize: 12 }}>Search location</span>
                }
              </FormGroup3>
          }

          {
            (index == location.length - 1 && location[index]?.state === "") ? (

              <FormGroup3>
                <StyledLabel>
                  City/Town
                </StyledLabel>
                {!(address && index > 0 && location[index]?.added == "no" && index == location.length - 1 && address == oldaddress) ? (
                  <span >{address.split(",")[(address.split(",").length - 3)]}</span>
                ) :
                  <span style={{ fontSize: 12 }}>Search location</span>
                }
              </FormGroup3>
            ) :
              <FormGroup3>
                <StyledLabel>
                  City/Town
                </StyledLabel>
                {address ? (
                  <span >{location[index]?.city}</span>
                ) : !address ? (
                  <span>{item.city}</span>
                ) :
                  <span style={{ fontSize: 12 }}>Search location</span>
                }
              </FormGroup3>
          }

          {
            (index == location.length - 1 && location[index]?.area === "") ? (

              <FormGroup3>
                <StyledLabel>
                  Area
                </StyledLabel>
                {!(address && index > 0 && location[index]?.added == "no" && index == location.length - 1 && address == oldaddress) ? (
                  <span >{address.split(",")[(address.split(",").length - 4)]}</span>
                ) :
                  <span style={{ fontSize: 12 }}>Search location</span>
                }
              </FormGroup3>
            ) :

              <FormGroup3>
                <StyledLabel>
                  Area
                </StyledLabel>

                {address ? (
                  <span >{location[index]?.area}</span>
                ) : !address ? (
                  <span>{item.area}</span>
                ) :
                  <span style={{ fontSize: 12 }}>Search location</span>
                }
              </FormGroup3>
          }
          <FormGroup3>
            <StyledLabel>
              Added
            </StyledLabel>
            {location[index]?.added == "yes" ? (
              <DoneIcon style={{ color: "green", }} />
            ) :
              ""
            }
          </FormGroup3>

          <FormGroup4>
            <Button
              variant="contained"
              onClick={() => updatelocation(index, address)}
              // className="btn save-btn"
              disableElevation
              style={{
                width: "50px",
                height: "35px",
                background: "#f5eceb",
                color: "green",
                fontSize: 16,
                fontFamily: "Poppins !important",

              }}

            >Add
            </Button>
          </FormGroup4>
          {(index === 0) ? (

            <FormGroup7>
              <Button

                variant="contained"
                onClick={() => handleRemoveFields(index)}
                disableElevation
                disabled

                style={{
                  width: "92px",
                  height: "35px",
                  background: "gray",
                  color: "#DC143C",
                  fontSize: 16,
                  fontFamily: "Poppins !important",
                }}
              >
                Delete
              </Button>
            </FormGroup7>
          ) :
            <FormGroup7>
              <Button
                variant="contained"
                onClick={() => handleRemoveFields(index)}
                // className="btn save-btn"
                disableElevation
                style={{
                  width: "92px",
                  height: "35px",
                  background: "#f5eceb",
                  color: "red",
                  fontSize: 16,
                  fontFamily: "Poppins !important",
                }}
              >
                Delete
              </Button>

            </FormGroup7>
          }

        </form>
        {
          index ?
            <div style={{ float: "right", position: "relative", bottom: 44, cursor: "pointer", left: 19 }} onClick={(e) => handleRemoveFields(index)}><RemoveCircleOutlineOutlined></RemoveCircleOutlineOutlined></div>
            : <div className="handlePlus" style={{ float: "right", bottom: 45, position: "relative", left: 20, cursor: "pointer", right: "15px" }}><AddCircleOutlineOutlined
              onClick={handleAddFields}
            ></AddCircleOutlineOutlined></div>
        }
      </div>
    );
  };


  React.useEffect(() => {
    if (organizationValues?.stateofOrganization) {
      dispatch(getCity(organizationValues?.stateofOrganization));
    }
  }, [organizationValues?.stateofOrganization]);

  // React.useEffect(() => {
  //   if (isOrganizationSuccess && organizationResponse?.status === "success") {
  //     setOrganizationValues({
  //       titleOfOrganization: "",
  //       descriptionOfOrganization: "",
  //       volunteerCount: "",
  //       organizationLogo: "",
  //       registeredAs: "",
  //       website: "",
  //       emailId: "",
  //       contactNo: "",
  //       totalNumberofVolunteers: "",
  //       chiefFunctionary: "",
  //       headquarters: "",
  //       totalStaff: "",
  //     });
  //     // resetFile();
  //     setSelected([]);
  //     setLocation([{ country: "", state: "", city: "", area: "" }]);
  //   }
  // }, [isOrganizationSuccess]);

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />

      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <div style={{ fontFamily: "monospace", fontSize: 16, }}>
              <Button
                onClick={() => navigate(-1)}
                className="backWrapper"
              >
                <i className={`fa fa-caret-left`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
              </Button>
              {/* <div style={{display: "flex", width: "75%", justifyContent: "flex-end", marginRight: 5, marginBottom: 20, }}>               
          <span style={{ color: "red" }}>Please do not leave this page until you submit as you may risk losing the partially filled in form </span>
          </div> */}
            </div>
            {/* <span style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>
          Edit Organization
        </span> */}
            <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Edit Organization</div>


            {locationPath &&
              (loading ? (
                <CircularProgress color="inherit" size={16} />
                // <CircularProgress color="blue" size={16} />
              ) : (
                <div style={{
                  padding: "10px 0",
                  // height: "50%",
                  // boxShadow: "1px 1px 1px 5px #f0f0f0",
                  borderRadius: 30,
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "center",
                  alignItems: "center",
                  // backgroundColor: "#fff"
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #dadce0",
                }}>
                  <span ><b>Update Organization Logo</b></span>
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    // justifyContent= "center"
                    >
                      <Avatar
                        src={
                          image
                            ? URL.createObjectURL(image)
                            : locationPath?.state?.organizationlogo
                          // : organizationValues?.organizationLogo
                        }
                        style={{
                          justifyContent: "center", alignItems: "center", width: "150px !important",
                          height: "150px !important",
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      />
                    </IconButton>
                  </label>
                  <input
                    name="Upload your image"
                    accept="image/*"
                    style={{
                      width: "15%",
                      border: "none",
                      padding: 10,
                      display: "flex",
                      height: 40,
                      color: "#949494",
                    }}
                    id="icon-button-file"
                    type="file"
                    onChange={handleChangeFile}
                    width="20"
                  />
                </div>
              ))}



            <form autoComplete="off">
              <div style={{ border: "1px solid #dadce0", borderRadius: 8, marginTop: 15, backgroundColor: "#fff" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    marginTop: 15,
                  }}
                >
                  <FormGroup>
                    <StyledLabel> Name of Organisation <RedStar /></StyledLabel>
                    <StyledInput
                      // value={organizationValues?.titleOfOrganization}
                      defaultValue={organizationValues?.titleOfOrganization}
                      type="text"
                      placeholder="Your answer"
                      onBlur={() => {
                        if (!organizationValues?.titleOfOrganization) {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            titleOfOrganizationError: true,
                          });
                        } else {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            titleOfOrganizationError: false,
                          });
                        }
                      }}
                      onChange={(e) => {
                        // handleOrganizationFieldChange("titleOfOrganization", e);
                        handleOrganizationFieldChange("titleOfOrganization", e);
                      }}
                    />
                    {organizationValuesError?.titleOfOrganizationError && (
                      <ErrorMessage message="Please enter the title of organization" />
                    )}
                  </FormGroup>

                  <FormGroup1>
                    <StyledLabel>
                      Registered as <RedStar />
                      <BasicPopover content="Please chose the registration type for your organization" />
                    </StyledLabel>
                    {/* <BasicPopover content="Please chose the registration type for your organization" /> */}
                    <Select
                      onBlur={() => {
                        if (!organizationValues?.registeredAs) {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            registeredAsError: true,
                          });
                        } else {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            registeredAsError: false,
                          });
                        }
                      }}
                      style={{
                        width: "100%",
                        fontSize: 14,
                        height: 56,
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                        border: "none",
                      }}
                      value={organizationValues?.registeredAs}
                      onChange={(e) => {
                        if (!organizationValues?.registeredAs) {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            registeredAsError: true,
                          });
                        } else {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            registeredAsError: false,
                          });
                        }
                        handleOrganizationFieldChange("registeredAs", e);
                      }}
                    >
                      {registeredAs.map((item, index) => (
                        <MenuItem style={{ fontSize: 16 }} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {organizationValuesError?.registeredAsError && (
                      <ErrorMessage message="Please select registered as" />
                    )}
                  </FormGroup1>

                  <FormGroup>
                    <StyledLabel>
                      Headquarters <RedStar />
                      <BasicPopover content="Please enter city name where your company has the HQ " />
                    </StyledLabel>

                    <StyledInput
                      onBlur={() => {
                        if (!organizationValues?.headquarters) {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            headquartersError: true,
                          });
                        } else {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            headquartersError: false,
                          });
                        }
                      }}
                      value={organizationValues?.headquarters}
                      // defaultValue={organizationValues?.headquarters}
                      type="text"
                      id="create-yaad--title"
                      placeholder="Your answer"
                      onChange={(e) => {
                        handleOrganizationFieldChange("headquarters", e);
                        if (!organizationValues?.headquarters) {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            headquartersError: true,
                          });
                        } else {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            headquartersError: false,
                          });
                        }
                      }}
                    />
                    {organizationValuesError?.headquartersError && (
                      <ErrorMessage message="Please enter the headquarter" />
                    )}
                  </FormGroup>


                  <FormGroup>
                    <StyledLabel>
                      Website (if any) <RedStar />
                    </StyledLabel>

                    <StyledInput
                      value={organizationValues?.website}
                      // defaultValue={organizationValues?.website}
                      type="text"
                      id="create-yaad--title"
                      placeholder="Your answer"
                      onChange={(e) => {
                        handleOrganizationFieldChange("website", e);
                        if (!organizationValues?.website) {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            websiteError: true,
                          });
                        } else {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            websiteError: false,
                          });
                        }
                      }}
                      onBlur={() => {
                        if (!organizationValues?.website) {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            websiteError: true,
                          });
                        } else {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            websiteError: false,
                          });
                        }
                      }}
                    />
                    {organizationValuesError?.websiteError && (
                      <ErrorMessage message="Please enter the website" />
                    )}
                  </FormGroup>

                  <FormGroup>
                    <StyledLabel>
                      Name of Chief Functionary <RedStar />
                      <BasicPopover content="Please enter name of Head of your organization" />
                    </StyledLabel>

                    <StyledInput
                      // value={organizationValues?.chiefFunctionary}
                      defaultValue={organizationValues?.chiefFunctionary}
                      type="text"
                      id="create-yaad--title"
                      placeholder="Your answer"
                      onBlur={() => {
                        if (!organizationValues?.chiefFunctionary) {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            chiefFunctionaryError: true,
                          });
                        } else {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            chiefFunctionaryError: false,
                          });
                        }
                      }}
                      onChange={(e) => {
                        handleOrganizationFieldChange("chiefFunctionary", e);
                      }}
                    />
                    {organizationValuesError?.chiefFunctionaryError && (
                      <ErrorMessage message="Please enter the name of chief functionary" />
                    )}
                  </FormGroup>

                  <FormGroup>
                    <StyledLabel>
                      Contact No <RedStar />
                      <BasicPopover content="Please add country code along with 10 digit phone number" />
                    </StyledLabel>

                    <StyledInput
                      // value={organizationValues?.contactNo}
                      defaultValue={organizationValues?.contactNo}
                      type="text"
                      id="create-yaad--title"
                      placeholder="Your answer"
                      onKeyPress={(e) => { preventMinus(e) }}
                      onBlur={() => {
                        if (!organizationValues?.contactNo) {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            contactNoError: true,
                          });
                        } else {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            contactNoError: false,
                          });
                        }
                      }}
                      onChange={(e) => {
                        handleOrganizationFieldChange("contactNo", e);
                      }}
                    />
                    {organizationValuesError?.contactNoError && (
                      <ErrorMessage message="Please enter the contact number" />
                    )}
                  </FormGroup>



                  <FormGroup>
                    <StyledLabel>
                      Email Id <RedStar />
                    </StyledLabel>

                    <StyledInput
                      // value={organizationValues?.emailId}
                      defaultValue={organizationValues?.emailId}
                      type="text"
                      id="create-yaad--title"
                      placeholder="Your answer"
                      onBlur={() => {
                        if (!organizationValues?.emailId) {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            emailIdError: true,
                          });
                        } else {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            emailIdError: false,
                          });
                        }
                      }}
                      onChange={(e) => {
                        handleOrganizationFieldChange("emailId", e);
                      }}
                    />
                    {organizationValuesError?.emailIdError && (
                      <ErrorMessage message="Please enter the email id" />
                    )}
                  </FormGroup>

                  <FormGroup>
                    <StyledLabel>
                      Total Staff <RedStar />
                      <BasicPopover content="Please enter number of employees in your organization" />
                    </StyledLabel>
                    <StyledInput
                      type="text"
                      // value={organizationValues?.totalStaff}
                      defaultValue={organizationValues?.totalStaff}
                      onKeyPress={(e) => { preventMinus(e) }}
                      min="0"
                      // type="text"
                      id="create-yaad--title"
                      placeholder="Your answer"
                      onBlur={() => {
                        if (!organizationValues?.totalStaff) {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            totalStaffError: true,
                          });
                        } else {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            totalStaffError: false,
                          });
                        }
                      }}
                      onChange={(e) => {
                        // handleOrganizationFieldChange("totalStaff", e);
                        handleOrganizationFieldValidation("totalStaff", e);
                      }}
                    />
                    {organizationValuesError?.totalStaffError && (
                      <ErrorMessage message="Please enter the no of employees" />
                    )}
                  </FormGroup>

                  <FormGroup>
                    <StyledLabel>
                      Total No. of Volunteers <RedStar />
                      <BasicPopover content="Please enter number of volunteers associated with your organization" />
                    </StyledLabel>

                    <StyledInput
                      value={organizationValues?.totalNumberofVolunteers}
                      // defaultValue={organizationValues?.totalNumberofVolunteers}
                      type="text"
                      id="create-yaad--title"
                      placeholder="Your answer"
                      onKeyPress={(e) => { preventMinus(e) }}
                      min="0"
                      onBlur={() => {
                        if (!organizationValues?.totalNumberofVolunteers) {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            totalNumberofVolunteersError: true,
                          });
                        } else {
                          setOrganizationValuesError({
                            ...organizationValuesError,
                            totalNumberofVolunteersError: false,
                          });
                        }
                      }}
                      onChange={(e) => {
                        // handleOrganizationFieldChange("totalNumberofVolunteers", e);
                        handleOrganizationFieldValidation("totalNumberofVolunteers", e);
                      }}
                    />
                    {organizationValuesError?.totalNumberofVolunteersError && (
                      <ErrorMessage message="Please enter total no of volunteers" />
                    )}
                  </FormGroup>

                  <FormGroup>
                    <StyledLabel>
                      Focus Thematic Areas <RedStar />
                      <BasicPopover content="Please select the focus areas for your organization" />
                    </StyledLabel>
                    <Multiselect
                      displayValue="focus"
                      showCheckbox
                      //    selectedValues={selectedFocusOption}
                      // selectedValues={selectedSOrgFocusArea}
                      selectedValues={locationPath?.state?.orgfocusarea}
                      options={focusOptions}
                      onSelect={(event) => {
                        setSelectedFocusOption(event);
                        if (_.isEmpty(event)) {

                          setThematicFocusAreaError("true")
                        } else {
                          setThematicFocusAreaError("false")

                        }
                      }}
                      onRemove={(event) => {
                        setSelectedFocusOption(event);
                        if (_.isEmpty(event)) {

                          setThematicFocusAreaError("true")
                        } else {
                          setThematicFocusAreaError("false")

                        }
                      }}
                      value={selectedFocusOption}
                      style={{
                        multiselectContainer: {
                          boxShadow: "0px 3px 6px #00000029",
                        },
                        searchBox: {
                          minHeight: 54,
                          PaddingTop: 10
                        }
                      }}

                    />
                    {thematicFocusAreaError === "true" && (
                      <ErrorMessage message="Please select focus thematic areas" />
                    )}

                  </FormGroup>
                  <FormGroup

                    style={{ paddingLeft: "20px", width: "100%" }}
                  >
                    <FormGroup2>
                      <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>Operational Areas</span><span style={{ color: "red" }}>*</span> <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 300, fontStyle: "italic" }}> (Search for location, select and click on Add to confirm. Click on "+" icon to enter additional locations)</span>
                      <PlacesAutocomplete
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelect}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) =>
                          <div style={{ padding: "25px 0px 0px 0px" }}>

                            {/* <input {...getInputProps({ placeholder: "Type Address", autoFocus: true, endAdornment:<SearchIcon />  })} /> */}
                            <TextField
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment>
                                    <IconButton>
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                              {...getInputProps({ placeholder: "Search for location..", autoFocus: false })} />
                            <div> {loading ? <div>...loading...</div> : null}</div>
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active ? "blue" : "white"
                              }
                              return (
                                <div {...getSuggestionItemProps(suggestion, { style })}>
                                  {suggestion.description}

                                </div>
                              )
                            })}
                          </div>

                        }

                      </PlacesAutocomplete>
                    </FormGroup2>
                    {location.map((item, index) => (
                      <div key={index}>{getAddress(item, index)}</div>
                    ))}


                  </FormGroup>
                </div>
                {isOrganizationSuccess &&
                  organizationResponse?.status === "success" && (
                    <span style={{ color: "green", textAlign: "center", display: "block" }}>
                      {organizationResponse?.message}
                    </span>
                  )}
                {isOrganizationSuccess && organizationResponse?.status === "failure" && (
                  <span style={{ color: "red", textAlign: "center", display: "block" }}>Please verify all the fields before submitting</span>
                )}

                {
                  organizationValuesError?.locationError && (
                    <span style={{ color: "red", textAlign: "center", display: "block" }}>Please search for location, select it and click on "Add"</span>
                  )
                }
                <div className="centerAlign" style={{ padding: "16px 0", marginBottom: 56 }}>
                  <Button
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    onClick={handleOnboardOrganization}
                    className="btn save-btn"
                    disableElevation
                    disabled={isOrganizationFetching}
                  >
                    {isOrganizationFetching ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      <span>Submit</span>
                    )}
                  </Button>
                  <br />
                </div>
              </div>
            </form>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
}
export default EditOrganizationScreen;
