/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import "../scss/containerStyles/_home.scss";
import "../App.css";
import "../cyaag-style.css";
import "../font-awesome.min.css";
import "../bootstrap.min.css";
import "../icon-font.css";
import "../flaticon-font.css";
import {
    userProfile,
    userProfileReset,
} from "../redux/action/UserProfileActions";
import { CopyRight } from "../components/CopyRight";
import { FixedFooterIcon } from "../components/FixedFooterIcon";
import ResponsiveDrawer from "../components/Header";
import TopHeaderScreen from "../components/TopHeader1";
import axios from "axios";
import { Link, useNavigate, useLocation, useParams} from "react-router-dom";
import Card from "@mui/material/Card";

const GetAToken = (props) => {
    // console.log("start HAScreen")
    const navigate = useNavigate();
    const locationPath = useLocation();
    const dispatch = useDispatch();
    const [ad, setAD] = useState([]);
    const [adToken, setADToken] = useState('');
    
    // console.log("calling profile")
    const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
        (state) => state.profile
    );
    
    const userid = localStorage.getItem("userid");

    React.useEffect(() => {
        dispatch(userProfile(userid));
    }, []);
    
    useEffect(() => {
        getADLogin();
    }, []);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const formData = new FormData();

        for (const [key, value] of urlParams.entries()) {
            formData.append(key, value);
        }
        
        console.log("formData = ", formData)
        setADToken(formData);

    // const queryString = paramsArray.join('&');
        
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const sessiondate = urlParams.get('session_state')
   
    console.log(code); // This will log the value of 'code' from the URL
    console.log(state); // This will log the value of 'code' from the URL
    console.log(sessiondate); // This will log the value of 'code' from the URL
      }, [profileSuccess]);

   console.log("adToken = ", adToken);
   console.log((!(adToken === "")))
   
    const getADLogin = async () => {
        try {
            if (adToken && adToken.entries().next().value) {     
                console.log("adToken has entries 1= ", adToken.entries().next().value)
                console.log("adToken has entries 2= ", adToken.entries().next().value)
                axios.post('/getAToken', adToken)
                    .then(response => {
                        console.log('Response from backend:', response.data);
                    })
                    .catch(error => {
                        console.error('Error sending params to backend:', error);
                    });
                // return res;
            }
            // }
        } catch (err) {
            
            throw err;
        }
    };


    return (
        <Card onClick={props.onClick}>
            <div className="wrapper">
                <ResponsiveDrawer {...props} />
                <section className="containers">
                    <TopHeaderScreen {...props} />
                    <div class="content content-wrap">
                       
                        <div class="content-mrg hasScreen">
                           
                            <div>
                               HIIIIIIIIIIII {ad}
                            </div>

                        </div>
                    </div>
                    <CopyRight />
                    <FixedFooterIcon />
                   
                </section>
            </div>
        </Card>
    );
};

export default GetAToken;
