
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ResponsiveDrawer from "../../components/Header";

import { CopyRight } from "../../components/CopyRight";
// import ExecHeader from "../components/ExecHeader";
import { ValidateUserOnboarding } from "../../components/ValidateUserOnboarding";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { userProfile } from "../../redux/action/UserProfileActions";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link, useNavigate } from "react-router-dom";
import {  Button } from "@mui/material";
import { userreviewlist } from "../../redux/action/UserOnboardReviewAction";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import { styled } from "@mui/material/styles";

const CustomTabs = styled(Tabs)(({ theme }) => ({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1976d2',
  },
}));

// Styled component for CustomTab
const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: 'uppercase',
  minWidth: 72,
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(4),
  color: '#888da8',
  fontWeight: 600,
  '&:hover': {
    color: '#115293',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#1976d2',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&:focus': {
    color: '#1976d2',
  },
}));

function createDataValidator(
  id,
  firstname,
  lastname,
  phoneno,
  organization,
  orgrole,
  onboardstatus,
  
) {
  return {
    id,
    firstname,
    lastname,
    phoneno,
    organization,
    orgrole,
    onboardstatus,
    
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
  // console.log(props)
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



const ReviewUserOnboarding = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [welcomeOpen, setWelcomeOpen] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const userid = localStorage.getItem("userid");
  const [value1, setValue1] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [reviewStatus, setReviewStatus] = React.useState("");
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [checked, setChecked] = useState(false);
  const { isSuccess: userreviewlistSuccess, response: userreviewlistResponse } = useSelector(
    (state) => state.userreviewlist
  );
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
 
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(userreviewlist());
    // dispatch(validatorList(newValue));
  };

  
  useEffect(() => {
    dispatch(userreviewlist(profileResponse));
    dispatch(userProfile(userid)); 
  }, []);

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < userreviewlistResponse.length; i += 1) {
      const formattedArray = [];
      formattedArray.push(
        // userreviewlistResponse[i].phoneno,
        userreviewlistResponse[i].firstname,
        userreviewlistResponse[i].lastname,
        userreviewlistResponse[i].phoneno,
        userreviewlistResponse[i].organization,
        userreviewlistResponse[i].orgrole,
        userreviewlistResponse[i].onboardstatus,
      );

      temp.push(createDataValidator(i, ...formattedArray));
    }
    setRows(temp);
  }, [userreviewlistResponse]);

  useEffect(() => {
    const firstTime = localStorage.getItem("firstTime");
    if (firstTime) {
      setWelcomeOpen(true);
    }
  }, []);

  
  const reviewedData = rows.filter((item) => item.onboardstatus === "submitted");
  const acceptedData = rows.filter((item) => item.onboardstatus === "approved");
  const rejectedData = rows.filter((item) => item.onboardstatus === "rejected");

  const sortedDataReview =
    inputValue === ""
      ? ""
      : reviewedData.filter((item) => item.firstname === inputValue);

  const sortedDataApprove =
    inputValue === ""
      ? ""
      : acceptedData.filter((item) => item.firstname === inputValue);

  const sortedDataRejected =
    inputValue === ""
      ? ""
      : rejectedData.filter((item) => item.firstname === inputValue);

  return (
    <div>
      <ResponsiveDrawer {...props} />
      <section className="containers">
      <TopHeaderScreen {...props} />
      <div class="content content-wrap">
          <div class="content-mrg">
          <Button
                onClick={() => navigate(-1)}
                className="backWrapper"
                style={{justifyContent:"left"}}
            >
                <i className={`fa fa-caret-left`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
      <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Review User Onboarding</div>
      <main  id="exec" style={{ marginLeft: "400" }} >
      <div style={{display: 'flex',
    justifyContent: 'center',
    marginRight: '100px',}}>
        <CustomTabs
          // style={{ display: "flex", justifyContent: "center", marginRight: "100" }}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <CustomTab label="Submitted" />
          <CustomTab label="Accepted" />
          <CustomTab label="Rejected" />
        </CustomTabs>
        </div>
        <TabPanel value={value} index={0} >
          <ValidateUserOnboarding
            value1={value1}
            setValue1={setValue1}
            inputValue={inputValue}
            setInputValue={setInputValue}
            rows={reviewedData}
            sortedData={sortedDataReview}
            actionTaken={false}
            setReviewStatus={setReviewStatus}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ValidateUserOnboarding
            value1={value1}
            setValue1={setValue1}
            inputValue={inputValue}
            setInputValue={setInputValue}
            rows={acceptedData}
            sortedData={sortedDataApprove}
            actionTaken
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ValidateUserOnboarding
            value1={value1}
            setValue1={setValue1}
            inputValue={inputValue}
            setInputValue={setInputValue}
            rows={rejectedData}
            sortedData={sortedDataRejected}
            actionTaken
          />
        </TabPanel>
      </main>
      </div>
      </div>
      <CopyRight />
      <FixedFooterIcon />
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({}, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewUserOnboarding);
