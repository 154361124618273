import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "../../components/Header";
import { CampaignTableComponentForSOAdmin, TableComponent } from "../../components/Table";
import { userProfile } from "../../redux/action/UserProfileActions";
import TopHeaderScreen from "../../components/TopHeader1";
import { CopyRight } from "../../components/CopyRight";
import { useNavigate } from "react-router-dom";
import {  Button } from "@mui/material";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";

  
  const MyCampaignScreen = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [activityList, setActivityList] = useState([]);
  
    const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
      (state) => state.profile
    );
  
    const getActivityList = async () => {
      try {
        const res = await axios.get(
          `/camp/status/${localStorage.getItem("userid")}/${
            profileResponse?.organization
          }`
        );
        setActivityList(res?.data);
        JSON.stringify(res)
        // console.log(res)
        return res;
      } catch (err) {
        throw err;
      }
    };
    useEffect(() => {
      dispatch(userProfile(localStorage.getItem("userid")));
    }, []);
    useEffect(() => {
      if (profileResponse?.organization) {
        getActivityList();
      }
    }, [profileResponse?.organization]);
  
    return (
      <div className="wrapper">
        <ResponsiveDrawer {...props} />
        <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
        <Button
                onClick={() => navigate(-1)}
                className="backWrapper"
            >
                <i className={`fa fa-caret-left`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>My Organization Campaigns</div>
          <CampaignTableComponentForSOAdmin rows={activityList} />
        </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
        </section>
      </div>
    );
  };
  
  export default MyCampaignScreen;
  