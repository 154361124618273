/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import "../scss/containerStyles/_home.scss";
import "../App.css";
import "../cyaag-style.css";
import "../font-awesome.min.css";
import "../bootstrap.min.css";
import "../icon-font.css";
import "../flaticon-font.css";
import {
    userProfile,
    userProfileReset,
} from "../redux/action/UserProfileActions";
import { CopyRight } from "../components/CopyRight";
import { FixedFooterIcon } from "../components/FixedFooterIcon";
import ResponsiveDrawer from "../components/Header";
import TopHeaderScreen from "../components/TopHeader1";
import axios from "axios";
import { Link, useNavigate, useLocation} from "react-router-dom";
import Card from "@mui/material/Card";

const AD = (props) => {
    // console.log("start HAScreen")
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [ad, setAD] = useState([]);
    
    // console.log("calling profile")
    const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
        (state) => state.profile
    );
    
    const userid = localStorage.getItem("userid");

    React.useEffect(() => {
        dispatch(userProfile(userid));
    }, []);
    
    useEffect(() => {
        getADLogin();
    }, []);

   
    const getADLogin = async () => {
        try {
            const res = await axios.get(
                `/api/v1/entra/login`
            );

            setAD(res?.data);
            console.log("res = ", res)
            // if (res?.data) {
            //     navigate(res?.data?.auth_uri)
            // }
            
            return res;
            // }
        } catch (err) {
            
            throw err;
        }
    };

    console.log("ad = ", ad)
    return (
        <Card onClick={props.onClick}>
            <div className="wrapper">
                <ResponsiveDrawer {...props} />
                <section className="containers">
                    <TopHeaderScreen {...props} />
                    <div class="content content-wrap">
                        <div class="content-mrg hasScreen">
                           
                            <div>
                                {JSON.stringify(ad.auth_uri)}
                            <ul><li><a href={ad.auth_uri}>Sign In</a></li></ul>
                            </div>
                            {/* <div>navigate({ad?.auth_uri})</div> */}

                        </div>
                    </div>
                    <CopyRight />
                    <FixedFooterIcon />
                   
                </section>
            </div>
        </Card>
    );
};

export default AD;
