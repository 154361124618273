import { Grid, Typography, Box } from "@mui/material";
import PropTypes from "prop-types";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ResponsiveDrawer from "../../components/Header";
import { useTheme } from "@mui/material/styles";
import { userProfile } from "../../redux/action/UserProfileActions";
import TopHeaderScreen from "../../components/TopHeader1";
import { CopyRight } from "../../components/CopyRight";
import CardMedia from "@mui/material/CardMedia";
import { Button } from "@mui/material";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



const OrganizationListScreen = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [activityList, setActivityList] = useState([]);
  const [value, setValue] = React.useState(0);

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  const getActivityList = async () => {
    try {
      const res = await axios.get(
        `/organization/list/${localStorage.getItem("userid")}`
      );
      setActivityList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    dispatch(userProfile(localStorage.getItem("userid")));
  }, []);
  useEffect(() => {
    getActivityList();
  }, [profileResponse]);

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button
              onClick={() => navigation(-1)}
              className="backWrapper"
            >
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Onboarded Organization(s)</div>
            <div className="customRow">
              {activityList.map((item) => (
                <div class="customWidth">
                  <div class="list-bg text-center">
                    <div class="list-content">
                      <CardMedia
                        image={item.organizationlogo}
                        // title={tile.name}
                        style={{borderRadius: "5px 5px 0 0",
                          height: 125,
                          backgroundSize: "100% 100%",}}
                      />
                      <div class="list-title paddingLeftRight">
                        <h1>
                          {item?.organization}
                        </h1>
                      </div>
                    </div>
                    <div class="hr"></div>
                    <div class="list-content mb-15 mt-20" style={{ paddingTop: 0 }}>
                      <div class="row">
                        <div class="col-lg-12 text-center"><a onClick={() =>
                          navigation(`/orgview/${item.clientid}`, { state: item })
                        } class="next-list" style={{ cursor: "pointer" }}>View more<i class="fa fa-arrow-right" style={{ marginLeft: 5 }} aria-hidden="true"></i></a></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
};

export default OrganizationListScreen;
