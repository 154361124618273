import React, {useEffect, useState, useRef} from "react";
import FormControl from "@mui/material/FormControl";
import { ListItemIcon, Checkbox, ListItemText } from "@mui/material";
import {
    Avatar,
    Box,
    Button,
    Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import _, { values } from "lodash";
// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";

import { useLocation, useNavigate } from "react-router-dom";
import TopHeaderScreen from "../../components/TopHeader1";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import styled from 'styled-components';

const HomeScreenMainContent = styled(Box)(({ theme }) => ({
  padding: '80px 0 100px 0px',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    padding: '80px 0 100px 330px',
  },
})); 

const FormGroup0 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#F0F8FF',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// Styled component for formGroup00
const FormGroup00 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#FAEBD7',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

const FormGroup = styled('div')(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: '40px',
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));


const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup1
const FormGroup1 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup2
const FormGroup2 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  flexDirection: 'column',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '75px 0 0 16px',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup3
const FormGroup3 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  width: '20%',
  '@media (max-width: 1200px)': {
    width: '20%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup4
const FormGroup4 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'right',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 0',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup7
const FormGroup7 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup8
const FormGroup8 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledLabel
const StyledLabel = styled('label')(({ theme }) => ({
  display: 'block',
  background: '#e9e7e7',
  padding: '10px',
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontWeight: 600,
  marginTop: '16px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

// StyledSpan
const StyledSpan = styled('span')(() => ({
  fontSize: '12px',
}));

// FocusThematic
const FocusThematic = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledInput
const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// StyledTextarea
const StyledTextarea = styled('textarea')(() => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// LogoButton
const LogoButton = styled('input')(() => ({
  width: '8%',
  border: 'none',
  padding: '10px',
  display: 'flex',
  height: '40px',
  color: 'white',
  '@media (max-width: 1920px)': {
    fontSize: '15px',
    width: '12%',
  },
  '@media (max-width: 1280px)': {
    fontSize: '15px',
    width: '15%',
  },
  '@media (max-width: 960px)': {
    fontSize: '15px',
    width: '20%',
  },
  '@media (max-width: 600px)': {
    fontSize: '15px',
    width: '30%',
  },
}));

// StyledTypography
const StyledTypography = styled(Typography)(({ theme }) => ({
  '& .css-u4tvz2-MuiFormLabel-root': {
    fontFamily: 'Poppins',
  },
  display: 'block',
  display: 'flex',
  fontSize: '16px',
  marginTop: '16px',
  fontWeight: 500,
  lineHeight: '18px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

const LoginPgLogo = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (max-width: 768px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
}));

const StyledLargeAvatar = styled(Avatar)(({ theme }) => ({
  width: '200px !important',
  height: '200px !important',
  margin: '0 auto',
  marginTop: '5px',
  marginBottom: '5px',
  '@media (max-width: 400px)': {
    width: '120px !important',
    height: '120px !important',
  },
  '@media (max-width: 600px)': {
    width: '140px !important',
    height: '140px !important',
  },
  '@media (max-width: 768px)': {
    width: '150px !important',
    height: '150px !important',
  },
}));

function RewardDeleteScreen(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const locationPath = useLocation();
    // const [type, setType] = useState(null);
    // const [id, setId] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    // const [deleteMessage, setDeleteMessage] = useState(null);

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    const [volunteerDetails, setvolunteerDetails] = useState({
        // fullname: "",
        campid: "",
        mpid: "",
        points: "",
        rewardtype: "",
        sponsor: "",
        title: "",
        subtitle: "",
        ownership: "",
    });

    const topRef = useRef(null);

    useEffect(() => {
      if (topRef.current) {
        topRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, []);

    const handleDeleteUser = async () => {
        // setDisplayConfirmationModal(true);
        const payload = {
          // campid: locationPath?.state?.campid,
          // createdate: locationPath?.state?.createdate,
          // reviewstatus: reviewstatus,
          // reviewcomments: reviewComments,
          // phoneno: localStorage.getItem("userid")
          mpid: locationPath?.state.mpid,
    
        };
        try {
          const res = await axios.put(
            // `/user/delete/${localStorage.getItem("userid")}`,
            `/marketplace/reward/delete`,
            payload
          );
          // navigate("/reviewcampaign");
          // setuserdeletestat(res?.data);
          setDisplayConfirmationModal(false);
          navigate("/reviewrewards");
          return res;
        } catch (err) {
          throw err;
        }
      };

    
    const [response, setResponse] = useState({});
    const { isSuccess: profileSuccess, response: VolunteerResponse } = useSelector(
        (state) => state.profile
    );

    useEffect(() => {
        if (locationPath?.state) {
            setvolunteerDetails({
                // fullname: locationPath?.state.fullname,
                // dob: locationPath?.state.dob,

                campid: locationPath?.state.campid,
                mpid: locationPath?.state.mpid,
                points: locationPath?.state.points,
                rewardtype: locationPath?.state.rewardtype,
                sponsor: locationPath?.state.sponsor,
                title: locationPath?.state.title,
                subtitle: locationPath?.state.subtitle,
                ownership: locationPath?.state.ownership,
                
            });
        }
    }, [locationPath?.state]);

    
  
    return (

        <div className="wrapper" ref={topRef}>

            <ResponsiveDrawer {...props} />
            <section className="containers">
            <TopHeaderScreen {...props} />
            <div class="content content-wrap">
            <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
                    Delete Reward
                </div>
                <form autoComplete="off">
                    <div style={{ border: "1px solid #dadce0", borderRadius: 8, padding: "13px 30px 40px 30px" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                            }}
                        >
                            <FormGroup>
                                <StyledLabel  >Reward Type </StyledLabel>
                                 <StyledInput
                                    disabled
                                    
                                    value={locationPath?.state.rewardtype}
                                    type="text"
                                     
                                />
                            </FormGroup>
                            <FormGroup>
                                <StyledLabel  >Ownership </StyledLabel>
                                 <StyledInput
                                    disabled
                                    
                                    value={locationPath?.state.ownership}
                                    type="text"
                                     
                                />
                            </FormGroup>
                            <FormGroup>
                                <StyledLabel  >Title </StyledLabel>
                                 <StyledInput
                                    disabled
                                    
                                    value={locationPath?.state.title}
                                    type="text"
                                     
                                />
                            </FormGroup>
                            <FormGroup>
                                <StyledLabel  >Subtitle </StyledLabel>
                                 <StyledInput
                                    disabled
                                    
                                    value={locationPath?.state.subtitle}
                                    type="text"
                                     
                                />
                            </FormGroup>
                            <FormGroup>
                                <StyledLabel  >Points </StyledLabel>
                                 <StyledInput
                                    disabled
                                    
                                    value={locationPath?.state.points}
                                    type="text"
                                     
                                />
                            </FormGroup>

                            

                        </div>
                    </div>
                </form>
                <div className="centerAlign" style={{ padding: "16px",marginRight:"10px",float:"left",width:"100%"}}>
                {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
                    <Button
                        onClick={() => setDisplayConfirmationModal(true)}
                        style={{
                            marginTop: 8,
                            // marginLeft:600,
                            width: "200px",
                            // background: "#E88A00",
                            background: "red",
                            borderRadius: 20,
                            paddingRight:10,
                            color: "#fff",
                            fontFamily: "Poppins !important",
                            alignSelf: "center",
                            marginRight:"5px",
                        }}
                    >
                        <span>Delete</span>
                    </Button>

                    <Button
                        onClick={() => navigate(-1)}
                        style={{
                            marginTop: 8,
                            // marginLeft: 600,
                            width: "200px",
                            // background: "grey",
                            background: "#e9ecef",
                            borderRadius: 20,
                            // color: "#fff",
                            color: "#3B3F5C",
                            fontFamily: "Poppins !important",
                            alignSelf: "center",
                        }}
                    >
                        <span>Cancel</span>
                    </Button>
                </div>
                {profileSuccess &&
                    <span style={{ color: "green", textAlign: "center", display: "block" }}>{response?.message}</span>}
                {!profileSuccess && (
                    // <span style={{ color: "red" }}>Error occured</span>
                    <span style={{ color: "red", textAlign: "center", display: "block" }}>{response?.message}</span>
                )}
                {/* {response && <span>{response?.message}</span>} */}
            </div>
            </div>
            <CopyRight />
        <FixedFooterIcon />
      </section>
            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={handleDeleteUser} hideModal={hideConfirmationModal} message={"Are you sure you want to delete this reward?"}  />
        </div>
    );
}
export default RewardDeleteScreen;
