import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate } from "react-router-dom";
import cYAAGlogo from "../../assets/logo-black.png";
import HowcYAAGWorks from "../../assets/howcYAAGWorks.png";
import whoUsescYAAG from "../../assets/whoUsescYAAG.png";
import cYAAGImage from "../../assets/cYAAGImage.png";
import LandingPage1Img from "../../assets/landingpage1.jpg";
import LandingPage2Img from "../../assets/landingpage2.jpg";


const AboutusFromLanding = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    // <div className="wrapper">
    <div class="landingPageWrapper">
      <div
        class="desktop-nav header navbar"
        style={{ marginBottom: "0px", padding: "0px 10px" }}
      >
        <div class="container">
          <div class="nav-logo">
            <a href="index.html" class="">
              {" "}
              <img src={cYAAGlogo} class="img-fluid" alt="logo" />
            </a>
          </div>
        </div>
      </div>
      <Button
        onClick={() => navigate(-1)}
        className="backWrapper"
        id="aboutusfromlandingback"
      >
        <i className={`fa fa-caret-left`} />
        <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
      </Button>
      <div
                style={{ float: "left", width: "100%" }}
                class="sliderContentWrap"
              >
      <h2 class="pl-3 pr-3 ">
                  About cYAAG
                  <br />
                </h2>
        <div class="item slideaboutus1">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 banner-left-content ">
                <div class="marginWrapper">
                  <p>
                    cYAAG's vision is to enable everyone to be a social
                    entrepreneur and contribute to the achievement of the
                    Sustainable Development Goals (SDG's) of our planet.
                  </p>
                  <p>
                    Our intelligent digital platform empowers and rewards people
                    for accelerating innovative solutions to achieve a triple
                    impact - social, environmental, and financial.
                  </p>
                  <p>
                    We offer highly scalable solutions leveraging the latest AI,
                    Cloud, IoT, and Social Networking technologies.
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center">
                <div class="slider-img d-sm-flex justify-content-center">
                  <img
                    alt="Steer Your Vision"
                    class="img-fluid marginWrapper"
                    height="400px"
                    src={cYAAGImage}
                    title="Steer Your Vision"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 class="pl-3 pr-3 ">
                  How cYAAG works​
                  <br />
                </h2>
        <div class="item slideaboutus2">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-12 col-12 banner-left-content">
                        <p>
                          <img
                            alt="cYAAG works"
                            class="img-fluid marginWrapper"
                            height="400px"
                            src={HowcYAAGWorks}
                            title="ERP Implementation"
                          />
                        </p>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center">
                        <div class="slider-img d-sm-flex justify-content-center">
                          <img
                            alt="ERP Implementation"
                            class="img-fluid marginWrapper"
                            height="600px"
                            // src={BannerRight}
                            src={LandingPage2Img}
                            title="ERP Implementation"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <h2 class="pl-3 pr-3 ">
                  Who uses cYAAG
                  <br />
                </h2>

                <div class="item slideaboutus3">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-12 col-12 banner-left-content">
                        <p>
                          <img
                            alt="cYAAG works"
                            class="img-fluid marginWrapper whiteBg"
                            height="800px"
                            src={whoUsescYAAG}
                            title="ERP Implementation"
                          />
                        </p>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center">
                        <div class="slider-img d-sm-flex justify-content-center">
                          <img
                            alt="ERP Implementation"
                            class="img-fluid marginWrapper"
                            height="400px"
                            // src={BannerRight}
                            src={LandingPage1Img}
                            title="ERP Implementation"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
      </div>
      <div
        class="tab-pane fade"
        id="animated-underline-contact"
        role="tabpanel"
        aria-labelledby="animated-underline-contact-tab"
      ></div>
    </div>
  );
};

export default AboutusFromLanding;
