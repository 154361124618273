import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Box, FormControl, Typography, Snackbar, Chip } from '@mui/material';
import { Button, TextField, useMediaQuery } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Multiselect from 'multiselect-react-dropdown';
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import TopHeaderScreen from "../../components/TopHeader1";
import RedStar from "../../components/RedStar";
import Alert from '@mui/material/Alert';

import styled from 'styled-components';

const StyledTypography = styled(Typography)(({ theme }) => ({
    '& .css-u4tvz2-MuiFormLabel-root': {
      fontFamily: 'Poppins',
    },
    display: 'block',
    display: 'flex',
    fontSize: '16px',
    marginTop: '16px',
    fontWeight: 500,
    lineHeight: '18px',
    '@media (max-width: 600px)': {
      fontSize: '14px',
    },
  }));
const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    padding: '16px',
    '@media (min-width: 600px)': {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
});
const Item = styled(Box)({
    flex: '1 1 48%',
    '@media (max-width: 600px)': {
        flex: '1 1 100%',
    },
});

const CustomAlert = styled(Alert)(({ theme, severity }) => ({
    width: '100%',
    backgroundColor: severity === 'success' ? '#28A745 !important' : '#B9544B !important',
    color: 'white !important',
    boxShadow: 'none',
    zIndex: 9999,
  }));


const EditTeamCampaign = (props) => {
    const navigate = useNavigate();
    const { teamid } = useParams();

    const [formData, setFormData] = useState({
        name: '',
        campaign: [],
        campaignGoal: []
    });

    const [campaignOptions, setCampaignOptions] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [editTeamResponse, setEditTeamResponse] = useState('');
    const [componentKey, setComponentKey] = useState(Date.now());
    const [snackbarMultiSelectMessage, setSnackbarMultiSelectMessage] = useState('');
    const [snackbarMultiSelectOpen, setSnackbarMultiSelectOpen] = useState(false);
    const [myTeamData, setMyTeamData] = useState([]);
    const [prepopulatedCampaigns, setPrepopulatedCampaigns] = useState([]);
    const topRef = useRef(null);

    useEffect(() => {
        if (topRef.current) {
        topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);
    
    const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
        (state) => state.profile
    );

    console.log("TeamID = ", teamid);

    useEffect(() => {
        const fetchCampaignOptions = async () => {
            try {
                const response = await axios.get(`/teams/campaign/list/${profileResponse?.organization}/${profileResponse?.phoneno}`);
                const campaigns = response.data.map(campaign => ({
                    name: campaign.name,
                    goal: campaign.goal
                }));
                setCampaignOptions(campaigns);
            } catch (error) {
                console.error('Error fetching campaign options:', error);
            }
        };
        fetchCampaignOptions();
    }, [profileResponse]);

    useEffect(() => {
        const fetchMyTeamSpecificPage = async () => {
            try {
                const response = await axios.get(`/teams/my/team/${teamid}/${profileResponse?.phoneno}`);
                setMyTeamData(response.data);
            } catch (error) {
                console.error('Error fetching my team', error);
            }
        };
        fetchMyTeamSpecificPage();
    }, [profileResponse]);

    useEffect(() => {
        if (myTeamData.length > 0) {
            const teamData = myTeamData[0];
            const prepopulatedCampaigns = teamData?.campname.map((campaignName, index) => ({
                id: index,
                name: campaignName,
            }));
            const formDataCampaignOptions = teamData?.campname.map((campaignName, index) => ({
                id: index,
                name: campaignName,
            }));
            const formDataAddedMembers = teamData?.memberdetails.map((member, index) => ({
                id: index,
                firstname: member.firstname,
                lastname: member.lastname,
                profilephoto: member.profilephoto,
                uniqueuserid: member.uniqueuserid,
                organization: member.organization,
                totalpoints: member.totalpoints,
            }));

            setPrepopulatedCampaigns(prepopulatedCampaigns);
            setFormData({
                ...formData,
                name: teamData.name || '',
                tagLine: teamData.tagline || '',
                // campaign: formDataCampaignOptions,
                // campaignGoal: teamData.goal,
                visibility: teamData.visibility || '',
                // logo: teamData.logo || null,
                about: teamData.about || '',
                members: formDataAddedMembers,
            });
            // setSelectedMembers(formDataAddedMembers);
        }
    }, [myTeamData]);

    
    const handleMultiselectChange = (selectedList) => {
        console.log("selectedList len = ", selectedList.length);
        const duplicateCampaign = selectedList.find(selectedCampaign => prepopulatedCampaigns.some(prepopCampaign => prepopCampaign.name === selectedCampaign.name));
        if (duplicateCampaign) {
            selectedList.pop();
            setSnackbarMultiSelectMessage("Campaign already subscribed");
            setSnackbarMultiSelectOpen(true);
        } else {
            const selectedCampaignGoals = selectedList.map(item => {
                const selectedCampaign = campaignOptions.find(campaign => campaign.name === item.name);
                return selectedCampaign ? selectedCampaign.goal : '';
            });
            setFormData({
                ...formData,
                campaign: selectedList.map(campaign => campaign.name),
                campaignGoal: selectedCampaignGoals,
            });
        }
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });


    const handleSubmit = async () => {
        const data = new FormData();
        data.append('teamid', teamid)
        data.append('campaign', formData.campaign.join(',') )
        try {
            console.log("formData.campaign.length = ", formData.campaign.length)
            if (formData.campaign.length !== 0) {
            const response = await axios.post(`/teams/edit/campaign/${profileResponse?.phoneno}`, data);
            console.log("response.data = ", response.data)
            setEditTeamResponse(response.data);
            setSnackbarOpen(true);
            setFormData((prevData) => ({
                ...prevData,
                campaign: [],
                campaignGoal: [],
            }));
        } else {
            const message = {"message":"No updates to the campaign", "status": "success"}
            setEditTeamResponse(message);
            setSnackbarOpen(true);
        }
    
            // setTimeout(() => {
            //     setSnackbarOpen(false);
            // }, 6000);
            setComponentKey(Date.now());

            // setComponentKey(Date.now());
        } catch (error) {
            console.error('There was an error editing the campaign!', error);
        }
    };

    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <div className="wrapper" ref={topRef}>
            <ResponsiveDrawer {...props} />
            <section className="containers">
                <TopHeaderScreen {...props} />
                <div className="content content-wrap">
                    <div className="content-mrg createTeamPage">
                        <Button style={{ padding: "6px 8px", color: "#1976d2"}} onClick={() => navigate(-1)} >
                            <i className={`fa fa-caret-left`} />
                            <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
                        </Button>
                        <div style={{ fontFamily: "monospace", fontSize: 16 }}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginBottom: 20,
                                }}
                            >
                                <marquee behavior="scroll" direction="left" style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px', color: 'red' }}>
                                    Please do not leave this page until you submit as you may risk
                                    losing the partially filled in form{" "}
                                </marquee>
                            </div>
                        </div>
                        <div
                            className="content-title"
                            style={{
                                fontSize: 25,
                                fontWeight: "600",
                                marginBottom: 15,
                                marginTop: 5,
                            }}
                        >
                            Edit Team Campaigns - <span style={{ color: "#007BFF" }}>{formData.name}</span>
                        </div>
                        <Box sx={{ maxWidth: 800, mx: 'auto', mt: 4 }}>
                            <Item style={{ textAlign: "center" }}>
                                <span style={{ display: "block" }}>Campaigns Subscribed</span>
                                {prepopulatedCampaigns && prepopulatedCampaigns.map((camp) => (
                                    <Chip
                                        key={camp.name}
                                        fontSize="large"
                                        size="small"
                                        label={camp.name}

                                    />
                                ))
                                }
                            </Item>
                        </Box>
                        <Box sx={{ maxWidth: 800, mx: 'auto', mt: 4, border: "1px solid #dadce0", borderRadius: 4, }}>
                        {/* <Box sx={{ maxWidth: 800, mx: 'auto', mt: 4 }}> */}
                            <Container>
                                <Item>
                                    <StyledTypography variant="subtitle1" gutterBottom>
                                        Campaign <RedStar />
                                       
                                        <pre>{JSON.stringify(handleMultiselectChange)}</pre>
                                    </StyledTypography>
                                    <FormControl fullWidth>

                                        <Multiselect
                                            key={componentKey}
                                            isObject={true}
                                            displayValue="name"
                                            onRemove={handleMultiselectChange}
                                            onSelect={handleMultiselectChange}
                                            options={campaignOptions}
                                            style={{ minHeight: '150px' }}
                                            className="multiSelectContainer"
                                        />
                                        {/* <Snackbar
                                            open={snackbarMultiSelectOpen}
                                            // autoHideDuration={3000}
                                            onClose={() => setSnackbarMultiSelectOpen(false)}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                        >
                                            <Alert onClose={() => setSnackbarMultiSelectOpen(false)} severity="warning">
                                                {snackbarMultiSelectMessage}
                                            </Alert>
                                        </Snackbar> */}
                                    </FormControl>
                                </Item>
                                <Item>
                                    <StyledTypography variant="subtitle1" gutterBottom>Campaign Goal</StyledTypography>
                                    {
                                        formData.campaignGoal.length === 0 ? (
                                            <TextField
                                                fullWidth
                                                name="campaignGoal"
                                                value=""
                                                sx={{
                                                    cursor: 'not-allowed'
                                                }}

                                            />
                                        ) :
                                            (
                                                formData.campaignGoal.map((goal, index) => (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            alignItems: 'center',
                                                            padding: '10px 12px 10px 12px',
                                                            marginBottom: '10px',
                                                            width:'100%',
                                                            height: '55px',
                                                            borderRadius: '4px',
                                                            backgroundColor: '#fff',
                                                            whiteSpace: 'normal',
                                                            wordBreak: 'break-word',
                                                            overflowY: 'auto',
                                                            textOverflow: 'ellipsis',
                                                            border: '1px solid #ccc'
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                whiteSpace: 'normal',
                                                                wordBreak: 'break-word',
                                                            }}
                                                        >
                                                            {goal}
                                                        </Typography>
                                                    </Box>
                                                )))}
                                </Item>
                            </Container>
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className="btn save-btn"
                                    // style={{ backgroundColor: 'blue', color: 'white', padding:"6px 16px" }}
                                    onClick={handleSubmit}
                                >
                                    <span>Save</span>
                                </Button>
                                
                                <Button
                                        onClick={() => navigate(`/team/home/${teamid}`)}
                                        className="btn cancel-btn"
                                        variant="contained"
                                        color="secondary"
                                        style={{
                                            width: '135px',
                                            background: '#e9ecef',
                                            // borderRadius: 7,
                                            // color: '#3B3F5C',
                                            // fontFamily: 'Poppins !important',
                                            height: '53px',
                                        }}
                                    >
                                        <span>Cancel</span>
                                    </Button>
                            </Box>
                        </Box>
                    </div>
                </div>
                <CopyRight />
          <FixedFooterIcon />
            </section>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Snackbar
        open={snackbarOpen}
        // autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <CustomAlert
          onClose={() => setSnackbarOpen(false)}
          severity={editTeamResponse?.status === "success" ? "success" : "warning"}
        >
          {editTeamResponse?.message}
        </CustomAlert>
      </Snackbar>
    </Box>
        </div>
    );
};

export default EditTeamCampaign;