import React, { useState, useEffect, useRef } from 'react';
import Fab from '@mui/material/Fab';
import Drawer from '@mui/material/Drawer';
import { styled, keyframes } from '@mui/system';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { FaFire, FaFireAlt } from 'react-icons/fa';
import { GiCampfire, GiFireFlower } from 'react-icons/gi';
import { useMediaQuery } from '@mui/material';


const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
`;

const dotFlashing = keyframes`
  0% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0;
  }
`;

const StyledFab = styled(Fab)({
  position: 'fixed',
  bottom: '45px',
  right: '30px',
  zIndex: 1000,
  backgroundColor: '#003366',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  width: '56px',
  height: '56px',
  '&:hover': {
    backgroundColor: '#002244',
  },
  '@media (max-width: 600px)': {
    bottom: '80px',
    right: '10px',
    width: '48px',
    height: '48px',
  },
  overflow: 'visible',
});

const AnimatedIcon = styled(FaFire)({
  color: '#FFA500',
  animation: `${pulse} 2s infinite ease-in-out`,
  fontSize: '24px',
  marginTop: '18px'
});

const TextOverlay = styled('div')({
  position: 'absolute',
  width: '100%',
  top: '-14px',
  left: '50%',
  transform: 'translateX(-50%)',
  color: 'transparent',
  fontSize: '0.6rem',
  // fontWeight: 'bold',
  zIndex: 10,
  background: 'linear-gradient(45deg, #FFC107, #FFA000)',
  WebkitBackgroundClip: 'text',
  backgroundClip: 'text',
  textTransform: 'none',
  textShadow: `
    0 0 0px rgba(0, 0, 0, 1.6), 
    0 0 1px rgba(0, 0, 0, -0.5), 
    0 0 5px rgba(0, 0, 1, 0.4)`,
  '@media (max-width: 600px)': {
    left: '24px',
  },
});

const ChatWindow = styled('div')({
  width: '400px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '@media (max-width:600px)': {
    width: '100vw',
    maxWidth: '400px', 
  },
});

const ChatMessages = styled('div')({
  flex: 1,
  overflowY: 'auto',
  padding: '16px', 
  borderBottom: '1px solid #ddd',
});

const Message = styled('div')(({ isUser }) => ({
  display: 'flex',
  // justifyContent: isUser ? 'flex-end' : 'flex-start',
  justifyContent: 'flex-start',
  marginBottom: '10px',
}));

const MessageContent = styled('div')(({ isUser }) => ({
  backgroundColor: isUser ? '#1976d2' : '#FFD580',
  color: isUser ? '#ffffff' : '#000000',
  padding: '8px 12px',
  borderRadius: '16px',
  width: 'fit-content',
  maxWidth: '100%',
  fontSize: '0.875rem',
  background: isUser
    ? 'linear-gradient(145deg, #1565c0, #1976d2)'
    : 'linear-gradient(145deg, #f0f0f0, #FFD580)',
  boxShadow: isUser
    ? '3px 3px 6px rgba(0, 0, 0, 0.2), -3px -3px 6px rgba(255, 255, 255, 0.4)'
    : '3px 3px 6px rgba(0, 0, 0, 0.1), -3px -3px 6px rgba(255, 255, 255, 0.3)',
  backgroundSize: '200% 200%',
  animation: 'shine 1.5s infinite',

  '@keyframes shine': {
    '0%': { backgroundPosition: '0% 0%' },
    '100%': { backgroundPosition: '100% 100%' },
  },
}));

const MessageInput = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  padding: '16px',
  gap: '10px',
});
const ModelChoice = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  padding: '16px',
  gap: '47px',
});

const DrawerHeader = styled('div')({
  padding: '16px',
  borderBottom: '1px solid #e0e0e0',
  backgroundColor: '#f5f5f5',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const LoadingDots = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '24px',
  '& span': {
    animation: `${dotFlashing} 1.4s infinite both`,
    margin: '0 2px',
  },
  '& span:nth-child(1)': {
    animationDelay: '0s',
  },
  '& span:nth-child(2)': {
    animationDelay: '0.2s',
  },
  '& span:nth-child(3)': {
    animationDelay: '0.4s',
  },
});
const SelectButton = styled('button')(({ theme, selected }) => ({
  backgroundColor: selected ? '#007bff' : '#fff',
  color: selected ? '#fff' : '#007bff',
  border: '1px solid #007bff',
  borderRadius: '4px',
  fontSize: '14px',
  padding: '5px 10px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: selected ? '#0056b3' : '#e7f3ff',
  },
}));

const AIChatAskAnything = ({ onParentFunction }) => {
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("crisp");

  const messagesEndRef = useRef(null);
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    handleDrawerOpen();
  }, []);

  
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleDrawerOpen = () => {
    // sessionStorage.setItem("botClosed", "true");
    setOpen(true);
    setMessages([...messages, {
      text: (
        <>
          Namaste / Hello - I am Agni <FaFire style={{ verticalAlign: 'middle', color: '#CC8400', marginRight: '7px', marginLeft: '-2px' }} />
          What do you want to know about cYAAG Platform?
        </>
      ),
      isUser: false
    }]);
  };

  const handleDrawerClose = () => {
    onParentFunction();
    setOpen(false);
    setMessages([]);
    // sessionStorage.setItem("botClosed", "false");
  };

  const handleSelect = (index) => {
    setSelectedIndex(index);
  };

  const handleSendMessage = async () => {
    if (inputValue.trim() !== '') {
      const userMessage = inputValue.trim();
      setMessages([...messages, { text: userMessage, isUser: true }]);
      setInputValue('');
      setLoading(true);
      setInputDisabled(true);
      console.log("selectedIndex = ", selectedIndex);
      try {
        const response = await axios.get(`/chat/agent/get/data/${encodeURIComponent(userMessage)}/${(selectedIndex)}`);
        const botMessage = response.data.message || 'Sorry, I could not understand that. Can you please rephrase?';

        let currentMessage = '';
        botMessage.split('').forEach((char, index) => {
          setTimeout(() => {
            currentMessage += char;
            setMessages((prevMessages) => {
              const updatedMessages = [...prevMessages];
              if (updatedMessages[updatedMessages.length - 1].isUser) {
                updatedMessages.push({ text: currentMessage, isUser: false });
              } else {
                updatedMessages[updatedMessages.length - 1].text = currentMessage;
              }
              return updatedMessages;
            });
            if (index === botMessage.length - 1) {
              setLoading(false);
              setInputDisabled(false);
            }
          }, index * 50);
        });
      } catch (error) {
        setMessages((prevMessages) => [...prevMessages, { text: 'Error retrieving response from the server.', isUser: false }]);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !inputDisabled) {
      handleSendMessage();
    }
  };

  return (
    <div className='askAgni'>
      <StyledFab aria-label="chat" onClick={handleDrawerOpen }>
      
        <TextOverlay>Ask AGNI</TextOverlay>
        <AnimatedIcon />
      </StyledFab>
      <Drawer anchor="right" open={open}>
        <ChatWindow>
          <DrawerHeader>
            <Typography variant="h6">Know about cYAAG</Typography>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </DrawerHeader>
          <ChatMessages>
            {messages.map((msg, index) => (
              <Message key={index} isUser={msg.isUser}>
                <MessageContent isUser={msg.isUser}>{msg.text}</MessageContent>
              </Message>
            ))}
            {loading && (
              <Message isUser={false}>
                <MessageContent isUser={false}>
                  <LoadingDots>
                    <span>.</span><span>.</span><span>.</span>
                  </LoadingDots>
                </MessageContent>
              </Message>
            )}
            <div ref={messagesEndRef} />
          </ChatMessages>
          <ModelChoice>
            <SelectButton
              selected={selectedIndex === "crisp"}
              onClick={() => handleSelect("crisp")}
              disabled={inputDisabled}
            >
              Crisp Response
            </SelectButton>
            <SelectButton
              selected={selectedIndex === "detail"}
              onClick={() => handleSelect("detail")}
              disabled={inputDisabled}
            >
              Detail Response
            </SelectButton>
          </ModelChoice>
          <MessageInput>
            <TextField
              variant="outlined"
              fullWidth
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Type a message..."
              disabled={inputDisabled}
            />
            <Button variant="contained" color="primary" className="btn save-btn" onClick={handleSendMessage} style={{ marginTop: isMobile ? '4px' : '3px' }} disabled={inputDisabled}>
              <span>Send</span>
            </Button>
          </MessageInput>


        </ChatWindow>
      </Drawer>
    </div>
  );
};

export default AIChatAskAnything;
