/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useMediaQuery, Avatar, Chip, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Button, Stack } from "@mui/material";
import _ from "lodash";
import { isEmpty } from "lodash";
import "../../scss/containerStyles/_home.scss";
import "../../App.css";
import "../../cyaag-style.css";
import "../../font-awesome.min.css";
import "../../bootstrap.min.css";
import "../../icon-font.css";
import "../../flaticon-font.css";
import {
  userProfile,
  userProfileReset,
} from "../../redux/action/UserProfileActions";
import { CopyRight } from "../../components/CopyRight";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import { getVolunteerCount } from "../../redux/action/volunteerCountAction";
import ResponsiveDrawer from "../../components/Header";
import TopHeaderScreen from "../../components/TopHeader1";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { socialLoginReset } from "../../redux/action/loginAction";
import { getCampaign } from "../../redux/action/CampaignAction";
import Card from "@mui/material/Card";
import { referral } from "../../redux/action/ReferralAction";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { volDashBoardReward } from "../../redux/action/DashboardReward";
import TestiImg from "../../assets/testi.png";
import OverallStatusImg from "../../assets/left-o-status.png";
import RewardMainImage from "../../assets/rew.png";
import { isArray } from "lodash";
import WonIcon from "../../assets/won-rewards.jpg";
import RedeemConfirmation from "../../components/RedeemConfirmation";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import Typography from "@mui/material/Typography";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import PostRedeemConfirmation from "../../components/PostRedeemConfirmation";
import CEOImage from "../../assets/ceo.png";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import { Audio, Circles } from "react-loader-spinner";
import CampaignCard from "../../components/CampaignCard";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Fab from '@mui/material/Fab';
import { keyframes } from '@mui/system';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import AIChatAskAnything from "../../components/AIChatAskAnything";
import { FaFire, FaFireAlt } from 'react-icons/fa';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { FaInfoCircle } from 'react-icons/fa';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import styled from "styled-components";
import CloseIcon from '@mui/icons-material/Close';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PopupHome1 from './PopupHome1';

const Container = styled("div")(({ theme, isMobile }) => ({
  padding: "10px",
  overflowX: isMobile ? 'auto' : 'hidden',
  whiteSpace: 'nowrap',
}));

const ScrollContainer = styled('div')(({ isMobile }) => ({
  overflowX: isMobile ? 'auto' : 'hidden',
  whiteSpace: 'nowrap',
}));


const HAScreen = (props) => {
  // console.log("start HAScreen")
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [myCampaignList, setMyCampaignList] = useState([]);
  const [myRecommendedCampList, setMyRecommendedCampList] = useState([]);
  const [campStatusCount, setCampStatusCount] = useState([]);
  const [SDGCampMapping, setSDGCampMapping] = React.useState([]);
  const [rewarddash, setRewardDash] = useState([]);
  const [mycommunity, setmycommunity] = useState([]);
  const [myredeemedrewards, setmyredeemedrewards] = useState([]);
  const [marketplacelist, setmarketplacelist] = useState([]);
  const [successStories, setSuccessStories] = useState([]);
  const [afterRedeem, setAfterRedeem] = useState([]);
  const [displayRedeemConfirmationModal, setDisplayRedeemConfirmationModal] =
    useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [redeemindex, setRedeemIndex] = useState(0);
  const [postRedeemDisplayModal, setPostRedeemDisplayModal] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [reLoading, setReLoading] = React.useState(false);
  const [newValue, setNewValue] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const homeRef = React.useRef();
  const [expanded, setExpanded] = React.useState(null);
  const [showChat1, setShowChat1] = useState(false);
  const [visible, setVisible] = useState([true, true, true]);
  const [userDashboardData, setUserDashboardData] = useState([]);
  const [isPopupHome1Open, setIsPopupHome1Open] = useState(false);
  const [isPopupHome2Open, setIsPopupHome2Open] = useState(false);
  const [isPopupHome3Open, setIsPopupHome3Open] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);


  const handlePopupHome1Open = () => {
    setIsPopupHome1Open(true);
    console.log("set to open")
  };

  const handlePopupHome2Open = () => {
    setIsPopupHome2Open(true);
  };

  const handlePopupHome3Open = () => {
    setIsPopupHome3Open(true);
  };

  const handleClose = (index) => {
    setVisible((prevVisible) => {
      const newVisible = [...prevVisible];
      newVisible[index] = false;
      return newVisible;
    });
  };
  const topRef = useRef(null);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isLoading]);

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  const userid = localStorage.getItem("userid");

  React.useEffect(() => {
    dispatch(userProfile(userid));
  }, [reLoading]);

  // const refferalId = localStorage.getItem("refferalId");

  // console.log("calling useeffect 2")
  useEffect(() => {
    dispatch(userProfile(userid));
    dispatch(getVolunteerCount());
    Promise.all([getProfileInfo()])
      .then((data) => { getVolunteerCampDetails(data); getCampStatusCount(data) })
      .then(() => getMyReward())
      .then(() => getRewardsFromMarketplace())
      .then(() => getMyRedeemedRewards())
      ;
    dispatch(
      getCampaign(profileResponse.orgrole, profileResponse.organization)
    );
    homeRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [reLoading, activeTabIndex, activeTabIndex]);

  useEffect(() => {
    getMyRedeemedRewards();
  }, []);

  useEffect(() => {
    //if (profileSuccess) {
    getMyCommunityDetails().then((data) => {
      setmycommunity(data);
    });
    // } else {
    setLoading(false);
    //  }
  }, [reLoading]);

  // console.log(mycommunity);

  useEffect(() => {
    getSuccessStories().then((data) => {
      setSuccessStories(data);
    });
  }, []);

  useEffect(() => {
    // setLoading(false);
    getRecommendedCampDetails();
    // setLoading(true);
  }, [reLoading]);

  useEffect(() => {
    getSDGCampMapping();
  }, [reLoading]);

  const getProfileInfo = async () => {
    try {
      const res = await axios.get(`cyaaguser/${userid}`);
      return res;
      // }
    } catch (err) {
      throw err;
    }
  };

  const getUserDashboard = async () => {
    try {
      const response = await axios.get(`/profile/user/dashboard/${profileResponse?.phoneno}`);
      setUserDashboardData(response?.data)
      return response;
    } catch (error) {
      console.error('Error fetching user insights', error);
    }
  };
  React.useEffect(() => {
    getUserDashboard();
  }, []);


  const TextOverlay = styled('div')({
    position: 'absolute',
    width: '100%',
    top: '-14px',
    left: '50%', // Center the text
    transform: 'translateX(-50%)',
    color: 'transparent',
    fontSize: '0.6rem', // Adjust font size as needed
    // fontWeight: 'bold',
    zIndex: 10, // Ensures text is on top of the icon
    background: 'linear-gradient(45deg, #FFC107, #FFA000)', // Slightly darker gradient for a sharper look
    WebkitBackgroundClip: 'text', // Clips the gradient to text
    backgroundClip: 'text',
    textTransform: 'none',
    textShadow: `
      0 0 0px rgba(0, 0, 0, 1.6), 
      0 0 1px rgba(0, 0, 0, -0.5), 
      0 0 5px rgba(0, 0, 1, 0.4)`,
    '@media (max-width: 600px)': {
      left: '24px',
    },
  });

  const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
`;

  const StyledFab = styled(Fab)({
    position: 'fixed',
    bottom: '45px',
    right: '30px',
    zIndex: 1000,
    backgroundColor: '#003366',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '56px', // Adjust to fit your icon
    height: '56px', // Adjust to fit your icon
    '&:hover': {
      backgroundColor: '#002244',
    },
    '@media (max-width: 600px)': {
      bottom: '80px',
      right: '10px',
      width: '48px',
      height: '48px',
    },
    overflow: 'visible',
  });

  const AnimatedIcon = styled(FaFire)({
    color: '#FFA500',
    animation: `${pulse} 2s infinite ease-in-out`,
    fontSize: '24px',
    // marginTop: '20px'
  });



  const getVolunteerCampDetails = async (dd) => {
    setIsLoading(true);
    try {
      // console.log(`vol_dashboard/camp/my/${dd[0].data?.orgrole}/${dd[0].data?.organization}/${dd[0].data?.phoneno}`)
      const res = await axios.get(
        `vol_dashboard/camp/my/${dd[0].data?.orgrole}/${dd[0].data?.organization}/${dd[0].data?.phoneno}`
      );

      setMyCampaignList(res);
      setNewValue(dd[0]?.data);
      // console.log("dd[0] = ", dd[0]);
      // getRewardsFromMarketplace();
      // setLoading(true);
      // setMyCampaignList(res?.data);
      setIsLoading(false);

      return res;
      // }
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
  };


  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          //slidesToScroll: 1
        }
      }
    ]
  };



  const getMyReward = async () => {
    try {
      const res = await axios.get(`/vol_dashboard/myreward/${userid}`);
      setRewardDash(res?.data);
      dispatch(volDashBoardReward(res?.data, res?.data?.[0]?.phoneno));
      return res;
    } catch (err) {
      throw err;
    }
  };
  const getRewardsFromMarketplace = async () => {
    try {
      //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);
      const res = await axios.get(`/marketplace/read/${userid}`);
      setmarketplacelist(res?.data);
      // console.log(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getMyCommunityDetails = async () => {
    try {
      //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);

      const res = await axios.get(`adopter_dashboard/mycommunity/${userid}`);
      // console.log(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getMyRedeemedRewards = async () => {
    try {
      //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);

      const res = await axios.get(`reward/redeemed/${userid}`);
      setmyredeemedrewards(res);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getSuccessStories = async () => {
    try {
      const res = await axios.get(`/success/stories`);
      // console.log(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getRecommendedCampDetails = async () => {
    try {
      const res = await axios
        .get(`recommendation/${userid}`)
        .then((response) => {
          // console.log(response?.data);
          setMyRecommendedCampList(response);
          return response;
        });

      // setMyCampaignList(res?.data);
      // console.log(res)
      // return res;
      // }
    } catch (err) {
      throw err;
    }
  };

  const getCampStatusCount = async (profileData) => {
    try {
      const res = await axios
        // .get(
        //   `camp/count/${newValue?.orgrole}/${newValue?.organization}/${userid}`
        // )
        .get(
          `camp/count/${profileData[0].data?.orgrole}/${profileData[0].data?.organization}/${userid}`
        )
        .then((response) => {
          // console.log(response?.data);
          setCampStatusCount(response);
          return response;
        });

      // setMyCampaignList(res?.data);
      // console.log(res)
      // return res;
      // }
    } catch (err) {
      throw err;
    }
  };

  const getSDGCampMapping = async () => {
    try {
      const res = await axios.get(`camp/sdg/mapping`);
      // setActivityList(res?.data);
      setSDGCampMapping(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const handleRedeem = async () => {
    // setDisplayConfirmationModal(true);

    const payload = {
      //campid: locationPath?.state?.campid,
      // createdate: locationPath?.state?.createdate,
      // reviewstatus: reviewstatus,
      // reviewcomments: reviewComments,
      phoneno: localStorage.getItem("userid"),
      rewardtype: marketplacelist?.data?.[redeemindex]?.rewardtype,
      mpid: marketplacelist?.data?.[redeemindex]?.mpid,
      campid: marketplacelist?.data?.[redeemindex]?.campid,
      title: marketplacelist?.data?.[redeemindex]?.title,
      subtitle: marketplacelist?.data?.[redeemindex]?.subtitle,
      points: marketplacelist?.data?.[redeemindex]?.points,
      // sponsor: JSON.stringify(marketplacelist?.data?.[redeemindex]?.sponsor),
      sponsor: marketplacelist?.data?.[redeemindex]?.sponsor,

      // phoneno: locationPath?.state.phoneno,
    };
    try {
      const res = await axios.post(
        `/reward/redeem/${profileResponse?.phoneno}`,
        payload
      );
      // navigate("/reviewcampaign");
      setAfterRedeem(res?.data);
      // console.log(res?.data?.message);
      // alert("calling set display false")
      setDisplayRedeemConfirmationModal(false);
      if (res?.data?.message === "Reward successfully redeemed") {
        setPostRedeemDisplayModal(true);
        // navigate("/hacampscreen");
      } else {
        setDisplayConfirmationModal(true);
      }
      return res;
    } catch (err) {
      throw err;
    }
  };

  // Hide the modal
  const hideRedeemConfirmationModal = () => {
    setDisplayRedeemConfirmationModal(false);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handlePostRedeemModal = () => {
    setPostRedeemDisplayModal(false);
    navigate("/marketplace");
  };

  const optionsTwo = {
    items: 2,
    margin: 0,
    loop: false,
    nav: true,
    autoplaySpeed: 900,
    autoplay: false,
    dots: false,
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
    touchDrag: false,
    userSelect: true
  };

  const optionsFour = {
    items: 4,
    margin: 10,
    loop: true,
    nav: false,
    autoplaySpeed: 900,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
    touchDrag: true,
  };

  const handleTouch = (e) => {
    e.stopPropagation();
  };

  const handleParentFunction = () => {
    setShowChat1(false);
    // handleChatClick();
  };

  const handleChatClick = () => {
    setShowChat1(!showChat1);
  };

  const openPopup = (popupId) => {
    setIsPopupHome1Open(popupId === 1);
    setIsPopupHome2Open(popupId === 2);
    setIsPopupHome3Open(popupId === 3);
  };

  const isMobile = useMediaQuery('(max-width:600px)');
  
  const handleTabClick = (index) => {
    setActiveTabIndex(index);
  };

  console.log("mycampaign list = ", myCampaignList)
  return (
    <Card style={{
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      margin: "0 -7px",
    }} onClick={props.onClick} >
      <div className="wrapper" ref={topRef} >
        <ResponsiveDrawer {...props} />
        <section className="containers" >
          <TopHeaderScreen {...props} />
          <div class="content content-wrap">
            <div class="content-mrg hasScreen" ref={homeRef} style={{ marginLeft: "20px" }}>
              {/* <Button onClick={() => navigate(-1)} className="backWrapper">
                <i className={`fa fa-caret-left`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
              </Button> */}
              <div style={{ marginBottom: '10px' }}></div>
              <div class="content content-pad-0">
                <div class="row">
                  <div class="col-lg-12">
                    <section id="tabs" class="project-tab">
                      <div class="container-fluids" style={{ maxWidth: "1440px" }}>
                        <Container isMobile={isMobile}>
                          <Grid container spacing={2} sx={{ padding: '8px', gap: 1, flexWrap: 'nowrap', overflowX: 'auto' }}>
                            <TransitionGroup component={null}>
                              {visible[0] && userDashboardData && ((userDashboardData?.[0]?.activecampaigns - userDashboardData?.[0]?.campsregistered) > 0) && (
                                <CSSTransition key="0" timeout={300} classNames={{
                                  enter: "slide-enter",
                                  enterActive: "slide-enter-active",
                                  exit: "slide-exit",
                                  exitActive: "slide-exit-active",
                                }}>
                                  <Grid
                                    item xs={3.7}
                                    style={{ paddingLeft: '0px', paddingTop: '0px', borderColor: 'gray', minWidth: '120px', }}
                                    // onClick={handlePopupHome1Open}
                                    sx={{
                                      border: '1px solid',
                                      position: 'relative',
                                      padding: '5px',
                                      borderRadius: '10px',
                                      backgroundColor: '#f0f0f0',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      textAlign: 'center',
                                      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                      backgroundImage: 'linear-gradient(145deg, #ffffff, #e6e6e6)',
                                      boxShadow: 'inset 0 0 5px rgba(255, 255, 255, 0.6), 0 4px 10px rgba(0, 0, 0, 0.2)',
                                      '&:hover': {
                                        transform: 'translateY(-4px)',
                                        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
                                      },
                                      // cursor:'pointer'
                                    }}
                                  >
                                    <CloseIcon
                                      sx={{
                                        position: 'absolute',
                                        top: '1px',
                                        right: '5px',
                                        cursor: 'pointer',
                                        fontSize: '12px',
                                        color: '#ff0000',
                                      }}
                                      onClick={() => handleClose(0)}
                                    />
                                    <span onClick={handlePopupHome1Open} style={{ cursor:'pointer', fontSize: isMobile ? 12 : 16, fontWeight: 600, color: 'white', backgroundColor: "#008080", padding: '3px 12px', borderRadius: "8px", marginTop: '1px' }}>{userDashboardData?.[0]?.campsunregistered}</span>
                                    <span onClick={handlePopupHome1Open} style={{ cursor:'pointer', fontSize: isMobile ? 10 : 12, color: '#333' }}>Camps to register</span>

                                  </Grid>

                                </CSSTransition>
                              )}
                              {visible[1] && !isEmpty(userDashboardData) && (userDashboardData?.[0]?.campsregistered > 0) && (
                                <CSSTransition key="1" timeout={300} classNames={{
                                  enter: "slide-enter",
                                  enterActive: "slide-enter-active",
                                  exit: "slide-exit",
                                  exitActive: "slide-exit-active",
                                }}>
                                  <Grid
                                    item xs={3.7}
                                    style={{ paddingLeft: '0px', paddingTop: '0px', borderColor: 'gray', minWidth: '120px' }}
                                    // onClick={handlePopupHome2Open}
                                    sx={{
                                      border: '1px solid',
                                      position: 'relative',
                                      padding: '5px',
                                      borderRadius: '10px',
                                      backgroundColor: '#f0f0f0',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      textAlign: 'center',
                                      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                      backgroundImage: 'linear-gradient(145deg, #ffffff, #e6e6e6)',
                                      boxShadow: 'inset 0 0 5px rgba(255, 255, 255, 0.6), 0 4px 10px rgba(0, 0, 0, 0.2)',
                                      '&:hover': {
                                        transform: 'translateY(-4px)',
                                        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
                                      },
                                      // cursor:'pointer'
                                    }}
                                  >
                                    <CloseIcon
                                      sx={{
                                        position: 'absolute',
                                        top: '1px',
                                        right: '5px',
                                        cursor: 'pointer',
                                        fontSize: '12px',
                                        color: '#ff0000',
                                      }}
                                      onClick={() => handleClose(1)}
                                    />
                                    <span onClick={handlePopupHome2Open} style={{ cursor:'pointer', fontSize: isMobile ? 12 : 16, fontWeight: 600, color: 'white', backgroundColor: "#008080", padding: '3px 12px', borderRadius: "8px", marginTop: '1px' }}>{userDashboardData?.[0]?.campsregistered}</span>
                                    <span onClick={handlePopupHome2Open} style={{ cursor:'pointer', fontSize: isMobile ? 10 : 12, color: '#333' }}>Camps to participate</span>

                                  </Grid>
                                </CSSTransition>
                              )}
                              {visible[2] && !isEmpty(userDashboardData) && userDashboardData?.[0]?.maxpointstobeearned !== 0 && (
                                <CSSTransition key="2" timeout={300} classNames={{
                                  enter: "slide-enter",
                                  enterActive: "slide-enter-active",
                                  exit: "slide-exit",
                                  exitActive: "slide-exit-active",
                                }}>
                                  <Grid
                                    item xs={3.7}
                                    style={{ paddingLeft: '0px', paddingTop: '0px', borderColor: 'gray', minWidth: '120px' }}
                                    // onClick={handlePopupHome3Open}
                                    sx={{
                                      border: '1px solid',
                                      position: 'relative',
                                      padding: '5px',
                                      borderRadius: '10px',
                                      backgroundColor: '#f0f0f0',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      textAlign: 'center',
                                      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                      backgroundImage: 'linear-gradient(145deg, #ffffff, #e6e6e6)',
                                      boxShadow: 'inset 0 0 5px rgba(255, 255, 255, 0.6), 0 4px 10px rgba(0, 0, 0, 0.2)',
                                      '&:hover': {
                                        transform: 'translateY(-4px)',
                                        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
                                      },
                                      // cursor: 'pointer'
                                    }}
                                  >
                                    <CloseIcon
                                      sx={{
                                        position: 'absolute',
                                        top: '1px',
                                        right: '5px',
                                        cursor: 'pointer',
                                        fontSize: '12px',
                                        color: '#ff0000',
                                      }}
                                      onClick={() => handleClose(2)}
                                    />
                                    <span onClick={handlePopupHome3Open} style={{ cursor:'pointer', fontSize: isMobile ? 12 : 16, fontWeight: 600, color: 'white', backgroundColor: "#008080", padding: '3px 12px', borderRadius: "8px", marginTop: '1px' }}>{userDashboardData?.[0]?.maxpointstobeearned}</span>
                                    <span onClick={handlePopupHome3Open} style={{ cursor:'pointer', fontSize: isMobile ? 10 : 12, color: '#333' }}>More points to WIN</span>

                                  </Grid>
                                </CSSTransition>
                              )}
                            </TransitionGroup>
                          </Grid>
                          <PopupHome1
                            isOpen={isPopupHome1Open || isPopupHome2Open || isPopupHome3Open}
                            setIsOpen={() => openPopup(null)}
                            th1={"Campaign"}
                            th2={
                              isPopupHome1Open ? "# of CMs" :
                                isPopupHome2Open ? "End Date" :
                                  isPopupHome3Open ? "Points to Win" :
                                    ""
                            }
                            attr1={
                              isPopupHome1Open ? "title" :
                                isPopupHome2Open ? "title" :
                                  isPopupHome3Open ? "title" :
                                    ""
                            }
                            attr2={
                              isPopupHome1Open ? "targetvol" :
                                isPopupHome2Open ? "enddate" :
                                  isPopupHome3Open ? "maxpointstobeearned" :
                                    ""
                            }
                            data={
                              isPopupHome1Open ? userDashboardData?.[0]?.activeunregcampdetails :
                                isPopupHome2Open ? userDashboardData?.[0]?.activeregcampdetails :
                                  isPopupHome3Open ? userDashboardData?.[0]?.campaignpointstoearn :
                                    ""
                            }
                          />
                        </Container>

                        <div class="cyagg-camp-space campaigns-tabs" >
                          <div >
                            <div class="row">
                              <div class="col-12">
                                <div>
                                  <h4 class="site-title">Campaigns <FaInfoCircle onClick={() => navigate(`/getstarted`)} style={{ cursor: 'pointer', color: '#DAA520', fontSize: '1em', }} title="How to get started" /></h4>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="widget-content animated-underline-content">
                            <ul
                              class="nav nav-tabs  mb-0 campaign-tabz innerCampaign"
                              id="animateLine"
                              role="tablist"
                              style={{ gap: '5px' }}
                            >
                              <li class="nav-item" style={{ minWidth: '80px' }}>
                                <a
                                  style={{ display: "flex", fontSize: isMobile ? '12px' : '16px', justifyContent: 'space-evenly' }}
                                  class="nav-link active"
                                  id="animated-underline-home-tab"
                                  data-toggle="tab"
                                  href="#animated-underline-home"
                                  role="tab"
                                  aria-controls="animated-underline-home"
                                  aria-selected="true"
                                  onClick={() => handleTabClick(0)}
                                >
                                  <i class="material-icons directions_run">
                                    &#xe566;
                                  </i>{" "}
                                  <span>Active</span>
                                  <span
                                    style={{
                                      color: "black",
                                      // textDecoration: "underline",
                                      backgroundColor: "#f0f0f0",
                                      padding: '1px 4px',
                                      borderRadius: "8px",
                                      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
                                    }}
                                  >
                                    {campStatusCount?.data?.[0]?.activecount}
                                  </span>
                                </a>
                              </li>
                              <li class="nav-item" style={{ minWidth: '75px' }}>
                                <a
                                  style={{ display: "flex", fontSize: isMobile ? '12px' : '16px', justifyContent: 'space-evenly' }}
                                  class="nav-link"
                                  id="animated-underline-profile-tab"
                                  data-toggle="tab"
                                  href="#animated-underline-profile"
                                  role="tab"
                                  aria-controls="animated-underline-profile"
                                  aria-selected="false"
                                  onClick={() => handleTabClick(1)}
                                >
                                  <i class="material-icons edit_calendar">
                                    &#xe742;
                                  </i>{" "}
                                  <span>Next</span>
                                  <span
                                    style={{
                                      color: "black",
                                      // textDecoration: "underline",
                                      backgroundColor: "#f0f0f0",
                                      padding: '1px 4px',
                                      borderRadius: "8px",
                                      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
                                    }}
                                  >
                                    {campStatusCount?.data?.[1]?.nextcount}
                                  </span>
                                </a>
                              </li>
                              <li class="nav-item" style={{ minWidth: '75px' }}>
                                <a
                                  style={{ display: "flex", fontSize: isMobile ? '12px' : '16px', justifyContent: 'space-evenly' }}
                                  class="nav-link"
                                  id="animated-underline-contact-tab"
                                  data-toggle="tab"
                                  href="#animated-underline-contact"
                                  role="tab"
                                  aria-controls="animated-underline-contact"
                                  aria-selected="false"
                                  onClick={() => handleTabClick(2)}
                                >
                                  <i class="material-icons check_circle_outline">
                                    &#xe92d;
                                  </i>{" "}
                                  <span>Done</span>
                                  <span
                                    style={{
                                      color: "black",
                                      backgroundColor: "#f0f0f0",
                                      padding: '1px 4px',
                                      borderRadius: "8px",
                                      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
                                    }}
                                  >
                                    {campStatusCount?.data?.[2]?.donecount}
                                  </span>
                                </a>
                              </li>

                              <li class="nav-item" style={{ minWidth: '75px' }}>
                                <a
                                  style={{ display: "flex", fontSize: isMobile ? '12px' : '16px', justifyContent: 'space-evenly' }}
                                  class="nav-link"
                                  id="animated-underline-recommended-tab"
                                  data-toggle="tab"
                                  href="#animated-underline-recommended"
                                  role="tab"
                                  aria-controls="animated-underline-recommended"
                                  aria-selected="false"
                                  onClick={() => handleTabClick(3)}
                                >
                                  <ThumbUpAltRoundedIcon />
                                  <span>For You</span>
                                </a>
                              </li>
                            </ul>
                            {isLoading ? (
                              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',minHeight:"400px", height: '20vh' }}>
                                <Circles
                                  type="TailSpin"
                                  color="rgb(155, 236, 34)"
                                  height={70}
                                  width={70}
                                  timeout={5000}
                                  ariaLabel="three-circles-rotating"
                                />
                              </div>
                            ) : (
                              <div
                                class="tab-content customOwlNav"
                                
                                id="animateLineContent-4"
                                style={{ maxWidth: '94%' }}
                              >
                                <div
                                  // class="tab-pane fade show active"
                                  className={`tab-pane fade ${activeTabIndex === 0 ? 'show active' : ''}`}
                                  id="animated-underline-home"
                                  role="tabpanel"
                                  aria-labelledby="animated-underline-home-tab"
                                  onTouchStart={handleTouch}
                                  onTouchMove={handleTouch}
                                  onTouchEnd={handleTouch}
                                >
                                  {campStatusCount &&
                                    campStatusCount?.data?.[0]?.activecount ===
                                    0 ? (
                                    <span>
                                      <i>
                                        <b>
                                          Please keep watching this space for
                                          new campaigns. You will also be
                                          notified here.
                                        </b>
                                      </i>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {/* campain carsouel homepage */}
                                  <OwlCarousel
                                    className="owl-theme"
                                    {...optionsTwo}
                                  >
                                    {myCampaignList &&
                                      myCampaignList?.data
                                        ?.filter(
                                          (item, index) =>
                                            item.active === "Active"
                                        )
                                        .map((tile, index, activeItems) => (
                                          <div style={{ textAlign: "center" }}>
                                            <span style={{ fontSize: isMobile ? 10 : 16 }}><span style={{ fontWeight: 600 }}>{index + 1}</span> {"/"} {activeItems.length}</span>
                                            <CampaignCard
                                              organizationlogo={tile?.organizationlogo}
                                              name={tile?.name}
                                              logo={tile?.logo}
                                              video={tile?.video}
                                              sponsor={tile?.sponsor}
                                              tag={tile?.tag}
                                              SDGCampMapping={SDGCampMapping}
                                              goalquantity={tile?.goalquantity}
                                              unitofgoal={tile?.unitofgoal}
                                              committedcount={tile?.committedcount}
                                              achievedcount={tile?.achievedcount}
                                              submittedcount={tile?.submittedcount}
                                              campaigntype={tile?.campaigntype}
                                              campid={tile?.campid}
                                              maxpointstobeearned={tile?.maxpointstobeearned}
                                              dayrem={tile?.dayrem}
                                              hourrem={tile?.hourrem}
                                              minrem={tile?.minrem}
                                              profileResponse={profileResponse}
                                              index={index}
                                              goal={tile?.goal}
                                              desc={tile?.desc}
                                              whattodo={tile?.whattodo}
                                              guidelines={tile?.guidelines}
                                              status={tile.active}
                                              landing="no"
                                              directlink="no"
                                              showcamplb="yes"
                                              participatepage="no"
                                              teamdetails={tile?.teamdetails}
                                              currentTabIndex = {activeTabIndex}
                                            />
                                          </div>

                                        ))}
                                  </OwlCarousel>
                                </div>

                                <div
                                  // class="tab-pane fade"
                                  className={`tab-pane fade ${activeTabIndex === 1 ? 'show active' : ''}`}
                                  id="animated-underline-profile"
                                  role="tabpanel"
                                  aria-labelledby="animated-underline-profile-tab"
                                  onTouchStart={handleTouch}
                                  onTouchMove={handleTouch}
                                  onTouchEnd={handleTouch}
                                >
                                  {campStatusCount?.data?.[1]?.nextcount ===
                                    0 && (
                                      <span>
                                        <i>
                                          <b>
                                            Please keep watching this space for
                                            new campaigns. You will also be
                                            notified here.
                                          </b>
                                        </i>
                                      </span>
                                    )}
                                  {/* <div class="campaign-sl owl-carousel owl-theme"> */}
                                  <OwlCarousel
                                    className="owl-theme"
                                    {...optionsTwo}
                                  >
                                    {myCampaignList && myCampaignList?.data
                                      ?.filter(
                                        (item, index) =>
                                          item.active === "Upcoming"
                                      )
                                      .map((tile, index, upcomingItems) => (
                                        <div style={{ textAlign: "center" }}>
                                          <span style={{ fontSize: isMobile ? 10 : 16 }}><span style={{ fontWeight: 600 }}>{index + 1}</span> {"/"} {upcomingItems.length}</span>
                                          <CampaignCard
                                            organizationlogo={tile?.organizationlogo}
                                            name={tile?.name}
                                            logo={tile?.logo}
                                            video={tile?.video}
                                            sponsor={tile?.sponsor}
                                            tag={tile?.tag}
                                            SDGCampMapping={SDGCampMapping}
                                            goalquantity={tile?.goalquantity}
                                            unitofgoal={tile?.unitofgoal}
                                            committedcount={tile?.committedcount}
                                            achievedcount={tile?.achievedcount}
                                            submittedcount={tile?.submittedcount}
                                            campaigntype={tile?.campaigntype}
                                            campid={tile?.campid}
                                            maxpointstobeearned={tile?.maxpointstobeearned}
                                            dayrem={tile?.dayrem}
                                            hourrem={tile?.hourrem}
                                            minrem={tile?.minrem}
                                            profileResponse={profileResponse}
                                            index={index}
                                            goal={tile?.goal}
                                            desc={tile?.desc}
                                            whattodo={tile?.whattodo}
                                            guidelines={tile?.guidelines}
                                            status={tile.active}
                                            landing="no"
                                            directlink="no"
                                            showcamplb="yes"
                                            participatepage="no"
                                            currentTabIndex = {activeTabIndex}
                                          />
                                        </div>
                                      ))}
                                  </OwlCarousel>
                                </div>
                                <div
                                  // class="tab-pane fade"
                                  className={`tab-pane fade ${activeTabIndex === 2 ? 'show active' : ''}`}
                                  id="animated-underline-contact"
                                  role="tabpanel"
                                  aria-labelledby="animated-underline-contact-tab"
                                  onTouchStart={handleTouch}
                                  onTouchMove={handleTouch}
                                  onTouchEnd={handleTouch}
                                >

                                  {campStatusCount?.data?.[2]?.donecount ===
                                    0 && (
                                      <span>
                                        <i>
                                          <b>
                                            Completed and Paused campaigns will
                                            appear here.
                                          </b>
                                        </i>
                                      </span>
                                    )}
                                  <OwlCarousel
                                    className="owl-theme"
                                    {...optionsTwo}
                                  >
                                    {myCampaignList && myCampaignList?.data
                                      ?.filter(
                                        (item, index) =>
                                          item.active === "Completed" 
                                        ||
                                          item.active === "Paused"
                                      )
                                      .map((tile, index, donepauseItems) => (
                                        <div style={{ textAlign: "center" }}>
                                          <span style={{ fontSize: isMobile ? 10 : 16 }}><span style={{ fontWeight: 600 }}>{index + 1}</span> {"/"} {donepauseItems.length}</span>
                                          <Typography style={{ textAlign: "center" }}>
                                            <Chip
                                              key={tile.active}
                                              fontSize="small"
                                              size="small"
                                              style={{
                                                backgroundColor:
                                                  tile.active === "Completed"
                                                    ? "#6C757D"
                                                    : tile.active === "Paused"
                                                      ? "#EF4E4E"
                                                      : tile.active === "Upcoming"
                                                        ? "#FADB5F"
                                                        : tile.active === "Active"
                                                          ? "#0D731E"
                                                          : "#000000", // Default color, if none of the conditions match
                                                color:
                                                  tile.active === "Upcoming"
                                                    ? "black"
                                                    : "white",
                                                marginBottom: "5px",
                                              }}
                                              label={tile.active}
                                            />
                                          </Typography>

                                          <CampaignCard
                                            organizationlogo={tile?.organizationlogo}
                                            name={tile?.name}
                                            logo={tile?.logo}
                                            video={tile?.video}
                                            sponsor={tile?.sponsor}
                                            tag={tile?.tag}
                                            SDGCampMapping={SDGCampMapping}
                                            goalquantity={tile?.goalquantity}
                                            unitofgoal={tile?.unitofgoal}
                                            committedcount={tile?.committedcount}
                                            achievedcount={tile?.achievedcount}
                                            submittedcount={tile?.submittedcount}
                                            campaigntype={tile?.campaigntype}
                                            campid={tile?.campid}
                                            maxpointstobeearned={tile?.maxpointstobeearned}
                                            dayrem={tile?.dayrem}
                                            hourrem={tile?.hourrem}
                                            minrem={tile?.minrem}
                                            profileResponse={profileResponse}
                                            index={index}
                                            goal={tile?.goal}
                                            desc={tile?.desc}
                                            whattodo={tile?.whattodo}
                                            guidelines={tile?.guidelines}
                                            landing="no"
                                            status={tile.active}
                                            directlink="no"
                                            showcamplb="yes"
                                            participatepage="no"
                                            currentTabIndex = {activeTabIndex}

                                          />
                                        </div>
                                      ))}
                                  </OwlCarousel>
                                </div>
                                {/* <div
                                  // class="tab-pane fade"
                                  className={`tab-pane fade ${activeTabIndex === 2 ? 'show active' : ''}`}
                                  id="animated-underline-paused"
                                  role="tabpanel"
                                  aria-labelledby="animated-underline-paused-tab"
                                  onTouchStart={handleTouch}
                                  onTouchMove={handleTouch}
                                  onTouchEnd={handleTouch}
                                >

                                  <OwlCarousel
                                    className="owl-theme"
                                    {...optionsTwo}
                                  >
                                    {myCampaignList && myCampaignList?.data
                                      ?.filter(
                                        (item, index) =>
                                          item.active === "Paused"
                                      )
                                      .map((tile, index, pausedItems) => (
                                        <div style={{ textAlign: 'center' }}>
                                          <span style={{ fontSize: isMobile ? 10 : 16 }}><span style={{ fontWeight: 600 }}>{index + 1}</span> {"/"} {pausedItems.length}</span>
                                          <Typography style={{ textAlign: "center" }}>
                                            <Chip
                                              key={tile.active}
                                              fontSize="small"
                                              size="small"
                                              style={{
                                                backgroundColor:
                                                  tile.active === "Completed"
                                                    ? "#6C757D"
                                                    : tile.active === "Paused"
                                                      ? "#EF4E4E"
                                                      : tile.active === "Upcoming"
                                                        ? "#FADB5F"
                                                        : tile.active === "Active"
                                                          ? "#0D731E"
                                                          : "#000000", // Default color, if none of the conditions match
                                                color:
                                                  tile.active === "Upcoming"
                                                    ? "black"
                                                    : "white",
                                                marginBottom: "5px",
                                              }}
                                              label={tile.active}
                                            />
                                          </Typography>

                                          <CampaignCard
                                            organizationlogo={tile?.organizationlogo}
                                            name={tile?.name}
                                            logo={tile?.logo}
                                            video={tile?.video}
                                            sponsor={tile?.sponsor}
                                            tag={tile?.tag}
                                            SDGCampMapping={SDGCampMapping}
                                            goalquantity={tile?.goalquantity}
                                            unitofgoal={tile?.unitofgoal}
                                            committedcount={tile?.committedcount}
                                            achievedcount={tile?.achievedcount}
                                            submittedcount={tile?.submittedcount}
                                            campaigntype={tile?.campaigntype}
                                            campid={tile?.campid}
                                            maxpointstobeearned={tile?.maxpointstobeearned}
                                            dayrem={tile?.dayrem}
                                            hourrem={tile?.hourrem}
                                            minrem={tile?.minrem}
                                            profileResponse={profileResponse}
                                            index={index}
                                            goal={tile?.goal}
                                            desc={tile?.desc}
                                            whattodo={tile?.whattodo}
                                            guidelines={tile?.guidelines}
                                            landing="no"
                                            status={tile.active}
                                            directlink="no"
                                            showcamplb="yes"
                                            participatepage="no"
                                            currentTabIndex = {activeTabIndex}

                                          />
                                        </div>

                                      ))}
                                  </OwlCarousel>
                                </div> */}

                                {/* This is for RECOMMENDED */}

                                <div
                                  // class="tab-pane fade"
                                  className={`tab-pane fade ${activeTabIndex === 3 ? 'show active' : ''}`}
                                  id="animated-underline-recommended"
                                  role="tabpanel"
                                  aria-labelledby="animated-underline-recommended-tab"
                                  onTouchStart={handleTouch}
                                  onTouchMove={handleTouch}
                                  onTouchEnd={handleTouch}
                                >

                                  {!isArray(myRecommendedCampList?.data) &&
                                    myRecommendedCampList?.data?.length === 0 ? (
                                    <span>
                                      <i>
                                        <b>
                                          Campaigns that matches your skills and
                                          interests will be shown here
                                        </b>
                                      </i>
                                    </span>
                                  ) : (
                                    <span>
                                      <i>
                                        <b>Campaigns you might be interested in...</b>
                                      </i>
                                    </span>
                                  )}
                                  <OwlCarousel
                                    className="owl-theme"
                                    {...optionsTwo}
                                  >
                                    {isArray(myRecommendedCampList?.data) &&

                                      myRecommendedCampList?.data?.map(
                                        (tile, index, recommendedItems) => (

                                          <CampaignCard
                                            organizationlogo={tile?.[0]?.organizationlogo}
                                            name={tile?.[0]?.name}
                                            logo={tile?.[0]?.logo}
                                            video={tile?.[0]?.video}
                                            sponsor={tile?.[0]?.sponsor}
                                            tag={tile?.[0]?.tag}
                                            SDGCampMapping={SDGCampMapping}
                                            goalquantity={tile?.[0]?.goalquantity}
                                            unitofgoal={tile?.[0]?.unitofgoal}
                                            committedcount={tile?.[0]?.committedcount}
                                            achievedcount={tile?.[0]?.achievedcount}
                                            submittedcount={tile?.[0]?.submittedcount}
                                            campaigntype={tile?.[0]?.campaigntype}
                                            campid={tile?.[0]?.campid}
                                            maxpointstobeearned={tile?.[0]?.maxpointstobeearned}
                                            dayrem={tile?.[0]?.dayrem}
                                            hourrem={tile?.[0]?.hourrem}
                                            minrem={tile?.[0]?.minrem}
                                            profileResponse={profileResponse}
                                            index={index}
                                            goal={tile?.[0]?.goal}
                                            desc={tile?.[0]?.desc}
                                            whattodo={tile?.[0]?.whattodo}
                                            guidelines={tile?.[0]?.guidelines}
                                            landing="no"
                                            status={tile.active}
                                            directlink="no"
                                            showcamplb="yes"
                                            participatepage="no"
                                            currentTabIndex = {activeTabIndex}
                                          />
                                        )
                                      )}
                                  </OwlCarousel>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </section>{" "}
                  </div>
                </div>
              </div>

              <div class="container-fluid" style={{ paddingLeft: isMobile ? "0px" : "15px", maxHeight: isMobile ? '254px' : '', minHeight: isMobile ? '' : '265px', }}>
         
             { !isLoading && myCampaignList && myCampaignList?.data ? (
                <div class="cyagg-sec-space">
                  <div>
                    <div class="row">
                      <div class="col-8">
                        <div>
                          {isMobile ?
                            <h5 class="site-title">My Overall Status</h5>
                            :
                            <h4 class="site-title">My Overall Status</h4>
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="row">
                      <div class="col-md-6">
                        <div>
                          <img
                            src={OverallStatusImg}
                            // class="hide-on-mobile"
                            class="img-fluid"
                            style={{
                              // width: "62%",
                              width: "36%",
                              margin: "0px auto",
                              display: isMobile ? 'none' : 'block'
                            }}
                          />
                        </div>
                      </div>
                      <div class="col-md-6" style={{
                        transform: isMobile ? "scale(0.8)" : "scale(1)", transformOrigin: isMobile ? "center top" : "top left",
                        margin: isMobile ? "0 auto" : "0",
                        textAlign: isMobile ? "center" : "left",
                      }}>
                        <div class="impact-sec-counts-wrap">
                          <div class="impact-sec-counts">
                            <div class="row">
                              <div class="col-md-3 col-6" style={{ paddingBottom: 20 }}>
                                <div>
                                  <span>My Points</span>
                                  <h5>
                                    {mycommunity?.data?.mylevel?.rewardpoint}
                                  </h5>
                                </div>
                              </div>
                              <div class="col-md-3 col-6" style={{ paddingBottom: 20 }}>
                                <div>
                                  <span>My Rank</span>
                                  <h5>
                                    {mycommunity?.data?.myrankpoints?.myrank}
                                  </h5>
                                </div>
                              </div>
                              <div class="col-md-3 col-6" style={{ paddingBottom: 20 }}>
                                <div>
                                  <span>My Level</span>
                                  <h5>
                                    {mycommunity?.data?.mylevel?.levelname}
                                  </h5>
                                </div>
                              </div>
                              <div class="col-md-3 col-6" style={{ paddingBottom: 20 }}>
                                <div>
                                  <span>Community Size</span>
                                  <h5>{mycommunity?.data?.refcount}</h5>
                                </div>
                              </div>
                            </div>
                            <div>
                              <h6 class="progress-head" style={{ marginTop: 0 }}>
                                Across all campaigns
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> ) : (<div></div>)
}
              </div>

              <div class="container-fluid" style={{ paddingLeft: isMobile ? "0px" : "15px", maxHeight: isMobile ? '480px' : '', minHeight: isMobile ? '' : '583px' }}>
                
              { !isLoading && myCampaignList && myCampaignList?.data ? (
                <div class="cyagg-sec-space">
                  <div>
                    <div class="row topSpace">

                      <div class="col-7">
                        <div>
                          {isMobile ?
                            <h5 class="site-title">Win Rewards!</h5>
                            :
                            <h4 class="site-title">Win Rewards!</h4>
                          }
                        </div>
                      </div>
                      <div class="col-5">
                        <div
                          style={{ textAlign: "right" }}
                          onClick={() => navigate(`/marketplace`)}
                        >
                          <a class="story-signin" style={{ padding: "6px 8px" }}>
                            View More <i class="fa fa-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4">
                      <div>
                        <img
                          class="hide-on-mobile"
                          src={RewardMainImage}
                          style={{ width: "100%", }}
                        />
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="" style={{ float: "left", width: "100%", transform: isMobile ? "scale(0.8)" : "scale(1)", transformOrigin: isMobile ? "center top" : "top left" }}>
                        <Slider {...settings}>
                          {marketplacelist &&
                            marketplacelist?.map(
                              (reward, index) =>
                                index < 5 && (
                                  <div class="item">
                                    <div class="reward-wrap">
                                      <div>
                                        {!(reward?.logo === "") ? (
                                          reward?.expired === "yes" ? (
                                            <img
                                              class="reward-imagehm"
                                              src={reward?.logo}
                                              style={{
                                                width: "100%",
                                                opacity: 0.4,
                                              }}
                                            />
                                          ) : (
                                            <img
                                              class="reward-imagehm"
                                              src={reward?.logo}
                                              style={{ width: "100%" }}
                                            />
                                          )
                                        ) : reward?.expired === "yes" ? (
                                          <img
                                            class="reward-imagehm"
                                            src={CEOImage}
                                            style={{
                                              width: "100%",
                                              opacity: 0.4,
                                            }}
                                          />
                                        ) : (
                                          <img
                                            class="reward-imagehm"
                                            src={CEOImage}
                                            style={{ width: "100%" }}
                                          />
                                        )}
                                      </div>
                                      <div class="reward-details">
                                        <div>
                                          <div>
                                            <div id="toggleAccordion">
                                              <div class="card mb-1">
                                                <div
                                                  class="card-header"
                                                  id={"headingOne" + index}
                                                >
                                                  <h5 class="mb-0 mt-0 reward-desc-title">
                                                    <span
                                                      role="menu"
                                                      class=""
                                                      onClick={() => handleToggle(index)}
                                                      aria-expanded={expanded === index}
                                                      aria-controls={`rew${index}`}
                                                    >
                                                      {reward?.rewardtype}
                                                      <i class="flaticon-down-arrow float-right"></i>
                                                    </span>
                                                  </h5>
                                                </div>

                                                <div
                                                  id={`rew${index}`}
                                                  className={`collapse ${expanded === index ? "show" : ""}`}
                                                  aria-labelledby={`headingOne${index}`}
                                                  data-parent="#toggleAccordion"
                                                >
                                                  <div class="card-body">
                                                    <p class="mb-3">
                                                      <div
                                                        style={{ textAlign: "center", }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 12,
                                                            color: "blue",
                                                          }}
                                                        >
                                                          {" "}
                                                          {
                                                            reward?.subtitle
                                                          }{" "}
                                                        </span>
                                                      </div>
                                                    </p>
                                                    <p class="mb-3">
                                                      <div
                                                        style={{ textAlign: "center", }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 12,
                                                            color: "green",
                                                          }}
                                                        >
                                                          {" "}
                                                          {reward?.title}{" "}
                                                        </span>
                                                      </div>
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="redeem-pts1">
                                            <div class="row">
                                              <div class="col-12">
                                                <div class="re-po1-right">
                                                  {!(
                                                    reward?.campid ===
                                                    "camp00001"
                                                  ) ? (
                                                    <Button
                                                      onClick={
                                                        () =>
                                                          navigate(
                                                            `/participate/campaign/${reward?.campid}/${profileResponse?.phoneno}`
                                                          )
                                                      }
                                                      style={{
                                                        textAlign: "left",
                                                        maxHeight: "50px",
                                                      }}
                                                    >
                                                      <a
                                                        style={{
                                                          fontFamily:
                                                            "sans-serif",
                                                        }}
                                                      >
                                                        {reward?.campname}
                                                      </a>
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      onClick={
                                                        () =>
                                                          navigate(`/aboutus`)
                                                      }
                                                      style={{

                                                        textAlign: "left",
                                                        maxHeight: "50px",
                                                      }}
                                                    >
                                                      <a
                                                        style={{
                                                          fontFamily:
                                                            "sans-serif",
                                                        }}
                                                      >
                                                        {reward?.campname}
                                                      </a>
                                                    </Button>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="text-center">
                                            <h6 class="sponsor-title">
                                              Sponsors
                                            </h6>
                                            <div class="row sponserWrapper">
                                              <div class="">
                                                {isArray(reward?.sponsor) &&
                                                  reward?.sponsor?.map(
                                                    (reward, index) => (
                                                      <img
                                                        style={{
                                                          width: "auto",
                                                          height: "50px",
                                                        }}
                                                        src={
                                                          reward?.sponsorlogo
                                                        }
                                                      />
                                                    )
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                            )}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div> ) : (<div></div>)
}


              </div>

              <div class="container-fluid" style={{ paddingLeft: isMobile ? "0px" : "15px", maxHeight: isMobile ? '465px' : '', minHeight: isMobile ? '' : '505px' }}>
                
              { !isLoading && myCampaignList && myCampaignList?.data ? (
                <div class="cyagg-sec-space">
                  <div>
                    <div class="row topSpace">
                      <div class="col-7">
                        <div>
                          {isMobile ?
                            <h5 class="site-title">Rewards Redeemed</h5>
                            :
                            <h4 class="site-title">Rewards Redeemed</h4>
                          }
                        </div>
                      </div>
                      <div class="col-5">
                        <div
                          style={{ textAlign: "right" }}
                          onClick={() => navigate(`/redeemreport`)}
                        >
                          <a class="story-signin" style={{ padding: "6px 8px" }}>
                            View More
                            <i class="fa fa-arrow-right" style={{ marginLeft: "3px" }}></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4">
                      <div>
                        <img
                          class="hide-on-mobile"
                          src={RewardMainImage}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="" style={{ float: "left", width: "100%", transform: isMobile ? "scale(0.8)" : "scale(1)", transformOrigin: isMobile ? "center top" : "top left" }}>
                        <Slider {...settings}>
                          {myredeemedrewards &&
                            myredeemedrewards?.data?.map(
                              (reward, index) =>
                                index < 2 &&
                                reward?.reward?.map(
                                  (item1, index1) =>
                                    index1 < 5 && (
                                      <div class="item" key={`${index}-${index1}`}>
                                        <div class="reward-wrap" style={{ position: "relative" }}>
                                          <div>
                                            {!(
                                              item1?.logo === "" ||
                                              item1?.logo === undefined
                                            ) ? (
                                              <img
                                                class="reward-image"
                                                src={item1?.logo}
                                                style={{ width: "100%" }}
                                              />
                                            ) : item1?.logo === undefined ? (
                                              <img
                                                class="reward-image"
                                                src={CEOImage}
                                                style={{ width: "100%" }}
                                              />
                                            ) : (
                                              <img
                                                class="reward-image"
                                                src={CEOImage}
                                                style={{ width: "100%" }}
                                              />
                                            )}

                                          </div>
                                          <div class="reward-details">
                                            <div>
                                              <div>
                                                <div id="toggleAccordion">
                                                  <div class="card mb-1">
                                                    <div
                                                      class="card-header"
                                                      id={"headingOne4" + index1}

                                                    >
                                                      <h5 class="mb-0 mt-0 reward-desc-title">
                                                        <span
                                                          role="menu"
                                                          class=""
                                                          onClick={() => handleToggle("reward" + index1)}
                                                          aria-expanded={expanded === "reward" + index1}
                                                          aria-controls={`rew${index1}`}
                                                        >
                                                          {item1?.rewardtype}
                                                          <i class="flaticon-down-arrow float-right"></i>
                                                        </span>
                                                      </h5>
                                                    </div>

                                                    <div
                                                      id={`rew${index1}`}
                                                      className={`collapse ${expanded === "reward" + index1 ? "show" : ""}`}
                                                      aria-labelledby={`headingOne${index1}`}
                                                      data-parent="#toggleAccordion"
                                                    >
                                                      <div class="card-body">
                                                        <p class="mb-3">
                                                          <div
                                                            style={{ textAlign: "center", }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 12,
                                                                color: "blue",
                                                              }}
                                                            >
                                                              {" "}
                                                              {
                                                                item1?.subtitle
                                                              }{" "}
                                                            </span>
                                                          </div>
                                                        </p>
                                                        <p class="mb-3">
                                                          <div
                                                            style={{ textAlign: "center", }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 12,
                                                                color: "green",
                                                              }}
                                                            >
                                                              {" "}
                                                              {item1?.title}{" "}
                                                            </span>
                                                          </div>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="redeem-pts">
                                                <div class="row">
                                                  <div class="col-12">
                                                    <div class="re-po1-right">
                                                      {!(
                                                        item1?.campid ===
                                                        "camp00001"
                                                      ) ? (
                                                        <Button
                                                          onClick={
                                                            () =>
                                                              navigate(
                                                                `/participate/campaign/${item1?.campid}/${profileResponse?.phoneno}`
                                                              )
                                                          }
                                                          style={{
                                                            // float: "right",
                                                            // width: "114px",
                                                            textAlign: "left",
                                                            maxHeight: "50px",
                                                          }}
                                                        >
                                                          <a
                                                            style={{
                                                              fontFamily:
                                                                "sans-serif",
                                                            }}
                                                          >
                                                            {item1?.campname}
                                                          </a>
                                                        </Button>
                                                      ) : (
                                                        <Button
                                                          onClick={
                                                            () =>
                                                              navigate(`/aboutus`)
                                                          }
                                                          style={{
                                                            // float: "right",
                                                            // width: "114px",
                                                            textAlign: "left",
                                                            maxHeight: "50px",
                                                          }}
                                                        >
                                                          <a
                                                            style={{
                                                              fontFamily:
                                                                "sans-serif",
                                                            }}
                                                          >
                                                            {item1?.campname}
                                                          </a>
                                                        </Button>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="text-center">
                                                <h6 class="sponsor-title">
                                                  Sponsors
                                                </h6>
                                                <div class="row sponserWrapper">
                                                  <div class="">
                                                    {isArray(item1?.sponsor) &&
                                                      item1?.sponsor?.map(
                                                        (reward, index) => (
                                                          <img
                                                            style={{
                                                              width: "auto",
                                                              height: "50px",
                                                            }}
                                                            src={
                                                              reward?.sponsorlogo
                                                            }
                                                          />
                                                        )
                                                      )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div>
                                                <img
                                                  src={WonIcon}
                                                  class="won-re-img"
                                                  alt="Won Icon"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    )
                                )
                            )}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div> ) : (<div></div>)
}



              </div>

              <div class="container-fluid" style={{ paddingLeft: isMobile ? "0px" : "15px", maxHeight: isMobile ? '283px' : '' }}>
              { !isLoading && myCampaignList && myCampaignList?.data ? (
                <div class="cyagg-sec-space">
                  <div>
                    <div class="row topSpace">
                      <div class="col-7">
                        <div>
                          <h4 class="site-title">Success Stories</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="home-demo" style={{ transform: isMobile ? "scale(0.8)" : "scale(1)", transformOrigin: isMobile ? "center top" : "top left" }}>
                    <OwlCarousel className="owl-theme" {...optionsFour}>
                      {successStories?.data?.map((item, index) => (
                        <div class="item">
                          <div class="succ-story-wrap">
                            <div>
                              {/* <img src="https://www.fuersie.de/sites/default/files/styles/image360w/public/2022-06/egozentrische-person.jpg?h=fa6d1f7a&itok=da_GdqKf" /> */}
                              <img src={item.image} />
                            </div>
                            <div class="text-center succ-story-details">
                              <h6>{item?.name}</h6>
                              <p classNName="sucessStoryContent" title={item?.msg}>{item?.msg}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                </div> ) : (<div></div>)
}

              </div>

              <div class="container-fluid" style={{ paddingLeft: isMobile ? "0px" : "15px", marginTop: "30px" }}>
                
              { !isLoading && myCampaignList && myCampaignList?.data ? (
                <div class="cyagg-sec-space">
                  <div class="testimonial-wrap">
                    <div class="row">
                      <div class="col-md-4 hide-on-spec">
                        <div class="testi-img">
                          <img src={TestiImg} style={{ width: "100%" }} />
                        </div>
                      </div>
                      <div class="col-md-8 add-full-width">
                        <div class="testi-content">
                          <h2>What our changemakers say about cYAAG</h2>
                          <div>
                            {/* <div class="testi-sl owl-carousel owl-theme"> */}
                            <OwlCarousel
                              className="owl-theme"
                              items="1"
                              autoplay
                              nav
                              dots
                              loop
                            >
                              <div class="item">
                                <div class="they-said-wrap">
                                  {/* <img
                                    class="test-per-img"
                                    src="https://www.fuersie.de/sites/default/files/styles/image360w/public/2022-06/egozentrische-person.jpg?h=fa6d1f7a&itok=da_GdqKf"
                                  /> */}
                                  <Avatar> </Avatar>
                                  <div class="testi-desc">
                                    <h6>CEO of a Healthcare Innovations Company, Delhi, India</h6>
                                    <p style={{ fontSize: 16 }}>
                                      cYAAG gets results. It is the current day
                                      Digital Word of Mouth!
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="item">
                                <div class="they-said-wrap">
                                  {/* <img
                                    class="test-per-img"
                                    src="https://www.fuersie.de/sites/default/files/styles/image360w/public/2022-06/egozentrische-person.jpg?h=fa6d1f7a&itok=da_GdqKf"
                                  /> */}
                                  <Avatar> </Avatar>
                                  <div class="testi-desc">
                                    <h6>Student from RV University, Bangalore, India</h6>
                                    <p style={{ fontSize: 16 }}>
                                      This platform empowers social changemakers
                                      to create impact!
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="item">
                                <div class="they-said-wrap">
                                  <Avatar> </Avatar>
                                  <div class="testi-desc">
                                    <h6>⁠Director of an Assistive Technology Foundation, San Francisco, USA</h6>
                                    <p style={{ fontSize: 16 }}>
                                      The goal was to reach visually impaired
                                      people. Participants and recipients were
                                      inspired and motivated. This YAAG has
                                      wings.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* </div> */}
                            </OwlCarousel>
                          </div>
                          {/* </OwlCarousel> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>) : (<div></div>)
}


              </div>
            </div>
          </div>
          <CopyRight />
          <FixedFooterIcon />
          <RedeemConfirmation
            showModal={displayRedeemConfirmationModal}
            confirmModal={handleRedeem}
            hideModal={hideRedeemConfirmationModal}
            message={"Are you sure you want to redeem this reward?"}
          />
          <ConfirmationDialog
            showModal={displayConfirmationModal}
            // confirmModal={handleRedeem}
            hideModal={hideConfirmationModal}
            message={"You do not have enough points to redeem"}
          />
          <PostRedeemConfirmation
            showModal={postRedeemDisplayModal}
            confirmModal={handlePostRedeemModal}
            // hideModal={handlePostRedeemModal}
            message={"You have successfully redeemed"}
          />
        </section>
        <div className="askAgni">
          {showChat1 && <AIChatAskAnything onParentFunction={handleParentFunction} />}
          <StyledFab aria-label="chat">
            <TextOverlay>Ask AGNI</TextOverlay>
            <AnimatedIcon onClick={handleChatClick} />
          </StyledFab>
        </div>

      </div>
    </Card>
  );
};

export default HAScreen;
