import React from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import ResponsiveDrawer from "./Header";
import { CopyRight } from "./CopyRight";
import axios from "axios";
import _ from "lodash";
import TopHeaderScreen from "./TopHeader1";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { CSVLink, CSVDownload } from "react-csv";
import { FixedFooterIcon } from "./FixedFooterIcon";

function CampOReport(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [campName, setCampName] = React.useState([]);
  const [selectedcampname, setselectedcampname] = React.useState([]);
  const [campSubmissionlist, setCampSubmissionlist] = React.useState([]);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  React.useEffect(() => {
    getCampName();
  }, []);

  const loadOptions = (searchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = campName.filter((category) =>
        category.label.toLowerCase().includes(searchValue.toLowerCase())
      );
      callback(filteredOptions);
    });
  };

  const getCampName = async () => {
    try {
      const res = await axios.get(`/camp/name/${profileResponse?.phoneno}`);
      setCampName(res?.data);

      return res;
    } catch (err) {
      throw err;
    }
  };
  const getRewardsFromMarketplaceOnSearch = async () => {
    try {
      const res = await axios
        .get(
          `/camp/o/report/${profileResponse?.phoneno}/${selectedcampname[0]["label"]}`
        )
        .then((response) => {
          setCampSubmissionlist(response?.data);
          return response;
        });
      //   }
    } catch (err) {
      throw err;
    }
  };

  const colorStyles = {
    control: (styles) => ({ ...styles }),
    multiValue: (styles, { data }) => {
      return { ...styles, backgroundColor: "#0096FF", color: "#fff" };
    },
    multiValueLabel: (styles, { data }) => {
      return { ...styles, color: "#fff" };
    },
    multiValueRemove: (styles, { data }) => {
      return {
        ...styles,
        color: "#fff",
        cursor: "pointer",
        ":hover": {
          color: "#fff",
        },
      };
    },
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Report on Campaign Submissions
            </div>
            <div class="formMarketPlace">
              <div>
                <div
                  class="form-market-wrap"
                  id=""
                  role="tabpanel"
                  aria-labelledby="nav-places-tab"
                >
                  <h6>Please select a Campaign</h6>
                  <form action="#" method="get">
                    <div
                      style={{
                        padding: "0px 0px 0px 0px",
                        width: "300px",
                        marginBottom: "5px",
                        maxWidth: "100%",
                      }}
                    >
                      <AsyncSelect
                        loadOptions={loadOptions}
                        defaultOptions={campName}
                        value={selectedcampname}
                        isMulti
                        placeholder="Select Campaign"
                        isOptionDisabled={() => selectedcampname.length >= 1}
                        styles={colorStyles}
                        onChange={(event) => {
                          setselectedcampname(event);

                        }}
                      />
                    </div>

                    <Button
                      class="btn camp-report-gen"
                      variant="contained"
                      onClick={getRewardsFromMarketplaceOnSearch}
                      className="btn save-btn"
                      disableElevation
                      style={{ marginLeft: "30px" }}
                    >
                      <i class="fa fa-search pr-2" aria-hidden="true"></i>
                      <span>Generate Report</span>
                    </Button>
                    <Button
                      class="btn camp-report"
                      variant="contained"
                      className="btn save-btn"
                      style={{ marginLeft: "30px" }}
                    >
                      <i
                        class="fa fa-arrow-circle-down pr-2"
                        aria-hidden="true"
                        style={{ color: "black" }}
                      ></i>
                      <CSVLink data={campSubmissionlist}>
                        Download Report
                      </CSVLink>
                    </Button>
                  </form>
                </div>
              </div>
            </div>

            <header id="SOADashboardScreen">
              <form autoComplete="off">
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  ></div>
                  <div
                    className="centerAlign"
                    style={{ padding: "16px 0", marginBottom: 50 }}
                  >
                    <br />

                    <div class="containers">
                      <div class="cyagg-sec-space">
                        <div>
                          <div class="col-md-12">
                            <div class="">
                              <div>
                                {campSubmissionlist.length > 0 && (
                                  <div
                                    class="marketplaceinrow"
                                    style={{
                                      marginBottom: "30px",
                                      float: "left",
                                    }}
                                  >
                                    <div class="reward-wrap-marketplace" style={{ display: "inline-flex" }}>
                                      {/* <div class="reward-details"> */}
                                      <h6 class="sponsor-title">
                                        Submissions from other orgs
                                      </h6>
                                      <div
                                        class="row sponserWrapper"
                                        style={{ fontSize: "20px", fontWeight: "500" }}
                                      >
                                        {
                                          campSubmissionlist?.[0]
                                            ?.acrossorgsubmitted
                                        }
                                      </div>
                                    </div>

                                    <div class="reward-wrap-marketplace" style={{ display: "inline-flex" }}>
                                      <h6 class="sponsor-title">
                                        Approvals from other orgs
                                      </h6>
                                      <div
                                        class="row sponserWrapper"
                                        style={{ fontSize: "20px", fontWeight: "500" }}
                                      >
                                        {
                                          campSubmissionlist?.[0]
                                            ?.acrossorgapproved
                                        }
                                      </div>
                                    </div>


                                    <div class="reward-wrap-marketplace" style={{ display: "inline-flex" }}>
                                      <h6 class="sponsor-title">
                                        Rejections from other orgs
                                      </h6>
                                      <div
                                        class="row sponserWrapper"
                                        style={{ fontSize: "20px", fontWeight: "500" }}
                                      >
                                        {
                                          campSubmissionlist?.[0]
                                            ?.acrossorgrejected
                                        }
                                      </div>
                                    </div>


                                    <div class="reward-wrap-marketplace" style={{ display: "inline-flex" }}>
                                      <h6 class="sponsor-title">
                                        Submissions from my org
                                      </h6>
                                      <div
                                        class="row sponserWrapper"
                                        style={{ fontSize: "20px", fontWeight: "500" }}
                                      >
                                        {
                                          campSubmissionlist?.[0]
                                            ?.sameorgsubmitted
                                        }
                                      </div>
                                    </div>



                                    <div class="reward-wrap-marketplace" style={{ display: "inline-flex" }}>
                                      <h6 class="sponsor-title">
                                        Approvals from my org
                                      </h6>
                                      <div
                                        class="row sponserWrapper"
                                        style={{ fontSize: "20px", fontWeight: "500" }}
                                      >
                                        {
                                          campSubmissionlist?.[0]
                                            ?.sameorgapproved
                                        }
                                      </div>
                                    </div>



                                    <div class="reward-wrap-marketplace" style={{ display: "inline-flex" }}>
                                      <h6 class="sponsor-title">
                                        Rejections from my org
                                      </h6>
                                      <div
                                        class="row sponserWrapper"
                                        style={{ fontSize: "20px", fontWeight: "500" }}
                                      >
                                        {
                                          campSubmissionlist?.[0]
                                            ?.sameorgrejected
                                        }
                                      </div>
                                    </div>



                                    <div class="reward-wrap-marketplace" style={{ display: "inline-flex" }}>
                                      <h6 class="sponsor-title">
                                        Unregistered count from my org
                                      </h6>
                                      <div
                                        class="row sponserWrapper"
                                        style={{ fontSize: "20px", fontWeight: "500" }}
                                      >
                                        {
                                          campSubmissionlist?.[0]
                                            ?.notregisteredfromorg
                                        }
                                      </div>
                                    </div>


                                    <div class="reward-wrap-marketplace" style={{ display: "inline-flex" }}>
                                      <h6 class="sponsor-title">
                                        % of Vol from my Org
                                      </h6>
                                      <div
                                        class="row sponserWrapper"
                                        style={{ fontSize: "20px", fontWeight: "500" }}
                                      >
                                        {
                                          campSubmissionlist?.[0]
                                            ?.volpercentfromsameorg
                                        }
                                      </div>
                                    </div>



                                    <div class="reward-wrap-marketplace" style={{ display: "inline-flex" }}>
                                      <h6 class="sponsor-title">
                                        % of Vol from other Orgs
                                      </h6>
                                      <div
                                        class="row sponserWrapper"
                                        style={{ fontSize: "20px", fontWeight: "500" }}
                                      >
                                        {
                                          campSubmissionlist?.[0]
                                            ?.volpercentfromacrossorg
                                        }
                                      </div>
                                    </div>



                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </header>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
}
export default CampOReport;
