// SingleSelectQuestion.js
import React, { useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  MenuItem,
  Select,
  FormControl,
  Chip,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


const SingleSelectQuestion = ({
  index,
  choices,
  onChoiceChange,
  question = { text: '', choices: [] },
  addChoice,
  removeChoice,
  removeQuestion,
  updateQuestionText,
  label,
}) => {
  const [newChoice, setNewChoice] = useState('');
  const [questionState, setQuestionState] = useState(question);

  // console.log("addChoice = ", addChoice)
  const handleEditChoice = (choiceIndex, editedChoice) => {
    
    const updatedChoices = [...questionState.choices];
    updatedChoices[choiceIndex] = editedChoice;
    setQuestionState({ ...questionState, choices: updatedChoices });
  };

  const handleDeleteChoice = (choiceIndex, lastIndex) => {
    setQuestionState((prevQuestionState) => {
      var updatedChoices = [...prevQuestionState.choices];
      console.log(question.choices[question.choices.length - 1].choices);

      if (question.choices.length !== prevQuestionState.choices.length) {
        updatedChoices = question.choices[question.choices.length - 1].choices;
      }


      // Remove the entire item at choiceIndex
      updatedChoices.splice(choiceIndex, 1);
      console.log("updatedChoices first = ", updatedChoices)

      if (lastIndex < updatedChoices.length && lastIndex >= 0) {
        const lastItem = { ...updatedChoices[lastIndex] };

        if (lastItem.choices && Array.isArray(lastItem.choices)) {
          lastItem.choices = lastItem.choices.filter((_, index) => index !== choiceIndex);
        }

        updatedChoices[lastIndex] = lastItem;
      }
      console.log("updatedChoices 2nd = ", updatedChoices)
      // Call removeChoice with the modified choices
      removeChoice(lastIndex, updatedChoices);

      console.log("removeChoice = ", removeChoice)

      console.log("updatedChoices final = ", updatedChoices)
      // Update the state with the modified choices
      return { ...prevQuestionState, choices: updatedChoices };
    });
  };

  const handleAddChoice = () => {
    // console.log("newChoice to be added = ", newChoice);
    if (newChoice.trim() !== '') {
      setQuestionState((prevQuestion) => {
        const updatedChoices = [...prevQuestion.choices, newChoice];
        // Call addChoice with the latest state
        addChoice({ ...prevQuestion, choices: updatedChoices });
        return { ...prevQuestion, choices: updatedChoices };
      });
      setNewChoice('');
    }
  };
  
  const handleTextChange = (event) => {
    const newText = event.target.value;
    updateQuestionText(index, newText);
    setQuestionState({ ...questionState, text: newText });
  };

  const handleRemoveQuestion = () => {
    removeQuestion(index);
  };

  return (
    <Grid container spacing={2} alignItems="center" style={{
      border: '3px solid #ddd',
      borderRadius: '8px',
      padding: '10px',
      marginBottom: '10px',
      marginTop: '10px',
      borderColor: "green",
      width: "100%",
      marginLeft: "0px",
    }}>
      <Grid item xs={10} style={{textAlign: "center"}}>
        <TextField
        key={`${index}-${label}`}
          // label="Label for Single Select Question"
          label={label}
          fullWidth
          // value={questionState.text}
          value = {question.text}
          onChange={handleTextChange}
          style={{pointerEvents:"auto"}}
        />
        {/* {questionState.choices.map((choice, choiceIndex) => (
          <div key={choiceIndex} style={{ display: "flex", width:"200px !important" }}>
            <Chip
              label={
                <input value={choice} style={{backgroundColor:'#009688', border:"none"}}/>
                
              }
              onDelete={() => handleDeleteChoice(choiceIndex)}
              color="primary"
              style={{
                
                margin: '0.5rem',
                backgroundColor: '#009688', // Background color
                color: '#fff', // Text color
                fontWeight: 'bold', // Bold text
                borderRadius: '8px', // Rounded corners
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Box shadow
                
              }}
            />
          </div>
        ))} */}

{choices.length > 0 && (
          <div style={{ display: "flex", flexFlow:"wrap"}}>
            {choices[choices.length - 1].choices.map((choice, choiceIndex) => (
              <Chip
                key={choiceIndex}
                label={
                  <input
                    value={choice}
                    style={{ backgroundColor: '#009688', border: "none", wordBreak:"break-all",pointerEvents:"auto" }}
                  />
                }
                // onDelete={() => handleDeleteChoice(choices.length - 1, choiceIndex)}
                onDelete={() => handleDeleteChoice(choiceIndex, choices.length - 1)}
                color="primary"
                style={{
                  margin: '0.5rem',
                  backgroundColor: '#009688',
                  color: '#fff',
                  fontWeight: 'bold',
                  borderRadius: '8px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                  pointerEvents:"auto"
                }}
              />
            ))}
          </div>
        )}
        
        <TextField
          label="Add Choice"
          value={newChoice}
          onChange={(e) => setNewChoice(e.target.value)}
          style={{marginTop:"8px",pointerEvents:"auto"}}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddChoice}
          style={{marginTop:"17px", marginLeft:"6px",pointerEvents:"auto"}}
        >
          Add Choice
        </Button>
      </Grid>
      <Grid item xs={1} style={{ paddingLeft: "0px", paddingTop: "0px" }}>
        <Button
          // onClick={removeQuestion}
          onClick={handleRemoveQuestion}
          startIcon={<DeleteForeverIcon style={{ fontSize: 30, color: "gray",pointerEvents:"auto" }} />}
        />
      </Grid>
    </Grid>
  );
};

export default SingleSelectQuestion;