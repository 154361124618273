import { Typography } from "@mui/material";
import React, { useEffect, useState, useRef }from "react";
// import QuoteIcon from "../assets/quote.png";

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Button } from "@mui/material";
import { CopyRight } from "./CopyRight";
import LoginLogo from "../assets/sdp.jpeg";
import "./Join/Join.css";
import ConfirmationDialog from "./ConfirmationDialog";
import { userProfile } from "../redux/action/UserProfileActions";
import styled from 'styled-components';

const LoginPgLogo = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (max-width: 768px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
}));


const EmailVerificationPG = (props) => {
  const [otp, setOtp] = React.useState("");
  const [validation, setValidation] = React.useState("");
  const userid = localStorage.getItem("userid");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationPath = useLocation();
  const { selectedIndex, formData } = locationPath.state || {};

  const topRef = useRef(null);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    if (userid) {
      dispatch(userProfile(userid));
    }
    localStorage.setItem("profileurl", profileResponse?.profileurl)
  }, []);

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state?.profile
  );
  const {
    isSuccess: emailloginSuccess,
    isFetching: emailloginFetching,
    isError: emailloginError1,
    response: emaillogin,
  } = useSelector((state) => state?.socialEmailLogin);

 
  const setOtpVal = (e) => {
    setOtp(e.target.value);
  };

  const sendEmailOTP = async () => {
    var phoneno = emaillogin.phoneno;
    if (phoneno === undefined) {
      phoneno = locationPath?.pathname.split("/")?.[2];
    }

    const res = await axios.post(`/verifyemail`, {
      phoneno: phoneno,
      email: locationPath?.pathname.split("/")?.[3],
    });
    setDisplayConfirmationModal(true);
  };
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    React.useState(false);
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  

  const handleVerify = async () => {
    try {
      const res = await axios.post(
        `/a/b/c/d/e/f/g/h/i/j/k/l/m/n/${locationPath?.pathname.split("/")?.[2]}/${
          locationPath?.pathname.split("/")?.[3]
        }`,
        {
          token: otp,
        }
      );

      setValidation(res?.data?.message);

      if (res?.data?.message === "VALIDATED" && localStorage.getItem("referedcampid") !== null && 
          localStorage.getItem("referedcampid") !== ""  && localStorage.getItem("referedcampid") !== "null" &&
          localStorage.getItem("referedcampid") !== undefined) {
        navigate(`/participate/campaign/${localStorage.getItem("referedcampid")}/${localStorage.getItem("userid")}}}`)
      } else if (res?.data?.message === "VALIDATED" && (localStorage.getItem("referedcampid") === null || 
                localStorage.getItem("referedcampid") === ""  || localStorage.getItem("referedcampid") === "null" ||
                localStorage.getItem("referedcampid") === undefined)) {
        navigate(`/pg/verify`, { state: { selectedIndex, formData } });
      }
      
      return res;
      // }
    } catch (err) {
      // setLoading(false);

      throw err;
    }

    
  };
  return (
    <div className="wrapper" ref={topRef}>
      {/* <ResponsiveDrawer {...props} /> */}
      <section className="containers emailOtp" style={{ overflow: "hidden" }}>
        {/* <TopHeaderScreen {...props} /> */}
        <div class="content content-wrap">
          {/* <div class="content-mrg"> */}
          <LoginPgLogo style={{ textAlign: "center" }}>
            {/* <img src={Login} style={{ width: "1%", alignItems: "center", height: "10px" }} /> */}

            <img
              src={LoginLogo}
              style={{
                height: 56,
                // width: 100,
              }}
              
            />
            <figcaption style={{marginLeft:"5px"}}>Powered by cYAAG</figcaption>
          </LoginPgLogo>
          <span
            style={{
              fontSize: 16,
              fontWeight: "600",
              // marginBottom: 15,
              marginTop: 120,
              display: "block",
              textAlign: "center",
            }}
          >
            Please enter the code as received in your email
          </span>
          <div className="joinOuterContainerEmail">
            <div className="emailOTP">
              {/* <h1 className="heading">Join</h1> */}
              <div >
                <input
                  defaultValue={otp}
                  type="number"
                  size="6"
                  maxLength="6"
                  id="create-yaad--title"
                  className="joinInput mt-20"
                  style={{
                    textAlign: "center",
                    // fontSize: "18px",
                    fontWeight: "500",
                  }}
                  // placeholder="Code"
                  onChange={(e) => setOtpVal(e)}
                  // onChange={setOtp(otp)}

                  // onBlur={() => {
                  //   if (!itemreward.points) {
                  //     setCampaignValuesError({
                  //       ...campaignValuesError,
                  //       pointsError: true,
                  //     });
                  //   } else {
                  //     if (!Number(itemreward.points)) {
                  //       console.log("Error")

                  //       setCampaignValuesError({
                  //         ...campaignValuesError,
                  //         pointsError: true,
                  //       });
                  //     } else {
                  //       setCampaignValuesError({
                  //       ...campaignValuesError,
                  //       pointsError: false,
                  //     });
                  //   }
                  //   }
                  // }}
                />
                {/* <Button
                variant="contained"
                onClick={handleVerify}
                className="btn verify-btn"
                disableElevation
              >
                <span>Submit</span>
              </Button> */}
                {validation && validation === "VALIDATED" ? (
                  // <button
                  //   // className={"button mt-20"}
                  //   className={"button"}
                  //   type="submit"
                  //   style={{ fontSize: "14px", backgroundColor: "gray" }}
                  //   // onClick={() => handleVerify()}
                  //   disabled
                  // >
                  //   Submit
                  // </button>

                  <Button
                  variant="contained"
                  color="secondary"
                  disabled
                  style={{marginTop:"10px", borderRadius: 30,
                    fontSize: 14,
                    textTransform: "uppercase",
                    padding: 8,
                    width: "60%",
                    background: "#e1a23b",
                    boxShadow: "none",
                    fontWeight: "bold",
                    marginTop: "40px",}}
                  // onClick={() => handleVerify()}
                  >
                  Verify
                  </Button>
                ) : (
                    <Button
                    variant="contained"
                    color="secondary"
                    
                    style={{marginTop:"10px", borderRadius: 30,
                      fontSize: 14,
                      textTransform: "uppercase",
                      padding: 8,
                      width: "60%",
                      background: "#e1a23b",
                      boxShadow: "none",
                      fontWeight: "bold",
                      marginTop: "40px",}}
                    onClick={() => handleVerify()}
                    >
                    Verify
                    </Button>

                )}

                <div
                  style={{
                    fontSize: "18px",
                    marginTop: "15px",
                    // display: "flex",
                  }}
                >
                  <Button style={{ cursor: "default", color: "#c6c6c9"}}>
                    Didn't Receive OTP?{" "}
                  </Button>
                  <Button
                    style={{ color: "#e1a23b", paddingLeft: "3px" }}
                    onClick={sendEmailOTP}
                  >
                    Click Here
                  </Button>
                </div>

                {validation && validation === "VALIDATED" ? (
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: "600",
                      // marginBottom: 15,
                      // marginTop: 5,
                      display: "block",
                      textAlign: "center",
                    }}
                  >
                    Your email has been successfully verified. Please
                    <a href="https://app.cyaag.com"> Click Here </a> and Sign in
                    with your phone number
                  </span>
                ) : validation === "NOT VALIDATED" ? (
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: "600",
                      // marginBottom: 15,
                      // marginTop: 5,
                      display: "block",
                      textAlign: "center",
                    }}
                  >
                    Sorry, Your email verification has failed. Please ensure
                    that you are entering the code correctly or that you are not
                    submitting the form with errors.
                  </span>
                ) : validation === "EXCEPTION" ? (
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: "600",
                      // marginBottom: 15,
                      // marginTop: 5,
                      display: "block",
                      textAlign: "center",
                    }}
                  >
                    Sorry, Your email verification has failed. Your organization
                    Administrator will get back to you on this. For any further
                    queries, please write to support@cyaag.com
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <CopyRight />
        <ConfirmationDialog
          showModal={displayConfirmationModal}
          // confirmModal={handleRedeem}
          hideModal={hideConfirmationModal}
          // message={"You do not have enough points to redeem"}
          message={"OTP has been resent. Please check your registered email"}
        />
      </section>
    </div>
  );
};

export default EmailVerificationPG;
