import { useEffect } from 'react';

const useResizeObserver = (callback, elementRef) => {
  useEffect(() => {
    if (!elementRef.current) return;

    const handleResize = () => {
      callback();
    };

    const observer = new ResizeObserver(() => {
      handleResize();
    });

    observer.observe(elementRef.current);

    return () => {
      observer.disconnect();
    };
  }, [callback, elementRef]);
};

export default useResizeObserver;
