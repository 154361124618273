import React from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import ResponsiveDrawer from "./Header";
import { CopyRight } from "./CopyRight";
import axios from "axios";
import _ from "lodash";
import TopHeaderScreen from "./TopHeader1";
import { useNavigate } from "react-router-dom";
import { DataAlertReportTableComponent } from "./Table";
import { FixedFooterIcon } from "../components/FixedFooterIcon";
import styled from 'styled-components';

const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

function DataAlertReport(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [campName, setCampName] = React.useState([]);
  const [dataAlertList, setDataAlertList] = React.useState([]);

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  React.useEffect(() => {
    getCampName();
    getRewardsFromMarketplaceOnSearch();
  }, []);


  const getCampName = async () => {
    try {
      const res = await axios.get(`/camp/name/${profileResponse?.phoneno}`);
      setCampName(res?.data);

      return res;
    } catch (err) {
      throw err;
    }
  };

  const getRewardsFromMarketplaceOnSearch = async () => {
    
    // console.log(selectedpointsrange.length === 0);
    try {
      // const res = ""
      //   if ((selectedrewardtype.length === 0) && !(selectedpointsrange.length === 0)) {
      
      const res = await axios
        .get(
          `/data/alert/report/${profileResponse?.phoneno}`
        )
        .then((response) => {
          setDataAlertList(response?.data);
          return response;
        });
      //   }
    } catch (err) {
      throw err;
    }
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Data Alerting Report
            </div>
            {/* <div>
              <Button className="btn download-btn" variant="contained" onClick={downloadDocument}>Download</Button>
            </div> */}
            {/* <div style={{ fontFamily: "monospace", fontSize: 16, }}>
            </div> */}

            {/* <div class="formMarketPlace">
              <div>
                <div
                  class="form-market-wrap"
                  id=""
                  role="tabpanel"
                  aria-labelledby="nav-places-tab"
                >
                  <h6>Please select a Campaign</h6>
                  <form action="#" method="get">
                    <div
                      style={{
                        // padding: "0px 30px 0px 0px",
                        padding: "0px 0px 0px 0px",
                        width: "300px",
                        marginBottom: "5px",
                        maxWidth: "100%",
                      }}
                    >
                      <AsyncSelect
                        loadOptions={loadOptions}
                        defaultOptions={campName}
                        value={selectedcampname}
                        isMulti
                        placeholder="Select Campaign"
                        isOptionDisabled={() => selectedcampname.length >= 1}
                        styles={colorStyles}
                        onChange={(event) => {
                          setselectedcampname(event);
                          
                        }}
                      />
                    </div>

                    <Button
                      class="btn camp-report-gen"
                      variant="contained"
                      onClick={getRewardsFromMarketplaceOnSearch}
                      className="btn save-btn"
                      disableElevation
                      style={{marginLeft:"30px"}}
                    >
                      <i class="fa fa-search pr-2" aria-hidden="true"></i>
                      <span>Generate Report</span>
                    </Button>
                    <Button
                      class="btn camp-report"
                      variant="contained"
                      className="btn save-btn"
                      style={{marginLeft:"30px"}}
                    >
                      <i
                        class="fa fa-arrow-circle-down pr-2"
                        aria-hidden="true"
                        style={{ color: "black" }}
                      ></i>
                      <CSVLink data={dataAlertList}>
                        Download Report
                      </CSVLink>
                    </Button>
                  </form>
                </div>
              </div>
            </div> */}

            <header id="SOADashboardScreen">
              <form autoComplete="off">
                <div
                  style={{
                    border: "1px solid #dadce0",
                    borderRadius: 8,
                    backgroundColor: "#fff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  ></div>
                  <div
                    className="centerAlign"
                    style={{ padding: "16px 0", marginBottom: 50 }}
                  >
                    <br />
                    <FormGroupFull>
                      <div>
                        <DataAlertReportTableComponent
                          rows={dataAlertList}
                        />
                      </div>
                    </FormGroupFull>
                  </div>
                </div>
              </form>
            </header>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
}
export default DataAlertReport;
