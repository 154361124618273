// FreeFormTextBox.js
import React from 'react';
import { TextField, Button, Grid } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const UploadFiles = ({ index, removeQuestion, updateQuestionText, label, question }) => {
  const handleTextChange = (event) => {
    const newText = event.target.value;
    updateQuestionText(index, newText);
    
  };

  return (
    <Grid container spacing={2} alignItems="center" 
    style={{
      border: '3px solid #ddd',
      borderRadius: '8px',
      padding: '10px',
      marginBottom: '10px',
      marginTop: '10px',
    }}
    >
      <Grid item xs={10}>
        <TextField
          key={`${index}-${label}`}
          // label="Label for Upload Files"
          label={label}
          fullWidth
          value={question.text}
          onChange={handleTextChange}
          style={{pointerEvents:"auto"}}
        />
      </Grid>
     
      <Grid item xs={1} style={{paddingLeft:"0px"}}>
        <Button
          // variant="contained"
          // color="secondary"
          onClick={removeQuestion}
          startIcon={<DeleteForeverIcon style={{ fontSize: 30, color:"gray",pointerEvents:"auto" }} />}
        >
          {/* Delete */}
        </Button>
      </Grid>
    </Grid>
  );
};

export default UploadFiles;
