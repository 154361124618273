import {
  Chip,
  Avatar,
  useMediaQuery,
  Typography,
  Box,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "../../components/Header";
import TopHeaderScreen from "../../components/TopHeader1";
import { CopyRight } from "../../components/CopyRight";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import {
  InputLabel,
  Button,
} from "@mui/material";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import debounce from "lodash/debounce";
import CampaignCard from "../../components/CampaignCard";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

const SearchPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  const [query, setQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState(""); // State to hold selected option
  const options = [{ value: "Changemaker", label: "Changemaker" }, { value: "Campaign", label: "Campaign" }]; // Dropdown options
  const [changemakerResults, setChangemakerResults] = useState([]);
  const [campaignResults, setCampaignResults] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [changemakerList, setChangemakerList] = useState([]);
  const [SDGCampMapping, setSDGCampMapping] = React.useState([]);

  const fetchChangemakerData = async (searchQuery) => {
    try {
      const response = await axios.get(`search/vol/test/${searchQuery}`);
      setChangemakerResults(response.data); // Update changemaker results
    } catch (error) {
      console.error("Error fetching Changemaker data:", error);
      setChangemakerResults([]); // Clear changemaker results on error
    }
  };

  const fetchCampaignData = async (searchQuery) => {
    try {
      const response = await axios.get(`/search/camp/test/${searchQuery}/${profileResponse?.organization}`);
      setCampaignResults(response.data); // Update campaign results
    } catch (error) {
      console.error("Error fetching Campaign data:", error);
      setCampaignResults([]); // Clear campaign results on error
    }
  };

  const debouncedFetchData = debounce(async (input) => {
    if (input.length === 0) {
      setChangemakerResults([]);
      setCampaignResults([]);
    } else {
      if (selectedOption.value === "Changemaker") {
        await fetchChangemakerData(input);
      } else if (selectedOption.value === "Campaign") {
        await fetchCampaignData(input);
      }
    }
  }, 300);

  const handleInputChange = (event) => {
    const userInput = event.target.value;
    setQuery(userInput);
    debouncedFetchData(userInput);
  };

  const handleOptionChange = (selectedValue) => {
    console.log("selectedValue=======", selectedValue);
    setSelectedOption(selectedValue);
    setQuery("");
    setChangemakerResults([]);
    setCampaignResults([]); // Clear results when changing options
  };

  const handleCampaignSearchResult = async (selectedItemId) => {
    console.log("Selected item ID:", selectedItemId);

    setCampaignResults([]);
    try {
      const res = await axios
        .get(
          `search/camp/${selectedItemId}/${profileResponse?.orgrole}/${profileResponse?.organization}/${profileResponse?.phoneno}`
        )
        .then((response) => {
          // setSearchResult(response.data);
          // setVolunteerListName([]);
          // setCampaignList(response.data);
          // console.log(response.data)

          if (response) {
            videoRef.current?.load();
            setCampaignList(response.data);
          }
          videoSourceRef.current.src = response.data[0].video;
          setCampaignList(response.data);
        });

      // Replace the above console.log with your endpoint call using axios
    } catch (error) {
      console.error("Error fetching Campaign data:", error);
      // setCampaignResults([]); // Clear campaign results on error
    }
  };

  const handleChangemakerSearchResult = async (selectedItemId) => {
    console.log("Selected item ID:", selectedItemId);
    setChangemakerResults([]);

    try {
      const res = await axios
        // .get(`search/vol/${selectedItemId}/${profileResponse?.phoneno}`)
        .get(`search/vol/${selectedItemId}`)
        .then((response) => {
          // setSearchResult(response.data);
          // setVolunteerListName([]);
          setChangemakerList(response.data);
          console.log(response.data);
        });

      // Replace the above console.log with your endpoint call using axios
    } catch (error) {
      console.error("Error fetching Campaign data:", error);
      // setCampaignResults([]); // Clear campaign results on error
    }
  };

  useEffect(() => {
    // currentUserData();
    getSDGCampMapping();
  }, [campaignList]);

  const getSDGCampMapping = async () => {
    try {
      const res = await axios.get(`camp/sdg/mapping`);
      // setActivityList(res?.data);
      setSDGCampMapping(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const videoRef = useRef(null);
  const videoSourceRef = useRef(null);
  const optionsTwo = {
    items: 2,
    margin: 0,
    loop: false,
    nav: true,
    autoplaySpeed: 900,
    autoplay: false,
    dots: false,
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const handleImageLoad = async (img) => {
    const im = await Promise.all(img);
    console.log(im);
    if (im) {
      return true;
    } else {
      return false;
    }

    // Additional actions to perform after the image is loaded
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div class="content content-pad-0">
              <h2>Search Page</h2>
              <div
                class="searchWrapper"
                style={{
                  float: "left",
                  padding: "20px",
                  width: "100%",
                  border: "1px solid rgb(235, 149, 50)",
                }}
              >
                <InputLabel id="demo-simple-select-label">Search by</InputLabel>

                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="searchContentWrapper"
                >
                  {/* Dropdown menu */}
                  {/* <select
                    value={selectedOption}
                    onChange={(e) => handleOptionChange(e.target.value)}
                    style={{
                      padding: "14px",
                      marginRight: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                      backgroundColor: "#fff",
                      flex: "1", // Expand to take remaining space
                      maxWidth: "180px", // Limit maximum width
                      width: "auto", // Let content determine width
                      fontSize: "18px", // Increase font size
                    }}
                  >
                    <option value="">Select</option>
                    {options.map((option, index) => (
                      <option
                        key={index}
                        value={option}
                        style={{ fontSize: "18px", marginTop: "10px" }}
                      >
                        {option}
                      </option>
                    ))}
                  </select> */}
                  <Select
                    value={selectedOption}
                    onChange={handleOptionChange}
                    className="selectedContainer"
                    options={options}
                    isSearchable={false} // Enable search functionality
                    placeholder="Select..."
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderRadius: 3,
                        borderColor: '#ccc',
                        boxShadow: 'none',
                        padding: '8px',
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? '#007bff' : 'white',
                        color: state.isSelected ? 'white' : 'black',
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: '#333',
                      }),
                    }}

                  />

                  {/* Search input */}
                  <Box
    style={{
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #ccc',
      borderRadius: '4px',
      padding: '4px',  // Adjust padding as needed
      backgroundColor: '#fff',
      maxWidth: '400px',
      width: '100%',
    }}
  >
                  <input
                    type="text"
                    placeholder={`Search ${selectedOption.value !== undefined ? selectedOption.value : '...'}`}
                    value={query}
                    onChange={handleInputChange}
                    style={{
                      padding: "9px",
                      // borderRadius: "4px",
                      // border: "1px solid #ccc",
                      border: 'none',
                      backgroundColor: "#fff",
                      flex: "2", 
                      maxWidth: "400px", 
                      width: "100%", 
                      fontSize: "16px",
                    }}
                  />
                  <InputAdornment position="end" >
      <SearchIcon style={{ color: 'gray' }} />
    </InputAdornment>
                  </Box>
                </div>

                
                <ul
                  style={{
                    listStyle: "none",
                    padding: "0",
                    margin: "0",
                  }}
                >
                  {selectedOption.value === "Changemaker" &&
                    changemakerResults.map((result, index) => (

                      <li
                        key={result.id}
                        onClick={() => {
                          console.log("result = ", result)
                          // handleChangemakerSearchResult(result.firstname)
                          handleChangemakerSearchResult(result.uniqueuserid)
                        }
                        }
                        style={{
                          fontSize: "16px",
                          paddingLeft: "10px",
                          cursor: "pointer",
                          backgroundColor: index % 2 === 0 ? "white" : "#f9f9f9",
                          marginBottom: "5px",
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "#f0f0f0";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = index % 2 === 0 ? "white" : "#f9f9f9";
                        }}
                      >
                        {/* Display Changemaker result information */}
                        {result.firstname} {result.lastname}
                      </li>
                    ))}
                  {selectedOption.value === "Campaign" &&
                    campaignResults.map((result, index) => (
                      <li
                        key={result.campid}
                        onClick={() => handleCampaignSearchResult(result.title)}
                        style={{
                          fontSize: "16px",
                          paddingLeft: "10px",
                          cursor: "pointer",
                          backgroundColor: index % 2 === 0 ? "white" : "#f9f9f9",
                          marginBottom: "5px",
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "#f0f0f0";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = index % 2 === 0 ? "white" : "#f9f9f9";
                        }}
                      >
                        {/* Display Campaign result information */}
                        {result.title}
                      </li>
                    ))}
                </ul>



                <div style={{ float: "left", width: "100%" }}>
                  {selectedOption.value === "Campaign" && (
                    <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                      <OwlCarousel className="owl-theme" {...optionsTwo}>
                        {campaignList.map((tile, index) => (

                          <div>
                            <div>
                            
<Typography style={{ textAlign: "center" }}>
                                            <Chip
                                              key={tile.active}
                                              fontSize="small"
                                              size="small"
                                              style={{
                                                backgroundColor:
                                                  tile.active === "Completed"
                                                    ? "#6C757D"
                                                    : tile.active === "Paused"
                                                    ? "#EF4E4E"
                                                    : tile.active === "Upcoming"
                                                    ? "#FADB5F"
                                                    : tile.active === "Active"
                                                    ? "#0D731E"
                                                    : "#000000", // Default color, if none of the conditions match
                                                color: 
                                                tile.active === "Upcoming"
                                                    ? "black"
                                                    : "white",
                                                marginBottom: "5px",
                                              }}
                                              label={tile.active}
                                            />
                                          </Typography>
                            </div>

                            <CampaignCard
                              organizationlogo={tile?.organizationlogo}
                              name={tile?.name}
                              logo={tile?.logo}
                              video={tile?.video}
                              sponsor={tile?.sponsor}
                              tag={tile?.tag}
                              SDGCampMapping={SDGCampMapping}
                              goalquantity={tile?.goalquantity}
                              unitofgoal={tile?.unitofgoal}
                              committedcount={tile?.committedcount}
                              achievedcount={tile?.achievedcount}
                              submittedcount={tile?.submittedcount}
                              campaigntype={tile?.campaigntype}
                              campid={tile?.campid}
                              maxpointstobeearned={tile?.maxpointstobeearned}
                              dayrem={tile?.dayrem}
                              hourrem={tile?.hourrem}
                              minrem={tile?.minrem}
                              profileResponse={profileResponse}
                              index={index}
                              goal={tile?.goal}
                              desc={tile?.desc}
                              whattodo={tile?.whattodo}
                              guidelines={tile?.guidelines}
                              landing = "no"
                              status={tile?.active}
                              directlink="no"
                              showcamplb="yes"
                              participatepage="no"
                            />
                          </div>
                        ))}
                      </OwlCarousel>
                    </div>
                  )}

                  {/* Changemaker list drop down */}

                  {selectedOption.value === "Changemaker" && (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-around",
                      }}
                    >
                      {changemakerList.map((tile) => (
                        <Button
                          key={tile.id}
                          onClick={() => navigate(`/newProfile/${tile?.profileurl}`)}
                          style={{
                            flex: "0 0 calc(50% - 20px)",
                            maxWidth: "calc(50% - 20px)",
                            margin: "10px",
                            padding: "10px",
                            boxSizing: "border-box",
                          }}
                        >
                          <div
                            class="volListWrapper"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ marginBottom: "10px", width: "100%" }}>
                              <div class="per-img">
                                {tile.profilephoto ? (
                                  <img
                                    class="one1"
                                    style={{
                                      width: "122px",
                                      borderRadius: "50%",
                                      height: "122px",
                                      verticalAlign: "super",
                                      maxWidth: "100%",
                                      margin: "0 auto",
                                      borderRadius: "0"
                                    }}
                                    src={tile.profilephoto}
                                    alt={`${tile.firstname} ${tile.lastname}`}
                                  />
                                ) : (
                                  <Avatar
                                    class="one1"
                                    style={{
                                      width: "122px",
                                      borderRadius: "50%",
                                      height: "122px",
                                      verticalAlign: "super",
                                      maxWidth: "100%",
                                      margin: "0 auto",
                                      borderRadius: "0"
                                    }}
                                    src="/broken-image.jpg"
                                    alt={`${tile.firstname} ${tile.lastname}`}
                                  />

                                )}
                              </div>
                            </div>
                            <div class="rightAlignItem" style={{ textAlign: "center" }}>
                              <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                                {tile.firstname} {tile.lastname}
                              </span>
                              <br />
                              <span>{tile.orgrole}</span>
                              <br />
                              <span style={{textTransform: "none"}}>{tile.organization}</span>
                              <br />
                            </div>
                          </div>
                        </Button>
                      ))}
                    </div>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
        <CopyRight style={{ paddingBottom: "0px" }} />
        <FixedFooterIcon />
      </section>
    </div>
  );
};

export default SearchPage;
