import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "../../components/Header";
import { TableComponent } from "../../components/Table";
import { getVolunteerName } from "../../redux/action/getVolunteerName";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link, useNavigate } from "react-router-dom";
import { CopyRight } from "../../components/CopyRight";
import {  Button } from "@mui/material";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";

const VolunteerManageScreen = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { response: volunteerNameResponse, isFetching: volunteerNameFetching } =
    useSelector((state) => state.volunteerName);

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  React.useEffect(() => {
    if (profileResponse) {
      dispatch(
        getVolunteerName({
          organization: profileResponse?.organization,
          userid: localStorage.getItem("userid"),
        })
      );
    }
  }, []);

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
      <TopHeaderScreen {...props} />
      <div class="content content-wrap">
          <div class="content-mrg">
          <Button
                onClick={() => navigate(-1)}
                className="backWrapper"
            >
                <i className={`fa fa-caret-left`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
          <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Manage Users</div>
        
        <TableComponent rows={volunteerNameResponse} />
      </div>
      </div>
      <CopyRight />
      <FixedFooterIcon />
      </section>
    </div>
  );
};

export default VolunteerManageScreen;
