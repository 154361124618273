/** @format */

import { call, put } from "redux-saga/effects";
import { get } from "lodash";

import {
  ONBOARD_INDIVIDUAL_FETCHING_DATA,
  ONBOARD_INDIVIDUAL_FETCHING_DATA_SUCCESS,
  ONBOARD_INDIVIDUAL_FETCHING_DATA_FAILURE,
  ONBOARD_BULK_FETCHING_DATA,
  ONBOARD_BULK_FETCHING_DATA_SUCCESS,
  ONBOARD_BULK_FETCHING_DATA_FAILURE,
  ONBOARD_ACTIVITY_FETCHING_DATA,
  ONBOARD_ACTIVITY_FETCHING_DATA_SUCCESS,
  ONBOARD_ACTIVITY_FETCHING_DATA_FAILURE,
  ONBOARD_ORGANIZATION_FETCHING_DATA,
  ONBOARD_ORGANIZATION_FETCHING_DATA_SUCCESS,
  ONBOARD_ORGANIZATION_FETCHING_DATA_FAILURE,
  ONBOARD_CAMPAIGN_FETCHING_DATA,
  ONBOARD_CAMPAIGN_FETCHING_DATA_SUCCESS,
  ONBOARD_CAMPAIGN_FETCHING_DATA_FAILURE,
  ONBOARD_SPONSOR_FETCHING_DATA,
  ONBOARD_SPONSOR_FETCHING_DATA_FAILURE,
  ONBOARD_SPONSOR_FETCHING_DATA_SUCCESS,
  ONBOARD_SELF_FETCHING_DATA,
  ONBOARD_SELF_FETCHING_DATA_FAILURE,
  ONBOARD_SELF_FETCHING_DATA_SUCCESS,

  ONBOARD_REGFORM_FETCHING_DATA,
  ONBOARD_REGFORM_FETCHING_DATA_FAILURE,
  ONBOARD_REGFORM_FETCHING_DATA_SUCCESS,

} from "../action/OnboardAction";
import { Api } from "../../services";

const myAPI = new Api();

export function* onboardIndividual(action) {
  yield put({
    type: ONBOARD_INDIVIDUAL_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.onboardIndividual, action.payload);
    yield put({
      type: ONBOARD_INDIVIDUAL_FETCHING_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    yield put({ type: ONBOARD_INDIVIDUAL_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}

export function* onboardBulk(action) {
  yield put({
    type: ONBOARD_BULK_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.onboardBulk, action.payload);
    const payload = get(response, "data");
    yield put({
      type: ONBOARD_BULK_FETCHING_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    yield put({ 
      type: ONBOARD_BULK_FETCHING_DATA_FAILURE,
      payload: e,
    });
    // alert("Validation Failed");
  }
}

export function* onboardActivity(action) {
  yield put({
    type: ONBOARD_ACTIVITY_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.onboardActivity, action.payload);
    const payload = get(response, "data");
    yield put({
      type: ONBOARD_ACTIVITY_FETCHING_DATA_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({ type: ONBOARD_ACTIVITY_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}

export function* onboardOrganization(action) {
  yield put({
    type: ONBOARD_ORGANIZATION_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.onboardOrganization, action.payload);
    const payload = get(response, "data");
    yield put({
      type: ONBOARD_ORGANIZATION_FETCHING_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    yield put({ type: ONBOARD_ORGANIZATION_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}

export function* onboardCampaign(action) {
  yield put({
    type: ONBOARD_CAMPAIGN_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.onboardCampaign, action.payload);
    const payload = get(response, "data");
    yield put({
      type: ONBOARD_CAMPAIGN_FETCHING_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    yield put({ type: ONBOARD_CAMPAIGN_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}

export function* onboardSponsor(action) {
  yield put({
    type: ONBOARD_SPONSOR_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.onboardSponsor, action.payload);
    const payload = get(response, "data");
    yield put({
      type: ONBOARD_SPONSOR_FETCHING_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    yield put({ type: ONBOARD_SPONSOR_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}


export function* onboardSelf(action) {
  yield put({
    type: ONBOARD_SELF_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.onboardSelf, action.payload);
    const payload = get(response, "data");
    yield put({
      type: ONBOARD_SELF_FETCHING_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    yield put({ type: ONBOARD_SELF_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}

export function* onboardRegForm(action) {
  yield put({
    type: ONBOARD_REGFORM_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.onboardRegForm, action.payload);
    const payload = get(response, "data");
    yield put({
      type: ONBOARD_REGFORM_FETCHING_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    yield put({ type: ONBOARD_REGFORM_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}