import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

// const muiTheme = createTheme({
//   // Customize your MUI theme here
// });

root.render(
  // <ChakraProvider>
  //   <ThemeProvider theme={muiTheme}>
      <App />
  //   </ThemeProvider>
  // </ChakraProvider>,
  // <React.StrictMode>
    // <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
