import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

export const CopyRight = (props) => {
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  return (
    <div class="nofooterinmob">
    <footer>
    <ul>
        <li className="privacyPolicy"><Link className="onOrange" to="/privacy-policy">Privacy policy</Link></li>
        <li> ©{new Date().getFullYear()} {""} {"Copyright by cYAAG"}</li>
        <li className="termsCondition"><Link className="onOrange" to="/terms-condition">Terms condition</Link></li>
      </ul>
      {/* <span>
        ©{new Date().getFullYear()} {profileResponse?.copyright}
      </span> */}
    </footer>
    </div>
  );
};
