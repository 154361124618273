import React from 'react';

const SVGProfileDefaultBanner = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 1008 204" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4.5" y="4.5" width="999" height="199" stroke="#F09916" />
    <path d="M4 4H1004V204H4V4Z" fill="#F09916" fillOpacity="0.87" />
    <circle cx="626.5" cy="169.5" r="7.5" fill="url(#paint0_linear_250_2)" fillOpacity="0.7" />
    <g filter="url(#filter0_f_250_2)">
      <ellipse cx="348" cy="43.5" rx="59" ry="30.5" fill="url(#paint1_linear_250_2)" fillOpacity="0.5" />
    </g>
    <ellipse cx="108" cy="104.5" rx="77" ry="47.5" fill="url(#paint2_linear_250_2)" fillOpacity="0.7" />
    <g filter="url(#filter1_f_250_2)">
      <ellipse cx="942.5" cy="73" rx="61.5" ry="69" fill="url(#paint3_linear_250_2)" fillOpacity="0.5" />
    </g>
    <g filter="url(#filter2_f_250_2)">
      <ellipse cx="94.5" cy="54" rx="90.5" ry="50" fill="url(#paint4_linear_250_2)" fillOpacity="0.5" />
    </g>
    <ellipse cx="703.5" cy="131" rx="16.5" ry="15" fill="url(#paint5_linear_250_2)" fillOpacity="0.7" />
    <ellipse cx="880.5" cy="116.5" rx="58.5" ry="55.5" fill="url(#paint6_linear_250_2)" fillOpacity="0.7" />
    <circle cx="584" cy="83" r="10" fill="url(#paint7_linear_250_2)" fillOpacity="0.7" />
    <circle cx="694" cy="53" r="10" fill="url(#paint8_linear_250_2)" fillOpacity="0.7" />
    <ellipse cx="424" cy="114" rx="35" ry="25" fill="url(#paint9_linear_250_2)" fillOpacity="0.7" />
    <defs>
      <filter id="filter0_f_250_2" x="285" y="9" width="126" height="69" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_250_2" />
      </filter>
      <filter id="filter1_f_250_2" x="877" y="0" width="131" height="146" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_250_2" />
      </filter>
      <filter id="filter2_f_250_2" x="0" y="0" width="189" height="108" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_250_2" />
      </filter>
      <linearGradient id="paint0_linear_250_2" x1="626.5" y1="162" x2="626.5" y2="177" gradientUnits="userSpaceOnUse">
        <stop offset="0.29" stopColor="#FFC107" />
        <stop offset="1" stopColor="#FDEFC3" />
      </linearGradient>
      <linearGradient id="paint1_linear_250_2" x1="348" y1="74" x2="348" y2="13" gradientUnits="userSpaceOnUse">
        <stop offset="0.57" stopColor="#FFC107" />
        <stop offset="0.99" stopColor="#F4E7C1" />
      </linearGradient>
      <linearGradient id="paint2_linear_250_2" x1="108" y1="57" x2="108" y2="152" gradientUnits="userSpaceOnUse">
        <stop offset="0.0259998" stopColor="#FFC107" />
        <stop offset="0.866" stopColor="#FED351" />
        <stop offset="1" stopColor="#FDEFC3" />
      </linearGradient>
      <linearGradient id="paint3_linear_250_2" x1="1004" y1="73" x2="881" y2="73" gradientUnits="userSpaceOnUse">
        <stop offset="0.0959998" stopColor="#FFC107" />
        <stop offset="0.566" stopColor="#FFC107" />
        <stop offset="1" stopColor="#F4E7C1" />
      </linearGradient>
      <linearGradient id="paint4_linear_250_2" x1="94.5" y1="104" x2="94.5" y2="4" gradientUnits="userSpaceOnUse">
        <stop offset="0.00999999" stopColor="#F4E7C1" />
        <stop offset="0.451" stopColor="#FFC107" />
        <stop offset="0.731" stopColor="#FFC107" />
      </linearGradient>
      <linearGradient id="paint5_linear_250_2" x1="703.5" y1="116" x2="703.5" y2="146" gradientUnits="userSpaceOnUse">
        <stop offset="0.29" stopColor="#FFC107" />
        <stop offset="1" stopColor="#FDEFC3" />
      </linearGradient>
      <linearGradient id="paint6_linear_250_2" x1="880.5" y1="61" x2="880.5" y2="172" gradientUnits="userSpaceOnUse">
        <stop offset="0.29" stopColor="#FFC107" />
        <stop offset="1" stopColor="#FDEFC3" />
      </linearGradient>
      <linearGradient id="paint7_linear_250_2" x1="584" y1="73" x2="584" y2="93" gradientUnits="userSpaceOnUse">
        <stop offset="0.29" stopColor="#FFC107" />
        <stop offset="1" stopColor="#FDEFC3" />
      </linearGradient>
      <linearGradient id="paint8_linear_250_2" x1="694" y1="43" x2="694" y2="63" gradientUnits="userSpaceOnUse">
        <stop offset="0.29" stopColor="#FFC107" />
        <stop offset="1" stopColor="#FDEFC3" />
      </linearGradient>
      <linearGradient id="paint9_linear_250_2" x1="424" y1="89" x2="424" y2="139" gradientUnits="userSpaceOnUse">
        <stop offset="0.29" stopColor="#FFC107" />
        <stop offset="1" stopColor="#FDEFC3" />
      </linearGradient>
    </defs>
  </svg>
);

export default SVGProfileDefaultBanner;
