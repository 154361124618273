import React from 'react';
import { Avatar } from '@mui/material';
import moment from "moment";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const ActivityCard = ({ profilePhoto, timestamp, message }) => {
    return (
      <Grid container spacing={2} alignItems="center" style={{ padding: '10px' }}>
        <Grid item>
          <Avatar src={profilePhoto} />
        </Grid>
        <Grid item xs>
          <Grid container direction="column">
            {/* <Grid item>
              <Typography variant="body2" color="textSecondary">
                {timestamp}
              </Typography>
            </Grid> */}
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {moment(timestamp).fromNow()}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{message}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

// const ActivityCard = ({ profilePhoto, timestamp, hoursAgo, message }) => {
//     const defaultAvatar = (
//         <Avatar alt="Profile" class="large-avatar" />
//     );
//     return (
//         <div className="profile-card">
//             <div className="profile-photo">
//                 {profilePhoto ? (
//                     <Avatar src={profilePhoto} alt="Profile" class="small-avatar" />
//                 ) : (
//                     defaultAvatar
//                 )}
//             </div>
//             <div className="profile-info">
//                 <div class="m-acti-wrap">
//                     <div class="sec-1">
//                         <div class="three">
//                             <span >{timestamp}</span>
//                             <span
//                                 style={{
//                                     color: "#000",
//                                     display:
//                                         "inline-block",
//                                     fontSize: 14,
//                                     color: "grey",
//                                     display:
//                                         "block",
//                                 }}
//                             >
//                                 {moment(
//                                     timestamp
//                                 ).fromNow()}
//                             </span>
//                             <h6>
//                                 {message}
//                             </h6>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

export default ActivityCard;
