import {
  Chip,
  Button,
  Avatar,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVolunteerCount } from "../../redux/action/volunteerCountAction";
import { getCampaign } from "../../redux/action/CampaignAction";
import { CopyRightLanding } from "../../components/CopyRightLanding";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate } from "react-router-dom";
import RewardMainImage from "../../assets/rew.png";
import cYAAGlogo from "../../assets/sdp.jpeg";
import Impact1 from "../../assets/impact1.png";
import Impact2 from "../../assets/impact2.png";
import Impact3 from "../../assets/impact3.png";
import LandingPage1Img from "../../assets/landingpage1.jpg";
import LandingPage2Img from "../../assets/landingpage2.jpg";
import LandingPage3Img from "../../assets/landingpage3.jpg";
import VideoInterviewImage from "../../assets/videointerview.png";
import CertificateImage from "../../assets/certificate.png";
import DiscountCouponImage from "../../assets/discountcoupon.png";
import GiftCardImage from "../../assets/giftcard.png";
import InternshipImage from "../../assets/internship.png";
import SocialMediaImage from "../../assets/socialmedia.png";
import CEOImage from "../../assets/ceo.png";
import { isArray } from "lodash";
import BronzeLvl from "../../assets/bronze.png";
import SilverLvl from "../../assets/silver.png";
import GoldLvl from "../../assets/gold.png";
import DiamondLvl from "../../assets/diamond.png";
import PlatinumLvl from "../../assets/platinum.png";
import CampaignCard from "../../components/CampaignCard";
import Fab from '@mui/material/Fab';
import { styled, keyframes } from '@mui/system';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import AIChatAskAnything from "../../components/AIChatAskAnything";
import useResizeObserver from '../../components/useResizeObserver';
import { FaFire, FaFireAlt } from 'react-icons/fa';
import PaymentOptions from "../../assets/paymentoptions.png";
import CloudIcon from '@mui/icons-material/Cloud';
import LockIcon from '@mui/icons-material/Lock';
import StarIcon from '@mui/icons-material/Star';


const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  flexWrap: 'wrap',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const Rect = styled('div')(({ theme }) => ({
  width: '31%',
  background: 'white',
  margin: '4px',
  padding: '15px',
  border: '1px solid #ddd',
  boxSizing: 'border-box',
  borderRadius: '10px',
  '@media (max-width: 768px)': {
    width: '98%',
  },
}));

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '10px 0',
}));

const Column = styled('div')(({ theme, flex, align }) => ({
  flex: flex || '1',
  textAlign: align || 'center',
}));

const Logo = styled('img')(({ theme }) => ({
  width: '50px',
  height: '50px',
  marginRight: '10px',
}));

const Title = styled('h3')(({ theme }) => ({
  margin: 0,
  marginLeft: '2px',
}));

const Description = styled('p')(({ theme }) => ({
  textAlign: 'center',
}));

const Price = styled('div')(({ theme }) => ({
  textAlign: 'center',
  fontSize: '16px',
  marginTop: '10px',
  '& span': {
    fontSize: '24px',
  },
}));

const Footer = styled('div')(({ theme }) => ({
  background: '#f0f0f0',
  padding: '10px',
  display: 'flex',
  borderRadius: '20px',
  border:'1px solid'
  // justifyContent: 'space-between',
}));

const Clickable = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  marginLeft: '3px'
}));

const Arrow = styled('div')(({ theme, expanded }) => ({
  display: 'inline-block',
  transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
  transition: 'transform 0.3s',
}));

const ExpandableContent = styled('div')(({ theme, expanded }) => ({
  marginTop: '10px',
  display: expanded ? 'block' : 'none',
  marginLeft: '33px',
  color: 'gray'
}));

const SelectButton = styled('button')(({ theme, selected }) => ({
  backgroundColor: selected ? '#007bff' : '#fff',
  color: selected ? '#fff' : '#007bff',
  border: '1px solid #007bff',
  borderRadius: '4px',
  padding: '5px 10px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: selected ? '#0056b3' : '#e7f3ff',
  },
}));

const PaymentButton = styled('button')(({ theme, disabled }) => ({
  backgroundColor: disabled ? '#ddd' : '#007bff',
  color: disabled ? '#666' : '#fff',
  // border: '1px solid #007bff',
  // borderRadius: '4px',
  // padding: '10px 20px',
  cursor: disabled ? 'not-allowed' : 'pointer',
  '&:hover': {
    backgroundColor: !disabled ? '#0056b3' : '#ddd',
  },
}));

const containerData = [
  {
    title: 'Basic',
    description: 'Free Tier',
    price: '₹0',
    unit: 'per user per month',
    footerContent: [
      { label: 'Campaigns', content: 'Unlimited' },
      { label: 'Reporting & Statistics', content: 'Users have access to basic reporting tools, providing limited insights into platform activities and user engagement' },
      { label: 'Team Creation', content: 'Users face restrictions on the number of teams they can create, encouraging focused and strategic team formation.' },
      { label: 'Joining Teams', content: 'Users have limitations on the number of teams they can join, promoting selective participation.' },
      { label: 'Performance Recognition', content: 'Users are not eligible for top performer bonuses, limiting recognition to standard performance metrics.' },
      { label: 'Rewards', content: 'Access to a standard reward system with general benefits and incentives.' },
      { label: 'Reward Points Expiry', content: 'Reward points expire monthly, encouraging users to stay active and regularly participate in platform activities.' },
      { label: 'Image Gallery', content: 'Users face limitations in the number of image uploads they can make to their gallery, ensuring efficient resource management.' },
      { label: 'Push Notifications', content: 'Users receive a limited number of push notifications, designed to reduce distractions and maintain focus.' },
      { label: 'Ad Experience', content: 'Users will encounter ads within the platform, which may include third-party advertisements and promotions.' },
    ],
    icon: <CloudIcon style={{ fontSize: 28, color: '#2196F3' }}/>, 
  },
  {
    title: 'Standard',
    description: 'Premium Tier',
    price: '₹35',
    unit: 'per user per month',
    footerContent: [
      { label: 'Campaigns', content: 'Unlimited' },
      { label: 'Reporting & Statistics', content: 'Premium users benefit from advanced reporting and statistical tools, offering detailed insights into user behavior, campaign performance, and more' },
      { label: 'Team Creation', content: 'There are no restrictions on the number of teams premium users can create, allowing for unlimited team formation and collaboration.' },
      { label: 'Joining Teams', content: 'Premium users can join as many teams as they wish, fostering greater collaboration and community engagement.' },
      { label: 'Performance Recognition', content: 'Outstanding users on the premium plan are eligible for top performer bonuses, offering additional incentives for excellence and high achievement.' },
      { label: 'Rewards', content: 'Users receive exclusive rewards, offering enhanced benefits and recognition for their contributions and engagement on the platform.' },
      { label: 'Reward Points Expiry', content: 'Reward points in the premium plan may not expire or have a longer validity period, allowing users to accumulate points over time without the pressure of frequent expiry.' },
      { label: 'Image Gallery', content: 'Users enjoy unlimited uploads in the image gallery, providing them with the freedom to store and showcase as many images as they like.' },
      { label: 'Push Notifications', content: 'Premium users receive standard push notifications, keeping them informed and engaged with platform updates and activities.' },
      { label: 'Ad Experience', content: 'Enjoy a seamless and uninterrupted experience with no ads displayed on the platform.' },
    ],
    // footerContent: [
    //   { label: 'Advanced Analytics', content: '7' },
    //   { label: 'Customizable Campaign Pages', content: '8.' },
    //   { label: 'Priority Business Support', content: '9.' },
    //   { label: 'Enhanced Integration', content: '10.' },
    //   { label: 'Advanced Reporting', content: '11.' },
    //   { label: 'Active Directory Auth', content: '11.' },
    // ],
    icon: (
      <div style={{ position: 'relative', display: 'inline-block' }}>
      <CloudIcon style={{ fontSize: 28, color: '#2196F3' }} />
      <LockIcon 
        style={{ 
          position: 'absolute', 
          top: '45%', 
          left: '52%', 
          transform: 'translate(-50%, -50%)', 
          fontSize: 14, 
          color: '#FFF' 
        }} 
      />
    </div>
    ), 
  },
  {
    title: 'Enterprise',
    description: 'Custom Tier',
    price: 'Contact Sales',
    unit: '',
    footerContent: [
      { label: 'Sole Tenant', content: 'Please contact Sales.' },
      { label: 'Advanced Security', content: 'Please contact Sales.' },
      { label: 'API Access', content: 'Please contact Sales.' },
      { label: 'Dedicated Account Manager', content: 'Please contact Sales.' },
      { label: 'Custom Development', content: 'Please contact Sales.' },
      { label: 'Global Scalability', content: 'Please contact Sales.' },
      { label: 'Teams', content: 'Please contact Sales.' },
      { label: 'Notifications', content: 'Please contact Sales.' },
      { label: 'Rewards & Recognitions', content: 'Please contact Sales.' },
      { label: 'AI Dashboards', content: 'Please contact Sales.' },
    ],
    // footerContent: [
    //   { label: 'Sole Tenant', content: 'This is the content for Custom Integrations.' },
    //   { label: 'Advanced Security', content: 'This is the content for White-Glove Service.' },
    //   { label: 'API Access', content: 'This is the content for Dedicated Account Manager.' },
    //   { label: 'Dedicated Account Manager', content: 'This is the content for Dedicated Account Manager.' },
    //   { label: 'Custom Development', content: 'This is the content for Dedicated Account Manager.' },
    //   { label: 'Global Scalability', content: 'This is the content for Dedicated Account Manager.' },
    // ],
    icon: (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <CloudIcon style={{ fontSize: 28, color: '#2196F3' }} />
      <StarIcon 
        style={{ 
          position: 'absolute', 
          top: '45%', 
          left: '52%', 
          transform: 'translate(-50%, -50%)', 
          fontSize: 12, 
          color: '#FFF' 
        }} 
      />
    </div>
    ),  
  },
  // {
  //   title: 'Premium',
  //   description: 'Unlimited Campaigns and metrics',
  //   price: '₹60',
  //   unit: 'per user per month',
  //   footerContent: [
  //     { label: 'Sole Tenant', content: 'This is the content for Custom Integrations.' },
  //     { label: 'Advanced Security', content: 'This is the content for White-Glove Service.' },
  //     { label: 'API Access', content: 'This is the content for Dedicated Account Manager.' },
  //     { label: 'Dedicated Account Manager', content: 'This is the content for Dedicated Account Manager.' },
  //     { label: 'Custom Development', content: 'This is the content for Dedicated Account Manager.' },
  //     { label: 'Global Scalability', content: 'This is the content for Dedicated Account Manager.' },
  //   ],
  //   icon: (
  //   <div style={{ position: 'relative', display: 'inline-block' }}>
  //     <CloudIcon style={{ fontSize: 28, color: '#2196F3' }} />
  //     <StarIcon 
  //       style={{ 
  //         position: 'absolute', 
  //         top: '45%', 
  //         left: '52%', 
  //         transform: 'translate(-50%, -50%)', 
  //         fontSize: 12, 
  //         color: '#FFF' 
  //       }} 
  //     />
  //   </div>
  //   ),  
  // },
];




const LandingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [campaignList, setCampaignList] = useState([]);
  const [rewardList, setRewardList] = useState([]);
  const [marketPlace, setMarketPlace] = useState([]);
  const [sponsor, setSponsor] = useState([]);
  const [SDGCampMapping, setSDGCampMapping] = React.useState([]);
  const [showChat, setShowChat] = useState(false);
  const [showComponent, setShowComponent] = useState("true");
  const [expandedIndexes, setExpandedIndexes] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndexes((prevIndexes) => ({
      ...prevIndexes,
      [index]: !prevIndexes[index],
    }));
  };
  const handlePaymentClick = () => {
    // navigate('/pg/registertemp', { state: { selectedIndex } });
    navigate('/pg/register', { state: { selectedIndex } });
  };
  const handleSelect = (index) => {
    setSelectedIndex(index);
  };
  const handleContactSales = (index) => {
    navigate('/pg/contactsales');
  };
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const { isSuccess: campSuccess, response: campResponse } = useSelector(
    (state) => state.getCampaign
  );

  useEffect(() => {
    localStorage.setItem("userid", "");
    localStorage.setItem("profileurl", "");
    localStorage.setItem("token", "");
    localStorage.setItem("referralid", null)
    localStorage.setItem("referedcampid", null)
    localStorage.setItem("reflevel", null);
    localStorage.setItem("title", null);
  }, [campaignList]);

  useEffect(() => {
    getVolunteerCamp();
    getRewardList();
    // getSponsors(); 
  }, []);

  useEffect(() => {
    getMarketPlace();
  }, [campaignList]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`camp/sdg/mapping`);
        console.log("res?.data = ", res?.data)
        setSDGCampMapping(res?.data);
      } catch (err) {
        console.error("Error fetching SDG camp mapping:", err);
      }
    };

    // Call the fetchData function
    fetchData();
  }, [campaignList]);

  console.log("SDGCampMapping  = ", SDGCampMapping);

  React.useEffect(() => {

    dispatch(
      getCampaign(profileResponse.orgrole, profileResponse.organization)
    );
    getSponsors();

  }, [campaignList]);

  const getVolunteerCamp = async () => {
    try {
      const res = await axios
        // .get(`vol_dashboard/camp/${pRes?.orgrole}/${pRes?.organization}`)
        .get(`landing/vol_dashboard/camp`)
        .then((response) => {
          setCampaignList(response?.data);
          return response;
        });
    } catch (err) {
      throw err;
    }
  };

  const handleParentFunction = () => {
    setShowChat(false);
    handleChatClick();
  };

  const handleChatClick = () => {
    setShowChat(!showChat);
  };

  const TextOverlay = styled('div')({
    position: 'absolute',
    width: '100%',
    top: '-14px',
    left: '50%', // Center the text
    transform: 'translateX(-50%)',
    color: 'transparent',
    fontSize: '0.6rem', // Adjust font size as needed
    // fontWeight: 'bold',
    zIndex: 10, // Ensures text is on top of the icon
    background: 'linear-gradient(45deg, #FFC107, #FFA000)', // Slightly darker gradient for a sharper look
    WebkitBackgroundClip: 'text', // Clips the gradient to text
    backgroundClip: 'text',
    textTransform: 'none',
    textShadow: `
      0 0 0px rgba(0, 0, 0, 1.6), 
      0 0 1px rgba(0, 0, 0, -0.5), 
      0 0 5px rgba(0, 0, 1, 0.4)`,
    '@media (max-width: 600px)': {
      left: '24px',
    },
  });

  const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
`;

  const StyledFab = styled(Fab)({
    position: 'fixed',
    bottom: '45px',
    right: '30px',
    zIndex: 1000,
    backgroundColor: '#003366',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '56px', // Adjust to fit your icon
    height: '56px', // Adjust to fit your icon
    '&:hover': {
      backgroundColor: '#002244',
    },
    '@media (max-width: 600px)': {
      bottom: '80px',
      right: '10px',
      width: '48px',
      height: '48px',
    },
    overflow: 'visible',
  });

  const AnimatedIcon = styled(FaFire)({
    color: '#FFA500',
    animation: `${pulse} 2s infinite ease-in-out`,
    fontSize: '24px',
    // marginTop: '20px'
  });

  const getRewardList = async () => {
    try {
      const res = await axios.get(`/success/stories`).then((response) => {
        setRewardList(response?.data);
        return response;
      });
    } catch (err) {
      throw err;
    }
  };

  const getMarketPlace = async () => {
    try {
      const res = await axios
        .get(`/landing/marketplace/read`)
        .then((response) => {
          setMarketPlace(response?.data);
          return response;
        });
    } catch (err) {
      throw err;
    }
  };

  const getSponsors = async () => {
    try {
      const res = await axios
        .get(`/sponsor`)
        .then((response) => {
          if (response.data) {
            setSponsor(response?.data);
          }
          return response;
        });
    } catch (err) {
      throw err;
    }
  };

  const ref = useRef(null);

  useResizeObserver(() => {
    console.log('Resized!');
  }, ref);

  const options = {
    items: 3,
    margin: 10,
    loop: false,
    nav: false,
    autoplaySpeed: 900,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const optionsmob = {
    items: 3,
    margin: 10,
    loop: true,
    nav: false,
    autoplaySpeed: 900,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const optionsFour = {
    items: 4,
    margin: 10,
    loop: true,
    nav: false,
    autoplaySpeed: 900,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  const optionsTwo = {
    items: 2,
    margin: 10,
    loop: false,
    nav: true,
    autoplaySpeed: 900,
    autoplay: false,
    dots: false,
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
    touchDrag: true,
  };

  const isDesktop = useMediaQuery("(min-width:600px)");

  return (
    <div class="landingPageWrapper" ref={ref}>
      <div class="desktop-nav header navbar" style={{ marginBottom: "0px", padding: "7px 0px 0px 0px" }}>
        <div class="container">
          <div class="nav-logo-land-direct">
            {" "}
            <img src={cYAAGlogo} class="img-fluid" alt="logo" style={{ maxHeight: "45px" }} />
            <figcaption >Powered by cYAAG</figcaption>
          </div>
          <ul class="navbar-nav" style={{ display: "inline-block" }}>
            <li class="nav-item" name="login" style={{ display: "inline-block" }}>
              <a
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </a>
            </li>
            <li
              class="nav-item"
              style={{ display: "inline-block", marginLeft: "20px" }}
            >
              <a
                class="cy-reg-btn"
                onClick={() => {
                  navigate("/register");
                }}
              >
                Sign Up
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <OwlCarousel className="owl-theme" items="1" autoplay nav dots loop>
          <div class="item slide1">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 banner-left-content">
                  <h2 class="pl-3 pr-3 ">
                    {/* Welcome to IEEE SDP  */}
                    Welcome to Sustainable Development Platform
                    <br />

                    {/* <span class = "poweredbycyaag" style={{color:"black", fontSize:"14px"}}>Vision</span> */}
                  </h2>
                  {/* <h4 class = "p-3 poweredbycyaag" style={{color:"black", fontSize:"14px"}}>Powered by cYAAG</h4> */}
                  <h4 class="p-3 landtext">
                    A global platform for changemakers to make real social
                    impact{" "}
                  </h4>

                  <h6 class="p-3 landtext">
                    In agriculture, healthcare, education, employment, air,
                    water, energy, climate, animal welfare, waste management,
                    women empowerment, and more!{" "}
                  </h6>
                  <div class="pl-4 pr-4">
                    {/* <button type="button" class="view-more-btn btn mb-4 mr-2">Learn More <i class="fa fa-arrow-right"></i></button> */}
                    <Button
                      // onClick={() => navigate(`/moreaboutus`)}
                      onClick={() => navigate(`/moreaboutus`)}
                      style={{ color: "white" }}
                      class="view-more-btn btn mb-4 mr-2"
                      id="learnmore1"
                    >
                      Learn More{" "}
                      <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </Button>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-center">
                  <div class="slider-img d-sm-flex justify-content-center">
                    <img
                      alt="Steer Your Vision"
                      class="img-fluid"
                      height="800px"
                      src={LandingPage1Img}
                      title="Steer Your Vision"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item slide1">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 banner-left-content">
                  <h2 class="pl-3 pr-3">
                    Participate Now
                    <br />
                    {/* <span>Implementation</span> */}
                  </h2>
                  <h4 class="p-3 landtext">
                    In social campaigns in your neighborhood and across the
                    world{" "}
                  </h4>
                  <h6 class="p-3 landtext">
                    Offered by many corporations, social enterprises, and
                    educational institutions{" "}
                  </h6>
                  <div class=" pl-4 pr-4">
                    {/* <button type="button" class="view-more-btn btn btn-secondary mb-4 mr-2">Learn More <i class="fa fa-arrow-right"></i></button> */}
                    <Button
                      onClick={() => navigate(`/moreaboutus`)}
                      style={{ color: "white" }}
                      class="view-more-btn btn mb-4 mr-2"
                      id="learnmore2"
                    >
                      Learn More{" "}
                      <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </Button>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-center">
                  <div class="slider-img d-sm-flex justify-content-center">
                    <img
                      alt="ERP Implementation"
                      class="img-fluid"
                      height="600px"
                      src={LandingPage2Img}
                      title="ERP Implementation"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item slide1">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 banner-left-content">
                  <h2 class="pl-3 pr-3 ">
                    Earn Recognition and Rewards <br />
                    {/* <span>Implementation</span> */}
                  </h2>
                  <h4 class="p-3 landtext">Be known for your social impact</h4>
                  <h6 class="p-3 landtext">
                    Become eligible for course credits, certificates,
                    internships, job opportunities, field trips, free training,
                    mentorship, prizes, cash awards, and many more!
                  </h6>
                  <div class=" pl-4 pr-4">
                    {/* <button type="button" class="view-more-btn btn btn-secondary mb-4 mr-2">Learn More <i class="fa fa-arrow-right"></i></button> */}
                    <Button
                      onClick={() => navigate(`/moreaboutus`)}
                      style={{ color: "white" }}
                      class="view-more-btn btn mb-4 mr-2"
                      id="learnmore3"
                    >
                      Learn More{" "}
                      <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </Button>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-center">
                  <div class="slider-img d-sm-flex justify-content-center">
                    <img
                      alt="ERP Implementation"
                      class="img-fluid"
                      height="600px"
                      src={LandingPage3Img}
                      title="ERP Implementation"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>



        <div class="container">
          <div class="cyagg-sec-space campaigns-tabs">
            <div>
              <div class="row">
                <div class="col-7">
                  <div>
                    <h4 class="site-title">Campaigns</h4>
                  </div>
                </div>
                <div class="col-5">
                  <div class="rightAlign">
                    <a class="story-signin">
                      <Button
                        onClick={() => navigate(`/login`)}
                        id="joinnowcampaign"
                        style={{ color: "white" }}
                      >
                        Join Now
                        <i class="fa fa-arrow-right"></i>
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="widget-content animated-underline-content">
              <ul
                class="nav nav-tabs  mb-3 campaign-tabz"
                id="animateLine"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="animated-underline-home-tab"
                    data-toggle="tab"
                    href="#animated-underline-home"
                    role="tab"
                    aria-controls="animated-underline-home"
                    aria-selected="true"
                  >
                    <i className="material-icons directions_run">&#xe566;</i>{" "}
                    <span>Active</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="animated-underline-profile-tab"
                    data-toggle="tab"
                    href="#animated-underline-profile"
                    role="tab"
                    aria-controls="animated-underline-profile"
                    aria-selected="false"
                  >
                    <i className="material-icons edit_calendar">&#xe742;</i>{" "}
                    <span>Next</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="animated-underline-contact-tab"
                    data-toggle="tab"
                    href="#animated-underline-contact"
                    role="tab"
                    aria-controls="animated-underline-contact"
                    aria-selected="false"
                  >
                    <i className="material-icons check_circle_outline">
                      &#xe92d;
                    </i>{" "}
                    <span>Done</span>
                  </a>
                </li>
              </ul>

              <div class="tab-content customOwlNav landingPage" id="animateLineContent-4">
                <div
                  class="tab-pane fade show active"
                  id="animated-underline-home"
                  role="tabpanel"
                  aria-labelledby="animated-underline-home-tab"
                >


                  <OwlCarousel className="owl-theme" {...optionsTwo}>
                    {SDGCampMapping && campaignList
                      ?.filter((item, index) => item.active === "Active")
                      .slice(0, 3)
                      .map((tile, index) => (
                        <div>
                          <CampaignCard
                            organizationlogo={tile?.organizationlogo}
                            name={tile?.name}
                            logo={tile?.logo}
                            video={tile?.video}
                            sponsor={tile?.sponsor}
                            tag={tile?.tag}
                            SDGCampMapping={SDGCampMapping}
                            goalquantity={tile?.goalquantity}
                            unitofgoal={tile?.unitofgoal}
                            committedcount={tile?.committedcount}
                            achievedcount={tile?.achievedcount}
                            submittedcount={tile?.submittedcount}
                            campaigntype={tile?.campaigntype}
                            campid={tile?.campid}
                            maxpointstobeearned={tile?.maxpointstobeearned}
                            dayrem={tile?.dayrem}
                            hourrem={tile?.hourrem}
                            minrem={tile?.minrem}
                            profileResponse={profileResponse}
                            index={index}
                            goal={tile?.goal}
                            desc={tile?.desc}
                            whattodo={tile?.whattodo}
                            guidelines={tile?.guidelines}
                            landing="yes"
                            showcamplb="no"
                            participatepage="no"

                          />
                        </div>
                      ))}
                  </OwlCarousel>
                </div>

                {/* Second tab */}
                <div
                  class="tab-pane fade"
                  id="animated-underline-profile"
                  role="tabpanel"
                  aria-labelledby="animated-underline-profile-tab"
                >
                  <OwlCarousel className="owl-theme" {...optionsTwo}>
                    {SDGCampMapping && campaignList
                      ?.filter((item, index) => item.active === "Upcoming")
                      .map((tile, index) => (

                        <div>
                          <CampaignCard
                            organizationlogo={tile?.organizationlogo}
                            name={tile?.name}
                            logo={tile?.logo}
                            video={tile?.video}
                            sponsor={tile?.sponsor}
                            tag={tile?.tag}
                            SDGCampMapping={SDGCampMapping}
                            goalquantity={tile?.goalquantity}
                            unitofgoal={tile?.unitofgoal}
                            committedcount={tile?.committedcount}
                            achievedcount={tile?.achievedcount}
                            submittedcount={tile?.submittedcount}
                            campaigntype={tile?.campaigntype}
                            campid={tile?.campid}
                            maxpointstobeearned={tile?.maxpointstobeearned}
                            dayrem={tile?.dayrem}
                            hourrem={tile?.hourrem}
                            minrem={tile?.minrem}
                            profileResponse={profileResponse}
                            index={index}
                            goal={tile?.goal}
                            desc={tile?.desc}
                            whattodo={tile?.whattodo}
                            guidelines={tile?.guidelines}
                            landing="yes"
                            showcamplb="no"
                            participatepage="no"
                          />
                        </div>
                      ))}
                  </OwlCarousel>
                </div>
                <div
                  class="tab-pane fade"
                  id="animated-underline-contact"
                  role="tabpanel"
                  aria-labelledby="animated-underline-contact-tab"
                >
                  <OwlCarousel className="owl-theme" {...optionsTwo}>
                    {SDGCampMapping && campaignList
                      ?.filter((item, index) => item.active === "Completed")
                      .map((tile, index1) => (
                        index1 < 2 &&

                        <div>
                          <CampaignCard
                            organizationlogo={tile?.organizationlogo}
                            name={tile?.name}
                            logo={tile?.logo}
                            video={tile?.video}
                            sponsor={tile?.sponsor}
                            tag={tile?.tag}
                            SDGCampMapping={SDGCampMapping}
                            goalquantity={tile?.goalquantity}
                            unitofgoal={tile?.unitofgoal}
                            committedcount={tile?.committedcount}
                            achievedcount={tile?.achievedcount}
                            submittedcount={tile?.submittedcount}
                            campaigntype={tile?.campaigntype}
                            campid={tile?.campid}
                            maxpointstobeearned={tile?.maxpointstobeearned}
                            dayrem={tile?.dayrem}
                            hourrem={tile?.hourrem}
                            minrem={tile?.minrem}
                            profileResponse={profileResponse}
                            index={index1}
                            goal={tile?.goal}
                            desc={tile?.desc}
                            whattodo={tile?.whattodo}
                            guidelines={tile?.guidelines}
                            landing="yes"
                            showcamplb="no"
                            participatepage="no"

                          />
                        </div>
                      ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Second container  */}
        <div class="container">
          <div class="cyagg-sec-space">
            <div>
              <div class="row">
                <div class="col-7">
                  <div>
                    <h4 class="site-title">Our Sponsors</h4>
                  </div>
                </div>
                <div class="col-5">
                  <div class="rightAlign">
                    <a class="story-signin">
                      <Button
                        onClick={() => navigate(`/login`)}
                        style={{ color: "white" }}
                        id="joinnowsponsor"
                      >
                        Join Now
                        <i class="fa fa-arrow-right"></i>
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="home-demo">
              <OwlCarousel className="owl-theme" {...optionsFour}>
                {sponsor?.map((item, index) => (
                  <div class="item">
                    <div class="succ-story-badge-wrap">
                      <div>
                        {item?.sponsorlevel === "bronze" ? (
                          <img src={BronzeLvl} class="img-fluid"></img>
                        ) : item?.sponsorlevel === "silver" ? (
                          <img src={SilverLvl} class="img-fluid"></img>
                        ) : item?.sponsorlevel === "gold" ? (
                          <img src={GoldLvl} class="img-fluid"></img>
                        ) : item?.sponsorlevel === "diamond" ? (
                          <img src={DiamondLvl} class="img-fluid"></img>
                        ) : (
                          <img src={PlatinumLvl} class="img-fluid"></img>
                        )}
                      </div>
                      <div class="text-center succ-story-details">
                        <h6>{item?.sponsor}</h6>
                        <p>{String(item?.sponsorlevel)} </p>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="cyagg-sec-space">
            <div>
              <div class="row">
                <div class="col-7">
                  <div>
                    <h4 class="site-title">Win Rewards!</h4>
                  </div>
                </div>
                <div class="col-5">
                  <div class="rightAlign">
                    <a class="story-signin">
                      <Button
                        onClick={() => navigate(`/login`)}
                        style={{ color: "white" }}
                        id="joinnowrewards"
                      >
                        Join Now
                        <i class="fa fa-arrow-right"></i>
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div>
                  <img class="hide-on-mobile" src={RewardMainImage} />
                </div>
              </div>
              <div class="col-md-8">
                <div class="">
                  <OwlCarousel className="owl-theme" {...options}>
                    {marketPlace?.map(
                      (item, index) =>
                        index < 5 && (
                          <div class="item">
                            <div class="reward-wrap">
                              <div class="landingPageRewardWrapper">
                                {item?.rewardtype ===
                                  "30 mins with a CEO" ? (
                                  <img
                                    class="reward-image"
                                    src={CEOImage}
                                    style={{ width: "100%" }}
                                  />
                                ) : item?.rewardtype ===
                                  "Certificate of Participation" ? (
                                  <img
                                    class="reward-image"
                                    src={CertificateImage}
                                    style={{ width: "100%" }}
                                  />
                                ) : item?.rewardtype ===
                                  "Video Interview with You" ? (
                                  <img
                                    class="reward-image"
                                    src={VideoInterviewImage}
                                    style={{ width: "100%" }}
                                  />
                                ) : item?.rewardtype ===
                                  "Discount Coupons" ? (
                                  <img
                                    class="reward-image"
                                    src={DiscountCouponImage}
                                    style={{ width: "100%" }}
                                  />
                                ) : item?.rewardtype ===
                                  "Gift Cards" ? (
                                  <img
                                    class="reward-image"
                                    src={GiftCardImage}
                                    style={{ width: "100%" }}
                                  />
                                ) : item?.rewardtype ===
                                  "Internship Opportunity" ? (
                                  <img
                                    class="reward-image"
                                    src={InternshipImage}
                                    style={{ width: "100%" }}
                                  />
                                ) : item?.rewardtype ===
                                  "Social Media Recognition" ? (
                                  <img
                                    class="reward-image"
                                    src={SocialMediaImage}
                                    style={{ width: "100%" }}
                                  />
                                ) : (
                                  <img
                                    class="reward-image"
                                    src={RewardMainImage}
                                    style={{ width: "100%" }}
                                  />
                                )}
                              </div>
                              <div class="reward-details">
                                <div>
                                  <div>
                                    <div id="toggleAccordion">
                                      <div class="card mb-1">
                                        <div
                                          class="card-header"
                                          id="headingOne4"
                                        >
                                          <h5 class="mb-0 mt-0 reward-desc-title">
                                            <span
                                              role="menu"
                                              class=""
                                              data-toggle="collapse"
                                              data-target={"#" + index}
                                              aria-expanded="true"
                                              aria-controls="iconChangeAccordionOne"
                                            >
                                              {item?.rewardtype}
                                              <i class="flaticon-down-arrow float-right"></i>
                                            </span>
                                          </h5>
                                        </div>

                                        <div
                                          id={index}
                                          class="collapse"
                                          aria-labelledby="headingOne4"
                                          data-parent="#toggleAccordion"
                                        >
                                          <div class="card-body">
                                            <p class="mb-3">
                                              <div
                                                style={{ textAlign: "center" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 12,
                                                    color: "blue",
                                                  }}
                                                >
                                                  {" "}
                                                  {item?.subtitle}{" "}
                                                </span>
                                              </div>
                                            </p>
                                            <p class="mb-3">
                                              <div
                                                style={{ textAlign: "center", }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 12,
                                                    color: "green",
                                                  }}
                                                >
                                                  {" "}
                                                  {item?.title}{" "}
                                                </span>
                                              </div>
                                            </p>
                                            <p class="mb-3">
                                              <div
                                                style={{
                                                  padding: 3,
                                                  margin: 4,
                                                  borderRadius: 4,
                                                  textAlign: "center"
                                                }}
                                              >
                                                <span style={{ fontSize: 12 }}>
                                                  {" "}
                                                  You need{" "}
                                                </span>
                                                <Chip
                                                  key={item?.points}
                                                  size="small"
                                                  label={item?.points}
                                                  style={{
                                                    backgroundColor: "white",
                                                    color: "#DAA520",
                                                    fontSize: 18,
                                                    fontWeight: "bold",
                                                  }}
                                                />{" "}
                                                <span style={{ fontSize: 12 }}>
                                                  pts to redeem
                                                </span>
                                              </div>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="redeem-pts">
                                    <div class="row">
                                      <div class="col-6">
                                        <div class="re-po-left">
                                          <img src="https://png.pngtree.com/png-clipart/20220213/original/pngtree-coins-stack-icon-vector-png-image_7267762.png" />
                                          <div class="pts">
                                            <h5>{item?.points}</h5>
                                            <span>Points</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <div
                                          class="re-po-right-landing"
                                          // onClick={() => navigate(`/login`)}
                                          disabled
                                        >
                                          <a>Redeem</a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="text-center">
                                    <h6 class="sponsor-title">Sponsors</h6>
                                    <div class="row sponserWrapper">
                                      {/* <div class=""> */}
                                      <div class="">
                                        {isArray(item?.sponsor) &&
                                          item?.sponsor?.map((reward, index) => (

                                            isDesktop ? (
                                              <img
                                                style={{
                                                  width: "auto",
                                                  height: "50px",
                                                }}
                                                src={reward?.sponsorlogo}
                                              />
                                            ) : (
                                              <img
                                                style={{
                                                  width: "auto",
                                                  height: "50px",
                                                }}
                                                src={reward?.sponsorlogo}
                                              />
                                            )

                                          ))}
                                      </div>

                                    </div>
                                    {/* </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Third container */}

        <div class="container">
          <div class="cyagg-sec-space">
            <div>
              <div class="row">
                <div class="col-7">
                  <div>
                    <h4 class="site-title">Success Stories</h4>
                  </div>
                </div>
                <div class="col-5">
                  <div class="rightAlign">
                    {/* <a class="story-signin">Join Now <i class="fa fa-arrow-right"></i></a> */}
                    <a class="story-signin">
                      <Button
                        onClick={() => navigate(`/login`)}
                        style={{ color: "white" }}
                        id="joinnowsuccess"
                      >
                        Join Now
                        <i class="fa fa-arrow-right"></i>
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="home-demo">
              <OwlCarousel className="owl-theme" {...optionsFour}>
                {rewardList?.map((item, index) => (
                  <div class="item">
                    <div class="succ-story-wrap">
                      <div>
                        <img src={item.image} />
                      </div>
                      <div class="text-center succ-story-details">
                        <h6>{item.name}</h6>
                        <p>{item.msg} </p>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>

        <br />
        <div class="container">
          <div class="cyagg-sec-space">
            <div>
              <div class="row">
                <div class="col-7">
                  <div>
                    <h4 class="site-title">Pricing</h4>
                  </div>
                </div>
                <div class="col-5">
                  <div class="rightAlign">
                    <a class="story-signin">
                      <Button
                        // onClick={() => navigate(`/pg/register`)}
                        onClick={handlePaymentClick}
                        style={{ color: (selectedIndex === null || selectedIndex === 2 || selectedIndex === 0) ? "gray" : "white", cursor: selectedIndex === null ? "not-allowed" : "pointer" }}
                        
                        disabled={(selectedIndex === null || selectedIndex === 2 || selectedIndex === 0)}
                      >
                        Payment
                        <i class="fa fa-arrow-right"></i>
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="home-demo">
              <div class="item">
                <div class="payment-gw-wrap">
                  <div>
                    {/* <img src={PaymentOptions} /> */}
                    <Container>
                      {containerData.map((container, index) => (
                        <Rect key={index}>
                          <Row>
                            <Column flex="0.9">
                            <Row>
                                {container.icon}
                              <Title>{container.title}</Title>
                            </Row>
                              <Description>{container.description}</Description>
                              <Price>
                                <span>{container.price}</span> {" "} {container.unit}
                              </Price>
                            </Column>
                          </Row>
                          {container.footerContent.map((content, infoIndex) => (
                            <div key={infoIndex}>
                              <Footer>
                                <Clickable onClick={() => toggleExpand(`${index}-${infoIndex}`)}>
                                  <Arrow expanded={expandedIndexes[`${index}-${infoIndex}`]}>►</Arrow>
                                </Clickable>
                                <Clickable onClick={() => toggleExpand(`${index}-${infoIndex}`)}>
                                  {content.label}
                                </Clickable>
                              </Footer>
                              <ExpandableContent expanded={expandedIndexes[`${index}-${infoIndex}`]}>
                                {content.content}
                              </ExpandableContent>
                            </div>
                          ))}
                          <Row>
                            <Column flex="0.9">
                            {
                              index === 1 ?
                              <SelectButton
                                selected={selectedIndex === index}
                                onClick={() => handleSelect(index)}
                              >
                                Select
                              </SelectButton>
                              : index === 0 ?
                              <SelectButton
                                selected={selectedIndex === index}
                                onClick={() => navigate("/register")}
                              >
                                Register
                              </SelectButton>
                             
                              :
                              <SelectButton
                                selected={selectedIndex === index}
                                onClick={() => handleContactSales(index)}
                              >
                                Contact Sales
                              </SelectButton>
}


                            </Column>
                          </Row>
                        </Rect>
                      ))}
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>





        {/* Final Container  */}

        {/* <div class="container">
          <div class="cyagg-sec-space">
            <div>
              <div class="row">
                <div class="col-md-7">
                  <div>
                    <h4 class="site-title">Your Way Ahead</h4>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8 add-full-width">
                <div>
                  <OwlCarousel
                    className="owl-theme"
                    items="1"
                    autoplay
                    nav
                    dots
                    loop
                  >
                    <div class="item">
                      <div class="streak-wrap">
                        <div>
                          <div class="row">
                            <div class="col-md-4">
                              <div>
                                <img src={StreakLeft} class="img-fluid" />
                              </div>
                            </div>
                            <div class="col-md-8">
                              <div>
                                <div class="streak-head">
                                  <div class="row">
                                    <div class="col-5">
                                      <div>
                                        <div>
                                          <span>Total points</span>
                                        </div>
                                        <div class="streak-point-sec">
                                          <img
                                            src="assests/img/streak-points.png"
                                            class="img-fluid"
                                          />
                                          <h4>1234</h4>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-7">
                                      <div>
                                        <a class="more-p-btn">
                                          How to Earn More Points?{" "}
                                          <img src="assests/img/qstnmark.png" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="streak-body">
                                  <div class="row">
                                    <div class="col-md-9">
                                      <div>
                                        <section class="signup-step-container">
                                          <div class="container">
                                            <div class="row d-flex justify-content-center">
                                              <div class="col-md-12">
                                                <div class="wizard">
                                                  <div class="wizard-inner">
                                                    <div class="connecting-line"></div>
                                                    <ul
                                                      class="nav nav-tabs"
                                                      role="tablist"
                                                    >
                                                      <li
                                                        role="presentation"
                                                        class="active"
                                                      >
                                                        <a
                                                          href="#step1"
                                                          data-toggle="tab"
                                                          aria-controls="step1"
                                                          role="tab"
                                                          aria-expanded="true"
                                                        >
                                                          <span class="round-tab">
                                                            1{" "}
                                                          </span>{" "}
                                                          <i>Step 1</i>
                                                        </a>
                                                      </li>
                                                      <li
                                                        role="presentation"
                                                        class="disabled"
                                                      >
                                                        <a
                                                          href="#step2"
                                                          data-toggle="tab"
                                                          aria-controls="step2"
                                                          role="tab"
                                                          aria-expanded="false"
                                                        >
                                                          <span class="round-tab">
                                                            2
                                                          </span>{" "}
                                                          <i>Step 2</i>
                                                        </a>
                                                      </li>
                                                      <li
                                                        role="presentation"
                                                        class="disabled"
                                                      >
                                                        <a
                                                          href="#step3"
                                                          data-toggle="tab"
                                                          aria-controls="step3"
                                                          role="tab"
                                                        >
                                                          <span class="round-tab">
                                                            3
                                                          </span>{" "}
                                                          <i>Step 3</i>
                                                        </a>
                                                      </li>
                                                      <li
                                                        role="presentation"
                                                        class="disabled"
                                                      >
                                                        <a
                                                          href="#step4"
                                                          data-toggle="tab"
                                                          aria-controls="step4"
                                                          role="tab"
                                                        >
                                                          <span class="round-tab">
                                                            4
                                                          </span>{" "}
                                                          <i>Step 4</i>
                                                        </a>
                                                      </li>
                                                    </ul>
                                                  </div>

                                                  <form
                                                    role="form"
                                                    action="index.html"
                                                    class="login-box"
                                                  >
                                                    <div
                                                      class="tab-content"
                                                      id="main_form"
                                                    >
                                                      <div
                                                        class="tab-pane active"
                                                        role="tabpanel"
                                                        id="step1"
                                                      >
                                                        <div>
                                                          <span class="streak-point">
                                                            This is spet one
                                                            nothing else
                                                            here..just go 1
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div
                                                        class="tab-pane"
                                                        role="tabpanel"
                                                        id="step2"
                                                      >
                                                        <div>
                                                          <span class="streak-point">
                                                            This is spet one
                                                            nothing else
                                                            here..just go 2
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div
                                                        class="tab-pane"
                                                        role="tabpanel"
                                                        id="step3"
                                                      >
                                                        <div>
                                                          <span class="streak-point">
                                                            This is spet one
                                                            nothing else
                                                            here..just go 3
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div
                                                        class="tab-pane"
                                                        role="tabpanel"
                                                        id="step4"
                                                      >
                                                        <div>
                                                          <span class="streak-point">
                                                            This is spet one
                                                            nothing else
                                                            here..just go 4
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div class="clearfix"></div>
                                                    </div>
                                                  </form>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </section>
                                      </div>
                                    </div>
                                    <div class="col-md-3 padd-0">
                                      <div class="text-center">
                                        <a class="streak-view-more">
                                          View More{" "}
                                          <i
                                            class="fa fa-chevron-right"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
              <div class="col-md-4 hide-on-spec">
                <div>
                  <img src={StreakRight} />
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div class="container">
          <div class="row"></div>
        </div>

        <div class="container hide-on-mobile">
          {/* <div class="container"> */}
          <div class="cyagg-sec-space">
            <div>
              <div class="row">
                <div class="col-md-8">
                  <h4 class="site-title">Create Triple Impact</h4>
                  <div class="impact-sec">
                    {/* <h4 class="site-title"><i><b>Social Impact</b></i></h4> */}
                    <h4 class="site-title">Social Impact</h4>
                    <p style={{ textAlign: "justify" }}>
                      Through cYAAG you can continually track how much
                      difference you made to people’s lives by participating in
                      campaigns – such as providing education to underprivileged
                      children, improving access to healthcare, supplying
                      drinking water, providing care for senior citizens,
                      improving communications in rural areas, empowering women,
                      providing for animal welfare, making communities cleaner
                      and greener, and addressing homelessness and poverty. You
                      can see the impact you are having as an individual and
                      through the extended community you have created on cYAAG!
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div>
                    <img src={Impact1} />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="col-md-4">
                  <div>
                    <img src={Impact2} />
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="impact-sec">
                    <h4 class="site-title">Financial Impact</h4>
                    <p style={{ textAlign: "justify" }}>
                      Campaigns on cYAAG also provide positive financial
                      benefits to people – making social businesses profitable,
                      creating new jobs, improving income levels, increasing
                      agricultural productivity, creating new businesses, and
                      increasing the number of women entrepreneurs. You can
                      track such financial impact metrics on cYAAG!{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="col-md-8">
                  <div class="impact-sec">
                    <h4 class="site-title">Environmental Impact</h4>
                    <p style={{ textAlign: "justify" }}>
                      cYAAG also helps you track the difference you are making
                      to the environment – like reducing carbon footprint,
                      planting trees, reducing water and air pollution,
                      implementing sustainable energy solutions, improving waste
                      management, reducing microplastics, improving
                      biodiversity, and much more!{" "}
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div>
                    <img src={Impact3} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container hide-on-desk">
          <div class="cyagg-sec-space">
            <div>
              <OwlCarousel className="owl-theme" {...optionsmob}>
                <div class="item">
                  {/* <h4 class="site-title">Create Triple Impact</h4> */}
                  <div class="impact-mob-card">
                    <div class="text-center">
                      <h4 class="site-title">Social Impact</h4>
                      <p class="text">
                        Through cYAAG you can continually track how much
                        difference you made to people’s lives by participating
                        in campaigns – such as providing education to
                        underprivileged children, improving access to
                        healthcare, supplying drinking water, providing care for
                        senior citizens, improving communications in rural
                        areas, empowering women, providing for animal welfare,
                        making communities cleaner and greener, and addressing
                        homelessness and poverty. You can see the impact you are
                        having as an individual and through the extended
                        community you have created on cYAAG.
                      </p>
                      <img src={Impact1} />
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="impact-mob-card">
                    <div class="text-center">
                      <h4 class="site-title">Financial Impact</h4>
                      <p class="text">
                        Campaigns on cYAAG also provide positive financial
                        benefits to people – making social businesses
                        profitable, creating new jobs, improving income levels,
                        increasing agricultural productivity, creating new
                        businesses, and increasing the number of women
                        entrepreneurs. You can track such financial impact
                        metrics on cYAAG!{" "}
                      </p>
                      <img src={Impact2} />
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="impact-mob-card">
                    <div class="text-center">
                      <h4 class="site-title">Environmental Impact</h4>
                      <p class="text">
                        cYAAG also helps you track the difference you are making
                        to the environment – like reducing carbon footprint,
                        planting trees, reducing water and air pollution,
                        implementing sustainable energy solutions, improving
                        waste management, reducing microplastics, improving
                        biodiversity, and much more!{" "}
                      </p>
                      <img src={Impact3} />
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="cyagg-sec-space">
            <div class="testimonial-wrap">
              <div class="row">
                <div class="col-md-4 hide-on-spec">
                  <div class="testi-img">
                    <img src="assests/img/testi.png" />
                  </div>
                </div>
                <div class="col-md-8 add-full-width">
                  <div class="testi-content">
                    <h2>What our changemakers say about cYAAG</h2>
                    <div>
                      {/* <OwlCarousel className='owl-theme' items="1" autoplay nav dots loop> */}
                      <OwlCarousel className="owl-theme" items="1" nav dots>
                        <div class="item">
                          <div class="they-said-wrap">
                            {/* <img class="test-per-img" src="https://www.fuersie.de/sites/default/files/styles/image360w/public/2022-06/egozentrische-person.jpg?h=fa6d1f7a&itok=da_GdqKf" /> */}
                            {/* <img class="test-per-img" src={LoginLogo} height="50%"/> */}
                            {/* <img class="test-per-img" src={LoginLogo} height="50%"/> */}
                            <Avatar> </Avatar>
                            <div class="testi-desc">
                              <h6>Student from RV University, Bangalore, India</h6>
                              <p style={{ fontSize: 16 }}>
                                This platform empowers social changemakers to
                                create impact!
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="item">
                          <div class="they-said-wrap">
                            {/* <img class="test-per-img" src={LoginLogo} height="50%" /> */}
                            <Avatar> </Avatar>
                            <div class="testi-desc">
                              <h6>CEO of a Healthcare Innovations Company, Delhi, India</h6>
                              <p style={{ fontSize: 16 }}>
                                cYAAG gets results. It is the current day
                                Digital Word of Mouth!
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="item">
                          <div class="they-said-wrap">
                            {/* <img class="test-per-img" src={LoginLogo} height="50%" /> */}
                            <Avatar> </Avatar>
                            <div class="testi-desc">
                              <h6>⁠Director of an Assistive Technology Foundation, San Francisco, USA</h6>
                              <p style={{ fontSize: 16 }}>
                                The goal was to reach visually impaired people.
                                Participants and recipients were inspired and
                                motivated. This YAAG has wings.
                              </p>
                            </div>
                          </div>
                        </div>
                      </OwlCarousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CopyRightLanding />
      <div className="askAgni mobileView">
        {showChat && <AIChatAskAnything onParentFunction={handleParentFunction} />}
        <StyledFab aria-label="chat">
          <TextOverlay>Ask AGNI</TextOverlay>
          <AnimatedIcon onClick={handleChatClick} />
        </StyledFab>
      </div>
    </div>
  );
};

export default LandingPage;
