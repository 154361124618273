import * as React from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {
  onboardActivityReset,
  onboardBulkReset,
  onboardCampaign,
  onboardCampaignReset,
  onboardIndividualReset,
  onboardOrganization,
  onboardOrganizationReset,
} from "../../redux/action/OnboardAction";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link } from "react-router-dom";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import styled from 'styled-components';

// import { styled } from '@mui/system';


const FormGroup = styled('div')(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: '40px',
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));



// FormGroup1
const FormGroup1 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledLabel
const StyledLabel = styled('label')(({ theme }) => ({
  display: 'flex',
  background: '#e9e7e7',
  padding: '10px',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 600,
  marginTop: '16px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));


// StyledInput
const StyledInput = styled('input')(({ theme }) => ({
  ...commonFontStyles,
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));



const commonFontStyles = {
  fontFamily: 'Arial, sans-serif', // Replace with your desired font family
  fontSize: '16px', // Replace with your desired font size
};

function OrganizationViewScreen(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationPath = useLocation();

  const [reviewstatus, setReviewStatus] = React.useState("");
  const [reviewComments, setReviewComments] = React.useState("");
  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "" },
  ]);

  const [focusArea, setFocusArea] = React.useState([
    { focus: "Water & Sanitation" },
    { focus: "Education" },
    { focus: "Climate Change and Sustainability" },
    { focus: "Poverty Alleviation" },
    { focus: "Teaching / Mentoring" },
    { focus: "Rural Development" },
    { focus: "Women Empowerment" },
    { focus: "Child Rights" },
    { focus: "Animal Rights" },
  ]);
  const [activityList, setActivityList] = React.useState(undefined);
  const [orgValues, setOrgValues] = React.useState({
    titleOfOrganization: "",
    totalStaff: "",
    headquarters: "",
    organizationLogo: "",
    registeredAs: "",
    website: "",
    emailId: "",
    contactNo: "",
    totalNumberofVolunteers: "",
    chiefFunctionary: "",
  });
  const {
    isCampaignSuccess,
    isCampaignFetching,

    isCampaignError,
  } = useSelector((state) => state.onBoard);

  const { isSuccess: citiesSuccess, response: citiesResponse } = useSelector(
    (state) => state.cities
  );

  function subtractYears(numOfYears, date = new Date()) {
    date.setFullYear(date.getFullYear() - numOfYears);

    return date;
  }

  const handleOnboardOrganization = async () => {
    const payload = {
      clientid: locationPath?.state?.clientid,
      createdate: locationPath?.state?.createdate,
      reviewstatus: reviewstatus,
      reviewcomments: reviewComments,
    };
    try {
      const res = await axios.put(
        `/org/review/${localStorage.getItem("userid")}`,
        payload
      );
      navigate("/revieworganization");
      return res;
    } catch (err) {
      throw err;
    }
  };

  const handleCampaignFieldChange = (field, e) => {
    (isCampaignError || isCampaignSuccess) && dispatch(onboardCampaignReset());
    setOrgValues({
      ...orgValues,
      [field]: e.target.value,
    });
  };

  const unitOfCampaign = activityList?.filter(
    (item, index) => item?.activity === orgValues?.typeOfCampaign
  );

  const onAddingItem = (event, option, index) => {
    const values = [...focusArea];
    values[index].isChecked = event.target.checked;
    setFocusArea(values);
  };

  const handleTextFieldCheckbox = (event, index) => {
    const values = [...focusArea];
    values[index].rewards = event.target.value;
    setFocusArea(values);
  };

  const handleChangeLocation = (id, e, field, index) => {
    const values = [...location];
    values[index][field] = e.target.value;
    setLocation(values);
  };

  const handleAddFields = () => {
    setLocation([...location, { country: "", state: "", city: "", area: "" }]);
  };

  const getAddress = (item, index) => {
    return (
      <div style={{ float: "left", width: "100%", marginTop: 25 }}>
        <FormGroup style={{ width: "100%" }}>
        <StyledLabel>
        {/* <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>
          {" "} */}
          Operational Areas{" "}
        {/* </span> */}
        </StyledLabel>
        </FormGroup>
        <form style={{ display: "flex", flexWrap: "wrap" }}>
          <FormGroup1>
            <StyledLabel>
              Country
            </StyledLabel>

            <StyledInput
              disabled
              value={item?.country}
              type="text"

            />
          </FormGroup1>

          <FormGroup1>
            <StyledLabel>
              State
            </StyledLabel>
            <StyledInput
              disabled
              value={item.state}
              type="text"

            />
          </FormGroup1>
          <FormGroup1>
            <StyledLabel>
              City/Town
            </StyledLabel>
            <StyledInput
              disabled
              value={item.city}
              type="text"

            />
          </FormGroup1>
          <FormGroup1>
            <StyledLabel>
              Area
            </StyledLabel>

            <StyledInput
              disabled
              value={item.area}
              type="text"
              id="create-yaad--title"

              //placeholder="Your Answer"
              onChange={(e) => handleChangeLocation(item, e, "area", index)}
            />
          </FormGroup1>
        </form>
        
      </div>
    );
  };

  React.useEffect(() => {
    dispatch(onboardBulkReset());
    dispatch(onboardIndividualReset());
    dispatch(onboardActivityReset());
    dispatch(onboardCampaignReset());
    dispatch(onboardOrganizationReset());
  }, []);

  React.useEffect(() => {
    if (locationPath?.state) {
      setOrgValues({
        titleOfOrganization: locationPath?.state?.organization,
        totalStaff: locationPath?.state?.noofemployees,
        headquarters: locationPath?.state?.orghq,
        registeredAs: locationPath?.state?.registeredas,
        website: locationPath?.state?.website,
        emailId: locationPath?.state?.emailid,
        chiefFunctionary: locationPath?.state?.chieffunctionary,
        contactNo: locationPath?.state?.contactnumber,
        orgFocusArea: locationPath?.state?.orgfocusarea,
        totalNumberofVolunteers: locationPath?.state?.noofvol,
        organizationLogo: locationPath?.state.organizationlogo,
      });
      setLocation(locationPath?.state?.location);
      // setFocusArea(JSON.parse(locationPath?.state?.rewardstobeearned))
    }
  }, [locationPath?.state]);

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Organization Profile
            </div>
            <form autoComplete="off">
              <div
                style={{
                  border: "1px solid #dadce0",
                  borderRadius: 8,
                  padding: "13px 30px 40px 30px",
                  backgroundColor: "#fff",
                }}
              >

                <br></br>

                <div class="list-img">
                  <img
                    src={orgValues?.organizationLogo}
                    style={{
                      // width: 190,
                      zIndex: 99999,
                      marginTop: 5,
                      maxHeight: 90,
                      // marginLeft: 605,
                      alignSelf: "center",
                      // position: "fixed",
                      // display: "flex",
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <FormGroup>
                    <StyledLabel>
                      Name of Organisation
                    </StyledLabel>
                    <StyledInput
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                      value={orgValues?.titleOfOrganization}
                    />
                  </FormGroup>

                  <FormGroup>
                    <StyledLabel>
                      Headquarter's
                    </StyledLabel>
                    <StyledInput

                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                      value={orgValues?.headquarters}
                    />
                    {/* <span> {orgValues?.headquarters} </span>
                    </StyledInput> */}
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel>
                      Website
                    </StyledLabel>
                    <StyledInput
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                      value={orgValues?.website}
                    />

                  </FormGroup>
                  <FormGroup>
                    <StyledLabel>
                      Name of Chief Functionary
                    </StyledLabel>

                    <StyledInput

                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                      value={orgValues?.chiefFunctionary}
                    />

                  </FormGroup>
                  <FormGroup>
                    <StyledLabel>
                      Contact Number
                    </StyledLabel>
                    <StyledInput

                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                      value={orgValues?.contactNo}
                    />
                    {/* <span> {orgValues?.contactNo} </span>
                      <span style={{ marginLeft: 4 }}>
                        {unitOfCampaign?.[0]?.unit}
                      </span>
                    </div> */}
                  </FormGroup>

                  <FormGroup>
                    <StyledLabel>
                      Email Id
                    </StyledLabel>
                    <StyledInput

                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                      value={orgValues?.emailId}
                    />

                  </FormGroup>

                  <FormGroup>
                    <StyledLabel>
                      Total Staff
                    </StyledLabel>

                    <StyledInput

                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                      value={orgValues?.totalStaff}
                    />

                  </FormGroup>

                  <FormGroup>
                    <StyledLabel>
                      Total No. of Changemakers
                    </StyledLabel>
                    <StyledInput
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                      value={orgValues?.totalNumberofVolunteers}
                    />

                  </FormGroup>
                  <FormGroup style={{ width: "100%" }}>
                    <StyledLabel>
                      Focus Thematic Areas
                    </StyledLabel>
                    <div style={{
                      border: 'none',
                      padding: '10px',
                      display: 'block',
                      borderRadius: '6px',
                      height: '54px',
                      boxShadow: '0px 3px 6px #00000029',
                    }}>
                      {orgValues?.orgFocusArea?.map((item, index) => (
                        <React.Fragment key={index}>
                          <span>
                            {item?.focus}
                            {index < orgValues.orgFocusArea.length - 1 ? ', ' : ''}
                          </span>
                        </React.Fragment>
                      ))}
                    </div>
                  </FormGroup>
                  {location.map((item, index) => (
                    <div key={index}>{getAddress(item, index)}</div>
                  ))}
                </div>
              </div>
            </form>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
}
export default OrganizationViewScreen;
