import React from "react";
// import './main.scss';
import _ from "lodash";
// import API from '../../services';
// import { Progress } from 'reactstrap';
import { useState } from "react";
import uplodIcon from "../assets/upload.png";
import { getCampDocs } from "../redux/action/campFilesAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import FileUploadImg from "../assets/file-up.png";
import { Button } from "@mui/material";
import { isEmpty } from "lodash";

const UploadDocsParticipate = (props) => {
  const dispatch = useDispatch();
  const locationPath = useLocation();  
  const [files, setFiles] = useState();
  const [fileSize, setFileSize] = useState(true);
  const [fileUploadProgress, setFileUploadProgress] = useState(false);
  const [fileUploadResponse, setFileUploadResponse] = useState(null);
  const {
    isCampDocSuccess,
    campDocResponse,
    isCampDocError,
  } = useSelector((state) => state.getCampDocs);

  const handleChange = (e) => {
    setFiles(e.target.files);
  };

  const handleUpload = () => {
    if (isEmpty(files)) {
      setFileUploadResponse("Please select file(s) to upload");
      console.log("EMPTY")
      return;
    }
 
    setFileSize(true);
    setFileUploadProgress(true);
    setFileUploadResponse(null);
    let formData = new FormData();    
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 51200000) {
        setFileSize(false);
        setFileUploadProgress(false);
        setFileUploadResponse(null);
        return;
      }

      formData.append(`files`, files[i]);
    
    }
    dispatch(
      getCampDocs({
        campid: props.camp,
        userId: localStorage.getItem("userid"),
        formData,
      })
    );
    setFileUploadProgress(false);
    setFileUploadResponse("File(s) uploaded");

    props.onFileUpload(true);
    
  };


  return (
    <div class="cyagg-sec-space">
      <div class="container">
        <div class="row" style={{justifyContent:"left"}}>
          <div class="col-md-6" style={{marginBottom:"40px"}}>
            <div>
              <div class="filecard-participate" style={{padding:"0px 0px 0px 0px", boxShadow: "0 0px 0px 0"}}>
                {/* <h4>Upload Documents</h4> */}
                <div class="drop_box" style={{padding:"0px 0px 0px 0px"}}>
                  {/* <header>
                    <h4>Select Doc here</h4>
                  </header> */}
                  <br />
                  {/* <p>Files Supported: PDF, TEXT, DOC , DOCX</p> */}
                  <input
                    type="file"
                    // hidden
                    accept=".doc,.docx,.pdf"
                    id="fileID"
                    // style={{ color:"orange", display: "none" }}
                    // style={{ color:"orange", backgroundColor:"orange" }}
                    multiple
                    onChange={handleChange}
                    placeholder="Select File and Upload"
                    // class="btn"
                  />
                  <button class="btn" style={{width:"38%"}} onClick={handleUpload}>
                    Upload
                  </button>
                  {/* <button class="btn">Choose File</button> */}
                </div>
                
                {!fileSize && (
                  <p style={{ color: "red" }}>File size exceeded 50MB!!</p>
                )}
                {fileUploadProgress && (
                  <p style={{ color: "orange" }}>Uploading File(s)</p>
                )}
                {fileUploadResponse != null && (
                  <p style={{ color: "green" }}>{fileUploadResponse}</p>
                )}
                
              </div>
              
            </div>
          </div>
          <div class="col-md-12">
            <div class="text-center">
              {/* <img
                src={FileUploadImg}
                class="img-fluid mob-space1 hide-on-mobile"
                style={{ width: "300px" }}
              /> */}
              {/* <p style={{ textAlign: "justify", fontSize: 12 }}>
                1. Please upload documents or scanned copies as required by the
                campaign. You can view your uploaded documents in <a href= "/myongoingcampaignspvt">Campaign Documents </a>
                 section
              </p> */}
              <p style={{ textAlign: "justify", fontSize: 12 }}>
                1. Please upload documents or scanned copies as required by the
                campaign. You can view your uploaded documents in <b>Campaign Documents </b> section
              </p>
              <p style={{ textAlign: "justify", fontSize: 12 }}>
                2. Please ensure that file size is less than 50 MB
              </p>
              <p style={{ textAlign: "justify", fontSize: 12 }}>
                3. Your uploaded documents will be visible only to you and your organization Admin/
                Campaign Owner
              </p>
            </div>
          </div>
          {/* {campFilesSuccess && <span>Files uploaded</span>} */}
        </div>
      </div>
    </div>
  );
};

export default UploadDocsParticipate;
