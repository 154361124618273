import React from 'react';

const SVG7 = (props) => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
<rect x="15" y="15.5" width="15" height="15" transform="rotate(180 15 15.5)" fill="url(#pattern0_140_1364)"/>
<defs>
<pattern id="pattern0_140_1364" patternContentUnits="objectBoundingBox" width="1" height="1">
<use xlinkHref="#image0_140_1364" transform="scale(0.02)"/>
</pattern>
<image id="image0_140_1364" width="50" height="50" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAe0lEQVR4nO3YsQmAUAxF0ds7SHALEfffQsRebLVRcIQk3gMZ4PHyix+QJElSByMwU1wAK3ACC8VDXM+UDDMC2yfEOzswULSJ65kDmCgiDJGETWQRvokkwiYSiQ5tvAyTVbhmSYXNJBU2k1SLr26r40Orc1CrA50kSdIf3aq6iiOdOpHBAAAAAElFTkSuQmCC"/>
</defs>
</svg>

);

export default SVG7;
