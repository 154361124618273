
// App.js
import React, { useState, useRef } from 'react';
import { Button, Container, Grid, Typography, Box } from '@mui/material';
import FreeFormTextBox from './FreeFormTextBox';
import SingleSelectQuestion from './SingleSelectQuestion';
import MultiSelectQuestion from './MultiSelectQuestion';
import Multiselect from "multiselect-react-dropdown";
import DateOfBirthQuestion from './DateOfBirthQuestion';
import { Modal } from "react-bootstrap";
import { MenuItem, Select, TextField } from "@mui/material";
import Message from './Message';
import {
  onboardRegForm,
  onboardRegFormReset,
  regFormResponse,
  isRegFormSuccess,
  isRegFormError,
  isRegFormFetching,
} from '../../redux/action/OnboardAction';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import TopHeaderScreen from "../../components/TopHeader1";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import UploadFiles from './UploadFiles';
import { isEmpty, isArray } from "lodash";
import DatePicker from 'react-datepicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Reorder } from 'framer-motion';
import UploadDocsParticipate from "../../components/UploadDocsParticipate";

const RegForm = (props) => {
  const modalRef = useRef(null);
  const [questions, setQuestions] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [campName, setCampName] = React.useState([]);
  const [selectedcampname, setselectedcampname] = React.useState([]);
  const [showSnapBar, setShowSnapBar] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [doesFormExist, setDoesFormExist] = React.useState([]);
  const fieldRef1 = React.useRef();
  const [showPreviewModal, setShowPrviewModal] = React.useState(false);

  const [indexValue, setIndexValue] = React.useState(false);

  const [idCounter, setIdCounter] = useState(1);
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const showSnackbar = (message) => {
    // You can customize the Snackbar appearance and behavior here
    setSnackbarOpen(true);
  };

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );


  const {
    regFormResponse,
    isRegFormSuccess,
    isRegFormError,
    isRegFormFetching,
  } = useSelector((state) => state.onboardRegForm);

  // const addQuestion = (question) => {
  //   setQuestions([...questions, { ...question }]);
  // };
  const addQuestion = (question) => {
   const newId = idCounter; 
    setIdCounter(idCounter + 1);
    if (question.type === 'singleSelect') {

      setQuestions([...questions, { ...question, choices: [],id: newId }]);
    } else {
      setQuestions([...questions, { ...question,id: newId }]);
    }

    const element = document.getElementById('scrollToViewEnd');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };


  // const removeQuestion = (index) => {
  //   const updatedQuestions = [...questions];
  //   console.log("index = ", index);
  //   console.log("updatedQuestions before = ", updatedQuestions);
  //   updatedQuestions.splice(index, 1);
  //   setQuestions(updatedQuestions);
  //   console.log("updatedQuestions after = ", updatedQuestions);
  // };

  const removeQuestion = (index) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      console.log("index = ", index);
      console.log("updatedQuestions before = ", updatedQuestions);
      updatedQuestions.splice(index, 1);
      console.log("updatedQuestions after = ", updatedQuestions);
      return updatedQuestions;
    });
  };


  const isFormValid = () => {
    return questions.every(obj => {
        if (obj.type === "singleSelect" || obj.type === "multiSelect") {
            if (obj.text === "" || obj.choices.length === 0 || (obj.choices[obj.choices.length - 1]?.choices?.length || 0) === 0) {
                return false;
            } else {
                return true;
            }
        } else {
            if (obj.text === "") {
                return false;
            } else {
                return true;
            }
        }
    });
};

  const handlePreview = () => {
    setShowPrviewModal(true);
  }

  const handleSubmit = () => {
    // Handle the submission logic here
    // console.log('Form submitted:', questions);
console.log("===valuiiid==",isFormValid())
    if (!isFormValid()) {
      // Show an error message or handle validation error as needed
      console.error('Please fill in all the components before submitting.');
      showSnackbar('Please enter all fields');
      setShowSnapBar(false);
      // showModal(false);
      // dispatch(onboardRegFormReset());

      return;
    } else {
      console.log('Form submitted: Questions', questions);
      let formData = new FormData();
      formData.append("title", JSON.stringify(questions));
      formData.append("campname", JSON.stringify(selectedcampname));
      dispatch(
        onboardRegForm({ userid: localStorage.getItem("userid"), formData })
      );
      setShowSnapBar(true);
    }

  };

  const updateQuestionText = (index, newText) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].text = newText;
    setQuestions(updatedQuestions);
  };


  React.useEffect(() => {
    if (isRegFormSuccess) {
      
      setShowSnapBar(true);
      setTimeout(() => {
        setShowSnapBar(false);
        
        dispatch(onboardRegFormReset());
        setselectedcampname([]);
        setQuestions([]);
      }, 3000);
    }
  }, [isRegFormSuccess]);

  React.useEffect(() => {
    getCampName();
  }, []);


  const getCampName = async () => {
    try {
      const res = await axios.get(
        `/camp/for/regform/${profileResponse?.phoneno}`
      );
      setCampName(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  React.useEffect(() => {
    getCampExistence();
  }, [selectedcampname]);


  const getCampExistence = async () => {
    try {
      if (!isEmpty(selectedcampname)) {
        const res = await axios.get(
          `/camp/existence/regform/${selectedcampname[0]["label"]}`
        );
        setDoesFormExist(res?.data);

        return res;
      }
    } catch (err) {
      throw err;
    }
  };

  const handleDragStart = (e) => {
    setIndexValue(false);
  };

  
  const clickEvent = (e) => {
    console.log("clickEventTrigger");
    setIndexValue(false);
  };

  const handleDragEnd = (e) => {
    setIndexValue(true);
  };

  const handleClick = (e) => {
    setIndexValue(true);
   };

   const handleTouchMove = () => {
    setIndexValue(true);
  };

  const handleTouchStart = () => {
    setIndexValue(true);
  };

  const handleTouchEnd = () => {
    setIndexValue(false);
  };

  const renderHTML = (htmlContent) => {
    return { __html: htmlContent };
  };

  const loadOptions = (searchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = campName.filter((category) =>
        category.label.toLowerCase().includes(searchValue.toLowerCase())
      );
      callback(filteredOptions);
    });
  };

  const closePreviewModalWindow = () => {
    console.log("in closePreviewModalWindow");
    setShowPrviewModal(false);
  };

  const colorStyles = {
    control: (styles) => ({ ...styles }),

    multiValue: (styles, { data }) => {
      return { ...styles, backgroundColor: "#0096FF", color: "#fff" };
    },
    multiValueLabel: (styles, { data }) => {
      return { ...styles, color: "#fff" };
    },
    multiValueRemove: (styles, { data }) => {
      return {
        ...styles,
        color: "#fff",
        cursor: "pointer",
        ":hover": {
          color: "#fff",
        },
      };
    },

  };


  return (
    <div className="wrapper" id="scrollToViewEnd">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <Container>
              <Typography variant="h4" align="center" gutterBottom>
                Design Registration Form
              </Typography>
              <div
                class="form-reg-wrap"
                id=""
                role="tabpanel"
                aria-labelledby="nav-places-tab"
              >
                <h6>Select a Campaign</h6>
                <form action="#" method="get">
                  <div
                    style={{
                      // padding: "0px 30px 0px 0px",
                      padding: "0px 0px 0px 0px",
                      width: "300px",
                      marginBottom: "5px",
                      maxWidth: "100%",
                    }}
                  >
                    <AsyncSelect
                      loadOptions={loadOptions}
                      defaultOptions={campName}
                      value={selectedcampname}
                      isMulti
                      placeholder="Select Campaign"
                      isOptionDisabled={() => selectedcampname.length >= 1}
                      styles={colorStyles}
                      onChange={(event) => {
                        setselectedcampname(event);

                      }}
                    />
                  </div>
                </form>
              </div>

              {
                !isEmpty(selectedcampname) && !isEmpty(doesFormExist) && doesFormExist[0]["formexists"] === "no" ? (
                  <Box overflowX="auto">
                    <marquee behavior="scroll" direction="left" style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px', color: 'red' }}>
                      The order and sequence of questions on the registration form will appear in the same order in which they are designed here
                    </marquee>

                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      style={{
                        border: '1px solid #ddd',
                        padding: '6px 22px 15px 0px',
                        borderRadius: '8px',
                        marginLeft: 0,
                        marginTop: 6,
                        width: '100%',
                        textAlign: 'center',
                        flexWrap: 'nowrap', // Ensure no wrapping of child items
                        overflowX: 'auto',  // Allow horizontal scrolling
                      }}
                    >
                      <Grid item xs={12} sm={6} md={3}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => addQuestion({type: 'freeForm', text: '' })}
                          // fullWidth
                          // style={{ width: "180px" }}
                          style={{
                            width: '180px',
                            // background: '#4CAF50', // Green color as an example
                            // background: '#4', // Green color as an example
                            color: 'white',
                            // border: '2px solid #45a049', // Border color on normal state
                            borderRadius: '4px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            letterSpacing: '2px',
                            transition: 'background 0.3s ease-in-out',
                            '&:hover': {
                              background: '#45a049',
                              border: '2px solid #4CAF50'
                            },
                          }}

                        >
                          Text Box
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6} md={2.5}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => addQuestion({ type: 'singleSelect', text: '', choices: [] })}
                          // fullWidth
                          style={{
                            width: '180px',
                            // background: '#4CAF50', // Green color as an example
                            // background: '#4', // Green color as an example
                            color: 'white',
                            borderRadius: '4px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            letterSpacing: '2px',
                            transition: 'background 0.3s ease-in-out',
                            '&:hover': {
                              background: '#45a049', // Darker green on hover
                            },
                          }}
                        >
                          Single Select
                        </Button>
                      </Grid>

                      <Grid item xs={12} sm={6} md={2.5}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => addQuestion({ type: 'multiSelect', text: '', choices: [] })}
                          // fullWidth
                          style={{
                            width: '180px',
                            // background: '#4CAF50', // Green color as an example
                            // background: '#4', // Green color as an example
                            color: 'white',
                            borderRadius: '4px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            letterSpacing: '2px',
                            transition: 'background 0.3s ease-in-out',
                            '&:hover': {
                              background: '#45a049', // Darker green on hover
                            },
                          }}
                        >
                          Multi Select
                        </Button>
                      </Grid>

                      <Grid item xs={12} sm={6} md={2.5}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => addQuestion({ type: 'dateOfBirth', text: '' })}
                          // fullWidth
                          style={{
                            width: '180px',
                            // background: '#4CAF50', // Green color as an example
                            // background: '#4', // Green color as an example
                            color: 'white',
                            borderRadius: '4px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            letterSpacing: '2px',
                            transition: 'background 0.3s ease-in-out',
                            '&:hover': {
                              background: '#45a049', // Darker green on hover
                            },
                          }}
                        >
                          Calendar
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => addQuestion({ type: 'message', text: '' })}
                          // fullWidth
                          style={{
                            width: '156px',
                            // background: '#4CAF50', // Green color as an example
                            // background: '#4', // Green color as an example
                            color: 'white',
                            borderRadius: '4px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            letterSpacing: '2px',
                            transition: 'background 0.3s ease-in-out',
                            '&:hover': {
                              background: '#45a049', // Darker green on hover
                            },
                          }}
                        >
                          Message
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => addQuestion({ type: 'uploadFiles', text: '' })}
                          // fullWidth
                          style={{
                            width: '180px',
                            // background: '#4CAF50', // Green color as an example
                            // background: '#4', // Green color as an example
                            color: 'white',
                            borderRadius: '4px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            letterSpacing: '2px',
                            transition: 'background 0.3s ease-in-out',
                            '&:hover': {
                              background: '#45a049', // Darker green on hover
                            },
                          }}
                        >
                          Upload Files
                        </Button>
                      </Grid>
                    </Grid>
                    <main className='dragDropWrapper' style={{pointerEvents: "none"}}>
                    <Reorder.Group values={questions} onReorder={setQuestions}>
                    {questions.map((question, index) => (
                      // <div key={question.text} className="secondaryWrapper">
                      // <div key={index} class="secondaryWrapper">
                     
                     <Reorder.Item value={question} key={question.id} style={{position:"relative"}}>
                      <div 
                      className="task" class="contCover">
                       {/* <h1>{JSON.stringify(index)}</h1> */}
                        {/* {JSON.stringify({question})} */}
                        {question.type === 'freeForm' && (
                          <div className={question.text === '' ? "secondaryWrapper errorMessage":"secondaryWrapper"}>
                          <FreeFormTextBox
                            // key={index}
                            // key={question.questionid}
                            key={`${index}-${question.questionid}`}
                            // key={`Label for Free Form Input ${index}`}
                            index={index}

                            // removeQuestion={() => removeQuestion(index)}
                            removeQuestion={removeQuestion}
                            updateQuestionText={updateQuestionText}
                            label={`Label for Free Form Input ${index}`}
                            question={question}
                            className="kumaran"
                          />
                          {/* <i class="fa-solid fa-up-down-left-right"></i> */}
                          <div style={{width: "18px",position: "absolute",top: "5px",left: "50%",zIndex:"1",pointerEvents: "auto"}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grip-horizontal" viewBox="0 0 16 16">
                            <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                          </svg>
                          </div>
                          </div>
                        )}
                        {question.type === 'singleSelect' && (
                          <div className={question.text === '' || question.choices.length <= 0 || question.choices[question.choices.length - 1]?.choices.length <= 0 ? "secondaryWrapper errorMessage" : "secondaryWrapper"}>
                           
                          <SingleSelectQuestion
                            // key={question.text}
                            key={`${index}-${question.questionid}`}
                            index={index}
                            text={question.text}
                            choices={question.choices}
                            // addChoice={(choice) => {
                            //   question.choices.push(choice);
                            //   setQuestions([...questions]);
                            // }}
                            addChoice={(newChoice) => {
                              const updatedQuestions = [...questions];
                              updatedQuestions[index].choices.push(newChoice);
                              setQuestions(updatedQuestions);
                            }}
                            // removeChoice={(choiceIndex) => {
                            //   const updatedChoices = [...question.choices];
                            //   updatedChoices.splice(choiceIndex, 1);
                            //   question.choices = updatedChoices;
                            //   setQuestions([...questions]);
                            // }}
                            removeChoice={(choiceIndex,updatedChoice) => {
                              const updatedChoices = [...question.choices];
                             updatedChoices.push({ type:question.type, text:question.text, choices: updatedChoice });
                              question.choices = updatedChoices;
                              setQuestions([...questions]);
                            }}
                            removeQuestion={() => {
                              setQuestions((prevQuestions) => {
                                const updatedQuestions = [...prevQuestions];
                                updatedQuestions.splice(index, 1);
                                console.log("====questions",question);
                                console.log("updatedQuestions in removeQuestion = ", updatedQuestions);
                                return updatedQuestions;
                              });
                            }}
                            updateQuestionText={updateQuestionText}
                            question={question}
                            label={`Label for Single Select Form Input ${index}`}
                          />
                           <div style={{width: "18px",position: "absolute",top: "5px",left: "50%",zIndex:"1",pointerEvents: "auto"}}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grip-horizontal" viewBox="0 0 16 16">
                            <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                          </svg>
                          </div>
                          </div>
                        )}
                        {question.type === 'multiSelect' && (
                          <div className={(question.text === '' || ((question.choices.length <= 0) || question.choices[question.choices.length - 1]?.choices.length <= 0)) ? "secondaryWrapper errorMessage" : "secondaryWrapper"}>
                          <MultiSelectQuestion
                            // key={question.text}
                            key={`${index}-${question.questionid}`}
                            index={index}
                            text={question.text}
                            choices={question.choices}
                            addChoice={(newChoice) => {
                              const updatedQuestions = [...questions];
                              updatedQuestions[index].choices.push(newChoice);
                              setQuestions(updatedQuestions);
                            }}
                            removeChoice={(choiceIndex,updatedChoice) => {
                              const updatedChoices = [...question.choices];
                             updatedChoices.push({ type:question.type, text:question.text, choices: updatedChoice });
                              question.choices = updatedChoices;
                              setQuestions([...questions]);
                            }}
                           
                            removeQuestion={() => {
                              setQuestions((prevQuestions) => {
                                const updatedQuestions = [...prevQuestions];
                                updatedQuestions.splice(index, 1);
                                console.log("====questions",question);
                                console.log("updatedQuestions in removeQuestion = ", updatedQuestions);
                                return updatedQuestions;
                              });
                            }}
                            updateQuestionText={updateQuestionText}
                            question={question}
                            label={`Label for Multi Select Form Input ${index}`}
                          />
                           <div style={{width: "18px",position: "absolute",top: "5px",left: "50%",zIndex:"1",pointerEvents: "auto"}}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grip-horizontal" viewBox="0 0 16 16">
                            <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                          </svg>
                          </div>
                          </div>
                        )}
                        {question.type === 'dateOfBirth' && (
                          <div className={question.text === '' ? "secondaryWrapper errorMessage":"secondaryWrapper"}>
                          <DateOfBirthQuestion
                            // key={question.text}
                            key={`${index}-${question.questionid}`}
                            index={index}
                            removeQuestion={() => removeQuestion(index)}
                            updateQuestionText={updateQuestionText}
                            label={`Label for Calendar Form Input ${index}`}
                            question={question}
                          />
                           <div style={{width: "18px",position: "absolute",top: "5px",left: "50%",zIndex:"1",pointerEvents: "auto"}}>
                          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M278.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-64 64c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8h32v96H128V192c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V288h96v96H192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l64 64c12.5 12.5 32.8 12.5 45.3 0l64-64c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8H288V288h96v32c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6v32H288V128h32c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-64-64z"/></svg> */}
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grip-horizontal" viewBox="0 0 16 16">
                            <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                          </svg>
                          </div>
                          </div>
                        )}
                        {question.type === 'message' && (
                         <div className={question.text === '' ? "secondaryWrapper errorMessage":"secondaryWrapper"}>
                          <Message
                            // key={question.text}
                            index={index}
                            removeQuestion={() => removeQuestion(index)}
                            updateQuestionText={updateQuestionText}
                            question={question}
                          />
                           <div style={{width: "18px",position: "absolute",top: "5px",left: "50%",zIndex:"1",pointerEvents: "auto"}}>
                           
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grip-horizontal" viewBox="0 0 16 16">
                            <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                          </svg>
                          </div>
                          </div>
                        )}
                        {question.type === 'uploadFiles' && (
                          <div className={question.text === '' ? "secondaryWrapper errorMessage":"secondaryWrapper"}>
                          <UploadFiles
                            // key={question.text}
                            key={`${index}-${question.questionid}`}
                            index={index}
                            removeQuestion={() => removeQuestion(index)}
                            updateQuestionText={updateQuestionText}
                            label={`Label for Upload Files Form Input ${index}`}
                            question={question}
                          />
                           <div style={{width: "18px",position: "absolute",top: "5px",left: "50%",zIndex:"1",pointerEvents: "auto"}}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grip-horizontal" viewBox="0 0 16 16">
                            <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                          </svg>
                          </div>
                          </div>
                        )}
                       
                      </div>
                      </Reorder.Item>
                     
                    ))}
                    </Reorder.Group>
                    </main>

                    {/* Active form field in bottom */}
                    {questions.length > 0 && (
                      <div>
                        <Grid
                          container
                          spacing={1}
                          direction="row"
                          style={{
                            border: '1px solid #ddd',
                            padding: '6px 22px 15px 0px',
                            borderRadius: '8px',
                            marginLeft: 0,
                            marginTop: 6,
                            width: '100%',
                            textAlign: 'center',
                            flexWrap: 'nowrap', // Ensure no wrapping of child items
                            overflowX: 'auto',  // Allow horizontal scrolling
                          }}
                        >
                          <Grid item xs={12} sm={6} md={3}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => addQuestion({ type: 'freeForm', text: '' })}
                              // fullWidth
                              // style={{ width: "180px" }}
                              style={{
                                width: '180px',
                                // background: '#4CAF50', // Green color as an example
                                // background: '#4', // Green color as an example
                                color: 'white',
                                // border: '2px solid #45a049', // Border color on normal state
                                borderRadius: '4px',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                letterSpacing: '2px',
                                transition: 'background 0.3s ease-in-out',
                                '&:hover': {
                                  background: '#45a049',
                                  border: '2px solid #4CAF50'
                                },
                              }}

                            >
                              Text Box
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={2.5}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => addQuestion({ type: 'singleSelect', text: '', choices: [] })}
                              // fullWidth
                              style={{
                                width: '180px',
                                // background: '#4CAF50', // Green color as an example
                                // background: '#4', // Green color as an example
                                color: 'white',
                                borderRadius: '4px',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                letterSpacing: '2px',
                                transition: 'background 0.3s ease-in-out',
                                '&:hover': {
                                  background: '#45a049', // Darker green on hover
                                },
                              }}
                            >
                              Single Select
                            </Button>
                          </Grid>

                          <Grid item xs={12} sm={6} md={2.5}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => addQuestion({ type: 'multiSelect', text: '', choices: [] })}
                              // fullWidth
                              style={{
                                width: '180px',
                                // background: '#4CAF50', // Green color as an example
                                // background: '#4', // Green color as an example
                                color: 'white',
                                borderRadius: '4px',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                letterSpacing: '2px',
                                transition: 'background 0.3s ease-in-out',
                                '&:hover': {
                                  background: '#45a049', // Darker green on hover
                                },
                              }}
                            >
                              Multi Select
                            </Button>
                          </Grid>

                          <Grid item xs={12} sm={6} md={2.5}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => addQuestion({ type: 'dateOfBirth', text: '' })}
                              // fullWidth
                              style={{
                                width: '180px',
                                // background: '#4CAF50', // Green color as an example
                                // background: '#4', // Green color as an example
                                color: 'white',
                                borderRadius: '4px',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                letterSpacing: '2px',
                                transition: 'background 0.3s ease-in-out',
                                '&:hover': {
                                  background: '#45a049', // Darker green on hover
                                },
                              }}
                            >
                              Calendar
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={2}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => addQuestion({ type: 'message', text: '' })}
                              // fullWidth
                              style={{
                                width: '156px',
                                // background: '#4CAF50', // Green color as an example
                                // background: '#4', // Green color as an example
                                color: 'white',
                                borderRadius: '4px',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                letterSpacing: '2px',
                                transition: 'background 0.3s ease-in-out',
                                '&:hover': {
                                  background: '#45a049', // Darker green on hover
                                },
                              }}
                            >
                              Message
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={2}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => addQuestion({ type: 'uploadFiles', text: '' })}
                              // fullWidth
                              style={{
                                width: '180px',
                                // background: '#4CAF50', // Green color as an example
                                // background: '#4', // Green color as an example
                                color: 'white',
                                borderRadius: '4px',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                letterSpacing: '2px',
                                transition: 'background 0.3s ease-in-out',
                                '&:hover': {
                                  background: '#45a049', // Darker green on hover
                                },
                              }}
                            >
                              Upload Files
                            </Button>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} className="submit-button-container" style={{ marginInline: "auto", marginTop: "10px", width: "100%", textAlign: "center", marginTop: "30px",paddingBottom:100 }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handlePreview}
                            fullWidth
                            className="btn save-btn"
                            disableElevation
                            style={{ margin: "0px", marginRight: "10px", width: "auto" }}
                          >
                            <span>Preview</span>
                          </Button>

                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmit}
                            fullWidth
                            className="btn save-btn"
                            disableElevation
                            style={{ margin: "0px", width: "auto" }}
                          >
                            <span>Submit</span>
                          </Button>
                        </Grid>
                      </div>
                    )}

                    {showSnapBar && questions.length > 0 && (
                      <div style={{ background: 'green', color: 'white', padding: '10px', marginBottom: '10px',position:'relative' ,bottom:'95px'}}>
                        Registration form created.
                      </div>
                    )}


                  </Box>
                 ) : isEmpty(selectedcampname) ? ""  
                    : <div style={{ textAlign: "center" }}><span >Registration form is already designed for this campaign. For any changes, please contact support@cyaag.com</span></div>
                }      
            </Container>

            <Modal
              show={showPreviewModal}
              onHide={() => closePreviewModalWindow()}
            >
              <Modal.Header>
                <Modal.Title>Form Preview</Modal.Title>
                <button
                  type="button"
                  onClick={() => closePreviewModalWindow()}
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </Modal.Header>
              <Modal.Body style={{ maxHeight: '600px', overflowY: 'scroll' }}>
                {questions.map((formItem, index) => (
                  <div key={index} class="freeFormGenerator">
                    {formItem.type === 'freeForm' ? (
                      <div>
                        {/* <label style={{ display: "block" }}>{[`Q${index + 1}`]}. {formItem.text}</label> */}
                        <label style={{ display: "block" }}>{formItem.text}</label>
                        <input
                          type="text"
                          id={`create-yaad--title-${index}`}
                          placeholder="Type"
                          style={{ marginBottom: 10 }}
                          value=""
                        />
                      </div>
                    ) : formItem.type === 'singleSelect' ? (
                      <div>
                        {/* <label style={{ display: "block" }}>{[`Q${index + 1}`]}. {formItem.text}</label> */}
                        <label style={{ display: "block" }}> {formItem.text}</label>
                        <Select
                          style={{
                            width: "100%",
                            fontSize: 14,
                            height: 48,
                            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                            border: "1px solid #ccc",
                            color: "#8a97a0",
                            backgroundColor: "#e8eeef",
                            marginBottom: "16px",
                            pointerEvents: "auto"
                          }}
                          value=""
                          placeholder="Select"
                        >
                          {formItem.choices.map((item, choiceIndex) => (
                            choiceIndex === formItem.choices.length - 1 ? (
                              item.choices.map((option, index) => (
                                <MenuItem key={index} style={{ fontSize: 16, overflow: "auto" }} value={option}>
                                  {option}
                                </MenuItem>
                              ))
                            ) : null
                          ))}
                        </Select>
                      </div>
                    ) : formItem.type === 'multiSelect' ? (
                      <div>
                        <label style={{ display: "block" }}>
                          {/* {[`Q${index + 1}`]}.  {formItem.text} */}
                          {formItem.text}
                        </label>
                        {formItem.choices.map((item, choiceIndex) => (
                          choiceIndex === formItem.choices.length - 1 ? (
                            <Multiselect
                              displayValue="value"  // Set displayValue to the property containing the display values
                              showCheckbox
                              selectedValues=""
                              options={item.choices.map((option, index) => ({ value: option, id: index }))}
                              value=""
                              style={{
                                searchBox: {
                                  minHeight: 54,
                                  paddingTop: 10,
                                  padding: "0px",
                                  border: "0px",
                                },
                              }}
                            />
                          ) : null
                        ))}
                      </div>
                    ) : formItem.type === "dateOfBirth" ? (
                      <div>
                        <label style={{ display: "block" }}>
                          {/* {[`Q${index + 1}`]}. {formItem.text} */}
                          {formItem.text}
                        </label>
                        <DatePicker
                          selected=""
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Select Date"
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={15}
                          timeInputLabel="Time:"
                          showTimeInput={false}
                          customInput={
                            <div
                              className="custom-datepicker-input"
                              style={{
                                background: '#e8eeef',
                                borderRadius: '5px',
                                color: '#8a97a0',
                                padding: '8px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <input
                                style={{
                                  border: 'none',
                                  width: '100%',
                                  outline: 'none',
                                  background: '#e8eeef',
                                }}
                                value=""
                                readOnly
                              />
                              <CalendarMonthIcon style={{ marginLeft: '8px' }} />
                            </div>
                          }
                        />

                      </div>
                    ) : formItem.type === "message" ? (
                      <div>
                        <div dangerouslySetInnerHTML={{ __html: formItem.text }} />
                      </div>
                    ) : formItem.type === "uploadFiles" ? (
                      <div>
                        <label style={{ display: "block" }}>
                          {/* {[`Q${index + 1}`]}. {formItem.text} */}
                          {formItem.text}
                        </label>
                        <UploadDocsParticipate/>
                      </div>
                    ) : null
                    }
                  </div>
                ))}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  style={{
                    // backgroundColor: "#db8d0f",
                    // backgroundColor: "#E9ECEF",
                    backgroundColor: "#A9A9A9",
                    color: "#fff",
                  }}
                  onClick={() => closePreviewModalWindow()}
                >
                  OK
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </section>

      <Snackbar
      className="errorOverlay"
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity="error"
        >
          Please enter all fields
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default RegForm;