/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Login from "../assets/Login.png";
import LoginLogo from "../assets/logo-black.png";
import { socialLogin } from "../redux/action/loginAction";
import { userProfile } from "../redux/action/UserProfileActions";
import { connect, useDispatch, useSelector } from "react-redux";
import "../App.css";
import { MenuItem, Select, Radio, FormLabel, TextField } from "@mui/material";
import { restrictedCountry } from "../utils/dict";
import { socialEmailLogin } from "../redux/action/LoginEmailAction";
import axios from "axios";
import styled from 'styled-components';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
// import {auth} from "../services/FirebaseConfig1";
import { firebaseConfig } from "../services/FirebaseConfig1";
import { initializeApp } from 'firebase/app';
import { Snackbar, Box, Alert } from '@mui/material';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import zIndex from "@mui/material/styles/zIndex";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Optional: Disable app verification for testing purposes (for testing only)
console.log("env = ", process.env.NODE_ENV)
if (process.env.NODE_ENV === 'development') {
  auth.settings.appVerificationDisabledForTesting = true;
  auth.appVerificationDisabledForTesting = true;
}

const PhoneAndEmailButton = styled(Button)({
  borderRadius: '30px !important',
  fontSize: 14,
  padding: 7,
  width: "65%",
  background: "#edc789 !important",
  boxShadow: "none",
  marginTop: "10px !important",
  alignSelf: "center",
  justifyContent: "center",
  '@media (min-width: 960px)': {
    width: "80%",
  },

});

const StyledSelect = styled(Select)(({ theme }) => ({
  width: "36%",
  marginRight: 4,
  "& .MuiOutlinedInput-input": {
    color: "gray",
  },
  "& .MuiInputLabel-root": {
    color: "gray",
  },
  "&:hover .MuiOutlinedInput-input": {
    color: "gray",
  },
  "&:hover .MuiInputLabel-root": {
    color: "gray",
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "gray",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
    color: "gray",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "gray",
  },
}));

const StyledButton = styled(Button)({
  borderRadius: '30px !important',
  fontSize: 14,
  textTransform: "uppercase",
  padding: 15,
  width: "80%",
  background: "#e1a23b !important",
  boxShadow: "none",
  fontWeight: "bold",
  marginTop: "10px !important",
  alignItems: "center",
  justifyContent: "center",
  '@media (min-width: 960px)': {
    width: "80%",
  },
});

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: 'black',
  },
}));

const LoginPgLogo = styled('div')({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (min-width: 960px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
});

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  '&.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: 'black', // Error border color
  },
  '& .MuiOutlinedInput-root': {
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'gray',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'gray',
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'gray',
    },
  },
  '& .Mui-error .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black',
    },
  },
}));

// const firebaseConfig = {
//   apiKey: "AIzaSyBOENeeZH0WC9-8-_86aO4P6MWX-t4ULUI",
//   authDomain: "cyaag-2fcf2.firebaseapp.com",
//   databaseURL: "https://cyaag-2fcf2.firebaseio.com",
//   projectId: "cyaag-2fcf2",
//   storageBucket: "cyaag-2fcf2.appspot.com",
//   messagingSenderId: "393825780552",
//   appId: "1:393825780552:web:bb2c3dae199eba027d297d",
//   measurementId: "G-K1DBGBN0GC",
// };

// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);

const LoginScreen = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [otpAccepted, setOtpAccepted] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [loginPhoneNumber, setLoginPhoneNumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumberisValid, setPhoneNumberisValid] = React.useState(true);
  const [signIn, setSignIn] = React.useState(false);
  const [callingCode, setCallingCode] = React.useState("");
  const [registerCallingCode, setRegisterCallingCode] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [emailTextError, setEmailTextError] = React.useState("");
  const [phoneTextError, setPhoneTextError] = React.useState("");
  const [phoneError, setPhoneError] = React.useState(false);
  // const [loginError, setLoginError] = React.useState(false);
  const [loginError, setLoginError] = React.useState(false);
  const [inHoverLogin, setHoverLogin] = React.useState(false);
  const [loginAuthentication, setLoginSuccess] = React.useState(false);
  const [logintype, setLogintype] = useState("");
  const [phoneerrorcount, setphoneerrorcount] = useState(1);
  const [emailerrorcount, setemailerrorcount] = useState(1);
  const [showRedirectMessage, setShowRedirectMessage] = useState(false);

  const {
    isSuccess: loginSuccess,
    isFetching: loginFetching,
    isError: loginError1,
    response: login,
  } = useSelector((state) => state?.login);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state?.profile
  );
  const {
    isSuccess: emailloginSuccess,
    isFetching: emailloginFetching,
    isError: emailloginError1,
    response: emaillogin,
  } = useSelector((state) => state?.socialEmailLogin);
  const userid = localStorage.getItem("userid");
  // console.log("userid = ", userid);

  useEffect(() => {

    if (logintype === "phoneno" && login.message === "Sorry, you are not registered on platform.") {
      setPhoneTextError("Please enter your registered Phone Number");
      setPhoneError(true);
    } else {
      setPhoneTextError("");
      setPhoneError(false);
    }
  }, [login.message, phoneerrorcount]);

  useEffect(() => {

    if (logintype === "email" && emaillogin.message === "Sorry, you are not registered on platform with this emailid") {
      setEmailTextError("Please enter your registered Email ID");
      setEmailError(true);
    } else {
      setEmailTextError("");
      setEmailError(false);
    }
  }, [emaillogin.message, emailerrorcount]);
  const handleChangeCallingCode = (event) => {
    setCallingCode(event.target.value);
    localStorage.setItem("code", event.target.value);
  };

  const replacedPhoneNumber =
    (registerCallingCode === ""
      ? "91"
      : registerCallingCode.replace(/\+/g, "")) + phoneNumber;
  const registerNumber =
    (registerCallingCode === "" ? "+91" : registerCallingCode) + phoneNumber;
  localStorage.setItem("registerNumber", replacedPhoneNumber);
  const loginNumber =
    (callingCode === "" ? "+91" : callingCode) + loginPhoneNumber;
  localStorage.setItem("loginNumber", loginNumber);
  const replacedLoginPhoneNumber =
    (callingCode === "" ? "91" : callingCode.replace(/\+/g, "")) +
    loginPhoneNumber;
  localStorage.setItem("replacedLoginPhoneNumber", replacedLoginPhoneNumber);
  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    console.log("000000")
    const initializeRecaptcha = async () => {
      if (!window.recaptchaVerifier) {
        try {
          const recaptchaContainer = document.getElementById('recaptcha-container1');
          if (recaptchaContainer) {
            console.log('Recaptcha container found. Initializing RecaptchaVerifier...');
            console.log('Auth object:', auth);
            if (auth) {
              if (auth.app && auth.config && auth.currentUser) {
                console.log('Auth is fully initialized');
              }
              console.log('Auth object keys:', Object.keys(auth));
              console.log('Auth object values:', Object.values(auth));
              if (auth.appVerificationDisabledForTesting) {
                console.log('appVerificationDisabledForTesting is defined');
              } else {
                console.log('appVerificationDisabledForTesting is not defined');
              }

              console.log('About to initialize RecaptchaVerifier...');
              window.recaptchaVerifier = new RecaptchaVerifier(auth,
                'recaptcha-container1',
                {
                  size: 'invisible',
                  callback: (response) => {
                    console.log('reCAPTCHA solved', response);
                  },
                  'expired-callback': () => {
                    console.log('reCAPTCHA expired');
                  },
                },

              );
              console.log('RecaptchaVerifier initialized:', window.recaptchaVerifier);
              await window.recaptchaVerifier.render();
              console.log('RecaptchaVerifier successfully initialized:', window.recaptchaVerifier);
            } else {
              console.error('Auth object is not defined.');
            }
          } else {
            console.error('Recaptcha container element not found.');
          }
        } catch (error) {
          console.error('Error initializing RecaptchaVerifier:', error);
        }
      }
    };

    initializeRecaptcha();
  }, []);

  const handleClickLogin = () => {
    if (!auth) {
      console.error('Firebase Auth is not initialized.');
      return;
    }
    const appVerifier = window.recaptchaVerifier;
    if (!appVerifier) {
      console.error('RecaptchaVerifier is not initialized.');
      return;
    }

    console.log('Signing in with phone number:', loginNumber);
    console.log('App Verifier:', appVerifier);

    signInWithPhoneNumber(auth, loginNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setShowRedirectMessage(true);
        console.log('SMS sent. Confirmation result:', confirmationResult);
        navigate('/otp');
      })
      .catch((error) => {
        console.log('Error during sign in:', error);
        console.log('Error code:', error.code);
        console.log('Error message:', error.message);
        if (error.code === 'auth/invalid-phone-number') {
          setPhoneTextError('Invalid phone number format. Please enter a valid phone number.');
          setPhoneError(true);
        } else {
          setPhoneTextError('Error signing in with phone number. Please try again.');
          setPhoneError(true);
        }
      });
  };

  const handleClickEmailLogin = () => {
    const phoneno = emaillogin.phoneno;
    const profileurl = emaillogin.profileurl;

    localStorage.setItem("userid", phoneno);
    localStorage.setItem("profileurl", profileurl);

    navigate(`/emailverifypr/${phoneno}/${email}`);
  };

  const sendEmailOTP = async () => {
    const phoneno = emaillogin.phoneno;

    const res = await axios.post(`/verifyemail`, {
      phoneno: phoneno,
      email: email,
    });

  };

  useEffect(() => {
    if (
      loginSuccess &&
      login !== [] &&
      login.message !== "Sorry, you are not registered on platform."
    ) {
      // setShowRedirectMessage(true);
      handleClickLogin();
      dispatch(userProfile(replacedLoginPhoneNumber));
    }
  }, [loginSuccess]);

  useEffect(() => {
    if (
      emailloginSuccess &&
      emaillogin !== [] &&
      emaillogin.message !== "Sorry, you are not registered on platform with this emailid"
    ) {

      sendEmailOTP();
      handleClickEmailLogin();
      dispatch(userProfile(email));
    }
  }, [emailloginSuccess]);

  // useEffect(() => {
  //   if (registerNumber) {
  //     // handleClick();
  //   }
  // }, [registerNumber]);


  useEffect(() => {
    if (loginAuthentication) {
      if (localStorage.getItem('referralid') !== null && localStorage.getItem('referedcampid') !== null && localStorage.getItem('reflevel') !== null &&
        localStorage.getItem('referralid') !== "" && localStorage.getItem('referedcampid') !== "" && localStorage.getItem('reflevel') !== "" &&
        localStorage.getItem('referralid') !== "null" && localStorage.getItem('referedcampid') !== "null" && localStorage.getItem('reflevel') !== "null") {
        // if (profileResponse?.organization === "cYAAG Technologies") {
        navigate(`/participate/campaign/${localStorage.getItem('referedcampid')}/${profileResponse?.phoneno}`)
        // }
      } else if (localStorage.getItem('title') !== null && localStorage.getItem('title') !== "" && localStorage.getItem('title') !== "null") {
        navigate(`/participate/camp/${localStorage.getItem('title')}`)
      } else if (profileResponse?.orgrole?.toLowerCase() === "investor") {
        navigate("/create_yaag");
      } else if (profileResponse?.orgrole?.toLowerCase() === "national") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "volunteer") {
        // navigate("/volunteer");
        navigate("/home");
      } else if (profileResponse?.orgrole?.toLowerCase() === "cyaagadmin") {
        navigate("/home");
      } else if (profileResponse?.orgrole?.toLowerCase() === "sponsoradmin") {
        navigate("/home");
      } else if (profileResponse?.orgrole?.toLowerCase() === "admin") {
        navigate("/home");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region1") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region2") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region3") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region4") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region5") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region6") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region7") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region8") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region9") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region10") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region11") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region12") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region13") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region14") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region15") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "campadmin") {
        navigate("/home");
      }
    }
  }, [loginAuthentication, profileSuccess]);

  useEffect(() => {
    if (!userid) {
      navigate("/login");
    } else {
      if (localStorage.getItem('referralid') !== null && localStorage.getItem('referedcampid') !== null && localStorage.getItem('reflevel') !== null &&
        localStorage.getItem('referralid') !== "" && localStorage.getItem('referedcampid') !== "" && localStorage.getItem('reflevel') !== "" &&
        localStorage.getItem('referralid') !== "null" && localStorage.getItem('referedcampid') !== "null" && localStorage.getItem('reflevel') !== "null") {
        navigate(`/participate/campaign/${localStorage.getItem('referedcampid')}/${profileResponse?.phoneno}`)
      } else if (localStorage.getItem('title') !== null && localStorage.getItem('title') !== "" && localStorage.getItem('title') !== "null") {
        navigate(`/participate/camp/${localStorage.getItem('title')}`)
      } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "investor") {
        navigate("/create_yaag");
      } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "national") {
        navigate("/exec");
      } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "volunteer") {
        // navigate("/volunteer");
        navigate("/home");
      } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "admin") {
        navigate("/home");
      } else if (
        profileResponse?.[0]?.orgrole?.toLowerCase() === "cyaagadmin"
      ) {
        navigate("/home");
      } else if (profileResponse?.orgrole?.toLowerCase() === "sponsoradmin") {
        navigate("/home");
      } else if (profileResponse?.orgrole?.toLowerCase() === "campadmin") {
        navigate("/home");
      }
    }
  }, [userid]);

  useEffect(() => {
    if (profileSuccess) {
      if (localStorage.getItem('referralid') !== null && localStorage.getItem('referedcampid') !== null && localStorage.getItem('reflevel') !== null &&
        localStorage.getItem('referralid') !== "" && localStorage.getItem('referedcampid') !== "" && localStorage.getItem('reflevel') !== "" &&
        localStorage.getItem('referralid') !== "null" && localStorage.getItem('referedcampid') !== "null" && localStorage.getItem('reflevel') !== "null") {
        navigate(`/participate/campaign/${localStorage.getItem('referedcampid')}/${profileResponse?.phoneno}`)
      } else if (localStorage.getItem('title') !== null && localStorage.getItem('title') !== "" && localStorage.getItem('title') !== "null") {
        navigate(`/participate/camp/${localStorage.getItem('title')}`)
      } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "investor") {
        navigate("/create_yaag");
      } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "national") {
        navigate("/exec");
      } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "volunteer") {
        // navigate("/volunteer");
        navigate("/home");
      } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "admin") {
        navigate("/home");
      } else if (
        profileResponse?.[0]?.orgrole?.toLowerCase() === "cyaagadmin"
      ) {
        navigate("/home");
      } else if (profileResponse?.orgrole?.toLowerCase() === "sponsoradmin") {
        navigate("/home");
      } else if (profileResponse?.orgrole?.toLowerCase() === "campadmin") {
        navigate("/home");
      }
    }
    // else{
    //   navigate("/login");
    // }
  }, []);
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  var patternName = new RegExp(/(.*[a-z]){3}/i);
  // console.log("emailError", emailTextError);

  var patternPhone = new RegExp(/^([0-9]{10})$/g);

  var patternEmail = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

  const sortedCallingCode = restrictedCountry.sort(function (a, b) {
    if (a.name < b.name) return -1;
    else if (a.name > b.name) return 1;
    return 0;
  });
  const chatBubbleContainerStyle = {
    position: 'relative',
    display: 'inline-block',
    marginLeft: '10px',
    marginTop: '5px',
  };

  // Chat bubble style
  const chatBubbleStyle = {
    backgroundColor: '#f1f1f1',
    borderRadius: '15px',
    padding: '10px 15px',
    display: 'inline-block',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    fontStyle: 'italic',
    color: '#333',
  };

  // Arrow style
  const arrowStyle = {
    content: '""',
    position: 'absolute',
    // bottom: '-10px',
    left: '125px',
    // width: 0,
    // height: 0,
    // borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '5px solid #f1f1f1',
    zIndex: 1,
  };
  return (
    <>

      <Snackbar
        open={showRedirectMessage && !phoneError}
        autoHideDuration={3000}
        onClose={() => setShowRedirectMessage(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setShowRedirectMessage(false)}
          severity="success"
          sx={{
            width: '100%',
            backgroundColor: '#28A745 !important',
            color: 'white !important',
            boxShadow: 'none',
            zIndex: 9999,
          }}
        >
          You will be redirected to OTP page
        </Alert>
      </Snackbar>

      <header style={{ background: "#f6f6f6", paddingTop: "0px" }}>
        <div
          style={{
            maxWidth: 500,
            margin: "0 auto",
            padding: "0 0px",
            background: "#f6f6f6",
          }}
        >
          <LoginPgLogo style={{ textAlign: "center" }}>
            <img
              src={Login}
              style={{ width: "100%", alignItems: "center", height: "300px" }}
            />
            <img src={LoginLogo} style={{ height: 56, width: 100 }} />
          </LoginPgLogo>
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "25px",
            marginTop: "30px",
            fontWeight: "900",
            color: "#4a4b4d",
            fontFamily: "Metropolis",
            paddingBottom: "20px",
          }}
        >
          Login using
        </div>
      </header>
      <main
        style={{
          padding: "6px 50px 60px 50px",
          borderRadius: "0px 0px 0 0",
          marginTop: "0px",
          background: "#f6f6f6",
        }}
      >
        <div
          style={{
            display: `${!signIn ? "block" : "none"}`,
            margin: "0 auto",
            maxWidth: 500,
            margin: "0 auto",
            padding: "0 0px",
            background: "#f6f6f6",
          }}
        >
          <FormControl
            fullWidth
            style={{ marginBottom: 30, textAlign: "center" }}
            variant="outlined"
          >
            {/* {showRedirectMessage && (
              <div style={{ color: "green", marginBottom: "10px" }}>
                You will be redirected to OTP page
              </div>
            )} */}
            <PhoneAndEmailButton
              variant="contained"
              color="secondary"
              onClick={() => {
                setLogintype("phoneno");
                setPhoneTextError(false);
                setPhoneError(false);
                setEmailTextError(false);
                setEmailError(false);
              }}
            >
              <InputAdornment position="start">
                <PhoneIcon color="#2C7973" />
              </InputAdornment>
              Phone No
            </PhoneAndEmailButton>
            <PhoneAndEmailButton
              variant="contained"
              color="secondary"
              onClick={() => {
                setLogintype("email");
                setPhoneTextError(false);
                setPhoneError(false);
                setEmailTextError(false);
                setEmailError(false);
              }}
            >
              <InputAdornment position="start">
                <EmailIcon color="#2C7973" />
              </InputAdornment>
              Email Id
            </PhoneAndEmailButton>
            <br></br>
            {logintype === "phoneno" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "5px",
                  style: "1px solid black",
                }}
              >
                <StyledSelect
                  value={callingCode === "" ? "+91" : callingCode}
                  onChange={handleChangeCallingCode}
                >
                  {sortedCallingCode.map((item, index) => (
                    <MenuItem value={item.dial_code}>
                      {item.name + "\t" + item.dial_code}
                    </MenuItem>
                  ))}
                </StyledSelect>
                <StyledOutlinedInput
                  required
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.26)",
                    padding: "0px 0px 0px 6px",
                  }}
                  id="login-phone-number"
                  error={loginError}
                  startAdornment={<PhoneIcon color="#2C7973" />}
                  onMouseEnter={() => setHoverLogin(true)}
                  onMouseLeave={() => setHoverLogin(false)}
                  placeholder="Phone Number"
                  disabled={otpAccepted}
                  onBlur={(e) => {
                    if (!patternPhone.test(e.target.value)) {
                      setPhoneTextError(
                        "Please enter your registered Phone Number"
                      );
                      setPhoneError(true);
                    } else {
                      setPhoneTextError(false);
                      setPhoneError(false);
                    }
                  }}
                  onChange={(e) => {
                    setLoginPhoneNumber(e.target.value);
                    setPhoneNumberisValid(true);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      dispatch(socialLogin(replacedLoginPhoneNumber));
                    }
                  }}
                />
              </div>
            ) : logintype === "email" ? (
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <StyledOutlinedInput
                  required
                  style={{
                    // display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5px",
                    style: "1px solid black",
                  }}
                  id="login-email"
                  error={loginError}
                  startAdornment={<EmailIcon color="#2C7973" />}
                  onMouseEnter={() => setHoverLogin(true)}
                  onMouseLeave={() => setHoverLogin(false)}
                  placeholder="Email Id"
                  disabled={otpAccepted}
                  onBlur={(e) => {
                    if (!patternEmail.test(e.target.value)) {
                      setEmailTextError(
                        "Please enter your registered Email ID"
                      );
                      setEmailError(true);
                    } else {
                      setEmailTextError(false);
                      setEmailError(false);
                    }
                  }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      dispatch(socialEmailLogin(email));
                    }
                  }}
                />
              </div>
            ) : (
              ""
            )}
            {phoneError && (
              <>
                <span style={{ color: "red" }}> {phoneTextError}</span>
                {/* <div style={chatBubbleContainerStyle}>
                  <PanToolAltIcon style={arrowStyle} />
                  <div style={chatBubbleStyle}>
                    <span>Please try EMAIL ID option</span>
                  </div>
                </div> */}
              </>
            )}
            {emailError && (
              <>
                <span style={{ color: "red" }}> {emailTextError}</span>
                {/* <div style={chatBubbleContainerStyle}>
                  <PanToolAltIcon style={arrowStyle}/>
                <div style={chatBubbleStyle}>
                  <span>Please try PHONE NO option</span>
                </div>
                </div> */}
              </>
            )}
          </FormControl>
          {/* <div id="recaptcha-container1" /> */}
          {/* {loginSuccess && <div id="recaptcha-container1" />} */}
          {emailloginSuccess && <div id="recaptcha-container2" />}
          <div style={{ width: "100%", textAlign: "center" }}>
            <StyledButton
              variant="contained"
              color="secondary"
              onClick={() => {
                if (!loginPhoneNumber) {
                  setLoginError(true);
                } else {
                  setLoginError(false);
                  dispatch(socialLogin(replacedLoginPhoneNumber));
                  if (
                    logintype === "phoneno" &&
                    login.message ===
                    "Sorry, you are not registered on platform."
                  ) {
                    setphoneerrorcount(phoneerrorcount + 1);
                    setLoginError(true);
                  } else {
                    setemailerrorcount(emailerrorcount + 1);
                    setLoginError(false);
                    setPhoneError(false);
                  }
                }
                if (!email) {
                  setLoginError(true);
                } else {
                  setLoginError(false);
                  dispatch(socialEmailLogin(email));
                  if (
                    logintype === "email" &&
                    emaillogin.message ===
                    "Sorry, you are not registered on platform with this emailid"
                  ) {
                    setemailerrorcount(emailerrorcount + 1);
                    setLoginError(true);
                  } else {
                    setemailerrorcount(emailerrorcount + 1);
                    setLoginError(false);
                    setEmailError(false);
                  }
                }
              }}
            >
              Sign In
            </StyledButton>
            <br></br>
            {/* <div id="recaptcha-container1" /> */}
            Not Registered?{" "}
            <a href="/register">
              <span style={{ textTransform: "uppercase" }}>Click Here</span>
            </a>
            <div id="recaptcha-container1" />
          </div>
        </div>
      </main>
    </>
  );
};

export default LoginScreen;
