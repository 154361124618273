import React, { useState, useRef } from 'react';

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { TextField, Button, Grid } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const Message = ({ index, removeQuestion, updateQuestionText }) => {
  const [messageText, setMessageText] = useState('');
  const quillRef = useRef(null);

  const handleTextChange = (newText) => {
    setMessageText(newText);
    updateQuestionText(index, newText);
  };

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    setMessageText(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const html = convertToHtml(rawContentState);
    setMessageText(html);
    updateQuestionText(index, html);
  };

  const convertToHtml = (rawContentState) => {
    let html = '';
    let currentListType = null; // Move this declaration outside the loop
    rawContentState.blocks.forEach(block => {
      let tag = '';
      let inlineStyles = '';

      switch (block.type) {
        case 'unstyled':
          tag = 'p';
          break;
        case 'ordered-list-item':
          if (currentListType !== 'ol') {
            if (currentListType) html += `</${currentListType}>`;
            currentListType = 'ol';
            html += `<${currentListType} style="margin-left: 20px;">`;
          }
          tag = 'li';
          break;
        case 'unordered-list-item':
          if (currentListType !== 'ul') {
            if (currentListType) html += `</${currentListType}>`;
            currentListType = 'ul';
            html += `<${currentListType} style="margin-left: 20px;">`;
          }
          tag = 'li';
          break;
        default:
          tag = block.type;
          break;
      }

      block.inlineStyleRanges.forEach(style => {
        switch (style.style) {
          case 'BOLD':
            inlineStyles += 'font-weight: bold;';
            break;
          case 'ITALIC':
            inlineStyles += 'font-style: italic;';
            break;
          case 'UNDERLINE':
            inlineStyles += 'text-decoration: underline;';
            break;
          default:
            inlineStyles += 'margin-left:20px;';
            break;
        }
      });

      html += `<${tag} style="${inlineStyles}">${block.text}</${tag}>`;
    });

    // Close the last list
    if (currentListType) {
      html += `</${currentListType}>`;
    }

    console.log("=====html===", html);
    return html;
  };



  React.useEffect(() => {
    // After each change, manually reset list numbering
    if (quillRef.current) {
      quillRef.current.getEditor().formatLine(0, 1, 'list', false, 'ordered');
    }
  }, [messageText]);



  return (
    <Grid container spacing={2} alignItems="center" style={{
      border: '3px solid #ddd',
      borderRadius: '8px',
      padding: '10px',
      marginBottom: '10px',
      marginTop: '10px',
    }}>
      <Grid item xs={10}>
        <Editor
          value={messageText}
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: ['inline', 'list', 'emoji', 'history'],
            inline: {
              options: ['bold', 'italic', 'underline'],
            },
            list: {
              options: ['unordered', 'ordered'],
            },
          }}
          style={{ lineHeight: '1.2', pointerEvents: "auto" }}
        />

      </Grid>
      <Grid item xs={1} style={{ paddingLeft: "0px" }}>
        <Button
          // variant="contained"
          // color="secondary"
          onClick={removeQuestion}
          startIcon={<DeleteForeverIcon style={{ fontSize: 30, color: "gray", pointerEvents: "auto" }} />}
        >
        </Button>
      </Grid>
    </Grid>
  );
};

export default Message;
