/** @format */

import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
} from "@mui/material";
import _ from "lodash";
import PropTypes from "prop-types";
import {
  userProfile,
  userProfileReset,
} from "../redux/action/UserProfileActions";
import { CopyRight } from "./CopyRight";
import { getVolunteerCount } from "../redux/action/volunteerCountAction";
import { volunteerMenuItems } from "../utils/dict";
import ResponsiveDrawer from "./Header";
import axios from "axios";
import { API_URL } from "../services/Constants";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { socialLoginReset } from "../redux/action/loginAction";
import { getUserProfileImage } from "../redux/action/userProfileImage";
import rewardIcon from "../assets/rewardIconNav.png";
import { volDashBoardReward } from "../redux/action/DashboardReward";
import GradeSharpIcon from "@mui/icons-material/GradeSharp";
import TopHeaderScreen from "./TopHeader1";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { FixedFooterIcon } from "./FixedFooterIcon";
import styled from 'styled-components';

const StyledCardTitleText = styled(Box)(({ theme }) => ({
  fontSize: 26,
  fontWeight: 400,
  width: 330,
  right: '0%',
  top: 0,
  boxShadow: '1px 2px 10px rgba(0,0,0,0.2)',
  borderRadius: 8,
  background: '#fff',
  '@media (max-width: 600px)': {
    fontSize: 18,
    fontWeight: 400,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const dispatch = useDispatch();

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const NotificationArchive = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [campaignList, setCampaignList] = useState([]);
  const [rewarddash, setRewardDash] = useState([]);
  const [value, setValue] = React.useState(0);
  const [image, setImage] = React.useState(null);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  
  const { isSuccess: myRewardSuccess, response: myRewardResponse } =
    useSelector((state) => state.volDashBoardReward);

  const userid = localStorage.getItem("userid");
  const clearStorage = () => {
    localStorage.clear();
    dispatch(userProfileReset());
    dispatch(socialLoginReset());
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isActive, setActive] = useState(false);

  const [loading, setLoading] = React.useState(false);

  const handleChangeFile = (e) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("profilephoto", e.target.files[0]);
    dispatch(
      getUserProfileImage({ userId: localStorage.getItem("userid"), formData })
    );
    setImage(e.target.files[0]);
  };

  const updateNotificationFlag = async () => {
    try {
      let formData = new FormData();
      // formData.append("notifyflag", "true");
      formData.append("flag", "true");

      const res = await axios({
        url: `/notification/flag/${profileResponse?.phoneno}`,
        method: "put",
        data: formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      dispatch(userProfile(localStorage.getItem("userid")));
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getVolunteerCamp = async () => {
    try {
      const res = await axios.get(
        `vol_dashboard/camp/${profileResponse?.orgrole}/${profileResponse?.organization}`
      );
      setCampaignList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };
  const getMyReward = async () => {
    try {
      const res = await axios.get(`/vol_dashboard/myreward/${userid}`);
      setRewardDash(res?.data);
      dispatch(volDashBoardReward(res?.data, res?.data?.[0]?.phoneno));
      return res;
    } catch (err) {
      throw err;
    }
  };

  

  useEffect(() => {
    dispatch(userProfile(userid));
    dispatch(getVolunteerCount());
    getVolunteerCamp();
    getMyReward();
  }, []);
  
  return (
    <div className="wrapperNotificationArchive">
      <ResponsiveDrawer {...props} />
      <section className="containers" style={{ overflow: "hidden" }}>
        <TopHeaderScreen {...props} />
        <div class="content content-wrap" style={{ marginBottom: 50 }}>
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>

            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Notifications
            </div>

            <div>
              <StyledCardTitleText>
                <div style={{ marginTop: "0px" }}>
                  {_.isEmpty(profileResponse?.notificationarchive) ? (
                    <div>
                      <span
                        style={{
                          // background: "#fff",
                          // float: "left",
                          width: "100%",
                          // width: "300px",

                          padding: "10px 12px",
                        }}
                      >
                        No notifications yet
                      </span>
                    </div>
                  ) : (
                    profileResponse?.notificationarchive?.map((item, index) => (
                      <div>
                        <div
                          style={{
                            background: "#fff",
                            float: "left",
                            width: 336,
                            padding: "10px 12px",
                            borderBottom: "1px solid #ccc",
                            backgroundColor: "rgba(255,255,255,0.9)",
    borderRadius: 10,
    padding: 16,
    marginTop: 20,
    boxShadow: "0.5px 0.5px 5px rgba(0,0,0,0.3)",
                          }}
                        >
                          <div
                            class=""
                            style={{
                              float: "left",
                              width: "50px",
                              marginRight: "10px",
                            }}
                          >
                            <img
                              src={item.logo}
                              class="notification-image"
                            />
                          </div>
                          <div
                            class=""
                            style={{
                              color: "#000",
                              float: "left",
                              width: "calc(100% - 60px)",
                            }}
                            onClick={() => {
                              item.type === "campaign" && item.status === "Active"
                                ? // navigate(`/campaigndetails/${item.campid}/${profileResponse?.orgrole}/${profileResponse?.organization}`)
                                  navigate(
                                    `/participate/campaign/${item.campid}/${profileResponse?.phoneno}`
                                  )
                                  : item.type === "campaign" && item.status === "Completed" ?
                                      navigate(
                                        `/home`
                                      ) 
                                : navigate(`/mypoints`);
                            }}
                          >
                            <span
                              style={{
                                color: "#000",
                                // display: "flex",
                                display: "inline-block",
                                // justifyContent: "flex-end",
                                fontSize: 16,
                                color: "grey",
                                display: "block",
                              }}
                            >
                              {moment(item?.createdate).fromNow()}
                            </span>
                            <span
                              style={{
                                fontSize: "20px",
                                cursor: "pointer",
                                display: "block",
                                "&:hover": {
      textDecoration: "underline",
      color: "blue",
    },
                              }}
                            >
                              {item?.msg}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </StyledCardTitleText>
            </div>
          </div>
        </div>
        <br />
        <br />
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
};

export default NotificationArchive;
