// ShareButtons.js
import React from 'react';
import {
  WhatsappShareButton,
  FacebookShareButton,
  EmailShareButton,
  TwitterShareButton,
  WhatsappIcon,
  FacebookIcon,
  EmailIcon,
  TwitterIcon,
} from 'react-share';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const ShareButtons = ({ url, onClose}) => {
  return (
    <div className="share-buttons">
        <div className="share-buttons-header">
        <IconButton className="close-button" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="share-buttons-icons">
      <WhatsappShareButton url={url}>
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <FacebookShareButton url={url}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <EmailShareButton url={url}>
        <EmailIcon size={32} round />
      </EmailShareButton>
      <TwitterShareButton url={url}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      </div>
    </div>
  );
};

export default ShareButtons;
