import * as React from "react";
import { Button, TextField , Avatar, Typography, Box} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { onboardCampaignReset } from "../../redux/action/OnboardAction";
import { updateCampaign, updateCampaignReset } from "../../redux/action/UpdateAction";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import axios from "axios";
import { getOrganization } from "../../redux/action/getOrganization";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import _ from "lodash";
import RedStar from "../../components/RedStar";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link } from "react-router-dom";
import { getCampProfileLogo } from "../../redux/action/campLogoAction";
import PauseConfirmation from "../../components/PauseConfirmation";
import { useState } from "react";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import styled, { keyframes } from 'styled-components';

const blinking = keyframes`
  0% { filter: brightness(0.9); }
  50% { filter: brightness(1.1); }
  100% { filter: brightness(1); }
`;

const BlinkingSVG = styled.svg`
  cursor: pointer;
  animation: ${blinking} 1s infinite;
`;

const SVGCampLeaderboard = (props) => (
  <BlinkingSVG width="40" height="40" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <rect width="40" height="40" fill="url(#pattern0_231_5)" />
    <defs>
      <pattern id="pattern0_231_5" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_231_5" transform="scale(0.02)" />
      </pattern>
      <image id="image0_231_5" width="50" height="50" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADPklEQVR4nO2Y20+SYRzHadnW/9PWVRsXvFt31tq8wHnAWWBBaFlgIIqkIWLiAhEUrAulPKQiMkhF8Ihazo43WRq1aalbuixMgV97XivFUHhBfNf2fLfPxgaM3+f5PacXBgMHBwcHBwcHh4yXIMReFmvDy2JBIkwQhH+cxbrKoCPAYBzzEsTmBz4flqTShJjLy0My67SIuJnMFDSaKzIZ+DWahFiSSFBnArSIPOOeOoFEPgqFCXfEJxAgkSAtIu5s5slE10YYBAH0iJQyU7rL2LDSWw0wakqIpZ4q6C5j09QRLGJKTkfS0tqOn5fYTqdKewkqXJB0ndXLr4GvWxN16nzruwebHkNyp9Y5qd2aKrFDvIxZ9PsW6Hfp4GWTCHoU6bBsUydXJFViD0pa5kHtWKXMQSI/3fWkANoQEEchAsWtn5IiMqkXkh2JJvK8UwmF+oIQx1w0SpUcs9iZaRafIUV42mkQNr6mTLSphZh7JI8q4u2ogCyTCG5ZVZQpaC0LccziRQav+D5cLm0OXZFbglThy5tDrzoOT8Q42UQZlUsL2SZRgHFYhxoWGd3pSFaccEwiiNiRrSEjzFpkMFEngAm9EN62yCAwZExaR7669DD2uCIu+pql0KDMCUUUmWkshCcqDkwbr4O3TkAWMmXIT5rIfmwM1oGv/TY5kL52BbkT7rt1RxL5bK2EwPBOB1BXrIp0CA43UC5msUsJY1o+rDqj3wBgD/1VuTCgzoWhWh75+/aKTFhz1sYuspcZ0w2wKthxiSTC2q6ifxcML0w34xNZ79eSIzGu4x+pBESoA9X65kERdZFVh4ZcK87KHPg+oDvy4rc8BvhiVZHrw13DA1f1RfAP6qmJLHTdAVt5BrhruPCDBglAu5lD8/euhmYFWvR7p/eBIss9anJxTdXnhy16utj0GGC+TUFeQp8aCmIX8Wi44FBmk88RaG7+gW6p4do86C3PiF1k9/V7N/GcBfHid+nC1iUaVDS90FYcswh6MxIHPeUdNsu27ek9ePcSeGq45GtnJSexc4QOQiPbMu8flsI7SwksdCohOPLvORYmgj6MvvQ/Mmsp2RH575Gz6fm7FQcHBwcHB4eRnPwCG4GUygmgFQIAAAAASUVORK5CYII=" />
    </defs>
  </BlinkingSVG>
);

const HomeScreenMainContent = styled(Box)(({ theme }) => ({
  padding: '80px 0 100px 0px',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    padding: '80px 0 100px 330px',
  },
})); 

const FormGroup0 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#F0F8FF',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// Styled component for formGroup00
const FormGroup00 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#FAEBD7',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

const FormGroup = styled('div')(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: '40px',
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));


const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup1
const FormGroup1 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup2
const FormGroup2 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  flexDirection: 'column',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '75px 0 0 16px',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup3
const FormGroup3 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  width: '20%',
  '@media (max-width: 1200px)': {
    width: '20%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup4
const FormGroup4 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'right',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 0',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup7
const FormGroup7 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup8
const FormGroup8 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledLabel
const StyledLabel = styled('label')(({ theme }) => ({
  display: 'flex',
  background: '#e9e7e7',
  padding: '10px',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 600,
  marginTop: '16px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

// StyledSpan
const StyledSpan = styled('span')(() => ({
  fontSize: '12px',
}));

// FocusThematic
const FocusThematic = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledInput
const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// StyledTextarea
const StyledTextarea = styled('textarea')(() => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// LogoButton
const LogoButton = styled('input')(() => ({
  width: '8%',
  border: 'none',
  padding: '10px',
  display: 'flex',
  height: '40px',
  color: 'white',
  '@media (max-width: 1920px)': {
    fontSize: '15px',
    width: '12%',
  },
  '@media (max-width: 1280px)': {
    fontSize: '15px',
    width: '15%',
  },
  '@media (max-width: 960px)': {
    fontSize: '15px',
    width: '20%',
  },
  '@media (max-width: 600px)': {
    fontSize: '15px',
    width: '30%',
  },
}));

// StyledTypography
const StyledTypography = styled(Typography)(({ theme }) => ({
  '& .css-u4tvz2-MuiFormLabel-root': {
    fontFamily: 'Poppins',
  },
  display: 'block',
  display: 'flex',
  fontSize: '16px',
  marginTop: '16px',
  fontWeight: 500,
  lineHeight: '18px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

const LoginPgLogo = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (max-width: 768px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
}));

const StyledLargeAvatar = styled(Avatar)(({ theme }) => ({
  width: '200px !important',
  height: '200px !important',
  margin: '0 auto',
  marginTop: '5px',
  marginBottom: '5px',
  '@media (max-width: 400px)': {
    width: '120px !important',
    height: '120px !important',
  },
  '@media (max-width: 600px)': {
    width: '140px !important',
    height: '140px !important',
  },
  '@media (max-width: 768px)': {
    width: '150px !important',
    height: '150px !important',
  },
}));

function PauseCampaignScreen(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "" },
  ]);

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const [campaignValues, setCampaignValues] = React.useState({
    titleOfCampaign: "",
    descriptionOfCampaign: "",
    typeOfCampaign: "",
    volunteerCount: "",
    // impact: "",
    startDate: new Date(),
    endDate: new Date(),
    durationOfCampaign: "",
    rewardType: "",
    goal: "",
    campaignType: "",
    hourOfParticipation: "",
    socialMediaMessage: "",
    campaignLogo: "",
    campaignVideo: "",
    visibility: "",
  });
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const {
    isCampaignSuccess,
    isCampaignFetching,
    campaignResponse,
    isCampaignError,
  } = useSelector((state) => state.updateCampaign);

  const handleCampaignFieldChange = (field, e) => {
    (isCampaignError || isCampaignSuccess) && dispatch(onboardCampaignReset());
    setCampaignValues({
      ...campaignValues,
      [field]:
        field === "startDate" || field === "endDate"
          ? e
          : field === "campaignLogo" || field === "campaignVideo"
            // : field === "campaignLogo"
            ? e.target.files[0]
            : e.target.value,
    });
  };

  React.useEffect(() => {
    setLocation([{ country: "", state: "", city: "", area: "", cityList: "" }]);
  }, []);

  const getAddress = (item, index) => {
    return (
      <div style={{ float: "left", width: "75%", marginTop: 50 }}>
        <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>Operational Areas</span>
        <form style={{ display: "flex", flexWrap: "wrap", width: "100%", float: "left" }}>
          <FormGroup1>
            <StyledLabel>
              Country
            </StyledLabel>
            <StyledInput
              disabled
              value={item.country}
              type="text"

            />
          </FormGroup1>

          <FormGroup1>
            <StyledLabel>
              State
            </StyledLabel>
            {/* {campaignValues?.campaignType === "Virtual" ? ( */}

            <StyledInput
              disabled
              value={item.state}
              type="text"

            />
          </FormGroup1>
          {/* {index !== location.length - 1 || (index === location.length - 1 && location[index].state !== "") || index === 0 ? ( */}
          <FormGroup1>
            <StyledLabel>
              City/Town
            </StyledLabel>
            {/* {campaignValues?.campaignType === "Virtual" ? ( */}

            <StyledInput
              disabled
              value={item.city}
              type="text"

            />
          </FormGroup1>
          <FormGroup1>
            <StyledLabel>
              Area
            </StyledLabel>
            <StyledInput
              disabled
              value={item.area}
              type="text"

            />
          </FormGroup1>
        </form>
      </div>
    );
  };


  React.useEffect(() => {
    dispatch(getOrganization({ userid: localStorage.getItem("userid") }));
    // getActivityList();
  }, []);

  const locationPath = useLocation();
  const [selectedSkills, setSelectedSkills] = React.useState([]);

  React.useEffect(() => {

    if (locationPath?.state?.skill) {
      setSelectedSkills(locationPath?.state?.skill);
    }
    // if (locationPath?.state?.rewardstobeearned) {
    //   setSelectedRewards(locationPath?.state?.rewardstobeearned);
    // }
    if (locationPath?.state) {
      setCampaignValues({
        titleOfCampaign: locationPath?.state?.title,
        descriptionOfCampaign: locationPath?.state?.desc,
        typeOfCampaign: locationPath?.state?.type,
        volunteerCount: locationPath?.state?.targetvol,
        // impact: locationPath?.state?.impact,
        visibility: locationPath?.state.visibility,
        // startDate: moment(locationPath?.state?.startDate).format("YYYY-DD-MM"),
        // endDate: moment(locationPath?.state?.endDate).format("YYYY-DD-MM"),
        // startDate: moment(locationPath?.state?.startdate, "YYYY-MM-DD"),
        // endDate: moment(locationPath?.state?.enddate, "YYYY-MM-DD"),
        startDate: locationPath?.state?.startdate,
        endDate: locationPath?.state?.enddate,
        durationOfCampaign: locationPath?.state?.timeframe,
        goal: locationPath?.state?.goal,
        campaignType: locationPath?.state?.campaigntype,
        hourOfParticipation: locationPath?.state?.hourofparticipation,
        socialMediaMessage: locationPath?.state?.socialmediamsg,
        campaignLogo: locationPath?.state?.logo,
        // banner: locationPath?.state?.banner,
        // campaignLogo: campaignValues.campaignLogo,
        // campaignVideo: locationPath?.state?.video,
        // rewardType: locationPath?.state?.rewardstobeearned,
        campid: locationPath?.state?.campid,
        skill: locationPath?.state?.skill,
      });
      setLocation(locationPath?.state?.location);
      // setFocusArea(JSON.parse(locationPath?.state?.rewardstobeearned))
    }
  }, [locationPath?.state]);
  const handlePauseCampaign = async () => {
    // setDisplayConfirmationModal(true);
    const payload = {
      //campid: locationPath?.state?.campid,
      // createdate: locationPath?.state?.createdate,
      // reviewstatus: reviewstatus,
      // reviewcomments: reviewComments,
      phoneno: localStorage.getItem("userid")
      // phoneno: locationPath?.state.phoneno,

    };
    try {
      const res = await axios.put(`/campaign/stop/${campaignValues?.campid}`, payload);
      // navigate("/reviewcampaign");
      // setuserdeletestat(res?.data);
      setDisplayConfirmationModal(false);
      navigate("/reviewcampaign");
      return res;
    } catch (err) {
      throw err;
    }
  };

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button
              onClick={() => navigate(-1)}
              className="backWrapper"
            >
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Pause Campaign</div>
            <div style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              alignItems: "center",
            }}></div>
            <form autoComplete="off">
              <div style={{ border: "1px solid #dadce0", borderRadius: 8, backgroundColor: "#fff" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <FormGroup>
                    <StyledLabel>
                      Name of Campaign (25 Chars)<RedStar />
                    </StyledLabel>

                    <StyledInput
                      value={campaignValues?.titleOfCampaign}
                      type="text"

                      placeholder="Your answer"
                      maxLength="25"
                      disabled
                      onChange={(e) => {
                        handleCampaignFieldChange("titleOfCampaign", e);
                      }}
                    />
                  </FormGroup>

                  <FormGroup>
                    <StyledLabel>
                      Campaign Description (200 Chars) <RedStar />
                    </StyledLabel>
                    <StyledTextarea
                      value={campaignValues?.descriptionOfCampaign}
                      type="text"
                      id="create-yaad--title"

                      placeholder="Your answer"
                      maxLength="200"
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel>
                      Campaign Goal <RedStar />
                    </StyledLabel>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <StyledInput
                        value={campaignValues?.goal}
                        type="text"
                        id="create-yaad--title"
                        placeholder="Your Answer"
                        disabled
                        onChange={(e) => {
                          handleCampaignFieldChange("goal", e);
                        }}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel>
                      Campaign Category <RedStar />
                    </StyledLabel>
                    {campaignValues?.typeOfCampaign != "" &&
                      <span style={{ color: "#0F0F0F", fontFamily: "inherit", padding: "5px" }}>
                        {campaignValues?.typeOfCampaign?.label} </span>
                    }
                  </FormGroup>

                  <FormGroup>
                    <StyledLabel>
                      Campaign Type <RedStar />
                    </StyledLabel>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <StyledInput
                        value={campaignValues.campaignType}
                        type="text"
                        id="create-yaad--title"

                        placeholder="Your Answer"
                        disabled
                        onChange={(e) => {
                          handleCampaignFieldChange("goal", e);
                        }}
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <StyledLabel>
                      Campaign Participation <RedStar />
                    </StyledLabel>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <StyledInput
                        value={campaignValues.visibility}
                        type="text"
                        id="create-yaad--title"

                        placeholder="Your Answer"
                        disabled
                        onChange={(e) => {
                          handleCampaignFieldChange("goal", e);
                        }}
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <StyledLabel>
                      Number of volunteers Required <RedStar />
                    </StyledLabel>

                    <StyledInput
                      value={campaignValues?.volunteerCount}
                      type="number"
                      id="create-yaad--title"

                      placeholder="Your answer"
                      disabled
                      onKeyPress={(e) => { preventMinus(e) }}
                      onChange={(e) => {
                        handleCampaignFieldChange("volunteerCount", e);
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel>
                      Start Date <RedStar />
                    </StyledLabel>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        // minDate={new Date()}
                        inputFormat={"yyyy-MM-dd"}
                        // inputFormat={"YYYY-MM-DD"}
                        value={campaignValues?.startDate}
                        disabled
                        onChange={(newValue) => {
                          handleCampaignFieldChange("startDate", newValue);
                        }}
                        minDate={new Date()}
                        // maxDate={campaignValues.endDate}

                        renderInput={(params) => (
                          <TextField
                            style={{
                              width: "100%",
                              height: 56,
                              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormGroup>

                  <FormGroup>
                    <StyledLabel>
                      End Date <RedStar />
                    </StyledLabel>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        // minDate={new Date()}
                        inputFormat={"yyyy-MM-dd"}
                        value={campaignValues.endDate}
                        disabled
                        onChange={(newValue) => {
                          handleCampaignFieldChange("endDate", newValue);
                        }}
                        // disabled={campaignValues.startDate=== "" ? true: false}
                        // minDate={new Date()}
                        minDate={campaignValues?.startDate}
                        //maxDate={}
                        renderInput={(params) => (
                          <TextField
                            style={{
                              width: "100%",
                              height: 56,
                              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel>
                      No. of Hours of Participation Required from a Volunteer <RedStar />
                    </StyledLabel>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <StyledInput
                        value={campaignValues?.hourOfParticipation}
                        type="number"
                        id="create-yaad--title"

                        placeholder="Your Answer"
                        min="0"
                        disabled
                        onKeyPress={(e) => { preventMinus(e) }}
                        onChange={(e) => {
                          handleCampaignFieldChange("hourOfParticipation", e);
                        }}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel>
                      Skills/Tags <RedStar />
                    </StyledLabel>
                    {locationPath?.state?.skill.map((item, index) => (
                      <span style={{ color: "#0F0F0F", fontFamily: "inherit", padding: "5px" }}> {item.skill}  {((index == (locationPath?.state?.skill.length - 1)) ? '' : ',')} </span>
                    ))}
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel>
                      Social Media Message (500 chars)<RedStar />
                    </StyledLabel>
                    <textarea
                      value={campaignValues?.socialMediaMessage}
                      type="text"
                      id="create-yaad--title"
                      placeholder="Your answer"
                      maxLength="500"
                      disabled
                      onChange={(e) => {
                        handleCampaignFieldChange("socialMediaMessage", e);
                      }}
                    />
                  </FormGroup>
                  <FormGroupFull>
                    {location.map((item, index) => (
                      <div key={index}>{getAddress(item, index)}</div>
                    ))}
                  </FormGroupFull>
                </div>
                <div className="centerAlign" style={{ padding: "16px 0", marginBottom: 56 }}>
                  <Button
                    variant="contained"
                    onClick={() => setDisplayConfirmationModal(true)}
                    className="btn save-pause-btn"
                    disableElevation
                    disabled={isCampaignFetching}
                  >
                    <span>Pause</span>
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => navigate("/reviewcampaign")}
                    style={{
                      width: "150px",
                      height: "50px",
                      background: "white",
                      color: "red",
                      fontSize: 16,
                      fontFamily: "Poppins !important",
                    }}
                  >
                    <span>Cancel</span>
                  </Button>
                  <br />
                </div>
                {isCampaignSuccess &&
                  <span  style={{ color: "green", textAlign: "center", display: "block", }}>{campaignResponse?.message}</span>}
                {!isCampaignSuccess && (
                  <span  style={{ color: "red", textAlign: "center", display: "block", }}>{campaignResponse?.message}</span>
                )}
              </div>
            </form>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
        <PauseConfirmation showModal={displayConfirmationModal} confirmModal={handlePauseCampaign} hideModal={hideConfirmationModal} message={"Are you sure you want to pause this campaign?"} />
      </section>
    </div>
  );
}
export default PauseCampaignScreen;
