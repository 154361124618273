import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import styled from 'styled-components';

const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

const StyledLabel = styled('label')(({ theme }) => ({
  display: 'block',
  background: '#e9e7e7',
  padding: '10px',
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontWeight: 600,
  marginTop: '16px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));


export const InputEnable = ({ label, value, onChange, onBlur, datePicker }) => {
  return (
    <div>
      <StyledLabel>{label}</StyledLabel>
      {datePicker ? (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            inputFormat={"yyyy-MM-dd"}
            value={value}
            onChange={onChange}
            renderInput={(params) => (
              <TextField
                style={{
                  width: 180,
                  height: 56,
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      ) : (
        <StyledInput
          
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          placeholder="Your answer"
          
        />
      )}
    </div>
  );
};
