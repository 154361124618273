import React from 'react';
const CustomShieldIcon = ({color, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="50px" 
        height="50px"
        // fill="silver" 
        fill={color}
        stroke="grey"
        strokeWidth="0.5" 
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
    >
        <path d="M12 2L3 6v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V6l-9-4z"></path>
    </svg>
);

export default CustomShieldIcon;
