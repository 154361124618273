import * as React from "react";
import { useEffect, useState } from 'react';
import {
  Button,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import axios from "axios";
import _ from "lodash";
import TopHeaderScreen from "../../components/TopHeader1";
import { useNavigate } from "react-router-dom";
import VideoSideBar from "../../components/VideoSideBar";
import VideoCard from "../../components/VideoCard";
import UploadFiles1Button from "../../components/UploadFiles1Button";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";

function MyOngoingCampaign(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [campregisterlist, setCampRegisterList] = useState([]);
  const [campID, setCampID] = useState([]);
  const [type, setType] = useState("public");
  const [selectedCategory, setSelectedCategory] = useState("cYAAG");
  
  const handleTriggerFunction = () => {
   
    const timeoutId = setTimeout(() => {
      getCampRegistrationList();
    }, 1000);
    console.log('Function triggered from ChildComponent');
  };

  const getCampRegistrationList = async () => {
    console.log("============= Calling again==========");
    try {
      const res = await axios.get(
        `/list/files/campaign/${selectedCategory}/${localStorage.getItem("userid")}`
      );
      setCampRegisterList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    getCampRegistrationList();

  }, [selectedCategory]);

  const getCampID = async () => {
    try {
      const res = await axios.get(
        `/camp/video/name/${selectedCategory}/${localStorage.getItem("userid")}`
      );
      setCampID(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    getCampID();
  }, [selectedCategory]);

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
          <Button onClick={() => navigate(-1)} className="backWrapper" >
                <i className={`fa fa-caret-left`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <Stack sx={{flexDirection: {sx: "column", md:"row"}}}>
              <Box sx={{height: {sx:"auto", md:"92vh"}, borderRight:"1px solid #FFDEAD", 
                        px:{sx:0, md:2}}}>
              <VideoSideBar 
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />
              </Box>
              <Box p={2} sx={{overflowY: 'auto', height: '90vh', flex: 2, flexWrap:"wrap", order:3 }}>
              {
                selectedCategory === "cYAAG" ? (
                  <Typography  mb={2} sx={{color: "black"}}>
                    <h3 style={{ fontFamily: "Roboto, sans-serif", fontSize: '25px', fontWeight: 600 }}>Campaign Gallery</h3>
                    <span style={{color:'#F31503'}}> Public </span>
                  </Typography>
                ): (
                  <Typography variant="h4" fontWeight="bold" mb={2} sx={{color: "black"}}>
                    {selectedCategory} <span style={{color:'#F31503'}}>Public Gallery</span>
                    {campID && campID?.[0]?.myregistration === "yes" &&  (campID?.[0]?.status === "Active" || campID?.[0]?.status === "Completed")? (
                      <div><UploadFiles1Button camp={campID?.[0]?.campid} selectedCategory={selectedCategory} parentTriggerFunction={handleTriggerFunction} /></div>
                      ):campID && campID?.[0]?.status === "Upcoming" ? (
                      <div>
                        <span style={{fontSize:"12px"}}>Please note that the campaign is not active yet.</span>
                      </div>
                    ):campID && campID?.[0]?.myregistration === "no" &&  campID?.[0]?.status === "Active" ? (
                      <div>
                        <span style={{fontSize:"12px"}}>Please register for the campaign in order to upload images/videos. 
                        <button
                          onClick={() => navigate(`/participate/campaign/${campID?.[0]?.campid}/${localStorage.getItem("userid")}`)}
                      >
                          <span style={{ paddingLeft: 3, fontSize: 14 }}>Click Here</span>
                      </button>
                      </span>
                      </div>
                    ):campID && campID?.[0]?.myregistration === "no" &&  (campID?.[0]?.status === "Completed") ? (
                      <div>
                        <span style={{fontSize:"12px"}}>Please note that the campaign is Completed and you may not be able to register or upload images/videos. 
                        </span>
                      </div>
                    ):
                    (
                      ""
                    )
                    }
                  </Typography>
                )
              }
                <VideoCard video={campregisterlist} camp={campID} type={type}/>
              </Box>
            </Stack> 
           </div>
         </div>
         <br />
         <br />
         <CopyRight />
         <FixedFooterIcon />
       </section>
     </div>
     
  );
}
export default MyOngoingCampaign;