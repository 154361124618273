import * as React from "react";
import {
  Card,
  Typography,
  Paper,
  CssBaseline,
  Grid,
  useMediaQuery,
  CircularProgress,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Button,
  CardActions,
  CardContent,
  Chip,
  CardMedia,
  InputBase,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "./Header";
import TopHeaderScreen from "./TopHeader1";
import { useLoadScript } from "@react-google-maps/api";
import GoogleMapReact from 'google-map-react';
import axios from "axios";
import { useState, useEffect, createRef } from "react";
import { LocationOnOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CampaignIcon from '@mui/icons-material/Campaign';
import SearchIcon from '@mui/icons-material/Search';

export default function PlatOnMap(props) {
  const libraries = ["places"]
  const [coordinates, setCoordinates] = useState({});
  const navigate = useNavigate();
  const [bounds, setBounds] = useState({});
  const [allCampList, setAllCampList] = useState([]);
  const [places, setPlaces] = useState([]);
  const [childClicked, setChildClicked] = useState({});
  const [vol, setVol] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("Active")

  React.useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        setCoordinates({ lat: latitude, lng: longitude });
      },
    );
  }, []);

  React.useEffect(() => {
    setIsLoading(true);
    getAllTypesOfCampListForMaps().then((data) => {
      setPlaces(data);
      setIsLoading(false);
    });
  }, [type, coordinates, bounds]);


  const getAllTypesOfCampListForMaps = async () => {
    try {
      const res = await axios.get(`map/campaign/list/${localStorage.getItem("userid")}/${type}`);
      // const res = await axios.get(`map/campaign/list/${bounds.sw.lat}/${bounds.ne.lng}`);
      setAllCampList(res?.data);

      return res;
    } catch (err) {
      throw err;
    }
  };

  const { isLoaded, loadError } = useLoadScript({
    // googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    googleMapsApiKey: "AIzaSyDkELxH1CVeAGbfVZsyCvcHPjcw-BrOYa8",
    libraries,
  });


  if (loadError) return "Error loading google maps";
  if (!isLoaded) return "Loading....";

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <Button
          onClick={() => navigate(-1)}
          className="backWrapper"
        >
          <i className={`fa fa-caret-left`} />
          <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
        </Button>
        <Grid container spacing={3} style={{ width: "80%", marginTop: "20px" }}>

          <Grid item xs={12} md={3}>
            {/* <Grid item xs={6} md={2}> */}
            <List
              places={places}
              childClicked={childClicked}
              isLoading={isLoading}
              type={type}
              setType={setType}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            {/* <Grid item xs={6} md={4}> */}
            <Map
              setCoordinates={setCoordinates}
              setBounds={setBounds}
              coordinates={coordinates}
              places={places}
              setChildClicked={setChildClicked}
              vol={vol}
              childClicked={childClicked}
            />

          </Grid>
        </Grid>
        {/* </> */}

      </section>

    </div>
  );
}

export const List = ({ places, childClicked, isLoading, type, setType }) => {
  const [elRefs, setElRefs] = useState([]);




  React.useEffect(() => {
    // const refs = Array(places?.data?.length).fill().map((_, i) => elRefs[i] || createRef());
    const refs = Array(places?.data).fill().map((_, i) => elRefs[i] || createRef());

    setElRefs(refs);
  }, [places]);



  return (
    <div style={{ padding: "18px" }}>
      <Typography variant="h5">Campaigns running around you</Typography>
      {
        isLoading ? (
          <div style={{
            height: "600px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
            <CircularProgress size="5rem" />
          </div>
        ) : (
          <>
            <FormControl style={{
              minWidth: 120,
              marginBottom: "30px"
            }}>
              <InputLabel>Type</InputLabel>
              <Select value={type} onChange={(e) => setType(e.target.value)}>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Upcoming">Upcoming</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
              </Select>

            </FormControl>
            <Grid container spacing={3} style={{
              height: "75vh",
              overflow: "auto",
            }}>
              {places?.data?.map((place, id) => (
                <Grid item key={id} xs={12}>
                  <PlaceDetails
                    place={place}
                    selected={Number(childClicked) === id}
                    refProp={elRefs[id]}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}

    </div>
  );
}

export const Map = ({ setCoordinates, setBounds, coordinates, places, setChildClicked, vol, childClicked }) => {

  const isDesktop = useMediaQuery('(min-width:600px)');
  const mapRef = React.useRef();

  return (
    <div style={{ padding: "18px" }}>
      {/* <Search panTo={panTo} /> */}
      <GoogleMapReact
        // bootstrapURLKeys={{ key: "AIzaSyDkELxH1CVeAGbfVZsyCvcHPjcw-BrOYa8" }}
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        // style={{width: "100%", height: "100vh"}}
        defaultCenter={coordinates}
        center={coordinates}
        defaultZoom={5}
        // margin={[100, 100, 100, 50]}
        onChange={(e) => {
          // console.log(e);
          setCoordinates({ lat: e.center.lat, lng: e.center.lng });
          setBounds({ ne: e.marginBounds.ne, sw: e.marginBounds.sw })
        }}
        onChildClick={(child) => setChildClicked(child)}
      >
        {
          places?.data?.map((place, i) => (
            <div
              style={{
                position: "absolute",
                transform: "translateX(-50%)",
                zIndex: 10,
              }}
              lat={Number((place.lat))}
              lng={Number((place.lng))}
              key={i}
              onCloseClick={() => {
                setChildClicked(null);
              }}
            >
              {
                !isDesktop ? (
                  <LocationOnOutlined text={place.title} color="primary" fontSize="large" />
                ) : (
                  <CampaignIcon style={{ cursor: "pointer" }} color="primary" fontSize="large" />

                )
              }

              {childClicked == i ? (
                // place.title
                <CampDetails place={place} />
              ) : null
              }
            </div>
          ))}

      </GoogleMapReact>
    </div>
  )
}

export const PlaceDetails = ({ place, selected, refProp }) => {
  const navigate = useNavigate();

  if (selected) {
    refProp?.current?.scrollIntoView({ behaviour: "smooth", block: "start" })

  }
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  return (
    <Card elevation={6}>
      <CardMedia
        style={{
          height: 169, position: "relative",
          padding: 7,
          paddingRight: 25,
          color: "black",
          display: "flex",
          cursor: "pointer",
        }}
        image={place.photo ? place.photo : "https://mt-dev-cyaag.s3.ap-south-1.amazonaws.com/Campaign/The Hyphen/c00001/logo/Vrikshi_Logo.png"}
        title={place.title}
        onClick={() =>
          navigate(`/campaigndetails/${place.campid}/${profileResponse?.orgrole}/${profileResponse?.organization}`)
        }
      />
      <CardContent style={{ padding: 8 }}>
        <Typography gutterBottom variant="h7">{place.title}</Typography>

      </CardContent>
    </Card>
  )
}


export const CampDetails = ({ place }) => {
  const navigate = useNavigate();
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  return (
    <Card elevation={6}>
      <CardMedia
        style={{
          height: 75, width: 80, position: "relative",
          padding: 7,
          paddingRight: 25,
          color: "black",
          display: "flex",
          cursor: "pointer",
        }}
        image={place.photo ? place.photo : "https://mt-dev-cyaag.s3.ap-south-1.amazonaws.com/Campaign/The Hyphen/c00001/logo/Vrikshi_Logo.png"}
        // title={place.title}
        onClick={() =>
          navigate(`/campaigndetails/${place.campid}/${profileResponse?.orgrole}/${profileResponse?.organization}`)
          // navigate(`/campaigndetails/${tile.campid}/${profileResponse?.orgrole}/${profileResponse?.organization}`)
        }
      />
      <CardContent style={{ padding: 8, fontSize: 8 }}>
        <span style={{ fontSize: "10" }}>{place.title}</span>
      </CardContent>
    </Card>
  )
}

export const Search = ({ place }) => {
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  return (
    <div>
      <SearchIcon />
      <InputBase />
    </div>
  )
}

